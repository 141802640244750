import React from 'react';
import { Spin } from 'antd';
import '../styles/loader.css'; // Add CSS for the full-screen loader

const FullScreenLoader = ({ loading, children }) => {
  return (
    <div className="full-screen-loader-container">
      {loading ? (
        <div className="loader-wrapper">
          <Spin size="large" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default FullScreenLoader;
