// import React, { useEffect, useState } from "react";
// import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
// import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
// import getDocumentTypeList from "../../services/getDocumentTypeList";
// import * as Yup from "yup";

// const { Option } = Select;

// const FiOvUploadInputForm = ({ setDocumentUpload, setDocumentTypeId }) => {
//   const [form] = Form.useForm();

//   const [documentName, setDocumentName] = useState("");
//   const [uploadDisabled, setUploadDisabled] = useState(true);
//   const [legalDocumentType, setLegalDocumentType] = useState([]);
//   const [fileList, setFileList] = useState([]);
//   const [image, setImage] = useState([]);
//   const [openUploadModal, setOpenUploadModal] = useState(false);
//   const [, setDeleteUploadPicture] = useState(null);
//   const [deleteFileIndex, setDeleteFileIndex] = useState(null);

//   const validationSchema = Yup.object().shape({
//     upload_documents: Yup.string().required("Documents name are required"),
//   });


//   const yupSync = {
//     async validator({ field }, value) {
//       await validationSchema.validateSyncAt(field, { [field]: value });
//     },
//   };
//   useEffect(() => {
//     const fetchDocumentTypeList = async () => {
//       const response = await getDocumentTypeList();
//       setLegalDocumentType(response.data);
//     };
//     fetchDocumentTypeList();
//   }, []);

//   const handleDocumentNameChange = (value) => {
//     setDocumentName(value);
//     setUploadDisabled(!value);
//   };

//   const handleUploadChange = ({ fileList: newFileList }) => {
//     const freshImages = newFileList?.map((file) => ({
//       file_path: URL.createObjectURL(file.originFileObj || file),
//     }));

//     const newImages = newFileList?.map((file) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           resolve({
//             file_path: reader.result,
//             type: file.type,
//             size: file.size,
//             name: file.name,
//           });
//         };
//         reader.onerror = reject;

//         reader.readAsDataURL(file.originFileObj || file);
//       });
//     });

//     Promise.all(newImages)
//       .then((base64Images) => {
//         const documentimage = base64Images.map((item) => ({
//           file: item.file_path,
//           document_type_id: documentName,
//         }));

//         setDocumentUpload(documentimage); // Set the state directly with the new array
//       })
//       .catch((error) => {
//         console.error("Error converting images to base64:", error);
//       });

//     setFileList(newFileList);
//     setImage(freshImages);
//     form.resetFields(["upload_documents"]);
//     setUploadDisabled(true);
//   };

//   const handleRemove = (file) => {
//     const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
//     setFileList(updatedFileList);

//     const updatedImages = updatedFileList.map((file) => ({
//       file: URL.createObjectURL(file.originFileObj || file),
//     }));
//     setImage(updatedImages);

//     // Ensure the deleted image is removed from the document upload
//     const updatedDocumentUpload = updatedFileList.map((item) => ({
//       file: URL.createObjectURL(item.originFileObj || item),
//       document_type_id: documentName,
//     }));
//     setDocumentUpload(updatedDocumentUpload);
//   };

//   const confirmDelete = () => {
//     // Remove image from both image and fileList states
//     const updatedFileList = fileList.filter((_, index) => index !== deleteFileIndex);
//     const updatedImages = image.filter((_, index) => index !== deleteFileIndex);

//     setFileList(updatedFileList);
//     setImage(updatedImages);

//     // Clear selected delete states
//     setOpenUploadModal(false);
//     setDeleteUploadPicture(null);
//     setDeleteFileIndex(null);

//     // Ensure document upload state is also updated
//     const updatedDocumentUpload = updatedFileList.map((file) => ({
//       file: URL.createObjectURL(file.originFileObj || file),
//       document_type_id: documentName,
//     }));
//     setDocumentUpload(updatedDocumentUpload);
//   };

//   const handleBeforeUpload = (file) => {
//     const isJpgOrPngOrPdf =
//       file.type === "image/jpeg" ||
//       file.type === "image/png" ||
//       file.type === "application/pdf";
//     if (!isJpgOrPngOrPdf) {
//       message.error("You can only upload JPG/PNG/PDF file!");
//       return Upload.LIST_IGNORE;
//     }
//     return false; // Prevent automatic upload
//   };

//   return (

//       <div>
//         <div className="property_description_heading_two">
//           <div className="property_description_data">
//             FI Documents and Other Documents
//           </div>
//         </div>

//         <div style={{ marginTop: "10px" }}>
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item
//                 name="upload_documents"
//                 label="Enter Document Name"
//                 required
//                 rules={[yupSync]}

//               >
//                 <Select
//                   placeholder="Please select"
//                   allowClear
//                   style={{ flex: 1, marginRight: "8px", width: "100%" }}
//                   showSearch
//                   onChange={handleDocumentNameChange}
//                   filterOption={(input, option) =>
//                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                   }
//                 >
//                   {legalDocumentType?.map((legaldocumenttype) => (
//                     <Option
//                       key={legaldocumenttype.name}
//                       value={legaldocumenttype.id}
//                     >
//                       {legaldocumenttype.display_name}
//                     </Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item name="file_path" label=" ">
//                 <Upload
//                   multiple
//                   fileList={fileList}
//                   onChange={handleUploadChange}
//                   onRemove={handleRemove}
//                   beforeUpload={handleBeforeUpload}
//                   showUploadList={false}
//                   disabled={uploadDisabled}
//                 >
//                   <Button
//                     type="primary"
//                     icon={<UploadOutlined />}
//                     disabled={uploadDisabled}
//                   >
//                     Upload Document
//                   </Button>
//                 </Upload>
//               </Form.Item>
//             </Col>
//           </Row>

//           <div style={{ margin: "10px 10px 10px 10px" }}>
//             <div style={{ display: "flex", flexWrap: "wrap" }}>
//               {image?.map((picture, index) => (
//                 <div
//                   key={index}
//                   style={{ margin: "10px", position: "relative" }}
//                 >
//                   <img
//                     src={picture.file_path}
//                     alt=""
//                     style={{ width: "100px", height: "50%" }}
//                   />
//                   <div
//                     style={{
//                       top: 0,
//                       right: 0,
//                       color: "#E5233DB2",
//                       cursor: "pointer",
//                       textAlign: "center",
//                       marginTop: "10px",
//                     }}
//                     onClick={() => {
//                       setDeleteUploadPicture(picture);
//                       setDeleteFileIndex(index);
//                       setOpenUploadModal(true);
//                     }}
//                   >
//                     <DeleteOutlined />
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           <Modal
//             title={<h4>Are you sure you want to delete this image?</h4>}
//             open={openUploadModal}
//             onOk={confirmDelete}
//             onCancel={() => {
//               setOpenUploadModal(false);
//               setDeleteUploadPicture(null);
//             }}
//             okType="danger"
//           ></Modal>
//         </div>
//       </div>
//   );
// };

// export default FiOvUploadInputForm;


import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
import getDocumentTypeList from "../../services/getDocumentTypeList";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import pdfIcon from "../../assets/pdf.png"; 
import RViewerJS from "viewerjs-react";

const { Option } = Select;

const FiOvUploadInputForm = ({ form ,setDocumentUpload}) => {
  const [, setDocumentName] = useState("");
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [, setDeletePicture] = useState(null);
  const [, setDeleteUploadPicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageId,setImageId] = useState()
  const handleDocumentNameChange = (value) => {
    setDocumentName(value);
    setUploadDisabled(!value);
  };

  const validationSchema = Yup.object().shape({
    upload_documents: Yup.mixed()
      .required("Document Name is required") // Ensures document selection is mandatory
      .label("Upload Document"),
  });
  
const yupSync = {
  async validator({ field }, value) {
    if (fileList.length > 0) {
      // Skip validation if a document is already uploaded
      return Promise.resolve();
    } else {
      // Enforce validation if no document is uploaded
      await validationSchema.validateSyncAt(field, { [field]: value });
    }
  },
};

  const handleUploadChange = ({ fileList: newFileList }) => {
    // setFileList(newFileList); 
    const freshImages = newFileList.map((file,index) => ({
      file_path: URL.createObjectURL(file.originFileObj || file),
      isPDF: file.type === "application/pdf",
      id:index
    }));
    
    setImage(freshImages);
 
    setUploadDisabled(true);
    form.resetFields(["upload_documents"]);
  };

  const confirmDelete = () => {
    setImage(
      image.filter((image) => image.id !== imageId)
    );
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }
    const newImage = {
      file_path: URL.createObjectURL(file),
    };
    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const fetchDocumentTypeList = async () => {
    try {
      const response = await getDocumentTypeList();
      setLegalDocumentType(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    fetchDocumentTypeList();

    form.resetFields(["upload_documents"]);
    setFileList([]);
    setImage([]);
    setUploadDisabled(true);
  }, [form]);



  return (
    <div style={{ border: "1px solid #E4E7EC", marginTop: "20px" }}>
      <div className="property_description_heading">
        <div className="property_description_data">
          Legal Documents and Other Documents
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{ margin: "10px 10px 10px 10px" }}
            // onClick={toggleCollapsepropertydescription}
          >
            {/* {collapsed1 ? <DownOutlined /> : <UpOutlined />} */}
          </div>
        </div>
      </div>

      <div style={{ margin: "10px" }}>
        <Row gutter={16}>
        <Col span={8}>
              <Form.Item
                name="upload_documents"
                label="Enter Document Name"
                rules={image.length ? null : [yupSync]}
                required
         
              >
                <Select
                  placeholder="Please select"
                  allowClear
                  style={{ flex: 1, marginRight: "8px", width: "100%" }}
                  showSearch
                  onChange={handleDocumentNameChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {legalDocumentType?.map((legaldocumenttype) => (
                    <Option
                      key={legaldocumenttype.name}
                      value={legaldocumenttype.id}
                    >
                      {legaldocumenttype.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          <Col span={4}>
            <Form.Item name="file_path" label=" ">
              <Upload
                multiple
                fileList={fileList}
                onChange={handleUploadChange}
                // onRemove={handleRemove}
                beforeUpload={handleBeforeUpload} // Handle file upload manually
                showUploadList={false} // Hide the file list
                disabled={uploadDisabled}
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  disabled={uploadDisabled}
                >
                  Upload Document
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ margin: "10px 10px 10px 10px" }}>
          <div style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}>
            {image?.map((picture, index) => (
              <div key={index} style={{ margin: "10px", position: "relative" }}>
                {picture.isPDF ? (
                  <img
                    src={pdfIcon}
                    alt="PDF Icon"
                    style={{ width: "100px", height: "50px" }}
                    onClick={() => window.open(picture.file_path, "_blank")} // Open PDF in new tab
                  />
                ) : (
                  <RViewerJS>
                    <img
                      src={picture.file_path}
                      alt=""
                      style={{ width: "100px", height: "50%" }}
                    />
                  </RViewerJS>
                )}
                <div
                  style={{
                    top: 0,
                    right: 0,
                    color: "#E5233DB2",
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    setDeleteUploadPicture(picture);
                    setImageId(picture.id);
                    setOpenUploadModal(true);
                  }}
                >
                  <DeleteOutlined />
                </div>
              </div>
            ))}
          </div>
        </div>

        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openModal}
          // onOk={deletePropertyPictures}
          onCancel={() => {
            setOpenModal(false);
            setDeletePicture(null);
          }}
          okType="danger"
        ></Modal>
        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openUploadModal}
          onOk={confirmDelete}
          onCancel={() => {
            setOpenUploadModal(false);
            setDeleteUploadPicture(null);
          }}
          okType="danger"
        ></Modal>
      </div>
    </div>
  );
};

export default FiOvUploadInputForm;


