import { Divider, Space, message, Tabs } from "antd";
import React, {  useState } from "react";
import "../styles/dedupe.css";
import CustomerMatching from "./CustomerMatching";
import OverallStatus from "./OverAllStatus";
import PropertyMatching from "./PropertyMatching";
import { useParams } from "react-router-dom";
import "../../loanApplication/loanSummary/styles/loanApplication.css";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import LoanDetailMainCard from "../../loanApplication/loanSummary/views/LoanDetailMainCard"

const { TabPane } = Tabs;

const Dedupe = () => {
  const { encrypted_loan_id } = useParams();
  const [activeKey, setActiveKey] = useState("0");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  // const tabs = [
  //   {
  //     label: "Customer Matching",
  //     component: <CustomerMatching key={`customermatching-${activeKey}`} />
  //   },
  //   {
  //     label: "Property Matching",
  //     component: <PropertyMatching loanId={loan_id} key={`propertymatching-${activeKey}`} />
  //   },
  //   {
  //     label: "Overall Status",
  //     component: <OverallStatus key={`overallstatus-${activeKey}`} />
  //   }
  // ];

  const tabs = [
    { label: "Customer Matching", component: <CustomerMatching /> },
    { label: "Property Matching", component: <PropertyMatching loanId={loan_id} /> },
    { label: "Overall Status", component: <OverallStatus /> },
  ];

  return (
    <>
      <div className="loan_application_progress_dedupe">

        <div style={{ margin: "12px" }}>
          <LoanDetailMainCard />
        </div>
      </div>

      <div className="dedupe_main_container">
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2 className="dedupe_heading">Matched LAN’s</h2>
          {/* <div className="button_div">
            <Button className="dedupe_heading_button_one">
              Kaluram (Applicant)
            </Button>
            <Button className="dedupe_heading_button_two">
              Nagu Bai (Co-Applicant)
            </Button>
            <UpOutlined style={{ margin: "1rem" }} />
          </div> */}
        </Space>

        <Divider style={{ margin: " 0px" }} />
        <Tabs
          type="card"
          style={{ margin: "20px 20px 20px 20px" }}
          onChange={handleTabChange}
          activeKey={activeKey}
          defaultActiveKey="0"
          destroyInactiveTabPane
        >
          {tabs.map((tab, index) => (
            <TabPane tab={tab.label} key={index}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default Dedupe;
