import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getRCUDataByIdService, getRCUStatusService, completeRemark } from "../services/rcuservices";

const Conclusions = ({ open, refreshData, closeForm, loanid, stage_id }) => {
  const [form] = Form.useForm();
  const [rcuid, setRcuid] = useState();
  const [status, setStatus] = useState([]);
  const [quillValue, setQuillValue] = useState(''); // State to manage ReactQuill value

  // Function to strip HTML tags
  const stripHtml = (html) => {
    let temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const getDepartmentData = async () => {
    try {
      const response = await getRCUDataByIdService(stage_id);
      const {
        overall_status,
        overall_remark
      } = response?.data?.rcu_data;
      setRcuid(response?.data?.rcu_data?.id)
      form.setFieldsValue({
        overall_status,
      });
      setQuillValue(overall_remark); // Set the initial value for ReactQuill
    } catch (error) {
      message.error(`${error?.response?.status}: ${error?.response?.data?.message}`);
    }
  };

  const getStatusData = async () => {
    try {
      const response = await getRCUStatusService();
      setStatus(response.data); // Assuming response.data is the array of statuses
    } catch (error) {
      message.error(`Failed to fetch statuses: ${error?.response?.data?.message}`);
    }
  };

  useEffect(() => {
    getDepartmentData();
    getStatusData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanid, open]);

  const handleSubmit = async (values) => {
    try {
      const processedValues = {
        ...values,
        overall_remark: stripHtml(quillValue), // Use the stripped value
      };
      const response = await completeRemark(rcuid, processedValues);
      if (response.status === 200 && response.success) {
        message.success("Completion Remark successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(`${error?.response?.status}: ${error?.response?.data?.message}`);
    }
  };

  const handleQuillChange = (value) => {
    setQuillValue(value);
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="overall_status"
            label="Overall Status"
            rules={[{ required: true, message: 'Please select an overall status' }]}
          >
            <Select placeholder="Please Select Overall Status" allowClear>
              {status.map((statusItem, index) => (
                <Select.Option key={index} value={statusItem}>
                  {statusItem}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={24}>
          <Form.Item
            label="Remarks"
            rules={[{ required: true, message: 'Please enter remarks' }]}
          >
            <ReactQuill 
              value={quillValue}
              placeholder="Enter description here..." 
              onChange={handleQuillChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button type="default" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Conclusions;
