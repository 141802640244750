import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import dayjs from "dayjs";
import {
  getSurroundings,
  getQualificationTypeChoice,
  getMartialStatus,
  getLivingStandardChoice,
} from "../../services/getCustomerDetailData";
import { updatePersonalDetailsById } from "../../services/applicantRvServices";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  no_of_family_members: Yup.string()
    .required("Family members is required"),
  is_working: Yup.string()
    .required("Is working is required"),
  has_dependent: Yup.string()
    .required("Has dependent is required"),
  date_of_birth: Yup.string()
    .required("Date of birth is required"),
  qualification: Yup.string()
    .required("Qualification is required"),
  marital_status: Yup.string()
    .required("Marital status is required"),
  is_spouse_working: Yup.string()
    .required("Is spouse working is required"),
  living_standard: Yup.string()
    .required("Living standard is required"),
  surrounding: Yup.array()
    .of(Yup.string().required())
    .min(1, "Surrounding is required")
    .required("Surrounding is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};


const PersonalDetailsEditForm = ({ customerDetails, closeForm, updateId, open, refreshData }) => {
  const [form] = Form.useForm();
  const [qualificationTypeChoice, setQualificationTypeChoice] = useState([]);
  const [martialStatus, setMartialStatus] = useState([]);
  const [LivingStandardChoice, setLivingStandardChoice] = useState([]);
  const [surroundings, setSurroundings] = useState([]);
  const { Option } = Select;

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getMartialStatus, setMartialStatus);
    fetchAllData(getSurroundings, setSurroundings);
    fetchAllData(getQualificationTypeChoice, setQualificationTypeChoice);
    fetchAllData(getLivingStandardChoice, setLivingStandardChoice);
  }, []);

  const getData = async () => {
    if (updateId) {
      try {
        const {
          no_of_family_members,
          is_working,
          has_dependent,
          date_of_birth: originalDateOfBirth,
          age,
          qualification,
          marital_status,
          spouse_name,
          is_spouse_working,
          living_standard,
          surrounding
        } =
          customerDetails;
        const parsedSurroundings = JSON.parse(surrounding.replace(/'/g, '"'));
        const formattedDateOfBirth = originalDateOfBirth ? dayjs(originalDateOfBirth, "DD-MM-YYYY") : null;

        form.setFieldsValue({
          no_of_family_members,
          is_working,
          has_dependent,
          date_of_birth: formattedDateOfBirth,
          age,
          qualification,
          marital_status,
          spouse_name,
          is_spouse_working,
          living_standard,
          surrounding: parsedSurroundings,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  const handleSubmit = async (values) => {
    values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    const formattedSurrounding = JSON.stringify(values.surrounding).replace(
      /"/g,
      "'"
    );

    const payload = {
      ...values,
      surrounding: formattedSurrounding, // Use the formatted string in the payload
    };

    try {
      if (updateId) {
        const response = await updatePersonalDetailsById(updateId, payload);
        if ((response.status = 200 && response.success)) {
          message.success("Personal details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="no_of_family_members"
              label="Family Members"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Family Members"
                type="number"
              // onChange={(e) => handleInput(e, "no_of_family_members")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="is_working" label="Working" required
              rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="has_dependent" label="Dependent" required
              rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="date_of_birth" label="DOB" required
              rules={[yupSync]}>
              <DatePicker
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>
          {/* //no fields in postman */}
          {/* <Col span={8}>
                        <Form.Item name="residence ownership details" label="Age" required
                        rules={[yupSync]}>
                            <Input
                                placeholder="Please Enter Age"
                                onChange={(e) => handleInput(e, "residence ownership details")}
                            />

                        </Form.Item>
                    </Col> */}
          <Col span={8}>
            <Form.Item name="qualification" label="Qualification" required
              rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {qualificationTypeChoice?.map((status) => (
                  <Option value={status}>{status}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="spouse_name" label="Spouse Name">
              <Input
                placeholder="Please Enter Spouse Name"
                onChange={(e) => handleInput(e, "spouse_name")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="marital_status" label="Marital Status" required
              rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {martialStatus?.map((status) => (
                  <Option value={status}>{status}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="is_spouse_working"
              label="Is Spouse Working"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="living_standard" label="Living Standard" required
              rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {LivingStandardChoice?.map((living) => (
                  <Option value={living}>{living}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="surrounding" label="Surrounding" required
              rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              // onChange={(value) => {
              //   const selectedValues = value.join(", ");
              // }}
              >
                {surroundings?.map((surrounding) => (
                  <Option key={surrounding} value={surrounding}>
                    {surrounding}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default PersonalDetailsEditForm;
