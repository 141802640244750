import { message, Row, Col, Typography, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { LTVCalculationsByLoanId } from '../services/incomeDetailsServices';

const { Text } = Typography;

const LTVCalculation = ({ refreshTableData, refreshLTVData }) => {
    const { encrypted_loan_id } = useParams();
    const [getLTVCalculation, setLTVCalculation] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading
    let loan_id = null;

    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        const fetchLTVCalculation = async () => {
            try {
                const response = await LTVCalculationsByLoanId(loan_id);
                setLTVCalculation(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            } finally {
                setLoading(false); // Stop loading regardless of success or error
            }
        };

        fetchLTVCalculation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTableData, loan_id, refreshLTVData]);

    return (
        <div className="income_calculations_container">
            <div className="heading_text">LTV Calculations</div>
            <div className="ltv-content">
                {loading ? (
                    // Display Skeleton loader when loading
                    <Skeleton active paragraph={{ rows: 10 }} />
                ) : (
                    <>
                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Loan Amount Required</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.approved_amount || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Appraised Obligations</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.appraised_obligations || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Tenor applied</Text>
                            </Col>
                            <Col>
                                <Text>: {`${getLTVCalculation?.approved_amount || 0}`} days</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Rate Of Interest</Text>
                            </Col>
                            <Col>
                                <Text>: {` ${getLTVCalculation?.approved_roi || 0}`}%</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Value of the property(₹ In lacs)</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.property_value || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Total Income</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.total_income || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Appraised Monthly Income</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.appraised_monthly_income || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Max FOIR</Text>
                            </Col>
                            <Col>
                                <Text>: {`${getLTVCalculation?.foir || 0}`}%</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Max EMI</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.max_emi || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>EMI Factor</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.emi_factor || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Eligibility (In ₹)</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.eligibility || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>EMI</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.emi || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row">
                            <Col span={10}>
                                <Text>Actual FOIR (including present loan)</Text>
                            </Col>
                            <Col>
                                <Text>: {`₹ ${getLTVCalculation?.foir || 0}`}</Text>
                            </Col>
                        </Row>

                        <Row className="ltv-row actual-ltv-row">
                            <Col span={10}>
                                <Text strong>Actual LTV</Text>
                            </Col>
                            <Col>
                                <Text strong>: {`₹ ${getLTVCalculation?.actual_ltv || 0}`}%</Text>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
};

export default LTVCalculation;
