import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
} from "antd";
import {
  getPersonaByIdService,
  updatePersonalDetailsByIdService,
  getAllCastService,
  listGenderType,
  listMaritalStatusType,
  listReligion,
  listNationality,
  listRelation,
  listEducation,
  listFamilyType,
  listCustomerType,
  getApplicantListByIdService
} from "../services/personalDetailsServices";
import * as Yup from "yup";
import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[A-Za-z ]+$/, "First name can only contain letters")
    .min(2, "First name must be at least 2 characters")
    .max(128, "First name must be at max 128 characters")
    .label("First name"),
  middle_name: Yup.string()
    // .nullable()
    // .matches(/^[A-Za-z_]+$ /, "Middle name can only contain letters and spaces")
    // .min(2, "Middle name must be at least 2 characters")
    .max(128, "Middle name must be at max 128 characters")
    .label("Middle name"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[A-Za-z ]+$/, "Last name can only contain letters")
    .min(2, "Last name must be at least 2 characters")
    .max(128, "Last name must be at max 128 characters")
    .label("Last Name"),
  date_of_birth: Yup.date()
    .required("Date of birth is required")
    .test(
      "valid-age",
      "Customer must be at least 18 years old",
      function (value) {
        const givenDate = dayjs(value); // Convert the given date to dayjs object for comparison
        const currentDate = dayjs(); // Get the current date using dayjs
        const eighteenYearsAgo = currentDate.subtract(18, "year"); // Calculate the date 18 years ago

        return givenDate.isValid() && givenDate.isBefore(eighteenYearsAgo);
      }
    )
    .test(
      "not-future-date",
      "Date of birth cannot be a future date",
      function (value) {
        return value ? dayjs(value).isBefore(dayjs()) : true;
      }
    )
    .label("Date Of Birth"),

  gender: Yup.string().required("Gender is required").label("Gender"),
  marital_status: Yup.string()
    .required("Marital status is required")
    .label("Marital status"),
  relation_with_applicant: Yup.string()
    .required("Relation with applicant is required")
    .min(1, "Relation with applicant is required"),
  caste_category: Yup.string()
    .required("Caste category is required")
    .label("Caste category"),
  family_type: Yup.string()
    .required("Family type is required"),
  education: Yup.string()
    .required("Education is required"),
  place_of_birth: Yup.string()
    .required("Place of birth is required")
    .matches(/^[A-Za-z ]+$/, "Place of birth can only contain letters")
    .min(2, "Place of birth must be at least 2 characters")
    .max(128, "Place of birth must be at max 128 characters")
    .label("Place of birth"),
  nationality: Yup.string()
    .required("Nationality is required")
    .label("Nationality"),
  religion: Yup.string().required("Religion is required").label("Religion"),
  university: Yup.string()
    .required("University is required")
    .min(2, "University must be at least 2 characters")
    .max(128, "University must be at max 128 characters")
    .label("University"),
  father_name: Yup.string()
    .required("Father name is required")
    .min(2, "Father name must be at least 2 characters")
    .max(128, "Father name must be at max 128 characters")
    .label("Father name"),
  email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format"
    )
    .label("Email"),

  phone_number: Yup.string()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return originalValue.replace(/\D/g, ''); // Remove all non-numeric characters
      }
      return originalValue;
    })
    .matches(/^\d{8,15}$/, {
      message: 'Phone number must be between 8 to 15 digits',
      excludeEmptyString: true,
    })
    .label("Phone number"),

  phone_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  alt_mobile_number: Yup.string()

    .matches(/^[0-9]*$/, "Alt Mobile Number must contain only digits"),

  alt_mobile_country_code: Yup.string()
    // .min(2, "Country Code must be exact 2 digits")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),

  spouse_name: Yup.string()
    .required("Spouse name is required")
    .matches(/^[A-Za-z ]+$/, "Spouse name can only contain letters")
    .min(2, "Spouse name must be at least 2 characters")
    .max(128, "Spouse name must be at max 128 characters")
    .label("Spouse name"),

  mobile_country_code: Yup.string()
    // .min(2, "Country Code must be exact 2 digits")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  new_applicant_id: Yup.string()
    .required("Applicant Name is required"),

  new_customer_type: Yup.string()
    .required("Customer Type Of Applicant is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PersonaleDetailsForm = ({ refreshData, open, closeForm, id, loan_id, customer, setCustomer }) => {
  const [form] = Form.useForm();
  const [castCategories, setCastCategories] = useState([]);
  const [genderType, setGenderType] = useState([]);
  const [maritalStatusType, setMaritalStatusType] = useState([]);
  const [religionType, setReligionType] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [relation, setRelation] = useState([]);
  const [education, setEducation] = useState([]);
  const [familyType, setFamilyType] = useState([]);
  const [isSpouseNameDisabled, setIsSpouseNameDisabled] = useState(false);
  const [applicanttype, setApplicantType] = useState("");
  const [customerType, setCustomerType] = useState([]);
  const [customerList, setCustomerList] = useState([])
  const [loading, setLoading] = useState(false);
  const ids = id?.id;

  const getPersonalData = async () => {
    if (ids) {
      try {
        const response = await getPersonaByIdService(ids);
        let {
          first_name,
          middle_name,
          last_name,
          date_of_birth,
          gender,
          marital_status,
          caste_category,
          place_of_birth,
          nationality,
          religion,
          university,
          father_name,
          email,
          phone_number,
          alt_mobile_number,
          mobile_number,
          spouse_name,
          // phone_country_code,
          // mobile_country_code,
          // alt_mobile_country_code,
          relation_with_applicant,
          family_type,
          education,
          customer_type
        } = response?.data;
        date_of_birth = dayjs(date_of_birth, "DD-MM-YYYY");
        if (
          marital_status === "Single" ||
          marital_status === "Widow" ||
          marital_status === "Divorced"
        )
          setIsSpouseNameDisabled(true);
        setApplicantType(customer_type.name);
        form?.setFieldsValue({
          first_name,
          middle_name,
          last_name,
          date_of_birth,
          gender,
          marital_status,
          caste_category: caste_category?.id,
          place_of_birth,
          nationality,
          religion,
          university,
          father_name,
          email,
          phone_number,
          alt_mobile_number,
          mobile_number,
          spouse_name,
          // phone_country_code,
          // mobile_country_code,
          // alt_mobile_country_code,
          relation_with_applicant: relation_with_applicant?.id,
          family_type,
          education,
          customer_type: customer_type?.name
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handleInput = (e, field) => {
    let newValue = e.target.value;

    // Apply specific restrictions based on the field
    if (field === "alt_mobile_number") {
      // Allow only digits and limit to exactly 10 characters
      newValue = newValue.replace(/\D/g, ""); // Remove non-digits
      newValue = newValue.slice(0, 10); // Limit to maximum 10 characters
    } else if (
      field === "first_name" ||
      field === "middle_name" ||
      field === "last_name" ||
      field === "place_of_birth" ||
      field === "nationality" ||
      field === "religion" ||
      field === "university" ||
      field === "father_name" ||
      field === "spouse_name"
    ) {
      // Allow only alphabetic characters and spaces for names
      newValue = newValue.replace(/[^A-Za-z\s]/g, "");
    } else if (field === "email") {
      // Allow alphabetic characters, ".", "@", and numbers for the email field
      newValue = newValue.replace(/[^A-Za-z.@0-9]/g, "");
    } else if (field === "mobile_number") {
      // Allow only numbers for the mobile_number field
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    // Update the form state with the sanitized value
    form?.setFieldsValue({ [field]: newValue });
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    // Format date_of_birth
    values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");

    // Clear spouse_name if isSpouseNameDisabled is true
    if (isSpouseNameDisabled) {
      values.spouse_name = "";
    }
    if (values.phone_number) {
      values.phone_country_code = "91";
    }
    if (values.alt_mobile_number) {
      values.alt_mobile_country_code = "91";
    }
    if (values.mobile_number) {
      values.mobile_country_code = "91";
    }
    // Remove customer_type if it's not a number
    if (isNaN(values.customer_type)) {
      delete values.customer_type;
    }

    try {
      if (ids) {
        const response = await updatePersonalDetailsByIdService(ids, values);

        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("Personal details updated successfully");
            refreshData();
            closeForm();
            setIsSpouseNameDisabled(false);
            setCustomer("");
          }
        } else {
          message.error("Failed to update personal details");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }finally{  
      setLoading(false)
    }
  };


  useEffect(() => {
    if (open) {
      getPersonalData();
      getAllCastList();
      getAllGenderType();
      getAllMaritalStatusType();
      getAllReligionType();
      getAllNationality();
      getAllRelation();
      getAllEducation();
      getAllFamilyType();
      getCustomerType();
      getApplicantList();

    } else {
      form?.resetFields();
    }

    const currentMaritalStatus = form?.getFieldValue("marital_status");

    if (
      currentMaritalStatus === "Single" ||
      currentMaritalStatus === "Widow" ||
      currentMaritalStatus === "Divorced"
    ) {
      setIsSpouseNameDisabled(true);
    } else {
      setIsSpouseNameDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  const getAllCastList = async () => {
    try {
      const response = await getAllCastService();
      setCastCategories(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getAllNationality = async () => {
    try {
      const response = await listNationality();
      setNationality(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllGenderType = async () => {
    try {
      const response = await listGenderType();
      setGenderType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllMaritalStatusType = async () => {
    try {
      const response = await listMaritalStatusType();
      setMaritalStatusType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllReligionType = async () => {
    try {
      const response = await listReligion();
      setReligionType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRelation = async () => {
    try {
      const response = await listRelation();
      setRelation(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllEducation = async () => {
    try {
      const response = await listEducation();
      setEducation(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getAllFamilyType = async () => {
    try {
      const response = await listFamilyType();
      setFamilyType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getCustomerType = async () => {
    try {
      const response = await listCustomerType();
      setCustomerType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getApplicantList = async () => {
    try {
      const response = await getApplicantListByIdService(loan_id);
      setCustomerList(response.data.customer_list);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCustomerTypeChange = (value) => {
    setCustomer(value)
  }



  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  const handleInputt = (e, name) => {
    let value = e.target.value.replace(/\D/g, '');
    value = value.slice(0, 15);
    form.setFieldsValue({ [name]: value });
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        marital_status: "",
        caste_category: "",
        place_of_birth: "",
        nationality: "",
        religion: "",
        university: "",
        father_name: "",
        spouse_name: "",
        email: "",
        phone_number: "",
        // phone_country_code: "",
        mobile_number: "",
        // alt_mobile_number: "",
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>

        <Col span={8}>
          <Form.Item
            name="customer_type"
            label="Customer Type "
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              onChange={handleCustomerTypeChange}
            >
              {customerType
                .filter(type => type.name !== applicanttype) // Filter out the "Co-Applicant" option
                .map(type => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>

        {applicanttype === "Applicant" && typeof customer === "number" && (
          <Col span={8}>
            <Form.Item
              name="new_applicant_id"
              label="Select New Applicant"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                showSearch
                allowClear
                onChange={handleCustomerTypeChange}
              >
                {customerList
                  // Filter out the "Co-Applicant" option
                  .map(type => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        )}



        {(applicanttype !== "Applicant" && customer === 1) &&
          <Col span={8}>
            <Form.Item
              name="new_customer_type"
              label="Customer Type Of Applicant"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select Customer Type of applicant"
                showSearch
                allowClear

              >
                {customerType
                  .filter(type => type.name !== "Applicant") // Filter out the "Co-Applicant" option
                  .map(type => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        }


      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter First Name"
              onChange={(e) => handleInput(e, "first_name")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="middle_name"
            label="Middle Name"
            rules={[yupSync]}
          >
            <Input
              placeholder="Please Enter Middle Name"
              onChange={(e) => handleInput(e, "middle_name")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Last Name"
              onChange={(e) => handleInput(e, "last_name")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="date_of_birth"
            label="Date Of Birth"
            rules={[yupSync]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="gender" label="Gender" rules={[yupSync]} required>
            <Select placeholder="Please Select" showSearch allowClear>
              {genderType.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="marital_status"
            label="Marital Status"
            rules={[yupSync]}
            required
          >
            <Select

              placeholder="Please Select" showSearch
              allowClear
              onChange={(value) => {
                form.setFieldsValue({ marital_status: value });
                if (value !== "Married") {
                  form.setFieldsValue({ spouse_name: "" });
                  setIsSpouseNameDisabled(true);
                } else {
                  setIsSpouseNameDisabled(false);
                }
              }}
            >
              {maritalStatusType.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="caste_category"
            label="Caste Category"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              allowClear
              // onSearch={onSearch}
              filterOption={filterOption}
            >
              {castCategories?.map((category, index) => (
                <Option key={category?.id} value={category?.id}>
                  {category?.display_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="place_of_birth"
            label="Place Of Birth"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Place Of Birth"
              onChange={(e) => handleInput(e, "place_of_birth")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="nationality"
            label="Nationality"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select"
              showSearch
              allowClear
            >
              {nationality.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="religion"
            label="Religion"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select"
              showSearch
              allowClear>
              {religionType.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="university"
            label="University"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter University"
              onChange={(e) => handleInput(e, "university")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="father_name"
            label="Father's Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Father's Name"
              onChange={(e) => handleInput(e, "father_name")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="spouse_name"
            label="Spouse's Name"
            rules={!isSpouseNameDisabled ? [yupSync] : false}
            required={!isSpouseNameDisabled ? true : false}
          >
            <Input
              placeholder="Please Enter Spouse's Name"
              onChange={(e) => handleInput(e, "spouse_name")}
              disabled={isSpouseNameDisabled}
            />
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item name="email" label="Email" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Email"
              onChange={(e) => handleInput(e, "email")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="relation_with_applicant"
            label="Relation With Applicant"
            required
            rules={[yupSync]}
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {relation?.map((relation) => {
                return (
                  <Select.Option key={relation.id} value={relation.id}>
                    {relation.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>

        <Col span={8}>
          <Form.Item name="education" label="Education" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {education?.map((type) => {

                return (
                  <Select.Option
                    key={type}
                    value={type}>
                    {type}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="family_type" label="Family Type" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {familyType?.map((type) => {

                return (
                  <Select.Option
                    key={type}
                    value={type}>
                    {type}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<span>Phone Number</span>}>
            <Input.Group compact>
              <Form.Item name="phone_country_code" rules={[yupSync]} noStyle>
                <Input
                  style={{
                    width: "20%",
                    textAlign: "center",
                    background: "#f1f1f1",
                  }}
                  defaultValue="91"
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="phone_number"
                rules={[yupSync]}
                noStyle
              >
                <Input
                  style={{ width: "80%" }}
                  placeholder="Please Enter Phone Number"
                  onChange={(e) => handleInputt(e, "phone_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<span>Mobile Number</span>} required>
            <Input.Group compact>
              <Form.Item name="mobile_country_code" noStyle rules={[yupSync]}>
                <Input
                  style={{
                    width: "20%",
                    textAlign: "center",
                    background: "#f1f1f1",
                  }}
                  defaultValue="91"
                  disabled
                />
              </Form.Item>
              <Form.Item name="mobile_number" rules={[yupSync]} noStyle>
                <Input
                  style={{ width: "80%" }}
                  placeholder="Please Enter Mobile Number"
                  onChange={(e) => handleNumberFields(e, "mobile_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<span>Alt Mobile Number</span>}>
            <Input.Group compact>
              <Form.Item name="alt_mobile_country_code" rules={[yupSync]} noStyle>
                <Input
                  style={{
                    width: "20%",
                    textAlign: "center",
                    background: "#f1f1f1",
                  }}
                  defaultValue="91"
                  disabled
                />
              </Form.Item>

              <Form.Item
                name="alt_mobile_number"
                rules={[yupSync]}
                noStyle
              >
                <Input
                  style={{ width: "80%" }}
                  placeholder="Please Enter Alt Mobile Number "
                  onChange={(e) => handleInput(e, "alt_mobile_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
          <Button type="default" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default PersonaleDetailsForm;
