import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllSubSection = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/sub-section/list`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
  
  export const getAllQueryStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choice/query-status`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
  export const getAllUsers = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/user/get-user-list`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getAllStages = (stage_code) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-stage-history/stage-re-intitied-dropdown/?current_stage=${stage_code}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createSendBackQuery = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/stage-query/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateUserAndStatusById = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/loan/loan-stage-history/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
