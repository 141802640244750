import { Table, Form, Space, Drawer, message } from "antd";
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import "../../styles/fidocumentinputform.css";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import moment from "moment";
import IdentificationDocumentForm from "./IdentificationDocumentForm";
import Header from "../../../layout/views/Header";
import ResidenceNeighborForm from "./ResidenceNeighborForm";
import VehicleForm from "./VehicleForm";
import getDocumentTypeList from "../../services/getDocumentTypeList";

const FiDocumentInputForm = ({
  form,
  vehicle,
  ownerShip,
  enteredIdentificationDocuments,
  setEnteredIdentificationDocuments,
  enteredNeighborConfirmation,
  setEnteredNeighborConfirmation,
  enteredvehiclesData,
  setEnteredvehiclesData,
}) => {
  const [open, setOpen] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [collapsed3, setCollapsed3] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [, setRefreshTableData] = useState(false);
  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [openThirdDrawer, setOpenThirdDrawer] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const [documentType, setDocumentType] = useState([]);

  const [formInstance] = Form.useForm();

  useEffect(() => {
    const fetchDocumentTypeList = async () => {
      const response = await getDocumentTypeList();
      setDocumentType(response.data);
    };
    fetchDocumentTypeList();
  }, []);

  const identificationColumn = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Identification Document",
      dataIndex: "document_type_id",
      key: "document_type_id",
      width: "500px",
      render: (text, record, index) => {
        const getDocumentValue = documentType?.find(
          (item) => item?.id === text
        );
        return getDocumentValue?.name;
      },
    },
    {
      title: "Details",
      dataIndex: "document_number",
      key: "datdocument_numbered",
      width: "300px",
    },

    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "#4880FF" }}
            onClick={() => handleDeleteOrEditForScrutiny(record.key, true)}
          />
          <DeleteOutlined
            style={{ color: "#E5233DB2" }}
            onClick={() => handleDeleteOrEditForScrutiny(record.key, false)}
          />
        </Space>
      ),
    },
  ];

  const NeighborColumn = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "500px",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "300px",
      key: "remark",
      render: (status) => {
        if (!status) {
          return "No status available";
        } else {
          const lowerCaseStatus = status.toLowerCase();
          return (
            <>
              {lowerCaseStatus === "positive" ? (
                <div className="positive">
                  <GoDotFill
                    style={{ width: "12px", margin: "-4px 5px 0px 0px" }}
                  />
                  <span>{status}</span>
                </div>
              ) : (
                <div className="negative">
                  <GoDotFill
                    style={{ width: "12px", margin: "-4px 5px 0px 0px" }}
                  />
                  <span>{status}</span>
                </div>
              )}
            </>
          );
        }
      },
    },

    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "#4880FF" }}
            onClick={() =>
              handleDeleteOrEditForPreDisbursement(record.key, true)
            }
          />
          <DeleteOutlined
            style={{ color: "#E5233DB2" }}
            onClick={() =>
              handleDeleteOrEditForPreDisbursement(record.key, false)
            }
          />
        </Space>
      ),
    },
  ];

  const vehiclescolumns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
    },
    {
      title: "Model No",
      dataIndex: "model_no",
      key: "model_no",
    },

    {
      title: "Ownership Type",
      dataIndex: "ownership_type",
      key: "ownership_type",
    },
    {
      title: "Vehicle No",
      dataIndex: "registration_number",
      key: "registration_number",
    },
    {
      title: "Financed From",
      dataIndex: "financed_from",
      key: "financed_from",
    },
    {
      title: "Loan Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
    },

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              style={{ color: "#4880FF" }}
              onClick={() =>
                handleDeleteOrEditForPostDisbursement(record.key, true)
              }
            />
            <DeleteOutlined
              style={{ color: "#E5233DB2" }}
              onClick={() =>
                handleDeleteOrEditForPostDisbursement(record.key, false)
              }
            />
          </Space>
        );
      },
    },
  ];

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const showDrawer = () => {
    setEditing(false); // Ensure editing mode is false when adding
    setOpen(true);
    formInstance.resetFields(); // Reset form fields
  };
  const onClose = () => {
    setOpen(false);
    formInstance.resetFields();
    setEditValue({});
  };

  const showSecondDrawer = () => {
    setEditing(false); // Ensure editing mode is false when adding
    setOpenSecondDrawer(true);
    formInstance.resetFields(); // Reset form fields
  };

  const onCloseSecondDrawer = () => {
    setOpenSecondDrawer(false);
    formInstance.resetFields(); // Reset form fields
    setEditValue({});
  };

  const showThirdDrawer = () => {
    setEditing(false); // Ensure editing mode is false when adding
    setOpenThirdDrawer(true);
    formInstance.resetFields(); // Reset form fields
  };

  const onCloseThirdDrawer = () => {
    setOpenThirdDrawer(false);
    setEditValue({});
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const handleSubmitIdentificationDocumnet = (values) => {
    const formattedValues = values.map((item) => ({
      ...item,
    }));

    if (editing) {
      const updatedData = enteredIdentificationDocuments?.map((item) =>
        item.key === editKey ? { ...item, ...formattedValues[0] } : item
      );
      setEnteredIdentificationDocuments(updatedData);
      setEditing(false);
      setEditKey(null);
    } else {
      const newData = formattedValues?.map((item, index) => ({
        ...item,
        key: enteredIdentificationDocuments.length + index + 1,
      }));
      setEnteredIdentificationDocuments([
        ...enteredIdentificationDocuments,
        ...newData,
      ]);
    }

    setOpen(false);
    message.success("Identification Documents submitted successfully!");
  };

  const handleDeleteOrEditForScrutiny = (key, isEdit) => {
    if (isEdit) {
      const selectedRow = enteredIdentificationDocuments.find(
        (item) => item.key === key
      );
      setEditValue(selectedRow);
      setEditKey(key);
      setEditing(true);
      setOpen(true);
      form.setFieldsValue({
        documents: [
          {
            document_type_id: selectedRow.document_type_id,
            document_number: selectedRow.document_number,
          },
        ],
      });
    } else {
      const newData = enteredIdentificationDocuments.filter(
        (item) => item.key !== key
      );
      setEnteredIdentificationDocuments(newData);
      message.success(" Identification Documents Data deleted successfully!");
    }
  };

  const handleSubmitResidenceNeighbor = (values) => {
    const formattedValues = values?.map((item) => ({
      ...item,
      dated: item.dated ? moment(item.dated).format("YYYY-MM-DD") : "",
    }));
    if (editing) {
      // Update the existing row
      const updatedData = enteredNeighborConfirmation?.map((item) =>
        item.key === editKey ? { ...item, ...values[0] } : item
      );
      setEnteredNeighborConfirmation(updatedData);
      setEditing(false); // Reset editing mode
      setEditKey(null); // Reset edit key
    } else {
      const newData = formattedValues?.map((item, index) => ({
        ...item,
        key: enteredNeighborConfirmation.length + index + 1, // Ensure unique keys for the new data
      }));
      setEnteredNeighborConfirmation([
        ...enteredNeighborConfirmation,
        ...newData,
      ]);
    }

    setOpenSecondDrawer(false); // Close the drawer after submitting
    message.success(
      " Residence Neighbour Confirmation submitted successfully!"
    );
  };

  const handleSubmitVehicles = (values) => {
    const formattedValues = values?.map((item) => ({
      ...item,
      dated: item.dated ? item.dated.format("YYYY-MM-DD") : "",
    }));
    if (editing) {
      // Update the existing row
      const updatedData = enteredvehiclesData?.map((item) =>
        item.key === editKey ? { ...item, ...values[0] } : item
      );
      setEnteredvehiclesData(updatedData);
      setEditing(false); // Reset editing mode
      setEditKey(null); // Reset edit key
    } else {
      const newData = formattedValues.map((item, index) => ({
        ...item,
        key: enteredvehiclesData.length + index + 1, // Ensure unique keys for the new data
      }));
      setEnteredvehiclesData([...enteredvehiclesData, ...newData]);
    }

    setOpenThirdDrawer(false); // Close the drawer after submitting
    message.success(" Vehicle Data submitted successfully!");
  };

  const handleDeleteOrEditForPreDisbursement = (key, isEdit) => {
    if (isEdit) {
      // Find the selected row data
      const selectedRow = enteredNeighborConfirmation.find(
        (item) => item.key === key
      );
      // Open the form for editing with the selected row data pre-populated
      setEditValue(selectedRow);
      setEditKey(key); // Set the key of the row being edited
      setEditing(true); // Set editing mode
      setOpenSecondDrawer(true);
      // Set the initial values for the form fields
      form.setFieldsValue({
        documents: [
          {
            document: selectedRow.document,
            dated: selectedRow.dated
              ? moment(selectedRow.dated, "YYYY-MM-DD")
              : null, // Ensure moment object
            document_type: selectedRow.document_type,
          },
        ],
      });
    } else {
      // Delete the selected row
      const newData = enteredNeighborConfirmation.filter(
        (item) => item.key !== key
      );
      setEnteredNeighborConfirmation(newData);
      message.success("Residence Neighbour Confirmation deleted successfully!");
    }
  };

  const handleDeleteOrEditForPostDisbursement = (key, isEdit) => {
    if (isEdit) {
      // Find the selected row data
      const selectedRow = enteredvehiclesData.find((item) => item.key === key);
      // Open the form for editing with the selected row data pre-populated
      setEditValue(selectedRow);
      setEditKey(key); // Set the key of the row being edited
      setEditing(true); // Set editing mode
      setOpenThirdDrawer(true);
      // Set the initial values for the form fields
      form.setFieldsValue({
        documents: [
          {
            vehicle_type: selectedRow.vehicle_type,
            model_no: selectedRow.model_no,
            ownership_type: selectedRow.ownership_type,
            registration_number: selectedRow.registration_number,
            financed_from: selectedRow.financed_from,
            loan_amount: selectedRow.loan_amount,
          },
        ],
      });
    } else {
      // Delete the selected row
      const newData = enteredvehiclesData.filter((item) => item.key !== key);
      setEnteredvehiclesData(newData);
      message.success(" Vehicle Details deleted successfully!");
    }
  };

  return (
    <div className="" style={{ marginTop: "20px", margin: "0px" }}>
      <Drawer
        title={
          <Header
            title={editing ? "Edit" : "Add"}
            onClose={onClose}
            name="Identification Documents"
          />
        }
        width={1050}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <IdentificationDocumentForm
          open={open}
          editValue={editValue}
          documentType={documentType}
          closeForm={onClose}
          setEnteredData={handleSubmitIdentificationDocumnet}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={editing ? "Edit" : "Add"}
            onClose={onCloseSecondDrawer}
            name="Residence Neighbour Confirmation"
          />
        }
        width={1050}
        onClose={onCloseSecondDrawer}
        open={openSecondDrawer}
        closable={false}
      >
        <ResidenceNeighborForm
          open={openSecondDrawer}
          closeForm={onCloseSecondDrawer}
          editValue={editValue}
          setEnteredData={handleSubmitResidenceNeighbor}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={editing ? "Edit" : "Add"}
            onClose={onCloseThirdDrawer}
            name="Vehicle Details"
          />
        }
        width={1050}
        onClose={onCloseThirdDrawer}
        open={openThirdDrawer}
        closable={false}
      >
        <VehicleForm
          open={openThirdDrawer}
          vehicle={vehicle}
          ownerShip={ownerShip}
          closeForm={onCloseThirdDrawer}
          editValue={editValue}
          setEnteredData={handleSubmitVehicles}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <div
        className="document_input_container"
        style={{
          marginTop: "20px",
          margin: "0px",
          display: "flex",
          gap: "20px",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: 1, minWidth: "400px" }}>
          <div className="document_detail_heading">
            <div className="property_description_data">
              Identification Documents
            </div>
            <div style={{ display: "flex" }}>
              <div
                className="property_description_data_button"
                onClick={showDrawer}
                style={{ width: "215px" }}
              >
                Add Identification Documents
              </div>
              <div
                style={{ margin: "10px 10px 10px 10px" }}
                onClick={toggleCollapse1}
              >
                {collapsed1 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {collapsed1 && (
            <div style={{ border: "1px solid #E4E7EC" }}>
              <Table
                columns={identificationColumn}
                dataSource={enteredIdentificationDocuments}
                pagination={false}
              />
            </div>
          )}
        </div>

        <div style={{ flex: 1, minWidth: "400px" }}>
          <div className="document_detail_heading">
            <div className="property_description_data">
              Residence Neighbour Confirmation
            </div>
            <div style={{ display: "flex" }}>
              <div
                className="property_description_data_button"
                onClick={showSecondDrawer}
              >
                Add Neighbour
              </div>
              <div
                style={{ margin: "10px 10px 10px 10px" }}
                onClick={toggleCollapse2}
              >
                {collapsed2 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {collapsed2 && (
            <div
              className="table_container"
              style={{ border: "1px solid #E4E7EC" }}
            >
              <Table
                columns={NeighborColumn}
                dataSource={enteredNeighborConfirmation}
                pagination={false}
              />
            </div>
          )}
        </div>
      </div>

      <div className="" style={{ margin: "" }}>
        <div className="document_details_table">
          <div className="document_detail_heading">
            <div className="property_description_data">Vehicles</div>
            <div style={{ display: "flex" }}>
              <div
                className="property_description_data_button"
                onClick={showThirdDrawer}
              >
                Add Vehicles
              </div>
              <div
                style={{ margin: "10px 10px 10px 10px" }}
                onClick={toggleCollapse3}
              >
                {collapsed3 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {collapsed3 && (
            <div
              className="table_container"
              style={{ border: "1px solid #E4E7EC" }}
            >
              <Table
                columns={vehiclescolumns}
                dataSource={enteredvehiclesData}
                pagination={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FiDocumentInputForm;
