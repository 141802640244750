import { axiosRequest } from "../../../../../utils/api/axiosRequest";
export const getPersonalDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getPersonaByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updatePersonalDetailsByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/customer-detail/${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllCastService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/caste-category/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getCountryService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/country/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const listGenderType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/gender`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listMaritalStatusType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/marital-status`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listReligion = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/religion`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listNationality  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/nationality`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
 
export const listRelation  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/relation/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listEducation  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/eductaion`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
 
 
export const listFamilyType  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/family-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
 

export const listCustomerType  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/customer-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getApplicantListByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/customer-detail/customer-list/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

 
