import React, { useEffect, useState } from "react";
import "../styles/vehicleDetails.css";
import "../../style/customerStyle.css";
import verify from "../assets/Group.png";
import add from "../assets/Add.png";
import { Drawer, Modal } from "antd";
import Loading from "../../../../../utils/loading/Loading";
import Header from "../../../../layout/views/Header";
import ExistingVehicleDetailsForm from "./ExistingVehicleDetailsForm";
import { deleteExistingVehicleDetailsByIdService, getExistingVehicleDetailsByIdService } from "../services/existingVehicleDetails";
import { useParams } from "react-router-dom";
import { Empty, message } from "antd";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";

const ExistingVehicleDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [exstingVehicleData, setExstingVehicleData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState();
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
    setSelectedPerson(null);
    setSelectedRecord(null);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getExistingVehicleDetailsByIdService(loan_id);
        setExstingVehicleData(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const renderEditButton = (showDrawer, id, person) => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
          setSelectedCustomer(id);
          setSelectedPerson(person);
        }}
      >
        <EditButton />
      </div>
    );
  };

  const Heading1 = [
    "Key Parameter's",
    "Existing Vehicle Details",
    "Engine Number",
    "Chassis Number",
    "Registration Number",
    "Registration Date",
    "State",
    "Registration Transport Office",
    "Current Owner Name",
    "Registration Expiry Date",
    "Vehicle Life",
    "Vehicle Body",
    "Vehicle Age",
    "Manufacturing year",
    "Road Tax Type",
    "Number Of Previous Owners",
    "Vehicle Type",
    "Vehicle Manufacturer",
    "Vehicle Model",
    "Vehicle Variant",
  ];

  const fieldNameMap = {
    "engine_number": "Engine Number",
    "chassis_number": "Chassis Number",
    "registration_number": "Registration Number",
    "resgistration_date": "Registration Date",
    "state": "State",
    "registration_transport_office": "Registration Transport Office",
    "current_owner_name": "Current Owner Name",
    "registration_expiry_date": "Registration Expiry Date",
    "vehicle_life": "Vehicle Life",
    "vehicle_body": "Vehicle Body",
    "vehicle_age": "Vehicle Age",
    "manufacturing_year": "Manufacturing year",
    "road_tax_type": "Road Tax Type",
    "number_of_previous_owners": "Number Of Previous Owners",
    "vehicle_type": "Vehicle Type",
    "vehicle_manufacturer": "Vehicle Manufacturer",
    "vehicle_model": "Vehicle Model",
    "vehicle_variant": "Vehicle Variant",
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return 'tab_form_main_data_two_entries';
      case 3:
        return 'tab_form_main_data_three_entries';
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return '';
    }
  };

  const deleteRecord = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteExistingVehicleDetailsByIdService(selectedRecord);
      if (response.status === 200 && response.success === true) {
        message.success("Existing loan details deleted successfully");
        toggleRefreshTableData();
        setSelectedRecord(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              // name={selectedPerson  `${selectedPerson}'s Existing Vehicle Details`}
              name={selectedPerson ? `${selectedPerson?.customer_name}'s  Existing Vehicle Details` : ` ${selectedPerson?.customer_name}'s Existing Vehicle Details`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <ExistingVehicleDetailsForm
            refreshData={toggleRefreshTableData}
            id={selectedCustomer}
            customer_id={selectedCustomerId}
            open={open}
            closeForm={onClose}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : exstingVehicleData && exstingVehicleData?.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">{Heading1[0]}</th>
                  {exstingVehicleData?.results?.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            <img src={verify} alt="group" style={{ marginLeft: "5px" }} />

                          </span>
                        </div>
                        <div
                          className="tab_form_sub_edit"
                          onClick={() => {
                            showDrawer();
                            setSelectedCustomerId(person)
                            setSelectedPerson(person)
                          }}
                        >
                          <img src={add} alt="Add Button" />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {exstingVehicleData && [...Array(exstingVehicleData?.max_count)]?.map((_, rowIndex) => (

                  Heading1?.slice(1)?.map((header, colIndex) => (
                    <tr
                      key={rowIndex * 100 + colIndex}
                      className={
                        header === "Existing Vehicle Details" ? "investment-row" : header === "PD Entries" ? "investment-pd-row " : ""
                      }
                    >
                      <td className="tab_form_left_heading">{header === "Existing Vehicle Details" ? `Existing Vehicle Details ${rowIndex + 1}` : header}</td>

                      {exstingVehicleData?.results?.map((person, colIndex) => (
                        <td
                          className={`tab_form_main_data_table ${getTableColumnClass(exstingVehicleData.results.length)}`}
                        >
                          {person?.existing_vehicle_details && person?.existing_vehicle_details[rowIndex] ? (
                            <>

                              {

                                header === "Engine Number"
                                  ? person?.existing_vehicle_details?.[rowIndex]?.engine_number || '-'

                                  : header === "Chassis Number"
                                    ? person?.existing_vehicle_details?.[rowIndex]?.chassis_number || '-'



                                    : header === "Registration Number"
                                      ? person?.existing_vehicle_details?.[rowIndex]?.registration_number || '-'

                                      : header === "Registration Date"
                                        ? person?.existing_vehicle_details?.[rowIndex]?.resgistration_date || '-'

                                        : header === "State"
                                          ? person?.existing_vehicle_details?.[rowIndex]?.state?.name || '-'

                                          : header === "Registration Transport Office"
                                            ? person?.existing_vehicle_details?.[rowIndex]?.registration_transport_office?.name || '-'

                                            : header === "Current Owner Name"
                                              ? person?.existing_vehicle_details?.[rowIndex]?.current_owner_name || '-'

                                              : header === "Registration Expiry Date"
                                                ? person?.existing_vehicle_details?.[rowIndex]?.registration_expiry_date || '-'

                                                : header === "Vehicle Life"
                                                  ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_life || '-'

                                                  : header === "Vehicle Body"
                                                    ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_body?.name || '-'

                                                    : header === "Vehicle Age"
                                                      ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_age || '-'

                                                      : header === "Manufacturing year"
                                                        ? person?.existing_vehicle_details?.[rowIndex]?.manufacturing_year || '-'

                                                        : header === "Road Tax Type"
                                                          ? person?.existing_vehicle_details?.[rowIndex]?.road_tax_type || '-'

                                                          : header === "Number Of Previous Owners"
                                                            ? person?.existing_vehicle_details?.[rowIndex]?.number_of_previous_owners || '-'

                                                            : header === "Vehicle Type"
                                                              ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_type?.name || '-'

                                                              : header === "Vehicle Manufacturer"
                                                                ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_manufacturer?.name || '-'

                                                                : header === "Vehicle Model"
                                                                  ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_model?.name || '-'

                                                                  : header === "Vehicle Variant"
                                                                    ? person?.existing_vehicle_details?.[rowIndex]?.vehicle_variant.name || '-'



                                                                    : ""}
                              {header === `Existing Vehicle Details` && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <div
                                    onClick={() => {
                                      setSelectedRecord(person?.existing_vehicle_details[rowIndex]?.id);
                                      setOpenModal(true);
                                    }}>

                                    <DeleteButton />
                                  </div>
                                  {person?.existing_vehicle_details[rowIndex]?.created_at &&
                                    person?.existing_vehicle_details[rowIndex]?.modified_at &&
                                    isModifiedAfterCreated(
                                      person?.existing_vehicle_details[rowIndex]?.created_at,
                                      person?.existing_vehicle_details[rowIndex]?.modified_at
                                    ) && (
                                      <HistoryTable
                                        id={(person?.existing_vehicle_details[rowIndex]?.id)}
                                        bench_id={"0708c9b364975aea"}
                                        fieldNameMap={fieldNameMap}
                                      />
                                    )}
                                  <ConditionalRender
                                    userPermissions={user_data?.permissions}
                                    requiredPermissions={["MPITDE9"]}
                                  >
                                    {renderEditButton(showDrawer, person?.existing_vehicle_details[rowIndex]?.id, person)}
                                  </ConditionalRender>

                                </div>
                              )}
                            </>
                          ) : (
                            header === "Existing Vehicle Details" ? "" : <span>-</span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRecord();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default ExistingVehicleDetails;






















