import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import {
  getDepartmentListService,
  listUserService,
} from "../services/designationService";

const { Option } = Select;

const DesignationFilterForm = ({
  closeForm,
  setFilterFormData,
  toggleRefreshTableData,
  setActionPerformed,
}) => {
  const [form] = Form.useForm();
  const [allDepartment, setAllDepartment] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);
  const handleSubmit = (values) => {
    // Iterate over form values and update filterFormDepartment
    setFilterFormData((prevDepartment) => ({
      ...prevDepartment,
      filter: true,
    }));
    Object.keys(values).forEach((key) => {
      setFilterFormData((prevDepartment) => ({
        ...prevDepartment,
        [key]: values[key],
      }));
    });
    toggleRefreshTableData();
    closeForm();
    setActionPerformed(true);
  };

  const handleReset = () => {
    form.resetFields();
    setFilterFormData({
      filter: false,
      is_active: null,
      is_verified: null,
      department: [],
      created_by: [],
      modified_by: [],
    });
    form.setFieldValue({
      filter: false,
      is_active: null,
      is_verified: null,
      department: [],
      created_by: [],
      modified_by: [],
    });
    setActionPerformed(true);
  };

  useEffect(() => {
    const fetchAllDepartment = async () => {
      try {
        const response = await getDepartmentListService();
        setAllDepartment(response.data);
      } catch (error) {
        message.error("Problem fetching departments list!");
      }
    };
    const fetchAllUsers = async () => {
      try {
        const response = await listUserService();
        setCreatedBy(response.data);
        setModifiedBy(response.data);
      } catch (error) {
        message.error("Problem fetching users!");
      }
    };
    fetchAllDepartment();
    fetchAllUsers();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        filter: false,
        is_active: null,
        is_verified: null,
        department: [],
        created_by: [],
        modified_by: [],
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="department" label="Department">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allDepartment?.map((department) => {
                return (
                  <Select.Option key={department.id} value={department.id}>
                    {department.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="created_by" label="Created By">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  ? option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {createdBy?.map((created) => {
                return (
                  <Select.Option key={created.id} value={created.id}>
                    {created.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="modified_by" label="Modified By">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  ? option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {modifiedBy?.map((modified) => {
                return (
                  <Select.Option key={modified.id} value={modified.id}>
                    {modified.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={handleReset}>Clear Filter</Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default DesignationFilterForm;
