import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
    createProductService,
    getProductByIdService,
    updateProductByIdService,
    listLoanTypeService
} from "../services/productServices";

const { Option } = Select;

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .required("Code is required")
        .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
        .min(8, "Code must be at least 8 characters")
        .max(8, "Code must be at max 8 characters")
        .label("Code"),
    name: Yup.string()
        .required("Name is required")
        .matches(/^[A-Za-z ]+$/, 'Name can only contain letters and spaces')
        .min(2, "Name must be at least 2 characters")
        .max(128, "Name must be at max 128 characters")
        .label("Name"),
    display_name: Yup.string()
        .required("Display Name is required")
        .matches(/^[A-Za-z ]+$/, 'Display name can only contain letters and spaces')
        .min(2, "Display name must be at least 2 characters")
        .max(128, "Display name must be at max 128 characters")
        .label("Display name"),
    loan_type: Yup.string()
        .required("Loan type is required")
        .min(1, "Loan type is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const ProductForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
    const [displayNameSynced, setDisplayNameSynced] = useState(true);
    const [form] = Form.useForm();
    const [allLoanTypes, setAllLoanTypes] = useState([]);
    const getProductData = async () => {
        if (id) {
            try {
                const response = await getProductByIdService(id);
                const { name, code, display_name, loan_type, is_active, is_verified } =
                    response?.data;
                form.setFieldsValue({
                    name,
                    code,
                    display_name,
                    loan_type: loan_type?.id,
                    is_active,
                    is_verified,
                });
                setDisplayNameSynced(true);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }
    };


    const handleSubmit = async (values) => {
        try {
            if (id) {
                const response = await updateProductByIdService(id, values);
                if ((response.status = 200 && response.success)) {
                    message.success("Product successfully updated");
                    refreshData();
                    closeForm();
                }
            } else {
                const response = await createProductService(values);
                if ((response.status = 201 && response.success)) {
                    message.success("Product successfully created");
                    refreshData();
                    closeForm();
                }
            }
            setDisplayNameSynced(true);
        } catch (error) {
            ;
            message.error(error.response.data.message);
        }
    };
    useEffect(() => {
        const getAllLoanTypes = async () => {
            try {
                const response = await listLoanTypeService();
                setAllLoanTypes(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        getAllLoanTypes();
    }, [form]);

    // Fetch department data when the form is initially opened or for editing
    useEffect(() => {
        if (open) {
            // If it's opened for editing, fetch department data
            getProductData();
        } else {
            form.resetFields();
            setDisplayNameSynced(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleNameChange = (e) => {
        let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
        newValue = capitalizeWords(newValue);
        form.setFieldsValue({ name: newValue });
        if (displayNameSynced) {
            form.setFieldsValue({ display_name: newValue });
        }
    };
    const handleDisplayNameChange = (e) => {
        let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
        newValue = capitalizeWords(newValue);
        form.setFieldsValue({ display_name: newValue });
        if (newValue === "") {
            setDisplayNameSynced(true); // Reset sync state if display_name is cleared
        } else {
            setDisplayNameSynced(false);
        }
    };


    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                name: "",
                display_name: "",
                code: "",
                is_active: true,
                is_verified: true,
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="name" label="Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Name"
                            onChange={handleNameChange}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Display Name"
                            onChange={handleDisplayNameChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                {isEditMode ? (
                    <Col span={12}>
                        <Form.Item name="code" label="Code" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Code" onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }} />
                        </Form.Item>
                    </Col>
                ) : null}
                <Col span={12}>
                    <Form.Item name="loan_type" label="Loan Type" rules={[yupSync]} required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allLoanTypes?.map((loantype) => {
                                return (
                                    <Select.Option key={loantype.id} value={loantype.id}>
                                        {loantype.display_name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="is_active" label="Is Active" required>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="is_verified" label="Is Verified" required>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default ProductForm;
