import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { deleteOccupationByIdService } from "../services/occupationServices";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import OccupationForm from "./OccupationForm";
import OccupationFilterForm from "./OccupationFilterForm";


const Occupation = () => {
  const { Title } = Typography;
  const [occupation, setOccupation] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

    // Filter Occupation
    const [showFilterForm, setShowFilterForm] = useState(false);
    const [filterFormData, setFilterFormData] = useState({
      filter: false,
      is_active: null,
      is_verified: null,
    });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedOccupation(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      width:200,

    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      sorter: true,
      render: (display_name) => {
        return display_name !== null ? display_name : " - ";
      },
      width:200,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      render: (code) => {
        return code !== null ? code : " - ";
      },
    },
   
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by.name;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by.name;
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },
    {
      title: "Actions",
      fixed:"right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => {
                setSelectedOccupation(record);
                showDrawer();
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setSelectedOccupation(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];


  const deleteOccupation = async () => {
    try {
      const response = await deleteOccupationByIdService(selectedOccupation.id);

      if (response.status === 200 && response.success === true) {
        message.success("Occupation deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedOccupation(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
        Occupation
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filters
          </Button>
          <Button
            onClick={showDrawer}
            type="primary"
            icon={<PlusCircleOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            Add Occupation  
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/occupation/"
        tableData={occupation}
        setTableData={setOccupation}
        tableColumns={columns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}

      />
      <Drawer
        title={<Header title={selectedOccupation ? "Edit" : "Add"} onClose={onClose} name="Occupation "/>}
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <OccupationForm
          refreshData={toggleRefreshTableData}
          id={selectedOccupation && selectedOccupation.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedOccupation} 

        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Occupation"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <OccupationFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteOccupation();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedOccupation?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default Occupation;
