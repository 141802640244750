import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getOccupationTypeChoice,
  getPremisesTypeChoice,
  getBusinessNatureChoice,
  getBusinessDesignationChoice,
} from "../../services/getCustomerDetailData";
import { updateOccupationDetails } from "../../services/applicantOvServices";
import * as Yup from "yup";

const OccupationEditForm = ({ customerDetails,closeForm,updateId,refreshData,open }) => {
  const [form] = Form.useForm();
  const [occupationTypeChoice, setOccupationTypeChoice] = useState([]);
  const [businessDesignationChoice, setBusinessDesignationChoice] = useState([]);
  const [BusinessNatureChoice, setBusinessNatureChoice] = useState([]);
  const [premisesTypeChoice, setPremisesTypeChoice] = useState([]);
  const { Option } = Select;

  const validationSchema = Yup.object().shape({
    occupation_type: Yup.string()
    .required("Occupation type is required"),
  person_contacted_at_office: Yup.string()
    .required("Person contacted at office is required"),
  met_person_designation: Yup.string()
    .required("Met person designation is required"),
  customer_designation: Yup.string()
    .required("Customer designation is required"),
    no_of_yrs_in_organization:Yup.string()
    .required("No of years in organization is required"),
  no_of_employee_seen: Yup.string()
    .required("Number of employees seen is required"),
  nature_of_business: Yup.string()
    .required("Nature of business is required"),
  type_of_premises: Yup.string()
    .required("Type of premises is required"),
  area_in_sqft: Yup.string()
    .required("Area in square feet is required"),
  turnover: Yup.string()
    .required("Turnover is required")
     
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getOccupationTypeChoice, setOccupationTypeChoice);
    fetchAllData(getPremisesTypeChoice, setPremisesTypeChoice);
    fetchAllData(getBusinessNatureChoice, setBusinessNatureChoice);
    fetchAllData(getBusinessDesignationChoice, setBusinessDesignationChoice);
  }, []);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");

    form.setFieldsValue({ [field]: newValue });
  };

  const getOccupationData = async () => {
    if (updateId) {
      try {
        const { occupation_type, person_contacted_at_office,met_person_designation,customer_designation,no_of_yrs_in_organization,no_of_employee_seen,nature_of_business,type_of_premises,area_in_sqft ,turnover} =
          customerDetails;
        form.setFieldsValue({
          occupation_type,
          person_contacted_at_office,
          met_person_designation,
          customer_designation,
          no_of_yrs_in_organization,
          no_of_employee_seen,
          nature_of_business,
          type_of_premises,
          area_in_sqft,
          turnover
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (updateId) {
        const response = await updateOccupationDetails(updateId, values);
        if ((response.status = 200 && response.success)) {
          message.success("Occupation Details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (open) {
      getOccupationData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="occupation_type" label="Occupation Type" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {occupationTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="person_contacted_at_office"
              label="Person Contacted At Office"
              rules={[yupSync]} 
              required
            >
              <Input
                placeholder="Please Enter Type Of House"
                onChange={(e) => handleInput(e, "person_contacted_at_office")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="met_person_designation"
              label="Met Person's Designation"
              rules={[yupSync]} 
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {businessDesignationChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="customer_designation"
              label="Applicant's Designation"
              rules={[yupSync]} 
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {businessDesignationChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="no_of_yrs_in_organization"
              label="No.Of Years in Organization"
              rules={[yupSync]} 
              required
            >
              <Input
                type="number"
                placeholder="Please Enter No.Of Years in Organization"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="no_of_employee_seen"
              label="No. Of Employees Seen"
              rules={[yupSync]} 
              required
            >
              <Input
                type="number"
                placeholder="Please Enter No. Of Employees Seen"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="nature_of_business"
              label="Nature of Business"
              rules={[yupSync]} 
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {BusinessNatureChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="type_of_premises"
              label="Type Of Premises & Sq.Ft"
              rules={[yupSync]} 
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {premisesTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="area_in_sqft" label="Area in Sq.ft" rules={[yupSync]}  required>
              <Input type="number" placeholder="Please Enter area in sq.ft" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="turnover" label="Salary Income/Turnover" required>
              <Input
                type="number"
                placeholder="Please Enter Salary Income/Turnover"
              />
            </Form.Item>
          </Col>
        </Row>
        <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      > 
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
      </Form>
    </div>
  );
};

export default OccupationEditForm;
