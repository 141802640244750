import { axiosRequest } from "../../../utils/api/axiosRequest";

export const loginUserService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/userauth/login`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const forgetPasswordService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/userauth/forgot_password`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const resetPasswordService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/userauth/reset-password`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

