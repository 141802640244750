import { Table } from 'antd';
import React from 'react'

const IncomeCashView = ({ nonCashIncomeData, renderEditButton, showDrawer }) => {
    const Noncashcolumns = [
        {
            title: 'SR.NO',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Month & Year',
            dataIndex: 'month_of_salary',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Monthly Net Salary In Cash',
            dataIndex: 'monthly_net_salary_cash',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Rental Income - Cash',
            dataIndex: 'rental_income_cash',
            key: 'rental_income_cash',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Rental Income - Non Cash',
            dataIndex: 'rental_income_non_cash',
            key: 'rental_income_non_cash',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Dividend / Interest',
            dataIndex: 'dividend_or_interest',
            key: 'dividend_or_interest',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Agricultural Income',
            dataIndex: 'agricultural_income',
            key: 'agricultural_income',
            render: (text) => text ? text : '-',
        },
    ];
    return (
        <div className="heading_details_container">
            <div style={{ overflowX: "auto" }}>
                {nonCashIncomeData.map((data) => (
                    <div key={data.id} style={{ marginBottom: "20px" }}>
                        <div className="heading_text">{data.customer?.name}{renderEditButton(showDrawer, data.customer?.id)}</div>

                        <Table
                            className="insurance_table"
                            columns={Noncashcolumns}
                            dataSource={data.income_detail} // Wrap the data in an array to render it in the table
                            pagination={false}
                            rowKey="id"
                            scroll={{ x: 'max-content' }}

                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default IncomeCashView