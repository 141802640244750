import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import baseConfig from "./config/baseConfig";
import { PersistGate } from 'redux-persist/integration/react';
ReactDOM.render(
  <GoogleOAuthProvider clientId={baseConfig.googleClentId}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

reportWebVitals();