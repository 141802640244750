import { axiosRequest } from "../../../utils/api/axiosRequest";

export const postTodo = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/dashboard_lite/get-to-do-list/tasks/",data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }