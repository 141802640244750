import React, { useEffect, useState } from "react";
import edit from "../assets/Editbutton.png";
import "../styles/creaditAnalysisTab.css"
import {
    PlusCircleOutlined,
} from "@ant-design/icons";

import { Space, Button, Table, message, Drawer, Modal } from "antd";
import { GoDotFill } from "react-icons/go";
import "../../../customerTabDetails/style/customerStyle.css";

import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined, } from "@ant-design/icons";
import {
    deleteSanctionConditionById, getSanctionDetailsByLoanId, getDistanceDetailsByLoanId,
    getStrengthsOfTheCaseByLoanId,
    deleteStregnthById, getDecisionAnalysisByLoanId
} from "../services/creditAnalysisServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import Header from "../../../../layout/views/Header";
import SanctionCondition from "./SanctionCondition";
import DistanceDetailsForm from "./DistanceDetailsForm";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CommonDeviation from "../../../../../utils/deviation/views/Deviation";
import DecisionQuestionsForm from "./DecisionQuestionsForm";
import DecisionQuestionAddForm from "./DecisionQuestionAddForm";
import StrenthForm from "./StrenthForm";
import WeaknessForm from "./WeaknessForm";


const CreaditAnalysis = () => {
    const { encrypted_loan_id } = useParams();
    const [refreshTableData, setRefreshTableData] = useState(false);
    const [internalSanctions, setInternalSanctions] = useState([]);
    const [externalSanctions, setExternalSanctions] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDistanceDetails, setOpenDistanceDetails] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedSanction, setSelectedSanction] = useState(null);
    const [sanctionType, setSanctionType] = useState(null);
    const [strengthsData, setStrengthsData] = useState([]);
    const [weaknessData, setWeaknessData] = useState([]);
    const [showEditStrengthForm, setShowEditChequeForm] = useState(false);
    const [showEditWeknessForm, setShowWeaknessForm] = useState(false);
    const [selectedStrengthData, setSelectedStrengthData] = useState(null);
    const [collapseSections, setCollapseSections] = useState(true);
    const [collapsedStrength, setCollapsedStrength] = useState(false);
    const [collapsedWeakness, setCollapsedWeakness] = useState(false);
    const [distanceDetailscollapseSections, setDistanceDetailscollapseSections] = useState(true);
    const [distanceDetails, setDistanceDetails] = useState(true);
    const [decisionAnalysis, setDecisionAnalysis] = useState([]);
    const [showEditQuestionsForm, setShowEditQuestionsForm] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [visible, setVisible] = useState(false);



    console.log("setSelectedQuestion", setSelectedQuestion)

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    const columns = [

        {
            title: 'Condition',
            dataIndex: 'sanction_condition',
            width: 450,
            render: (text) => text || '-'
        },
        {
            title: 'Remark',
            dataIndex: 'sanction_condition_description',
            width: 450,
            render: (text) => text || '-'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 300,
            render: status => {
                if (!status) {
                    return 'No status available';
                } else {
                    return (
                        <>
                            {status === 'Completed' ? (
                                <div className="credit">
                                    <GoDotFill style={{ width: "12px", margin: "-4px 5px 0px 0px" }} />
                                    <span>{status}</span>
                                </div>
                            ) : status === 'Waived' ? (
                                <div className="waived_status_div">
                                    <GoDotFill style={{ width: "12px", margin: "-4px 5px 0px 0px" }} />
                                    <span>{status}</span>
                                </div>
                            ) : (
                                <div className="credit_red" style={{ background: " #FEF3F2" }}>
                                    <GoDotFill style={{ width: "12px", margin: "-4px 5px 0px 0px" }} />
                                    <span>{status}</span>
                                </div>
                            )}

                        </>
                    );
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 300,
            render: (_, record) => {
                // Conditionally render edit and delete options only if status is not 'Completed'
                if (record.status !== 'Completed') {
                    return (
                        <Space size="middle">
                            <EditOutlined
                                style={{ color: "#4880FF" }}
                                onClick={() => {
                                    setSelectedSanction(record);
                                    const sanction_condition_type = record.sanction_condition_type;
                                    showDrawer(sanction_condition_type);
                                }}
                            />
                            <DeleteOutlined
                                style={{ color: "#E5233D" }}
                                onClick={() => {
                                    setSelectedSanction(record);
                                    setOpenModal(true);
                                }}
                            />
                        </Space>
                    );
                } else {
                    return null; // Render nothing if status is 'Completed'
                }
            }
        }
    ];

    const Strenghtcolumns = [
        {
            title: 'SrNo.',
            render: (_, __, index) => index + 1,
            width: "200px"
        },

        {
            title: 'Description',
            dataIndex: 'description',
            width: "500px"

        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "300px",

            render: (_, record) => {
                // Conditionally render edit and delete options only if status is not 'Completed'

                return (
                    <Space size="middle">
                        <EditOutlined
                            style={{ color: "blue" }}
                            onClick={() => {
                                setSelectedStrengthData(record);
                                showStrengthDrawer(); // Open StrenthForm drawer
                            }}
                        />
                        <DeleteOutlined
                            style={{ color: "#E5233D" }}
                            onClick={() => {
                                setSelectedStrengthData(record);
                                setOpenModal(true);
                            }}

                        />
                    </Space>
                );

            }
        },

    ];

    const Weaknesscolumns = [
        {
            title: 'Sr.No',
            render: (_, __, index) => index + 1,
            width: "200px"
        },


        {
            title: 'Description',
            dataIndex: 'description',
            width: "500px"

        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "300px",

            render: (_, record) => {
                // Conditionally render edit and delete options only if status is not 'Completed'

                return (
                    <Space size="middle">
                        <EditOutlined
                            style={{ color: "blue" }}
                            onClick={() => {
                                setSelectedStrengthData(record);
                                showWeaknessDrawer(true); // Open StrenthForm drawer
                            }}
                        />
                        <DeleteOutlined
                            style={{ color: "#E5233D" }}
                            onClick={() => {
                                setSelectedStrengthData(record);
                                setOpenModal(true);
                            }}

                        />
                    </Space>
                );

            }
        },

    ];



    const columns5 = [
        {

            title: 'Sr.No',
            dataIndex: 'no',
            render: (text, record, index) => {
                const obj = {
                    children: index % 2 === 0 ? text : '',
                    props: {},
                };


                if (index % 2 === 0) {
                    obj.props.rowSpan = 2;
                } else {
                    obj.props.rowSpan = 0;
                }

                return obj;
            },
        },

        {
            title: 'Parameters',
            dataIndex: 'parameters',

        },
        {
            title: 'Text',
            dataIndex: 'text',
        },
        {
            title: 'Written By',
            dataIndex: 'written_by',
        },




        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record, index) => {
                const obj = {
                    children: '',
                    props: {},
                };

                if (index % 2 === 0) {
                    obj.props.rowSpan = 2;
                    obj.children = (
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer' }}
                            onClick={() => {
                                handleEditClick(record);
                                setSelectedQuestion(record?.id);

                                console.log("1111", record)
                                console.log("222", record.no)

                            }}
                        >
                            <EditOutlined style={{ color: "#4880FF", fontSize: '16px' }} />
                        </div>
                    );
                } else {
                    obj.props.rowSpan = 0;
                }

                return obj;
            },
        }

    ];

    const showDrawer = (type) => {
        setOpen(true);
        setSanctionType(type);
        setDrawerVisible(true);

    };
    const onClose = () => {
        setOpen(false);
        setSelectedSanction(null);

    };


    const showDistanceDetailsDrawer = () => {
        setOpenDistanceDetails(true);
    };

    const onCloseDistanceDetails = () => {
        setOpenDistanceDetails(false);
    };


    const showDrawer1 = () => {
        setVisible(true);
    };

    const onClose1 = () => {
        setVisible(false);

    };


    const onCloseStrenthForm = () => {
        setShowEditChequeForm(false);
        setSelectedStrengthData(null);
    };

    const showStrengthDrawer = () => {
        setShowEditChequeForm(true);
    };
    const onCloseWeknessForm = () => {
        setShowWeaknessForm(false);
        setSelectedStrengthData(null);
    };

    const showWeaknessDrawer = () => {
        setShowWeaknessForm(true);
    };
    const toggleCollapse1 = () => {
        setCollapsedStrength(!collapsedStrength);
    };
    const toggleCollapse2 = () => {
        setCollapsedWeakness(!collapsedWeakness);
    };



    useEffect(() => {
        // Fetch data when the component mounts
        const fetchData = async () => {
            try {
                const response = await getSanctionDetailsByLoanId(loan_id);
                setInternalSanctions(response.data.Internal);
                setExternalSanctions(response.data.External);
            } catch (error) {
                // setLoading(false);
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        const fetchDistanceDetailsData = async () => {
            try {
                const response = await getDistanceDetailsByLoanId(loan_id);
                setDistanceDetails(response.data);
            } catch (error) {

                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        const decisionAnalysisData = async () => {
            try {
                const response = await getDecisionAnalysisByLoanId(loan_id);
                const data = response.data.map((item, index) => {
                    return [
                        {
                            key: `${index + 1}-question`,
                            no: index + 1,
                            parameters: 'Question',
                            text: item.question,
                            written_by: item.created_by.name,
                            id: item.id
                        },
                        {
                            key: `${index + 1}-answer`,
                            no: '',
                            parameters: 'Answer',
                            text: item.answer,
                            written_by: item.created_by.name,
                            id: item.id
                        }
                    ];
                }).flat();
                setDecisionAnalysis(data);
                console.log("111", setDecisionAnalysis)
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };



        fetchData();
        fetchDistanceDetailsData();
        decisionAnalysisData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTableData]);

    const toggleRefreshTableData = () => {
        setRefreshTableData((prev) => !prev);
    };


    const handleEditClick = (record) => {
        setSelectedRecord(record); // Optional: Set the selected record if needed
        setShowEditQuestionsForm(true); // Open the Drawer
    };

    const onCloseQuestionForm = () => {
        setShowEditQuestionsForm(false); // Close the Drawer
    };

    console.log("111", drawerVisible);
    const deleteSanctionCondition = async () => {
        try {
            const response = await deleteSanctionConditionById(selectedSanction.id);

            if (response.status === 200 && response.success === true) {
                message.success("Sanction Condition deleted successfully");
                setOpenModal(false);
                toggleRefreshTableData();
                setSelectedSanction(null);
            }
        } catch (error) {
            setOpenModal(false);
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };


    useEffect(() => {
        const fetchStrengthsData = async () => {
            try {
                const response = await getStrengthsOfTheCaseByLoanId(loan_id);
                setStrengthsData(response?.data?.strength);
                setWeaknessData(response?.data?.weakness);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        fetchStrengthsData();
    }, [loan_id, refreshTableData]);


    const deleteStrength = async () => {
        try {
            const response = await deleteStregnthById(selectedStrengthData?.id);

            if (response.status === 200 && response.success === true) {
                const deleteMessage = selectedStrengthData?.key_type === "Weakness"
                    ? "Weakness of the case is deleted successfully"
                    : "Strength of the case is deleted successfully";

                message.success(deleteMessage);
                setOpenModal(false);
                toggleRefreshTableData();
                setSelectedStrengthData(null);
            }
        } catch (error) {
            setOpenModal(false);
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };


    return (
        <>
            <Drawer
                title={
                    <Header
                        title={selectedSanction ? "Edit" : "Add"}
                        onClose={onClose}
                        name={`${sanctionType} Sanction condition`}
                    />
                }
                width={800}
                onClose={onClose}
                open={open}
                closable={false}
            >
                <SanctionCondition
                    refreshData={toggleRefreshTableData}
                    id={selectedSanction && selectedSanction.id}
                    open={open}
                    closeForm={onClose}
                    sanction_condition_type={sanctionType}
                />
            </Drawer>
            <Modal
                title="Confirm Delete"
                visible={openModal}
                onOk={() => {
                    setOpenModal(false);
                    deleteSanctionCondition();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p style={{ color: "black", fontSize: "14px" }}>{`Are you sure you want to delete?`}</p>
            </Modal>
            <Drawer
                title={<Header title="Edit" onClose={onCloseStrenthForm} />}

                width={800}
                onClose={onCloseStrenthForm}
                open={showEditStrengthForm}
                closable={false}
            >
                <StrenthForm

                    id={selectedStrengthData && selectedStrengthData?.id}
                    closeForm={onCloseStrenthForm}
                    open={showEditStrengthForm}
                    refreshData={toggleRefreshTableData} />

            </Drawer>
            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    deleteStrength();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>

            <Drawer
                title={<Header title="Edit" onClose={onCloseWeknessForm} />}

                width={800}
                onClose={onCloseWeknessForm}
                open={showEditWeknessForm}
                closable={false}
            >
                <WeaknessForm

                    id={selectedStrengthData && selectedStrengthData?.id}
                    closeForm={onCloseWeknessForm}
                    open={showEditWeknessForm}
                    refreshData={toggleRefreshTableData} />



            </Drawer>
            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    deleteStrength();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>


            <div className="tab_main_container ">


                <div className="heading_details_container" style={{ padding: '20px' }}>
                    <div
                        className="heading_text"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div style={{ fontSize: '18px', fontWeight: 'bold', flex: '1 1 auto' }}>
                            Sanction Condition
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                                marginTop: '10px',
                                flex: '1 1 auto',
                            }}
                        >

                            {collapseSections ? (
                                <UpOutlined
                                    onClick={() => setCollapseSections(!collapseSections)}
                                    style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <DownOutlined
                                    onClick={() => setCollapseSections(!collapseSections)}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                        </div>
                    </div>

                    {collapseSections && (
                        <>
                            <div style={{ marginTop: '20px' }}>


                                <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E6F7FF" }}>
                                    <div className="heading_text">Internal Sanctions</div>
                                    <div>
                                        <div style={{ marginRight: "10px" }}>
                                            <Button
                                                icon={<PlusCircleOutlined />}
                                                style={{
                                                    background: "rgb(39, 102, 144)",
                                                    margin: "10px",
                                                    marginRight: "10px",
                                                    color: "white",
                                                    border: '1px solid rgb(39, 102, 144)'
                                                }}
                                                onClick={() => showDrawer('Internal')}
                                            >
                                                Add Sanction Condition
                                            </Button>

                                        </div>
                                    </div>
                                </div>

                                <div className="table_container" style={{ overflowX: 'auto' }}>
                                    <Table
                                        className="credit_analysis_table"
                                        columns={columns}
                                        dataSource={internalSanctions}
                                        pagination={false}
                                        style={{ color: '#737B7F' }}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    marginTop: '20px',
                                    borderTop: '1px solid #e4e7ec',
                                    paddingTop: '20px',
                                }}
                            >

                                <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E6F7FF" }}>
                                    <div className="heading_text"> External Sanctions</div>
                                    <div>
                                        <div style={{ marginRight: "10px" }}>
                                            <Button
                                                icon={<PlusCircleOutlined />}
                                                style={{
                                                    background: "rgb(39, 102, 144)",
                                                    margin: "10px",
                                                    marginRight: "10px",
                                                    color: "white",
                                                    border: '1px solid rgb(39, 102, 144)'
                                                }}
                                                onClick={() => showDrawer('External')}
                                            >
                                                Add Sanction Condition
                                            </Button>

                                        </div>
                                    </div>
                                </div>

                                <div className="table_container" style={{ overflowX: 'auto' }}>
                                    <Table
                                        className="credit_analysis_table"
                                        columns={columns}
                                        dataSource={externalSanctions}
                                        pagination={false}
                                        style={{ color: '#737B7F' }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>


                <div className="heading_details_container">
                    <CommonDeviation toggleRefreshTableData={toggleRefreshTableData} refreshTableData={refreshTableData} />
                    {/* <div style={{ backgroundColor: "#E6F7FE" }} className=" loan_details_heading">Deviation</div>
                    <div style={{ color: "#737B7F" }} className=" loan_details_heading">Auto Deviations</div>
                    <Table className="insurance_table" columns={columns1} dataSource={data2} pagination={false} />
                    <div style={{ color: "#737B7F" }} className=" loan_details_heading">Manual Deviations</div>
                    <Table className="insurance_table" columns={columns2} dataSource={data3} pagination={false} /> */}
                </div>


                <div className="heading_details_container">


                    <div className="heading_text">
                        <div className=" ">
                            <div className="">
                                Strengths of the Case</div></div>
                        <div style={{ display: "flex" }}>
                            <div
                                style={{ margin: "10px 10px 10px 10px" }}
                                onClick={toggleCollapse1}
                            >
                                {collapsedStrength ? <DownOutlined /> : <UpOutlined />}
                            </div>
                        </div>
                    </div>
                    <div className="table_container">
                        {!collapsedStrength && (

                            <Table
                                className="credit_analysis_table"
                                columns={Strenghtcolumns}
                                dataSource={strengthsData}
                                pagination={false}
                            />
                        )}
                    </div>
                </div>

                <div className="heading_details_container">
                    <div style={{ backgroundColor: "#E6F7FE", display: "flex", justifyContent: "space-between" }}>

                        <div className="">
                            <div className="heading_text">
                                Weakness of the Case</div></div>
                        <div style={{ display: "flex" }}>
                            <div
                                style={{ margin: "10px 10px 10px 10px" }}
                                onClick={toggleCollapse2}
                            >
                                {collapsedWeakness ? <DownOutlined /> : <UpOutlined />}
                            </div>
                        </div>
                    </div>
                    <div className="table_container">
                        {!collapsedWeakness && (
                            <Table
                                className="credit_analysis_table"
                                columns={Weaknesscolumns}
                                dataSource={weaknessData}
                                pagination={false}
                            />
                        )}
                    </div>
                </div>
                <div className="loan_distance_container" >
                    <Drawer
                        title={
                            <Header
                                title="Edit"
                                onClose={onCloseDistanceDetails}
                                name={`Distence Details`}
                            />
                        }
                        width={970}
                        onClose={onCloseDistanceDetails}
                        open={openDistanceDetails}
                        closable={false}
                    >
                        <DistanceDetailsForm
                            refreshData={toggleRefreshTableData}
                            id={loan_id}
                            open={openDistanceDetails}
                            closeForm={onCloseDistanceDetails}
                        />
                    </Drawer>


                    <div style={{ backgroundColor: "#E6F7FE", display: "flex", justifyContent: "space-between" }} >
                        <div className="heading_text">
                            Distance Details</div>
                        <div style={{ display: "flex", gap: "10px", marginRight: "10px" }}>
                            <div className="tab_form_sub_edit" onClick={() => {
                                showDistanceDetailsDrawer();
                                // setSelectedCustomer(id);
                                // setSelectedPerson(person);
                            }}>
                                <img src={edit} alt="Edit Button" />
                            </div>
                            {distanceDetailscollapseSections ? (
                                <UpOutlined onClick={() => setDistanceDetailscollapseSections(!distanceDetailscollapseSections)} />
                            ) : (
                                <DownOutlined onClick={() => setDistanceDetailscollapseSections(!distanceDetailscollapseSections)} />
                            )}

                        </div>
                    </div>

                    {(distanceDetailscollapseSections && (
                        <div style={{ display: "flex" }}>
                            <div className="distance_left_headings">
                                <div className="distance_details_headings">
                                    <div className="distance_details_heading">Distance From One Location To Another</div>
                                </div>
                                <div className="distance_left_heading left_heading">From Branch to Collateral</div>
                                <div className="distance_left_heading left_heading ">From Branch to Business Premises</div>
                                <div className="distance_left_heading  " >From Branch to Current Premises</div>
                                <div className="distance_left_heading">From Current Residence to Collateral</div>
                                <div className="distance_left_heading">From Current Business Premises to Collateral</div>
                            </div>

                            <div className="distance_left_headings">
                                <div className="distance_details_headings">
                                    <div className="distance_details_heading">User Calculated Distance(in KM)</div>
                                </div>
                                <div className="distance_left_heading">{distanceDetails?.user_distance?.from_branch_to_collateral || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.user_distance?.from_branch_to_business_premises || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.user_distance?.from_branch_to_current_premises || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.user_distance?.from_current_residence_to_collateral || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.user_distance?.from_current_business_premises_to_collateral || '-'}</div>
                            </div>

                            <div className="distance_left_headings">
                                <div className="distance_details_headings">
                                    <div className="distance_details_heading">System Calculated Distance (in KM)</div>
                                </div>
                                <div className="distance_left_heading">{distanceDetails?.system_distance?.from_branch_to_collateral || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.system_distance?.from_branch_to_business_premises || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.system_distance?.from_branch_to_current_premises || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.system_distance?.from_current_residence_to_collateral || '-'}</div>
                                <div className="distance_left_heading">{distanceDetails?.system_distance?.from_current_business_premises_to_collateral || '-'}</div>
                            </div>
                        </div>
                    ))}
                </div>



                {/* <div style={{ backgroundColor: "#E6F7FE" }} className=" loan_details_heading">Decision Questions</div> */}
                <div className="heading_details_container" style={{ marginTop: "0px" }}>
                    <div style={{ backgroundColor: "#E6F7FE", display: "flex", justifyContent: "space-between" }} >

                        <div className="heading_text">
                            Decision Questions</div>
                        <div>
                            <Drawer
                                title={
                                    <Header
                                        title="Edit"
                                        onClose={onCloseQuestionForm} />}
                                width={900}
                                onClose={onCloseQuestionForm}
                                open={showEditQuestionsForm} // Use state to control visibility

                                closable={false}>
                                <DecisionQuestionsForm
                                    refreshData={toggleRefreshTableData}
                                    open={showEditQuestionsForm}
                                    closeForm={onCloseQuestionForm}
                                    selectedQuestion={selectedQuestion} // Pass selected question ID to the form
                                    selectedRecord={selectedRecord}
                                />
                            </Drawer>
                            <Drawer
                                title={
                                    <Header
                                        title="Add"
                                        onClose={onClose1}

                                    />
                                }
                                width={900}
                                onClose={onClose1}
                                open={visible}
                                closable={false}
                            >
                                <DecisionQuestionAddForm
                                    refreshData={toggleRefreshTableData}
                                    open={visible}
                                    closeForm={onClose1}
                                    loan_id={loan_id}

                                />
                            </Drawer>

                            <div style={{ marginRight: "10px" }}>


                                <Button
                                    icon={<PlusCircleOutlined />}
                                    style={{

                                        background: "rgb(39, 102, 144)",
                                        margin: "10px",
                                        color: "white",
                                        border: '1px solid rgb(39, 102, 144)'
                                    }}
                                    onClick={showDrawer1}
                                >
                                    Add Decision Question
                                </Button>
                                {collapseSections ? (
                                    <UpOutlined onClick={() => setCollapseSections(!collapseSections)} />
                                )
                                    : (
                                        <DownOutlined onClick={() => setCollapseSections(!collapseSections)} />
                                    )
                                }
                            </div>
                        </div>


                    </div>
                    <div className="table_container">
                        {(collapseSections && (
                            <>
                                <Table className="credit_analysis_table" columns={columns5} dataSource={decisionAnalysis} pagination={false} />
                            </>
                        ))}
                    </div>
                </div>


            </div >

        </>
    );
};

export default CreaditAnalysis;
