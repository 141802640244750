import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, message, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import * as Yup from "yup";

import {
  createUserPersonalDetailsService,
  getUserPersonalDetailsService,
  updateUserPersonalDetailsService,
  checkMobileNumberService,
  checkMobileEmailService,
} from "../services/userService";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d{10}.*$/, "Invalid mobile number")
    .label("Mobile"),
  employee_code: Yup.string()
    .required("Employee Code is required")
    .min(2, "Employee Code must be at least 2 characters")
    .max(8, "Employee Code must be at max 8 characters")
    .label("Employee Code"),
  code: Yup.string()
  .required("Code is required")
  .length(8, "Code must be exactly 8 characters")
  .matches(/^[A-Z0-9]*$/, "Code must contain only capital letters and numbers")
  .label("Code"),
  country_code: Yup.string()
    .required("Code is required")
    .min(2, "Code must be at least 2 characters")
    .max(16, "Code must be at max 16 characters")
    .label("Code"),
  email: Yup.string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address!"
    )
    .label("Email"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at max 16 characters")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character"
    )
    .label("Password"),
    password2: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .label("Confirm Password"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const CustomUploadButton = ({ loading, imageUrl, isEditMode }) => (
  <div>
    {loading ? (
      <LoadingOutlined />
    ) : imageUrl ? (
      <img
        src={imageUrl}
        alt="avatar"
        style={{
          marginTop: "5px",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    ) : !isEditMode ? (
      <>
        <PlusOutlined />
        <div>Upload Image</div>
      </>
    ) : (
      <>
        <PlusOutlined />
        <div>Upload Image</div>
      </>
    )}
  </div>
);

const PersonalDetailsForm = ({
  id,
  open,
  onCancel,
  onNext,
  closeDrawer,
  setId,
  formCompleted,
  isEditMode,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const beforeUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    setImageFile(file);
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    }
  };

  const handleCancel = () => {
    onCancel();
    closeDrawer();
  };

  const getUserData = async () => {
    if (id) {
      try {
        const response = await getUserPersonalDetailsService(id);
        const {
          name,
          email,
          mobile,
          employee_code,
          code,
          country_code,
          password,
          password2,
          profile_img,
        } = response?.data;

        form.setFieldsValue({
          name,
          email,
          mobile,
          employee_code,
          code,
          country_code,
          password,
          password2,
          profile_img,
        });
        if (profile_img) {
          setImageUrl(profile_img);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const responseEmail = await checkMobileEmailService(values.email, id);
      const responseMobile = await checkMobileNumberService(values.mobile, id);

      if (!responseEmail.success || !responseMobile.success) {
        if (!responseEmail.success) {
          form.setFields([
            {
              name: "email",
              errors: ["Email already taken"],
            },
          ]);
        }

        if (!responseMobile.success) {
          form.setFields([
            {
              name: "mobile",
              errors: ["Mobile Number already taken"],
            },
          ]);
        }
        return;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("mobile", values.mobile);
      // formData.append("email", values.email);
      // formData.append("password", values.password);
      // formData.append("password2", values.password2);
      formData.append("country_code", values.country_code);
      formData.append("employee_code", values.employee_code);
      formData.append("code", values.code);
      if (imageFile) {
        formData.append("profile_img", imageFile, values.profile_img);
      }
      if (!id) {
        // Append email, password, and confirm password only when creating a new user
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("password2", values.password2);
      }
      if (id) {
        const response = await updateUserPersonalDetailsService(id, formData);
        if (response.status === 200 && response.success) {
          message.success("User Personal Details Successfully Updated");
          onNext();
        }
      } else {
        const response = await createUserPersonalDetailsService(formData);
        if (response.status === 201 && response.success) {
          setId(response?.data?.id);
          message.success("User Successfully created");
          formCompleted(true);
          onNext();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }finally{  
      setLoading(false)
    }

  };

  useEffect(() => {
    if (open) {
      getUserData();
    } else {
      form.resetFields();
      setImageUrl(null);
      setImageFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  const handleValuesChange = async (changedValues, allValues) => {
    const validationPromises = Object.keys(changedValues).map(async (field) => {
      try {
        await validationSchema.validateAt(field, {
          [field]: changedValues[field],
        });
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      } catch (error) {
        form.setFields([
          {
            name: field,
            errors: [error.message],
          },
        ]);
      }
    });
    await Promise.all(validationPromises);
  };

  const handleMobileChange = async (e) => {
    const newMobileValue = e.target.value;
    const numericValue = newMobileValue.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 10);
    form.setFieldsValue({ mobile: truncatedValue });

    if (newMobileValue.length === 10) {
      try {
        await validationSchema.validateAt("mobile", { mobile: numericValue });
        const response = await checkMobileNumberService(numericValue, id);

        if (response.success) {
          form.setFields([
            {
              name: "mobile",
              errors: [],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "mobile",
              errors: [response.message],
            },
          ]);
        }
      } catch (error) {
        form.setFields([
          {
            name: "mobile",
            errors: [error.message],
          },
        ]);
      }
    }
  };

  const handleEmailChange = async (e) => {
    const newEmailValue = e.target.value;

    try {
      await validationSchema.validateAt("email", { email: newEmailValue });
      const response = await checkMobileEmailService(newEmailValue, id);

      form.setFieldsValue({ email: newEmailValue, user_id: id });

      if (response.success) {
        form.setFields([
          {
            name: "email",
            errors: [],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "email",
            errors: ["Email already taken"],
          },
        ]);
      }
    } catch (error) {
      form.setFields([
        {
          name: "email",
          errors: [error.message],
        },
      ]);
    }
  };

  const handleEmailChangeDebounce = debounce(handleEmailChange, 500);

  const handleAlphanumericChange = (fieldName) => (e) => {
    const inputValue = e.target.value;
    const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
    form.setFieldsValue({ [fieldName]: alphanumericValue });
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
    form.setFieldsValue({ name: alphabeticValue });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={{
          name: "",
          mobile: "",
          email: "",
          password: "",
          password2: "",
          country_code: "+91",
          employee_code: "",
          code: "",
          profile_img: "",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Profile Image">
              <Upload
                name="profile_img"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                <CustomUploadButton
                  loading={loading}
                  imageUrl={imageUrl}
                  isEditMode={isEditMode}
                />
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={<span>Name</span>}
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Name"
                onChange={handleNameChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span>Country Code and Mobile </span>} required>
              <Input.Group compact>
                <Form.Item name="country_code" noStyle>
                  <Input
                    style={{
                      width: "20%",
                      textAlign: "center",
                      background: "#f1f1f1",
                    }}
                  />
                </Form.Item>
                <Form.Item name="mobile" noStyle rules={[yupSync]}>
                  <Input
                    style={{ width: "80%" }}
                    placeholder="Enter Mobile Number"
                    onChange={handleMobileChange}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label={<span>Email</span>}
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Email"
                onChange={handleEmailChangeDebounce}
                disabled={!!id}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="employee_code"
              label={<span>Employee Code</span>}
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Employee Code"
                onChange={handleAlphanumericChange("employee_code")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="code"
              label={<span>Code</span>}
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Code"
                onChange={handleAlphanumericChange("code")}
              />
            </Form.Item>
          </Col>
        </Row>
        {!id && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                required
                rules={[yupSync]}
              >
                <Input.Password placeholder="Please Enter Password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password2"
                label="Confirm Password"
                required
                rules={[
                  { required: true, message: "Confirm Password is required!" },
                  {
                    validator: (_, value) =>
                      value === form.getFieldValue("password")
                        ? Promise.resolve()
                        : Promise.reject("Passwords must match"),
                  },
                ]}
              >
                <Input.Password placeholder="Please Enter Confirm Password" />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Space
          direction="Horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Next
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default PersonalDetailsForm;
