import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Form,
  Input,
  Row,
  Space,
  Upload,
  message,
  Select,
} from "antd";
import {
  getLandTypeService,
  getPropertyDetailsById,
  updatePropertyById,
  listConstructionStage,
  listOwnership,
  listAreaCategory,
  listCity,
  listTaluka,
  listDistrict,
  listPincode,
  listState,
  listCountry,
  pincodeCodeService,
  listPropertyType,
  listDocumentType,
  deleteDocumentDetailsByIdService,
} from "../services/propertyDetailsService";
import pdf_image from "../assets/pdf.png";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  area_category: Yup.string()
    .required("Area category is required")
    .label("Area Category"),
  ownership: Yup.string()
    .required("Loan ownership is required")
    .label("Ownership"),
  land_type: Yup.string().required("Land type is required").label("Land Type"),
  number_of_floors: Yup.string()
    .required("Number of floors is required")
    .test(
      "is-number",
      " Number of floors must contain only digits",
      (value) => {
        return /^[0-9]+$/.test(value);
      }
    )
    // .test(
    //   "min-max-range",
    //   "Number of floors must be between 0 to 99",
    //   (value) => {
    //     const numericValue = parseInt(value, 10);
    //     return numericValue >= 0 && numericValue <= 99;
    //   }
    // )
    .matches(/^[0-9]+$/, "Number of floors must contain only digits")
    .label("Number Of Floors"),
  construction_stage: Yup.string()
    .required("Construction stage is required")
    .label("Construction Stage"),

  property_age: Yup.string()
    .required("Property age is required")
    // .test("min-max-range", "Property age must be between 0 to 99", (value) => {
    //   const numericValue = parseInt(value, 10);
    //   return numericValue >= 0 && numericValue <= 99;
    // })

    .test(
      "max-decimals",
      "Property age must have at most two decimal places",
      (value) => {
        if (value.includes(".")) {
          const [, decimals] = value.split(".");
          return decimals.length <= 2;
        }
        return true;
      }
    )
    .test("decimal-range", "Decimal part must be between 0 and 11", (value) => {
      if (value.includes(".")) {
        const [, decimals] = value.split(".");
        return parseInt(decimals, 10) >= 0 && parseInt(decimals, 10) <= 11;
      }
      return true;
    })
    .test("is-number", "Property age must contain only digits", (value) =>
      /^[0-9]+(\.[0-9]{0,2})?$/.test(value)
    )
    // .matches(/^[0-9]+(\.[0-9]{1,2})?$/, "Property age must contain only digits")
    .label("Property Age"),

  address_line_1: Yup.string()
    .required("Address line 1 is required")
    .min(2, "Address line 1 must be at least 2 characters long")
    .max(225, "Address line 1 must be at most 225 characters long")
    .label("Address Line 1"),
  address_line_2: Yup.string()
    .required("Address line 2 is required")
    .min(2, "Address line 2 must be at least 2 characters long")
    .max(225, "Address line 2 must be at most 225 characters long")
    .label("Address line 2"),
  landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be at least 2 characters long")
    .max(225, "Landmark must be at most 225 characters long")
    .label("Landmark"),
  developer_name: Yup.string()
    .required("Developer name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Developer name can only contain alphabetical values & whitespaces"
    ),
  project_name: Yup.string()
    .required("Project name is required")
    .matches(
      /^[A-Za-z0-9\s]+$/,
      "Project name must contain only alphanumeric characters and spaces"
    ),
  property_type: Yup.string().required("Property type is required"),
  // document_number: Yup.string()
  //   .required("Document number is required"),
  // document_type: Yup.string()
  //   .required("Document types is required"),
  city: Yup.string().required("City is required").label("City"),
  taluka: Yup.string().required("Taluka is required").label("Taluka Type"),
  district: Yup.string()
    .required("District is required")
    .label("District Type"),
  pincode: Yup.string().required("Pincode  is required").label("Pincode Type"),
  state: Yup.string().required("State  is required").label("State Type"),
  country: Yup.string().required("Country a is required").label("Country Type"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PropertyDetailsForm = ({
  id,
  updateId,
  open,
  closeForm,
  refreshData,
  images,
}) => {
  const [form] = Form.useForm();
  const [landTypes, setLandTypes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [constructionStage, setConstructionStage] = useState([]);
  const [ownership, setOwnership] = useState([]);
  const [areaCategory, setAreaCategory] = useState([]);
  const [city, setCity] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [district, setDistrict] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState();
  const [propertyType, setPropertyType] = useState();
  const [image, setImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [uploadDisabled, setUploadDisabled] = useState(true);
  // const handleUploadChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);

  //   const newImages = newFileList.map((file) => ({
  //     file_path: URL.createObjectURL(file.originFileObj || file),
  //   }));
  //   setImage(newImages);
  //   // form.resetFields(["upload_documents"]);
  //   // setUploadDisabled(true);
  //   form.resetFields(["document_type"]);
  //   form.resetFields(["document_number"]);

  // };
  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];

    // Assuming 'updatedFileList' is your array and 'form' is your form reference
    updatedFileList = updatedFileList.map((file, index) => {
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          document_type: form.getFieldValue("document_type"),
          document_number: form.getFieldValue("document_number"),
          document_path: file.originFileObj
            ? file.originFileObj.name
            : file.document_path,
        };
      }
      return file;
    });
    setFileList(updatedFileList);
    form.setFieldsValue({
      document_type: undefined,
      document_number: undefined,
      document_path: undefined,
    });
  };
  // const handleRemove = (file) => {
  //   const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
  //   setFileList(updatedFileList);

  //   const updatedImages = updatedFileList.map((file) => ({
  //     file_path: URL.createObjectURL(file.originFileObj || file),
  //   }));
  //   setImage(updatedImages);
  //   if (updatedFileList.length === 0) {
  //     setSelectDisabled(false);
  //   }
  // };
  const confirmDelete = () => {
    
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };
  const deletePropertyPictures = async () => {
    try {
      const response = await deleteDocumentDetailsByIdService(deletePicture.id);

      if (response?.status === 200 && response?.success === true) {
        message?.success("Property Picture deleted successfully");
        setOpenModal(false);
        // toggleRefreshTableData();
        // setSelectedPropertyPicture(null);
        refreshData();
      }
    } catch (error) {
      // setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };
  const getPropertyDetailsData = useCallback(async () => {
    if (id) {
      try {
        const response = await getPropertyDetailsById(id);

        let {
          area_category,
          ownership,
          land_type,
          number_of_floors,
          construction_stage,
          property_age,
          address_line_1,
          address_line_2,
          landmark,
          city,
          taluka,
          district,
          pincode,
          state,
          country,
          property_type,
          project_name,
          developer_name,
        } = response?.data;
        form.setFieldsValue({
          area_category,
          ownership,
          land_type,
          number_of_floors,
          construction_stage,
          property_age,
          address_line_1,
          address_line_2,
          landmark,
          project_name,
          developer_name,
          city: city?.id,
          taluka: taluka?.id,
          district: district?.id,
          pincode: pincode?.id,
          state: state?.id,
          country: country?.id,
          property_type: property_type?.id,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  }, [id, form]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (values) => {
    setLoading(true); 
    try {
      values.number_of_floors = values.number_of_floors.toString();
      values.property_age = values.property_age.toString();
      // const propertyDocumentDetail = fileList.map((file) => ({
      //   document_type: form.getFieldValue('document_type'), // Assuming document_type is selected in the form
      //   document_path: file?.name, // The URL where the file is uploaded, depends on the upload response
      //   document_number: values.document_number, // Replace 'default_number' with appropriate value
      // }));

      const propertyDocumentDetail = await Promise.all(
        fileList.map(async (file) => ({
          document_path: await fileToBase64(file.originFileObj),
          document_number: file.document_number,
          document_type: file.document_type,
        }))
      );
      const { document_type, document_path, document_number, ...restValues } =
        values;
      const payload = {
        // loan_detail: 963, // Assuming this is a static value or you can get it dynamically
        ...restValues,
        property_document_detail: propertyDocumentDetail,
      };
      const response = await updatePropertyById(updateId, payload);
      if ((response.status = 200 && response.success)) {
        message.success("Property Details successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }

  };

  useEffect(() => {
    if (open) {
      getPropertyDetailsData();
    } else {
      form.resetFields();
      setFileList([]);
      setImage([]);
      // setUploadDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const getAllLandType = async () => {
      try {
        const response = await getLandTypeService();
        setLandTypes(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllConstructionStage = async () => {
      try {
        const response = await listConstructionStage();
        setConstructionStage(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllOwnership = async () => {
      try {
        const response = await listOwnership();
        setOwnership(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllAreaCategory = async () => {
      try {
        const response = await listAreaCategory();
        setAreaCategory(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCity = async () => {
      try {
        const response = await listCity();
        setCity(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllTaluka = async () => {
      try {
        const response = await listTaluka();
        setTaluka(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await listDistrict();
        setDistrict(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincode = async () => {
      try {
        const response = await listPincode();
        setPincode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await listState();
        setStates(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllCountry = async () => {
      try {
        const response = await listCountry();
        setCountry(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllPropertyType = async () => {
      try {
        const response = await listPropertyType();
        setPropertyType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllDocumentType = async () => {
      try {
        const response = await listDocumentType();
        setDocumentType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllLandType();
    getAllConstructionStage();
    getAllOwnership();
    getAllAreaCategory();
    getAllCity();
    getAllTaluka();
    getAllDistrict();
    getAllPincode();
    getAllState();
    getAllCountry();
    getAllPropertyType();
    getAllDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInputFields = (e, fieldName) => {};

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          taluka: taluka?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        // area_category: "",
        // ownership: "",
        // land_type: "",
        number_of_floors: "",
        // construction_stage: "",
        property_age: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        project_name: "",
        developer_name: "",
        // city: "",
        // taluka: "",
        // district: "",
        // pincode: "",
        // state: "",
        // country: "",
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="area_category"
            label="Area Category"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {areaCategory.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="ownership"
            label="Ownership"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {ownership.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="number_of_floors"
            label="Number Of Floors"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Number Of Floors"
              onChange={(e) => handleNumberFields(e, "number_of_floors")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="construction_stage"
            label="Construction Stage"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {constructionStage.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="property_age"
            label="Property Age (In Years)"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Property Age"
              onChange={(e) => handleNumberFields(e, "property_age")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="land_type"
            label="Land Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {landTypes?.map((land_type) => (
                <Select.Option key={land_type} value={land_type}>
                  {land_type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>




        <Col span={8}>
          <Form.Item label="Property Type" name="property_type" rules={[yupSync]}
            required>
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {propertyType?.map((propertytype) => (
                <Select.Option key={propertytype?.id} value={propertytype?.id}>
                  {propertytype?.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="project_name"
            label="Name Of Project"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Project Name"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="developer_name"
            label="Developer Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Developer Name"
            />
          </Form.Item>
        </Col>


      
        <Col span={8}>
          <Form.Item
            name="address_line_1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInputFields(e, "address_line_1")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="address_line_2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInputFields(e, "address_line_2")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Landmark"
              onChange={(e) => handleInputFields(e, "landmark")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="developer_name"
            label="Developer Name"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Developer Name" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Property Type"
            name="property_type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {propertyType?.map((propertytype) => (
                <Select.Option key={propertytype?.id} value={propertytype?.id}>
                  {propertytype?.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="project_name"
            label="Name Of Project"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Project Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              showSearch
              placeholder="Please Select"
              onChange={handlePincodeChange}
              allowClear
            >
              {pincode?.map((pincodes, index) => (
                <Option key={pincodes.id} value={pincodes.id}>
                  {pincodes.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {city?.map((locations, index) => (
                <Option key={locations.id} value={locations.id}>
                  {locations.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {taluka?.map((talukas, index) => (
                <Option key={talukas.id} value={talukas.id}>
                  {talukas.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {district?.map((districts, index) => (
                <Option key={districts.id} value={districts.id}>
                  {districts.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {country?.map((countries, index) => (
                <Option key={countries.id} value={countries.id}>
                  {countries.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Form.Item style={{border:'1px solid red'}}>Documents</Form.Item>
      </Row> */}

<div style={{ height: "30px", background: "#F1F1F1" }}>
  <p style={{ marginTop: "10px",marginLeft:"10px" }}>Documents</p>
</div>

        
      
      <Row gutter={16} style={{ marginBottom: "16px" , marginTop:"30px"}}>
        <Col span={8}>
          <Form.Item label="Document Type" name="document_type">
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              // onChange={handleDocumentNameChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {documentType?.map((documenttype) => (
                <Select.Option key={documenttype?.id} value={documenttype?.id}>
                  {documenttype?.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Document Number" name="document_number">
            <Input placeholder="Please Enter Document Number" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="document_path" label="Upload Documents">
            <Upload
              single
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                style={{ width: "176%" }}
              >
                Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ margin: "10px 10px 10px 10px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {image?.map((picture, index) => (
            <div key={index} style={{marginRight:"15px",position: "relative" }}>
              <img
                src={picture.file_path}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
              <div
                style={{
                  // marginLeft: "-19px",
                  color: "#E5233DB2",
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "10px",
                }}
                onClick={() => {
                  setDeleteUploadPicture(picture);
                  setOpenUploadModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="image-gallery">
        {images?.map((picture, index) => (
          <div key={index} className="image-container">
            {picture.file_type === "application/pdf" ? (
              <img
                src={pdf_image}
                width="100px"
                height="100px"
                style={{ border: "none" }}
                alt="PDF Preview"
              />
            ) : (
              <img
                src={picture.document_path}
                alt="Preview"
                style={{ width: "100px", height: "100px" }}
              />
            )}

            <h4 style={{ textAlign: "center", color: "#1890FF" }}>
              {picture.document_type.display_name}
            </h4>
            <div
              className="delete-icon"
              onClick={() => {
                setDeletePicture(picture);
                setOpenModal(true);
              }}
            >
              <DeleteOutlined />
            </div>
          </div>
        ))}
      </div>
      <Modal
        title="Confirm Delete"
        open={openUploadModal}
        onOk={confirmDelete}
        onCancel={() => {
          setOpenUploadModal(false);
          setDeleteUploadPicture(null);
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this upload image?`}</p>
      </Modal>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={deletePropertyPictures}
        onCancel={() => {
          setOpenModal(false);
          setDeletePicture(null);
        }}
        okType="danger"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PropertyDetailsForm;
