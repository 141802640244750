import { Select, message, Spin, Button } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { getListOfLoan } from '../services/otcpddServices';
import OTCPDD from './OTCPDD';
import { decrypt, encrypt } from '../../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams, useNavigate } from 'react-router-dom';
import "../style/style.css"

const AllLoanNumber = ({ id }) => {
  const [loanList, setLoanList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showOTCPDD, setShowOTCPDD] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const { encrypted_loan_id } = useParams();
  const navigate = useNavigate();  // useNavigate for routing

  useEffect(() => {
    let loan_id = null;

    try {
      loan_id = decrypt(encrypted_loan_id);
    
      setSelectedLoanId(loan_id);
    } catch (error) {
      message.error("Invalid Loan ID");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encrypted_loan_id]);

  const handleSearch = async (value) => {
    if (value) {
      setLoading(true);
      try {
        const response = await getListOfLoan(value);
        setLoanList(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    } else {
      setLoanList([]);
    }
  };
  const handleSelect = (loan_id) => {
    setSelectedLoanId(loan_id);
    // No routing or other action here
  };

  const handleButtonClick = () => {
    if (selectedLoanId) {
      const encryptedLoanId = encrypt(selectedLoanId.toString());
     
      navigate(`/otc_pdd/${encryptedLoanId}`);
      setShowOTCPDD(true);
    } else {
      message.warning("Please select a loan number from the dropdown.");
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500),
    []);

  return (
    <div>
      <h2 className='heading_otc'>Application Number</h2>
      <div style={{ display: "flex", flexDirection: "row" }}>

        <div className='application_serch' style={{ marginTop: '' }}>
          <Select
            showSearch
            allowClear
            placeholder="Enter Loan Number"
            onSearch={debouncedHandleSearch}
            onSelect={handleSelect}
            loading={loading}

            filterOption={false}
            notFoundContent={loading ? <Spin size="small" /> : null}
            disabled={id}
          >
            {loanList.map((loan) => (
              <Select.Option key={loan.id} value={loan.id}>
                {loan?.loan_account_number}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <Button style={{
            background: "hsla(204, 57%, 36%, 1)",
            color: "white", marginTop: "", marginLeft: "20px"
          }} onClick={handleButtonClick}>Fetch OTC Data</Button>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        {showOTCPDD && selectedLoanId && (
          <OTCPDD id={selectedLoanId} />
        )}
      </div>
    </div>
  );
};

export default AllLoanNumber;