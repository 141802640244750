import { axiosRequest } from "../../../../../utils/api/axiosRequest";


// import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getFeeDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Send loan_id as a URL parameter
      const response = await axiosRequest.get(`/api/v1/loan/charge-details/fees-details?loan_detail=${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

