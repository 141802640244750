import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Loading from "../../../utils/loading/Loading";
import Finserveidcard from "./Finserveidcard";
import Hfcidcard from "./Hfcidcard";

const IdCardRenderer = () => {
  const [idCardData, setIdCardData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIdCardData({
          data: {
            profile_image: decoded.profile_image,
            department: decoded.department,
            branch: decoded.branch,
            emergency_number: decoded.emergency_number,
            mobile_number: decoded.mobile_number,
            date_of_birth: decoded.date_of_birth,
            organization: decoded.organization,
            employee_id: decoded.employee_id,
            blood_group: decoded.blood_group,
            employee_name: decoded.employee_name,
          },
        });
      } catch (error) {
        console.error("Invalid token:", error);
        // Handle error or redirect to an error page
      }
    } else {
      // Handle error or redirect to an error page
      console.error("No token provided");
    }
  }, [location]);

  if (!idCardData) {
    return <Loading />;
  }

  return (
    <>
      {idCardData && idCardData.data.organization === "Finserve" ? (
        <Finserveidcard data={idCardData} />
      ) : (
        <Hfcidcard data={idCardData} />
      )}
    </>
  );
};

export default IdCardRenderer;
