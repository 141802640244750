import { Col, Form, Radio, Row } from "antd";
import React from "react";

const ICICIQuestion = ({ index, item, insurance_id, text }) => {
  const newQuesOccupation = item?.question.includes(
    "Is your occupation associated with any specific hazard"
  );
  const checkForFemale = item?.question.includes("Only For Female Customers");

  return (
    <div>
      <p className="insurance_personal_details_heading">
        {checkForFemale ? null : index >= 6 ? index + 1 : index + 1}.{" "}
        {item.question}
      </p>

      {/* Main Question Radio */}
      {!(newQuesOccupation || checkForFemale) &&
        ((text === "personal" && index !== 0 && index !== 2) || text !== "personal") && (
          <Form.Item
            name={`radio_${item.id}`}
            rules={[{ required: true, message: "Please select an answer" }]}
            initialValue={
              insurance_id ? (item.answer ? 1 : 0) : null // No selection if insurance_id is false
            }
          >
            <Radio.Group>
              <Row gutter={16}>
                <Col span={12}>
                  <Radio value={1}>Yes</Radio>
                </Col>
                <Col span={12}>
                  <Radio value={0}>No</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        )}
    </div>
  );
};

export default ICICIQuestion;
