import React from "react";
import { Tabs } from "antd";
import { CalendarOutlined, CheckSquareOutlined } from "@ant-design/icons";
import DashboardLiteToDoList from "./DashboardLiteToDoList";
import styles from "../style/MeetingCard.module.css";

const MeetingTaskSwitcher = () => {
  const meetings = [
    {
      title: "Meeting with Prashant",
      startTime: "12:30 PM",
      endTime: "04:36 PM",
      type: "success",
    },
    {
      title: "Important Announcement",
      startTime: "12:30 PM",
      endTime: "04:36 PM",
      type: "info",
    },
    {
      title: "Meeting with Prashant",
      startTime: "02:30 AM",
      endTime: "05:36 PM",
      type: "success",
    },
    {
      title: "Meeting with Prashant",
      startTime: "12:30 PM",
      endTime: "04:36 PM",
      type: "info",
    },
  ];

  return (
    <div className="w-full">
      <Tabs defaultActiveKey="meetings">
        <Tabs.TabPane
          tab={
            <span>
              <CalendarOutlined /> Meetings
            </span>
          }
          key="meetings"
        >
          <div className={`${styles.meetingsContainer} space-y-3 mt-4`}>
            {meetings.map((meeting, index) => (
              <div
                className={`${styles.meetingCard} ${
                  index % 2 === 0
                    ? styles.greenBackground
                    : styles.blueBackground
                }`}
                key={index}
              >
                <div className={styles.meetingTitle}>
                  <span className={styles.statusDot}></span>
                  <span>Meeting with Prashant</span>
                </div>
                <div className={styles.meetingTime}>
                  <span className={styles.clockIcon}>🕒</span>
                  <span>12:30 PM - 04:36 PM</span>
                </div>
              </div>
            ))}
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <CheckSquareOutlined /> To-Do's
            </span>
          }
          key="todos"
        >
            <DashboardLiteToDoList />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default MeetingTaskSwitcher;
