


import {axiosRequest} from "../../../../../utils/api/axiosRequest"


export const getBankAccountDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/bank-account-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBankAccountByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listBankService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/banks/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const listBankBranchService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/bankbranch/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAccountType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/account-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const bankIFCcodeService = (IFSCCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/bankbranch/get-bank-branch-by-ifsc/?ifsc=${IFSCCode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const updateBankAccountDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/bank-account-detail/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createAddBankService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/bank-account-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteBankByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/bank-account-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const createInitiatConsent = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/account-aggregator/redirection?", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getCustomerMobileByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const accountAggregartorStatusService = (trackingId,referenceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/account-aggregator/get-status?trackingId=${trackingId}&referenceId=${referenceId}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};