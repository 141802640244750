import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllPartDisbursementService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;

      let path = `/api/v1/master/PartDisbursement/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        
        path = `/api/v1/master/PartDisbursement/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getPartDisbursementByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/partdisb-construction-stage/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createPartDisbursementService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/master/partdisb-construction-stage/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updatePartDisbursementByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/master/partdisb-construction-stage/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const deletePartDisbursementByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/master/partdisb-construction-stage/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const PropertyStructureListService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/property-structure/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listUserService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get('/api/v1/user/get-user-list');
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };