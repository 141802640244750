import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createLegalInitiationDataService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/initiate-stage/legal-initiate", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


export const getAllAgentService = (code) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/master/agent/agency-agent-list?code=${code}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
  
  export const getAllAgencyService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/master/agency/list`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getAllTransactionService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/loan/choices-api/legal-transcation-choice`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getPropertyDetailsById = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/property-detail/property-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getLoanDetailsById = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getCustomerNameByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
  