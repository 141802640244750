import React, { useEffect, useState } from "react";
import "../styles/cibil.css";
import "../styles/highMarkReport.css";
import { Divider, Table, message } from "antd";
import { getHighMarkReportByIdService } from "../services/highMarkReportService";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import green_rectangle from "../assets/green_rectangle .png";
import red_rectangle from "../assets/red_rectangle.png";
import Chart from "react-google-charts";
import greendot from "../assets/greendot.png";
import yellowdot from "../assets/yellowdot.png";
import reddot from "../assets/reddot.png";
import crifimage from "../assets/crifimage.png";
import layer from "../assets/Layer.png";
import ReactSpeedometer from "react-d3-speedometer";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const appendix_column = [
  {
    title: "Section",
    dataIndex: "section",
    key: "section",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
];
const appendix_data = [
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
];

const data = [
  {
    key: "1",
    type: "Primary Match",
    own: "0",
    other: "0",
    active: "0",
    closed: "0",
    default: "0",
  },
  {
    key: "1",
    type: "Secondary Match ",
    own: "0",
    other: "0",
    active: "0",
    closed: "0",
    default: "0",
  },
];

const options = {
  pieHole: 0.8,
  colors: ["#219653", "#E61D2B", "#E6AE1D"],
  pieSliceText: "none",
  legend: "none",
};
const baroptions = {
  chart: {
    // title: "Company Performance",
    // subtitle: "Sales, Expenses, and Profit: 2014-2017",
  },
  legend: { position: "none" },
  width: "20px",
};
export const HighMarkReport = () => {
  const { encrypted_loan_id } = useParams();
  const [highMarkData, setHighMarkData] = useState(null);

  let customer_id = null;
  try {
    customer_id = decrypt(encrypted_loan_id); 
  } catch (error) { 
    message.error("Invalid Loan ID");
  }


  const chartdata = [
    ["Task", "Number of Accounts"],
    ["Others", parseInt(
      highMarkData?.data?.account_summary
        ?.all_accounts?.primary_secured_number_of_account
    ) +
      parseInt(
        highMarkData?.data?.account_summary
          ?.all_accounts?.primary_unsecured_number_of_account
      ) +
      parseInt(
        highMarkData?.data?.account_summary
          ?.all_accounts?.primary_untagged_number_of_account
      )],
    [
      "Overdue",
      parseInt(
        highMarkData?.data?.account_summary?.all_accounts?.primary_overdue_number_of_account
      ),
    ],
    [
      "Active",
      parseInt(
        highMarkData?.data?.account_summary?.all_accounts?.primary_active_number_of_account
      ),
    ],
  ];

  const bardata = [
    ["Year", "Sales"],
    ["30 Days",  parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_30_days
    ),],
    ["60 Days", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_60_days
    ),],
    ["12 Months",  parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_12_months
    ),],
    ["24 Months", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_24_months
    ),],
    ["Recent", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.recent
    ),],
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getHighMarkReportByIdService(customer_id);
        setHighMarkData(data);
      } catch (error) {
        console.error("Error fetching highmark report data:", error);
      }
    };

    fetchData();
  }, [customer_id]);

  const personalInfoVariations = highMarkData?.data?.personal_info_variation;
  return (
    <>
      <div className="main_cibil_container">
        <div>
          <p className="para_heading_first">CRIF Highmark Report</p>
        </div>
        <div style={{ paddingLeft: "20px" }} className="cibil_heading_second">
          Home / HL0000000164179 /
          <span style={{ color: "#000000D9" }}>CRIF Highmark Report</span>
        </div>
      </div>

      <div style={{ background: "#78C25E" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ height: "160px" }}>
            <ReactSpeedometer
              value={parseInt(highMarkData?.data?.score?.score_value)}
              customSegmentLabels={[
                {
                  text: "Very Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Ok",
                  position: "INSIDE",
                  color: "#555",
                  fontSize: "19px",
                },
                {
                  text: "Good",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Very Good",
                  position: "INSIDE",
                  color: "#555",
                },
              ]}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <img src={crifimage} alt="" />
        </div>
        <Divider
          style={{
            margin: "0",
            backgroundColor: "white",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 10px 0px 10px",
            borderbottom: "20px",
          }}
        >
          <h4>
            <span style={{ color: "#00000073" }}>SCORE NAME:</span>
            {highMarkData?.data?.score?.score_type}
          </h4>
          <h4>
            <span style={{ color: "#00000073" }}>Application ID:</span>
            {highMarkData?.data?.score?.application_number}
          </h4>
          <h4>
            <span style={{ color: "#00000073" }}>DATE OF REQUEST:</span>
            {highMarkData?.data?.score?.created_at}
          </h4>
        </div>
      </div>
      <div className="div1" style={{ marginTop: "10px" }}>
        <div
          style={{
            width: "35%",
            borderRight: "1px solid #F0F2F5",
            marginLeft: "10px",
          }}
        >
          <p className="para" style={{ margin: "10px" }}>
            Account Summary
          </p>
          <div className="summary_para">
            Tip: Current Balance, Disbursed Amount & Instalment Amount is
            considered ONLY for ACTIVE account 
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="third_div_account">
            <div
              style={{
                border: "1px solid #E4E7EC",
                width: "380px",
                height: "257px",
                borderRadius: "8px",
                margin: "12px 0px 0px 12px",
              }}
            >
              <h4
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  margin: "12px 0px 0px 12px",
                }}
              >
                All Accounts
              </h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "126px", margin: "50px 0px 0px 13px" }}>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={greendot} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {" "}
                      Others -
                      {parseInt(
                        highMarkData?.data?.account_summary
                          ?.all_accounts?.primary_secured_number_of_account
                      ) +
                        parseInt(
                          highMarkData?.data?.account_summary
                            ?.all_accounts?.primary_unsecured_number_of_account
                        ) +
                        parseInt(
                          highMarkData?.data?.account_summary
                            ?.all_accounts?.primary_untagged_number_of_account
                        )}
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={reddot} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Overdue -
                      {
                        highMarkData?.data?.account_summary
                          ?.all_accounts?.primary_overdue_number_of_account
                      }
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={yellowdot} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Active -
                      {
                        highMarkData?.data?.account_summary
                          ?.all_accounts?.primary_active_number_of_account
                      }
                    </span>
                  </h4>
                </div>
                <div style={{ width: "250px", height: "100px" }}>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="220px"
                    data={chartdata}
                    options={options}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #E4E7EC",
                width: "400px",
                height: "257px",
                borderRadius: "8px",
                margin: "12px 12px 12px 12px",
              }}
            >
              <h4
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  margin: "12px 0px 0px 12px",
                }}
              >
                Inquiries in last 24 Months (Total - {parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_24_months
    )})
                <span>
                  <img src={layer} alt="" />
                </span>
              </h4>
              <div style={{ margin: "15px 12px 5px 12px " }}>
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="200px"
                  data={bardata}
                  options={baroptions}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "450px",
              display: "flex",
              justifyContent: "space-between",
              margin: "12px 12px 12px 12px",
            }}
          >
            <div
              style={{
                width: "188px",
                height: "104px",
                borderRadius: "3px",
                background: "#5030E514",
              }}
            >
              <div style={{ margin: "8px 8px " }}>
                <h4 style={{ margin: "8px 8px" }}>Amt Disbd/ High Credit</h4>
                <h2 style={{ marginLeft: "8px" }}>
                  ₹
                  {
                    highMarkData?.data?.account_summary
                      ?.all_accounts?.primary_disbursed_amount
                  }
                </h2>
              </div>
            </div>
            <div
              style={{
                width: "188px",
                height: "104px",
                borderRadius: "3px",
                background: "#5030E514",
              }}
            >
              <div style={{ margin: "8px 8px " }}>
                <h4 style={{ margin: "8px 8px" }}>Current Balance</h4>
                <h2 style={{ marginLeft: "8px" }}>
                  ₹
                   {highMarkData?.data?.account_summary?.all_accounts?.primary_current_balance}
                </h2>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "8px 8px" }}>
              <h4 style={{ color: "#667085" }}>
                New Account(s) in last 6 Months:
                <span style={{color:"#000000B2"}}>
                  {
                  highMarkData?.data?.account_summary
                    ?.all_accounts?.new_account_in_last_six_month
                }
                </span>
              </h4>
            </div>
            <div style={{ margin: "8px 8px" }}>
              <h4 style={{ color: "#667085" }}>
                New Delinquent Account(s) in last 6 Months:
                <span style={{color:"#000000B2"}}>
                  {
                  highMarkData?.data?.account_summary
                    ?.all_accounts?.new_delinq_account_in_last_six_month
                }
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div className="div1" style={{ marginTop: "10px" }}>
              <div
                style={{
                  width: "35%",
                  borderRight: "1px solid #F0F2F5",
                  marginLeft: "10px",
                }}
              >
                <p className="para" style={{ margin: "10px" }}>
                  Inquiry Input Information
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className="heagin_first"
                  style={{ borderBottom: "1px solid #F0F2F5" }}
                >
                  <p className="para" style={{ margin: "10px" }}>
                    {highMarkData?.data?.customer_detail?.customer_name}
                  </p>
                </div>

                <div>
                  <div className="third_div_account">
                    <div className="first_div">
                      <div className="lable_first">
                        <span className="span_lable"> Date of Birth </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.date_of_birth}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable">Gender </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.gender}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable"> Father </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.father_name}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable"> Spouse </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.spouse_name}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable"> Entity ID </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.gender}
                        </span>
                      </div>
                    </div>
                    <div className="second_div">
                      <div className="lable_first">
                        <span className="span_lable">Phone Number (S) </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.phone_number}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable">Identification (S) </span>
                        <span className="span_data">
                          :{" "}
                          {highMarkData?.data?.customer_detail?.identification}
                        </span>
                      </div>

                      <div className="lable_first">
                        <span className="span_lable">Email ID (S) </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "0px 10px 10px 10px" }}>
                    <div className="customer_div">
                      <span className="customer_lable"> Current address </span>
                      <span className="customer_address">
                        : {highMarkData?.data?.customer_detail?.current_address}
                      </span>
                    </div>
                    <div className="customer_div">
                      <span className="customer_lable"> Other Address </span>
                      <span className="customer_address">
                        : {highMarkData?.data?.customer_detail?.other_address}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="div1" style={{ marginTop: "10px" }}>
              <div
                style={{
                  width: "35%",
                  borderRight: "1px solid #F0F2F5",
                  marginLeft: "10px",
                }}
              >
                <p className="para" style={{ margin: "16px 0px 16px 10px" }}>
                  CRIF HM Score (S)
                </p>
                <div className="summary_para">
                  Tip: Current Balance, Disbursed Amount & Instalment Amount is
                  considered ONLY for ACTIVE account 
                </div>
                <div style={{ marginTop: "10px" }}>
                  <p
                    style={{
                      marginLeft: "12px",
                      color: "#00000080",
                      margin: "0px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Positive impact on credit score
                    </span>
                  </p>
                  <p
                    style={{
                      marginLeft: "12px",
                      color: "#00000080",
                      margin: "0px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <img src={red_rectangle} alt="red" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Negative impact on credit score
                    </span>
                  </p>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ margin: "12px 0px 16px 10px" }}>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      No/minimal missed payments in recent past
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Normal proportion of outstanding balance to disbursed
                      amount
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Consistent decrease in outstanding balance on self/overall
                      installment loans in the past
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      No/minimal missed payments in recent past
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={red_rectangle} alt="red" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      No/minimal missed payments in recent past
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="div2">
                <div style={{ width: "35%", borderRight: "1px solid #F0F2F5" }}>
                  <p className="para" style={{ margin: "10px" }}>
                    Personal Information - Variations
                  </p>
                </div>

                <div className="main_div_cibil_second" style={{ width: "97%" }}>
                  {personalInfoVariations &&
                    Object.entries(personalInfoVariations).map(
                      ([variationKey, variationValue], index) => (
                        <div key={index}>
                          {variationValue.length > 0 && (
                            <>
                              <div className="personal_information_div">
                                <div className="reported_div">
                                  <span className="variation_key">
                                    {variationKey}
                                  </span>
                                  <span className="variation_key">
                                    {" "}
                                    Reported On
                                  </span>
                                </div>
                                {variationValue.map((item, i) => (
                                  <div key={i} className="reported_div">
                                    <span className="value_lable">
                                      {item.value}
                                    </span>
                                    <span className="reported_data">
                                      {item.reported_date}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>

            <div className="div1" style={{ marginTop: "10px" }}>
              <div style={{ width: "35%", borderRight: "1px solid #F0F2F5" }}>
                <p className="para" style={{ margin: "10px" }}>
                  Account Information
                </p>
              </div>
              <div style={{ width: "97%" }}>
                {highMarkData &&
                  highMarkData?.data?.account_information?.Active?.map(
                    (account, index) => (
                      <div key={index}>
                        <div
                          className="heagin_first"
                          style={{ borderBottom: "1px solid #F0F2F5" }}
                        >
                          <p
                            className="para"
                            style={{ margin: "10px", color: "#BE0000" }}
                          >
                            Active Account {index + 1}
                            <span>
                              <button
                                className={
                                  index === 1 ? "personal_btn" : "consumer_btn"
                                }
                              >
                                {index === 1
                                  ? "Personal Loan"
                                  : "Consumer Loan"}
                              </button>
                            </span>
                          </p>
                        </div>
                        <div className="third_div_account">
                          <div className="first_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Credit Grantor{" "}
                              </span>
                              <span className="span_data">
                                : {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Account Number
                              </span>
                              <span className="span_data">
                                : {account.account_number}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Ovwnership</span>
                              <span className="span_data">
                                : {account.ownership_ind}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Credit Limit</span>
                              <span className="span_data">
                                :{account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Cash Limit</span>
                              <span className="span_data">
                                :{account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">InstlAmt/Freq</span>
                              <span className="span_data">
                                :{account.installment_amount}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Disbursed Date</span>
                              <span className="span_data">
                                : {account.disbursed_date}
                              </span>
                            </div>
                          </div>
                          <div className="second_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                Last Payment Date{" "}
                              </span>
                              <span className="span_data">
                                : {account.last_payment_date}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Closed Date </span>
                              <span className="span_data">
                                {" "}
                                :{account.closed_date}
                              </span>
                            </div>

                            <div className="lable_first">
                              <span className="span_lable">Tenure(month) </span>
                              <span className="span_data">
                                :{account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                Disbd Amt/High Credit{" "}
                              </span>
                              <span className="span_data">
                                {" "}
                                :₹ {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Current Balance
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.current_balanace}{" "}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable"> Last Paid Amt</span>
                              <span className="span_data">
                                {" "}
                                : {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable"> Overdue Amt</span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.credit_guarantor}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          className="heagin_first"
                          style={{ borderBottom: "1px solid #F0F2F5" }}
                        >
                          <p className="para" style={{ margin: "10px" }}>
                            Payment History
                          </p>
                        </div>
                        <div style={{ borderBottom: "1px solid #F0F2F5" }}>
                          <div
                            className="main_div_cibil_second"
                            style={{ width: "100%", margin: "10px" }}
                          >
                            <table style={{ width: "99%" }}>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      position: "relative",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        transform: "translateY(-30%)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          transform: "rotate(25deg)",
                                          display: "inline-block",
                                          marginRight: "4px",
                                        }}
                                      >
                                        Year
                                      </span>
                                      <span
                                        style={{
                                          transform: "rotate(25deg)",
                                          display: "inline-block",
                                          marginRight: "20px",
                                        }}
                                      >
                                        Month
                                      </span>
                                    </div>
                                  </th>

                                  {Object.keys(
                                    account?.combined_payment_history
                                  )?.map(
                                    (year, index) =>
                                      index === 0 &&
                                      Object.keys(
                                        account?.combined_payment_history[year]
                                      )?.map((month) => (
                                        <th
                                          style={{ color: "#667085" }}
                                          key={`${year}-${month}`}
                                        >
                                          {month}
                                        </th>
                                      ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(
                                  account?.combined_payment_history
                                )?.map((year) => (
                                  <tr key={year}>
                                    <td
                                      style={{
                                        backgroundColor: "#FAFAFA",
                                        color: "#667085",
                                      }}
                                    >
                                      {year}
                                    </td>
                                    {Object.keys(
                                      account?.combined_payment_history[year]
                                    )?.map((month) => (
                                      <td
                                        style={{ color: "#667085" }}
                                        key={`${year}-${month}`}
                                      >
                                        {account?.combined_payment_history[
                                          year
                                        ][month] || "-"}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                {highMarkData &&
                  highMarkData?.data?.account_information?.Closed?.map(
                    (account, index) => (
                      <div key={index}>
                        <div
                          className="heagin_first"
                          style={{
                            borderTop: "1px solid #F0F2F5",
                            borderBottom: "1px solid #F0F2F5",
                          }}
                        >
                          <p
                            className="para"
                            style={{ margin: "10px", color: "#415A05" }}
                          >
                            Closed Account 1
                            <span>
                              <button className="commercial_btn">
                                Commercial Vehicle Loan
                              </button>
                            </span>
                          </p>
                        </div>

                        <div className="third_div_account">
                          <div className="first_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Credit Grantor{" "}
                              </span>
                              <span className="span_data">
                                {" "}
                                : {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Account Number
                              </span>
                              <span className="span_data">
                                :{account.account_number}{" "}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Ovwnership</span>
                              <span className="span_data">
                                : {account.ownership_ind}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Credit Limit</span>
                              <span className="span_data">
                                :{account.credit_limit}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Cash Limit</span>
                              <span className="span_data">:</span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">InstlAmt/Freq</span>
                              <span className="span_data">
                                :{account.installment_amount}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Status</span>
                              <span className="span_data">
                                : {account.account_status}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Settlement Amt</span>
                              <span className="span_data">
                                : {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Disbursed Date</span>
                              <span className="span_data">
                                : {account.disbursed_date}
                              </span>
                            </div>
                          </div>
                          <div className="second_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                Last Payment Date{" "}
                              </span>
                              <span className="span_data">
                                : {account.last_payment_date}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Closed Date </span>
                              <span className="span_data">
                                {" "}
                                :{account.closed_date}
                              </span>
                            </div>

                            <div className="lable_first">
                              <span className="span_lable">Tenure(month) </span>
                              <span className="span_data">
                                :{account.original_term}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                Disbd Amt/High Credit{" "}
                              </span>
                              <span className="span_data">
                                {" "}
                                :₹ {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Current Balance
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.current_balanace}{" "}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable"> Overdue Amt</span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.overdue_amount}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                Principal Writeoff Amt
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.principal_write_off_amt}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Total Writeoff Amt
                              </span>
                              <span className="span_data">
                                {" "}
                                :{account.write_off_amount}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          className="heagin_first"
                          style={{ borderBottom: "1px solid #F0F2F5" }}
                        >
                          <p className="para" style={{ margin: "10px" }}>
                            Payment History
                          </p>
                        </div>
                        <div style={{ borderBottom: "1px solid #F0F2F5" }}>
                          <div
                            className="main_div_cibil_second"
                            style={{ width: "100%", margin: "10px" }}
                          >
                            <table style={{ width: "99%" }}>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      position: "relative",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        transform: "translateY(-30%)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          transform: "rotate(25deg)",
                                          display: "inline-block",
                                          marginRight: "4px",
                                        }}
                                      >
                                        Year
                                      </span>
                                      <span
                                        style={{
                                          transform: "rotate(25deg)",
                                          display: "inline-block",
                                          marginRight: "20px",
                                        }}
                                      >
                                        Month
                                      </span>
                                    </div>
                                  </th>
                                  {Object.keys(
                                    account?.combined_payment_history
                                  )?.map(
                                    (year, index) =>
                                      index === 0 &&
                                      Object.keys(
                                        account?.combined_payment_history[year]
                                      )?.map((month) => (
                                        <th
                                          style={{ color: "#667085" }}
                                          key={`${year}-${month}`}
                                        >
                                          {month}
                                        </th>
                                      ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(
                                  account?.combined_payment_history
                                )?.map((year) => (
                                  <tr key={year}>
                                    <td
                                      style={{
                                        backgroundColor: "#FAFAFA",
                                        color: "#667085",
                                      }}
                                    >
                                      {year}
                                    </td>
                                    {Object.keys(
                                      account?.combined_payment_history[year]
                                    )?.map((month) => (
                                      <td
                                        style={{ color: "#667085" }}
                                        key={`${year}-${month}`}
                                      >
                                        {account?.combined_payment_history[
                                          year
                                        ][month] || "-"}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>

            <div className="div1" style={{ marginTop: "10px" }}>
              <div
                style={{
                  width: "35%",
                  borderRight: "1px solid #F0F2F5",
                  marginLeft: "10px",
                }}
              >
                <p className="para" style={{ margin: "10px" }}>
                  Advanced Overlap Report - Summary
                </p>
                <div className="summary_para">
                  Tip: Current Balance, Disbursed Amount & Instalment Amount is
                  considered ONLY for ACTIVE account 
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Table dataSource={data} pagination={false}>
                  <Column title="Type" dataIndex="type" key="type" />
                  <ColumnGroup title="Association">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Account Summary">
                    <Column
                      title={<span className="Column_group_title">Active</span>}
                      dataIndex="active"
                      key="active"
                    />
                    <Column
                      title={<span className="Column_group_title">Closed</span>}
                      dataIndex="closed"
                      key="closed"
                    />
                    <Column
                      title={
                        <span className="Column_group_title">Default</span>
                      }
                      dataIndex="default"
                      key="default"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Disbursed Amount">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Instalment Amount">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Current Balance">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                </Table>
              </div>
            </div>
            <div className="div1" style={{ marginTop: "10px" }}>
              <div
                style={{
                  width: "35%",
                  borderRight: "1px solid #F0F2F5",
                  marginLeft: "10px",
                }}
              >
                <p className="para" style={{ margin: "10px" }}>
                  Group Details - Summary
                </p>
                <div className="summary_para">
                  Tip: Current Balance, Disbursed Amount & Instalment Amount is
                  considered ONLY for ACTIVE account 
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Table dataSource={data} pagination={false}>
                  <Column title="Type" dataIndex="type" key="type" />
                  <ColumnGroup title="Association">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Account Summary">
                    <Column
                      title={<span className="Column_group_title">Active</span>}
                      dataIndex="active"
                      key="active"
                    />
                    <Column
                      title={<span className="Column_group_title">Closed</span>}
                      dataIndex="closed"
                      key="closed"
                    />
                    <Column
                      title={
                        <span className="Column_group_title">Default</span>
                      }
                      dataIndex="default"
                      key="default"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Disbursed Amount">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Instalment Amount">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Current Balance">
                    <Column
                      title={<span className="Column_group_title">Own</span>}
                      dataIndex="own"
                      key="own"
                    />
                    <Column
                      title={<span className="Column_group_title">Other</span>}
                      dataIndex="other"
                      key="other"
                    />
                  </ColumnGroup>
                </Table>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div className="div2">
                <div style={{ width: "35%", borderRight: "1px solid #F0F2F5" }}>
                  <p className="para" style={{ margin: "10px" }}>
                    Appendix
                  </p>
                </div>
                <div className="main_div_cibil_second" style={{ width: "97%" }}>
                  <Table
                    className="highmark_table"
                    columns={appendix_column}
                    dataSource={appendix_data}
                    pagination={false}
                  />
                  <div className="disclaimer_div">
                    Disclaimer: This document contains proprietary information
                    to CRIF High Mark and is prepared by using the proprietary
                    match logic of CRIF High Mark may not be used or disclosed
                    to others, except with the written permission of CRIF High
                    Mark. Any paper copy of this document will be considered
                    uncontrolled. If you are not the intended recipient, you are
                    not authorized to read, print, retain, copy, disseminate,
                    distribute, or use this information or any part thereof.
                    PERFORM score provided in this document is joint work of
                    CRIF SPA (Italy) and CRIF High Mark (India).
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #F0F2F5",
                marginTop: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "16px 12px 16px 12px",
                }}
              >
                <h4>
                  {" "}
                  <span className="italicfont">CHM Ref Number:</span>
                  VAST240325CR261986949
                </h4>
                <h4>
                  <span className="italicfont"> PREPARED FOR:</span>VASTU
                  HOUSING
                </h4>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <h4 className="last_paragraph">Copyrights reserved (c) 2024</h4>
              <h4 className="last_paragraph">
                {" "}
                CRIF High Mark Credit Information Services Pvt. Ltd
              </h4>
              <h4 className="last_paragraph"> Company Confidential Data</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
