import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getAllDesignationService = (page, limit, searchQuery) => {
    return new Promise(async (resolve, reject) => {
        try {
            page = page ? page : 1;
            limit = limit ? limit : 10;
            let path = `/api/v1/master/designations/?page=${page}&limit=${limit}`;
            if (searchQuery.length > 0) {
                path = `/api/v1/master/designations/?search=${searchQuery}&page=${page}&limit=${limit}`;
            }
            const response = await axiosRequest.get(path);
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const getDesignationByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(
                `/api/v1/master/designations/${id}`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const createDesignationService = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post(
                "/api/v1/master/designations/",
                data
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const updateDesignationByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(
                `/api/v1/master/designations/${id}`,
                data
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const deleteDesignationByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.delete(
                `/api/v1/master/designations/${id}`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const getDepartmentListService = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get("/api/v1/master/departments/list");
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const listUserService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get('/api/v1/user/get-user-list');
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
