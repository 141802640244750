import { Button, Checkbox, Col, Form, Row, Select, message, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from "yup";
import "../styles/PDInitiationForm.css";
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import { createPdInitiationDataService, getAllPdType, getAllUserListService, listAllDocumentCategoryService } from '../services/pdService';

const validationSchema = Yup.object().shape({
    pd_type: Yup.string()
        .required("PD Type is required.")
        .min(1, "PD Type is required"),
    assign_to: Yup.string()
        .required("Assign To is required.")
        .min(1, "Assign To is required"),

});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const PDInitiationForm = ({ closeForm }) => {
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [allAgencies, setAllAgencies] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allDocumentCategory, setAllDocumentCategory] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [loading, setLoading] = useState(false);

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const pdTypesResponse = await getAllPdType();
                setAllAgencies(pdTypesResponse.data.choices);

                const usersResponse = await getAllUserListService();
                setAllUsers(usersResponse.data);

                const documentCategoriesResponse = await listAllDocumentCategoryService();
                setAllDocumentCategory(documentCategoriesResponse.data);
            } catch (error) {
                message.error(error?.response?.data?.message || "Failed to fetch data");
            }
        };

        fetchData();
    }, []);

    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        if (checked) {
            setSelectedCategories(allDocumentCategory.map(category => category.id));
        } else {
            setSelectedCategories([]);
        }
    };

    const handleCheckboxChange = (checkedValues) => {
        setSelectedCategories(checkedValues);
        setSelectAll(checkedValues.length === allDocumentCategory.length);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await form.validateFields(); // Ant Design form validation

            if (selectedCategories.length === 0) {
                message.error("At least one checkbox should be selected");
                return;
            }

            const formData = new FormData();
            formData.append('loan_detail', loan_id);
            formData.append('stage_initiate', 'Pd');
            formData.append('pd_type', values.pd_type);
            formData.append('assign_to', values.assign_to);
            formData.append('stage', "MSPDDD4");
            formData.append('stage_status', "MSSTIDA4");
            formData.append('document_categories_array', JSON.stringify(selectedCategories));

            const response = await createPdInitiationDataService(formData);
            if (response.status === 200 && response.success) {
                message.success('PD Initiated Successfully');
                form.resetFields();
                setSelectedCategories([]);
                setSelectAll(false);
                closeForm();
            }
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        form.resetFields();
        setSelectedCategories([]);
        setSelectAll(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeForm]);
    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                // pd_type: "",
                // assign_to: "",
                picture_type: []
            }}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name="pd_type" label="Select PD Type :" rules={[yupSync]} required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allAgencies?.map((agency) => (
                                <Select.Option key={agency.value} value={agency.value}>
                                    {agency.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="assign_to" label="Assign To :"
                        rules={[yupSync]} required
                    >
                        <Select
                            mode="single"
                            placeholder="Please select"
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allUsers?.map((user) => (
                                <Select.Option key={user.id} value={user.id}>
                                    {user.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="picture_type" label="Picture Type :" required rules={[
                        {
                            validator: (_, value) => {
                                if (value && value.length > 0) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('At least one checkbox should be selected');
                            }
                        }
                    ]}>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "5px" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <span className="pd_checkbox_content" style={{ margin: "5px" }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            >
                                                Select All
                                            </Checkbox>
                                        </span>
                                        <Checkbox.Group
                                            style={{ width: '100%' }}
                                            value={selectedCategories}
                                            onChange={handleCheckboxChange}
                                        >
                                            <Row>
                                                {allDocumentCategory?.map(document_category => (
                                                    <Col span={6} key={document_category.id}>
                                                        <div style={{ margin: '5px' }}>
                                                            <Checkbox value={document_category.id}>
                                                                {document_category.display_name}
                                                            </Checkbox>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse", marginTop: "20px" }}
            >
                <Button type="primary" htmlType="submit" loading={loading}>
                    Initiate
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default PDInitiationForm;


