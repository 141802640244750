import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import FiDocumentInputForm from "./FiDocumentInputForm";
import FiUploadInputForm from "./FiUploadInputForm";
import { createFiCombineForm } from "../../services/FIInputFormServices";
import {
  getAccessibilityChoice,
  getAssests,
  getHouseTypeChoice,
  getLivingStandardChoice,
  getMartialStatus,
  getOverallStatus,
  getOwnerShip,
  getQualificationTypeChoice,
  getRelationShip,
  getResidenceChoice,
  getVehicleTypeChoice,
  getSurroundings,
} from "../../services/getCustomerDetailData";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

const FiRVForm = ({
  selectedData,
  onSuccessfulSubmit,
  onSuccess,
  stageId,
  fiAddresses,
}) => {
  const [form] = Form.useForm();
  const [documentUpload, setDocumentUpload] = useState([]);
  const [enteredIdentificationDocuments, setEnteredIdentificationDocuments] =
    useState([]);
  const [enteredNeighborConfirmation, setEnteredNeighborConfirmation] =
    useState([]);
  const [enteredvehiclesData, setEnteredvehiclesData] = useState([]);
  const [residenceChoice, setResidenceChoice] = useState([]);
  const [houseType, setHouseType] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [qualificationTypeChoice, setQualificationTypeChoice] = useState([]);
  const [accessibility, setAccessibility] = useState([]);
  const [LivingStandardChoice, setLivingStandardChoice] = useState([]);
  const [relationShip, setRelationship] = useState([]);
  const [martialStatus, setMartialStatus] = useState([]);
  const [ownerShip, setOwnerShip] = useState([]);
  const [assets, setAssets] = useState([]);
  const [overAllStatus, setOverAllStatus] = useState([]);
  const [surroundings, setSurroundings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRented, setIsRented] = useState(null);
  const [isMarried, setIsMarried] = useState(false);
  
  const { encrypted_loan_id } = useParams();

  const navigate = useNavigate();
  const { Option } = Select;

  const handleMaritalStatusChange = (value) => {
    setIsMarried(value === "Married"); // Update this condition based on your 'maritalStatus' values
  };

  const handleRentedChange = (value) => {
    setIsRented(value);
  };

  const validationSchema = Yup.object().shape({
    residence_ownership: Yup.string().required(
      "Residence ownership is required"
    ),
    house_type: Yup.string().required("Type of house  is required"),
    relationship_with_applicant: Yup.string().required(
      "Relationship with applicant is required"
    ),
    no_of_years_at_current_address: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("No. of years at current address is required")
      .min(0, "Number of years must be positive"),

    no_of_years_in_current_city: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("No. of years in city is required")
      .min(0, "Number of years must be positive"),
    owner_name: Yup.string().required("Houseowner's name is required"),
    rent_amount: Yup.number()
    .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("Rent amount is required")
      .min(0, "Rent amount must be positive"),
    permanent_address: Yup.string()
      .required("Permanent Address is required")
      .max(225, "Permanent Address cannot exceed 225 characters"),
    area_in_sq_feet: Yup.string()
      .required("Residence area in sq. ft. is required")
      .min(0, "Area must be positive"),
    is_rented: Yup.boolean().required("Is rented status is required"),
    no_of_family_members: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("Number of family members is required")
      .min(1, "At least one family member is required"),

    is_working: Yup.boolean().required("Working status is required"),
    has_dependent: Yup.boolean().required("Dependent status is required"),
    date_of_birth: Yup.date()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be in the future"),
    qualification: Yup.string().required("Qualification is required"),
    spouse_name: Yup.string().required("Spouse Name is required"),
    marital_status: Yup.string().required("Marital status is required"),
    is_spouse_working: Yup.boolean().required("Is spouse working is required"),
    living_standard: Yup.string().required("Living standard is required"),
    business_surrounding: Yup.array()
      .of(Yup.string())
      .required("Surrounding is required"),
    assets: Yup.array()
      .of(Yup.string())
      .required("At least one asset must be selected"),
    name_plate: Yup.string().required("Name plate is required"),
    society_board: Yup.string().required("Society board is required"),
    is_negative_area: Yup.boolean().required(
      "Negative area status is required"
    ),
    has_political_contact: Yup.boolean().required(
      "Political contact status is required"
    ),
    accessibility: Yup.string().required("Accessibility is required"),
    overall_status: Yup.string().required("Overall status is required"),
    neighbor_check: Yup.string().required("Neighbour check is required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [
          vehicleResponse,
          houseTypeResponse,
          residenceChoiceResponse,
          qualificationTypeResponse,
          livingStandardResponse,
          relationshipResponse,
          maritalStatusResponse,
          ownershipResponse,
          overallStatusResponse,
          accessibilityResponse,
          assetsResponse,
          SurroundingsResponse,
        ] = await Promise.all([
          getVehicleTypeChoice(),
          getHouseTypeChoice(),
          getResidenceChoice(),
          getQualificationTypeChoice(),
          getLivingStandardChoice(),
          getRelationShip(),
          getMartialStatus(),
          getOwnerShip(),
          getOverallStatus(),
          getAccessibilityChoice(),
          getAssests(),
          getSurroundings(),
        ]);

        // Batch state updates
        setVehicle(vehicleResponse.data);
        setHouseType(houseTypeResponse.data);
        setResidenceChoice(residenceChoiceResponse.data);
        setQualificationTypeChoice(qualificationTypeResponse.data);
        setLivingStandardChoice(livingStandardResponse.data);
        setRelationship(relationshipResponse.data);
        setMartialStatus(maritalStatusResponse.data);
        setOwnerShip(ownershipResponse.data);
        setOverAllStatus(overallStatusResponse.data);
        setAccessibility(accessibilityResponse.data);
        setAssets(assetsResponse.data);
        setSurroundings(SurroundingsResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchAllData();
  }, []);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleSubmit = async (values) => {
    setLoading(true); // Start loading
    try {
      const modifiedIdentificationDocuments =
        enteredIdentificationDocuments.map(({ key, ...rest }) => rest);
      const modifiedNeighborConfirmation = enteredNeighborConfirmation.map(
        ({ key, dated, ...rest }) => rest
      );
      const modifiedvehiclesData = enteredvehiclesData.map(
        ({ key, dated, ...rest }) => rest
      );

      // Use optional chaining and default to an empty array if undefined
      const businessSurroundingString = values.business_surrounding
        ? values.business_surrounding.toString()
        : "";

      const isRented = values.is_rented;
      const rentAmount = isRented ? values.rent_amount : null;
      const permanentAddress = isRented ? values.permanent_address : null;

      // Conditionally remove spouse-related fields if marital status is not married
      const maritalStatus = values.marital_status;

      const newValues = {
        ...values,
        surrounding: businessSurroundingString,
        identification_docs: modifiedIdentificationDocuments?.length
          ? modifiedIdentificationDocuments
          : [],
        neighbor_confirmation: modifiedNeighborConfirmation?.length
          ? modifiedNeighborConfirmation
          : [],
        vehicles: modifiedvehiclesData?.length ? modifiedvehiclesData : [],
        other_docs: documentUpload,
        loan_stage_id: stageId,
        customer_address_detail_id: selectedData["fi address id"],
        date_of_birth: values.date_of_birth.format("YYYY-MM-DD"),
        rent_amount: rentAmount, // Assign rent_amount based on is_rented
        permanent_address: permanentAddress, // Assign permanent_address based on is_rented
      };
      // Remove spouse_name and is_spouse_working fields if marital status is not married
      if (maritalStatus !== "Married") {
        delete newValues.spouse_name;
        delete newValues.is_spouse_working;
      }

      const response = await createFiCombineForm(newValues);
      if (response.status === 200) {
        message.success("RV Form submitted successfully!");
        if (fiAddresses.length === 1) {
          return navigate(`/loandetails/${encrypted_loan_id}`);
        }
        onSuccessfulSubmit(newValues.id);
        onSuccess();
      } else {
        message.error("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form", error);
      message.error(error?.response?.data.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <div style={{ margin: "20px 20px 10px 20px" }}>
        <div className="property_description_heading">
          <div className="property_description_data">
            Residence Address: {selectedData.full_address}
          </div>
        </div>
      </div>

      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 20px 20px 20px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="residence_ownership"
              label="Residence Ownership Details:"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {residenceChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="house_type"
              label="Type Of House"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {houseType?.map((house) => (
                  <Option value={house}>{house}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div
          className="property_description_heading_two"
          style={{ marginBottom: "10px" }}
        >
          <div className="property_description_data">
            Person Contacted At Residence
          </div>
        </div>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="relationship_with_applicant"
              label="Relationship With Applicant"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {relationShip?.map((relation) => (
                  <Option value={relation.id}>{relation.display_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="no_of_years_at_current_address"
              label="No. Of Years At Current Address"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter No. Of Years At Current Address"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="no_of_years_in_current_city"
              label="No.Of Years In city"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter No.Of Years In city"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="area_in_sq_feet"
              label="Residence Area In Sq. Ft."
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Residence Area In Sq. Ft."
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="owner_name"
              label="Houseowner's Name"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Houseowner's Name"
                onChange={(e) => handleInput(e, "owner_name")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="is_rented"
              label="Is Rented"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                onChange={handleRentedChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {isRented && (
          <>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="rent_amount"
                  label="If Rented, Mention Rent Amount"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    placeholder="Please Enter Mention Rent Amount"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="permanent_address"
                  label="If Rented please mention Permanent Address"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    placeholder="Please Enter Permanent Address"
                    onChange={(e) => handleInput(e, "permanent_address")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <div
          className="property_description_heading_two"
          style={{ marginBottom: "10px" }}
        >
          <div className="property_description_data">Personal Details</div>
        </div>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="no_of_family_members"
              label="Family Members"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Family Members"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="is_working"
              label="Working"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="has_dependent"
              label="Dependent"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="date_of_birth"
              label="Date of Birth"
              rules={[yupSync]}
              required
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="qualification"
              label="Qualification"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {qualificationTypeChoice?.map((status) => (
                  <Option value={status}>{status}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="marital_status"
              label="Marital Status"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleMaritalStatusChange}
              >
                {martialStatus?.map((status) => (
                  <Option value={status}>{status}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {isMarried && (
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="spouse_name"
                label="Spouse Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Spouse Name"
                  onChange={(e) => handleInput(e, "spouse_name")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="is_spouse_working"
                label="Is Spouse Working"
                required
                rules={[yupSync]}
              >
                <Select
                  placeholder="Please Select"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="living_standard"
              label="Living Standard"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {LivingStandardChoice?.map((living) => (
                  <Option value={living}>{living}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="business_surrounding"
              label="Surrounding"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {surroundings?.map((surrounding) => (
                  <Option key={surrounding} value={surrounding}>
                    {surrounding}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <FiDocumentInputForm
          form={form}
          vehicle={vehicle}
          ownerShip={ownerShip}
          enteredIdentificationDocuments={enteredIdentificationDocuments}
          setEnteredIdentificationDocuments={setEnteredIdentificationDocuments}
          enteredNeighborConfirmation={enteredNeighborConfirmation}
          setEnteredNeighborConfirmation={setEnteredNeighborConfirmation}
          enteredvehiclesData={enteredvehiclesData}
          setEnteredvehiclesData={setEnteredvehiclesData}
        />
        <div
          className="property_description_heading_two"
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <div className="property_description_data">Assets Seen</div>
        </div>
        <Col span={12}>
          <Form.Item name="assets" label="Assets" rules={[yupSync]} required>
            <Select
              mode="multiple"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "65%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {assets.map((asset) => (
                <Option value={asset.id}>{asset.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <div
          className="property_description_heading_two"
          style={{ marginBottom: "10px" }}
        >
          <div className="property_description_data">Others</div>
        </div>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="name_plate"
              label="Name Plate"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Name Plate"
                onChange={(e) => handleInput(e, "name_plate")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="society_board"
              label="Society Board"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Society Board"
                onChange={(e) => handleInput(e, "society_board")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="is_negative_area"
              label="Negative Area"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="has_political_contact"
              label="Political Contacts"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="accessibility"
              label="Accessibility"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {accessibility?.map((access) => (
                  <Option value={access}>{access}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div
          className="property_description_heading_two"
          style={{ marginBottom: "10px" }}
        >
          <div className="property_description_data">Conclusion</div>
        </div>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="overall_status"
              label="Overall Status"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {overAllStatus?.choices?.map((overall) => (
                  <Option value={overall.value}>{overall.value}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="neighbor_check"
              label="Neighbour Check"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Neighbour Check"
                onChange={(e) => handleInput(e, "neighbor_check")}
              />
            </Form.Item>
          </Col>
        </Row>

        <FiUploadInputForm form={form} setDocumentUpload={setDocumentUpload} />

        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Space>
      </Form>
    </>
  );
};

export default FiRVForm;
