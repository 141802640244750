import { Form, Row, Col, message, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { listAddressType } from '../services/addressDetailsServices';
import OfficeForm from './OfficeForm';

const validationSchema = Yup.object().shape({
    address_type: Yup.string()
        .required("Address type is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};
const AddressType = ({ customer_id, closeForm, open, refreshData }) => {
    const [form] = Form.useForm();
    const [addressTypes, setAddressTypes] = useState([]);
    const [selectedAddressType, setSelectedAddressType] = useState(null);
    useEffect(() => {
        const getAllEmploymentType = async () => {
            try {
                const response = await listAddressType();
                setAddressTypes(response.data);
            }
            catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        getAllEmploymentType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);
    const handleAddressTypeChange = (value) => {
        setSelectedAddressType(value);
    };
    useEffect(() => {
        if (!open) {
            form.resetFields();
            setSelectedAddressType(null);
        }
    }, [open, form]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{

                }}
            >
                <Row gutter={16} style={{ marginBottom: '16px' }}>
                    <Col span={8}>
                        <Form.Item name="address_type" label="Address Type" rules={[yupSync]} required>
                            <Select
                                Add Employment Details
                                mode="single"
                                placeholder="Please select"
                                required={true}
                                allowClear
                                onChange={handleAddressTypeChange}
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }>
                                {addressTypes?.map((Address_type) => {

                                    return (
                                        <Select.Option
                                            key={Address_type.id}
                                            value={Address_type.id}>
                                            {Address_type.display_name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {selectedAddressType && (
                <OfficeForm customer_id={customer_id}
                    closeForm={closeForm}
                    refreshData={refreshData}
                    open={open}
                    selectedAddressType={selectedAddressType}
                />
            )}
        </>
    )
}

export default AddressType