import React, { useEffect, useState } from "react";
import "../styles/miscellaneousDetails.css";
import { Checkbox, Drawer, Table, message } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Header from "../../layout/views/Header";
import ProvisionForm from "./ProvisionForm";
import ConclusionsForm from "./ConclusionsForm";
import ValuerCertificationForm from "./ValuerCertificationForm";
import { getMiscellaneousDetailServiceById } from "../services/miscellaneousDetailsService";
import dot from "../assets/Dot.png";
import reddot from "../assets/reddot.png";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../utils/loading/Loading";
import EditButton from "../../../utils/editButton/EditButton";


const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Assigned To",
    dataIndex: "assigned_to",
    key: "assigned_to",
  },
  {
    title: "Forwarded To",
    dataIndex: "forwarded_to",
    key: "forwarded_to",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Initiated Date",
    dataIndex: "initiated_date",
    key: "initiated_date",
  },
  {
    title: "Completion Date",
    dataIndex: "completion_date",
    key: "completion_date",
  },
];

const data = [
  {
    key: "1",
    id: "1",
    assigned_to: "VASTU LEGAL",
    forwarded_to: "NA",
    status: "",
    initiated_date: "04-Jan-2024 11:18:12 pm",
    completion_date: "-",
  },
  {
    key: "2",
    id: "2",
    assigned_to: "VASTU LEGAL",
    forwarded_to: "NA",
    status: "",
    initiated_date: "04-Jan-2024 11:18:12 pm",
    completion_date: "-",
  },
  {
    key: "3",
    id: "3",
    assigned_to: "VASTU LEGAL",
    forwarded_to: "NA",
    status: "",
    initiated_date: "04-Jan-2024 11:18:12 pm",
    completion_date: "-",
  },
  {
    key: "4",
    id: "4",
    assigned_to: "VASTU LEGAL",
    forwarded_to: "NA",
    status: "",
    initiated_date: "04-Jan-2024 11:18:12 pm",
    completion_date: "-",
  },
  {
    key: "5",
    id: "5",
    assigned_to: "VASTU LEGAL",
    forwarded_to: "NA",
    status: "",
    initiated_date: "04-Jan-2024 11:18:12 pm",
    completion_date: "-",
  },
];
const MiscellaneousDetails = ({ loanId, stageId }) => {
  const [openEditOne, setOpenEditOne] = useState(false);
  const [openEditTwo, setOpenEditTwo] = useState(false);
  const [openEditThree, setOpenEditThree] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [collapsed3, setCollapsed3] = useState(true);
  const [collapsed4, setCollapsed4] = useState(true);
  const [collapsed5, setCollapsed5] = useState(true);
  const [miscellaneousDetails, setMiscellaneousDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };
  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };

  const showDrawerOne = () => {
    setOpenEditOne(true);
  };

  const onCloseOne = () => {
    setOpenEditOne(false);
  };

  const showDrawerTwo = () => {
    setOpenEditTwo(true);
  };

  const onCloseTwo = () => {
    setOpenEditTwo(false);
  };

  const showDrawerThree = () => {
    setOpenEditThree(true);
  };

  const onCloseThree = () => {
    setOpenEditThree(false);
  };
  const renderEditButtonOne = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerOne();
        }}
      >
        <EditButton />
      </div>
    );
  };

  const renderEditButtonTwo = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerTwo();
        }}
      >
        <EditButton />
      </div>
    );
  };

  const renderEditButtonThree = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerThree();
        }}
      >
        <EditButton />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMiscellaneousDetailServiceById(stageId);
        setMiscellaneousDetails(response.data);
        setUpdateId(response.data[0]?.id);
        setIsChecked(response.data[0]?.declaration === 1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  return (
    <div className="Miscellaneous_details_main_container">
      <Drawer
        title={
          <Header title="Edit" onClose={onCloseOne} name="Other Provisions" />
        }
        width={970}
        onClose={onCloseOne}
        open={openEditOne}
        closable={false}
      >
        {loanId && stageId && (
          <ProvisionForm
            open={openEditOne}
            updateId={updateId}
            loanId={loanId}
            stageId={stageId}
            closeForm={onCloseOne}
            refreshData={toggleRefreshData}
          />
        )}
      </Drawer>
      <Drawer
        title={<Header title="Edit" onClose={onCloseTwo} name="Conclusion" />}
        width={970}
        onClose={onCloseTwo}
        open={openEditTwo}
        closable={false}
      >
        {loanId && stageId && (
          <ConclusionsForm
            open={openEditTwo}
            updateId={updateId}
            loanId={loanId}
            stageId={stageId}
            closeForm={onCloseTwo}
            refreshData={toggleRefreshData}
          />
        )}
      </Drawer>

      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseThree}
            name="Valuer Certification"
          />
        }
        width={970}
        onClose={onCloseThree}
        open={openEditThree}
        closable={false}
      >
        {loanId && stageId && (
          <ValuerCertificationForm
            updateId={updateId}
            loanId={loanId}
            stageId={stageId}
            open={openEditThree}
            refreshData={toggleRefreshData}
            closeForm={onCloseThree}
            Checktrue={isChecked} // Pass the checkbox state
            setIsChecked={setIsChecked} // Pass the state updater function
          />
        )}
      </Drawer>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">Other Provisions</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              {renderEditButtonOne()}
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px" }}
              onClick={toggleCollapse1}
            >
              {collapsed1 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed1 && (
          <div className="miscellaneous_details_basic_card_container">
            {loading ? ( // Show loading spinner if data is loading
              <Loading />
            ) : (
              <div className="miscellanious_details_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Who was the first owner of Property?
                  </div>
                  {miscellaneousDetails &&
                    miscellaneousDetails.map((detail, index) => (
                      <div
                        key={index}
                        className="property_description_basic_card_data"
                      >
                        {detail.first_owner}
                      </div>
                    ))}
                </div>

                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Who is the present owner of the property?
                  </div>
                  {miscellaneousDetails &&
                    miscellaneousDetails.map((detail, index) => (
                      <div
                        key={index}
                        className="property_description_basic_card_data"
                      >
                        {detail.present_owner}
                      </div>
                    ))}
                </div>

                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Is the title clear and marketable?{" "}
                  </div>
                  {miscellaneousDetails &&
                    miscellaneousDetails.map((detail, index) => (
                      <div
                        key={index}
                        className="property_description_basic_card_data"
                      >
                        {detail.market_clear === true ? "Yes" : "No"}
                      </div>
                    ))}
                </div>

                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    How the title devolve to current owner by which document
                    (Chain of event must be mention with document no and date)
                  </div>
                  {miscellaneousDetails &&
                    miscellaneousDetails.map((detail, index) => (
                      <div
                        key={index}
                        className="property_description_basic_card_data"
                      >
                        {detail.chain_of_events}
                      </div>
                    ))}
                </div>

                <div>
                  <div className="provision_table_one">
                    <div className="legal_table">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "200%",
                            color: "#00000080",
                          }}
                        >
                          East:
                        </div>
                        {miscellaneousDetails &&
                          miscellaneousDetails.map((detail, index) => (
                            <div
                              key={index}
                              style={{ width: "500%", marginLeft: "1rem" }}
                            >
                              {detail.description_east}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="legal_table">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "200%",
                            color: "#00000080",
                          }}
                        >
                          West:
                        </div>
                        {miscellaneousDetails &&
                          miscellaneousDetails.map((detail, index) => (
                            <div
                              key={index}
                              style={{ width: "500%", marginLeft: "1rem" }}
                            >
                              {detail.description_west}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="legal_table">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "200%",
                            color: "#00000080",
                          }}
                        >
                          North:
                        </div>
                        {miscellaneousDetails &&
                          miscellaneousDetails.map((detail, index) => (
                            <div
                              key={index}
                              style={{ width: "500%", marginLeft: "1rem" }}
                            >
                              {detail.description_north}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="legal_table">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "200%",
                            color: "#00000080",
                          }}
                        >
                          South:
                        </div>
                        {miscellaneousDetails &&
                          miscellaneousDetails.map((detail, index) => (
                            <div
                              key={index}
                              style={{ width: "500%", marginLeft: "1rem" }}
                            >
                              {detail.description_south}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">Conclusions</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              {renderEditButtonTwo()}
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px" }}
              onClick={toggleCollapse2}
            >
              {collapsed2 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed2 && (
          <div className="miscellaneous_details_basic_card_container">
            {loading ? ( // Show loading spinner if data is loading
              <Loading />
            ) : (
              <div className="miscellanious_details_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Overall Status
                  </div>
                  {miscellaneousDetails &&
                    miscellaneousDetails.map((detail, index) => (
                      <div
                        key={index}
                        className={`miscellaneous_status ${
                          detail.overall_status === "Positive"
                            ? "miscellaneous_positive"
                            : "miscellaneous_negative"
                        }`}
                      >
                        <span style={{ margin: "2px 5px" }}>
                          <img
                            src={
                              detail.overall_status === "Positive"
                                ? dot
                                : reddot
                            }
                            alt=""
                            style={
                              detail.overall_status === "Positive"
                                ? {}
                                : { width: "6px" }
                            }
                          />
                        </span>
                        {detail.overall_status}
                      </div>
                    ))}
                </div>

                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Overall Remark
                  </div>
                  {miscellaneousDetails &&
                    miscellaneousDetails.map((detail, index) => (
                      <div
                        key={index}
                        className="property_description_basic_card_data"
                      >
                        {detail.conclusion}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">Advocate Details</div>
          <div style={{ display: "flex" }}>
            <div
              style={{ margin: "10px 10px 10px 10px" }}
              onClick={toggleCollapse3}
            >
              {collapsed3 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed3 && (
          <div className="miscellaneous_details_basic_card_container">
            {loading ? ( // Show loading spinner if data is loading
              <Loading />
            ) : (
              <div className="miscellanious_details_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Advocate Name
                  </div>
                  <div className="property_description_basic_card_data">
                    Pragathi S
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">Valuer Certification</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              {renderEditButtonThree()}
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px" }}
              onClick={toggleCollapse4}
            >
              {collapsed4 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed4 && (
          <div className="miscellaneous_details_basic_card_container">
            {loading ? ( // Show loading spinner if data is loading
              <Loading />
            ) : (
              <div className="miscellanious_details_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Remark
                  </div>
                  <div className="property_description_basic_card_data">
                    <Checkbox
                      style={{ marginRight: "8px" }}
                      checked={isChecked}
                    />
                    I Hereby Declare that The Information in this Report is True
                    and Correct to the best of my Knowledge and Belief.
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">Vendor Stage Details</div>
          <div style={{ display: "flex" }}>
            <div
              style={{ margin: "10px 10px 10px 10px" }}
              onClick={toggleCollapse5}
            >
              {collapsed5 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed5 && (
          <div style={{ border: "1px solid #E4E7EC" }} className="table_container">
            <Table columns={columns} dataSource={data} pagination={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MiscellaneousDetails;
