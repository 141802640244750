
import React, { useEffect, useState } from "react";
import {
  Select,
  TreeSelect,
  Button,
  Space,
  message,
  Col,
  Row,
  Typography,
} from "antd";
import {
  getAllPermissionsFormatted,
  getAllRolesFormatted,
  getAllUserAssignedRolesAndPermissions,
  updateUserPermissionsDetailsServicebyId,
  updateUserRolesDetailsServicebyId,
} from "../services/userService";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const RolesPermissionsForm = ({ open, onCancel, closeDrawer, id, onNext }) => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [flattenedPermissions, setFlattenedPermissions] = useState([]);
   const [prevSelectedRoles, setPrevSelectedRoles] = useState([]);



  const handleCancel = () => {
    onCancel();
  };

  const handleDrawerClose = () => {
    setSelectedPermissions([]);
    setSelectedRoles([]);
    closeDrawer();
  };

  const handleRoleChange = (value) => {
    // Determine added roles
    let changeType;
    const addedRoles = value.filter(
      (role) => !prevSelectedRoles.includes(role)
    );

    // Determine removed roles
    const removedRoles = prevSelectedRoles.filter(
      (role) => !value.includes(role)
    );

    // Update the selected roles and previous selected roles states
    setSelectedRoles(value);
    setPrevSelectedRoles(value);

    // Determine change type
    if (addedRoles.length > 0) {
      changeType = "Add";
    } else if (removedRoles.length > 0) {
      changeType = "Remove";
    } else {
      changeType = null;
    }

    // Separate variable to track flattened permissions
    let updatedFlattenedPermissions = [...flattenedPermissions];

    // Determine change type
    if (addedRoles.length > 0) {
      changeType = "Add";
      // Concatenate permissions for added roles to updatedFlattenedPermissions
      addedRoles.forEach((roleId) => {
        const role = roles.find((r) => r.id === roleId);
        if (role) {
          updatedFlattenedPermissions = [
            ...updatedFlattenedPermissions,
            ...role.permissions,
          ];
        }
      });

      // Extract unique permission IDs
      updatedFlattenedPermissions = [...new Set(updatedFlattenedPermissions)];
      setFlattenedPermissions(updatedFlattenedPermissions);
    }

    if (changeType === "Remove") {
      // Remove permissions for the removed roles from updatedFlattenedPermissions
      removedRoles.forEach((roleId) => {
        const role = roles.find((r) => r.id === roleId);
        if (role) {
          updatedFlattenedPermissions = updatedFlattenedPermissions.filter(
            (permission) => !role.permissions.includes(permission)
          );
          setFlattenedPermissions(updatedFlattenedPermissions);
        }
      });
    }

    const mixedFormatPermissions = [];

    // Create a copy of flattenedPermissions
    const tempArray = [...updatedFlattenedPermissions];

    permissions.forEach((module) => {
      const modulePermissionIds =
        module.module_permission_list || module.permissions.map((p) => p.id);

      // Check if all permissions of the module are present in tempArray
      if (modulePermissionIds.every((id) => tempArray.includes(id))) {
        // All permissions of the module are present
        mixedFormatPermissions.push(module.module_name);

        // Remove the permissions of the module from tempArray
        modulePermissionIds.forEach((id) => {
          const index = tempArray.indexOf(id);
          if (index !== -1) {
            tempArray.splice(index, 1);
          }
        });
      }
    });

    // Add any remaining permissions in tempArray
    mixedFormatPermissions.push(...tempArray);

    setSelectedPermissions(mixedFormatPermissions);
  };

  const handlePermissionChange = (selectedPermissionIds) => {
    setSelectedPermissions(selectedPermissionIds);

    // Code to extract flattened permissions from selected tree array.
    const permissionIds = selectedPermissionIds.map((value) => {
      // If the value is a string, it's a module, so return the module_value array
      if (typeof value === "string") {
        const module = permissions.find(
          (module) => module.module_name === value
        );
        const module_permissions = module.permissions.map(
          (permission) => permission.id
        );
        const uniquePermissions = [...new Set(module_permissions)];
        return uniquePermissions || [];
      }
      // If the value is a number, it's an individual permission, so return it as a single-element array
      else if (typeof value === "number") {
        return [value];
      }
      // If the value is neither string nor number, return an empty array
      else {
        return [];
      }
    });

    // Flatten the array of arrays to get a single array of permission IDs
    const flattenedPermissionIds = [].concat(...permissionIds);
    setFlattenedPermissions(flattenedPermissionIds);
  };

  const renderPermissionTreeNodes = (modules) => {
    return modules.map((module) => (
      <TreeNode
        key={module.module_name}
        title={module.module_name}
        value={module.module_name}
      >
        {module.permissions.map((permission) => (
          <TreeNode
            key={permission.id}
            title={permission.display_name}
            value={permission.id}
          />
        ))}
      </TreeNode>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
       if (id !== null) {
        const permissionsResponse = await getAllPermissionsFormatted();
        const rolesResponse = await getAllRolesFormatted();
       
       
        const userRolesAndPermissionsResponse = await getAllUserAssignedRolesAndPermissions(id);
       
        setPermissions(permissionsResponse.data);
        setRoles(rolesResponse.data);
        
        if (userRolesAndPermissionsResponse.data) {
          setSelectedRoles(userRolesAndPermissionsResponse.data.roles_list);
          setSelectedPermissions(userRolesAndPermissionsResponse.data.permissions_list);
        }
      }
      } catch (error) {
        message.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async () => {
    try {
      let tempArray = [...flattenedPermissions];
      let allRolePermissionsArray = [];
      // Loop over selected roles
      selectedRoles.forEach((selectedRoleId) => {
        // Find the selected role in dummy_data.roles
        const selectedRole = roles.find((role) => role.id === selectedRoleId);

        allRolePermissionsArray = allRolePermissionsArray.concat(
          selectedRole.permissions
        );
      });

      tempArray = tempArray.filter(
        (permission) => !allRolePermissionsArray.includes(permission)
      );

      const roleResponse = await updateUserRolesDetailsServicebyId(
        id,
        selectedRoles
      );
      const PermissionResponse = await updateUserPermissionsDetailsServicebyId(
        id,
        tempArray
      );
      if (roleResponse.status === 200 && PermissionResponse.status === 200) {
        message.success("Roles and Permissions updated successfully!");
        setFlattenedPermissions([]);
      }
      onNext();
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <div>
            <Typography>Roles</Typography>
            <Select
              mode="multiple"
              placeholder="Select Roles"
              style={{ width: "100%" }}
              onChange={handleRoleChange}
              value={selectedRoles}
              showSearch
              allowClear
            >
              {roles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.display_name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Typography>Permissions</Typography>
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              value={selectedPermissions}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Permissions"
              allowClear
              treeDefaultExpandAll
              treeCheckable
              showCheckedStrategy="SHOW_PARENT"
              onChange={handlePermissionChange}
            >
              {renderPermissionTreeNodes(permissions)}
            </TreeSelect>
          </div>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "1rem",
        }}
      >
        <Button type="primary" onClick={handleSubmit}>
          Next
        </Button>
        <Button onClick={handleCancel}>Prev</Button>
        <Button onClick={handleDrawerClose}>Cancel</Button>
      </Space>
    </div>
  );
};

export default RolesPermissionsForm;

