import { axiosRequest } from "../../../utils/api/axiosRequest"

export const getCustomerListByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-combine/get-customer-list-by-loan-stage-id/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getCustomerDetailListByLoanId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-combine/get-customer-details-by-customer-id/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getResidenceOverallStatusByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-customer-detail/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getPersonContactedAtResidenceByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-person-contacted/?fi_customer_id=${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPersonalDetailByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-personal-detail/?fi_customer_id=${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getIdentificationDocumentsByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-document/identification-docs-by-fi-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getIdentificationDocumentsById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-neighbour-confirmation/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getResidenceNeighbourByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-neighbour-confirmation/by-fi-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getResidenceNeighbourDocumentsById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-document/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getVehiclesByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-vehicle-detail/vehicle-by-fi-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getVehicleById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-vehicle-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getAssestsByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-assets/by-fi-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getOthersDetailsByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-other-detail/?fi_customer_id=${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getFiUploadDocumentsByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-document/other-docs-by-fi-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

// OV //

export const getFiBusinessOccupationByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-business-occupation/?fi_customer_id=${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getFiBusinessPremisesByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/fi-business-premises/?fi_customer_id=${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}