import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getTodoList = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get("/api/v1/dashboard_lite/get-to-do-list/tasks/");
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }