import React, { useEffect, useState } from 'react'
import { getDistanceDetailsByLoanId, updateDistanceDetailsService } from '../services/creditAnalysisServices';
import { Button, Form, Input, Space, message } from 'antd';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import * as Yup from "yup";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';

const validationSchema = Yup.object().shape({
    from_branch_to_collateral: Yup.string()
        .matches(/^\d+(\.\d{1,2})?$/, "Please enter numeric value only !")
        .required("This field is required"),
    from_branch_to_business_premises: Yup.string()
        .matches(/^\d+(\.\d{1,2})?$/, "Please enter numeric value only !")
        .required("This field is required"),
    from_branch_to_current_premises: Yup.string()
        .matches(/^\d+(\.\d{1,2})?$/, "Please enter numeric value only !")
        .required("This field is required"),
    from_current_residence_to_collateral: Yup.string()
        .matches(/^\d+(\.\d{1,2})?$/, "Please enter numeric value only !")
        .required("This field is required"),
    from_current_business_premises_to_collateral: Yup.string()
        .matches(/^\d+(\.\d{1,2})?$/, "Please enter numeric value only !")
        .required("This field is required"),
});
const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const DistanceDetailsForm = ({ refreshData, open, closeForm, id }) => {
    const [distanceDetails, setDistanceDetails] = useState(true);
    const { encrypted_loan_id } = useParams();
    const [form] = Form.useForm();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    // useEffect(() => {
    const fetchDistanceDetailsData = async () => {
        try {
            const response = await getDistanceDetailsByLoanId(loan_id);
            setDistanceDetails(response.data);

            const userDistance = response.data?.user_distance || {};

            form.setFieldsValue({
                from_branch_to_collateral: userDistance.from_branch_to_collateral,
                from_branch_to_business_premises: userDistance.from_branch_to_business_premises,
                from_branch_to_current_premises: userDistance.from_branch_to_current_premises,
                from_current_residence_to_collateral: userDistance.from_current_residence_to_collateral,
                from_current_business_premises_to_collateral: userDistance.from_current_business_premises_to_collateral
            });
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    useEffect(() => {
        if (open) {
            fetchDistanceDetailsData();
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    // fetchDistanceDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleSubmit = async (values) => {
        try {
            const payload = {
                ...values,
                loan_detail: parseInt(loan_id, 10),

            };
            const response = await updateDistanceDetailsService(payload);
            if ((response.status = 200 && response.success)) {
                message.success("Distance Details updated successfully");
                refreshData();
                closeForm();
            }
            // message.success('Form submitted successfully');
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };
    return (
        <>
            <Form
                form={form}
                onFinish={handleSubmit}
            >
                <div className="loan_insurance_container">
                    <div style={{ display: "flex" }}>
                        <div className="distance_left_headings">
                            <div className="distance_details_headings">
                                <div className="distance_details_heading">Distance From One Location To Another</div>
                            </div>
                            <div className="distance_left_heading_form">From Branch to Collatera  <span className="required_asterisk">*</span></div>
                            <div className="distance_left_heading_form">From Branch to Business Premises  <span className="required_asterisk">*</span></div>
                            <div className="distance_left_heading_form">From Branch to Current Premises  <span className="required_asterisk">*</span></div>
                            <div className="distance_left_heading_form">From Current Residence to Collateral  <span className="required_asterisk">*</span></div>
                            <div className="distance_left_heading_form_current_business">From Current Business Premises to Collateral  <span className="required_asterisk">*</span></div>
                        </div>

                        <div className="distance_left_headings">
                            <div className="distance_details_headings">
                                <div className="distance_details_heading">User Calculated Distance (in KM)</div>
                            </div>

                            <div className="distance_left_heading_form">
                                <Form.Item name="from_branch_to_collateral" rules={[yupSync]} required>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="distance_left_heading_form">
                                <Form.Item name="from_branch_to_business_premises" rules={[yupSync]} required>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="distance_left_heading_form">
                                <Form.Item name="from_branch_to_current_premises" rules={[yupSync]} required>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="distance_left_heading_form">
                                <Form.Item name="from_current_residence_to_collateral" rules={[yupSync]} required>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="distance_left_heading_form_current_business">
                                <Form.Item name="from_current_business_premises_to_collateral" rules={[yupSync]} required>
                                    <Input />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="distance_left_headings">
                            <div className="distance_details_headings">
                                <div className="distance_details_heading">System Calculated Distance (in KM)</div>
                            </div>
                            <div className="distance_left_heading_form">{distanceDetails?.system_distance?.from_branch_to_collateral || '-'}</div>
                            <div className="distance_left_heading_form">{distanceDetails?.system_distance?.from_branch_to_business_premises || '-'}</div>
                            <div className="distance_left_heading_form">{distanceDetails?.system_distance?.from_branch_to_current_premises || '-'}</div>
                            <div className="distance_left_heading_form">{distanceDetails?.system_distance?.from_current_residence_to_collateral || '-'}</div>
                            <div className="distance_left_heading_form_current_business">{distanceDetails?.system_distance?.from_current_business_premises_to_collateral || '-'}</div>
                        </div>
                    </div>
                </div>
                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse", paddingTop: "20px" }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>
        </>
    )
}

export default DistanceDetailsForm