import React, { useState, useEffect } from "react";
import { Modal, Input, Button, message } from "antd";

const RemarkModal = ({ visible, onConfirm, onCancel, selectedOtcData }) => {
  const [remark, setRemark] = useState("");

  useEffect(() => {
    if (visible) {
      setRemark("");
    }
  }, [visible]);

  const handleConfirm = () => {
    if (remark.trim() === "") {
      message.error("Remark is required");
      return;
    }
    if (!selectedOtcData?.id) {
      message.error("No document selected for deletion");
      return;
    }
    onConfirm(selectedOtcData.id, remark);
  };

  return (
    <Modal
      title="Confirm Delete"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="delete" type="primary" onClick={handleConfirm}>
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this document?</p>
      <Input
        placeholder="Enter remark"
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
      />
    </Modal>
  );
};

export default RemarkModal;
