const routeArray = [
    { stage: 'Legal', status: 'Initiated', route: (loan_id, stage_id) => `/legal-input-form/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Initiated', route: (loan_id, stage_id) => `/technical_valuation_report/${loan_id}/${stage_id}` },
    { stage: 'Legal', status: 'Completed', route: (loan_id, stage_id) => `/legal_valuation/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Completed', route: (loan_id, stage_id) => `/technical_valuation/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Initiated', route: (loan_id, stage_id) => `/rcupost/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Completed', route: (loan_id, stage_id) => `/rcu/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Initiated', route: (loan_id, stage_id) => `/fi_input_form/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Completed', route: (loan_id, stage_id) => `/fi/${loan_id}/${stage_id}` },
    { stage: 'Dedupe', status: 'Completed', route: (loan_id) => `/dedupe/${loan_id}` },
];

export const getActionRoute = (stage, status, encrypted_loan_id, encrypted_stage_id) => {
    const routeObject = routeArray.find(
        (r) => (r.stage === stage && r.status === status)
    );
    return routeObject ? routeObject.route(encrypted_loan_id,encrypted_stage_id ) : `/maintenance`;
};
