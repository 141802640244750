import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Space, Table, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  createDocumentDetailServiceById,
  listDocumentTypeService,
  updateDocumentDetailServiceById,
} from "../services/documentDetailService";
import dayjs from "dayjs";
import * as Yup from "yup";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

const validationSchema = Yup.object().shape({
  document: Yup.string()
    .required("Please enter document")
    .matches(
      /^(?!\s+$)[A-Za-z0-9\s!@#$%^&*()_+=[\]{};:'",.<>?\\|`~/-]+$/,
      "Document cannot contain only spaces"
    )
    .label("Document"),
});

const yupSync = {
  async validator(rule, value) {
    try {
      await validationSchema.validate({ document: value }); // Validate the 'document' field
    } catch (error) {
      throw new Error(error.errors[0]); // Throw the validation error message
    }
  },
};

const ScrutinyAndLegalOpinionForm = ({ legalId, refreshData, open, closeForm, selectedScrutiny}) => {
  const [documentType, setDocumentType] = useState([]);
  const [form] = Form.useForm();

  const fetchDocumentType = async () => {
    try {
      const response = await listDocumentTypeService();
      setDocumentType(response.data.choices);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    if (open) {
      fetchDocumentType();
      if (selectedScrutiny) {
        form.setFieldsValue({
          documents: [
            {
              document: selectedScrutiny.document,
              dated: selectedScrutiny.dated ? dayjs(selectedScrutiny.dated, "DD-MM-YYYY") : null,
              document_type: selectedScrutiny.document_type,
            },
          ],
        });
      } else {
        form.resetFields();
      }
    }
  }, [open, selectedScrutiny, form]);

  const handleSubmit = async (values) => {
    const formattedValues = values.documents.map((doc) => ({
      ...doc,
      dated: doc.dated ? doc.dated.format("YYYY-MM-DD") : "",
    }));


    try {
      let response;
      if (selectedScrutiny) {
        response = await updateDocumentDetailServiceById(selectedScrutiny.id, formattedValues[0]);
        if (response.status === 200 && response?.success) {
          message.success("Scrutiny successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const new_Value = { legal_detail: legalId, scrutiny_documents: formattedValues };
        response = await createDocumentDetailServiceById(new_Value);
        if (response.status === 200 && response?.success) {
         
          message.success("Scrutiny successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      closeForm();
      message.error(error?.response?.data?.message);
    }
  };

  const customDateValidator = (_, value) => {
    const today = moment().startOf("day");

    if (!value) {
      return Promise.reject("");
    }

    if (value.isAfter(today, 'day')) {
      return Promise.reject("Date can not be in the future date.");
    }
    return Promise.resolve();
  };
  

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.List name="documents" initialValue={[{ document: "", dated: "" }]}>
        {(fields, { add, remove }) => {
          const columns = [
            {
              title: "Sr. No",
              dataIndex: "key",
              key: "sr_no",
              render: (text, record, index) => index + 1,
            },
            {
              title: "Document",
              dataIndex: ["document"],
              key: "document",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "document"]}
                  rules={[yupSync]}
                  required
                >
                  <TextArea rows={1} placeholder="Enter document" />
                </Form.Item>
              ),
            },
            {
              title: "Dated",
              dataIndex: ["dated"],
              key: "dated",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "dated"]}
                  rules={[{ required: true, message: "Please select date" }, { validator: customDateValidator }]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" placeholder="DD-MM-YYYY" />
                </Form.Item>
              ),
            },
            {
              title: "Type of Document",
              dataIndex: ["document_type"],
              key: "document_type",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "document_type"]}
                  rules={[{ required: true, message: "Please select document type" }]}
                >
                  <Select
                    mode="single"
                    placeholder="Please Select"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {documentType?.map((documenttype) => (
                      <Option key={documenttype.value} value={documenttype.value}>
                        {documenttype.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ),
            },
          ];

          if (!selectedScrutiny) {
            columns.push({
              title: "Actions",
              fixed: "right",
              render: (_, record, index) => (
                <Space size="middle">
                  <DeleteOutlined
                    style={{ color: index === 0 ? "#ccc" : "#E5233DB2", marginBottom: "25px" }}
                    onClick={() => index !== 0 && remove(record.name)}
                    disabled={index === 0}
                    title={index === 0 ? "Cannot delete the first document" : ""}
                  />
                </Space>
              ),
            });
          }

          return (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, key: field.key }))}
                columns={columns}
                pagination={false}
              />
              {!selectedScrutiny && (
                <Button type="primary" onClick={() => add()} style={{ marginTop: "20px" }}>
                  More Documents
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
      <Space direction="horizontal" align="center" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "20px" }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ScrutinyAndLegalOpinionForm;