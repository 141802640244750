import {axiosRequest} from "../../../utils/api/axiosRequest"


export const createFiCombineForm = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/fi-combine/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getInitiatedDataByLoanId = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/initiate-stage/get-credit-assessment-data-for-fi-input-form/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createFIInputFormData = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/fi-combine/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }