import React, { useState } from "react";
import { Layout, Row, Col, Typography, Calendar, Card, Table } from "antd";
import MeetingTaskSwitcher from "./MeetingTaskSwitcher";
import DashBoardLiteImageSlider from "./DashBoardLiteImageSlider";
import headerImage from "../assets/rafiki.png";

import dayjs from "dayjs";
import { useSelector } from "react-redux";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const TaskTable = ({ assignedTask }) => {
  const columns = [
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text, record) =>
        `${record.stage} ${record.stage_status} for #${record.LAN}`,
    },
    {
      title: "Pending Since",
      dataIndex: "pendingSince",
      key: "pendingSince",
      render: (text, record) => {
        const createdAt = dayjs(record.created_at);
        const now = dayjs();
        const daysPending = now.diff(createdAt, "day");
        const minutesPending = now.diff(createdAt, "minute");

        // Determine color based on daysPending
        let color;
        if (daysPending === 0) color = "green";
        else if (daysPending === 1) color = "yellow";
        else color = "red";

        return (
          <span>
            {daysPending > 0 ? (
              <Text style={{ color }}>{daysPending} days </Text>
            ) : (
              <Text style={{ color }}>{minutesPending} minutes </Text>
            )}
            <Text style={{ color: "grey" }}>
              ({createdAt.format("YYYY-MM-DD")})
            </Text>
          </span>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Table
        columns={columns}
        dataSource={assignedTask}
        pagination={false}
        scroll={{ y: 300 }}
        style={{ width: "100%" }}
      />
    </div>
  );
};

const ScheduleList = () => {
  return <MeetingTaskSwitcher />;
};

const DashboardTaskAssignUI = ({ assignedTask }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { user_data } = useSelector((state) => state.user);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };
  return (
    <Layout style={{ padding: "18px", backgroundColor: "#f0f5ff" }}>
      <Header
        style={{
          background: "#FFFFFF", // Light background color
          padding: "12px 12px", // Extra padding for a balanced layout
          borderRadius: "8px", // Rounded corners for a modern feel
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "auto",
        }}
      >
        <div>
          <Title
            level={3}
            style={{
              margin: 0,
              color: "#27658F",
              fontFamily: "poppins",
              fontWeight: 400,
            }}
          >
            {" "}
            Welcome Back,{" "}
            <Text
              strong
              style={{
                color: "#27658F",
                fontFamily: "poppins",
                fontSize: "18px",
              }}
            >
              {user_data.name}
            </Text>
          </Title>
          <Text
            style={{ color: "#595959", fontSize: "16px", lineHeight: "1.5" }}
          >
            {" "}
            Here's what's going on today.
          </Text>
        </div>

        <img
          src={headerImage}
          alt="Illustration"
          style={{
            width: "100px", // Adjust size as needed
            height: "auto",
            borderRadius: "50%", // Optional rounded effect for icons
          }}
        />
      </Header>

      <Content style={{ marginTop: "24px", color: "rgb(39, 101, 143)" }}>
        <Row gutter={24}>
          <Col span={8}>
            <Card
              title="Today's Schedule"
              headStyle={{
                color: "rgb(39, 101, 143)",
                fontFamily: "poppins",
                fontSize: "20px",
                fontWeight: 400,
              }}
            >
              <Calendar fullscreen={false} onSelect={handleDateSelect} />
            </Card>
            <Card
              headStyle={{
                color: "rgb(39, 101, 143)",
                fontFamily: "poppins",
                fontSize: "20px",
                fontWeight: 400,
              }}
              style={{ marginTop: "16px", width: "100%" }}
            >
              <div>
                <ScheduleList selectedDate={selectedDate} />
              </div>
            </Card>
          </Col>

          <Col span={16}>
            <Card
              title="Assigned Tasks"
              headStyle={{
                color: "rgb(39, 101, 143)",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 400,
              }}
              bodyStyle={{
                padding: 0,
              }}
              style={{
                marginBottom: "16px", // Adjust the value as needed for the desired gap
              }}
            >
              <TaskTable assignedTask={assignedTask} />
            </Card>

            <Card
              headStyle={{ color: "rgb(39, 101, 143)", fontFamily: "Poppins" }}
            >
              <DashBoardLiteImageSlider />
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default DashboardTaskAssignUI;
