import React, { useEffect, useState } from "react";
import { Space, Modal, Typography, Drawer, Button } from "antd";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import { Form } from "antd";
import PartDisbursedFilterForm from "./PartDisbursedFilterForm";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";

const { Title } = Typography;

const PartDisbursedInitiate = () => {
  const [listOfPartDisbursment, setListOfPartDisbursment] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedListOfPartDisbursement, setSelectedListOfPartDisbursement] = useState(null);
  const [filteropen, setFilteropen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    location: null,
    loan_type: [],
    loan_status: [],
    sales_manager: [],
    amountType: null,
    created_by: [],
    modified_by: [],
  });

  const [form] = Form.useForm();

  const { user_data } = useSelector((state) => state.user);

  const showDrawer1 = () => {
    setFilteropen(true);
  };

  const onClose1 = () => {
    setSelectedListOfPartDisbursement(null);
    setFilteropen(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!filteropen && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false);
    }
  }, [filteropen, actionPerformed]);
const columns = [
  {
    title: "Loan Account Number",
    dataIndex: "loan_detail",
    render: (loanDetail) => loanDetail?.loan_account_number || "-", // Access nested property in render
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    render: (text) => text || "-", // Access the 'customer_name' field directly
  },
  {
    title: "Branch",
    dataIndex: "branch_name",
    render: (text) => text || "-", // Access the 'branch_name' field directly
  },
  {
    title: "Requested Amount",
    dataIndex: "disbursment_amount_requested",
    render: (amount) => amount?.toLocaleString() || "-", // Format the amount if available
  },
  {
    title: "Initiated By",
    dataIndex: "initiated_by",
    render: (initiatedBy) => initiatedBy?.name || "-", // Access the nested 'name' field in 'initiated_by'
  },
  {
    title: "Assigned To",
    dataIndex: "assigned_to",
    render: (assignedTo) => assignedTo?.name || "-", // Access the nested 'name' field in 'assigned_to'
  },
  {
    title: "Initiated Date",
    dataIndex: "created_at",
    render: (date) => date || "-", // Use the 'created_at' field for the initiated date
  },
  {
    title: "Status",
    render: (record) => (
      <div
        style={{
          backgroundColor: "#ECFFDC",
          borderRadius: "9px",
          padding: "5px 10px",
          display: "inline-block",
          color: "#40B5AD",
        }}
      >
        {record?.current_status?.display_name || "-"} {/* Access nested property 'display_name' */}
      </div>
    ),
  },
];



const authorizedColumns = [
  {
    title: "View",
    fixed: "right",
    render: (_, record) => {
      console.log("Record ID", record?.id); // Log the record ID to the console
      return (
        <Link to={record?.loan_detail?.id ? `/parstDisbursmenttable/${encrypt(record?.loan_detail.id.toString())}` : '#'}>
          <EyeOutlined style={{ fontSize: "18px", color: "black" }} />
        </Link>
      );
    },
  },
];


  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["MPANOB6"]
  );

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Part Disbursed Initiated
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={showDrawer1}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filter
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/loan/part-disbursment/"
        tableData={listOfPartDisbursment}
        setTableData={setListOfPartDisbursment}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search "}
        filters={filterFormData}
      />

      <Drawer
        title={<Header title="Filter" name="Applications" onClose={onClose1} />}
        width={720}
        onClose={onClose1}
        open={filteropen}
        styles={{ body: { paddingBottom: 80 } }}
        closable={false}
      >
        <PartDisbursedFilterForm
          refreshData={toggleRefreshTableData}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          open={showDrawer1}
          closeForm={onClose1}
          form={form}
          placement="right"
          setActionPerformed={setActionPerformed}
        />
      </Drawer>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedListOfPartDisbursement?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default PartDisbursedInitiate;

