import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createFIInitiationDataService = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post("/api/v1/loan/initiate-stage/fi-initiate", data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}


export const getAllAgentService = (code) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`api/v1/master/agent/agency-agent-list?code=${code}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getAllAgencyService = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`api/v1/master/agency/list`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getAddressDataByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/customer-address-detail/by-loan-id/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const getLoanDetailsById = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getCustomerNameByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
  