import { axiosRequest } from "../../api/axiosRequest";


const url = '/api/v1/loan'

export const getAllDeviationById = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`${url}/loan-deviation/deviation-by-loan-id/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getDeviationList = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/deviation/list`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const postDeviationList = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/loan/loan-deviation/`,data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const deleteDeviation = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`${url}/loan-deviation/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

export const updateDeviationList = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`${url}/loan-deviation/${id}`,data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}