import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  listCity,
  listTaluka,
  listDistrict,
  listPincode,
  listState,
  listCountry,
  getPropertyDescriptionById,
  listUnitsService,
  updatePropertyDescriptionById,
  CustomerListService,
} from "../services/propertyDescriptionService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import moment from "moment";
import AddPropertyOwnerModal from "./AddPropertyOwnerModal";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  present_owner: Yup.string()
    .required("Property Owner  is required")
    .label("Property Owner"),
  unit_type: Yup.string().required("Units is required").label("Units"),
  area_of_property: Yup.string()
    .required("Area of Property is required")
    .label("Area of Property"),
    description_east: Yup.string()
    .trim()
    .required("On East Side is required")
    .label("On East Side"),
  description_west: Yup.string()
    .trim()
    .required("On West Side is required")
    .label("On West Side"),
  description_north: Yup.string()
    .trim()
    .required("On North Side is required")
    .label("On North Side"),
  description_south: Yup.string()
    .trim()
    .required("On South Side is required")
    .label("On South Side"),
    address1: Yup.string()
    .trim()
    .required("Address Line 1 is required")
    .label("Address Line 1"),
  address2: Yup.string()
    .trim()
    .required("Address Line 2 is required")
    .label("Address Line 2"),
    landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be at least 2 characters long.")
    .max(225, "Landmark must be at most 225 characters long.")
    .label("Landmark"),
  city: Yup.string().required("City is required").label("City"),
  taluka: Yup.string().required("Taluka is required").label("Taluka"),
  district: Yup.string()
    .required("District is required")
    .label("District"),
  pincode: Yup.string()
    .required("Pincode is required")
    .label("Pincode"),
  state: Yup.string().required("State is required").label("State"),
  country: Yup.string()
    .required("Country is required")
    .label("Country"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PropertyDescriptionForm = ({
  loan_id,
  refreshData,
  updateId,
  open,
  closeForm,
  stageId
}) => {
  const [, setPropertyDescriptionLegalDetailData] = useState([]);
  const [, setPropertyDescriptionLegalAddressData] = useState([]);
  const [, setShowOtherInput] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [city, setCity] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [district, setDistrict] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [state, setState] = useState([]);
  const [country, setCountry] = useState([]);
  const [units, setUnits] = useState([]);
  const [propertyOwner, setPropertyOwner] = useState([]);
  const [form] = Form.useForm();

  const getPropertyDescriptionLegalDetailData = async () => {
    try {
      const response = await getPropertyDescriptionById(stageId);
      setPropertyDescriptionLegalDetailData(response.data.legal_detail_data);

      let {
        transaction_type,
        present_owner,
        address,
        area_of_property,
        unit_type,
        description_east,
        description_west,
        description_north,
        description_south,
        date_of_report,
      } = response?.data.legal_detail_data;
      date_of_report = dayjs(date_of_report, "DD-MM-YYYY");

      form?.setFieldsValue({
        transaction_type,
        present_owner,
        address,
        area_of_property,
        unit_type,
        description_east,
        description_west,
        description_north,
        description_south,
        date_of_report,
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getPropertyDescriptionLegalAddressData = async () => {
    try {
      const response = await getPropertyDescriptionById(stageId);
      setPropertyDescriptionLegalAddressData(response.data.legal_address_data);
      let {
        address1,
        address2,
        district,
        state,
        city,
        pincode,
        landmark,
        taluka,
        country,
      } = response?.data.legal_address_data;

      form?.setFieldsValue({
        address1,
        address2,
        district,
        state: state?.id,
        city,
        pincode,
        landmark,
        taluka,
        country,
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const fetchUnits = async () => {
    try {
      // Fetch Units from the API
      const response = await listUnitsService();
      setUnits(response.data.choices);

    } catch (error) {
      console.error("Error fetching Units:", error);
      message.error("Failed to fetch Units");
    }
  };

  const fetchCustomerName = async (loan_id) => {
    try {
      const response = await CustomerListService(loan_id);
      setPropertyOwner(response.data.customer_list);
    } catch (error) {
      console.error("Error fetching Property Owners Name:", error);
      message.error("Failed to fetch Property Owners Name");
    }
  };

  useEffect(() => {
    if (open) {
      getPropertyDescriptionLegalDetailData();
      getPropertyDescriptionLegalAddressData();
      fetchUnits();
      fetchCustomerName(loan_id);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form, stageId]);

  const handleOwnerChange = (value) => {
    if (value === "other") {
      setShowOtherInput(true);
      setIsModalVisible(true); // Show the modal when "Other" is selected
      form.setFieldsValue({ present_owner: "" }); // Clear the field value
    } else {
      setShowOtherInput(false);
      setIsModalVisible(false);
      form.setFieldsValue({ other_owner: "" });
    }
  };

  const handleAddPropertyOwner = (newOwner) => {
    // Add the new owner to the property owner list
    setPropertyOwner([
      ...propertyOwner,
      { id: propertyOwner.length + 1, first_name: newOwner },
    ]);
    // Optionally, you can also set the selected owner in the form field
    form.setFieldsValue({ present_owner: newOwner });
  };

  const handleSubmit = async (values) => {
    values.date_of_report = values.date_of_report.format("YYYY-MM-DD");
    try {
      const response = await updatePropertyDescriptionById(updateId, values);
      if ((response.status = 200 && response.success)) {
        message.success("Property Description successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleInput = (e, field) => {
    let newValue = e.target.value;
    if (field === "area_of_property") {
      newValue = newValue.replace(/[^0-9]/g, ""); // Only allow digits for the area_of_property field
    } else {
      newValue = newValue.replace(/[^A-Za-z\s]/g, ""); // Only allow alphabets and whitespace for other fields
    }
    form.setFieldsValue({ [field]: newValue });
  };

  const customDateValidator = (_, value) => {
    const today = moment().startOf("day");

    if (!value) {
      return Promise.reject("Date of report is required");
    }

    if (value.isAfter(today, "day")) {
      return Promise.reject("Date can not be in the future date.");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const getAllCity = async () => {
      try {
        const response = await listCity();
        setCity(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllTaluka = async () => {
      try {
        const response = await listTaluka();
        setTaluka(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await listDistrict();
        setDistrict(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincode = async () => {
      try {
        const response = await listPincode();
        setPincode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await listState();
        setState(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountry = async () => {
      try {
        const response = await listCountry();
        setCountry(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllCity();
    getAllTaluka();
    getAllDistrict();
    getAllPincode();
    getAllState();
    getAllCountry();
  }, [form]);

  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s,./()0-9/-]/g, ""); // Allow numbers
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        transaction_type: "",
        area_of_property: "",
        on_east_side: "",
        on_west_side: "",
        on_north_side: "",
        on_south_side: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
      }}
    >
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name="transaction_type" label="Transaction Type" required >
            <Input
              placeholder="Fresh Transaction"
              onChange={(e) => handleInput(e, "transaction_type")}
              disabled

            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="present_owner"
            label="Property Owner"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              onChange={handleOwnerChange}
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {propertyOwner?.map((property) => {
                
                return (
                  <Select.Option key={property.id} value={property.first_name}>
                    {`${property.first_name} (${property.customer_type})`}
                  </Select.Option>
                );
              })}
              <Option key="other" value="other">
                Other
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="address" label="Property Address" required>
            <Input
              placeholder="Enter Property Address"
              onChange={(e) => handleInput(e, "property_address")}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="area_of_property"
            label="Area of Property"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Area of Property"
              onChange={(e) => handleInput(e, "area_of_property")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="unit_type" label="Units" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {units?.map((unit) => (
                <Option key={unit.value} value={unit.value}>
                  {unit.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div
        className="property_description_heading_two"
        style={{ marginBottom: "20px" }}
      >
        <div className="property_description_data">As per Documents</div>
      </div>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              // onChange={(e) => handleInput(e, "address_line_1")}
              onChange={(e) => handletextInput(e, "address1")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handletextInput(e, "address2")}

            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select District"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {district?.map((district) => {
                return (
                  <Select.Option key={district.id} value={district.name}>
                    {district.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select State"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {state?.map((state) => {
                return (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select City"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {city?.map((city) => {
                return (
                  <Select.Option key={city.id} value={city.name}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select Pincode"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {pincode?.map((pincode) => {
                return (
                  <Select.Option key={pincode.id} value={pincode.name}>
                    {pincode.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Landmark"
              onChange={(e) => handletextInput(e, "landmark")}

            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select Taluka"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {taluka?.map((taluka) => {
                return (
                  <Select.Option key={taluka.id} value={taluka.name}>
                    {taluka.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select Country"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {country?.map((country) => {
                return (
                  <Select.Option key={country.id} value={country.name}>
                    {country.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="description_east"
            label="On East Side"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter On East Side"
              onChange={(e) => handletextInput(e, "description_east")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="description_west"
            label="On West Side"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter On West Side"
              onChange={(e) => handletextInput(e, "description_west")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="description_north"
            label="On North Side"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter On North Side"
              onChange={(e) => handletextInput(e, "description_north")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="description_south"
            label="On South Side"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter On South Side"
              onChange={(e) => handletextInput(e, "description_south")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="date_of_report"
            label="Date of Report"
            rules={[{ validator: customDateValidator }]}
            required
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              placeholder="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>

      <AddPropertyOwnerModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onAddPropertyOwner={handleAddPropertyOwner}
      />
    </Form>
  );
};

export default PropertyDescriptionForm;
