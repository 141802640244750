import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import getDocumentTypeList from "../../services/getDocumentTypeList";
import { getIdentificationDocumentsById } from "../../services/FIViewFormServices";
import { updateResidenceDocumentsById } from "../../services/applicantRvServices";
const ResidenceNeighborConfirmationEditForm = ({  closeForm, id, open, refreshData }) => {
  const [form] = Form.useForm();
  const [, setDocumentType] = useState([]);
  const { Option } = Select;

  const getAgentData = async () => {
    if (id) {
      try {
        const response = await getIdentificationDocumentsById(id);
        const { name, remark } =
          response?.data;
        form.setFieldsValue({
          name,
          remark,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getAgentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const fetchDocumentTypeList = async () => {
      const response = await getDocumentTypeList();
      setDocumentType(response.data);
    };
    fetchDocumentTypeList();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateResidenceDocumentsById(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Residence documents successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="name"
              label=" Document"
            >
              <Input rows={1} placeholder="Enter document" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="remark"
              label="Remark"
            >
              <Select placeholder="Select status">
                <Option value="positive">Positive</Option>
                <Option value="negative">Negative</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default ResidenceNeighborConfirmationEditForm;
