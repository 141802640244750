import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getAllLocationsservice = (page, limit, searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        page = page ? page : 1;
        limit = limit ? limit : 10;
        let path = `/api/v1/master/locations/?page=${page}&limit=${limit}`;
        if (searchQuery.length > 0) {
          path = `/api/v1/master/locations/?search=${searchQuery}&page=${page}&limit=${limit}`;
        }
        const response = await axiosRequest.get(path);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


export const getZoneListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get("/api/v1/master/zones/list");
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createLocationsService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
        const response = await axiosRequest.post("/api/v1/master/locations/", data);
        return resolve(response.data);
        // console.log(response.data);
    } catch (error) {
      return reject(error)
    }
  })
}

export const getLocationsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/locations/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateLocationsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/master/locations/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteLocationsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/master/locations/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const listUserService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/user/get-user-list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const pincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllCityService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/city/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllTalukaService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/taluka/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllDistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/district/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllCountriesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


