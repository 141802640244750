import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { getOwnerShip, getVehicleTypeChoice } from "../../services/getCustomerDetailData";
import { getVehicleById } from "../../services/FIViewFormServices";
import { updateVehicleById } from "../../services/applicantRvServices";

const VehiclesEditForm = ({ closeForm, id, open, refreshData  }) => {
  const [form] = Form.useForm();
  const [vehicle, setVehicle] = useState([]);
  const [ownerShip, setOwnerShip] = useState([]);

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getVehicleTypeChoice, setVehicle);
    fetchAllData(getOwnerShip, setOwnerShip);
  }, []);

  const getVehicleData = async () => {
    if (id) {
      try {
        const response = await getVehicleById(id);
        const { 
          loan_amount, 
          vehicle_type,
          registration_number,
          financed_from,
          model_no,
          ownership_type 
        } =
          response?.data;
        form.setFieldsValue({
          loan_amount, 
          vehicle_type,
          registration_number,
          financed_from,
          model_no,
          ownership_type 
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getVehicleData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateVehicleById(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Vehicle successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="vehicle_type"
              label="Type of Vehicle"
              rules={[{ required: true, message: "Please enter Vehicle Type" }]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {vehicle?.map((veh) => (
                  <option value={veh}>{veh}</option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="model_no"
              label="Model No"
              rules={[{ required: true, message: "Please enter Model No" }]}
            >
              <Input placeholder="Enter Model No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="ownership_type"
              label="Ownership Type"
              rules={[
                { required: true, message: "Please enter Ownership Type" },
              ]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {ownerShip?.map((own) => (
                  <option value={own}>{own}</option>
                ))}
              </Select>
              {/* <Input placeholder="Enter Ownership Type" /> */}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="registration_number"
              label="Vehicle No"
              rules={[{ required: true, message: "Please enter Vehicle No" }]}
            >
              <Input placeholder="Enter Vehicle No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="financed_from"
              label="Financed From"
              rules={[
                { required: true, message: "Please enter financed from" },
              ]}
            >
              <Input placeholder="Enter Financed From" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="loan_amount"
              label="Loan Amount"
              rules={[{ required: true, message: "Please enter loan_amount" }]}
            >
              <Input placeholder="Enter Loan Amount" />
            </Form.Item>
          </Col>
        </Row>

        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default VehiclesEditForm;
