import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message, DatePicker } from "antd";
import * as Yup from "yup";
import {
  getVehicleDetailsByIdService,
  listRTOService,
  listStateService,
  listVehicleBodyService,
  listVehicleManufacturesService,
  listVehicleModelService,
  listVehicleTypeService,
  listVehicleVariantService,
  updateVehicleDetailsByIdService,
  listRoadTaxType,
  vehicleModelService,
  vehicleManufatureService,
} from "../services/vahicleService";
import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
  engine_number: Yup.string()
    .required("Engine number is required")
    .test(
      "min-max-range",
      "Engine number must be between 12 to 14",
      (value) => {
        return value.length >= 12 && value.length <= 14;
      }
    )
    .test(
      "alphanumeric-combination",
      "Please enter a combination of characters and numbers",
      (value) => {
        return /[a-zA-Z]/.test(value) && /\d/.test(value);
      }
    ),

  chassis_number: Yup.string()
    .required("Chassis number is required")
    .test(
      "exact-length",
      "Chassis number must have exactly 17 characters",
      (value) => {
        return value.length === 17;
      }
    )
    .test(
      "alphanumeric-combination",
      "Please enter a combination of characters and numbers",
      (value) => {
        // Test if the value contains both characters and numbers
        return /[a-zA-Z]/.test(value) && /\d/.test(value);
      }
    ),

  registration_number: Yup.string()
    .required("Registration number is required")
    .test(
      "exact-length",
      "Registration number must have exactly 10 characters",
      (value) => {
        return value.length === 10;
      }
    )
    .test(
      "alphanumeric-combination",
      "Please enter a combination of characters and numbers",
      (value) => {

        return /[a-zA-Z]/.test(value) && /\d/.test(value);
      }
    ),
  resgistration_date: Yup.string()
    .required("Registration date is required"),
  state: Yup.string()
    .required("State is required"),
  registration_transport_office: Yup.string()
    .required("Registration transport office is required"),
  current_owner_name: Yup.string()
    .required("Current owner name is required")
    .max(2, "Current owner name can not exceed 2 characters")
    .max(100, "Current owner name can not exceed 100 characters")
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed in current owner name'),
  registration_expiry_date: Yup.string()
    .required("Registration expiry date is required"),
  vehicle_life: Yup.string()
    .required("Vehicle life is required")
    .matches(/^[0-9]+$/, { message: "Vehicle life must contain only digits" }),
  // .test("min-max-range", "Vehicle life must be between 1 to 40.", (value) => {
  //   const numericValue = parseInt(value, 10); // Corrected base to 10
  //   return numericValue >= 1 && numericValue <= 40;
  // }),
  vehicle_age: Yup.string()
    .required("Vehicle age is required")
    .matches(/^[0-9]+$/, { message: "Vehicle age must contain only digits" }),
  // .test("min-max-range", "Vehicle age must be between 1 to 40.", (value) => {
  //   const numericValue = parseInt(value, 10);
  //   return numericValue >= 1 && numericValue <= 40;
  // }),
  vehicle_body: Yup.string()
    .required("Vehicle body is required"),

  manufacturing_year: Yup.string()
    .required("Manufacturing year is required")
    .test('valid-format', 'Manufacturing year must be in the format "Month Year" (Month first letter must be Capital. e.g. January 2024)', function (value) {
      const regex = /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$/;
      return regex.test(value);
    }),
  road_tax_type: Yup.string()
    .required("Road tax type is required")
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed in Road Tax type'),

  number_of_previous_owners: Yup.string()
    .required("Number of previous owners is required")
    .min(1, "Number of previous owners must be greater than 0"),
  // .matches(/^[1-9]$/, "Number of previous owners must be a single digit between 1 and 9")
  // .test(
  //   "is-number",
  //   "Number of previous owners must contain only digits",
  //   (value) => {
  //     return /^\d$/.test(value);
  //   }
  // ),
  vehicle_type: Yup.string()
    .required("Vehicle type is required"),
  vehicle_manufacturer: Yup.string()
    .required("Vehicle manufacturer is required"),
  vehicle_model: Yup.string()
    .required("Vehicle model required"),
  vehicle_variant: Yup.string()
    .required("Vehicle variant is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const VehicleDetailsForm = ({ id, updateId, open, closeForm, refreshData }) => {
  const [form] = Form.useForm();
  const [stateType, setStateType] = useState([]);
  const [vehicleType, setVehicleType] = useState([]);
  const [vehicleManufacture, setVehicleManufacture] = useState([]);
  const [vehiclemodel, setVehicleModel] = useState([]);
  const [vehiclebody, setVehicleBody] = useState([]);
  const [vehiclevarint, setVehicleVariant] = useState([]);
  const [roadTaxType, setRoadTaxType] = useState([]);
  const [transportOffice, setTransportOffice] = useState([]);
  const [disabledModelSelect, setDisabledModelSelect] = useState(true);
  const [disabledVariantSelect, setDisabledVariantSelect] = useState(true);
  const [loading, setLoading] = useState(false);

  const getVehicleData = useCallback(async () => {
    if (id) {
      try {
        const response = await getVehicleDetailsByIdService(id);
        let {
          engine_number,
          chassis_number,
          registration_number,
          resgistration_date,
          state,
          registration_transport_office,
          current_owner_name,
          registration_expiry_date,
          vehicle_life,
          vehicle_body,
          vehicle_age,
          manufacturing_year,
          road_tax_type,
          number_of_previous_owners,
          vehicle_type,
          vehicle_manufacturer,
          vehicle_model,
          vehicle_variant,
        } = response?.data;
        resgistration_date = dayjs(resgistration_date, "DD-MM-YYYY");
        registration_expiry_date = dayjs(
          registration_expiry_date,
          "DD-MM-YYYY"
        );
        manufacturing_year = dayjs(manufacturing_year, "YYYY-MM");
        form.setFieldsValue({
          engine_number,
          chassis_number,
          registration_number,
          resgistration_date,
          registration_transport_office: registration_transport_office?.id,
          current_owner_name,
          registration_expiry_date,
          vehicle_life,
          vehicle_age,
          manufacturing_year,
          road_tax_type,
          number_of_previous_owners,
          vehicle_body: vehicle_body.id,
          vehicle_type: vehicle_type.id,
          vehicle_manufacturer: vehicle_manufacturer?.id,
          vehicle_model: vehicle_model?.id,
          vehicle_variant: vehicle_variant?.id,
          state: state.id,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  }, [id, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.vehicle_life = values.vehicle_life.toString()
      values.resgistration_date =
        values.resgistration_date.format("YYYY-MM-DD");
      values.registration_expiry_date =
        values.registration_expiry_date.format("YYYY-MM-DD");
      values.manufacturing_year = values.manufacturing_year.format("YYYY-MM");

      const response = await updateVehicleDetailsByIdService(updateId, values);
      if ((response.status = 200 && response.success)) {
        message.success("Vehicle Details successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }

  };

  useEffect(() => {
    if (open) {
      getVehicleData();
    } else {
      form.resetFields();
    }
  }, [open, id, form, getVehicleData]);

  useEffect(() => {
    const getAllState = async () => {
      try {
        const response = await listStateService();
        setStateType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllVehicleType = async () => {
      try {
        const response = await listVehicleTypeService();
        setVehicleType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllVehicleManufacture = async () => {
      try {
        const response = await listVehicleManufacturesService();
        setVehicleManufacture(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllVehicleModel = async () => {
      try {
        const response = await listVehicleModelService();
        setVehicleModel(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllRTO = async () => {
      try {
        const response = await listRTOService();
        setTransportOffice(response?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };


    const getAllVehicleBody = async () => {
      try {
        const response = await listVehicleBodyService();
        setVehicleBody(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllVehicleVariant = async () => {
      try {
        const response = await listVehicleVariantService();
        setVehicleVariant(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllRoadTaxType = async () => {
      try {
        const response = await listRoadTaxType();
        setRoadTaxType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllState();
    getAllRTO();
    getAllVehicleType();
    getAllVehicleManufacture();
    getAllVehicleModel();
    getAllVehicleBody();
    getAllVehicleVariant();
    getAllRoadTaxType();
  }, [form]);

  const handleInput = (e, field) => {
    const newValue = e.target?.value?.replace(/[^A-Za-z0-9\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const registrationDateValidator = (_, value) => {
    const manufacturingYear = form.getFieldValue("manufacturing_year");
    const currentDate = dayjs()

    if (dayjs(value).isBefore(dayjs(manufacturingYear, "YYYY"), "day")) {
      return Promise.reject("Registration date should be after manufacturing year");
    }
    if (dayjs(value).isAfter(currentDate, "day") || dayjs(value).isSame(currentDate, "day")) {
      return Promise.reject("Registration date can not be today's date or a future date");
    }
    return Promise.resolve();
  };

  const registrationExpiryDateValidator = (_, value) => {
    const registrationDate = form.getFieldValue("resgistration_date");
    if (dayjs(value).isSame(dayjs(registrationDate, "DD-MM-YYYY"), "day")) {
      return Promise.reject("Registration expiry date cannot be the same as registration date");
    }

    if (dayjs(value).isBefore(dayjs(registrationDate, "DD-MM-YYYY"), "day")) {
      return Promise.reject("Registration expiry date should be after registration date");
    }

    return Promise.resolve();
  };


  const handleManufacturerChange = async (manufactureID) => {
    if (manufactureID === undefined) {
      return;
    }
    
    try {
      const response = await vehicleManufatureService(manufactureID);
      setVehicleModel(response?.data);
      setDisabledModelSelect(false);


      form.setFieldsValue({ vehicle_model: undefined }); // Reset the vehicle model field
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleModelChange = async (modelId) => {
    if (modelId === undefined) {
      return;
    }
    try {
      const response = await vehicleModelService(modelId);
      setVehicleVariant(response.data);
      form.setFieldsValue({ vehicle_variant: undefined }); // Reset vehicle variant field
      setDisabledVariantSelect(false);
    } catch (error) {
      message.error(ErrorMessage(error.response.status, error.response.data.message));
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        engine_number: "",
        chassis_number: "",
        registration_number: "",
        resgistration_date: "",
        // state: "",
        // registration_transport_office: "",
        current_owner_name: "",
        registration_expiry_date: "",
        vehicle_life: "",
        // vehicle_body: "",road_tax_type
        vehicle_age: "",
        manufacturing_year: "",
        // road_tax_type: "",
        number_of_previous_owners: "",
        // vehicle_type: "",
        // vehicle_manufacturer: "",
        // vehicle_model: "",
        // vehicle_variant: "",

        vehicle_manufacturer: undefined,
        vehicle_model: undefined,
        vehicle_variant: undefined,
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="vehicle_type"
            label="Vehicle Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vehicleType?.map((vehicle_type) => {
                return (
                  <Select.Option key={vehicle_type.id} value={vehicle_type.id}>
                    {vehicle_type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="vehicle_manufacturer"
            label="Vehicle Manufacturer"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleManufacturerChange}
           
            >
              {vehicleManufacture?.map((vehicle_manufacturer) => {
                return (
                  <Select.Option
                    key={vehicle_manufacturer.id}
                    value={vehicle_manufacturer.id}
                  >
                    {vehicle_manufacturer.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="vehicle_model"
            label="Vehicle Model"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleModelChange}
              disabled={disabledModelSelect}
            >
              {vehiclemodel?.map((vehicle_model) => {
                return (
                  <Select.Option
                    key={vehicle_model.id}
                    value={vehicle_model.id}
                  >
                    {vehicle_model.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>


      </Row>


      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="vehicle_variant"
            label="Vehicle Variant"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disabledVariantSelect}
            >
              {vehiclevarint?.map((vehicle_variant) => {
                return (
                  <Select.Option
                    key={vehicle_variant.id}
                    value={vehicle_variant.id}
                  >
                    {vehicle_variant.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="engine_number"
            label="Engine Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Engine Number"
              onChange={(e) => handleInput(e, "engine_number")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="chassis_number"
            label="Chassis Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Chassis Number"
              onChange={(e) => handleInput(e, "chassis_number")}
            />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="resgistration_date"
            label="Registration Date"
            rules={[
              { required: true, message: "Registration Date is required!" },
              { validator: registrationDateValidator }
            ]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {stateType?.map((state) => {
                return (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="registration_transport_office"
            label="Registration Transport Office" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {transportOffice?.map((transport) => {
                return (
                  <Select.Option key={transport?.id} value={transport?.id}>
                    {transport?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="current_owner_name"
            label="Current Owner Name"
            required
            rules={[yupSync]}
          >
            <Input
              placeholder="Please Enter Current Owner Name"
              onChange={(e) => handleInput(e, "current_owner_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="registration_expiry_date"
            label="Registration Expiry Date"
            rules={[
              { required: true, message: "Registration Expiry Date is required!" },
              { validator: registrationExpiryDateValidator }
            ]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="vehicle_life"
            label="Vehicle Life"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Vehicle Life"
              onChange={(e) => handleNumberFields(e, "vehicle_life")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="vehicle_body"
            label="Vehicle Body"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vehiclebody?.map((vehicle_body) => {
                return (
                  <Select.Option key={vehicle_body.id} value={vehicle_body.id}>
                    {vehicle_body.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="vehicle_age"
            label="Vehicle Age"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Vehicle Age"
              onChange={(e) => handleNumberFields(e, "vehicle_age")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="registration_number"
            label="Registration Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Registration Number"
              onChange={(e) => handleInput(e, "registration_number")}
            />
          </Form.Item>
        </Col>



      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="manufacturing_year"
            label="Manufacturing Year"
            rules={[
              { required: true, message: "Manufacturing Year is required!" },
              { validator: registrationDateValidator }
            ]}
            required
          >
            <DatePicker.MonthPicker
              format="MMMM YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="road_tax_type"
            label="Road Tax Type"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" allowClear showSearch filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
              {roadTaxType.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="number_of_previous_owners"
            label="Number Of Previous Owners"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Number Of Previous Owners"
              onChange={(e) => handleInput(e, "number_of_previous_owners")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default VehicleDetailsForm;
