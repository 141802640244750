import React, { useState, useEffect } from "react";
import { Col, Row, Select, message } from "antd";
import ICICIInsuranceForm from "./ICICIInsuranceForm";
import MagmaHDIInsuranceForm from "./MagmaHDIInsuranceForm";
import HDFCInsuranceForm from "./HDFCInsuranceForm";
import {
  getPersonalDetailsByIdService,
  getInsuranceCompaniesByIdService,
} from "../services/loanInsuranceService";

const { Option } = Select;

const InsuranceForm = ({ closeForm, open, id, toggleRefreshInsurance,setCount,count,setDisabledButton,disabledButton }) => {
  const [selectedInsuranceType, setSelectedInsuranceType] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [errors, setErrors] = useState({});
  const [personalData, setPersonalData] = useState([]);
  const [companies, setCompanies] = useState([]);

  const handleInsuranceTypeChange = (value) => {
    setSelectedInsuranceType(value);
    setErrors((prevErrors) => ({ ...prevErrors, insurance_type: "" }));
    setSelectedCustomerId(null);
  };

  const handleCustomerChange = (value) => {
    setSelectedCustomerId(value);
    setErrors((prevErrors) => ({ ...prevErrors, insured_customer: "" }));    
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPersonalDetailsByIdService(id);
        setPersonalData(response.data.customers);
      } catch (error) {
        message.error(
          `${error?.response?.status}: ${error?.response?.data?.message}`
        );
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const fetchInsuranceCompanies = async () => {
      try {
        const res = await getInsuranceCompaniesByIdService();
        setCompanies(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchInsuranceCompanies();
  }, [id]);

  // Reset form when the drawer is closed
  useEffect(() => {
    if (!open) {
      setSelectedInsuranceType("");
      setSelectedCustomerId(null);
      setErrors({});
    }
  }, [open]);

  const renderInsuranceForm = () => {
    if (!selectedInsuranceType) {
      return null; // Don't render the form if either field is not selected
    }

    switch (selectedInsuranceType) {
      case 1:
        return (
          <ICICIInsuranceForm
            id={id}
            customerId={selectedCustomerId}
            closeForm={closeForm}
            insuranceType={selectedInsuranceType}
            setSelectedCustomer={setSelectedCustomerId}
            setSelectedInsuranceType={setSelectedInsuranceType}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );

      case 2:
        return (
          <>
          <HDFCInsuranceForm
            id={id}
            customerId={selectedCustomerId}
            closeForm={closeForm}
            insuranceType={selectedInsuranceType}
            setSelectedCustomer={setSelectedCustomerId}
            setSelectedInsuranceType={setSelectedInsuranceType}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
          </>
        );

      case 3:
        return (
          <MagmaHDIInsuranceForm
            id={id}
            customerId={selectedCustomerId} // Pass the extracted customer IDs
            setCount={setCount}
            count={count}
            closeForm={closeForm}
            insuranceType={selectedInsuranceType}
            setSelectedCustomer={setSelectedCustomerId}
            setSelectedInsuranceType={setSelectedInsuranceType}
            toggleRefreshInsurance={toggleRefreshInsurance}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="drawer-insurance-space">
        <Row gutter={16}>
          <Col span={12}>
            <div>
              <label style={{ fontSize: 14 }}>
                {" "}
                <span style={{ color: "red", marginRight: "4px" }}>*</span>
                Insurance Type{" "}
              </label>
              <br />
              <Select
                placeholder="Please Select"
                allowClear
                onChange={handleInsuranceTypeChange}
                value={selectedInsuranceType || undefined}
                style={{ width: 300 }}
              >
                {companies.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
              {errors.insurance_type && (
                <div style={{ color: "red" }}>{errors.insurance_type}</div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div>
              <label style={{ fontSize: 14 }}>
                {" "}
                <span style={{ color: "red", marginRight: "4px" }}>*</span>
                Select Insured Customer{" "}
              </label>
              <br />
              <Select
                // mode={allowMultipleCustomers ? "multiple" : undefined}
                placeholder="Please Select Customer"
                allowClear
                onChange={handleCustomerChange}
                value={selectedCustomerId}
                style={{ width: 300 }}
              >
                {personalData.map((person) => (
                  <Option key={person.id} value={person.id}>
                    {person.first_name} {person.middle_name} {person.last_name}{" "}
                    ({person.customer_type.display_name})
                  </Option>
                ))}
              </Select>
              {errors.insured_customer && (
                <div style={{ color: "red" }}>{errors.insured_customer}</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: "20px", marginTop: "40px" }}>
        {renderInsuranceForm()}
      </div>
    </>
  );
};

export default InsuranceForm;
