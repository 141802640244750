import {axiosRequest} from "../../../utils/api/axiosRequest"


export const getMiscellaneousDetailServiceById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-legal-data/legal-data/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateMiscellaneousDetailServiceById = (legal_id,values) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/property-legal-data/combine-update-legal-data-and-address/${legal_id}`,values);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listOverallTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/overall-status-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};