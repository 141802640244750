import { Col, Form, Input, Row, Select, message, DatePicker, Button, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import {
  createPensionerService,
  getPensionerByIdService,
  listBusinessLegalStructure,
  listIndustryervice,
  updatePensionerByIdService
} from '../services/employmentDetailsServices';
import moment from "moment";
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';

const validationSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required("Organization name is required")
    .min(2, "Organization name must be between 2 and 128 characters")
    .max(128, "Organization name must be between 2 and 128 characters"),
  industry_type: Yup.string().required("Industry type is required"),
  year_of_start: Yup.string()
    .required("Year of start is required"),


  gross_income: Yup.string().required("Gross income is required"),
  net_income: Yup.string().required("Net income is required"),
  business_legal_structure: Yup.string().required(
    "Business legal structure is required"
  )
    .min(2, "Business legal structure must be between 2 and 128 characters")
    .max(128, "Business legal structure must be between 2 and 128 characters"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PensionerForm = ({ refreshData, id, open, closeForm, customer_id, selectedEmploymentType }) => {
  const [form] = Form.useForm();
  const [allIndustryType, setAllIndustryType] = useState([]);
  const [businessLegalStructure, setBusinessLegalStructure] = useState([]);
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const getAllIndustryType = async () => {
      try {
        const response = await listIndustryervice();
        setAllIndustryType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllBusinessLegalStructure = async () => {
      try {
        const response = await listBusinessLegalStructure();
        setBusinessLegalStructure(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllIndustryType();
    getAllBusinessLegalStructure();
  }, []);

  const handleNumberFields = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };
  const customNetIncomeValidator = (_, value) => {
    const grossIncome = form.getFieldValue("gross_income");
    if (parseFloat(value) > parseFloat(grossIncome)) {
      return Promise.reject("Net Income must be less than or equal to Gross Income");
    }
    return Promise.resolve();
  };
  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };


  useEffect(() => {
    if (id) {
      const getPensionerData = async () => {
        try {
          const response = await getPensionerByIdService(id);
          let {
            organization_name,
            industry_type,
            year_of_start,
            gross_income,
            net_income,
            business_legal_structure,
          } = response?.data;

          year_of_start = dayjs().year(year_of_start).startOf('year');

          form.setFieldsValue({
            organization_name,
            industry_type: industry_type.id,
            year_of_start,
            gross_income,
            net_income,
            business_legal_structure,
          });
        } catch (error) {
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      };

      getPensionerData();
    }
  }, [id, form, open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    values.net_income = parseFloat(values.net_income, 10);
    values.gross_income = parseFloat(values.gross_income, 10);
    values.year_of_start = values.year_of_start.format("YYYY");
    try {
      if (id) {
        const response = await updatePensionerByIdService(id, {
          ...values,
        });

        if (response.status === 200 && response.success) {
          message.success("Employment Details successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const formattedValues = [{
          "employment_details": {
            customer_detail: customer_id,
            employment_type: selectedEmploymentType,
            loan_detail: parseInt(loan_id),
          },
          "pensioner": {
            ...values
          }
        }];
        const response = await createPensionerService(formattedValues);
        if ((response.status = 200 && response.success)) {
          message.success("Employment details successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
    setLoading(false); // End loading
  }
  };

  return (

    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        // industry_type: "",
        organization_name: "",
        // business_legal_structure: "",
        year_of_start: "",
        gross_income: "",
        net_income: ""
      }}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="industry_type"
            label="Industry Type"
            rules={[yupSync]}
            required
          >
            <Select
              showSearch
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
             filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allIndustryType?.map((investment) => (
                <Select.Option key={investment.id} value={investment.id}>
                  {investment.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="organization_name"
            label="Organization Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Organization Name"
              onChange={(e) => handleInput(e, "organization_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="business_legal_structure"
            label="Business Legal Structure"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" showSearch    allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {businessLegalStructure.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="year_of_start"
            label="Year Of Start   (In Year)"
            rules={[yupSync]}
            required
          >
            <DatePicker
              picker="year"
              disabledDate={disabledDate}
              placeholder="Select Date"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="gross_income"
            label="Gross Income"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Gross Income"
              onChange={(e) => handleNumberFields(e, "gross_income")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="net_income"
            label="Net Income"
            rules={[
              { required: true, message: "Net income is required" },
              { validator: customNetIncomeValidator }
            ]}
            required
          >
            <Input
              placeholder="Please Enter Your Net Income"
              onChange={(e) => handleNumberFields(e, "net_income")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  )
}

export default PensionerForm