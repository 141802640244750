import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import EditButton from "../../../../utils/editButton/EditButton";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import PersonalDetailsEditForm from "./PersonalDetailsEditForm";
import { getPersonalDetailByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const PersonalDetails = ({ selectedCustomerId }) => {
  const [openEditThree, setOpenEditThree] = useState(false);
  const [collapsed3, setCollapsed3] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [personalData, setPersonalData] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getPersonalDetailByCustomerId(selectedCustomerId);
        setPersonalData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData,selectedCustomerId]);

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };
  const showDrawerThree = () => {
    setOpenEditThree(true);
  };
  const onCloseThree = () => {
    setOpenEditThree(false);
  };
  const renderEditButtonThree = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerThree();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div style={{ marginTop:"20px",border:"1px solid #e4e7ec" }}>
      <Drawer
        title={
          <Header title="Edit" onClose={onCloseThree} name="Personal Details" />
        }
        width={970}
        onClose={onCloseThree}
        open={openEditThree}
        closable={false}
      >
        <PersonalDetailsEditForm
          updateId={personalData?.id}
          // loan_id={loan_id}
          open={openEditThree}
          refreshData={toggleRefreshTableData}
          closeForm={onCloseThree}
          customerDetails={personalData}
        />
      </Drawer>
      <div className="fi_description_heading">
        <div className="fi_description_data">Personal Details </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {renderEditButtonThree()}
          </div>
          <div
            style={{ margin: "10px 10px 10px 10px",cursor:"pointer" }}
            onClick={toggleCollapse3}
          >
            {collapsed3 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed3 && (
        <div>
          <>
            <div className="fi_description_basic_card_container">
              <div className="fi_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Family Members
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.no_of_family_members ||
                      "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Age
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.age || "-"}
                  </div>
                </div>

                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Is Spouse Working:
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.is_spouse_working === true
                      ? "Yes"
                      : personalData?.is_spouse_working ===
                        false
                        ? "No"
                        : "-"}
                  </div>
                </div>
              </div>

              <div className="fi_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Working
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.is_working === true
                      ? "Yes"
                      : personalData?.is_working === false
                        ? "No"
                        : "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Qualification
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.qualification || "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Living Standard
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.living_standard || "-"}
                  </div>
                </div>
              </div>

              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Dependent
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.has_dependent === true
                      ? "Yes"
                      : personalData?.has_dependent === false
                        ? "No"
                        : "-"}
                  </div>
                </div>

                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Spouse Name
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.spouse_name || "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Surroundings
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.surrounding
                      ? JSON.parse(
                        personalData.surrounding.replace(
                          /'/g,
                          '"'
                        )
                      ).join(", ")
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="fi_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    DOB
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.date_of_birth || "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Marital Status
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personalData?.marital_status || "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default PersonalDetails;
