import React from 'react'
import {
    DeleteOutlined,
} from "@ant-design/icons";
const DeleteButton = () => {
    return (
        <><DeleteOutlined
            style={{
                border: "1px solid #CACECE",
                width: "26px",
                height: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#FFFFFF",
                color: "#1788AF",
                fontSize: "13px",
                cursor: "pointer"
            }}
 /></>
    )
}

export default DeleteButton