import { Table } from 'antd'
import React from 'react'

const IncomeNonCashView = ({ nonCashIncomeData, renderEditButton, showDrawer }) => {
    const Noncashcolumns = [
        {
            title: 'SR.NO',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Month & Year',
            dataIndex: 'month_of_salary',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Fixed Net Salary',
            dataIndex: 'monthly_net_salary_non_cash',
            render: (text) => text ? text : '-',
        },
        {
            title: 'OT/Variable',
            dataIndex: 'over_time_pay',
            key: 'over_time_pay',
            render: (text) => text ? text : '-',
        },
    ];

    return (

        <div className="heading_details_container">
            <div style={{ overflowX: "auto" }}>
                {nonCashIncomeData.map((data) => (
                    <div key={data.id} style={{ marginBottom: "20px" }}>
                        <div className="heading_text">{data.customer?.name}{renderEditButton(showDrawer, data.customer?.id)}</div>

                        <Table
                            className="insurance_table"
                            columns={Noncashcolumns}
                            dataSource={data.income_detail} // Wrap the data in an array to render it in the table
                            pagination={false}
                            rowKey="id"

                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default IncomeNonCashView