import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import {
  createSanctionConditionService,
  getSanctionConditionById,
  listSanctionConditionService,
  updateSanctionConditionById,
  listStatus
} from "../services/creditAnalysisServices";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  status: Yup.string()
    .required("Status is required!"),
    sanction_condition_id: Yup.string()
    .required("Condition is required!"),
    sanction_condition: Yup.string()
    .required("Status is required!")
    .min(2, "Condition must be at least 2 characters!")
    .max(1000, "Condition must be at max 1000 characters!"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const SanctionCondition = ({ refreshData, closeForm, id, open, sanction_condition_type }) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [allSanctionCondition, setAllSanctionCondition] = useState([]);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getSanctionData = async () => {
    if (id) {
      try {
        const response = await getSanctionConditionById(id);
        const {
          sanction_condition,
          sanction_condition_description,
          status
        } =
          response?.data;
        form.setFieldsValue({
          sanction_condition,
          sanction_condition_description,
          status


        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };
  const getAllStatus = async () => {
    try {
      const response = await listStatus();
      setStatus(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  useEffect(() => {
    if (open) {
      getSanctionData();
      getAllStatus();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    if (!open) {
      setShowOtherInput(false); // Reset the state when the form is closed
    }
  }, [open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.sanction_condition_type = sanction_condition_type;
      values.loan_detail = loan_id;
      if (values.sanction_condition_id === "Other") {
        // Remove the 'sanction_condition_id' field from the values object
        delete values.sanction_condition_id;
      }
      if (id) {
        const response = await updateSanctionConditionById(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Sanction Condition successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createSanctionConditionService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Sanction Condition  successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }
  };
  useEffect(() => {
    const getAllSanctionCondition = async () => {
      try {
        const response = await listSanctionConditionService();
        setAllSanctionCondition(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllSanctionCondition();
  }, [form]);

  const handleSelectChange = (value) => {
    if (value === "Other") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
  };


  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
      }}
    >
      <Row gutter={16}>
        <Col span={16}>
          {!id && (
            <Form.Item name="sanction_condition_id" label="Condition" rules={[yupSync]} required>
              <Select
                mode="single"
                placeholder="Please select"
                onChange={handleSelectChange}
              >
                {allSanctionCondition?.map((sanctionCondition) => (
                  <Select.Option key={sanctionCondition.id} value={sanctionCondition.id}>
                    {sanctionCondition.display_name}
                  </Select.Option>
                ))}
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          )}
         
        </Col>
        <Col span={12}>

        </Col>

        <Col span={16}>
        {(showOtherInput || id) && (
          <>
            <Form.Item
              name="sanction_condition"
              label="Condition"
              rules={[yupSync]} required
            >
              <Input placeholder="Please enter other condition" />
            </Form.Item>
        
          <Form.Item
            name="sanction_condition_description"
            label="Remark"
          >
            <Input placeholder="Please enter other condition" />
          </Form.Item>
          </>
  )}
        </Col>

      </Row>
      <Row gutter={16}>
        <Col span={12}>

          <Form.Item
            name="status"
            label="Status"
            rules={[yupSync]} required
          >
            <Select placeholder="Please Select" allowClear>
              {status.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>

          </Form.Item>

        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button
          onClick={closeForm}
        >Cancel</Button>
      </Space>
    </Form>
  )
}

export default SanctionCondition