import React, { useState, useEffect } from "react";
import { Tooltip, message, Select, Button, Drawer } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "../styles/loanApplication.css";
import LoanDetails from "../../loanTabDetails/loanDetails/views/LoanDetails";
import CustomerDetails from "../../customerTabDetails/customerDetails/views/CustomerDetails";
import {
  getLoanDetailsById,
  getLoanDetailsByLoanId,
  getAllStages,

  getMasterStages,
  getStageHistory,
} from "../services/loanapplicationService";
import { useParams } from "react-router-dom";
import LoanApplicationDetails from "./LoanApplicationDetails";
import Header from "../../../layout/views/Header";
import "viewerjs-react/dist/index.css";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import "../../loanSummary/styles/loanApplication.css"
import LegalInitiationForm from "../initiationForms/LegalInitiationForm/views/LegalInitiationForm";
import FIInitiationForm from "../initiationForms/FIInitiationForm/views/FIInitiationForm";
import RCUInitiationForm from "../initiationForms/RCUInitiationForm/views/RCUInitiationForm";
import PDInitiationForm from "../initiationForms/PDInitiationForm/views/PDInitiationForm";

import { useSelector } from "react-redux";
import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import TechnicalInitiationForm from "../initiationForms/TechnicalInitiatonForm/views/TechnicalInitiationForm";
import LoanDetailMainCard from "./LoanDetailMainCard";
import PrincipleInitiationForm from "../initiationForms/PricipleInititationForm/views/PrincipleInitiationForm";
import FinalApproval from "../initiationForms/FinalApprovalInitiationForm/views/FinalApproval";
import { FloatButton } from 'antd';
import CaseHealthSummary from "../../caseHealthSummary/views/CaseHealthSummary";
import {AiOutlineVerticalAlignTop } from "react-icons/ai";


const { Option } = Select;

const LoanApplication = () => {
  const { encrypted_loan_id } = useParams();
  const [loanDetails, setLoanDetails] = useState(null);
  const [, setLoanDetailsByLoanId] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [allStages, setAllStages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [title, setTitle] = useState("");
  const [isDocumentsDrawerVisible, setDocumentsDrawerVisible] = useState(false);
  const [refreshLoanDetailMainCard, setRefreshLoanDetailMainCard] = useState(false);

  const handleRefresh = () => {
    // This changes the state, which will cause the component to re-render
    setRefreshLoanDetailMainCard((prev) => !prev); 
  };


  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDocumentsDrawer = () => {
    setDocumentsDrawerVisible(true);
  };

  const closeDocumentsDrawer = () => {
    setDocumentsDrawerVisible(false);
  };

  const [masterStages, setMasterStages] = useState(null);
  const { user_data } = useSelector((state) => state.user);

  const handleSelectStage = (value) => {
    setSelectedStage(value);
  };

  const showFormDrawer = () => {
    if (!selectedStage) {
      message.error("Please select a stage before proceeding.");
      return;
    }
    setOpen(true);
  };
  const formOnClose = () => {
    setOpen(false);
    // setAllStages(null);
  };

  const fetchStages = async () => {
    try {
      const allStagesListResponse = await getAllStages(loan_id);
      const currentStageResponse = await getStageHistory(loan_id);
      const masterStagesResponse = await getMasterStages();

      let response = currentStageResponse.data;
      let stages = masterStagesResponse.data;
      const insertCode = {
        Initiated: "section_initiated",
        Completed: "section_completed",
        "Re - Initiated": "section_reinitiated",
        Query: "section_query",
        Hold: "section_hold",
      };

      const priority = [
        "Initiated",
        "Re - Initiated",
        "Query",
        "Hold",
        "Completed",
        "Cancelled"
      ];

      // First, create a dictionary to track the highest priority status for each stage
      let stagePriority = {};

      // Go through the response array to determine the highest priority status for each stage
      response.forEach((item) => {
        const stageCode = item.stage_code;
        const status = item.status;
        if (stagePriority[stageCode]) {
          // Compare the current status priority with the existing one
          const currentPriorityIndex = priority.indexOf(
            stagePriority[stageCode]
          );
          const newPriorityIndex = priority.indexOf(status);
          if (newPriorityIndex < currentPriorityIndex) {
            stagePriority[stageCode] = status;
          }
        } else {
          stagePriority[stageCode] = status;
        }
      });

      // Now, modify the stages array by adding the class_name key based on the highest priority status
      stages.forEach((stage) => {
        const stageCode = stage.code;
        if (stagePriority[stageCode]) {
          const status = stagePriority[stageCode];
          if (insertCode[status]) {
            stage.class_name = insertCode[status];
          }
        }
      });
      setMasterStages(stages);
      setAllStages(allStagesListResponse.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchLoanDetailsByLoanId = async () => {
      try {
        const response = await getLoanDetailsByLoanId(loan_id);
        setLoanDetailsByLoanId(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchLoanDetails();
    fetchLoanDetailsByLoanId();
    fetchStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id]);

  const showDrawer = async () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    fetchStages();
  };

  const handleScrollToSection = (e) => {
    if (e) e.preventDefault(); // Ensure event is passed and prevent default behavior
    document.getElementById('loan_details').scrollIntoView({ behavior: 'smooth' });
  };
  
  const CustomTooltip = () => {
    return (
      <div>
        <div style={{ marginBottom: "8px" }}>
          Loan Application Progress - Colour Code Reference
        </div>
        <div className="tooltip-content">
          <div style={{ display: "flex" }}>
            <div
              className="color-code"
              style={{ backgroundColor: "#00d91d", marginTop: "1px" }}
            ></div>
            <div>Completed</div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="color-code"
              style={{ backgroundColor: "#00c7c4", marginTop: "1px" }}
            ></div>
            <div>Initiated</div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="color-code"
              style={{ backgroundColor: "#E31557", marginTop: "1px" }}
            ></div>
            <div>Cancelled</div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="color-code"
              style={{ backgroundColor: "#c524c8", marginTop: "1px" }}
            ></div>
            <div>Re Initiated</div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="color-code"
              style={{ backgroundColor: "#15F3CE", marginTop: "1px" }}
            ></div>
            <div>Query</div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="color-code"
              style={{ backgroundColor: "#9FA5A4", marginTop: "1px" }}
            ></div>
            <div>Void</div>
          </div>
          {/* <div>
            <div
              className="color-code"
              style={{ backgroundColor: "#FFFF00" }}
            ></div>
            <span>Initiated</span>
          </div>
          <div>
            <div
              className="color-code"
              style={{ backgroundColor: "#E31557" }}
            ></div>
            <span>Cancelled</span>
          </div>
          <div>
            <div
              className="color-code"
              style={{ backgroundColor: "#268DC5" }}
            ></div>
            <span>Forwarded</span>
          </div>
          <div>
            <div
              className="color-code"
              style={{ backgroundColor: "#15F3CE" }}
            ></div>
            <span>Query</span>
          </div>
          <div>
            <div
              className="color-code"
              style={{ backgroundColor: "#9FA5A4" }}
            ></div>
            <span>Void</span>
          </div> */}
        </div>
      </div>
    );
  };
  React.useEffect(() => {
    const loanNumber = loanDetails?.loan_account_number ? ` (${loanDetails.loan_account_number})` : '';
    switch (selectedStage) {

      case "Legal":
        setTitle(`Legal ${loanNumber}`);
        break;
      case "FI":
        setTitle(`FI ${loanNumber}`);
        break;
      case "RCU":
        setTitle(`RCU ${loanNumber}`);
        break;
      case "PD":
        setTitle(`PD ${loanNumber}`);
        break;
      case "Technical":
        setTitle(`Technical ${loanNumber}`);
        break;
      default:
        setTitle("");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage]);

  // const mainApplicant = loanDetailsByLoanId?.customer_details.find(
  //   (customer_details) =>
  //     customer_details.customer_type.customer_type_name === "Applicant"
  // );

  // const firstLetter = mainApplicant ? mainApplicant.full_name.charAt(0) : "-";

//   const handleScrollToSection = (event) => {
//     event.preventDefault(); // Prevent the default anchor behavior
//     const section = document.getElementById('loan_details'); // Get the target section
//     if (section) {
//         section.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the section
//     }
// };

  return (
    <div id="loan_details">
      <Drawer
        title={
          <Header title={"Initiate"} onClose={formOnClose} name={`${title}`} />
        }
        width={950}
        onClose={formOnClose}
        open={open}
        closable={false}
      >
        {selectedStage === "Legal" && <LegalInitiationForm
          closeForm={formOnClose}
          open={open} />}
        {selectedStage === "FI" && <FIInitiationForm
          closeForm={formOnClose}
          open={open} />}
        {selectedStage === "RCU" && <RCUInitiationForm style={{ overflowX: 'hidden' }} loan_id={loan_id} open={open} closeForm={formOnClose} />}
        {selectedStage === "PD" && <PDInitiationForm
          closeForm={formOnClose}
          open={open} />}
        {selectedStage === "Technical" && <TechnicalInitiationForm
          closeForm={formOnClose}
          open={open} />}
        {selectedStage === "In Principle Approval" && <PrincipleInitiationForm
          closeForm={formOnClose}
          open={open} />}
        {selectedStage === "Final Approval" && <FinalApproval
          closeForm={formOnClose}
          open={open} />}
      </Drawer>

      <LoanDetailMainCard refresh={refreshLoanDetailMainCard} />

      <div>
        <FloatButton
          tooltip={<div>Documents</div>}
          onClick={showDocumentsDrawer}
          style={{ marginRight: '70px' }}
        />
       {/* <a
    href="#loan_details"
    onClick={handleScrollToSection}
    className="scroll-button"
>
    <FloatButton.BackTop
        visibilityHeight={0}
        style={{ marginRight: '1px' }}
    />
   
</a> */}

<a
  href="#loan_details"
  className="scroll-button"
  style={{ display: 'inline-block' }}
>
  <Button
    // type="primary"
    shape="circle"
    icon={<AiOutlineVerticalAlignTop style={{ fontSize: '20px' }} />}
    onClick={handleScrollToSection} 
    className="floatButton"
   
    style={{
      width: '40px',   
      height: '40px',   
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '1px',
      marginBottom:'13px',
      fontSize: '24px' ,
      background: '#ffffff',
      transition: 'background-color 0.2s',
      border:'1px solid #ffffff',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    }}
  />
</a>



        <Drawer
          title={
            <Header
              title=<div className='home_application_summary_heading'>Application Summary</div>
              onClose={closeDocumentsDrawer}
              visible={isDocumentsDrawerVisible}
            />
          }
          width={1200}

          onClose={closeDocumentsDrawer}
          open={isDocumentsDrawerVisible}
          body-Style={{ paddingBottom: 80 }}
          closable={false}
          loan_id={loan_id}
        >
          <CaseHealthSummary
            loan_id={loan_id}
          />
        </Drawer>
      </div>


      <Drawer
        title={
          <Header
            title="View"
            onClose={onCloseDrawer}
            name="Application Stages"
          />
        }
        width={1050}
        onClose={onCloseDrawer}
        open={drawerVisible}
        closable={false}
      >
        <LoanApplicationDetails open={drawerVisible} />
      </Drawer>


      <div className="loan_application_progress">
        <div className="loan_application_progress_content">
          <div className="left_content">
            <p className="tooltip-text">Loan Application Progress</p>
            <Tooltip
              title={<CustomTooltip />}
              overlayClassName="custom-tooltip"
            >
              <QuestionCircleOutlined
                style={{
                  color: "#667085",
                  fontSize: "17px",
                }}
              />
            </Tooltip>
            <Button type="link" className="link_button" onClick={showDrawer}>
              <u> View Detailed Progress </u>
            </Button>
          </div>
          <div className="right_content">
            <Select
              placeholder="Select Stage "
              className="select_stage"
              onChange={handleSelectStage}
            >
              {allStages
                ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map((stage) => {
                  return (
                    <Option key={stage.id} value={stage.display_name}>
                      {stage.display_name}
                    </Option>
                  );
                })}
            </Select>
            <Button
              onClick={showFormDrawer}
              type="primary"
              className="go_button" >
              Go
            </Button>
          </div>
        </div>

        <div className="container">
          {masterStages?.map((stage) => {
            const stageClass = stage?.class_name || "section_default";
            return (
              <div key={stage.code} className={`section ${stageClass}`}>
                {stage.stage}
              </div>
            );
          })}
        </div>
      </div>

      <ConditionalRender
        userPermissions={user_data?.permissions}
        requiredPermissions={["MPTECR1"]}
      >
        <CustomerDetails loanDetails={loanDetails} />
      </ConditionalRender>

      <ConditionalRender
        userPermissions={user_data?.permissions}
        requiredPermissions={["MPOADI9"]}
      >
        <LoanDetails loanDetails={loanDetails} onRefresh={handleRefresh} />
        {/* <PartDisbursedTable/> */}
      </ConditionalRender>
    </div>
  );
};

export default LoanApplication;
