import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getAllRolesService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;
      let path = `/api/v1/master/roles/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        path = `/api/v1/master/roles/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPermissionsService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/permissions/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listRolesService = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/roles/list`);
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};
export const getRoleByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/roles/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createRoleService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/master/roles/", data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateRoleByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/master/roles/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteRoleByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/master/roles/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listUserService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/user/get-user-list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
