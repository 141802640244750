import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
    PlusCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    FilterOutlined
} from "@ant-design/icons";
import Header from "../../../layout/views/Header";
import DataTable from "../../../../utils/dataTable/DataTable";
import { deleteTechincalDocumentByIdService } from "../services/techincalDocumentServices";
import TechnicalForm from "./TechnicalForm";
import TechnicalDocumentFilterFrom from "./TechnicalDocumentFilterFrom";





const TechnicalDocument = () => {

    const { Title } = Typography;
    const [technical, setTechnical] = useState([]);
    const [refreshTableData, setRefreshTableData] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedTechnical, setSelectedTechnical] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [actionPerformed, setActionPerformed] = useState(false);

    // Filter Status
    const [showFilterForm, setShowFilterForm] = useState(false);
    const [filterFormData, setFilterFormData] = useState({
        filter: false,
        is_active: null,
        is_verified: null,
    });

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setSelectedTechnical(null);
    };

    const closeFilterForm = () => {
        setShowFilterForm(false);
    };

    const toggleRefreshTableData = () => {
        setRefreshTableData((prev) => !prev);
    };

    useEffect(() => {
        if (!showFilterForm && actionPerformed) {
            toggleRefreshTableData();
            setActionPerformed(false); // Reset the action performed state
        }
    }, [showFilterForm, actionPerformed]);
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            fixed: "left",
            width: 200,
        },
        {
            title: "Display Name",
            dataIndex: "display_name",
            sorter: true,
            render: (display_name) => {
                return display_name !== null ? display_name : "-";
            },
            width: 200,
        },
        {
            title: "Code",
            dataIndex: "code",
            sorter: true,
            render: (code) => {
                return code !== null ? code : "-";
            },
        },

        {
            title: "Is Active",
            dataIndex: "is_active",
            render: (_, record) => {
                return record.is_active ? (
                    <Badge
                        text="Active"
                        color="#52C41A"
                        style={{ fontSize: "16px", color: "#52C41A" }}
                    />
                ) : (
                    <Badge
                        text="Inactive"
                        color="#FF4D4F"
                        style={{ fontSize: "16px", color: "#FF4D4F" }}
                    />
                );
            },
        },
        {
            title: "Is Verified",
            dataIndex: "is_verified",
            render: (_, record) => {
                return record.is_verified ? (
                    <Badge
                        text="Verified"
                        color="#52C41A"
                        style={{ fontSize: "16px", color: "#52C41A" }}
                    />
                ) : (
                    <Badge
                        text="Unverified"
                        color="#FF4D4F"
                        style={{ fontSize: "16px", color: "#FF4D4F" }}
                    />
                );
            },
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (_, record) => {
                return record.created_by.name;
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
        },
        {
            title: "Modified By",
            dataIndex: "modified_by",
            render: (_, record) => {
                return record.modified_by.name;
            },
        },
        {
            title: "Modified At",
            dataIndex: "modified_at",
        },
        {
            title: "Actions",
            fixed: "right",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                setSelectedTechnical(record);
                                showDrawer();
                            }}
                        />
                        <DeleteOutlined
                            onClick={() => {
                                setSelectedTechnical(record);
                                setOpenModal(true);
                            }}
                        />
                    </Space>
                );
            },
        },
    ];


    const deleteStatus = async () => {
        try {
            const response = await deleteTechincalDocumentByIdService(selectedTechnical.id);

            if (response.status === 200 && response.success === true) {
                message.success("Technical Document deleted successfully");
                setOpenModal(false);
                toggleRefreshTableData();
                setSelectedTechnical(null);
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };
    return (
        <div>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <Title level={2} align="center">
                    Technical Document
                </Title>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={() => setShowFilterForm(true)}
                        icon={<FilterOutlined />}
                        style={{ marginLeft: "1rem" }}
                    >
                        More Filters
                    </Button>
                    <Button
                        onClick={showDrawer}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        style={{ marginLeft: "1rem" }}
                    >
                        Add Technical Document
                    </Button>
                </div>
            </Space>

            <DataTable
                apiPath="/api/v1/master/technical-document/"
                tableData={technical}
                setTableData={setTechnical}
                tableColumns={columns}
                refreshData={refreshTableData}
                searchPlaceholder={"Type to search"}
                filters={filterFormData}
            />
            <Drawer
                title={<Header title={selectedTechnical ? "Edit" : "Add"} onClose={onClose} name="Technical Document" />}
                width={720}
                onClose={onClose}
                open={open}
                closable={false}
            >
                <TechnicalForm
                    refreshData={toggleRefreshTableData}
                    id={selectedTechnical && selectedTechnical.id}
                    open={open}
                    closeForm={onClose}
                    isEditMode={!!selectedTechnical}

                />
            </Drawer>
            <Drawer
                title={
                    <Header
                        title={"Filter"}
                        onClose={closeFilterForm}
                        name="Technical Document"
                    />
                }
                width={720}
                onClose={closeFilterForm}
                open={showFilterForm}
                closable={false}
            >
                <TechnicalDocumentFilterFrom
                    open={showFilterForm}
                    closeForm={closeFilterForm}
                    filterFormData={filterFormData}
                    setFilterFormData={setFilterFormData}
                    toggleRefreshTableData={toggleRefreshTableData}
                    setActionPerformed={setActionPerformed}

                />
            </Drawer>
            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    deleteStatus();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete ${selectedTechnical?.name}?`}</p>
            </Modal>
        </div>
    );
};

export default TechnicalDocument;
