
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import { createVastuAccountService, getVastuAccountByIdService, listBankService, updateVastuAccountByIdService } from "../services/vastuAccountServices";

const { Option } = Select;

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .required("Code is required")
        .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
        .min(8, "Code must be at least 8 characters")
        .max(8, "Code must be at max 8 characters")
        .label("Code"),
    name: Yup.string()
        .required("Name is required")
        // .matches(/^[A-Za-z ]+$/, 'Name can only contain letters and spaces')
        .min(2, "Name must be at least 2 characters")
        .max(128, "Name must be at max 128 characters")
        .label("Name"),
    display_name: Yup.string()
        .required("Display name is required")
        // .matches(/^[A-Za-z ]+$/, 'Display name can only contain letters and spaces')
        .min(2, "Display name must be at least 2 characters")
        .max(128, "Display name must be at max 128 characters")
        .label("Display name"),
    account_no: Yup.string()
        .required("Account number is required")
        .matches(/^\d+$/, "Account number can only contain digits")
        .min(9, "Account number must be between 9 and 18 digits")
        .max(18, "Account number must be between 9 and 18 digits"),
    is_active: Yup.string()
        .required("Is Active is required!"),
    is_verified: Yup.string()
        .required("Is Verified is required!"),
    bank: Yup.string().required("Bank is required").min(1, "Bank is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const VastuAccountForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
    const [form] = Form.useForm();
    const [allBanks, setAllBanks] = useState([]);
    const [displayNameSynced, setDisplayNameSynced] = useState(true);


    const getVastuAccountData = async () => {
        if (id) {
            try {
                const response = await getVastuAccountByIdService(id);
                const { name, code, display_name, account_no, bank, is_active, is_verified } =
                    response?.data;
                form?.setFieldsValue({
                    name,
                    code,
                    display_name,
                    bank: bank?.id,
                    account_no,
                    is_active,
                    is_verified,
                });
                setDisplayNameSynced(true);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }
    };


    const handleSubmit = async (values) => {
        try {
            if (id) {
                const response = await updateVastuAccountByIdService(id, values);
                if ((response.status = 200 && response?.success)) {
                    message.success("Vastu Account successfully updated");
                    refreshData();
                    closeForm();
                }
            } else {
                const response = await createVastuAccountService(values);
                if ((response.status = 201 && response?.success)) {
                    message?.success("Vastu Account successfully created");
                    refreshData();
                    closeForm();
                }
            }
            setDisplayNameSynced(true);
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };


    useEffect(() => {
        if (open) {
            getVastuAccountData();
        } else {
            form.resetFields();
            setDisplayNameSynced(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleNameChange = (e) => {
        let newValue = e.target.value.replace(/[^A-Za-z0-9-\s]/g, "");
        newValue = capitalizeWords(newValue);
        form.setFieldsValue({ name: newValue });
        if (displayNameSynced) {
            form.setFieldsValue({ display_name: newValue });
        }
    };

    const handleDisplayNameChange = (e) => {
        const newValue = e.target.value.replace(/[^A-Za-z0-9-\s]/g, "");
        if (newValue === "") {
            setDisplayNameSynced(true); // Reset sync state if display_name is cleared
        } else {
            setDisplayNameSynced(false);
        }
        form.setFieldsValue({ display_name: newValue });
    };

    useEffect(() => {
        const getAllBanks = async () => {
            try {
                const response = await listBankService();
                setAllBanks(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };

        getAllBanks();
    }, [form]);

    const handleSelectedLoans = (value) => { };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                name: "",
                display_name: "",
                code: "",
                is_active: true,
                is_verified: true,
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="name" label="Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Name"
                            onChange={handleNameChange}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Display Name"
                            onChange={handleDisplayNameChange}
                        />
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="account_no" label="Account Number" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Account Number"
                            // onChange={handleDisplayNameChange}
                            onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, ''); }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="bank" label="Bank" rules={[yupSync]} required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            onChange={handleSelectedLoans}
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allBanks?.map((bank) => {
                                return (
                                    <Select.Option key={bank.id} value={bank.id}>
                                        {bank.display_name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                {isEditMode ? (
                    <Col span={12}>
                        <Form.Item name="code" label="Code" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Code" onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }} />
                        </Form.Item>
                    </Col>
                ) : null}

                <Col span={12}>
                    <Form.Item name="is_active" label="Is Active" required>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="is_verified" label="Is Verified" required>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default VastuAccountForm;
