import React, { useState, useEffect } from 'react';
import { AiOutlineUp } from "react-icons/ai";
import ValuationDetails from '../../valuationDetails/views/ValuationDetails';
import PropertyDetails from '../../propertyDetails/views/PropertyDetails';
import DocumentDetails from '../../documentDetails/views/DocumentDetails';
import DecisionDetails from '../../decisionDetails/views/DecisionDetails';
import Trail from '../../trail/views/Trail';
import { Tabs, message } from 'antd';
import "../styles/details.css";
// import adhar from "../assets/image 192 (1).png";
import { getCreditAssessmentData } from "../services/details"
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import LoanSummaryBlock from '../../../../../utils/loanSummaryBlock/LoanSummaryBlock';
const { TabPane } = Tabs;

const Details = ({typeCheck}) => {
  const [creditAssessmentStageData, setCreditAssessmentStageData] =
    useState(null);
  const { encrypted_stage_id } = useParams();
  const [mode] = useState("top");



  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
    console.log("stage", stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchCreditAssessmentStageData = async () => {
      try {
        const data = await getCreditAssessmentData(stage_id);
        setCreditAssessmentStageData(data.data);
      } catch (error) {
        message.error(
          error.response?.data?.message ||
          "An error occurred while fetching data"
        );
      }
    };
    fetchCreditAssessmentStageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    { label: "Valuation Details", component: <ValuationDetails stage_id={stage_id} /> },
    { label: "Property Details", component: <PropertyDetails stage_id={stage_id} /> },
    { label: "Document Details", component: <DocumentDetails stage_id={stage_id} /> },
    { label: "Decision Details", component: <DecisionDetails /> },
    { label: "Trail", component: <Trail /> },
  ];

  return (
    <>
      <div className="technical_valuation_first_container">
        <div>
          <h3 className="technical_heading">Technical </h3>
        </div>

      </div>
     <LoanSummaryBlock data={creditAssessmentStageData} typeCheck={typeCheck}/>

      <div className="customer_main_container" style={{ marginTop: "30px" }}>
        <div className="customer_main_heading">
          <div className="customer_heading">
            <h2 className="customer_details_heading">Details</h2>
          </div>
          <div className="customer_icon">
            <AiOutlineUp />
          </div>
        </div>



        <div>
          <Tabs tabPosition={mode} type="card" style={{ margin: "20px" }}>
            {tabs.map((tab, index) => (
              <TabPane tab={tab.label} key={index}>
                {tab.component}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Details;
