import {axiosRequest} from "../../../utils/api/axiosRequest"


export const getDocumentDetailServiceByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/legal-document-collection-detail/legal-document-collection-and-upload-document/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getDocumentDetailServiceById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/legal-document-collection-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createDocumentDetailServiceById = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/legal-document-collection-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateDocumentDetailServiceById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/legal-document-collection-detail/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteDocumentDetailServiceById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/legal-document-collection-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createLegalUploadDocumentDetailService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/legal-document-upload/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteLegalUploadDocumentDetailServiceById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/legal-document-upload/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listDocumentTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/document-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLegalIploadDocumentTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/legal-upload-document-type-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


