import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, message, Select } from "antd";
import * as Yup from "yup";
import { Radio } from "antd";
import { getPropertyTechnicalDataByIdService } from "../../valuationDetails/services/valuationDetailsServices";
import {
  updateTechnicalPropertyDetailsByIdService,
  getByLawChoicesService,
  getRiskDemolitionChoiceService
} from "../services/propertyDetailsServices"; 

const validationSchema = Yup.object().shape({
  as_per_approved: Yup.string().required(
    "Availability of Approved Plan is required"
  ),
  local_byelaws: Yup.string().required(
    "Designation of the authority is required"
  ),
  construction_permission_no: Yup.string().required(
    "Construction Permission Number is required"
  ),
  dcr_norms: Yup.string()
    .required("DCR norms field is required")
    .label("DCR norms"),
  floors_permitted: Yup.string()
    .required("Number of floors permitted is required")
    .label("Number of floors permitted"),
  floors_constructed: Yup.string()
    .required("Number of floors constructed is required")
    .label("Number of floors constructed"),
  fsi_achieved: Yup.string()
    .required("FSI achieved field is required")
    .label("FSI achieved"),
  risk_of_demolition: Yup.string()
    .required("Risk of demolition field is required")
    .label("Risk of demolition"),
  setback_deviation: Yup.string()
    .required("Setback Deviation field is required")
    .label("Setback Deviation"),
  vertical_deviation: Yup.string()
    .required("Vertical Deviation field is required")
    .label("Vertical Deviation"),
  extension_in_future: Yup.string()
    .required("Any extension in future field is required")
    .label("Any extension in future"),
});

const { Option } = Select;

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PlanApprovalForm = ({
  onChange,
  value,
  refreshData,
  id,
  open,
  closeForm,
  stage_id
}) => {
  const [form] = Form.useForm(); 
  const [technicalid, setTechnicalid] = useState("");
  const [laws, setLaws] = useState([]);
  const [risks, setRisks] = useState([]);
 
  const getPersonalData = async () => {
    try {
      const response = await getPropertyTechnicalDataByIdService(stage_id);
      setTechnicalid(response.data.technical_detail[0].id);
      let { as_per_approved, local_byelaws, construction_permission_no, dcr_norms, floors_permitted, floors_constructed, fsi_achieved, risk_of_demolition, setback_deviation, vertical_deviation, extension_in_future  } =
        response?.data?.technical_detail[0];
      const dcrNormsValue = dcr_norms ? 'true' : 'false'; // 'dcr_norms' is not defined
      form?.setFieldsValue({ 
        as_per_approved,
        local_byelaws,
        construction_permission_no,
        dcr_norms: dcrNormsValue, // 'dcr_norms' is not defined
        floors_permitted, // 'floors_permitted' is not defined
        floors_constructed, // 'floors_constructed' is not defined
        fsi_achieved, // 'fsi_achieved' is not defined
        risk_of_demolition, // 'risk_of_demolition' is not defined
        setback_deviation, // 'setback_deviation' is not defined
        vertical_deviation, // 'vertical_deviation' is not defined
        extension_in_future // 'extension_in_future' is not defined
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  
  const getAllAuthority = async () => {
    try {
      const response = await getByLawChoicesService();
      setLaws(response?.data?.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Authorities Fetched successfully");
        }
      }
    } catch (error) {
      message.error(
        error.response?.data?.message ||
        "An error occurred while updating Authorities"
      );
    }
  };

  const getAllRiskChoices = async () => {
    try {
      const response = await getRiskDemolitionChoiceService();
      setRisks(response?.data?.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Authorities Fetched successfully");
        }
      }
    } catch (error) {
      message.error(
        error.response?.data?.message ||
          "An error occurred while updating Authorities"
      );
    }
  };

  useEffect(() => {
    if (open) {
      getPersonalData();
      getAllAuthority();
      getAllRiskChoices();
    } else {
      form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form, id, stage_id]);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
    };
    try {
      const response = await updateTechnicalPropertyDetailsByIdService(
        technicalid,
        payload
      );

      if (response && response?.data) {
        if (response?.status === 200 && response?.success) {
          message.success("Plan Approval Details updated successfully");
          refreshData();
          closeForm();
        }
      } else {
        message.error("Failed to update Plan Approval Details");
      }
      //   }
    } catch (error) {
      closeForm();
      message.error(
        error.response?.data?.message ||
        "An error occurred while updating personal details"
      );
    }
  };

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleCharacterFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;
  
    // Remove all characters except digits, alphabetic characters, and decimal points
    newValue = newValue.replace(/[^0-9a-zA-Z.]/g, "");
  
    // Ensure that only one decimal point is allowed
    const decimalCount = (newValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
    }
  
    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        as_per_approved: "",
        local_byelaws: "",
        construction_permission_no: "",
      }}
    >

<Row gutter={16} style={{ marginBottom: '16px' }}>





<Col span={8}>
    <Form.Item
        name="dcr_norms"
        label="Is the plan complying DCR norms"
        rules={[{ validator: yupSync.validator, field: "dcr_norms" }]}
        required
    >
        <Select
            placeholder="Select Option"
            onChange={(value) => form.setFieldsValue({ dcr_norms: value })}
            allowClear
        >
            {/* Conditionally render options based on dcr_norms value */}
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
        </Select>
    </Form.Item>
</Col>



<Col span={8}>
<Form.Item
name="floors_permitted"
label=" Number of floors permitted "
rules={[yupSync]}
required
>
<Input
placeholder="Please Enter  Number of floors permitted"
onChange={(e) => handleNumberFields(e, "floors_permitted")}
/>
</Form.Item>
</Col>

<Col span={8}>
    <Form.Item name="floors_constructed" label=" Number of floors constructed" rules={[yupSync]} required>
        <Input placeholder="Please Enter  Number of floors constructed rate (₹) *"
            onChange={(e) => handleNumberFields(e, "floors_constructed")}
        />
    </Form.Item>
</Col>

</Row>

<Row gutter={16} style={{ marginBottom: "16px" }}>
<Col span={8}>
<Form.Item
name="fsi_achieved"
label="FSI achieved"
rules={[yupSync]}
required
>
<Input
placeholder="Please Enter FSI achieved"
onChange={(e) => handleCharacterFields(e, "fsi_achieved")}
/>
</Form.Item>
</Col>

<Col span={8}>
    <Form.Item name="risk_of_demolition" label="Risk of demolition " rules={[yupSync]} required>
        <Select placeholder="Search To Select" style={{ width: '100%' }} allowClear>
            {risks?.map((law, index) => (
                <Option key={law.label} value={law.label}>
                    {law.label}
                </Option>
            ))}
        </Select>
    </Form.Item>

</Col>

<Col span={8}>
<Form.Item
name="setback_deviation"
label="Setback Deviation(%)"
rules={[yupSync]}
required
>
<Input
placeholder="Please Enter Setback Deviation(%)"
onChange={(e) => handleNumberFields(e, "setback_deviation")}
/>
</Form.Item>
</Col>
</Row>

<Row gutter={16} style={{ marginBottom: "16px" }}>
<Col span={8}>
<Form.Item
name="vertical_deviation"
label="Vertical Deviation(%)"
rules={[yupSync]}
required
>
<Input
placeholder="Please Enter Vertical Deviation(%)"
onChange={(e) => handleNumberFields(e, "vertical_deviation")}
/>
</Form.Item>
</Col>

<Col span={8}>
    <Form.Item name="extension_in_future" label="Any extension in future?" rules={[yupSync]} required>
        <Input placeholder="Please Enter Any extension in future?"
            onChange={(e) => handleCharacterFields(e, "extension_in_future")} 
        />
    </Form.Item>
</Col>
<Col span={8}>
          <Form.Item
            name="as_per_approved"
            label="Availability of Approved Plan / Sanction Plan "
            rules={[yupSync]}
            required
          >
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio value={0}>Yes</Radio>
                <Radio value={1}>No</Radio>
                <Radio value={2}>Not Applicable</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
</Row>


      <Row gutter={16} style={{ marginBottom: "16px" }}>

        <Col span={8}>
          <Form.Item
            name="local_byelaws"
            label="Designation of the authority"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Search To Select" style={{ width: "100%" }} allowClear>
              {laws?.map((law, index) => (
                <Option key={law.label} value={law.value}>
                  {law.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="construction_permission_no"
            label=" Construction Permission Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Construction Permission Number"
              onChange={(e) => handleNumberFields(e, "requested_tenure")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PlanApprovalForm;
