import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getOfficeTypeChoice,
  getBusinessPremisesOwner,
  getSurroundings,
  getOfficeLocationTypeChoice
} from "../../services/getCustomerDetailData";
import { updateBusinessPremisesDetails } from "../../services/applicantOvServices";
import * as Yup from "yup";

const BusinessPremisesEditForm = ({ customerDetails,closeForm,updateId ,refreshData,open}) => {
  const [form] = Form.useForm();
  const [businessPremisesOwner, setBusinessPremisesOwner] = useState([]);
  const [surroundings, setSurroundings] = useState([]);
  const [officeTypeChoice, setOfficeTypeChoice] = useState([]);
  const [officeLocationTypeChoice, setOfficeLocationTypeChoice] = useState([]);
  const { Option } = Select;

  const validationSchema = Yup.object().shape({
    ownership: Yup.string().required("Business Premises Ownership is required"),
    company_board: Yup.boolean().required("Company Board is required"),
    activity: Yup.boolean().required("Activity is required"),
    setup: Yup.boolean().required("Setup is required"),
    surrounding: Yup.array().min(1, "At least one surrounding is required"),
    office_type: Yup.string().required("Type of office is required"),
    office_location: Yup.string().required("Location of office is required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getBusinessPremisesOwner, setBusinessPremisesOwner);
    fetchAllData(getSurroundings, setSurroundings);
    fetchAllData(getOfficeTypeChoice, setOfficeTypeChoice);
    fetchAllData(getOfficeLocationTypeChoice, setOfficeLocationTypeChoice);
  }, []);


  const getPremisesData = async () => {
    if (updateId) {
      try {
        const { ownership, company_board, activity, surrounding, office_type, office_location, setup } = customerDetails;
        const parsedSurroundings = JSON.parse(surrounding.replace(/'/g, '"'));
        form.setFieldsValue({
          ownership,
          company_board,
          activity,
          setup,
          surrounding:parsedSurroundings,
          office_type,
          office_location,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };
  console.log('surrounding:', customerDetails?.surrounding);
  const handleSubmit = async (values) => {
    const formattedSurrounding = JSON.stringify(values.surrounding).replace(
      /"/g,
      "'"
    );

    const payload = {
      ...values,
      surrounding: formattedSurrounding, // Use the formatted string in the payload
    };

    try {
      if (updateId ) {
        const response = await updateBusinessPremisesDetails(updateId, payload);
        if ((response.status = 200 && response.success)) {
          message.success("Business Premises Details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (open) {
      getPremisesData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="ownership"
              label="Business Premises Ownership"
              rules={[yupSync]} 
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {businessPremisesOwner?.map((business) => (
                  <Option value={business}>{business}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="company_board" label="Company Board" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="activity" label="Activity" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
              {/* <Input
                                placeholder="Please Enter Activity"
                                onChange={(e) => handleInput(e, "activity")}
                            /> */}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="setup" label="Setup" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="surrounding" label="Surrounding" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {surroundings?.map((surrounding) => (
                  <Option key={surrounding} value={surrounding}>
                    {surrounding}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="office_type" label="Type of Office" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {officeTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="office_location"
              label="Location of Office"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {officeLocationTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
              {/* <Input
                placeholder="Please Enter Location of Office"
                onChange={(e) => handleInput(e, "office_location")}
              /> */}
            </Form.Item>
          </Col>
        </Row>

        <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      > 
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
      </Form>
    </div>
  );
}

export default BusinessPremisesEditForm ;
