import React, { useEffect } from "react";
import { Button, Form, Input, Select, Space, Table } from "antd";

const VehicleForm = ({
  closeForm,
  vehicle,
  ownerShip,
  selectedScrutiny,
  editValue,
  setEnteredData,
  refreshData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectedScrutiny) {
      form.resetFields();
    }
    if (editValue) {
      form.setFieldsValue({
        documents: [
          {
            vehicle_type: editValue.vehicle_type,
            key: editValue.key,
            model_no: editValue.model_no,
            ownership_type: editValue.ownership_type,
            registration_number: editValue.registration_number,
            financed_from: editValue.financed_from,
            loan_amount: editValue.loan_amount,
          },
        ],
      });
    }
  }, [selectedScrutiny, form, editValue]);

  const handleSubmit = (values) => {
    setEnteredData(values.documents); // Pass the entered data to the parent component
    form.resetFields();
    closeForm(); // Close the form after submitting
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.List name="documents" initialValue={[{ document: "", dated: "" }]}>
        {(fields, { add, remove }) => {
          const columns = [
            {
              title: "Sr. No",
              dataIndex: "key",
              key: "sr_no",
              render: (text, record, index) => index + 1,
            },
            {
              title: "Vehicle Type",
              dataIndex: ["vehicle_type"],
              key: "vehicle_type",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "vehicle_type"]}
                  rules={[
                    { required: true, message: "Vehicle type is required" },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    allowClear
                    style={{ width: "150px" }} // Set a fixed width here
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {vehicle?.map((veh) => (
                      <Select.Option key={veh} value={veh}>
                        {veh}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ),
            },
            {
              title: "Model no",
              dataIndex: ["model_no"],
              key: "model_no",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "model_no"]}
                  rules={[{ required: true, message: "Please enter Model No" }]}
                >
                  <Input placeholder="Enter Model No" />
                </Form.Item>
              ),
            },
            {
              title: "Ownership Type",
              dataIndex: ["ownership_type"],
              key: "ownership_type",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "ownership_type"]}
                  rules={[
                    { required: true, message: "Ownership type is required" },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    allowClear
                    style={{ width: "150px" }} 
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {ownerShip?.map((own) => (
                      <option value={own}>{own}</option>
                    ))}
                  </Select>
                  {/* <Input placeholder="Enter Ownership Type" /> */}
                </Form.Item>
              ),
            },

            {
              title: "Vehicle No",
              dataIndex: ["registration_number"],
              key: "registration_number",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "registration_number"]}
                  rules={[
                    { required: true, message: "Vehicle no is required" },
                  ]}
                >
                  <Input placeholder="Enter Vehicle No" />
                </Form.Item>
              ),
            },
            {
              title: "Financed From",
              dataIndex: ["financed_from"],
              key: "financed_from",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "financed_from"]}
                  rules={[
                    { required: true, message: "Financed from is required" },
                  ]}
                >
                  <Input placeholder="Enter Financed From" />
                </Form.Item>
              ),
            },
            {
              title: "Loan Amount",
              dataIndex: ["loan_amount"],
              key: "loan_amount",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "loan_amount"]}
                  rules={[
                    { required: true, message: "Loan amount is required" },
                    {
                      pattern: /^\d+$/,
                      message: "Loan amount must be a number",
                    },
                  ]}
                >
                  <Input placeholder="Enter Loan Amount" />
                </Form.Item>
              ),
            },
          ];

          return (
            <>
              <Table
                dataSource={fields.map((field) => ({
                  ...field,
                  key: field.key,
                }))}
                columns={columns}
                pagination={false}
              />
              {!selectedScrutiny && (
                <Button
                  type="primary"
                  onClick={() => add()}
                  style={{
                    marginTop: "20px",
                    position: "fixed",
                  }}
                >
                  Add Vehicle
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "20px",
          position: "fixed",
          bottom: "100px",
          right: "20px",
          left: "20px",
        }}
      >
        <Button type="primary" htmlType="submit">
          Ok
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default VehicleForm;
