import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createPincodeService,
  getPincodeByIdService,
  listAllCityService,
  listAllCountryService,
  listAllDistrictService,
  listAllStateService,
  listAllTalukaService,
  updatePincodeByIdService,
} from "../services/pinecodeServices";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
    name: Yup.string()
    .required("Name is required")
    .matches(/^\d{6}$/, 'Name must be a 6 digit pincode')
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^\d{6}$/, 'Display name must be a 6 digit pincode')
    .label("Display name"),
  state: Yup.string().required("state is required!").min(1, "state is required"),
  city: Yup.string().required("city is required!").min(1, "city is required"),
  district: Yup.string().required("District is required").min(1, "District is required"),
  taluka: Yup.string().required("Taluka is required").min(1, "Taluka is required"),
  country: Yup.string().required("Country is required").min(1, "Country is required"),
  is_negative: Yup.string().required("Is negative is required").min(1, "Is negative is required"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PinCodeForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allState, setAllState] = useState([]);
  const [allcity, setAllCity] = useState([]);
  const [allDistrict, setAllDistrict] = useState([]);
  const [allTaluka, setAllTaluka] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);

  const getPincodeData = async () => {
    if (id) {
      try {
        const response = await getPincodeByIdService(id);
        const {
          name,
          code,
          display_name,
          state,
          city,
          district,
          taluka,
          country,
          is_negative,
          is_active,
          is_verified,
        } = response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          state:state?.id,
          city:city?.id,
          district:district?.id,
          taluka:taluka?.id,
          country:country?.id,
          is_negative,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true); 
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updatePincodeByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Pincode successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createPincodeService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Pincode successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true); 
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  // Fetch department data when the form is initially opened or for editing
  useEffect(() => {
    if (open) {
      // If it's opened for editing, fetch department data
      getPincodeData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^0-9]/g, "");
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^0-9]/g, "");
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true);
    } else {
      setDisplayNameSynced(false);
    }
  };
  

  useEffect(() => {
    const getAllState = async () => {
      try {
        const response = await listAllStateService();

        setAllState(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllState();
  }, [form]);

  const handleSelectedState = (value) => {};

  useEffect(() => {
    const getAllCity = async () => {
      try {
        const response = await listAllCityService();

        setAllCity(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllCity();
  }, [form]);

  useEffect(() => {
    const getAllDistrict = async () => {
      try {
        const response = await listAllDistrictService();

        setAllDistrict(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllDistrict();
  }, [form]);

  useEffect(() => {
    const getAllTaluka = async () => {
      try {
        const response = await listAllTalukaService();

        setAllTaluka(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllTaluka();
  }, [form]);

  useEffect(() => {
    const getAllCountry = async () => {
      try {
        const response = await listAllCountryService();

        setAllCountry(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllCountry();
  }, [form]);



  const handleSelectedCity = (value) => {};

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>
        
        <Col span={12}>
          <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Code"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              onChange={handleSelectedCity}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allcity?.map((city) => {
                return (
                  <Select.Option key={city.id} value={city.id}>
                    {city.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              onChange={handleSelectedState}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allState?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="district" label="District" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              onChange={handleSelectedState}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allDistrict?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              onChange={handleSelectedState}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allTaluka?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              onChange={handleSelectedState}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allCountry?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_negative" label="Is Negative" rules={[yupSync]} required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PinCodeForm;
