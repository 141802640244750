import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllSchemaService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;

      let path = `/api/v1/master/scheme/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        
        path = `/api/v1/master/scheme/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getSchemaByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/scheme/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createSchemaService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/master/scheme/", data);
                
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updatecSchemaByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/master/scheme/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteSchemaByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/master/scheme/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listAllLoansService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listUserService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/user/get-user-list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};