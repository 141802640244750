import { axiosRequest } from "../../../utils/api/axiosRequest";

export const createidCardService = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post(
                "api/v1/identity-card/create-id",
                data
            );
            return resolve(response.data);
            
        } catch (error) {
            return reject(error);
        }
    });
};

export const getIDCardDetailsServicebyId = (organization, employee_id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(
                `/api/v1/identity-card/get-single-id?organization=${organization}&employee-id=${employee_id}`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};
export const getIDCardDetailsbyId = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(
                `/api/v1/identity-card/get-id-card/${id}`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const updateIDCardDetailsByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/identity-card/update-idcard/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const deleteIDCardDetailsServicebyId = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.delete(
                `/api/v1/identity-card/delete-id/${id}`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};