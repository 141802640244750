import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Typography,
  Progress,
  Avatar,
  Layout,
} from "antd";
import { StarOutlined } from "@ant-design/icons";
import { getEmployeeBirthday } from "../service/getEmployeeBirthday";
import { getEmployeeWorkAnniversery } from "../service/getEmployeeWorkAnniversery";
import cakeImage from "../assets/Group.png";
import "../style/DashBoardLiteEmployeeDetail.css";

const { Title, Text } = Typography;

const DashBoardLiteEmployeeDetail = () => {
  const [birthdayCelebrations, setBirthdayCelebrations] = useState([]);
  const [anniversaryCelebrations, setAnniversaryCelebrations] = useState([]);

  useEffect(() => {
    const getBirthdayList = async () => {
      try {
        const response = await getEmployeeBirthday();
        setBirthdayCelebrations(response.data);
      } catch (error) {
        setBirthdayCelebrations([]); // Ensure the list is empty if there is an error
      }
    };

    const getAnniversaryList = async () => {
      try {
        const response = await getEmployeeWorkAnniversery();
        if (response.data.length) {
          setAnniversaryCelebrations(response.data);
        } else {
          setAnniversaryCelebrations([]);
        }
      } catch (error) {
        console.error("Error fetching anniversary list:", error);
        setAnniversaryCelebrations([]); // Ensure the list is empty if there is an error
      }
    };

    getBirthdayList();
    getAnniversaryList();
  }, []);

  return (
    <Layout style={{ padding: "20px", backgroundColor: "#f0f5ff" }}>
      <div style={{ maxWidth: "500px" }}>
        <Card
          bordered={false}
          style={{
            borderRadius: "12px",
            marginBottom: "50px",
          }}
        >
          <Title level={4} className="title">Let's Get to Work</Title>
          <p style={{color: "rgba(0, 0, 0, 0.8)"}} className="subheading">Tuesday, Oct 15th, 2024</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "12px",
            }}
          >
            <Text>Punch-in: 09:42 AM</Text>
            <Text>
              Work Time: <strong>02:25 hrs</strong>
            </Text>
          </div>
          <Progress percent={20} showInfo={false} strokeColor="#73C9AE" />

          <Text
            type="secondary"
            style={{ display: "block", textAlign: "center", margin: "8px 0" }}
          >
            Shift: 09:30 - 18:30
          </Text>
          <Button type="primary" block>
            Punch Out
          </Button>
        </Card>

        <Card
          className="title"
          title="Birthday Celebrations"
          headStyle={{
            color: "rgb(39, 101, 143)",
            fontFamily: "poppins",
            fontSize: "20px",
            fontWeight: 500,
          }}
          style={{ marginBottom: "50px" }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // Internet Explorer 10+
            }}
          >
            <style>
              {`
      /* Hide scrollbar for WebKit-based browsers */
      div::-webkit-scrollbar {
        display: none;
      }
    `}
            </style>

            {birthdayCelebrations?.length === 0 ? (
              <div
                style={{ textAlign: "center", padding: "20px", color: "#888" }}
              >
                No birthdays today
              </div>
            ) : (
              birthdayCelebrations?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "70px",
                    marginBottom: "10px",
                    borderRadius: "12px",
                    border: "2px solid #E2BF46",
                    padding: "0 15px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={item.profile_img} />
                    <div
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <Text>{item.name}</Text>
                      <br />
                      <Text type="secondary">{item.location.name}</Text>
                    </div>
                  </div>
                  <img
                    src={cakeImage}
                    alt="cake"
                    style={{ marginTop: "25px", height: "50px" }}
                  />
                </div>
              ))
            )}
          </div>
        </Card>

        {/* <Title level={4} className="title">
          Anniversary Celebrations
        </Title> */}
        <Card
          title="Anniversary Celebrations"
          headStyle={{
            color: "rgb(39, 101, 143)",
            fontFamily: "poppins",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // Internet Explorer 10+
            }}
          >
            <style>
              {`
      /* Hide scrollbar for WebKit-based browsers */
      div::-webkit-scrollbar {
        display: none;
      }
    `}
            </style>

            {anniversaryCelebrations.length === 0 ? (
              <div
                style={{ textAlign: "center", padding: "20px", color: "#888" }}
              >
                No work anniversaries today
              </div>
            ) : (
              anniversaryCelebrations?.map((item, index) => {
                const randomColor = `#${Math.floor(
                  Math.random() * 16777215
                ).toString(16)}`;
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "70px",
                      marginBottom: "10px",
                      borderRadius: "12px",
                      border: `1px solid ${randomColor}`,
                      padding: "0 15px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar src={item.profile_img} />
                      <div style={{ marginLeft: "10px" }}>
                        <Text>{item.name}</Text>
                        <br />
                        <Text type="secondary">{item.location.name}</Text>
                      </div>
                    </div>
                    <StarOutlined
                      style={{ fontSize: "18px", color: "#ffa940" }}
                    />
                  </div>
                );
              })
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default DashBoardLiteEmployeeDetail;
