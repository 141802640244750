import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import EditButton from "../../../../utils/editButton/EditButton";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import OverallStatusEditForm from "./OverallStatusEditForm";
import { getResidenceOverallStatusByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { GoDotFill } from "react-icons/go";

const OverallStatus = ({ selectedCustomerId }) => {
  const [openEditNine, setOpenEditNine] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [collapsed9, setCollapsed9] = useState(true);
  const [overallStatusData, setOverallStatusData] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const toggleCollapse9 = () => {
    setCollapsed9(!collapsed9);
  };
  const showDrawerNine = () => {
    setOpenEditNine(true);
  };
  const onCloseNine = () => {
    setOpenEditNine(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getResidenceOverallStatusByCustomerId(
          selectedCustomerId
        );
        setOverallStatusData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, selectedCustomerId]);

  const renderEditButtonNine = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerNine();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div style={{ margin: "20px 0px 20px 0px" }}>
      <Drawer
        title={
          <Header title="Edit" onClose={onCloseNine} name="OverallStatus" />
        }
        width={990}
        onClose={onCloseNine}
        open={openEditNine}
        closable={false}
      >
        <OverallStatusEditForm
          updateId={overallStatusData?.id}
          // loan_id={loan_id}
          open={openEditNine}
          refreshData={toggleRefreshTableData}
          closeForm={onCloseNine}
          customerDetails={overallStatusData}
        />
      </Drawer>
      <div className="property_description_heading">
        <div className="property_description_data">Overall Status</div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {renderEditButtonNine()}
          </div>
          <div
            style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
            onClick={toggleCollapse9}
          >
            {collapsed9 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed9 && (
        <div className="property_description_basic_card_container">
          <div className="property_description_basic_card">
            <div className="property_description_basic_card-content">
              <div className="property_description_basic_card_label">
                Overall Status
              </div>
              <div className="property_description_basic_card_data">
                <GoDotFill
                  style={{
                    width: "12px",
                    margin: "-4px 0px 0px 0px",
                    color:
                      overallStatusData?.overall_status === "Positive"
                        ? "#389E0D"
                        : "#FF4D4F", 
                  }}
                />
                <span
                  style={{
                    color:
                      overallStatusData?.overall_status === "Positive"
                        ? "#389E0D"
                        : "#FF4D4F",
                    marginLeft: "5px",
                  }}
                >
                  {overallStatusData?.overall_status || "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="property_description_basic_card">
            <div className="property_description_basic_card-content">
              <div className="property_description_basic_card_label">
                Neighbour Check
              </div>
              <div className="property_description_basic_card_data">
                {overallStatusData?.neighbor_check || "-"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverallStatus;
