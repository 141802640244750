import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Select, Row, message } from "antd";
import Quill from "quill";
import { listOverallTypeService } from "../../services/miscellaneousDetailsService";

const { Option } = Select;

const ConclusionsInputForm = ({ yupSync,form }) => {
  const [overallstatus, setOverallstatus] = useState([]);
  const quillRef = useRef(null);

  const fetchoverallstatus = async () => {
    try {
      const response = await listOverallTypeService();
      setOverallstatus(response.data.choices);
    } catch (error) {
      console.error("Error fetching Overall Type:", error);
      message.error("Failed to fetch Overall Type");
    }
  };

  useEffect(() => {

      fetchoverallstatus();
      if (!quillRef.current) {
        quillRef.current = new Quill('#conclusionsQuillEditor', {
          theme: 'snow',
        });
  
        quillRef.current.on('text-change', () => {
          const content = quillRef.current.root.innerHTML;
          const cleanContent = content.replace(/^<p>|<\/p>$/g, ''); // Remove wrapping <p> tags
          form.setFieldValue("conclusion", cleanContent);
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <div style={{ border: "1px solid #E4E7EC", marginTop: "20px" }}>
      <div className="property_description_heading">
        <div className="property_description_data">Conclusion</div>
      </div>
      <div style={{ margin: "10px" }}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="overall_status"
              label="Overall Status"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {overallstatus?.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="conclusion"
          label="Overall Remark"
          rules={[yupSync]}
          required
        >
          <div
            id="conclusionsQuillEditor"
            style={{
              height: "130px",
              border: "1px solid #d9d9d9",
              borderRadius: "2px",
            }}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ConclusionsInputForm;
