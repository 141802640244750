import React, { useEffect, useState } from "react";
import { Card, Radio, Typography, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getTodoList } from "../service/getTodoList";
import { postTodo } from "../service/postTodo";

const DashboardLiteToDoList = () => {
  const { Text, Link } = Typography;
  const [toDoList, setToDoList] = useState([]);
  const [newToDo, setNewToDo] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    const fetchTodos = async () => {
      const response = await getTodoList();
      setToDoList(response.data);
    };

    fetchTodos();
  }, []); // Empty dependency array to fetch to-do list on component mount

  const handleAddToDo = async () => {
    if (newToDo.trim()) {
      const data = {
        title: newToDo,
        description: "first you have to present there",
        status: "Inprogress",
        priority: "High",
      };

      // Add to-do via API
      await postTodo(data);

      // Fetch updated to-do list after adding the new to-do
      const response = await getTodoList();
      setToDoList(response.data);

      setNewToDo("");
      setShowInput(false);
    }
  };

  const handleRadioChange = (id) => {
    // Move the completed item to the top of the list
    setToDoList((prevList) => {
      // Find the completed item
      const updatedList = prevList.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item
      );

      // Sort items: completed tasks move to the top
      const sortedList = updatedList.sort(
        (a, b) => (b.completed ? 1 : 0) - (a.completed ? 1 : 0)
      );

      return sortedList;
    });
  };

  return (
    <div>
      <Card
        bordered={false}
        style={{ backgroundColor: "#fff", borderRadius: 8 }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: "#fef6f5",
            border: "1px solid #f5222d",
            borderRadius: 8,
          }}
        >
          <Radio.Group style={{ width: "100%" }}>
            {toDoList.map((item) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <Radio
                  value={item.id}
                  checked={item.completed}
                  onClick={() => handleRadioChange(item.id)} // Moves completed task to the top
                />
                <Text delete={item.completed} style={{ marginLeft: 8 }}>
                  {item.title}
                </Text>
              </div>
            ))}
          </Radio.Group>

          {showInput && (
            <div
              style={{ marginTop: 16, display: "flex", alignItems: "center" }}
            >
              <Input
                value={newToDo}
                onChange={(e) => setNewToDo(e.target.value)}
                placeholder="Enter new to-do"
                style={{ marginRight: 8 }}
              />
              <Button type="primary" onClick={handleAddToDo}>
                Add
              </Button>
            </div>
          )}

          {!showInput && (
            <div
              style={{ marginTop: 16, display: "flex", alignItems: "center" }}
            >
              <PlusOutlined style={{ color: "#8c8c8c" }} />
              <Link
                style={{ color: "#1890ff", marginLeft: 8 }}
                onClick={() => setShowInput(true)}
              >
                Add To-Do
              </Link>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default DashboardLiteToDoList;
