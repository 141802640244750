import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
  Tabs,
  Upload,
  Modal,
} from "antd";
import "../styles/insuranceForm.css";
import {
  getInsuranceDetailsByIdService,
  getRelationByIdService,
  getAllCityService,
  getAllStateService,
  getallpincode,
  getAllOccuptationTypeService,
  getPersonalDetailsByIdService,
  postMagmaHDI,
  postEditMagma,
  getLoanDetailsByIdService,
  deleteHDFCDocumentDetailsByIdService,
  getMagmaPlanningList,
} from "../services/loanInsuranceService";
import moment from "moment";
import dayjs from "dayjs";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import pdf_image from "../assets/pdf.png";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import MagmaInsuredCustomerForm from "./MagmaInsuredCustomerForm";
import MagmaHDIInsurance from "./MagmaHDIInsurance";
import { listNationality } from "../../../customerTabDetails/personalDetails/services/personalDetailsServices";
import { listLocationService } from "../../../listOfApplication/services/listOfApplicationService";
import getDocumentTypeList from "../../../../fi/services/getDocumentTypeList";
import { stringToDate } from "../../../../../utils/dateConvertor/DateConvertor";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  insured_customer_name: Yup.string().required(
    "Insured Customer Name is required"
  ),
  branch_address: Yup.string().required("Branch Address is required"),
  property_type: Yup.string().required("Property Type is required"),
  occupation: Yup.string().required("Loan Amount is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pincode is required"),
  salutation: Yup.string().required("Salutation is required"),
  mobile: Yup.string().required("Mobile is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().required("Email is required"),
  address_line_1: Yup.string()
    .required("Address Line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  address_line_2: Yup.string()
    .required("Address Line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  nationality: Yup.string().required("Nationality is required"),
  date_of_birth: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Date Of Birth is required"),
  applicant_type: Yup.string().required("Applicant Type is required"),
  share_of_loan: Yup.string().required("Share Of Loan is required"),
  pan_no: Yup.string().required("PAN Number is required"),
  height: Yup.string().required("Height is required"),
  weight: Yup.string().required("Weight is required"),
  loan_amount: Yup.number().required("Loan Amount is required"),
  loan_tenure: Yup.number().required("Tenure of loan is required"),
  policy_term: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Convert empty string to null
    .nullable() // Allows the field to be null
    .required("Policy Term is required")
    .max(360, "Policy Term cannot be more than 360 months"),
  sum_assured: Yup.string().required("Sum Assured is required"),
  total_premium_incl_gst: Yup.string().required(
    "Total Premium Inclusive Of GST is required"
  ),
  emi_amount: Yup.string().required("EMI Amount is required"),
  monthly_income: Yup.string().required("Monthly Income is required"),
  age_proof: Yup.string().required("Age Proof is required"),
  nominee_name: Yup.string().required("Nominee Name is required"),
  nominee_nationality: Yup.string().required("Nationality is required"),
  nominee_email: Yup.string().required("Email is required"),
  nominee_mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^\d{10}$/, "Nominee Contact Number must be exactly 10 digits"),
  nominee_date_of_birth: Yup.date()
    .max(
      new Date(),
      "Nominee Date of Birth cannot be a today's date or future date"
    )
    .required("Nominee Date Of Birth is required"),
  nominee_gender: Yup.string().required("Gender is required"),
  nominee_address_line1: Yup.string()
    .required("Address Line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  nominee_address_line2: Yup.string()
    .required("Address Line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  relation_with_assured_person: Yup.string().required("Relation is required"),
  nominee_insured_customer_name: Yup.string().required("Name is required"),
  nominee_city: Yup.string().required("City is required"),
  nominee_state: Yup.string().required("State is required"),
  nominee_pincode: Yup.string().required("Pincode is required"),
  nominee_relation: Yup.string().required("Relation is required"),
  nominee_contact_number: Yup.string()
    .required("Nominee Contact Number is required")
    .matches(/^\d{10}$/, "Nominee Contact Number must be exactly 10 digits"),
  total_premium_amount: Yup.string().required(
    "Total Premium Amount is required"
  ),
  nominee_relationship_with_life_to_be_assured: Yup.string().required(
    "Relationship With Life To Be Assured is required"
  ),
  contact_number: Yup.string().required("Contact Number is required"),
  apointee_name: Yup.string().required("Appointee Name is required"),
  apointee_date_of_birth: Yup.date()
    .max(new Date(), "Date of Birth cannot be today's date or a future date")
    .test(
      "is-18-years-old",
      "Appointee is not yet 18 years old",
      function (value) {
        const today = new Date();
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate()
        );
        return value <= eighteenYearsAgo;
      }
    )
    .required("Appointee Date Of Birth is required"),

  apointee_gender: Yup.string().required("Appointee Gender is required"),
  apointee_contact_number: Yup.string()
    .required("Appointee Contact Number is required")
    .matches(/^\d{10}$/, "Appointee Contact Number must be exactly 10 digits"),
  tenure: Yup.string().required("Tenure is required"),
  apointee_relationship_with_life_to_be_assured: Yup.string().required(
    "Appointee Relationship With Life To Be Assured is required"
  ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const MagmaHDIInsuranceForm = ({
  id,
  insurance_id,
  customerId,
  customer_id,
  closeDrawer,
  closeForm,
  toggleRefreshTableData,
  toggleRefreshInsurance,
  setSelectedCustomer,
  setSelectedInsuranceType,
  disabledButton,
  setDisabledButton
}) => {
  const [form] = Form.useForm();
  const [relation, setRelation] = useState([]);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [datas, setDatas] = useState([]);
  const [planDetails, setPlanDetails] = useState({});
  const [count, setCount] = useState(0);
  const [occupationType, setOccupationTypes] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [planningListData, setPlanningListData] = useState();
  const [, setMatchedCustomer] = useState(null);
  const [matchedNominee, setMatchedNominee] = useState(null);
  const [nominee, setSelectedNominee] = useState();
  const [isUnder18, setIsUnder18] = useState(false);
  const [, setLoanid] = useState("");
  const [branchLocations, setBranchLocations] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [nomineeId, setNomineeId] = useState();
  const [editloanid, setEditloanid] = useState("");
  const [documentUrls, setDocumentUrls] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [image, setImage] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insurancePlanMagma, setInsurancePlanMagma] = useState();
  const [formValues, setFormValues] = useState({});
  const [appointeeId, setAppointeeId] = useState();
  const getAllRelationlist = async () => {
    try {
      const response = await getRelationByIdService();
      setRelation(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch relations."
      );
    }
  };

  const getAllCitylist = async () => {
    try {
      const response = await getAllCityService();
      setCity(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch cities."
      );
    }
  };

  const getAllState = async () => {
    try {
      const response = await getAllStateService();
      setStates(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch states."
      );
    }
  };

  const getAllNationality = async () => {
    try {
      const response = await listNationality();
      setNationality(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllPincodelist = async () => {
    try {
      const response = await getallpincode();
      setPincode(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch pincodes."
      );
    }
  };

  const getAllOccupationlist = async () => {
    try {
      const response = await getAllOccuptationTypeService();
      setOccupationTypes(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllPlanninglist = async () => {
    try {
      const response = await getMagmaPlanningList();
      setPlanningListData(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getPersonalDetailsByIdService(id);
      setPersonalData(response.data.customers);
      setLoanid(response.data.loan_detail);
    } catch (error) {
      message.error(
        `${error?.response?.status}: ${error?.response?.data?.message}`
      );
    }
  };

  const getLocationList = async () => {
    try {
      const response = await listLocationService();
      setBranchLocations(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getDocumentType = async () => {
    try {
      const response = await getDocumentTypeList();
      setDocumentTypes(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const fetchLoanData = async () => {
    try {
      const response = await getLoanDetailsByIdService(id);
      setLoanData(response.data);
    } catch (error) {
      message.error(
        `${error?.response?.status}: ${error?.response?.data?.message}`
      );
    }
  };

  useEffect(() => {
    getAllRelationlist();
    getAllCitylist();
    getAllState();
    getAllPincodelist();
    getAllOccupationlist();
    getAllPlanninglist();
    getAllNationality();
    getLocationList();
    getDocumentType();
  }, []);

  useEffect(() => {
    if (id && id.length > 0) {
      fetchData();
      fetchLoanData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, customerId]);
  

  useEffect(() => {
    if (formValues.length > 0 && !insurance_id) {
      formValues?.forEach((item, index) => {
        if (item) {
          // Check if item exists before proceeding
          // Ensure date_of_birth is defined and valid before formatting it
          // Set the fields values for each insured customer
          form.setFieldsValue({
            [`insured_customer_name_${index}`]:
              item?.insured_customer_name || "",
            [`occupation_${index}`]: item?.occupation || undefined,
            [`gender_${index}`]: item?.gender || undefined,
            [`nationality_${index}`]: item?.nationality || undefined,
            [`mobile_${index}`]: item?.mobile || "",
            [`email_${index}`]: item?.email || "",
            // [`applicant_type_${index}`]: item?.applicant_type || "",
            [`address_line_1_${index}`]: item?.address_line_1 || "",
            [`address_line_2_${index}`]: item?.address_line_2 || "",
            [`city_${index}`]: item?.city || undefined,
            [`state_${index}`]: item?.state || undefined,
            [`pincode_${index}`]: item?.pincode || undefined,
          });
        } else {
          return null;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, form, customerId]);


  const getUserData = useCallback(async () => {
    if (!insurance_id) return;
    try {
      const response = await getInsuranceDetailsByIdService(insurance_id);
  
      // Check for a valid response before processing
      if (!response || !response.data) {
        throw new Error("No data in response");
      }
  
      const {
        nominee_detail,
        insurance_coverage_data,
        insured_customer_under_magma_plan,
        insurance_magma_plan,
      } = response?.data || {};
  
      setCount(insured_customer_under_magma_plan?.length);
      const nominee_dob = nominee_detail[0]?.date_of_birth;
      const checkAppointee = nominee_detail[0]?.appointee;
      setAppointeeId(checkAppointee?.id);
      const parsedDate = moment(nominee_dob, "DD-MM-YYYY");
      
      // Calculate age
      const age = moment().diff(parsedDate, "years");
      setIsUnder18(age < 18);
  
      setNomineeId(nominee_detail[0].id);
      setCount(insured_customer_under_magma_plan?.length);
      const { appointee } = response?.data?.nominee_detail[0];
      setFileList(response?.data?.document);
      setExistingImages(response?.data?.document);
  
      // Set insured customer details
      setDatas(insured_customer_under_magma_plan);
      setPlanDetails(insurance_magma_plan);
  
      // Set loan details
      setEditloanid(response.data.loan_detail);
  
      // Set nominee details
      if (nominee_detail && nominee_detail.length > 0) {
        const nominee = nominee_detail[0];
        let nomineedateOfBirthValue = dayjs(nominee?.date_of_birth, "DD-MM-YYYY");
  
        form.setFieldsValue({
          nominee_name: nominee?.name || "",
          nominee_date_of_birth: nomineedateOfBirthValue,
          nominee_gender: nominee?.gender || "",
          nominee_contact_number: nominee?.mobile || "",
          nominee_relationship_with_life_to_be_assured: nominee?.relation?.id || "",
          nominee_address_line1: nominee?.address_line_1 || "",
          nominee_address_line2: nominee?.address_line_2 || "",
          nominee_city: nominee?.city?.id || "",
          nominee_state: nominee?.state?.id || "",
          nominee_pincode: nominee?.pincode?.id || "",
        });
      }
  
      let appointeedateOfBirthValue = dayjs(appointee?.date_of_birth, "DD-MM-YYYY");
      if (checkAppointee) {
        form.setFieldsValue({
          apointee_name: appointee?.appointee_name || "",
          apointee_gender: appointee?.gender || "",
          apointee_date_of_birth: appointeedateOfBirthValue,
          apointee_relationship_with_life_to_be_assured: appointee?.relation?.id || "",
          apointee_contact_number: appointee?.mobile || "",
        });
      }
  
      // Set insurance coverage details
      if (insurance_coverage_data) {
        form.setFieldsValue({
          loan_amount: insurance_coverage_data?.loan_amount || "",
          tenure: insurance_coverage_data?.tenure || "",
          policy_term: insurance_coverage_data?.policy_term || "",
          sum_assured: insurance_coverage_data?.sum_assured || "",
          monthly_income: insurance_coverage_data?.monthly_income || "",
          emi_amount: insurance_coverage_data?.emi_amount || "",
          total_premium_amount: insurance_coverage_data?.total_premium_incl_gst || "",
          age_proof: insurance_coverage_data?.age_proof?.id || "",
          branch_address: insurance_coverage_data?.branch_address?.id || "",
        });
      }
    } catch (error) {
      console.error("Error fetching insurance details:", error);
      message.error("Failed to fetch insurance details.");
    }
  }, [insurance_id, form, setAppointeeId, setCount, setNomineeId, setFileList, setExistingImages, setDatas, setPlanDetails, setEditloanid, setIsUnder18]);
  

  useEffect(() => {
    if (insurance_id) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance_id,getUserData]);

  useEffect(() => {
    if (nominee) {
      if (nominee === "other") {
        form.setFieldsValue({
          nominee_name: "",
          nominee_gender: "",
          nominee_date_of_birth: "",
          nominee_contact_number: "",
        });
      } else {
        const foundCustomer = personalData.find(
          (person) => person.id === nominee
        );

        if (foundCustomer) {
          setMatchedNominee(foundCustomer);
          const [day, month, year] = foundCustomer?.date_of_birth.split("-");
          const formattedDate = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"); // Create a moment object
          const parsedDate = moment(formattedDate, "DD-MM-YYYY");

          // Calculate age
          const age = moment().diff(parsedDate, "years");
          if (age < 18) {
            setIsUnder18(true);
          } else {
            setIsUnder18(false);
          }
          form.setFieldsValue({
            nominee_name: `${foundCustomer.first_name} ${foundCustomer.middle_name} ${foundCustomer.last_name}`,
            nominee_gender: foundCustomer.gender || "",
            nominee_contact_number: foundCustomer.mobile_number,
            nominee_date_of_birth: formattedDate,
          });
        }
      }
    }
  }, [nominee, personalData, form]);

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleNomineeChange = (value) => {
    setSelectedNominee(value);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      // Filter out removed files
      const newDocumentUrls = documentUrls.filter((url) => url !== file.url);
      setDocumentUrls(newDocumentUrls);
    } else if (file.status === "done") {
      // Add newly uploaded file URL
      const base64 = await fileToBase64(file.originFileObj);
      setDocumentUrls([...documentUrls, base64]);
    }
    setFileList(fileList);
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      // Parse the given date string in DD-MM-YYYY format
      const parsedDate = moment(dateString, "DD-MM-YYYY");

      // Calculate age
      const age = moment().diff(parsedDate, "years");

      setIsUnder18(age < 18);
    } else {
      setIsUnder18(false);
    }
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };
  const validateAddressFields = (formValues, customerId) => {
    if (customerId) {
      // Check if all address fields are present and not empty
      const { address_line_1_0, address_line_2_0, city_0, state_0, pincode_0 } =
        formValues;
      // Check if all fields are empty strings
      if (
        !address_line_1_0 &&
        !address_line_2_0 &&
        !city_0 &&
        !state_0 &&
        !pincode_0
      ) {
        message.error("All address fields cannot be empty.");
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    validateAddressFields(values, customerId);
    const propertyDocumentDetail = await Promise.all(
      fileList.map(async (file) => {
        const base64 = await fileToBase64(file.originFileObj);
        return base64; // Only return the base64 URL
      })
    );

    const convertDate = (dateString) => {
      const date = new Date(dateString);
      // Get the year, month, and day
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(date.getUTCDate()).padStart(2, "0");
      // Combine into the desired format
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    const insured_customers = [];
    const nominne = {
      id: nomineeId,
      name: values.nominee_name,
      mobile: values.nominee_contact_number,
      mobile_country_code: "91",
      date_of_birth: convertDate(values?.nominee_date_of_birth),
      gender: values?.nominee_gender,
      address_line_1: values?.nominee_address_line1,
      address_line_2: values?.nominee_address_line2,
      relation: values?.nominee_relationship_with_life_to_be_assured,
      city: values?.nominee_city,
      state: values?.nominee_state,
      pincode: values?.nominee_pincode,
      appointee_detail: {
        id: appointeeId,
        appointee_name: values.apointee_name,
        mobile: values.apointee_contact_number,
        mobile_country_code: "91",
        date_of_birth: convertDate(values.apointee_date_of_birth),
        gender: values.apointee_gender,
        relation: values.apointee_relationship_with_life_to_be_assured,
      },
    };
    const numberOfInsured = formValues.length; // Assuming the length represents the number of insured customers
    for (let i = 0; i < numberOfInsured; i++) {
      const insuredCustomer = {
        ocupation: values[`occupation_${i}`],
        city: values[`city_${i}`],
        state: values[`state_${i}`],
        pincode: values[`pincode_${i}`],
        address_line_1: values[`address_line_1_${i}`],
        address_line_2: values[`address_line_2_${i}`],
        relationship_with_insured_customer: values[`relation_${i}`],
      };

      // If i > 0, add name, gender, email, and date_of_birth to the object
      if (i > 0) {
        insuredCustomer.name = values[`insured_customer_name_${i}`];
        insuredCustomer.gender = values[`gender_${i}`];
        insuredCustomer.email = values[`email_${i}`];
        insuredCustomer.date_of_birth = convertDate(
          values[`date_of_birth_${i}`]
        );
        insuredCustomer.nationality = values[`nationality_${i}`];
        insuredCustomer.mobile = values[`mobile_${i}`];
        insuredCustomer.mobile_country_code = "91";
        // insuredCustomer.customer_type = values[`applicant_type_${i}`]
      }
      insured_customers.push(insuredCustomer);
    }

    // Insurance ID check to process customer data
    if (insurance_id) {
      //  const numberOfInsured = formValues.length;
      const insured_customers = [];
      // const numberOfInsured = newFormData.length; // Assuming the length represents the number of insured customers
      for (let i = 0; i < count; i++) {
        insured_customers.push({
          id: parseInt(values[`id_${i}`]),
          ocupation: values[`occupation_${i}`],
          city: values[`city_${i}`],
          state: values[`state_${i}`],
          pincode: values[`pincode_${i}`],
          address_line_1: values[`address_line_1_${i}`],
          address_line_2: values[`address_line_2_${i}`],
          relationship_with_insured_customer:
            values[`relationship_with_insured_customer_${i}`],
          mobile: values[`mobile_${i}`],
          mobile_country_code: "91",
          // customer_type : values[`applicant_type_${i}`]
        });
      }
      const formattedEditValues = {
        loan_detail: id || editloanid,
        insurance_detail: insurance_id,
        insured_customer_detail: {
          ...insured_customers[0],
          customer_detail: customerId,
        }, // First customer
        insurance_plan_magma: insurancePlanMagma,
        nominne: nominne,
        insurance_coverage: {
          policy_term: values.policy_term,
          sum_assured: values.sum_assured,
          monthly_income: values.monthly_income,
          total_premium_incl_gst: values.total_premium_amount,
          emi_amount: values.emi_amount,
          property_type: values.property_type,
          branch_address: values.branch_address,
          age_proof: values.age_proof,
        },
        insured_customer_under_magma_plan: insured_customers.slice(1), // Exclude the first customer
        document: propertyDocumentDetail,
      };

      const response = await postEditMagma(formattedEditValues);
      if (response && response.success) {
        closeForm();
        form.resetFields();
        toggleRefreshTableData();
        setSelectedCustomer([]);
        toggleRefreshInsurance();
        setLoading(false)
        if (setSelectedInsuranceType) {
          setSelectedInsuranceType(""); // Ensure setSelectedInsuranceType is defined before calling
        }
        return
      } else {
        setLoading(false)
        console.error("Error: Response did not indicate success", response);
        message.error("Failed to update or add insurance");
        return
      }
    }

    const formattedPostValues = {
      loan_detail: id || editloanid,
      insured_customer_detail: {
        ...insured_customers[0],
        customer_detail: customerId,
      }, // First customer
      insurance_plan_magma: insurancePlanMagma,
      nominne: nominne,
      insurance_coverage: {
        policy_term: values.policy_term,
        sum_assured: values.sum_assured,
        monthly_income: values.monthly_income,
        total_premium_incl_gst: values.total_premium_amount,
        emi_amount: values.emi_amount,
        property_type: values.property_type,
        branch_address: values.branch_address,
        age_proof: values.age_proof,
      },
      insured_customer_under_magma_plan: insured_customers.slice(1), // Exclude the first customer
      document: propertyDocumentDetail,
    };

    try {
      let response;
      response = await postMagmaHDI(formattedPostValues);
      if (response && response.success) {
        closeForm();
        form.resetFields();
        toggleRefreshInsurance();
        setLoading(false)
      } else {
        console.error("Error: Response did not indicate success", response);
        message.error("Failed to update or add insurance");
      }
    } catch (error) {
      console.error("Error occurred while submitting:", error);
      message.error(error?.response?.data?.message);
      setLoading(false)

    } finally {
      setLoading(false); // Stop loading after the process completes
    }
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteHDFCDocumentDetailsByIdService(
        deletePicture.id
      );

      if (response?.status === 200 && response?.data?.success === true) {
        message.success("Document deleted successfully");
        setOpenModal(false);
        toggleRefreshInsurance();
      } else {
        // Handle unexpected success response formats
        message.error("Failed to delete the document. Please try again.");
      }
    } catch (error) {
      message.error(
        ErrorMessage(
          error?.response?.status,
          error?.response?.data?.message || "An error occurred"
        )
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/[^0-9.]/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const { TabPane } = Tabs;

  const handleContactNumberFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except digits
    newValue = newValue.replace(/[^0-9]/g, "");

    // Ensure that the value does not exceed 10 digits
    if (newValue.length > 10) {
      newValue = newValue.slice(0, 10);
    }

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div>
      {!insurance_id && (
        <MagmaInsuredCustomerForm
          personalData={personalData}
          setMatchedCustomer={setMatchedCustomer}
          customerId={customerId}
          occupationType={occupationType}
          filterOption={filterOption}
          states={states}
          city={city}
          pincode={pincode}
          formValues={formValues}
          setFormValues={setFormValues}
          insurance_id={insurance_id}
          datas={datas}
          form={form}
        />
      )}

      {!insurance_id && customerId && (
        <div style={{ marginBottom: "50px" }}>
          <MagmaHDIInsurance
            planningListData={planningListData}
            setCount={setCount}
            formValues={formValues}
            setFormValues={setFormValues}
            setInsurancePlanMagma={setInsurancePlanMagma}
          />
        </div>
      )}
      {insurance_id && (
        <div style={{ marginBottom: "50px" }}>
          <MagmaHDIInsurance
            planningListData={planningListData}
            insurance_id={insurance_id}
            planDetails={planDetails}
            setCount={setCount}
            formValues={formValues}
            setFormValues={setFormValues}
            setInsurancePlanMagma={setInsurancePlanMagma}
          />
        </div>
      )}
      <Form form={form} layout="vertical" onFinish={handleSubmit}   onValuesChange={(changedValues, allValues) => {
  setDisabledButton(false)
    // You can add additional logic here to handle changes
  }}>
        {count > 0 && (
          <div>
            {/* <div className="details_coverage_hdfc">Insured Customer</div> */}
            <Tabs defaultActiveKey="0" style={{ marginLeft: 20 }}>
              {insurance_id && datas.length
                ? datas.map((customer, index) => {
                    const formattedDate = stringToDate(customer?.date_of_birth);
                    return (
                      <TabPane
                        tab={`Insured Customer ${index + 1}`}
                        key={index}
                      >
                        <div className="drawer-insurance-space">
                          {/* <Form
                            layout="vertical"
                            // onValuesChange={onFormValuesChange}
                            onFinish={(values) => handleSubmit(values)}
                            form={form} // Bind the form instance
                            initialValues={{
                              ...customer,
                              // Use dynamic field key based on the index
                              [`city_${index}`]: customer.city.id,
                              [`date_of_birth_${index}`]: formattedDate,
                            }}
                          > */}
                            <Row gutter={16} style={{ marginBottom: "16px" }}>
                              {/* Insured Customer Name */}
                              <Col span={8} style={{ display: "none" }}>
                                <Form.Item
                                  name={`id_${index}`}
                                  // label="Insured Customer Name"
                                  initialValue={customer.id}
                                  required
                                ></Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={`insured_customer_name_${index}`}
                                  label="Insured Customer Name"
                                  required
                                  initialValue={`${customer.first_name} ${customer.middle_name} ${customer.last_name}`.trim()}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Insured Customer Name is required`,
                                    },
                                    {
                                      pattern: /^[a-zA-Z\s]*$/,
                                      message:
                                        "Only letters and spaces are allowed",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={
                                      `${customer.first_name} ${customer.middle_name} ${customer.last_name}`.trim() ||
                                      "Please Enter Customer Name"
                                    }
                                    disabled={index === 0}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Allow only letters and spaces
                                      const filteredValue = value.replace(
                                        /[^a-zA-Z\s]/g,
                                        ""
                                      );

                                      // Update the input field only if filtered value is different
                                      if (value !== filteredValue) {
                                        e.target.value = filteredValue; // Update the input value to filtered value
                                      }

                                      // You can choose to handle valid input here if needed
                                      // For example, if you want to set valid values to state or perform other actions
                                      // handleAlphaFields({ target: { value: filteredValue } }, `insured_customer_name_${index}`);
                                    }}
                                    // disabled={index === 0} // Disable if index is 0 (or whatever condition you need)
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  name={index === 0 ? `date_of_birth` : `date_of_birth_${index}`}
                                  label="Date Of Birth"
                                  initialValue={formattedDate}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Date Of Birth is required",
                                    },
                                  ]}
                                  required
                                >
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{
                                      width: "100%",
                                    }}
                                    disabled={index === 0}
                                    placeholder="Select Date"
                                    onChange={handleDateChange}
                                  />
                                </Form.Item>
                              </Col>

                              {/* Gender */}
                              <Col span={8}>
                                <Form.Item
                                  name={`gender_${index}`}
                                  label="Gender"
                                  initialValue={customer.gender}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Gender is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Search To Select"
                                    style={{ width: "100%" }}
                                    allowClear
                                    disabled={index === 0}
                                  >
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                  </Select>
                                </Form.Item>
                              </Col>

                              {/* Occupation */}
                              <Col span={8}>
                                <Form.Item
                                  name={`occupation_${index}`}
                                  label="Occupation"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Occupation is required",
                                    },
                                  ]}
                                  initialValue={customer.ocupation.id}
                                >
                                  <Select
                                    placeholder="Please Select"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    allowClear
                                  >
                                    {occupationType?.map((loc) => (
                                      <Option key={loc.id} value={loc.id}>
                                        {loc?.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              {/* Contact Number */}
                              <Col span={8}>
                                <Form.Item label="Contact Number" required>
                                  <Input.Group compact>
                                    <Form.Item noStyle>
                                      <Input
                                        style={{
                                          width: "20%",
                                          textAlign: "center",
                                          background: "#f1f1f1",
                                        }}
                                        defaultValue="+91"
                                        disabled={index === 0}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name={`mobile_${index}`}
                                      noStyle
                                      initialValue={customer.mobile}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Contact Number is Required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        style={{ width: "80%" }}
                                        placeholder={customer.mobile}
                                        disabled={index === 0}
                                      />
                                    </Form.Item>
                                  </Input.Group>
                                </Form.Item>
                              </Col>

                              {/* Nationality */}
                              <Col span={8}>
                                <Form.Item
                                  name={`nationality_${index}`}
                                  label="Nationality"
                                  initialValue={customer.nationality}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Nationality is Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Please Select"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    allowClear
                                    disabled={index === 0}
                                  >
                                    {nationality?.map((loc) => (
                                      <Option key={loc} value={loc}>
                                        {loc}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  name={`relationship_with_insured_customer_${index}`}
                                  label="Relationship with Life to be Assured"
                                  style={{ display: index === 0 && "none" }}
                                  initialValue={
                                    customer?.relationship_with_insured_customer
                                      ?.id
                                  } // Ensure safe access to customer data
                                  rules={[
                                    {
                                      required: true,
                                      message: "Relationship is Required",
                                    },
                                  ]} // Add validation rule for required field
                                >
                                  <Select
                                    placeholder="Please Select"
                                    showSearch
                                    value={
                                      customer
                                        ?.relationship_with_insured_customer
                                        ?.name
                                    }
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    disabled={index === 0} // Ensure filterOption is defined
                                    allowClear
                                  >
                                    {relation?.length > 0 ? ( // Safely check if relation exists and has options
                                      relation.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))
                                    ) : (
                                      <Option disabled>
                                        No options available
                                      </Option> // Fallback when there are no options
                                    )}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16} style={{ marginBottom: "16px" }}>
                              {/* Email */}
                              <Col span={8}>
                                <Form.Item
                                  name={`email_${index}`}
                                  label="Email ID"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Email is Required",
                                    },
                                  ]}
                                  initialValue={customer.email}
                                >
                                  <Input
                                    placeholder={customer.email}
                                    disabled={index === 0}
                                  />
                                </Form.Item>
                              </Col>

                              {/* Address Line 1 */}
                              <Col span={12}>
                                <Form.Item
                                  name={`address_line_1_${index}`}
                                  label="Address Line 1"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Address Line 1 is Required",
                                    },
                                  ]}
                                  initialValue={customer.address_line_1} // Set initial value
                                >
                                  <Input
                                    defaultValue={customer.address_line_1} // Use defaultValue instead of placeholder
                                  />
                                </Form.Item>
                              </Col>

                              {/* Address Line 2 */}
                              <Col span={12}>
                                <Form.Item
                                  name={`address_line_2_${index}`}
                                  label="Address Line 2"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Address Line 2 is Required",
                                    },
                                  ]}
                                  initialValue={customer.address_line_2}
                                >
                                  <Input
                                    placeholder={customer.address_line_2}
                                    //disabled
                                  />
                                </Form.Item>
                              </Col>

                              {/* City */}
                              <Col span={8}>
                                <Form.Item
                                  name={`city_${index}`}
                                  label="City"
                                  initialValue={customer.city.id}
                                  rules={[
                                    {
                                      required: true,
                                      message: "City is Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    // disabled
                                    allowClear
                                  >
                                    {city?.map((loc) => (
                                      <Option key={loc.id} value={loc.id}>
                                        {loc.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              {/* State */}
                              <Col span={8}>
                                <Form.Item
                                  name={`state_${index}`}
                                  label="State"
                                  initialValue={customer.state.id}
                                  rules={[
                                    {
                                      required: true,
                                      message: "State is Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    //disabled
                                    allowClear
                                  >
                                    {states?.map((loc) => (
                                      <Option key={loc.id} value={loc.id}>
                                        {loc.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              {/* Pincode */}
                              <Col span={8}>
                                <Form.Item
                                  name={`pincode_${index}`}
                                  label="Pincode"
                                  initialValue={customer.pincode.id}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Pincode is Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Please Select"
                                    filterOption={filterOption}
                                    //disabled
                                    allowClear
                                  >
                                    {pincode?.map((loc) => (
                                      <Option key={loc.id} value={loc.id}>
                                        {loc.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          {/* </Form> */}
                        </div>
                      </TabPane>
                    );
                  })
                : formValues &&
                  (Array.isArray(formValues) ? formValues : [formValues]).map(
                    (formValue, index) => {
                      // const newFormattedDate = stringToDate(formValue?.date_of_birth);
                      const newFormattedDate = formValue?.date_of_birth
                        ? moment(formValue.date_of_birth).format("DD-MM-YYYY")
                        : null;
                      return (
                        <TabPane
                          tab={`Insured Customer ${index + 1}`}
                          key={index}
                        >
                          <div className="drawer-insurance-space">
                            <Form
                              layout="vertical"
                              // onValuesChange={onFormValuesChange}
                              onFinish={(values) => handleSubmit(values)}
                              form={form} // Bind the form instance
                              onValuesChange={(changedValues, allValues) => {
                                setDisabledButton(false)
                                  // You can add additional logic here to handle changes
                                }}
                              initialValues={{
                                ...formValue,
                                // Use dynamic field key based on the index
                                [`date_of_birth_${index}`]: newFormattedDate
                                  ? moment(newFormattedDate, "DD-MM-YYYY")
                                  : null,
                              }}
                            >
                              <Row gutter={16} style={{ marginBottom: "16px" }}>
                                {/* Insured Customer Name */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`insured_customer_name_${index}`}
                                    label="Insured Customer Name"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Insured Customer Name is required`,
                                            },
                                            {
                                              pattern: /^[a-zA-Z\s]*$/,
                                              message:
                                                "Only letters and spaces are allowed",
                                            },
                                          ]
                                    }
                                  >
                                    <Input
                                      placeholder="Enter Customer Name"
                                      disabled={index === 0} // Disabled if it's the first index
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Date Of Birth */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`date_of_birth_${index}`}
                                    label="Date Of Birth"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Date Of Birth is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <DatePicker
                                      format="DD-MM-YYYY"
                                      style={{ width: "100%" }}
                                      placeholder="Select Date"
                                      disabled={index === 0}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Gender */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`gender_${index}`}
                                    label="Gender"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Gender is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <Select
                                      placeholder={
                                        "Search To Select" || undefined
                                      }
                                      style={{ width: "100%" }}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      <Option value="Male">Male</Option>
                                      <Option value="Female">Female</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Occupation */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`occupation_${index}`}
                                    label="Occupation"
                                    rules={[
                                      {
                                        required: true,
                                        message: `Occupation is required`,
                                      },
                                    ]}
                                    required
                                  >
                                    <Select
                                      placeholder="Please Select Occupation"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {occupationType?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Contact Number */}
                                <Col span={8}>
                                  <Form.Item
                                    label="Contact Number"
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Contact Number is required`,
                                            },
                                            {
                                              pattern: /^\d+$/,
                                              message:
                                                "Only numbers are allowed",
                                            },
                                            {
                                              max: 10,
                                              message:
                                                "Contact Number cannot exceed 10 digits",
                                            },
                                          ]
                                    }
                                    required
                                  >
                                    <Input.Group compact>
                                      <Form.Item noStyle>
                                        <Input
                                          style={{
                                            width: "20%",
                                            textAlign: "center",
                                            background: "#f1f1f1",
                                          }}
                                          defaultValue="+91"
                                          disabled
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name={`mobile_${index}`}
                                        required
                                        noStyle
                                        rules={
                                          index === 0
                                            ? []
                                            : [
                                                {
                                                  required: true,
                                                  message: `Contact Number is required`,
                                                },
                                                {
                                                  pattern: /^\d+$/,
                                                  message:
                                                    "Only numbers are allowed",
                                                },
                                                {
                                                  max: 10,
                                                  message:
                                                    "Contact Number cannot exceed 10 digits",
                                                },
                                              ]
                                        }
                                      >
                                        <Input
                                          style={{ width: "80%" }}
                                          placeholder="Mobile Number"
                                          onChange={(e) =>
                                            handleContactNumberFields(
                                              e,
                                              `mobile_${index}`
                                            )
                                          }
                                          disabled={index === 0}
                                        />
                                      </Form.Item>
                                    </Input.Group>
                                  </Form.Item>
                                </Col>

                                {/* Nationality */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`nationality_${index}`}
                                    label="Nationality"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Nationality is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <Select
                                      placeholder="Please Select Nationality"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {nationality?.map((loc) => (
                                        <Option key={loc} value={loc}>
                                          {loc}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Col span={8}>
                                  <Form.Item
                                    name={`relation_${index}`}
                                    label="Relationship with Life to be Assured"
                                    required
                                    style={{ display: index === 0 && "none" }}
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Relationship with Life to be Assured is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <Select
                                      placeholder="Please Select Relationship"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {relation?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={16} style={{ marginBottom: "16px" }}>
                                {/* Email */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`email_${index}`}
                                    label="Email ID"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Email ID is required`,
                                            },
                                            {
                                              type: "email",
                                              message:
                                                "The input is not valid E-mail!",
                                            },
                                          ]
                                    }
                                  >
                                    <Input
                                      placeholder="Email ID"
                                      disabled={index === 0}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Applicant Type */}
                                {/* <Col span={8}>
                       <Form.Item
                         name={`applicant_type_${index}`}
                         label="Applicant Type"
                         required
                       >
                         <Select
                           showSearch
                           placeholder="Please Select"
                           filterOption={filterOption}
                           disabled={index === 0}
                         >
                           {customerList?.map((loc) => (
                             <Option key={loc.id} value={loc.id}>
                               {loc.name}
                             </Option>
                           ))}
                         </Select>
                       </Form.Item>
                     </Col> */}

                                {/* Address Line 1 */}
                                <Col span={12}>
                                  <Form.Item
                                    name={`address_line_1_${index}`}
                                    label="Address Line 1"
                                    required
                                    rules={[
                                      {
                                        required: true,
                                        message: `Address Line 1 is required`,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Please Enter Address Line 1"
                                      disabled={index === 0}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Address Line 2 */}
                                <Col span={12}>
                                  <Form.Item
                                    name={`address_line_2_${index}`}
                                    label="Address Line 2"
                                    rules={[
                                      {
                                        required: true,
                                        message: `Address Line 2 is required`,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Please Enter Address Line 2"
                                      disabled={index === 0}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* City */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`city_${index}`}
                                    label="City"
                                    rules={[
                                      {
                                        required: true,
                                        message: `City is required`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Please Select City"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {city?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* State */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`state_${index}`}
                                    label="State"
                                    rules={[
                                      {
                                        required: true,
                                        message: `State is required`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Please Select State"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {states?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Pincode */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`pincode_${index}`}
                                    label="Pincode"
                                    rules={[
                                      {
                                        required: true,
                                        message: `Pincode is required`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Please Select Pincode"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {pincode?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      );
                    }
                  )}
            </Tabs>
          </div>
        )}

        {count > 0 && (
          <div>
            <div className="details_coverage_hdfc">Nominee Details Magma:</div>
            <div className="drawer-insurance-space">
              {id && (
                <Row>
                  <Col span={8}>
                    <Form.Item name="select_nominee" label="Select Nominee">
                      <Select
                        placeholder="Please Select Nominee"
                        showSearch
                        optionFilterProp="children"
                        onChange={handleNomineeChange}
                        filterOption={filterOption}
                        allowClear
                        required
                      >
                        {personalData
                          ?.filter((person) => person.id !== customerId)
                          .map(
                            (person, index) =>
                              person.customer_type?.name === "Co-Applicant" && (
                                <Option key={person.id} value={person.id}>
                                  {`${person.first_name} ${person.middle_name} ${person?.last_name}`}
                                </Option>
                              )
                          )}
                        <Option value="other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}>
                  <Form.Item
                    name="nominee_name"
                    label="Name"
                    required
                    // rules={[yupSync]}
                    rules={nominee !== "other" ? [] : [yupSync]}
                  >
                    <Input
                      placeholder={
                        nominee === "other"
                          ? "Please Enter Your Name"
                          : `${matchedNominee?.first_name || ""} ${
                              matchedNominee?.middle_name || ""
                            } ${matchedNominee?.last_name || ""}`.trim() ||
                            "Please Enter Nominee Name"
                      }
                      value={
                        nominee === "other"
                          ? ""
                          : `${matchedNominee?.first_name || ""} ${
                              matchedNominee?.middle_name || ""
                            } ${matchedNominee?.last_name || ""}`.trim()
                      }
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="nominee_date_of_birth"
                    label="Date Of Birth"
                    rules={[yupSync]}
                    required
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Date"
                      onChange={handleDateChange}
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_gender"
                    label="Gender"
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      placeholder="Select Gender"
                      style={{ width: "100%" }}
                      disabled={!insurance_id && nominee !== "other"}
                      allowClear
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_relationship_with_life_to_be_assured"
                    label="Relationship with Life to be Assured"
                    // rules={[yupSync, { required: true, message: 'Please select a relationship' }]}  // Added required validation here
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      placeholder="Please Select" // Placeholder should now display correctly
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                    >
                      {relation?.map((relations) => (
                        <Option key={relations.id} value={relations.id}>
                          {relations.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Contact Number" required>
                    <Input.Group compact>
                      <Input
                        style={{
                          width: "20%",
                          textAlign: "center",
                          background: "#f1f1f1",
                        }}
                        disabled={!insurance_id}
                        defaultValue="+91"
                      />
                      <Form.Item
                        name="nominee_contact_number"
                        rules={[yupSync]}
                        noStyle
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder={"Enter Contact Number" || undefined}
                          disabled={!insurance_id && nominee !== "other"}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={12}>
                  <Form.Item
                    name="nominee_address_line1"
                    label="Address Line 1"
                    required
                    rules={[yupSync]}
                  >
                    <Input
                      placeholder="Please Enter Address Line 1"
                      onChange={(e) => handleInput(e, "nominee_address_line1")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="nominee_address_line2"
                    label="Address Line 2"
                    required
                    rules={[yupSync]}
                  >
                    <Input
                      placeholder="Please Enter Address Line 2"
                      onChange={(e) => handleInput(e, "nominee_address_line2")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}>
                  <Form.Item
                    name="nominee_city"
                    label="City"
                    required
                    rules={[yupSync]}
                  >
                    <Select
                      placeholder="Please Select"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                    >
                      {city?.map((locations) => (
                        <Option key={locations.id} value={locations.id}>
                          {locations.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_state"
                    label="State"
                    required
                    rules={[yupSync]}
                  >
                    <Select
                      placeholder="Please Select"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                    >
                      {states?.map((state) => (
                        <Option key={state.id} value={state.id}>
                          {state.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_pincode"
                    label="Pincode"
                    required
                    rules={[yupSync]}
                  >
                    <Select placeholder="Please Select" allowClear>
                      {pincode?.map((pin) => (
                        <Option key={pin.id} value={pin.id}>
                          {pin.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {isUnder18 && (
                <Row gutter={16} style={{ marginBottom: "16px" }}>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_name"
                      label="Appointee Name"
                      required
                      rules={[yupSync]}
                    >
                      <Input placeholder="Enter Appointee Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_date_of_birth"
                      label="Date Of Birth"
                      rules={[yupSync]}
                      required
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_gender"
                      label="Gender"
                      required
                      rules={[yupSync]}
                    >
                      <Select placeholder="Select Gender">
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_relationship_with_life_to_be_assured"
                      label="Relationship with the Nominee"
                      rules={[yupSync]}
                      required
                    >
                      <Select
                        placeholder="Please Select"
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                      >
                        {relation?.map((relations) => (
                          <Option key={relations.id} value={relations.id}>
                            {relations.display_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_contact_number"
                      label="Contact Number"
                      required
                    >
                      <Input.Group compact>
                        <Input
                          style={{
                            width: "20%",
                            textAlign: "center",
                            background: "#f1f1f1",
                          }}
                          disabled={!insurance_id}
                          defaultValue="+91"
                        />
                        <Form.Item
                          name="apointee_contact_number"
                          noStyle
                          rules={[yupSync]}
                        >
                          <Input
                            style={{ width: "80%" }}
                            placeholder="Enter Contact Number"
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>

            <div className="details_coverage_hdfc">
              Details Of The  Coverage MAGMA HDI:
            </div>
            <div className="drawer-insurance-space">
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}>
                  <Form.Item name="loan_amount" label="Loan Amount" required>
                    <Input
                      placeholder={`${loanData.requested_amount}`}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="tenure"
                    label="Tenure Of Loan (In Months)"
                    required
                  >
                    <Input
                      placeholder={`${loanData.approved_tenure}`}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="policy_term"
                    label="Policy Term (In Months)"
                    required
                    rules={[yupSync]}
                  >
                    <Input
                      placeholder="Please Enter Policy Term"
                      onChange={(e) => handleNumberFields(e, "policy_term")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}>
                  <Form.Item label="Sum Assured (₹)" required>
                    <Input.Group compact>
                      <Form.Item noStyle>
                        <Input
                          style={{
                            width: "20%",
                            textAlign: "center",
                            background: "#f1f1f1",
                          }}
                          disabled
                          defaultValue="₹"
                        />
                      </Form.Item>
                      <Form.Item
                        name="sum_assured"
                        noStyle
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder="Please Enter Sum Assured"
                          onChange={(e) => handleNumberFields(e, "sum_assured")}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Monthly Income" required>
                    <Input.Group compact>
                      <Form.Item noStyle>
                        <Input
                          style={{
                            width: "20%",
                            textAlign: "center",
                            background: "#f1f1f1",
                          }}
                          disabled
                          defaultValue="₹"
                        />
                      </Form.Item>
                      <Form.Item
                        name="monthly_income"
                        label="Monthly Income"
                        noStyle
                        required
                        rules={[yupSync]}
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder="Please Enter Monthly Income"
                          onChange={(e) =>
                            handleNumberFields(e, "monthly_income")
                          }
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="emi_amount"
                    label="EMI Amount"
                    rules={[yupSync]} // Assuming yupSync is properly defined elsewhere
                    required
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Please Enter EMI Amount"
                      onChange={(e) => handleNumberFields(e, "emi_amount")} // Correct event handler
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}>
                  <Form.Item
                    label="Total Premium Amount (Including GST)(₹)"
                    required
                    rules={[yupSync]}
                  >
                    <Input.Group compact>
                      <Form.Item noStyle>
                        <Input
                          style={{
                            width: "20%",
                            textAlign: "center",
                            background: "#f1f1f1",
                          }}
                          disabled
                          defaultValue="₹"
                        />
                      </Form.Item>
                      <Form.Item
                        name="total_premium_amount"
                        rules={[yupSync]}
                        noStyle
                        required
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder="Please Enter Total Premium Amount"
                          onChange={(e) =>
                            handleNumberFields(e, "total_premium_amount")
                          }
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Branch Address"
                    name="branch_address"
                    required
                    rules={[yupSync]}
                  >
                    <Select
                      placeholder="Search To Select"
                      style={{ width: "100%" }}
                      // disabled
                      allowClear
                    >
                      {branchLocations?.map((item) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Age Proof"
                    name="age_proof"
                    required
                    rules={[yupSync]}
                  >
                    <Select
                      placeholder="Search To Select"
                      style={{ width: "100%" }}
                      // disabled
                      allowClear
                    >
                      {documentTypes?.map((item) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="details_coverage_hdfc">Documents:</div>
            <div className="drawer-insurance-space">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="documents" label="Documents">
                    <Upload
                      single
                      fileList={fileList}
                      onChange={handleUploadChange}
                      beforeUpload={handleBeforeUpload}
                      showUploadList={false}
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        style={{ width: "176%" }}
                      >
                        Upload Document
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ margin: "10px 10px 10px 10px" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {image?.map((picture, index) => (
                    <div
                      key={index}
                      style={{ marginRight: "15px", position: "relative" }}
                    >
                      <RViewerJS>
                        <img
                          src={picture.file_path}
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      </RViewerJS>
                      <div
                        style={{
                          // marginLeft: "-19px",
                          color: "#E5233DB2",
                          cursor: "pointer",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          setDeleteUploadPicture(picture);
                          setOpenUploadModal(true);
                        }}
                      >
                        <DeleteOutlined />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="image-gallery">
                {existingImages?.map((picture, index) => (
                  <div key={index} className="image-container">
                    {picture.file_type === "application/pdf" ? (
                      <img
                        src={pdf_image}
                        width="100px"
                        height="100px"
                        style={{ border: "none" }}
                        alt="PDF Preview"
                      />
                    ) : (
                      <RViewerJS
                        options={{
                          url: (image) => image.getAttribute("data-original"),
                        }}
                      >
                        <img
                          src={picture.thumb_document}
                          data-original={picture.document}
                          alt="Preview"
                          style={{
                            width: "100px",
                            height: "100px",
                            cursor: "pointer",
                          }}
                        />
                      </RViewerJS>
                    )}

                    {/* <h4 style={{ textAlign: "center", color: "#1890FF" }}>
                  {picture.document_type.display_name}
                </h4> */}
                    <div
                      className="delete-icon"
                      onClick={() => {
                        setDeletePicture(picture);
                        setOpenModal(true);
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Modal
          title="Confirm Delete"
          open={openUploadModal}
          onOk={confirmDelete}
          onCancel={() => {
            setOpenUploadModal(false);
            setDeleteUploadPicture(null);
          }}
          okType="danger"
        >
          <p>{`Are you sure you want to delete this upload image?`}</p>
        </Modal>
        <Modal
          title="Confirm Delete"
          open={openModal}
          onOk={deletePropertyPictures}
          onCancel={() => {
            setOpenModal(false);
            setDeletePicture(null);
          }}
          okType="danger"
        >
          <p>Are you sure you want to delete?</p>
        </Modal>

        <Col span={24}>
          <div style={{ textAlign: "right" }}>
            <Space
              direction="horizontal"
              align="center"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Button type="primary" htmlType="submit" loading={loading} disabled={disabledButton}>
                Save
              </Button>
              <Button htmlType="button" onClick={closeForm}>
                Cancel
              </Button>
            </Space>
          </div>
        </Col>
      </Form>
    </div>
  );
};

export default MagmaHDIInsuranceForm;
