import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getAllPincodeService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;

      let path = `/api/v1/master/pincode/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        
        path = `/api/v1/master/pincode/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getPincodeByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/pincode/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createPincodeService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/master/pincode/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updatePincodeByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/master/pincode/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const deletePincodeByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/master/pincode/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }



export const listAllCityService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/city/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listAllDistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/district/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAllTalukaService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/taluka/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAllCountryService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listUserService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/user/get-user-list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};