import { useEffect, useState } from "react";
import { axiosRequest } from "../../../utils/api/axiosRequest";
import UseFI from "./UseFI";

const UseCustomer = () => {
  const {fiAdresses} = UseFI()
  const [customerId, setCustomerId] = useState();

  useEffect(() => {
    if(fiAdresses?.length){
      setCustomerId(fiAdresses[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [assets,setAssets] = useState([])
  const [fiBusinessOccupation,setFiBusinessOccupation] = useState({})
  const [fiBusinessPremises,setFiBusinessPremises] = useState({})
  const[fiCustomerAddressData,setFiCustomerAddressData] = useState({})
  const [fiDetail,setFiDetail] = useState({})

  
  useEffect(() => {
    const fetchCustomerDetailData = async () => {
      try {
        const response = await axiosRequest.get(
          `/api/v1/loan/fi-combine/get-customer-details-by-customer-id/${customerId}`
        );
        if (!response?.data) {
          return null;
        }
        setAssets(response?.data?.data?.assets)
        setFiBusinessOccupation(response?.data?.data?.fi_business_occupation)
        setFiBusinessPremises(response?.data?.data?.fi_business_premises)
        setFiCustomerAddressData(response?.data?.data?.fi_customer_address_data)
        setFiDetail(response?.data?.data?.fi_customer_address_data)
      } catch (error) {
        throw Error(error);
      }
    };
    if(customerId){
      fetchCustomerDetailData();
    }
  }, [customerId]);
  return { setCustomerId,customerId,assets,fiBusinessOccupation,fiBusinessPremises,fiCustomerAddressData,fiDetail};
};

export default UseCustomer;
