import React from "react";
import { Button, Col, Form, Input, Row, Space } from "antd";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    bank_name: Yup.string()
        .required("Bank name is required"),
    bank_type: Yup.string()
        .required("Branch name is required"),
    holder_name: Yup.string()
        .required("Holder name is required"),
    branch_name: Yup.string()
        .required("Account type is required"),
    account_no: Yup.string()
        .required("Account number is required"),
    ifsc: Yup.string()
        .required("IFSC Number is required"),
    micr: Yup.string()
        .required("MICR is required"),
    email: Yup.string()
        .required("Email is required"),
    status: Yup.string()
        .required("Status is required"),
    vendor: Yup.string()
        .required("Vendor is required"),
    mode: Yup.string()
        .required("Mode is required"),
    mobile_number: Yup.string()
        .required("Mobile number is required"),
    upload_file: Yup.string()
        .required("Upload file is required"),

});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};


const NachAddForm = ({ closeForm }) => {

    

    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            //onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                bank_name: "",
                bank_type: "",
                holder_name: "",
                branch_name: "",
                account_no: "",
                ifsc: "",
                micr: "",
                email: "",
                status: "",
                vendor: "",
                mode: "",
                mobile_number: "",
                upload_file: "",

            }}
        >
            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="bank_name" label="Bank Name" rules={[yupSync]}
                        required>
                        <Input placeholder="Please Enter Bank Name"

                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="bank_type" label="Bank Type" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Bank Type" />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="account_no" label="Account Number" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Account Number" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="branch_name" label="Branch Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Branch Name"

                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="holder_name" label="Holder Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Holder Name"

                        />
                    </Form.Item>
                </Col>


                <Col span={8}>
                    <Form.Item name="ifsc" label="IFSC Number" rules={[yupSync]} required>
                        <Input placeholder="Please Enter IFSC Number"

                        />
                    </Form.Item>
                </Col>
            </Row>



            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="micr" label="MICR" rules={[yupSync]} required>
                        <Input placeholder="Please Enter MICR"

                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="email" label="EMI" rules={[yupSync]} required>
                        <Input placeholder="Please Enter EMI" />
                    </Form.Item>
                </Col>


                <Col span={8}>
                    <Form.Item name="status" label="Status" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Status"

                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="vendor" label="Vendor" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Vendor"

                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="mode" label="Mode" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Mode" />
                    </Form.Item>
                </Col>


                <Col span={8}>
                    <Form.Item name="mobile_number" label="Mobile Number" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Mobile Number"

                        />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="issues_case" label="Issues for unregistered cases" rules={[yupSync]} required>
                        <Input.TextArea placeholder="Please Enter Issues for unregistered cases" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="action_branch" label="Action required form branch" rules={[yupSync]} required>
                        <Input.TextArea placeholder="Please Enter Mode" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="upload_file" label="Upload File" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Upload File"

                        />
                    </Form.Item>
                </Col>

            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default NachAddForm;
