import {axiosRequest} from "../../../utils/api/axiosRequest"

export const createLegalInputFormData = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/property-legal-data/create-combine-legal/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const getLegalInputFormData = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/initiate-stage/get-credit-assessment-data-for-legal-input-form/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }