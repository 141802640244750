import React from "react";
import { Card, Table, Row, Col, Typography, Tooltip } from "antd";

const { Title } = Typography;

const PlanTable = ({ insurance_magma_plan }) => {
  const columns = [
    { title: "Plan", dataIndex: "name", key: "name" },
    { title: "Sum Of Assured", dataIndex: "sum_assured", key: "sum_assured" },
    { title: "No. of Adults", dataIndex: "no_of_adults", key: "no_of_adults" },
    {
      title: "No. of Children",
      dataIndex: "no_of_children",
      key: "no_of_children",
    },
    { title: "Years 18 to 45", dataIndex: "yrs_18_to_45", key: "yrs_18_to_45" },
  ];

  return (
    <Table
      dataSource={insurance_magma_plan}
      columns={columns}
      pagination={false}
      bordered
      style={{ backgroundColor: "#f0f2f5" }} // Light grey background for the table
    />
  );
};

const BenefitTable = ({ insurance_magma_plan }) => {
  const columns = [
    {
      title: "Convalescence Benefit",
      dataIndex: "convalescence_benifits",
      key: "convalescence_benifits",
    },
    {
      title: "Amount in Rs. Exclusive of GST",
      dataIndex: "amount_excl_gst",
      key: "amount_excl_gst",
    },
  ];

  return (
    <Table
      dataSource={insurance_magma_plan}
      columns={columns}
      pagination={false}
      bordered
      style={{ backgroundColor: "#f0f2f5" }} // Light grey background for the table
    />
  );
};

const CustomerTable = ({ insured_customer_under_magma_plan }) => {
  const columns = [
    {
      title: "Insured Customer Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (value, record) => (
        <p>
          {record?.first_name} {record?.middle_name} {record?.last_name}
        </p>
      ),
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
    },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Relationship with life to be Assured",
      dataIndex: "relationship",
      key: "relationship",
      render: (value, record) => (
        <p>{record?.relationship_with_insured_customer?.name}</p>
      ),
    },
    { title: "Nationality", dataIndex: "nationality", key: "nationality" },
    {
      title: "Full Address",
      dataIndex: "address_line_1",
      key: "address_line_1",
      render: (text) => (
        <Tooltip title={text}>
          <p
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "150px", // Adjust maxWidth as needed
              margin: 0,
            }}
          >
            {text}
          </p>
        </Tooltip>
      ),
    },
  ];

  return (
    <Table
      dataSource={insured_customer_under_magma_plan}
      columns={columns}
      pagination={false}
      bordered
      style={{ backgroundColor: "#f0f2f5" }} // Light grey background for the table
    />
  );
};

const MagmaPlanData = ({ data }) => {
  const { insurance_magma_plan, insured_customer_under_magma_plan } = data;

  return (
    <div style={{ padding: "20px" }}>
      <Card style={{ borderWidth: "2px" }}>
        {" "}
        {/* Blue border for the card */}
        <Row gutter={24}>
          {" "}
          {/* Add gutter here for spacing between columns */}
          <Col span={12}>
            <Title level={4} style={{ color: "#1890ff", fontSize: "15px" }}>
              {" "}
              {/* Blue text for the title */}
              {insurance_magma_plan?.display_name} -{" "}
              {insurance_magma_plan?.sum_assured}
            </Title>
            <PlanTable insurance_magma_plan={[insurance_magma_plan]} />
          </Col>
          <Col span={12}>
            <Title level={4} style={{ color: "#1890ff", fontSize: "15px" }}>
              {" "}
              {/* Green text for the title */}
              Convalescence Benefit
            </Title>
            <BenefitTable insurance_magma_plan={[insurance_magma_plan]} />
          </Col>
        </Row>
      </Card>

      <Card style={{ marginTop: "20px", borderWidth: "2px" }}>
        {" "}
        {/* Green border for the card */}
        <Title level={4} style={{ color: "#1890ff", fontSize: "18px" }}>
          {" "}
          {/* Green text for the title */}
          Magma Insured Customers - {insurance_magma_plan?.display_name} -{" "}
          {insurance_magma_plan?.sum_assured}
        </Title>
        <CustomerTable
          insured_customer_under_magma_plan={insured_customer_under_magma_plan}
        />
      </Card>
    </div>
  );
};

export default MagmaPlanData;
