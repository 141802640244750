import React, { useEffect, useState } from "react";
import EditButton from "../../../../utils/editButton/EditButton";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import DocumentsOvEditForm from "./DocumentsOvEditForm";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { getFiUploadDocumentsByCustomerId } from "../../services/FIViewFormServices";
import pdf_image from "../../assets/pdf.png";
import RViewerJS from "viewerjs-react";

const DocumentsOv = ({selectedCustomerId}) => {
const [open, setOpen] = useState(false);
const [collapsed6, setCollapsed6] = useState(true);
const [refreshData, setRefreshData] = useState(false);
const [otherDocumentsOvData, setOtherDocumentsOvData] = useState(null);


  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const toggleCollapse6 = () => {
    setCollapsed6(!collapsed6);
  };

  const onClose6 = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getFiUploadDocumentsByCustomerId(selectedCustomerId);
        setOtherDocumentsOvData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData,selectedCustomerId]);

  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div style={{ margin:'10px' ,border:"1px solid #e4e7ec"}}>
      <Drawer
        title={<Header title="Edit" onClose={onClose6} name="FI Documents and Other Documents" />}
        width={970}
        onClose={onClose6}
        open={open}
        closable={false}
      >
        <DocumentsOvEditForm
          open={open}
          closeForm={onClose6}
          refreshData={toggleRefreshData}
          images={otherDocumentsOvData}
          selectedCustomerId={selectedCustomerId}
        />
      </Drawer>
      <div className="fi_description_heading">
        <div className="fi_description_data">
          FI Documents and Other Documents{" "}
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {renderEditButton()}
          </div>
          <div
            style={{ margin: "10px 10px 10px 10px" }}
            onClick={toggleCollapse6}
          >
            {collapsed6 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed6 && (
        <div>
          <>
          <div className='document_images'>
  {otherDocumentsOvData?.map((image, index) => (
    <div key={index} style={{ flex: "0 0 11.1%", boxSizing: "border-box", padding: "10px" }}>
      {image.file_type === "application/pdf" ? (
        <img
          src={pdf_image}
          width="100px"
          height="100px"
          style={{ border: 'none', cursor: 'pointer' }}
          alt="PDF Preview"
          onClick={() => window.open(image.document_path, '_blank')}
        />
      ) : (
        <RViewerJS
          options={{
            url: (img) => img.getAttribute("data-original"),
          }}
        >
          <img
            src={image.thumb_document}
            data-original={image.document_path}
            alt="Preview"
            style={{ width: "100px", height: "100px", cursor: 'pointer' }}
          />
        </RViewerJS>
      )}
      <div style={{ textAlign: "center", width: "100px", marginTop: "10px" }}>
        {image.document_type.document_name}
      </div>
    </div>
  ))}
</div>

          </>
        </div>
      )}
    </div>
  );
};

export default DocumentsOv;
