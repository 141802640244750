import React from "react";
import { Table, Empty } from "antd";

const LogTable = ({ data, fieldNameMap }) => {
  // Define columns for the sub-tables
  const columns = [
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
    },
    {
      title: "Updated By",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
      key: "modified_at",
    },
  ];

  const filteredData = data.filter(({ field_name }) =>
    field_name !== "alt_mobile_country_code" &&
    field_name !== "mobile_country_code" &&
    field_name !== "phone_country_code"
  );

  // Generate tables for each field
  const fieldTables = filteredData.map(({ field_name, log_entries }) => {
    const tableData = log_entries.map((entry, index) => ({
      key: `${field_name}-${index}`,
      old_value: entry.old_value ? (typeof entry.old_value === "object" ? entry.old_value.display_name : entry.old_value) : "-",
      new_value: entry.new_value ? (typeof entry.new_value === "object" ? entry.new_value.display_name : entry.new_value) : "-",
      username: entry.user.username,
      modified_at: entry.record_modified_at,
    }));

    return (
      <div key={field_name}>
        <h3 style={{ margin: "35px 0px 15px 10px" }}>{fieldNameMap[field_name]}</h3>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          bordered
          scroll={{ x: true }}
        />
      </div>
    );
  });

  return <div>
    {data.length > 0 ? (
      fieldTables
    ) : (
      <div className="">
        <Empty />
      </div>
    )}
  </div>;
};

export default LogTable;