import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message, Input } from "antd";
import {
  listLoanTypeService,
  listLocationService,
  listStatuservice,
  listOfUsersService
} from "../services/partDisbursedService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  gtr: Yup.string()
    .required("Greater than is required ")
    .matches(/^[0-9]+$/, "Amount can only contain numbers ")
    .label("Greater Than"),
  lt: Yup.string()
    .required("Less than is required")
    .matches(/^[0-9]+$/, "Amount can only contain numbers")
    .label("Less Than"),
  gtrb: Yup.string()
    .required("Greater than is required ")
    .matches(/^[0-9]+$/, "Amount can only contain numbers")
    .label("Greater Than"),
  ltb: Yup.string()
    .required("Less than is required")
    .matches(/^[0-9]+$/, "Amount can only contain numbers")
    .label("Less Than"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};


const PartDisbursedFilterForm = ({ open, filterFormData, closeForm, setFilterFormData, refreshData, setActionPerformed }) => {
  const [form] = Form.useForm();
  const [allLocation, setAllLocation] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [status, setStatus] = useState([]);
  const [users, setUsers] = useState([])


  useEffect(() => {
    fetchData();
    fetchLoanData();
    fetchStatus();
    fetchUsers();
  }, [open]);

  const handleSubmit = (values) => {
    let updatedValues = { ...values };

    if (!values.location || values.location.length === 0) {
      delete updatedValues.location;
    }

    // Remove fields with empty string values
    Object.keys(updatedValues).forEach((key) => {
      if (updatedValues[key] === "") {
        delete updatedValues[key];
      }
    });

    // Check if amountType is set to "yes", then add requested_amount: true
    if (updatedValues.amountType !== "") {
      updatedValues = {
        ...updatedValues,
        requested_amount: true
      };
    }

    if (updatedValues.gtrb) {
      updatedValues.gtr = updatedValues.gtrb;
      delete updatedValues.gtrb;
    }
    if (updatedValues.ltb) {
      updatedValues.lt = updatedValues.ltb;
      delete updatedValues.ltb;
    }

    setFilterFormData({
      filter: true,
      ...updatedValues
    });

    refreshData();
    // form.resetFields();
    closeForm();
    setActionPerformed(true);
  };

  const handleClearFilters = (filterFormData) => {
    setFilterFormData({}); // Clear all filter data

    // Create a new object with updated amountType
    const updatedFilterData = {
      ...filterFormData,
      amountType: "",
      location: [],
      gtr: "",
      lt: "",
      gtrb: "",
      ltb: "",
      loan_type: [],
      loan_status: [],
      sales_manager: [],
      created_by: [],
      modified_by:[],
    };

    // Reset form fields
    form.setFieldsValue(updatedFilterData);
    setActionPerformed(true);

  };


  const fetchData = async () => {
    try {
      const locationResponse = await listLocationService();
      setAllLocation(locationResponse.data);
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  const fetchLoanData = async () => {
    try {
      const locationResponse = await listLoanTypeService();
      setLoanType(locationResponse.data);
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  const fetchStatus = async () => {
    try {
      const locationResponse = await listStatuservice();
      setStatus(locationResponse.data);
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  const fetchUsers = async () => {
    try {
      const usersResponse = await listOfUsersService();
      setUsers(usersResponse.data);
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          location: filterFormData.location || [],
          gtr: filterFormData.gtr,
          lt: filterFormData.lt,
          amountType: filterFormData.amountType,
          loan_type: [],
          loan_status: [],
          sales_manager: [],
          created_by: [],
          modified_by:[],
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="location" label="Location">
              <Select
                mode="multiple"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  form.setFieldsValue({ location: value });
                }}
              >
                {allLocation?.map((location) => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item name="loan_type" label="Loan Type">
              <Select
                mode="multiple"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  form.setFieldsValue({ loan_type: value });
                }}
              >
                {loanType?.map((loan_type) => (
                  <Select.Option key={loan_type.id} value={loan_type.id}>
                    {loan_type.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="loan_status" label="Status">
              <Select
                mode="multiple"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  form.setFieldsValue({ status: value });
                }}
              >
                {status?.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="sales_manager" label="Sales Manager">
              <Select
                mode="multiple"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  form.setFieldsValue({ status: value });
                }}
              >
                {users?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="created_by" label="Created By">
              <Select
                mode="multiple"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {users?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="modified_by" label="Modified By">
              <Select
                mode="multiple"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {users?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item name="is_active" label="Is Active">
              <Select placeholder="Please Select">
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="is_verified" label="Is Verified">
              <Select placeholder="Please Select">
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item name="amountType" label="Amount">
              <Select
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                onChange={(value) => {
                  setFilterFormData({ ...filterFormData, amountType: value, lt: "", gtr: "" });
                }}

                value={filterFormData.amountType}
              >
                <Select.Option key="1" value={1}>
                  Greater Than
                </Select.Option>
                <Select.Option key="2" value={2}>
                  Less Than
                </Select.Option>
                <Select.Option key="3" value={3}>
                  Between
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {(filterFormData.amountType === 1) && (
            <Col span={12}>
              <Form.Item name="gtr" label="Greater Than Amount" rules={[yupSync]}>
                <Input placeholder="Enter Greater Than Amount" />
              </Form.Item>
            </Col>
          )}
          {(filterFormData.amountType === 2) && (
            <Col span={12}>
              <Form.Item name="lt" label="Less Than Amount" rules={[yupSync]}>
                <Input placeholder="Enter Less Than Amount" />
              </Form.Item>
            </Col>
          )}
          {(filterFormData.amountType === 3 || filterFormData.amountType === 3) && (
            <>
              <Col span={12}>
                <Form.Item name="gtrb" label="Greater Than Amount" rules={[yupSync]}>
                  <Input placeholder="Enter Greater Than Amount" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="ltb" label="Less Than Amount" rules={[yupSync]}>
                  <Input placeholder="Enter Less Than Amount" />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Space direction="horizontal" align="center" style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={handleClearFilters}>Clear Filters</Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default PartDisbursedFilterForm;
