import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import * as Yup from "yup";
import 'react-quill/dist/quill.snow.css';
import "../../LegalInitiationForm/style/legalInitiationForm.css";
import "../../commonCSS/initiationFormCommonForm.css"
import { PlusOutlined } from '@ant-design/icons';
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { useParams } from 'react-router-dom';
import { htmlToText } from 'html-to-text';
import {
    createLegalInitiationDataService,
    getAllAgencyService,
    // getAllAgencyTypeService,
    getAllAgentService,
    getAllUserListService,
    getBasicDetailsDetailsById,
    getCustomerNameByIdService,
    getLoanDetailsById,
    getPropertyDetailsById
} from '../services/technicalServices';
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
    agency: Yup.string()
        .required("Vendor is required")
        .min(1, "Vendor is required"),
    agent: Yup.string()
        .required("Agent name is required")
        .min(1, "Agent name is required"),
    agency_type: Yup.string()
        .required("Agency type is required")
        .min(1, "Agency type is required"),
    internal_technical_manager: Yup.string()
        .required("Internal technical manager name is required")
        .min(1, "Internal technical manager name is required"),


});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};



const TechnicalInitiationForm = ({ closeForm, open }) => {
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [value, setValue] = useState('');
    const [allAgencies, setAllAgencies] = useState([]);
    const [allAgents, setAllAgents] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    // const [allagency, setAllAgency] = useState([]);
    const [PropertyAddress, setpropertyAddress] = useState([]);
    const [basic, setBasic] = useState([])
    const [loanDetails, setLoanDetails] = useState([]);
    const [isPropertyChecked, setIsPropertyChecked] = useState(true);
    const [applicant, setApplicant] = useState({});
    const [fileList, setFileList] = useState([]);
    const [isAgencySelected, setIsAgencySelected] = useState(false);
    const [selectedAgencyId, setSelectedAgencyId] = useState('');
    const { user_data } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);

    const generateMailSubject = () => {
        const locationName = loanDetails?.location?.display_name || '';
        const loanAccountNumber = loanDetails?.loan_account_number || '';
        return `Technical initiate  - ${loanAccountNumber} - ${applicant.firstName}  ${applicant.middleName} ${applicant.lastName} (${applicant.type}) - ${locationName}`;
    };


    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {

        const getAllAgencyTypes = async () => {
            try {
                const response = await getAllAgencyService();
                setAllAgencies(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };

        // const getAllAgencyType = async () => {
        //     try {
        //         const response = await getAllAgencyTypeService();
        //         setAllAgency(response.data);
        //     } catch (error) {
        //         message.error(error.response.data.message);
        //     }
        // };
        const getAllUser = async () => {
            try {
                const response = await getAllUserListService();
                setAllUsers(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        const getAllPropertyDetails = async () => {
            try {
                const response = await getPropertyDetailsById(loan_id);
                setpropertyAddress(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        const getAllBasicDetails = async () => {
            try {
                const response = await getBasicDetailsDetailsById(loan_id);
                setBasic(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        const getAllLoanDetails = async () => {
            try {
                const response = await getLoanDetailsById(loan_id);
                setLoanDetails(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        const getAllCustomers = async () => {
            try {
                const response = await getCustomerNameByIdService(loan_id);
                if (response && response.data) {
                    const customers = response.data.customers || [];;
                    const applicant = customers.find(customer => customer.customer_type.display_name === "Applicant");
                    if (applicant) {
                        setApplicant({
                            firstName: applicant.first_name,
                            middleName: applicant.middle_name || '',
                            lastName: applicant.last_name || '',
                            type: applicant.customer_type.display_name,
                            mobile_number: applicant.mobile_number || '',
                        });
                    }

                }
                if (user_data?.email) {
                    form.setFieldsValue({ mail_cc: user_data.email });
                }
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        getAllAgencyTypes();
        // getAllAgencyType();
        getAllUser();
        getAllPropertyDetails();
        getAllBasicDetails();
        getAllLoanDetails();
        getAllCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, loan_id, user_data, open]);

    const handleAgencyChange = (value) => {
        if (!value) {
            setAllAgents([]);
            setSelectedAgencyId('');
            setIsAgencySelected(false);
            form.setFieldsValue({ agent: undefined });
            form.setFieldsValue({ mail_to: undefined });
        } else {
            const selectedAgency = allAgencies.find(agency => agency.code === value);
            setSelectedAgencyId(selectedAgency ? selectedAgency.id : '');
            setIsAgencySelected(!!value);
            if (value) {
                getAllAgents(value); // Call with the selected value
            }
        }
    };

    const getAllAgents = async (agencyCode) => {
        try {
            const response = await getAllAgentService(agencyCode);
            setAllAgents(response.data);
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    const handleAgentChange = (agentId) => {
        if (!agentId) {
            form.setFieldsValue({ mail_to: undefined });
        } else {
            const selectedAgent = allAgents.find(agent => agent.id === agentId);

            // Update the mail_cc field with the selected agent's email
            if (selectedAgent) {
                form.setFieldsValue({
                    mail_to: selectedAgent.email,
                });
            }
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true); 
        // const mailCC = values.mail_cc;
        // const addCC = values.add_cc;
        // const concatenatedCC = [mailCC, addCC].filter(Boolean).join(',');
        try {
            const valuesWithoutAgency = { ...values };
            delete valuesWithoutAgency.agency;
            delete valuesWithoutAgency.mail_subject;

            const formData = new FormData();

            for (let key in valuesWithoutAgency) {
                if (key === 'remark') {
                    formData.append(key, htmlToText(value));
                } else if (key !== 'add_cc') { // Exclude add_cc
                    formData.append(key, valuesWithoutAgency[key]);
                }
            }

            if (isPropertyChecked) {
                formData.append('property_address', concatenatedAddress);
            }

            fileList.forEach(file => {
                formData.append('attachment', file.originFileObj);
            });

            // Generate the email body content
            const emailBodyContent = `
                <style>
                    .email_div_container {
                        border: 1px solid #D9D9D9;
                        border-radius: 5px;
                        margin-top: 20px;
                    }
                    .content_block {
                        display: flex;
                        flex-direction: row;
                    }
                    .email_heading {
                        width: 23%;
                        padding: 10px;
                        border-right: 1px solid #D9D9D9;
                    }
                    .email_content {
                        padding: 10px 10px 0px 10px;
                        width: 77%;
                    }
                    .initiation_form_table_one {
                        margin-top: 10px;
                        border: 1px solid #b8b3b373;
                        border-radius: 8px;
                    }
                    .initiation_form_table_data {
                        border-bottom: 1px solid #b8b3b373;
                        display: flex;
                        flex-direction: row;
                    }
                    .initiation_form_table_data_heading {
                        width: 35%;
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 10px;
                        text-align: left;
                        color: #000000B2;
                        margin-left: 8px;
                        border-right: 1px solid #D9D9D9;
                    }
                    .initiation_form_table_data_value {
                        padding: 10px;
                        width: 65%;
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                        text-align: left;
                        color: #000000B2;
                        margin-left: 8px;
                    }
                </style>
                Dear Team,<br>
                Please initiate Legal and Title Search for Mr. ${applicant.firstName} ${applicant.middleName} ${applicant.lastName} (${applicant.type})
                <div class="initiation_form_table_one">
                    <div class="initiation_form_table_data">
                        <div class="initiation_form_table_data_heading">Customer Name</div>
                        <div class="initiation_form_table_data_value">${applicant.firstName} ${applicant.middleName} ${applicant.lastName} (${applicant.type})</div>
                    </div>
                    <div class="initiation_form_table_data">
                        <div class="initiation_form_table_data_heading">LAN</div>
                        <div class="initiation_form_table_data_value">${loanDetails.loan_account_number ? loanDetails.loan_account_number : '-'}</div>
                    </div>
                    <div class="initiation_form_table_data">
                        <div class="initiation_form_table_data_heading">Product</div>
                        <div class="initiation_form_table_data_value">${loanDetails?.loan_type?.name}</div>
                    </div>
                    <div class="initiation_form_table_data">
                        <div class="initiation_form_table_data_heading">Property Address</div>
                        <div class="initiation_form_table_data_value">${concatenatedAddress}</div>
                    </div>
                    <div class="initiation_form_table_data">
                        <div class="initiation_form_table_data_heading">Branch</div>
                        <div class="initiation_form_table_data_value">${loanDetails?.location?.name}</div>
                    </div>
                </div>
            `;

            // Append the email body and other required fields
            formData.append('mail_body', emailBodyContent);
            formData.append('loan_detail', loan_id);
            formData.append('stage_initiate', 'Technical');
            formData.append('property_address', concatenatedAddress);
            formData.append('stage', "MSLAIA3");
            formData.append('stage_status', "MSSTIDA4");
            formData.append('agency', selectedAgencyId);
            formData.append('mail_subject', generateMailSubject());
            // formData.append('agent', valuesWithoutAgency.agent);
            // formData.append('internal_technical_manager', values.internal_technical_manager);
            // formData.append('agency_type', values.agency_type);

            const response = await createLegalInitiationDataService(formData);
            if (response.status === 200 && response.success) {
                message.success('Technical initiation created successfully');
                form.resetFields();
                setFileList([]);
                setValue(null);
                setIsAgencySelected(false);
                closeForm();
            }
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
            closeForm()
            setLoading(false)
        }
        // }finally{  
        //     setLoading(false)
            
        //   }
    };



    const handleCheckboxChange = (e) => {
        setIsPropertyChecked(e.target.checked);
    };

    useEffect(() => {
        form.resetFields();
        setIsAgencySelected(false);
        setFileList([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeForm]);

    const concatenatedAddress = `${PropertyAddress?.address_line_1 || ''}, ${PropertyAddress?.address_line_2 || ''}, ${PropertyAddress?.landmark || ''}, ${PropertyAddress?.taluka?.name || ''}, ${PropertyAddress?.district?.name || ''}, ${PropertyAddress?.city?.name || ''}, ${PropertyAddress?.pincode?.name || ''}, ${PropertyAddress?.state?.name || ''}, ${PropertyAddress?.country?.name || ''}`;

    const handleUpload = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };



    return (
        <>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    // agency: "",
                    // agent: "",
                    // transaction_type: "",
                    // internal_technical_manager: "",
                    mail_to: "",
                    mail_cc: "",
                    mail_subject: "",
                    remark: "",
                    attachment: "",
                }}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item name="agency" label="Vendor" rules={[yupSync]} required >
                            <Select
                                mode="single"
                                placeholder="Please select"
                                required={true}
                                allowClear
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleAgencyChange}
                            >
                                {allAgencies?.map((agency_type) => {
                                    return (
                                        <Select.Option key={agency_type.code} value={agency_type.code}>
                                            {agency_type.display_name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="agent" label="Agent Name" rules={[yupSync]} required>
                            <Select
                                mode="single"
                                placeholder="Please select"
                                required={true}
                                allowClear
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleAgentChange}
                                disabled={!isAgencySelected}
                            >
                                {allAgents?.map((agents) => {
                                    return (
                                        <Select.Option key={agents.id} value={agents.id}>
                                            {agents.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item name="agency_type" label="Agency Type" rules={[yupSync]} required>
                            <Select
                                mode="single"
                                placeholder="Please select"
                                required={true}
                                allowClear
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {allagency?.map((agne) => {
                                    return (
                                        <Select.Option key={agne.id} value={agne.id}>
                                            {agne.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col span={8}>
                        <Form.Item name="internal_technical_manager" label="Internal Technical Manager Name" rules={[yupSync]} required>
                            <Select
                                mode="single"
                                placeholder="Please select"
                                required={true}
                                allowClear
                                style={{ width: "100%" }}

                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {allUsers?.map((user) => (
                                    <Select.Option key={user.id} value={user.id}>
                                        {user.name}

                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <div  >
                    <div>Properties</div>
                    <div style={{ display: 'flex', marginTop: "5px" }}>
                        <div>
                            <Checkbox
                                onChange={handleCheckboxChange}
                                checked={isPropertyChecked}
                            />
                        </div>

                        <div className='legal_property_heading' style={{ marginLeft: '5px', width: "10%" }}>Property :</div>
                        <div style={{ marginLeft: "-30px" }}> {concatenatedAddress}</div>
                    </div>

                    {!isPropertyChecked && (
                        <div style={{ color: 'red', marginLeft: '10px' }}>
                            Property is required
                        </div>
                    )}
                    {/* <div className='legal_note'>Note: Please edit the same in sales form.</div> */}
                </div>


                <div className="email_div_container">
                    <div style={{ width: "100%" }}>
                        <div className="content_block">
                            <span className="email_heading"> <span className='all_required_sign'> *</span> To :</span>

                            <span className="email_content"><Form.Item name="mail_to" rules={[{ required: true, message: 'Please enter the email' }]} >
                                <Input
                                    disabled
                                />
                            </Form.Item></span>
                        </div>
                        <div className="content_block">
                            <span className="email_heading"> <span className='all_required_sign'> *</span>CC :</span>
                            <span className="email_content"><Form.Item name="mail_cc" rules={[{ required: true, message: 'Please enter the cc' }]}>
                                <Input
                                    disabled />
                            </Form.Item></span>
                        </div>
                        <div className="content_block">
                            <span className="email_heading">Add CC :</span>
                            <span className="email_content"><Form.Item name="add_cc"><Input /></Form.Item></span>

                        </div>
                        <div className="content_block">
                            <span className="email_heading"> <span className='all_required_sign'> *</span> Subject :</span>
                            <span className="email_content"><Form.Item name="mail_subject">
                                {generateMailSubject()}
                            </Form.Item></span>
                        </div>
                        <div className="content_block">
                            <span className="email_heading">Message :</span>
                            <span className="email_content">Dear Team,<br></br>
                                Please Initiate Technical and Title Search for {applicant.firstName} {applicant.lastName}<br></br>
                                <div className="initiation_form_table_one ">
                                    <div className="initiation_form_table_data">
                                        <div className="initiation_form_table_data_heading">Customer Name</div>
                                        <div className="initiation_form_table_data_value"> {applicant.firstName} {applicant.middleName} {applicant.lastName} ({applicant.type})</div>
                                    </div>
                                    <div className="initiation_form_table_data">
                                        <div className="initiation_form_table_data_heading">
                                            LAN
                                        </div>
                                        <div className="initiation_form_table_data_value">  {basic ? (
                                            <div>
                                                {basic.loan_account_number ? basic.loan_account_number : '-'}
                                            </div>
                                        ) : (
                                            <div className="initiation_form_table_data_value"> - </div>
                                        )}
                                        </div>
                                    </div>
                                    <div className="initiation_form_table_data">
                                        <div className="initiation_form_table_data_heading">
                                            Product
                                        </div>
                                        {basic ? (
                                            <div className="initiation_form_table_data_value">{basic?.loan_type?.name}</div>
                                        ) : (
                                            <div className="initiation_form_table_data_value">-</div>
                                        )}


                                    </div>

                                    <div className="initiation_form_table_data">
                                        <div className="initiation_form_table_data_heading">Property Address</div>
                                        <div className="initiation_form_table_data_value"> {concatenatedAddress}
                                        </div>
                                    </div>
                                    <div className="initiation_form_table_data">
                                        <div className="initiation_form_table_data_heading">Branch</div>
                                        {basic ? (
                                            <div className="initiation_form_table_data_value">{basic?.location?.name}</div>
                                        ) : (
                                            <div className="initiation_form_table_data_value">-</div>
                                        )}
                                    </div>
                                    <div className="initiation_form_table_data">
                                        <div className="initiation_form_table_data_heading">Contact Person</div>
                                        <div className="initiation_form_table_data_value">{applicant.firstName}  {applicant.middleName} {applicant.lastName} ({applicant.type}) - {applicant.mobile_number}</div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        {/* <div className="content_block">
                            <span className="email_heading">  <span style={{ color: 'red' }}> *</span>Remarks:<br></br>
                                (If attached, please mention the list of documents)</span>
                            <span className="email_content"> <Form.Item name="remark" rules={[{ required: true, message: 'Please enter the  remark' }]}> <ReactQuill theme="snow" value={value} onChange={setValue} /></Form.Item></span>
                        </div> */}


                        <div className="content_block">
                            <span className="email_heading">
                                <span className='all_required_sign'> *</span> Remarks:<br />
                                (If attached, please mention the list of documents)
                            </span>
                            <span className="email_content">
                                <Form.Item
                                    name="remark"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter remarks'
                                        }
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        value={value}
                                        onChange={(val) => {
                                            setValue(val);
                                            form.setFieldsValue({ remark: val });
                                        }}
                                    />
                                </Form.Item>
                            </span>
                        </div>


                        <div className="content_block">
                            <span className="email_heading">Attachments :</span>
                            <span className="email_content">
                                <Upload

                                    multiple
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={fileList}
                                    showUploadList={{
                                        showPreviewIcon: false,
                                        showRemoveIcon: true,
                                    }}

                                    onChange={handleUpload}
                                    // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                    beforeUpload={() => false}

                                >



                                    <button style={{ border: 0, background: 'none' }} type="button">
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </button>


                                </Upload>

                            </span>
                        </div>





                    </div>
                </div>
                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse", marginTop: "20px" }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Initiate
                    </Button>

                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
                <br></br>
            </Form>

        </>
    )
}

export default TechnicalInitiationForm