import { axiosRequest } from '../../../utils/api/axiosRequest';

export const getEmployeeAssignedWork = () => {
    return new Promise(async (resolve, reject) => {
        try {
          const response = await axiosRequest.get("/api/v1/dashboard_lite/get-work-list/");
          return resolve(response.data)
        } catch (error) {
          return reject(error)
        }
      })
}
