import React from 'react'
import { Table, } from "antd";
import "../../partDisbursedInitiate/styles/partDisbursed.css";


const ChargesReceivable = () => {

  const paymentData = [
    {
      sr_no: "1",
      charges_name: "total",
      table_due: "0",

    },
  ]


  const columns = [

    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text) => text || "-",
    },

    {
      title: "Charges Name",
      dataIndex: "charges_name",
      key: "charges_name",
    },
    {
      title: "Table Due",
      dataIndex: "table_due",
      key: "table_due",
    }

  ];

  return (
    <div>
        <>
          <div className="part_disbursed_table">
            <div className="part_disbursed_heading">
              <div className="part_disbursed_data" >
              <h3>Charges Receivable</h3>
              </div>
            </div>

            <div className='table_content'>
              <Table
                dataSource={paymentData}
                columns={columns}
                pagination={false}
              />
            </div>

          </div>
        </>


      </div>
    
  )
}

export default ChargesReceivable


