import { axiosRequest } from "../api/axiosRequest";

export const getTableDataService = (
  apiPath,
  page,
  limit,
  searchQuery,
  sortField,
  sortOrder,
  filters
) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;
      // let path = `${apiPath}?page=${page}&limit=${limit}`;
      let path = `${apiPath}${apiPath.includes('?') ? '&' : '?'}page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        // path = `${apiPath}?search=${searchQuery}&page=${page}&limit=${limit}`;
        path = `${apiPath}${apiPath.includes('?') ? '&' : '?'}search=${searchQuery}&page=${page}&limit=${limit}`;
      }

      if (sortField && sortOrder) {
        path += `&field=${sortField}&order=${sortOrder}`;
      }

      if (filters && filters.filter) {
        // Iterate through the keys of filters and append to the path

        Object.keys(filters).forEach((key) => {
          if (filters[key] !== null) {
            // Check if the value is an array and convert it to a comma-separated string
            if (Array.isArray(filters[key]) && filters[key].length > 0) {
              const value =
                Array.isArray(filters[key]) && filters[key].join(",");

              path += `&${key}=${value}`;
            } else {
              path += `&${key}=${filters[key]}`;
            }
          }
        });
      }

      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};





