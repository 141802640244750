import React, { useState, useEffect } from 'react' 
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Checkbox, Drawer } from 'antd';
import Header from "../../../../layout/views/Header";
import '../styles/valuationDetails.css'
import '../../../../loanApplication/loanTabDetails/basicDetails/styles/basicDetails.css'
import ValuePropertyDetailsForm from './ValuePropertyDetailsForm';
import ValueCertificationDetailsForm from './ValueCertificationDetailsForm';
import ValuationPropertyDetailsForm from './ValuationPropertyDetailsForm'; 
import { getPropertyTechnicalDataByIdService, getPropertyTechMarketValueByIdService } from '../services/valuationDetailsServices';
import "../../commonCSS/commonTechnicalValuation.css"
import Loading from "../../../../../utils/loading/Loading";
import { Empty } from 'antd'; 
import EditButton from '../../../../../utils/editButton/EditButton';



const ValuationDetails = ({stage_id}) => { 
  const [valuationPropertyData, setValuationPropertyData] = useState(null);
  const [propertyMarketValue, setPropertyMarketValue] = useState(null);

  const [openPropertyForm, setOpenPropertyForm] = useState(false);
  const [openValuationPropertyForm, setOpenValuationPropertyForm] = useState(false);
  const [openCertificationForm, setOpenCertificationForm] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [showValueProperty, setShowValueProperty] = useState(true);
  const [showGovtValueProperty, setShowGovtValueProperty] = useState(true); // State variable to manage visibility
  const [collapseSections, setCollapseSections] = useState(true);
  const [loading, setLoading] = useState(true);


  const showValuationPropertyForm = () => {
    setOpenValuationPropertyForm(true);
  };

  const showPropertyForm = () => {
    setOpenPropertyForm(true);
  };

  const showCertificationForm = () => {
    setOpenCertificationForm(true);
  }; 
  
  const handleFormClose = () => {
    setOpenValuationPropertyForm(false);
    setOpenPropertyForm(false);
    setOpenCertificationForm(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  }; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPropertyTechnicalDataByIdService(stage_id);
        setValuationPropertyData(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [stage_id, refreshData]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPropertyTechMarketValueByIdService(stage_id);
        setPropertyMarketValue(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [stage_id, refreshData]); 
 
  const handleCheckboxChange = (e) => {
    setValuationPropertyData((prevData) => ({
      ...prevData,
      technical_detail: prevData.technical_detail.map((detail, index) =>
        index === 0 ? { ...detail, declaration: detail.declaration === 1 ? 0 : 1 } : detail
      )
    }));
  };


  return (
    <>
      <div className='main_basic_container'>
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <>
            <div className='fade-in'>
              <div className='valuation_container'>
                <div className='valuation_details'>
                  <div className='valuation_details_blue' >Valuation Of Property ( Fair Market Valuation /Distress Valuation)</div>
                  <div >
                    <div style={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
                      {/* <div className="underwriting_form_sub_edit" onClick={showPropertyForm}>
                        <img src={edit} alt="Pencil Icon" />
                        <span>Edit</span>
                      </div> */}
                     <div onClick={showPropertyForm} style={{ display: "flex", alignItems: "center"}}>
                      <EditButton className="editbutton" /> 
                      </div>
                      {collapseSections ? (
                        <UpOutlined onClick={() => setCollapseSections(!collapseSections)} style={{ margin: "16px" }} />
                      ) : (
                        <DownOutlined onClick={() => setCollapseSections(!collapseSections)} style={{ margin: "16px" }} />
                      )}
                    </div>
                  </div>

                  <Drawer
                    title={
                      <Header
                        title="Edit"
                        onClose={handleFormClose} // Pass onClose function to the Header component
                        name="Valuation Of Property ( Fair Market Valuation /Distress Valuation)"
                      />
                    }
                    width={970}
                    onClose={handleFormClose}
                    visible={openPropertyForm}
                    styles={{ body: { paddingBottom: 80 } }}
                    closable={false}
                    refreshData={toggleRefreshData}
                  >
                    <ValuationPropertyDetailsForm
                      open={openPropertyForm}
                      closeForm={handleFormClose}
                      refreshData={toggleRefreshData}
                      stage_id={stage_id}
                    />
                  </Drawer>


                </div>

                {(collapseSections && (


                  <>
                    {propertyMarketValue?.technical_market_valuation_details ? (
                      <div className='fade-in '>
                        <div className='valuation_details_heading'>
                          <div className='valuation_details_data_td_heading'>Area Type</div>
                          <div className='valuation_details_data_td_heading_first'>As per Plan/deed (in sqft)</div>
                          <div className='valuation_details_data_td_heading_first'>As per Site (In sqft)</div>
                          <div className='valuation_details_data_td_heading_first'>Deviation in %</div>
                          <div className='valuation_details_data_td_heading_first'>Rate / Sqft</div>
                          <div className='valuation_details_data_td_heading_first'>% Completed</div>
                          <div className='valuation_details_data_td_heading_first'>% Recommended</div>
                          <div className='valuation_details_data_td_heading_first'>Valuation (₹)</div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Plot Area/UDS</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.rate}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.valuation}</div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Existing construction area</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.rate}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.valuation}</div>
                        </div>

                        <div className='valuation_details_data_red'>
                          <div className='valuation_details_data_td'>Proposed Construction area
                            <div className='valuation_data_red'>(Under-construction properties should be updated here)</div></div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.rate}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.valuation}</div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Amenities</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.rate}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.valuation}</div>
                        </div>

                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Fair Market Value (₹)</div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'>{valuationPropertyData?.technical_detail[0]?.completed_value}</div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Total Property value on 100% completion (₹) </div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'>{valuationPropertyData?.technical_detail[0]?.total_market_value}</div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Forced sale Value(₹) at 75%</div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'>{valuationPropertyData?.technical_detail[0]?.forced_sale_value}</div>
                        </div>

                        <div className='valuation_details_data_last'>
                          <div >
                            Considered for valuation is:- Total Property value of 100% completed (₹):{valuationPropertyData?.technical_detail[0]?.total_market_value}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="no_data_found_message">
                        <Empty />
                      </div>
                    )}
                  </>
                )
                )}

              </div>


              <div className='valuation_container'>
                <div className='valuation_details'>
                  <div className='valuation_details_blue'>As Per Govt. Value Of Property</div>
                  <div>
                    <div style={{ display: "flex", justifyItems: "center" }}>
                      {/* <div className="underwriting_form_sub_edit" onClick={showValuationPropertyForm}>
                        <img src={edit} alt="Pencil Icon" />
                        <span>Edit</span>
                      </div> */}
                       <div onClick={showValuationPropertyForm} style={{ display: "flex", alignItems: "center"}}>
                      <EditButton />
                      </div>
                      {showGovtValueProperty ? (
                        <UpOutlined onClick={() => setShowGovtValueProperty(!showGovtValueProperty)} style={{ margin: "16px" }} />
                      ) : (
                        <DownOutlined onClick={() => setShowGovtValueProperty(!showGovtValueProperty)} style={{ margin: "16px" }} />
                      )}
                    </div>
                    <Drawer
                      title={<Header title="Edit" onClose={handleFormClose} name="As Per Govt. Value Of Property" />}
                      width={970}
                      onClose={handleFormClose}
                      visible={openValuationPropertyForm}
                      styles={{ body: { paddingBottom: 80 } }}
                      closable={false}
                      refreshData={toggleRefreshData}
                    >
                      <ValuePropertyDetailsForm
                        open={openValuationPropertyForm} closeForm={handleFormClose} refreshData={toggleRefreshData} stage_id={stage_id}/>
                    </Drawer>

                  </div>
                </div>

                {showGovtValueProperty && (
                  <>
                    {valuationPropertyData?.technical_detail?.length > 0 ? (

                      <div className='value_property'>


                        <div className='value_property_heading'> Government guideline/circle rate for land(Rs Per Sq.ft)
                          {valuationPropertyData ? (
                            <div className="value">₹ {valuationPropertyData?.technical_detail[0]?.land_value}</div>
                          ) : (
                            <div className="value">-</div>
                          )}
                        </div>

                        <div className='value_property_heading'>Land value as per govt rate (₹)

                          {valuationPropertyData ? (
                            <div className="value">  <br></br>₹ {valuationPropertyData?.technical_detail[0]?.land_value_government}</div>
                          ) : (
                            <div className="value">  <br></br>-</div>
                          )}
                        </div>


                        <div className='value_property_heading'>Government guideline/circle rate for building (₹ Per Sq.ft)
                          {valuationPropertyData ? (
                            <div className="value">₹ {valuationPropertyData?.technical_detail[0]?.government_rate_for_bldg}</div>
                          ) : (
                            <div className="value">-</div>
                          )}
                        </div>


                        <div className='value_property_heading'>Flat/apartment value as per government rate (₹)
                          {valuationPropertyData ? (
                            <div className="value">₹ {valuationPropertyData?.technical_detail[0]?.apt_value_gov}</div>
                          ) : (
                            <div className="value">-</div>
                          )}
                        </div>

                        <div className='value_property_heading'>Total valuation of property as per government rate
                          {valuationPropertyData !== null && valuationPropertyData !== undefined ? (
                            <div className="value">₹ {valuationPropertyData?.technical_detail[0]?.total_valuation_goverment ?? "-"}</div>
                          ) : (
                            <div className="value">-</div>
                          )}
                        </div>

                        <div className='value_property_heading'> Remarks
                          {valuationPropertyData ? (
                            <div className="value">  <br></br>{valuationPropertyData?.technical_detail[0]?.valuation_remark}</div>
                          ) : (
                            <div className="value">  <br></br>-</div>
                          )}
                        </div>

                      </div>
                    ) : (
                      <div className="no_data_found_message">
                        <Empty />
                      </div>
                    )}
                  </>
                )}




              </div>

              <div className='valuation_container'>
                <div className='valuation_details'>
                  <div className='valuation_details_blue'>Valuer Certification</div>
                  <div>
                    <div style={{ display: "flex", justifyItems: "center" }}>
                      {/* <div className="underwriting_form_sub_edit" onClick={showCertificationForm}>
                        <img src={edit} alt="Pencil Icon" />
                        <span>Edit</span>
                      </div> */}
                      <div  onClick={showCertificationForm} style={{ display: "flex", alignItems: "center"}}>
                      <EditButton/>
                      </div>
                      {showValueProperty ? (
                        <UpOutlined onClick={() => setShowValueProperty(!showValueProperty)} style={{ margin: "16px" }} />
                      ) : (
                        <DownOutlined onClick={() => setShowValueProperty(!showValueProperty)} style={{ margin: "16px" }} />
                      )}
                    </div>
                    <Drawer
                      title={<Header title="Edit" onClose={handleFormClose} name="Valuer Certification" />}
                      width={970}
                      onClose={handleFormClose}
                      visible={openCertificationForm}
                      styles={{ body: { paddingBottom: 80 } }}
                      closable={false}
                      refreshData={toggleRefreshData}
                    >
                      <ValueCertificationDetailsForm
                        open={openCertificationForm} closeForm={handleFormClose} refreshData={toggleRefreshData} stage_id={stage_id}/>
                    </Drawer>
                  </div>
                </div>

                {showValueProperty && (


                  <>
                    {valuationPropertyData?.technical_detail?.length > 0 ? (
                      <>
                        <div className='value_property'>


                          <div className='value_property_heading'> Date of visit
                            {valuationPropertyData ? (
                              <div className="value">{valuationPropertyData?.technical_detail[0]?.date_of_visit}</div>
                            ) : (
                              <div className="value">-</div>
                            )}
                          </div>

                          <div className='value_property_heading'>Date of report submission
                            {valuationPropertyData ? (
                              <div className="value">{valuationPropertyData?.technical_detail[0]?.date_of_valuation}</div>
                            ) : (
                              <div className="value">-</div>
                            )}
                          </div>

                          <div className='value_property_heading'>Name of Engineer Visited the property
                            {valuationPropertyData ? (
                              <div className="value">{valuationPropertyData?.technical_detail[0]?.engineer_name}</div>
                            ) : (
                              <div className="value">-</div>
                            )}
                          </div>

                          <div className='value_property_heading'>Place
                            {valuationPropertyData ? (
                              <div className="value">{valuationPropertyData?.technical_detail[0]?.place}</div>
                            ) : (
                              <div className="value">-</div>
                            )}
                          </div>

                        </div>

                        <div>
                          <div className='declaration' style={{ marginBottom: "10px" }}>Remark
                            {valuationPropertyData ? (
                              <>
                                <br></br>
                                <Checkbox
                                  style={{ marginLeft: "0.5%" }}
                                  onChange={handleCheckboxChange}
                                  checked={valuationPropertyData?.technical_detail[0]?.declaration === 1}
                                />
                                <span style={{ marginLeft: "5px", color: "#000000B2" }}>I Hereby Declare that</span><br></br>
                                <span style={{ marginLeft: "27px", color: "#000000B2" }}> I Have visited this property.</span><br></br>
                                <span style={{ marginLeft: "27px", color: "#000000B2" }}>I Have no Direct or indirect interest in the Property Valued.</span><br></br>
                                <span style={{ marginLeft: "27px", color: "#000000B2" }}> The Information in this Report is True and Correct to the best of my Knowledge and Belief.
                                </span>

                              </>
                            ) : (
                              <div className="value">-</div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="no_data_found_message">
                        <Empty />
                      </div>
                    )}
                  </>
                )}

              </div>
            </div>
          </>
        )}
      </div >
    </>


  );
}

export default ValuationDetails;
