import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createPermissionService,
  getPermissionByIdService,
  listModuleNameService,
  updatePermissionByIdService,
} from "../services/permissionService";

const { Option } = Select;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters")
    .min(2, "Name must be at least 2 characters!")
    .max(128, "Name must be at max 128 characters!")
    .label("Name"),
  display_name: Yup.string()
    .required("Display Name is required")
    .matches(/^[A-Za-z ]+$/, "Display Name can only contain letters")
    .min(2, "Display Name must be at least 2 characters!")
    .max(128, "Display Name must be at max 128 characters!")
    .label("Display Name"),
    code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
  module_name: Yup.string()
    .required("Module Name is required!")
    .label("Module Name"),
  is_active: Yup.boolean(),
  is_verified: Yup.boolean(),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};
const PermissionForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [moduleName, setModuleName] = useState([]);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);


  const getPermissionData = async () => {
    if (id) {
      try {
        const response = await getPermissionByIdService(id);
        const {
          name,
          code,
          display_name,
          module_name,
          is_active,
          is_verified,
        } = response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          module_name: module_name?.id || module_name,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true); 
      } catch (error) {
        message.error(error.response.data.message);
      }
    }
  };
  const fetchModuleNames = async () => {
    try {
      // Fetch module names from the API
      const response = await listModuleNameService();
      setModuleName(response.data);
    } catch (error) {
      console.error("Error fetching module names:", error);
      message.error("Failed to fetch module names");
    }
  };
  useEffect(() => {
    if (open) {
      getPermissionData();
      fetchModuleNames();
    } else {
      form.resetFields();
      setDisplayNameSynced(true); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updatePermissionByIdService(id, values);
        if (response.status === 200 && response.success) {
          message.success("Permission successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createPermissionService(values);
        if (response.status === 201 && response.success) {
          message.success("Permission successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true); 
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>
        
        <Col span={12}>
          <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Code"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item name="module_name" label="Module Name" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {moduleName?.map((module) => (
                <Option key={module} value={module}>
                  {module}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PermissionForm;
