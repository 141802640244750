import React, { useState } from "react";
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Modal, Table, message } from "antd";
import "../../OTCPDD/style/style.css";

const ChequeAndNEFT = () => {
  const [collapsed1, setCollapsed1] = useState(false);

  const [collapsed2, setCollapsed2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  
  const formatCurrency = (value) => {
    return `₹ ${value.toLocaleString('en-IN')}`;
  };

  const disbursementDetailsColumn = [
    {
      title: "Sr. No",
      dataIndex: "document_type",
      key: "document_type",
    },
    {
      title: "Date of Disbursement",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Disbursement Month",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Type of Disbursement",
      dataIndex: "received_location",
      key: "received_location",
    },
    {
      title: "Gross Disbursement (₹)",
      dataIndex: "status",
      key: "status",
    },
  ];

  
  const transactionDetailsColumn = [
    {
      title: "Sr. No",
      dataIndex: "document_type",
      key: "document_type",
    },
    {
      title: "Mode",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Type",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Instrument No.",
      dataIndex: "received_location",
      key: "received_location",
    },
    {
      title: "Amount (₹)",
      dataIndex: "status",
      key: "status",
      render: (text) => formatCurrency(text),
    },
    {
      title: "Beneficiary Name",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Instrument Date",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Remark",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Document",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action",
      dataIndex: "status",
      key: "status",
    },
  ];
  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };
  return (
    <div className="tab_main_container">
      <div>
        <div className="heading_details_container"
          style={{ borderBottom: !collapsed1 && "none" }}>
          <div className="heading_text"
            style={{ borderBottom: collapsed1 && "none" }}>
            <div>Disbursement Details</div>
            <div>
              <div
                onClick={toggleCollapse1}
              >
                {collapsed1 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>

          <div className="otc_pdd_table_container">
            {!collapsed1 && (

              <Table
                // dataSource={PreFinancial}
                columns={disbursementDetailsColumn}
                pagination={false}
              />

            )}
          </div>
        </div>
      </div>

      <div>
        <div className="heading_details_container"
          style={{ borderBottom: !collapsed2 && "none" }}>
          <div className="heading_text"
            style={{ borderBottom: collapsed2 && "none" }}>
            <div>Transaction Details</div>
            <div>
              <div
                onClick={toggleCollapse2}
              >
                {collapsed2 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          <div className="otc_pdd_table_container">
            {!collapsed2 && (

              <Table
                // dataSource={PostFinancial}
                columns={transactionDetailsColumn}
                pagination={false}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        title="Alert"
        open={openModal}
        onOk={() => {
          message.success("Document deleted successfully");
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okText="Ok"
        cancelText="Cancel"
        centered
        width={500}
      >
        <p>Are you sure you want to delete this document?</p>
      </Modal>
    </div>
  );
};

export default ChequeAndNEFT;
