import React, { useEffect, useState } from 'react';
import "../styles/televerification.css"
import { EllipsisOutlined } from '@ant-design/icons';
import { SlCallOut, SlCallIn } from "react-icons/sl";
import { IoCallOutline } from "react-icons/io5";
import { Badge, Drawer, message } from 'antd';
import ReactAudioPlayer from 'react-audio-player';
import { getTeleverificationDetailsByIdService } from '../services/televerificationDetailsService';
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading"
import { Empty } from 'antd';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import "../../../../commonCss/commonStyle.css";
import Group from "../assets/Group.png"
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import TeleVerificationCallDetailsForm from './TeleVerificationCallDetailsForm';
import Header from '../../../../layout/views/Header';

const additionalHeaders = [
  "Key Parameter's",
  "Tele Verification",
];
const TeleVerificationCallDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [televerificationData, setTeleverificationData] = useState();
  const [selectedPerson, setSelectedPerson] = useState(null);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // setSelectedCustomer(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getTeleverificationDetailsByIdService(loan_id);
        setTeleverificationData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);
  return (
    <div>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title="Call"
              onClose={onClose}
              name={`${selectedPerson?.customer_name}`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <TeleVerificationCallDetailsForm
            refreshData={toggleRefreshTableData}
            customer_id={selectedCustomer}
            open={open}
            closeForm={onClose}
          />

        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : televerificationData && televerificationData.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">{additionalHeaders[0]}
                  </th>
                  {televerificationData?.results.map((person, index) => (
                    <th className="tab_form_top_heading" style={{ borderBottom: "1px solid #e4e7ec" }}>
                      <span style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            <img src={Group} alt="group" style={{ marginLeft: "5px" }} />

                          </span>
                        </div>
                        <div
                          className="tab_form_sub_edit"
                          onClick={() => {
                            setSelectedCustomer(person?.customer_id);
                            setSelectedPerson(person)
                            showDrawer();
                          }}
                          style={{ background: "#FFF", width: "50px" }}
                        >
                          <IoCallOutline style={{ fontSize: "14px" }} />
                          <span style={{ color: "" }}>Call</span>
                        </div>
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>

                <tr >
                  <td className='tab_form_left_heading' >
                    {additionalHeaders[1]}
                  </td>
                  {
                    televerificationData?.results?.map((person, index) => {
                      return (
                        <td style={{ verticalAlign: 'top' }}>
                          <div>
                            {person?.audio_calls?.length > 0 ? (
                              person?.audio_calls?.map((media, index) => (
                                <div className='televerification_card' key={index}>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div className='televerification_response_box'>
                                      <div className='televerification_response'>
                                        <Badge status="success" text="Completed" style={{ color: "#52C41A" }} />
                                      </div>
                                    </div>
                                    <div className='more_icon'>
                                      <EllipsisOutlined className='ellipsisoutlined_icon' />
                                    </div>
                                  </div>
                                  <div className='televerification_number'>
                                    <div className='number_first'>
                                      <div><SlCallOut /></div>
                                      <div>{media.call_from}</div>
                                    </div>
                                    <div className='number_second'>
                                      <div><SlCallIn /></div>
                                      <div>{media.call_to}</div>
                                    </div>
                                  </div>
                                  <div className='audio_package'>
                                    <ReactAudioPlayer src={media.recording} controls />
                                  </div>
                                  {/* <Divider style={{ margin: "15px 0px 0px 0px" }} />
                                  <div className='details_of_card'>
                                    <span>Details</span>
                                    <DownOutlined />
                                  </div> */}
                                </div>
                              ))
                            ) : (
                              <div className='no_media_details'>
                                -
                              </div>
                            )}
                          </div>
                        </td>

                      )
                    })
                  }

                </tr>

              </tbody>
            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeleVerificationCallDetails;