import { Col, DatePicker, Form, Input, Row, Select } from "antd";

const { Option } = Select;

const HDFCInsuredCustomerForm = ({
  form,
  formData,
  formValue,
  yupSync,
  index,
  filterOption,
  occupationType,
  handleInput,
  city,
  states,
  pincode,
  insurance_id,
  handleNomineeChange,
  personalData,
  nominee,
  handleDateChange,
  handleInputChange,
  relation,
  isUnder18,
  count,
  customerId,
  handleSubmit,
  matchedNominee,
  matchedCustomer
}) => {
  return (
    <>
      <Form
        form={form}
        // onValuesChange={handleFormValuesChange}
        layout="vertical"
        initialValues={{...formValue}}
        onFinish={(values) =>
          handleSubmit(values, matchedCustomer, matchedNominee)
        }
      >
        <div className="drawer-insurance-space">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={`insured_customer_name_${index}`}
                label="Insured Customer Name"
                initialValue={formData[`insured_customer_name_${index}`]}
                required
              >
                <Input
                  // value={`${matchedCustomer?.first_name || ""} ${matchedCustomer?.middle_name || ""} ${matchedCustomer?.last_name || ""}`.trim()}
                  value={customerId}
                  placeholder="Enter insured customer name"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`date_of_birth_${index}`}
                label="Date Of Birth"
                required
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  placeholder="Select date"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name={`gender_${index}`} label="Gender" required>
                <Select
                  placeholder="Please select"
                  style={{ width: "100%" }}
                  disabled
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name={`occupation_${index}`}
                label="Occupation"
                rules={[
                  {
                    required: true,
                    message: "Occupation is required",
                  },
                ]}
              >
                <Select
                  placeholder="Please Select Occupation"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear
                >
                  {occupationType?.map((locations, index) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`nationality_${index}`}
                label="Nationality"
                rules={[
                  {
                    required: true,
                    message: "Nationality is required",
                  },
                ]}
              >
                <Input
                  placeholder="Enter nationality"
                  // value={`${matchedCustomer?.nationality || ""}`}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`contact_number_${index}`}
                label="Contact Number"
              >
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="+91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name={`mobile_${index}`} noStyle required>
                    <Input
                      style={{ width: "80%" }}
                      placeholder="Enter contact number"
                      // value={`${matchedCustomer?.mobile_number || ""}`}
                      disabled
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item name={`email_${index}`} label="Email ID" required>
                <Input
                  placeholder="Enter email"
                  // value={`${matchedCustomer?.email || ""}`}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`applicant_type_${index}`}
                label="Applicant Type"
                required
              >
                <Input
                  placeholder="Enter applicant type"
                  // value={`${matchedCustomer?.customer_type?.display_name || ""
                  //   }`}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={12}>
              <Form.Item
                name={`address_line_1_${index}`}
                label="Address Line 1"
                rules={[
                  {
                    required: true,
                    message: "Address Line 1 is required",
                  },
                ]}
                required
              >
                <Input
                  placeholder="Please Enter Address Line 1"
                  // value={`${matchedCustomer?.address_line_1 || ""}`}
                  onChange={(e) => handleInput(e, "address_line_1")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={`address_line_2_${index}`}
                label="Address Line 2"
                rules={[
                  {
                    required: true,
                    message: "Address Line 2 is required",
                  },
                ]}
                required
              >
                <Input
                  placeholder="Please Enter Address Line 2"
                  // value={`${matchedCustomer?.address_line_2 || ""}`} onChange={(e) => handleInput(e, "address_line_2")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={`city_${index}`}
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select City"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  required
                  allowClear
                >
                  {city?.map((locations, index) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`state_${index}`}
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select State"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear
                >
                  {states?.map((states, index) => (
                    <Option key={states.id} value={states.id}>
                      {states.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`pincode_${index}`}
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select Pincode"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {pincode?.map((pincodes, index) => (
                    <Option key={pincodes.id} value={pincodes.id}>
                      {pincodes.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        {/* <div className="second_heading_details_container">Nominee Details</div> */}
        <div className="drawer-insurance-space">
            <Row>
              <Col span={8}>
                <Form.Item
                  name={`select_nominee_${index}`}
                  label="Select Nominee"
                >
                  <Select
                    placeholder="Please select"
                    showSearch
                    optionFilterProp="children"
                    onChange={handleNomineeChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {personalData
                      ?.filter((person) => person.id !== customerId) // Filter to ensure customerId exists
                      .map(
                        (person, index) =>
                          person.customer_type?.name === "Co-Applicant" && (
                            <Option key={person.id} value={person.id}>
                              {`${person.first_name} ${person.middle_name} ${person?.last_name}`}
                            </Option>
                          )
                      )}
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name={`nominee_insured_customer_name_${index}`}
                label="Insured Customer Name"
                rules={nominee === "other" ? [ {
                  required: true,
                  message: "Insured Customer Name is required",
                }] : []}
                required
              >
                <Input
                  placeholder="Enter customer name"
                  disabled={nominee !== "other"}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`nominee_date_of_birth_${index}`}
                label="Date Of Birth"
                rules={[
                  {
                    required: true,
                    message: "Date of Birth is required",
                  },
                ]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  placeholder="Select Date"
                  onChange={handleDateChange}
                  disabled={nominee !== "other"}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`nominee_gender_${index}`}
                label="Gender"
                rules={nominee === "other" ? [yupSync(index)] : []}
                required
              >
                <Select
                  placeholder="Search To Select"
                  style={{ width: "100%" }}
                  disabled={nominee !== "other"}
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name={`nominee_contact_number_${index}`}
                label="Contact Number"
              >
                <Input.Group compact>
                  <Form.Item noStyle required>
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="+91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name={`nominee_mobile_${index}`}
                    rules={nominee === "other" ? [yupSync(index)] : []}
                    noStyle
                    required
                  >
                    <Input
                      style={{ width: "80%" }}
                      // placeholder={
                      //   nominee === "other"
                      //     ? "Please Enter Your Mobile Number"
                      //     : matchedNominee?.mobile_number
                      // }
                      placeholder="Enter contact number"
                      // value={
                      //   nominee === "other"
                      //     ? contactNumber
                      //     : matchedNominee?.mobile_number
                      // }

                      onChange={handleInputChange}
                      disabled={nominee !== "other"}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`relation_with_assured_person_${index}`}
                rules={[
                  {
                    required: true,
                    message: "Relationship is required",
                  },
                ]}
                label="Relation"
                required
              >
                <Select
                  placeholder="Please Select Relation"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relation?.map((relations) => (
                    <Option key={relations.id} value={relations.id}>
                      {relations.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={`nominee_address_line_1_${index}`}
                label="Address Line 1"
                rules={[
                  {
                    required: true,
                    message: "Address Line 1 is required",
                  },
                ]}
                required
              >
                <Input
                  placeholder="Please Enter Address"
                  onChange={(e) => handleInput(e, "nominee_address_line_1")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={`nominee_address_line_2_${index}`}
                label="Address Line 2"
                rules={[
                  {
                    required: true,
                    message: "Address Line 2 is required",
                  },
                ]}
              >
                <Input
                  placeholder="Please Enter Address"
                  onChange={(e) => handleInput(e, "nominee_address_line_2")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={`nominee_city_${index}`}
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select City"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {city?.map((locations) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`nominee_state_${index}`}
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select State"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {states?.map((states) => (
                    <Option key={states.id} value={states.id}>
                      {states.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={`nominee_pincode_${index}`}
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select Pincode"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {pincode?.map((pincodes) => (
                    <Option key={pincodes.id} value={pincodes.id}>
                      {pincodes.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {isUnder18 && (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name={`appointee_name_${index}`}
                  label="Appointee Name"
                  rules={[
                    {
                      required: true,
                      message: "Appointee Name is required",
                    },
                  ]}
                  required
                >
                  <Input placeholder="Please Enter Appointee Nominee Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={`appointee_date_of_birth_${index}`}
                  label="Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Date Of Birth is required",
                    },
                  ]}
                 
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={`appointee_gender_${index}`}
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required",
                    },
                  ]}
                  required
                >
                  <Select
                    placeholder="Search To Select"
                    style={{ width: "100%" }}
                    allowClear
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Contact Number" required>
                  <Input.Group compact>
                    <Form.Item noStyle>
                      <Input
                        style={{
                          width: "20%",
                          textAlign: "center",
                          background: "#f1f1f1",
                        }}
                        defaultValue="+91"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      name={`appointee_contact_number_${index}`}
                      rules={[
                        {
                          required: true,
                          message: "Contact Number is required",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        style={{ width: "80%" }}
                        placeholder="Please Enter Contact Number"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={`apointee_relationship_with_life_to_be_assured_${index}`}
                  label="Relationship with Nominee"
                  rules={[
                    {
                      required: true,
                      message: "Relationship is required",
                    },
                  ]}
                  required
                >
                  <Select
                    placeholder="Please Select Relation"
                    showSearch
                    optionFilterProp="children"
                    onChange={handleNomineeChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {relation?.map((relations) => (
                      <Option key={relations.id} value={relations.id}>
                        {relations.display_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      </Form>
    </>
  );
};

export default HDFCInsuredCustomerForm;
