import React, { useState, useEffect } from "react";
import { Modal, Drawer, message } from "antd";
import {
  getInsuranceDetailsByIdService,
  deleteHDFCErgo,
} from "../services/loanInsuranceService";
import { DeleteOutlined } from "@ant-design/icons";
import HDFCInsuranceForm from "./HDFCInsuranceForm";
import Header from "../../../../layout/views/Header";
import pdf_image from "../assets/pdf.png";
import EditButton from "../../../../../utils/editButton/EditButton";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const HDFCInsuranceview = ({
  insurance_id,
  loan_id,
  toggleRefreshInsurance,
}) => {
  const [openModal, setOpenModal] = useState(false);
  // const [refreshData, setRefreshData] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [, setSelectedUser] = useState(null);
  const [data, setData] = useState([]);
  const [customer_id, setCustomer_id] = useState("");
  const [imageData, setImagedata] = useState(null);
  const [refreshDelete, setRefreshTableDelete] = useState(false);

  const toggleRefreshDelete = () => {
    setRefreshTableDelete((prev) => !prev);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelectedUser(null);
    setSelectedUserId(null);
    setOpen(false);
  };

  // const closeDrawer = () => {
  // setOpen(false);
  // setSelectedUser(null);
  // setSelectedUserId(null);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInsuranceDetailsByIdService(insurance_id);
        setData(data.data);
        setImagedata(data?.data?.document);
        setCustomer_id(
          data?.data?.insured_customer_detail?.customer_detail?.id
        );
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance_id, refreshDelete]);

  const deleteUserById = async () => {
    try {
      const response = await deleteHDFCErgo(insurance_id);
      if (response.status === 200 && response.success === true) {
        message.success("HDFC Ergo deleted successfully");
        setOpenModal(false);
        toggleRefreshInsurance();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  // Merge function

  const mergeData = (insuredCustomers, nominees) => {
    return insuredCustomers?.map((customer) => {
      const matchingNominees = nominees.filter(
        (nominee) => nominee.insurance_customer_detail === customer.id
      );
      return {
        ...customer,
        nominees: matchingNominees[0],
      };
    });
  };
  // Merged result
  const mergedResult = mergeData(
    data?.insured_customer_detail,
    data?.nominee_detail
  );
  return (
    <div style={{ marginLeft: "7px" }}>
      <div className="icici_view_header">
        <div className="insurance_view_heading">Details Of Coverage</div>
        <div className="insurance_button_container">
          {/* <div className="insurance-download-button">
 <VerticalAlignBottomOutlined />
 Download Form
 </div> */}
          <div
            className="insurance-edit-button"
            onClick={() => {
              showDrawer();
            }}
          >
            <EditButton />
          </div>
          <Drawer
            title={
              <Header
                title={insurance_id ? "Edit" : "Add"}
                onClose={onClose}
                name="HDFC Insurance"
              />
            }
            width={1000}
            onClose={onClose}
            open={open}
            styles={{
              body: { paddingBottom: 80, paddingLeft: 0, paddingRight: 0 },
            }}
            closable={false}
          >
            <HDFCInsuranceForm
              toggleRefreshInsurance={toggleRefreshInsurance}
              id={selectedUserId}
              open={open}
              images={imageData}
              refreshDelete={toggleRefreshDelete}
              // closeDrawer={closeDrawer}
              closeForm={onClose}
              onCancel={onClose}
              setId={setSelectedUserId}
              insurance_id={insurance_id}
              loan_id={loan_id}
              customer_id={customer_id}
            />
          </Drawer>
          <div
            className="insurance-delete-button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <DeleteOutlined />
          </div>
        </div>
      </div>

      <div className="loan_and_insurance_container">
        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Loan Amount</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.loan_amount || "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">PAN</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.pan_no || "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Tenure Of Loan</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.tenure || "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">
              Premium Amount (Including GST)
            </div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.total_premium_incl_gst || "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Policy Term(In Months)</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.policy_term || "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Property Type</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.proprty_type?.name || "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Sum Assured</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.sum_assured || "-"}
            </div>
          </div>
        </div>
      </div>

      {mergedResult?.map((insuredCustomer, index) => {
        return (
          <>
            <div className="icici_view_header">
              <div className="insurance_view_heading">
                Life To Be Assured {index + 1}
              </div>
            </div>
            <div className="loan_and_insurance_container">
              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Name</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.first_name || "-"}{" "}
                    {insuredCustomer?.middle_name || "-"}{" "}
                    {insuredCustomer?.last_name || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Nationality</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nationality || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Address Line 2</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.address_line_2 || "-"}
                  </div>
                </div>
                {/* <div className="basic_card-content">
 <div className="loan_inusurance_label">Height</div>
 <div className="data">{data?.insured_customer_detail?.[0]?.height || "-"}</div>
 </div> */}
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Date Of Birth</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.date_of_birth || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Contact Number</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.mobile || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">City</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.city?.name || "-"}
                  </div>
                </div>
                {/* <div className="basic_card-content">
 <div className="loan_inusurance_label">Weight</div>
 <div className="data">{data?.insured_customer_detail?.[0]?.weight || "-"}</div>
 </div> */}
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Gender</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.gender || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Email</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.email || "-"}
                  </div>
                </div>

                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">State</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.state?.name || "-"}
                  </div>
                </div>
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Occupation</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.ocupation?.name || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Address Line 1</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.address_line_1 || "-"}
                  </div>
                </div>

                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Pincode</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.pincode?.name || "-"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="second_heading_details_container">
                Nominee Details
              </div>
              <div className="loan_and_insurance_container">
                <div className="laon_insurance_Basic_card">
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">Name</div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.name || "-"}
                    </div>
                  </div>
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">
                      Nominee's Contact Number
                    </div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.mobile || "-"}
                    </div>
                  </div>

                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">State</div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.state?.name || "-"}
                    </div>
                  </div>
                  {insuredCustomer?.nominees?.appointee && (
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Appointee's Gender
                      </div>
                      <div className="loan_inusurance_data">
                        {insuredCustomer?.nominees?.appointee?.gender || "-"}
                      </div>
                    </div>
                  )}
                </div>

                <div className="laon_insurance_Basic_card">
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">
                      Relation With The Life To Be Assured
                    </div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.relation?.name || "-"}
                    </div>
                  </div>
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">Address Line 1</div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.address_line_1 || "-"}
                    </div>
                  </div>
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">Pincode</div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.pincode?.name || "-"}
                    </div>
                  </div>
                  {data?.nominee_detail?.[0]?.appointee && (
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Appointee's Contact Number
                      </div>
                      <div className="loan_inusurance_data">
                        {insuredCustomer?.nominees?.appointee?.mobile || "-"}
                      </div>
                    </div>
                  )}
                </div>

                <div className="laon_insurance_Basic_card">
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">
                      Nominee's Date Of Birth
                    </div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.date_of_birth || "-"}
                    </div>
                  </div>
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">Address Line 2</div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.address_line_2 || "-"}
                    </div>
                  </div>

                  {insuredCustomer?.nominees?.appointee && (
                    <>
                      <div className="loan_insurance_basic_card_content">
                        <div className="loan_inusurance_label">
                          Appointee Name
                        </div>
                        <div className="loan_inusurance_data">
                          {insuredCustomer?.nominees?.appointee
                            ?.appointee_name || "-"}
                        </div>
                      </div>
                      <div className="loan_insurance_basic_card_content">
                        <div className="loan_inusurance_label">
                          Relationship With Nominee
                        </div>
                        <div className="loan_inusurance_data">
                          {insuredCustomer?.nominees?.appointee?.relation
                            ?.name || "-"}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="laon_insurance_Basic_card">
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">
                      Nominee's Gender
                    </div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.gender || "-"}
                    </div>
                  </div>
                  <div className="loan_insurance_basic_card_content">
                    <div className="loan_inusurance_label">City</div>
                    <div className="loan_inusurance_data">
                      {insuredCustomer?.nominees?.city?.name || "-"}
                    </div>
                  </div>

                  {insuredCustomer?.nominees?.appointee && (
                    <>
                      <div className="loan_insurance_basic_card_content">
                        <div className="loan_inusurance_label">
                          Appointee's Date Of Birth
                        </div>
                        <div className="loan_inusurance_data">
                          {insuredCustomer?.nominees?.appointee
                            ?.date_of_birth || "-"}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      })}

      <div
        className="image-gallery"
        style={{ marginBottom: "10px", marginLeft: "0px" }}
      >
        {imageData?.map((picture, index) => (
          <div key={index} className="image-container">
            {picture.file_type === "application/pdf" ? (
              <a
                href={picture.document}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={pdf_image}
                  width="100px"
                  height="100px"
                  style={{ border: "none", cursor: "pointer" }}
                  alt="PDF Preview"
                />
              </a>
            ) : (
              <RViewerJS
                options={{
                  url: (image) => image.getAttribute("data-original"),
                }}
              >
                <img
                  src={picture.thumb_document}
                  data-original={picture.document}
                  alt="Preview"
                  style={{ width: "100px", height: "100px", cursor: "pointer" }}
                />
              </RViewerJS>
            )}
          </div>
        ))}
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteUserById(); // Pass the current deleteValue
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this insurance?`}</p>
      </Modal>
    </div>
  );
};

export default HDFCInsuranceview;
