import React, { useState, useEffect } from 'react';
import "../../partDisbursedInitiate/styles/partDisbursed.css";
import "../styles/constructionTimeline.css";
import { message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { getPartDisbursalDetailsByIdService } from "../services/constructionTimelineDetailsService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import dummy_img from "../assets/dummyImage.png";

const ConstructionLine = () => {
  const [partDisbursalDetails, setPartDisbursalDetails] = useState([]);
  const { encrypted_loan_id } = useParams();
  const [currentIndexes, setCurrentIndexes] = useState([]);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPartDisbursalDetailsByIdService(loan_id);
        setPartDisbursalDetails(response.data);
        setCurrentIndexes(response.data.map(() => 0)); // Initialize currentIndexes for each item

      } catch (error) {

        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id]);

  const handlePrevClick = (index) => {
    setCurrentIndexes((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = (newIndexes[index] - 1 + partDisbursalDetails[index].part_disbursment_documents.length) % partDisbursalDetails[index].part_disbursment_documents.length;
      return newIndexes;
    });
  };

  const handleNextClick = (index) => {
    setCurrentIndexes((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = (newIndexes[index] + 1) % partDisbursalDetails[index].part_disbursment_documents.length;
      return newIndexes;
    });
  };

  return (
    <div className="part_disbursed_table">
      <div className="part_disbursed_heading">
        <div className="part_disbursed_data">
          <h3>Construction Timeline</h3>
        </div>
      </div>

      <div className='construction_timeline_container'>
        <div className='timeline_container'>
          {partDisbursalDetails.map((data, index) => (
            <div key={index} className='construction_container'>
              <div className='construction_timeline_card'>
                <div className='step_circle'></div>
                <div className='line'></div>
              </div>

              <div className='construction_timeline_card1'>
                <div className="carousel">
                  <img
                    src={data.part_disbursment_documents[currentIndexes[index]]?.file_path || dummy_img}
                    alt={`Slide ${currentIndexes[index]}`}
                    className="carousel-image"
                    onClick={() => handleNextClick(index)}
                  />
                  <div className="carousel-controls">
                    <button onClick={() => handlePrevClick(index)}><LeftOutlined /></button>
                    <button onClick={() => handleNextClick(index)}><RightOutlined /></button>
                  </div>
                </div>
              </div>

              <div className='construction_timeline_card2'>
                <div className='construction_timeline'>
                  <div className='disbursal_card'>
                    <div className="label">Construction Type</div>
                    <div className="data">{data.construction_type || 'N/A'}</div>
                  </div>
                  <div className='disbursal_card'>
                    <div className="label">Builder Name</div>
                    <div className="data">{data.property_detail?.property_builder_name || 'N/A'}</div>
                  </div>
                  <div className='disbursal_card'>
                    <div className="label">Construction Description</div>
                    <div className="data">{data.construction_description}</div>
                  </div>
                </div>

                <div className='construction_timeline' style={{ marginBottom: "2rem" }}>
                  <div className='disbursal_card'>
                    <div className="label">Raised By</div>
                    <div className="data">{data.created_by?.name}</div>
                  </div>
                  <div className='disbursal_card'>
                    <div className="label">Current Status</div>
                    <div className="data">{data.current_status?.name}</div>
                  </div>

                  <div className='disbursal_card'>
                    <div className="label">Completion % Request (As per user)</div>
                    <div className="data">{data.construction_completion_as_per_user}%</div>
                  </div>

                </div>

                <div className='construction_timeline' style={{ marginBottom: "2rem" }}>

                  <div className='disbursal_card'>
                    <div className="label">Completion % Recommended (As per user)</div>
                    <div className="data">{data.construction_completion_recommended_as_per_user}%</div>
                  </div>
                  <div className='disbursal_card'>
                    <div className="label">Disb Amount Request</div>
                    <div className="data">
                      ₹ {data.disbursment_amount_requested.toLocaleString('en-IN')}
                    </div>

                  </div>

                  <div className='disbursal_card'>
                    <div className="label"></div>
                    <div className="data"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ConstructionLine;
