
import React, { useState, useEffect } from "react";
import { Button, Drawer, Space, Table, message } from "antd";
import "../../loanandInsurance/styles/loanInsurance.css";
import "../../IncomeEvaluation/styles/incomeEvalution.css";
import {
  getBankDetailsById,
  getIncomeEvaluationById,
} from "../services/incomeEvaluationService";
import { useParams } from "react-router-dom";
import CreditTransactionDetails from "./CreditTransactionDetails";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import "../../IncomeEvaluation/styles/incomeEvalution.css"
import Header from "../../../../layout/views/Header";
import { LTVCalculationsByLoanId, createCam } from "../services/incomeDetailsServices"
import {
  PlusCircleOutlined,
  EditOutlined
} from "@ant-design/icons";
import { loanbycustomerId } from "../services/incomeDetailsServices";
import EditButton from "../../../../../utils/editButton/EditButton";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import LTVCalculation from "./LTVCalculation";
import IncomeCalculation from "./IncomeCalculation";
import ExistingLoanDetailsForm from "../../../customerTabDetails/existingLoansDetails/views/ExistingLoanDetailsForm";
import { formConfig, viewConfig } from "../../../../../config/schemeConfig";

const IncomeEvalution = ({ loanDetails }) => {
  const { encrypted_loan_id } = useParams();
  const [incomeEvaluations, setIncomeEvaluations] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [, setTotal] = useState({});
  const [open, setOpen] = useState(false);
  const [nonCashIncomeData, setNonCashIncomeData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState();
  const [schemeDisplayName, setSchemeDisplayName] = useState('');
  const [getLTVCalculationsrefresh, setLTVCalculationsRefresh] = useState(false);
  const [selectedExistingLoan, setSelectedExistingLoan] = useState(null);
  // const [openModal, setOpenModal] = useState(false);
  const [openExistingLoan, setOpenExistingLoan] = useState(false);
  const [handleCAMButton, setHandleCAMButton] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    // Check if loanDetails and scheme are defined, then extract display_name
    if (loanDetails && loanDetails?.scheme && loanDetails?.scheme?.display_name) {
      setSchemeDisplayName(loanDetails?.scheme?.code);
    }

  }, [loanDetails, loan_id]);

  const showDrawerExistingLoan = () => {
    setOpenExistingLoan(true);
  };

  const onCloseExistingLoan = () => {
    setOpenExistingLoan(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedPerson(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const toggleLTVCalculationsData = () => {
    setLTVCalculationsRefresh((prev) => !prev);
  };


  useEffect(() => {
    // Fetch data when the component mounts
    const fetchCustomerData = async () => {
      try {
        if (loan_id) {
          const response2 = await loanbycustomerId(loan_id);
          if (response2.status === 200 && response2.success) {
            setNonCashIncomeData(response2.data);
          }
        }
      } catch (error) {
        // Handle error in API call
        message.error("Error fetching customer data:", error);
      }
    };

    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshTableData]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getIncomeEvaluationById(loan_id);
        const loans = response.data.loans || [];
        const existingLoanDetails = loans
          .map((loan) => {
            const loanDetails = loan.existing_loan_details.map((detail) => ({
              ...detail,
              key: detail.id,
              customer_name: loan.customer_name,
              loan_purpose_name: detail.loan_purpose?.name || "",
              loan_status_name: detail.loan_status?.name || "",
              financial_institution_name:
                detail.financial_institutions?.name || "",

              consider_obligation: detail.consider_obligation ? "Yes" : "No",
              eligibility_calculation_under_repayment_program: detail.eligibility_calculation_under_repayment_program ? "Yes" : "No",
            }));
            return loanDetails;
          })
          .flat();
        const totalValues = response.data.total || {};
        setTotal(totalValues);
        setIncomeEvaluations(existingLoanDetails);
      } catch (error) {
        message.error("Error fetching existing loan data");
      }
    };

    fetchData();
  }, [loan_id, refreshTableData]);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await getBankDetailsById(loan_id);
        const bankData = response.data.results || [];

        // Function to flatten bank objects
        const flattenBanks = (customersData) => {
          const flattenedBanks = [];
          customersData.forEach((customer) => {
            customer.customer_bank_details.forEach((bank) => {
              flattenedBanks.push({ customer_id: customer.customer_id, ...bank });
            });
          });
          return flattenedBanks;
        };

        const flattenedBanksArray = flattenBanks(bankData);
        const tableBankArray = flattenedBanksArray.map((account, index) => {
          return {
            key: index,
            customer_id: account.customer_id,
            bank_detail_id: account.id,
            Parameters: `${account?.customer_detail?.first_name} ${account?.customer_detail?.middle_name} ${account?.customer_detail?.last_name}`,
            Bank_Name: account?.bank?.display_name,
            Account_Number: account?.account_number,
            Bank_Branch: account?.bank_branch?.display_name,
            Account_Type: account?.account_type,
          };
        });

        setBankDetails(tableBankArray);
      } catch (error) {
        message.error("Error fetching Bank Details");
      }
    };
    fetchBankDetails();
  }, [loan_id]);



  const handleCreateCam = async () => {
    try {
      const payload = {
        loan_detail: loan_id,
      };
      await createCam(payload);
      await LTVCalculationsByLoanId(loan_id);
      toggleLTVCalculationsData();
      message.success("CAM created successfully");
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  // const deleteRental = async () => {
  //   try {
  //     const response = await deleteRentalDetailsByIdLoanId(selectedExistingLoan);

  //     if (response.status === 200 && response.success === true) {
  //       message.success("Rental deleted successfully");
  //       setOpenModal(false);
  //       toggleRefreshTableData();
  //       setSelectedExistingLoan(null);
  //     }
  //   } catch (error) {
  //     setOpenModal(false);
  //     message.error(error.response.data.message);
  //   }
  // };

  const renderEditButton = (showDrawer, id,) => (
    <div className="tab_form_sub_edit" onClick={() => {
      setSelectedPerson(id);
      showDrawer();
    }}>
      <EditButton />
    </div>
  );



  const columnsFour = [
    {
      title: "Customer Name/Parameter",
      dataIndex: "Parameters",
      width: 230,
    },
    {
      title: "Bank Name",
      dataIndex: "Bank_Name",
    },
    {
      title: "Account Number",
      dataIndex: "Account_Number",
    },
    {
      title: "Bank Branch",
      dataIndex: "Bank_Branch",
    },
    {
      title: "Account Type",
      dataIndex: "Account_Type",
    },
  ];

  const col = [
    {
      title: "Customer Name/Parameter",
      dataIndex: "customer_detail",
      render: (customerDetail) =>
        customerDetail?.first_name +
        " " +
        customerDetail?.middle_name +
        " " +
        customerDetail?.last_name,
      width: 220,
      fixed: "left",
      className: "custom-header",
    },

    {
      title: "Loan Purpose",
      dataIndex: "loan_purpose_name",
      width: 150,
    },
    {
      title: "Sanctioned Amount",
      dataIndex: "sanctioned_amount",
      width: 120,
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstanding_amount",
      width: 120,
    },
    {
      title: "Sanctioned Date",
      dataIndex: "sanctioned_date",
      width: 120,
    },

    {
      title: "Maturity Date",
      dataIndex: "maturity_date",
      width: 120,
    },
    {
      title: "EMI",
      dataIndex: "emi_amount",
      width: 120,
    },
    {
      title: "ROI",
      dataIndex: "roi",
      width: 120,
    },
    {
      title: "Loan Status",
      dataIndex: "loan_status_name",
      width: 120,
    },
    {
      title: "Obligation Considered",
      dataIndex: "consider_obligation",
      width: 120,
    },
    {
      title: "Source of Liability",
      dataIndex: "source_of_liability",
      width: 120,
    },
    {
      title: "Financial Institute",
      dataIndex: "financial_institution_name",
      width: 120,
    },
    {
      title: "Eligibility Calculation Repayment Program",
      dataIndex: "eligibility_calculation_under_repayment_program",
      width: 120,
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => {
                setSelectedExistingLoan(record.id);
                setSelectedCustomerId(record.customer_id)
                showDrawerExistingLoan();
              }}
            />
            {/* <DeleteOutlined
            onClick={() => {
              setSelectedExistingLoan(record?.id);
              setOpenModal(true);
            }}
          /> */}
          </Space>
        );
      },
    },


  ];



  return (
    <>
      <div className="tab_main_container">

        {/* <Drawer
          title={
            <Header
              title={"Add"}
              onClose={onClose}
              name={
                "Income details"
              }
            />
          }
          width={1000}
          onClose={onClose}
          open={open}
          closable={false}
        >
          {schemeDisplayName === 'MSRROL23' && (
            <IncomeNonCashForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData}
            />
          )}
          {schemeDisplayName === 'MSOAOA70' && (
            <IncomeCashForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData}
            />
          )}
          {schemeDisplayName === 'MSEOE670' && (
            <IncomeMultiplierForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData} />
          )}
          {schemeDisplayName === 'MSOTIE80' && (
            <NormalIncomeAndIncomeEstimateForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData} />
          )} */}
        {/* {schemeDisplayName === 'MSTWVT31' && (
            <LowLtv
              closeForm={onClose}
              open={open} />
          )} */}

        {/* {schemeDisplayName === 'MSGAGN70' && (
            <Banking
              closeForm={onClose}
              open={open} />
          )} */}
        {/* {schemeDisplayName === 'Gross receipts 360' && (
            <GrossReciepts
              closeForm={onClose}
              open={open} />
          )} */}
        {/* {schemeDisplayName === 'MSLQIE21' && (
            <EmiEuiliserForm
              closeForm={onClose}
              open={open}
              refreshData={toggleRefreshTableData} />
          )}
          {schemeDisplayName === 'MSR6CP80' && (
            <PureRentalForm
              closeForm={onClose}
              open={open}
              refreshData={toggleRefreshTableData}
            />

          )}

        </Drawer> */}

        <Drawer
          title={
            <Header
              title={"Add"}
              onClose={onClose}
              name={"Income details"}
            />
          }
          width={1000}
          onClose={onClose}
          open={open}
          closable={false}
        >
          {schemeDisplayName && (
            (() => {
              const formConfigMatch = formConfig.find(form => form.schemeDisplayName === schemeDisplayName);
              if (formConfigMatch) {
                const FormComponent = formConfigMatch.component;

                // Conditionally set props based on the form type
                const commonProps = {
                  closeForm: onClose,
                  open: open,
                  refreshData: toggleRefreshTableData,
                };

                if (
                  schemeDisplayName === 'MSRROL23' ||
                  schemeDisplayName === 'MSOAOA70' ||
                  schemeDisplayName === 'MSEOE670' ||
                  schemeDisplayName === 'MSOTIE80'
                ) {
                  return (
                    <FormComponent
                      {...commonProps}
                      id={selectedPerson} // Add the `id` prop for other forms
                    />
                  );
                }


                return (
                  <FormComponent
                    {...commonProps} // Spread common props for Emi and Rental forms
                  />
                );
              }
              return null;
            })()
          )}
        </Drawer>

        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', gap: "10px" }}>
          {schemeDisplayName !== 'MSGAGN70' && schemeDisplayName !== 'MSTWVT31' && (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={showDrawer}
            >
              ADD
            </Button>
          )}
          {handleCAMButton && (
            <Button type="primary"
              icon={<PlusCircleOutlined />}
              danger
              onClick={handleCreateCam}
            >
              CAM
            </Button>
          )}
        </div>
        {/* {nonCashIncomeData.length > 0 && (
          <>
            {nonCashIncomeData[0].scheme_name === "MSRROL23" ? (
              <IncomeNonCashView
                nonCashIncomeData={nonCashIncomeData}
                renderEditButton={renderEditButton}
                showDrawer={showDrawer}
              />
            ) : null}

            {nonCashIncomeData[0].scheme_name === "MSOAOA70" ? (
              <IncomeCashView
                nonCashIncomeData={nonCashIncomeData}
                renderEditButton={renderEditButton}
                showDrawer={showDrawer}
              />
            ) : null}

            {nonCashIncomeData[0].scheme_name === "MSEOE670" ? (
              <IncomeMultiplierView
                nonCashIncomeData={nonCashIncomeData}
                renderEditButton={renderEditButton}
                showDrawer={showDrawer}
              />
            ) : null}
            {nonCashIncomeData[0].scheme_name === "MSOTIE80" ? (
              <IncomeEstimateView
                nonCashIncomeData={nonCashIncomeData}
                renderEditButton={renderEditButton}
                showDrawer={showDrawer}
              />
            ) : null}

            {schemeDisplayName === 'MSR6CP80' ? (
              <PureRentalView
                refreshData={toggleRefreshTableData}
              />
            ) : null}
          </>
        )} */}

        {nonCashIncomeData.length > 0 && (
          <>
            {viewConfig?.map((config) => {
              if (nonCashIncomeData[0].scheme_name === config.scheme_name) {
                const ViewComponent = config.component;
                let viewProps = {};

                // Manually pass props using if statements
                if (
                  config.scheme_name === 'MSRROL23' ||
                  config.scheme_name === 'MSOAOA70' ||
                  config.scheme_name === 'MSEOE670' ||
                  config.scheme_name === 'MSOTIE80'
                ) {
                  // Common props for most components
                  viewProps.nonCashIncomeData = nonCashIncomeData;
                  viewProps.renderEditButton = renderEditButton;
                  viewProps.showDrawer = showDrawer;
                }

                if (config.scheme_name === 'MSR6CP80') {
                  // Props for PureRentalView
                  viewProps.refreshData = toggleRefreshTableData;
                }

                return <ViewComponent key={config.scheme_name} {...viewProps} />;
              }
              return null;
            })}
          </>
        )}




        <div className="heading_details_container">

          <div className="heading_text">
            Existing Loan
            <div className="" style={{ display: "flex" }} ></div>
          </div>


          <div style={{ overflowX: "auto" }}>
            <Table
              className="insurance_table"
              // apiPath={`/api/v1/customer/existing_loan_detail/underwritting_existing_loan/${id}`}
              columns={col}
              dataSource={incomeEvaluations}
              pagination={false}
            />
          </div>
        </div>

        <div style={{ padding: "0px 10px" }}>
          {schemeDisplayName !== "MSR6CP80" && (
            <IncomeCalculation
              schemeDisplayName={schemeDisplayName}
              refreshTableData={refreshTableData}
              setHandleCAMButton={setHandleCAMButton}
            />
          )}
          <LTVCalculation
            refreshTableData={refreshTableData}
            refreshLTVData={getLTVCalculationsrefresh}
          />
        </div>

        <div className="heading_details_container">


          <div className="heading_text">
            Bank Account Details </div>
          <div className="bank_account_details_table">
            <Table
              columns={columnsFour}
              style={{ width: "100%" }}
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    <CreditTransactionDetails customer_id={record.customer_id} bank_id={record.bank_detail_id} />
                  </div>
                ),
                rowExpandable: (record) => record.name !== "Not Expandable",
              }}
              dataSource={bankDetails}
              pagination={false}
            />
          </div>
        </div>
      </div >
      {/* <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRental();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal> */}
      <Drawer
        title={
          <Header
            title={"Edit"}
            onClose={onCloseExistingLoan}
            name={
              "Existing Loan Form"
            }
          />
        }
        width={1000}
        onClose={onCloseExistingLoan}
        open={openExistingLoan}
        closable={false}
      >

        <ExistingLoanDetailsForm
          closeForm={onCloseExistingLoan}
          open={openExistingLoan}
          id={selectedExistingLoan}
          customer_id={selectedCustomerId}
          refreshData={toggleRefreshTableData}
        />

      </Drawer>
    </>
  );
};

export default IncomeEvalution;
