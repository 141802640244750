const ErrorMessage = (status, message) => {
  const defaultMessages = {
    400: "Bad Request: The server could not understand the request.",
    401: "Unauthorized: Authentication is required and has failed or has not been provided.",
    402: "Payment Required: The request is missing a required payment.",
    403: "Forbidden: The server understood the request, but refuses to authorize it.",
    404: "Not Found: The requested resource could not be found on the server.",
    405: "Method Not Allowed: The method specified in the request is not allowed for the resource.",
    406: "Not Acceptable: The server cannot produce a response matching the list of acceptable values defined in the request.",
    407: "Proxy Authentication Required: The client must first authenticate itself with the proxy.",
    408: "Request Timeout: The server timed out waiting for the request.",
    409: "Conflict: The request could not be completed due to a conflict with the current state of the target resource.",
    410: "Gone: The requested resource is no longer available on the server and no forwarding address is known.",
    411: "Length Required: The server refuses to accept the request without a defined Content-Length.",
    412: "Precondition Failed: One or more conditions in the request header fields evaluated to false.",
    413: "Payload Too Large: The server refuses to process the request because the payload is too large.",
    414: "URI Too Long: The server refuses to process the request because the URI is too long.",
    415: "Unsupported Media Type: The server refuses to process the request because the payload format is unsupported.",
    416: "Range Not Satisfiable: The server cannot provide the requested range.",
    417: "Expectation Failed: The server cannot meet the requirements of the Expect request-header field.",
    418: "I'm a teapot: This code is defined in RFC 2324, Hyper Text Coffee Pot Control Protocol.",
    421: "Misdirected Request: The request was directed at a server that is not able to produce a response.",
    422: "Unprocessable Entity: The server understands the content type of the request entity but was unable to process the contained instructions.",
    423: "Locked: The source or destination resource of a method is locked.",
    424: "Failed Dependency: The method could not be performed on the resource because the requested action depended on another action and that action failed.",
    425: "Too Early: The server is unwilling to risk processing a request that might be replayed.",
    426: "Upgrade Required: The server refuses to perform the request using the current protocol.",
    428: "Precondition Required: The origin server requires the request to be conditional.",
    429: "Too Many Requests: The user has sent too many requests in a given amount of time.",
    431: "Request Header Fields Too Large: The server is unwilling to process the request because its header fields are too large.",
    451: "Unavailable For Legal Reasons: The server is denying access to the resource as a consequence of a legal demand.",
    500: "Internal Server Error: The server has encountered a situation it doesn't know how to handle.",
    501: "Not Implemented: The server either does not recognize the request method, or it lacks the ability to fulfill the request.",
    502: "Bad Gateway: The server was acting as a gateway or proxy and received an invalid response from the upstream server.",
    503: "Service Unavailable: The server is not ready to handle the request. Common causes include a temporary overloading of the server, maintenance being performed on the server, or the server being down for maintenance.",
    504: "Gateway Timeout: The server was acting as a gateway or proxy and did not receive a timely response from the upstream server or some other auxiliary server.",
    505: "HTTP Version Not Supported: The server does not support the HTTP protocol version that was used in the request.",
    506: "Variant Also Negotiates: Transparent content negotiation for the request results in a circular reference.",
    507: "Insufficient Storage: The server is unable to store the representation needed to complete the request.",
    508: "Loop Detected: The server detected an infinite loop while processing a request.",
    510: "Not Extended: Further extensions to the request are required for the server to fulfill it.",
    511: "Network Authentication Required: The client needs to authenticate to gain network access.",
  };

  if (!status) {
    return `An unexpected error has occured!!`;
  } else {
    return (
      message ||
      defaultMessages[status] ||
      `An error occurred with status code: ${status}`
    );
  }
};

export default ErrorMessage;
