import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const listUserService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get('/api/v1/user/get-user-list');
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const initiateFinalApproval = (loan_id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/loan/initiate-stage/final-approval-initiate/${loan_id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }