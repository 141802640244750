import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  HistoryOutlined,
  UpOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Space, Table, message, } from "antd";
import {
  deleteOTCPDDByIdService,

  getOTCPDDByLoanIdService,
} from "../services/otcpddServices";
import { useParams } from "react-router-dom";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";

import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import AddOTCPDD from "./AddOTCPDD";
import Header from "../../../../../layout/views/Header";
import Remark from "./Remark";
import '../../OTCPDD/style/style.css'
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { FilePdfOutlined } from '@ant-design/icons';

const OTCPDD = () => {
  const [collapsed1, setCollapsed1] = useState(false);
  const [collapsed2, setCollapsed2] = useState(false);
  const [collapsed3, setCollapsed3] = useState(false);
  const [collapsed4, setCollapsed4] = useState(false);
  const [collapsed5, setCollapsed5] = useState(false);
  const [openModal, setOpenModal] = useState(false);


  // const [selectedOtcPddData, setSelectedOtcPddData] = useState();
  const [otc, setOtc] = useState([]);
  const [pdd, setPdd] = useState([]);
  const [preDisbursals, setPreDisbursals] = useState([]);
  const [deferred, setDeferred] = useState([]);
  const [waived, setWaived] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [showAddOtcForm, setShowAddOtcForm] = useState(false);
  const [selectedOtcData, setSelectedOtcData] = useState();

  const { encrypted_loan_id } = useParams();
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const onCloseOtcForm = () => {
    setShowAddOtcForm(false);
    setSelectedOtcData(null);
  };

  const showOtcFormDrawer = () => {
    setShowAddOtcForm(true);
  };


  useEffect(() => {
    const fetchCheques = async () => {
      try {
        const response = await getOTCPDDByLoanIdService(loan_id);
        setOtc(response?.data.OTC || []);
        setPdd(response?.data.PDD || []);
        setPreDisbursals(response?.data["Pre Disbursals"] || []);
        setDeferred(response?.data.Deferred || []);
        setWaived(response?.data.Waived || []);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchCheques();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshTableData]);

  const deleteOtc = async (remark) => {
    try {
      const response = await deleteOTCPDDByIdService(
        selectedOtcData?.id,
        remark
      );

      if ((response.status = 200 && response.success === true)) {
        message.success("OTC PDD deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedOtcData(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };
  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      // width: "10px",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Type",
      dataIndex: "otc_pdd_type",
      // width: "100px",
      key: "otc_pdd_type",
      render: (text) => text || " - ",
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      // width: "150px",
      render: (text) => text || " - ",
    },
    {
      title: "Description",
      dataIndex: "description",
      // width: "100px",
      key: "description",
      render: (text) => text || " - ",
    },
    {
      title: "Received Location",
      dataIndex: "received_at",
      key: "received_at",
      // width: "150px",
      render: (text) => text || " - ",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      // width: "100px",
      render: (text) => text || " - ",
    },
    {
      title: "Date",
      dataIndex: "due_date",
      key: "due_date",
      // width: "150px",
      render: (text) => text || " - ",
    },

    {
      title: "Documents",
      dataIndex: "docs_otc_pdc",
      // width: "150px",
      key: "docs_otc_pdc",
      render: (documents) => {
        return documents && documents.length ? (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "9px" }}>
            {documents.map((doc, index) => {
              return (
                <div key={index} style={{ width: "50px", height: "60px", overflow: "hidden" }}>
                  {doc.file_type === 'image/jpeg' ? (
                    <RViewerJS
                      options={{
                        url: (image) => image.getAttribute("data-original"), // This will fetch the full-size image for preview
                      }}
                    >
                      <img
                        src={doc.thumb_document_path} // Thumbnail image
                        data-original={doc.document_path}
                        alt={`Document ${index + 1}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
                      />
                    </RViewerJS>

                  ) : doc.file_type === 'application/pdf' ? (
                    <a href={doc.document_path} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', cursor: "pointer" }}>
                      <FilePdfOutlined style={{ fontSize: '48px', color: '#ff0000' }} />
                    </a>
                  ) : (
                    "Unsupported file type"

                  )}
                </div>
              );
            })}
          </div>
        ) : (
          " - "
        );
      },
    }
    ,

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              style={{ color: "#4880FF" }}
              onClick={() => {
                setSelectedOtcData(record);
                showOtcFormDrawer();
              }}
            />
            <DeleteOutlined
              style={{ color: "#E5233DB2" }}
              onClick={() => {
                setOpenModal(true);
                setSelectedOtcData(record);
              }}
            />
            <HistoryOutlined style={{ color: "#FF7C03" }} />
          </Space>
        );
      },
    },
  ];

  return (
    <div className="tab_main_container">
      <Drawer
        title={
          <Header
            title={selectedOtcData ? "Edit" : "Add"}
            onClose={onCloseOtcForm}
            name="OTC/PDD"
          />
        }
        width={900}
        onClose={onCloseOtcForm}
        open={showAddOtcForm}
        closable={false}
      >
        <AddOTCPDD
          id={selectedOtcData?.id}
          refreshData={toggleRefreshTableData}
          open={showAddOtcForm}
          closeForm={onCloseOtcForm}
        />
      </Drawer>
      <Remark
        refreshTableData={refreshTableData}
        selectedOtcData={selectedOtcData}
        visible={openModal}
        onConfirm={deleteOtc}
        onCancel={() => setOpenModal(false)}
      />
      {/* <div style={{margin:'10px'}}>
     <h4 >Application Number</h4>
        <div className='application_serch' style={{marginTop:"15px"}}>

          <Select
            showSearch
            allowClear
            placeholder="Enter Loan Number"
            onSearch={debouncedHandleSearch}
            onSelect={handleSelect}
            loading={loading}
            filterOption={false}
            notFoundContent={loading ? <Spin size="small" /> : null}
            disabled={id}
          >
            {loanList.map((loan) => (
              <Select.Option key={loan.id} value={loan.loan_account_number}>
                {loan?.loan_account_number}
              </Select.Option>
            ))}
          </Select>


        </div>
      </div> */}

      <div>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px 10px 0px 10px" }}>
          <Button
             icon={<PlusCircleOutlined />}
            onClick={showOtcFormDrawer}
            style={{
              background: "hsla(204, 57%, 36%, 1)",
              color: "white",
            }}
          >
            Add OTC/PDD
          </Button>
        </div>
        <div className="heading_details_container" 
           style={{ borderBottom: !collapsed1 && "none" }}>
          <div className="heading_text"
            style={{ borderBottom: collapsed1 && "none" }}
          >
            <div>OTC</div>
            <div>
              <div
                onClick={toggleCollapse1}
              >
                {collapsed1 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>

          {!collapsed1 && (
            <div className="otc_pdd_table_container">
              <Table dataSource={otc} columns={columns} pagination={false} />
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="heading_details_container" 
        style={{ borderBottom: !collapsed2 && "none" }}>
          <div className="heading_text"
            style={{ borderBottom: collapsed2 && "none" }}>
            <div>PDD</div>
            <div>
              <div
                onClick={toggleCollapse2}
              >
                {collapsed2 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {!collapsed2 && (
            <div className="otc_pdd_table_container">
              <Table dataSource={pdd} columns={columns} pagination={false} />
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="heading_details_container" 
        style={{ borderBottom: !collapsed3 && "none" }}>
          <div className="heading_text"
            style={{ borderBottom: collapsed3 && "none" }}>
            <div> Waived</div>
            <div>
              <div
                onClick={toggleCollapse3}
              >
                {collapsed3 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {!collapsed3 && (
            <div className="otc_pdd_table_container">
              <Table dataSource={waived} columns={columns} pagination={false} />
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="heading_details_container" 
        style={{ borderBottom: !collapsed4 && "none" }}>
          <div className="heading_text"
            style={{ borderBottom: collapsed4 && "none" }}>
            <div>Deferred</div>
            <div>
              <div
                onClick={toggleCollapse4}
              >
                {collapsed4 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {!collapsed4 && (
            <div className="otc_pdd_table_container">
              <Table dataSource={deferred} columns={columns} pagination={false} />
            </div>
          )}
        </div>
      </div>

      <div>
      <div className="heading_details_container" 
      style={{ borderBottom: !collapsed5 && "none" }}>
        <div className="heading_text"
         style={{ borderBottom: collapsed5 && "none" }}>
          <div>Pre Disbursals</div>
          <div>
            <div
              onClick={toggleCollapse5}
            >
              {collapsed5 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {!collapsed5 && (
          <div className="otc_pdd_table_container">
            <Table
              dataSource={preDisbursals}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default OTCPDD;
