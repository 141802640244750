import React, { useEffect, useState } from "react";
import "../../documentDetails/styles/documentstyle.css"; 
import { Drawer, message } from "antd";
import { useParams } from "react-router-dom";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import Header from "../../../../layout/views/Header";
import ProvidedDocument from "./ProvidedDocument";
import {
  getDocumentDetailsByIdService,
  getRemarkDataByIdService,
} from "../services/documentDetailsServices";
import "../../commonCSS/commonTechnicalValuation.css";
import PropertyPictureForm from "./PropertyPictureForm";
import ObservationPropertyForm from "./ObservationPropertyForm";
import Loading from "../../../../../utils/loading/Loading";
import { Empty } from "antd";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import EditButton from "../../../../../utils/editButton/EditButton";

const DocumentDetails = ({ stage_id }) => {
  const { encrypted_loan_id } = useParams();
  const [openDocumentProvidedForm, setOpenDocumentProvidedForm] =
    useState(false);
  const [openObservationProperty, setOpenObservationPropertyForm] =
    useState(false);
  const [openPropertyPictureForm, setOpenPropertyPicturesForm] =
    useState(false);
  const [documentDetail, setDocumentDetail] = useState();
  const [propertyData, setPropertyData] = useState();
  const [images, setImages] = useState([]);
  const [SelectedPropertyPicture, setSelectedPropertyPicture] = useState([]);

  const [showDocumentDetails, setShowDocumentDetails] = useState(true);
  const [showObservationDetails, setShowObservationDetails] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [showPropertyPicturesDetails, setshowPropertyPicturesDetails] =
    useState(true);

  const [technicalId, SetTechnicalId] = useState(true);
  const [loading, setLoading] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDocumentProvidedForm = () => {
    setOpenDocumentProvidedForm(true);
  };

  const showObservationPropertyForm = () => {
    setOpenObservationPropertyForm(true);
  };

  const showPropertyPicturesForm = () => {
    setOpenPropertyPicturesForm(true);
    setSelectedPropertyPicture(null);
  };

  const onClose = () => {
    setOpenDocumentProvidedForm(false);
    setOpenObservationPropertyForm(false);
    setOpenPropertyPicturesForm(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDocumentDetailsByIdService(stage_id);

        // setDocumentDetail(data.data);
        // setImages(data.technical_upload_serialized_data);
        SetTechnicalId(data.data.allotment_letter.technical_detail);
        setDocumentDetail(data.data || {});
        setImages(data.data.technical_upload_serialized_data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchPropertyData = async () => {
      try {
        setLoading(true);
        const data = await getRemarkDataByIdService(stage_id);
        setPropertyData(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
    fetchPropertyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshData, stage_id]);

  return (
    <>
      {/* {loading ? (
        <Loading loading={loading} />
      ) : ( */}
      <div className="main_basic_container">
        <div className="valuation_container valuation_content">
          <div className="valuation_details">
            <div className="valuation_details_blue">
              Documents Provided by VHFCL
            </div>
            <div>
              <div style={{ display: "flex", justifyItems: "center" }}>
                <div
                  // className="underwriting_form_sub_edit"
                  onClick={showDocumentProvidedForm}
                  style={{ display: "flex", alignItems: "center"}}
                >
                  {/* <img src={edit} alt="Pencil Icon" />
                  <span>Edit</span> */}
                  <div> 
                  <EditButton/>
                  </div>
                </div>

                {showDocumentDetails ? (
                  <DownOutlined
                    onClick={() => setShowDocumentDetails(!showDocumentDetails)}
                    style={{ margin: "16px" }}
                  />
                ) : (
                  <UpOutlined
                    onClick={() => setShowDocumentDetails(!showDocumentDetails)}
                    style={{ margin: "16px" }}
                  />
                )}
              </div>

              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Documents Provided by VHFCL"
                  />
                }
                width={970}
                onClose={onClose}
                visible={openDocumentProvidedForm}
                styles={{ body: { paddingBottom: 80 } }}
                closable={false}
                refreshData={toggleRefreshData}
              >
                <ProvidedDocument
                  open={openDocumentProvidedForm}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  stage_id={stage_id}
                />
              </Drawer>
            </div>
          </div>

          {showDocumentDetails && (
            <>
              {loading ? (
                <Loading loading={loading} />
              ) : (
                <div className="data_table fade-in">
                  {documentDetail ? (
                    <>
                      <div className="document_details_heading">
                        <div className="document_details_data_td_heading">
                          Document Description
                        </div>
                        <div className="document_details_data_td_heading_first">
                          Collection Status
                        </div>
                        <div className="document_details_data_td_heading_first">
                          Document/approval Number
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div className='document_details_data_td' style={{ width: "450px", padding: '5px' }}>Sale Deed/Release deed</div></div>
                        <div className=' document_details_data_text_td'>{documentDetail?.sale_deed?.collection_status ? 'Yes' : 'No'}</div>
                        <div className='document_details_data_text_td'>{documentDetail?.sale_deed?.collection_status ? (documentDetail?.sale_deed?.document_number) : 'NA'}</div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Sale agreement{" "}
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.sale_agreement?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.sale_agreement?.collection_status
                            ? documentDetail?.sale_agreement?.document_number ||
                              "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div className='document_details_data_td' style={{ width: "450px", padding: '5px' }}>Statutory plan and number</div></div>
                        <div className=' document_details_data_text_td'>{documentDetail?.statutory_plan_number?.collection_status ? 'Yes' : 'No'}</div>
                        <div className='document_details_data_text_td'>{documentDetail?.statutory_plan_number?.collection_status ? (documentDetail?.statutory_plan_number?.document_number) : 'NA'}</div>

                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Construction agreement/Gift Deed
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.construction_agreement
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.construction_agreement
                            ?.collection_status
                            ? documentDetail?.construction_agreement
                                ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            RERA approval Number if applicable
                          </div>
                        </div>

                        <div className=' document_details_data_text_td'>{documentDetail?.rera_approval?.collection_status ? 'Yes' : 'No'}</div>
                        <div className='document_details_data_text_td'>{documentDetail?.rera_approval?.collection_status ? (documentDetail?.rera_approval?.document_number) : 'NA'}</div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Allotment letter
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.allotment_letter?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.allotment_letter?.collection_status
                            ? documentDetail?.allotment_letter
                                ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            7/12 extract/Patta/Land record
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.land_record?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.land_record?.collection_status
                            ? documentDetail?.land_record?.document_number ||
                              "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Conversion Certificate
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.conversion_certificate
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.conversion_certificate
                            ?.collection_status
                            ? documentDetail?.conversion_certificate
                                ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Deviation approval mail from Vastu authority for
                            lack of documents
                          </div>
                        </div>

                        <div className=" document_details_data_text_td">
                          {documentDetail?.deviation_approval?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.deviation_approval?.collection_status
                            ? documentDetail?.deviation_approval
                                ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Property Tax receipts
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.property_tax_receipt
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.property_tax_receipt
                            ?.collection_status
                            ? documentDetail?.property_tax_receipt
                                ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Estimate Provided and its value
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.estimate_provided?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.property_tax_receipt
                            ?.collection_status
                            ? documentDetail?.estimate_provided
                                ?.document_number 
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            Other documents
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.other_document?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.other_document?.collection_status
                            ? documentDetail?.other_document?.document_number ||
                              "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div style={{ backgroundColor: "#FAFAFA" }}>
                          <div
                            className="document_details_data_td"
                            style={{ width: "450px", padding: "5px" }}
                          >
                            General Power of attorney
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.general_power_of_attorney
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.general_power_of_attorney
                            ?.collection_status
                            ? documentDetail?.general_power_of_attorney
                                ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="no_data_found_message">
                      <Empty />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <div className="valuation_container valuation_content fade-in">
          <div className="valuation_details">
            <div className="valuation_details_blue">
              Property Specific Remarks & Observation
            </div>
            <div>
              <div style={{ display: "flex", justifyItems: "center" }}>
                <div
                  // className="underwriting_form_sub_edit"
                  onClick={showObservationPropertyForm}
                  style={{ display: "flex", alignItems: "center"}}
                >
                  {/* <img src={edit} alt="Pencil Icon" />
                  <span>Edit</span> */}
               
                  <EditButton/> 
                </div>
                {showObservationDetails ? (
                  <DownOutlined
                    onClick={() =>
                      setShowObservationDetails(!showObservationDetails)
                    }
                    style={{ margin: "16px" }}
                  />
                ) : (
                  <UpOutlined
                    onClick={() =>
                      setShowObservationDetails(!showObservationDetails)
                    }
                    style={{ margin: "16px" }}
                  />
                )}
              </div>

              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Property Specific Remarks & Observation"
                  />
                }
                width={970}
                onClose={onClose}
                visible={openObservationProperty}
                styles={{ body: { paddingBottom: 80 } }}
                closable={false}
                refreshData={toggleRefreshData}
              >
                <ObservationPropertyForm
                  open={openObservationProperty}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  stage_id={stage_id}
                />
              </Drawer>
            </div>
          </div>

          {showObservationDetails && (
            <div className="data_table fade-in">
              {propertyData ? (
                <>
                  <div className="document_details_second_heading">
                    <div className="document_details_second_data">
                      Overall Status <span style={{ color: "red" }}>*</span>
                    </div>
                    <div
                      className="document_details_second_data"
                      style={{
                        color:
                          propertyData?.technical_detail?.[0]
                            ?.overall_status === "Positive"
                            ? "#52C41A"
                            : "red",
                      }}
                    >
                      {propertyData?.technical_detail?.[0]?.overall_status}
                    </div>

                    <div className="document_details_second_data">
                      Remarks /Observation ( please enter NA if none)
                      <div
                        className="document_details_second_data"
                        style={{ color: "#505050", marginLeft: "0px" }}
                      >
                        {propertyData?.technical_detail?.[0]?.remark}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="no_data_found_message">
                  <Empty />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="valuation_container valuation_content fade-in">
          <div className="valuation_details">
            <div className="valuation_details_blue">Property Pictures</div>
            <div>
              <div style={{ display: "flex", justifyItems: "center" }}>
                <div
                  // className="underwriting_form_sub_edit"
                  onClick={showPropertyPicturesForm}
                  style={{ display: "flex", alignItems: "center"}}
                >
                  {/* <img src={edit} alt="Pencil Icon" />
                  <span>Edit</span> */}
                <div> 
                  <EditButton/>
                </div>
                </div>
                {showPropertyPicturesDetails ? (
                  <DownOutlined
                    onClick={() =>
                      setshowPropertyPicturesDetails(
                        !showPropertyPicturesDetails
                      )
                    }
                    style={{ margin: "16px" }}
                  />
                ) : (
                  <UpOutlined
                    onClick={() =>
                      setshowPropertyPicturesDetails(
                        !showPropertyPicturesDetails
                      )
                    }
                    style={{ margin: "16px" }}
                  />
                )}
              </div>

              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Property Pictures"
                  />
                }
                width={970}
                onClose={onClose}
                open={openPropertyPictureForm}
                styles={{ body: { paddingBottom: 80 } }}
                closable={false}
                refreshData={toggleRefreshData}
              >
                <PropertyPictureForm
                  open={openPropertyPictureForm}
                  setSelectedPropertyPicture={setSelectedPropertyPicture}
                  SelectedPropertyPicture={SelectedPropertyPicture}
                  images={images}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  technical_detail={technicalId}
                />
              </Drawer>
            </div>
          </div>
          {showPropertyPicturesDetails && (
            <div className="valuation_Proerty_images_container fade-in">
              {images.length > 0 ? (
                <div className="document_images">
                  {images?.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        flex: "0 0 11.1%",
                        boxSizing: "border-box",
                        padding: "5px",
                      }}
                    >
                      <RViewerJS
                        options={{
                          url: (img) => img.getAttribute("data-original"),
                        }}
                      >
                        <img
                          src={image.thumb_file_path}
                          data-original={image.file_path}
                          alt="Preview"
                          style={{
                            width: "100%",
                            height: "70%",
                            cursor: "pointer",
                          }}
                        />
                      </RViewerJS>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        {image.name}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no_data_found_message">
                  <Empty />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default DocumentDetails;
