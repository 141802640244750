import React, { useEffect, useState } from 'react'
import { Drawer, Empty, message } from "antd";
import "../styles/vehicle.css"
import "../../../customerTabDetails/style/customerStyle.css"
import Header from '../../../../layout/views/Header';
import VehicleDetailsForm from './VehicleDetailsForm';
import { getVehicleDetailsByIdService } from '../services/vahicleService';
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';
import "../../../../commonCss/commonStyle.css";
import EditButton from '../../../../../utils/editButton/EditButton';
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const VehicleDetails = () => {
    const { encrypted_loan_id } = useParams();
    const [vehicleData, setvehicleData] = useState(null);
    const [open, setOpen] = useState(false);
    const [createdById, setCreatedById] = useState("");
    const [refreshData, setRefreshData] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [loading, setLoading] = useState(true);
    const { user_data } = useSelector((state) => state.user);

    let loan_id = null;
    try {
      loan_id = decrypt(encrypted_loan_id); 
    } catch (error) { 
      message.error("Invalid Loan ID");
    }
  

    const toggleRefreshData = () => {
        setRefreshData((prev) => !prev);
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                const data = await getVehicleDetailsByIdService(loan_id);
                setvehicleData(data.data);
                setCreatedById(data.data?.loan_detail);
                setUpdateId(data.data?.id);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchData();
    }, [loan_id, refreshData]);


    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const fieldNameMap = {
        "engine_number": "Engine Number",
        "chassis_number": "Chassis Number",
        "registration_number": "Registration Number",
        "resgistration_date": "Registration Date",
        "state": "State",
        "registration_transport_office": "Registration Transport Office",
        "current_owner_name": "Current Owner Name",
        "registration_expiry_date": "Registration Expiry Date",
        "vehicle_life": "Vehicle Life",
        "vehicle_body": "Vehicle Body",
        "vehicle_age": "Vehicle Age",
        "manufacturing_year": "Manufacturing year",
        "road_tax_type": "Road Tax Type",
        "number_of_previous_owners": "Number Of Previous Owners",
        "vehicle_type": "Vehicle Type",
        "vehicle_manufacturer": "Vehicle Manufacturer",
        "vehicle_model": "Vehicle Model",
        "vehicle_variant": "Vehicle Variant",
    };

    return (
        <>
            <div className='main_vehicle_details_container'>
                <div className='fade-in'>
                    {loading ? (
                        <Loading loading={loading} />
                    ) : vehicleData ? (
                        <>
                            <div className='vehicle_edit_container'>
                                {vehicleData?.created_at &&
                                    vehicleData?.modified_at &&
                                    isModifiedAfterCreated(
                                        vehicleData?.created_at,
                                        vehicleData?.modified_at
                                    ) && (
                                        <HistoryTable
                                            id={(vehicleData?.id)}
                                            bench_id={"71d5f2542f1d3f6b"}
                                            fieldNameMap={fieldNameMap}
                                        />
                                    )}
                                <ConditionalRender
                                    userPermissions={user_data?.permissions}
                                    requiredPermissions={["MPOLDE7"]}
                                >
                                    <div style={{padding:'1px'}} className="tab_form_sub_edit edit_button "
                                        onClick={showDrawer}>
                                      <EditButton/>
                                    </div>
                                </ConditionalRender>
                            </div>
                            <div className='vehicle_deatails_container'>
                                <Drawer
                                    title={
                                        <Header
                                            title="Edit"
                                            onClose={onClose}
                                            name="Vehicle Details"
                                        />
                                    }
                                    width={970}
                                    onClose={onClose}
                                    open={open}
                                    styles={{ body: { paddingBottom: 80 } }}
                                    closable={false}
                                    refreshData={toggleRefreshData}
                                >
                                    <VehicleDetailsForm
                                        open={open}
                                        closeForm={onClose}
                                        id={createdById}
                                        updateId={updateId}
                                        refreshData={toggleRefreshData}
                                    />
                                </Drawer>


                                <div className='Vehicle_card' >
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Engine Number</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.engine_number}</div> : <div className='data'>N/A</div>}
                                    </div>

                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Chassis Number</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.chassis_number}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Registration Number</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {(vehicleData.registration_number).toLocaleString()}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Registration Date</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {(vehicleData.resgistration_date).toLocaleString()}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">State</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.state && vehicleData.state.name}</div> : <div className='data'>N/A</div>}
                                    </div>

                                </div>

                                <div className='Vehicle_card' >
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Registration Transport Office</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData?.registration_transport_office?.name}</div> : <div className='data'>N/A</div>}
                                    </div>

                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Current Owner Name</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.current_owner_name}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Registration Expiry Date</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {vehicleData.registration_expiry_date}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Life</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {vehicleData.vehicle_life}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Body</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.vehicle_body && vehicleData.vehicle_body.name}</div> : <div className='data'>N/A</div>}
                                    </div>

                                </div>
                                <div className='Vehicle_card' >
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Age</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.vehicle_age}</div> : <div className='data'>N/A</div>}
                                    </div>

                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Manufacturing year</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.manufacturing_year}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Road Tax Type</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {vehicleData.road_tax_type}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Number Of Previous Owners</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.number_of_previous_owners}</div> : <div className='data'>N/A</div>}
                                    </div>

                                </div>
                                <div className='Vehicle_card' >
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Type</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.vehicle_type && vehicleData.vehicle_type.name}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    {/* {vehicleData ? <div className='data'>{vehicleData.vehicle_type.name}</div> : <div className='data'>N/A</div>} */}


                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Manufacturer</div>
                                        {vehicleData ? <div className='vehicle_details_data'>{vehicleData.vehicle_manufacturer && vehicleData.vehicle_manufacturer.name}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Model</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {vehicleData.vehicle_model && vehicleData.vehicle_model.name}</div> : <div className='data'>N/A</div>}
                                    </div>
                                    <div className="vehicle_card_content">
                                        <div className="vehicle_details_label">Vehicle Variant</div>
                                        {vehicleData ? <div className='vehicle_details_data'> {vehicleData.vehicle_variant && vehicleData.vehicle_variant.name}</div> : <div className='data'>N/A</div>}
                                    </div>



                                </div>


                            </div>
                        </>
                    ) :
                        (
                            <div className="no_data_found_message">
                                <Empty />
                            </div>
                        )}
                </div>
            </div>

        </>
    )
}

export default VehicleDetails;






















