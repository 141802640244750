import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import vastu_logo from "../assets/vastulogo.png";
import login_image from "../assets/login_image.png";
import "../styles/login.css";
import { forgetPasswordService } from "../services/loginService";

const ForgetPassword = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set the document title when the component mounts
    document.title = "Vastu LMS | Login";

    // Clean up by resetting the document title when the component unmounts
    return () => {
      document.title = "LMS"; // Set the default title or your desired default title
    };
  }, []);

  const onFinish = async (values) => {
    try {
      await forgetPasswordService(values);
      message.success("Password reset link sent successfully.");
      navigate("/");
    } catch (error) {
      if (!error.response.data.message) {
        message.error("Sorry, Please contact support..!");
      } else {
        message.error(error.response.data.message);
      }
    }
  };

  return (
    <div className="login_form_main_container">
      <div className="form_control_container">
        <div className="form_section">
          <Form
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <div className="form_content">
              <div className="header_section">
                <img src={vastu_logo} alt="Logo" />
                <div className="welcome_header_section">
                  <h2>Forgot Password!</h2>
                  <p className="welcome_back_heading">
                    Forgot Password? Please enter your Email.
                  </p>
                </div>
              </div>
              <div className="input_section">
                <div className="form_input_section">
                  <div className="input_wrapper">
                    <label>Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Email is required",
                        },
                        {
                          type: "email",
                          message: "Invalid email format",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          height: "38px",
                        }}
                        placeholder="Enter your email"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="login_singup_google">
                  <div className="form_action_section">
                    <Button
                      style={{
                        padding: "10px 18px",
                        height: "44px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                      classNames="sign_in_button"
                      type="primary"
                      htmlType="submit"
                      className="w-100 mb-2"
                    >
                      Send Reset Password Link
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div className="footer_section">
          <p>© 2022 Vastu Housing Finance Corporation ltd</p>
        </div>
      </div>

      <div className="image_control_container">
        <img
          className="right_side_heroImage_wrapper"
          src={login_image}
          alt="Login"
        />
      </div>
    </div>
  );
};

export default ForgetPassword;
