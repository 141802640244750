import React, { useEffect, useState } from "react";
import "../styles/propertyDescription.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from "../../layout/views/Header";
import PropertyDescriptionForm from "./PropertyDescriptionForm";
import { getPropertyDescriptionById } from "../services/propertyDescriptionService";
import Loading from "../../../utils/loading/Loading";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import EditButton from "../../../utils/editButton/EditButton";


const PropertyDescription = ({ loanId, stageId }) => {
  const [collapsed1, setCollapsed1] = useState(true);
  const [open, setOpen] = useState(false);
  const [propertyDescription, setPropertyDescription] = useState(null);
  const [createdById] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [loading, setLoading] = useState(true);

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPropertyDescriptionById(stageId);
        setPropertyDescription(response?.data);
        setUpdateId(response.data?.legal_detail_data?.id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div style={{ border: "1px solid #e4e7ec" }} className="">
      <Drawer
        title={
          <Header title="Edit" onClose={onClose} name="Property Description" />
        }
        width={970}
        onClose={onClose}
        open={open}
        closable={false}
      >
        {stageId && loanId && (
          <PropertyDescriptionForm
            loan_id={loanId}
            stageId={stageId}
            open={open}
            closeForm={onClose}
            id={createdById}
            updateId={updateId}
            refreshData={toggleRefreshData}
          />
        )}
      </Drawer>

      <div style={{ margin: "10px" }}>
        <div className="property_description_heading" style={{backgroundColor:"#e6f7ff"}}>
          <div className="property_description_data">Property Description</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              {renderEditButton()}
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
              onClick={toggleCollapse1}
            >
              {collapsed1 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed1 && (
          <div>
            {loading ? ( // Show loading spinner if data is loading
              <Loading />
            ) : (
              <>
                <div className="property_description_basic_card_container">
                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Transaction Type
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.transaction_type || "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Units
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data?.unit_type ||
                          "-"}
                      </div>
                    </div>
                  </div>

                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Property Owner
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.present_owner || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Property Address
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data?.address ||
                          "-"}
                      </div>
                    </div>
                  </div>

                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Area of Property
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.area_of_property || "-"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="property_description_heading_two">
                  <div className="property_description_data">
                    As per Documents
                  </div>
                </div>

                <div className="property_description_basic_card_container">
                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Address line 1
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.address1 ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        City
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.city || "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Country
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.country ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        On South Side
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.description_south || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Address line 2
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.address2 ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Taluka
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.taluka ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        On East Side
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.description_east || "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Date of Report
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data?.date_of_report
                          ? formatDate(propertyDescription.legal_detail_data.date_of_report)
                          : "-"
                        }

                      </div>
                    </div>
                  </div>

                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Landmark
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.landmark ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        District
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.district ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        On West Side
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.description_west || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="property_description_basic_card">
                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        Pincode
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.pincode ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        State
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_address_data?.state?.name ||
                          "-"}
                      </div>
                    </div>

                    <div className="property_description_basic_card-content">
                      <div className="property_description_basic_card_label">
                        On North Side
                      </div>
                      <div className="property_description_basic_card_data">
                        {propertyDescription?.legal_detail_data
                          ?.description_north || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyDescription;
