
import React, { useEffect, useState } from "react";
import "../styles/investmentDetails.css";
import "../../style/customerStyle.css";
import verify from "../assets/Group.png";
import add from "../assets/Add.png";
import { Drawer, Empty, Modal, message } from "antd";
import Header from "../../../../layout/views/Header";
import InvestmentDetailsForm from "./InvestmentDetailsForm";
import {
  getInvestmentDetailsByIdService,
  deleteInvestmentDetailsByIdService
} from "../services/investmentDetailsServices";
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading"
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";

const InvestmentDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [investmentData, setInvestmentData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState()
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
    setSelectedPerson(null);
    setSelectedRecord(null);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getInvestmentDetailsByIdService(loan_id);
        setInvestmentData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const renderEditButton = (showDrawer, id, person) => {

    return (
      <div className="tab_form_sub_edit" onClick={() => {
        showDrawer();
        setSelectedCustomer(id);
        setSelectedPerson(person)
      }}>
        <EditButton />
      </div>
    );
  };

  const Heading1 = [
    "Key Parameter's",
    "Investment Details",
    "Investment Type",
    "Invested Amount",
    "Invested Date",
    "Maturity Date",
  ];

  const fieldNameMap = {
    "investment_type": "Investment Type",
    "invested_amount": "Invested Amount",
    "invested_date": "Invested Date",
    "maturity_date": "Maturity Date",
  };

  const handleAddButtonClick = (person) => {
    setSelectedCustomerId(person.customerId);
    setSelectedPerson(person)
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return 'tab_form_main_data_two_entries';
      case 3:
        return 'tab_form_main_data_three_entries';
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return '';
    }
  };

  const deleteRecord = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteInvestmentDetailsByIdService(selectedRecord);
      if (response.status === 200 && response.success === true) {
        message.success("Investment details deleted successfully");
        toggleRefreshTableData();
        setSelectedRecord(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };
  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              name={selectedPerson ? `${selectedPerson?.customer_name}'s Investment Details` : `${selectedPerson?.customer_name}'s Investment Detail`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <InvestmentDetailsForm
            refreshData={toggleRefreshTableData}
            id={selectedCustomer}
            customer_id={selectedCustomerId}
            open={open}
            closeForm={onClose}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : investmentData.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">{Heading1[0]}
                  </th>
                  {investmentData?.results.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            <img src={verify} alt="group" style={{ marginLeft: "5px" }} />

                          </span>
                        </div>
                        <div
                          className="tab_form_sub_edit"
                          onClick={() => {
                            showDrawer();
                            handleAddButtonClick(person)
                          }}
                        >
                          <img src={add} alt="Add Button" />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {investmentData && [...Array(investmentData.max_count)].map((_, rowIndex) => (

                  Heading1.slice(1).map((header, colIndex) => (
                    <tr
                      className={
                        header === "Investment Details"
                          ? "investment-row"
                          : header === "PD Entries"
                            ? "investment-pd-row "
                            : ""
                      }
                    >
                      <td className="tab_form_left_heading">{header === "Investment Details" ? `Investment Details ${rowIndex + 1}` : header}</td>

                      {investmentData?.results.map((person, colIndex) => (
                        <td
                          className={`tab_form_main_data_table ${getTableColumnClass(investmentData.results.length)}`}
                        >
                          {person?.customer_investment_type && person?.customer_investment_type[rowIndex] ? (
                            <>
                              {header === "Investment Type"
                                ? person.customer_investment_type[rowIndex]?.investment_type?.name || '-'
                                : header === "Invested Amount"

                                  ? `₹ ${IndianNumberFormat(person.customer_investment_type[rowIndex]?.invested_amount) || '-'}`
                                  : header === "Invested Date"
                                    ? person.customer_investment_type[rowIndex]?.invested_date || '-'
                                    : header === "Maturity Date"
                                      ? person.customer_investment_type[rowIndex]?.maturity_date || '-'
                                      : ""}
                              {header === `Investment Details` && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <div
                                    onClick={() => {
                                      setSelectedRecord(person.customer_investment_type[rowIndex].id);
                                      setOpenModal(true);
                                    }}>

                                    <DeleteButton />
                                  </div>
                                  {person?.customer_investment_type[rowIndex]?.created_at &&
                                    person?.customer_investment_type[rowIndex]?.modified_at &&
                                    isModifiedAfterCreated(
                                      person?.customer_investment_type[rowIndex]?.created_at,
                                      person?.customer_investment_type[rowIndex]?.modified_at
                                    ) && (
                                      <HistoryTable
                                        id={(person?.customer_investment_type[rowIndex]?.id)}
                                        bench_id={"ddedc17cb8598036"}
                                        fieldNameMap={fieldNameMap}
                                      />
                                    )}
                                  <ConditionalRender
                                    userPermissions={user_data?.permissions}
                                    requiredPermissions={["MPITDE9"]}
                                  >
                                    {renderEditButton(showDrawer, person.customer_investment_type[rowIndex].id, person)}
                                  </ConditionalRender>

                                </div>
                              )}
                            </>
                          ) : (
                            header === "Investment Details" ? "" : <span>-</span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRecord();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default InvestmentDetails
