import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  getLocationListService,
  getUserDetailsServicebyId,
  updateUserLocationsDetailsServicebyId,
  updateUserZonesDetailsServicebyId,
} from "../services/userService";
import { getAllZoneService } from "../services/userService";

const validationSchema = Yup.object().shape({
  zones: Yup.array().required("Zones is required!"),
  locations: Yup.array().required("Locations is required!"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ZonesLocationForm = ({ open, onCancel, closeDrawer, id }) => {
  const [form] = Form.useForm();
  const [, setRefreshTableData] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [allZones, setAllZones] = useState([]);

  // States for Selected Zones and Locations
  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // Get location list
  const getLocationsList = async () => {
    try {
      const response = await getLocationListService();
      setAllLocations(response.data);
    } catch (error) {
    
    }
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDrawerClose = () => {
    closeDrawer();
  };

  const getAllZones = async () => {
    try {
      const response = await getAllZoneService(1, 500, "");
      setAllZones(response.data);
    } catch (error) {
    }
  };

  const getUserZonesLocationsData = async () => {
    if (id) {
      try {
        const response = await getUserDetailsServicebyId(id);
        const { zones, locations } = response?.data;

        const uniqueLocationsSet = new Set([
          ...(locations || []).map((location) => location.id),
          ...(zones || [])
            .filter((zone) => zone?.locations) // Filter out zones without locations
            .flatMap((zone) =>
              (zone?.locations || []).map((location) => location.id)
            ),
        ]);

        // Convert the Set back to an array
        const selectedLocations = [...uniqueLocationsSet];

        form.setFieldsValue({
          zones: (zones || []).map((zone) => zone.id),
          locations: selectedLocations,
        });
        setSelectedLocations(selectedLocations || []);
        setSelectedZones((zones || []).map((zone) => zone.id));
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // Handle the change in zones
  const handleZonesChange = (value) => {
    setSelectedZones(value);

    // Fetch and collect locations associated with the selected zones
    let locations = [];
    value.forEach((zoneId) => {
      const zone = allZones.find((z) => z.id === zoneId);
      if (zone) {
        locations = [...locations, ...zone.locations.map((loc) => loc.id)];
      }
    });

    const uniqueLocations = [...new Set(locations)];
    setSelectedLocations(uniqueLocations);
    form.setFieldsValue({ locations: uniqueLocations });
  };

  // Handle Change in Locations
  const handleLocationsChange = (value) => {
    setSelectedLocations(value);
  };

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const userZonesResponse = await updateUserZonesDetailsServicebyId(id, {
          zones: selectedZones,
        });
        const userLocationsResponse =
          await updateUserLocationsDetailsServicebyId(id, {
            locations: selectedLocations,
          });

        if (
          userZonesResponse.status === 200 &&
          userZonesResponse.success &&
          userLocationsResponse.status === 200 &&
          userLocationsResponse.success
        ) {
          message.success("User Locations Updated Successfully");
          message.success("User Zones Updated Successfully");
        }
        toggleRefreshTableData();
        handleDrawerClose();
      }
    } catch (error) {
      handleDrawerClose();
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (open) {
      getUserZonesLocationsData();
    } else {
      form.resetFields();
    }
    getLocationsList();
    getAllZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        zones: [],
        locations: [],
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="zones" label="Zones" rules={[yupSync]}>
            <Select
              mode="multiple"
              placeholder="Please select"
              required={true}
              allowClear
              onChange={handleZonesChange}
              value={selectedZones}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allZones?.map((zone) => (
                <Select.Option key={zone.id} value={zone.id}>
                  {zone.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="locations" label="Locations" rules={[yupSync]}>
            <Select
              mode="multiple"
              placeholder="Please select"
              required={true}
              onChange={handleLocationsChange}
              value={selectedLocations}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allLocations?.map((location) => (
                <Select.Option key={location.id} value={location.id}>
                  {location.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>

        <Button onClick={handleCancel}>Prev</Button>
        <Button onClick={handleDrawerClose}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ZonesLocationForm;

