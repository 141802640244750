import React, { useState, useEffect } from "react";
import "../styles/rcu.css";
import {
  Drawer,
  Form,
  Select,
  Space,
  Button,
  message,
  Modal,
  Empty,
} from "antd";
import { UpOutlined, EditOutlined, DeleteOutlined, FileOutlined } from "@ant-design/icons";
import Rcustatusform from "./rcustatusform";
import Manualdeviationform from "./manualdeviationform";
import Conclusions from "./conclusions";
import Completeremark from "./completeremark";
import Header from "../../layout/views/Header";
import Propertypicturesdocuments from "./propertypicturesdocuments";
import {
  patchDedupService,
  deleteDeviationServicebyId,
  getRCUIdbyloanId,
  getRCUdetails,
} from "../services/rcuservices";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import Loading from "../../../utils/loading/Loading";
import pdf from "../assets/Pdf.png"
import RViewerJS from "viewerjs-react";
import EditButton from "../../../utils/editButton/EditButton";

import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";

const { Option } = Select;

const Rcu = () => {
  const [isRemarkVisible, setIsRemarkVisible] = useState(true);
  const [isConclusionVisible, setIsConclusionVisible] = useState(true);
  const [isDeviationVisible, setIsDeviationVisible] = useState(true);
  const [isRcuVisible, setIsRcuVisible] = useState(true);
  const [isCustomersVisible, setIsCustomersVisible] = useState(true);
  // const [isBasicDetailVisible, setIsBasicDetailVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDeviation, setOpenDeviation] = useState(false);
  const [openConclusions, setOpenConclusions] = useState(false);
  const [openCompleteRemark, setOpenCompleteRemark] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [picture, setPicture] = useState(true);
  const [propertyPictures, setPropertyPictures] = useState();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setDeleteValue] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dedupStatusCheck, setDedupStatusCheck] = useState("");
  const [editingCustomerId, setEditingCustomerId] = useState("");
  const [rcuData, setRCUData] = useState("");
  const [rcuid, setRcuid] = useState("")
  const [loanid, setLoanid] = useState("");
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [manualDeviationForm] = Form.useForm(); // Initialize form instance

  useEffect(() => {
    try {
      setLoanid(decrypt(encrypted_loan_id));
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  }, [encrypted_loan_id]);


  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const getRCUById = async () => {
      try {
        setLoading(true);
        const response = await getRCUIdbyloanId(stage_id);
        if (response.status === 200 && response.success) {
          setData(response.data);
          setRcuid(response.data.rcu_data.id)
          setLoading(false);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };

    const getRCUData = async () => {
      try {
        setLoadingContent(true)
        const response = await getRCUdetails(stage_id);
        if (response.status === 200 && response.success) {
          setRCUData(response.data);
          setLoadingContent(false)
        }
      } catch (error) {
        message.error(
          (error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (loanid) {
      getRCUById();
      getRCUData();
    }
  }, [loanid, stage_id, refreshData]);

  const toggleRemarkVisibility = () => setIsRemarkVisible(!isRemarkVisible);
  const toggleConclusionVisibility = () =>
    setIsConclusionVisible(!isConclusionVisible);
  const toggleDeviationVisibility = () =>
    setIsDeviationVisible(!isDeviationVisible);
  const toggleRcuVisibility = () => setIsRcuVisible(!isRcuVisible);
  const toggleCustomersVisibility = () =>
    setIsCustomersVisible(!isCustomersVisible);
  // const toggleBasicDetailVisibility = () =>
  //   setIsBasicDetailVisible(!isBasicDetailVisible);
  const togglepicturesVisibility = () => setPicture(!picture);
  const toggleRefreshData = () => setRefreshData((prev) => !prev);

  const showDrawer = () => setOpen(true);
  const showDeviationDrawer = () => setOpenDeviation(true);
  const showConclusionDrawer = () => setOpenConclusions(true);
  const showPropertyPicturesDrawer = () => setPropertyPictures(true);
  const showCompleteRemarkDrawer = () => setOpenCompleteRemark(true);

  const onClose = () => {
    manualDeviationForm.resetFields();
    setOpen(false);
    setOpenCompleteRemark(false);
    setPropertyPictures(false);
    setOpenConclusions(false);
    setOpenDeviation(false);
    setSelectedUser("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeleteValue(null);
  };

  const handleChange = (value) => setDedupStatusCheck(value);

  const handleSave = async (customerId) => {
    try {
      const response = await patchDedupService(customerId, {
        dedup_check: dedupStatusCheck,
      });
      if (response.status === 200 && response.success) {
        message.success("Dedup status successfully updated");
        setEditingCustomerId(null);
        toggleRefreshData();
      }
    } catch (error) {
      message.error(
        `${error?.response?.status}: ${error?.response?.data?.message}`
      );
    }
  };

  const handleEditClick = (customerId) => {
    setEditingCustomerId(customerId);
    setDedupStatusCheck(""); // Reset dedupStatusCheck if needed
  };

  const handleCancelEdit = () => setEditingCustomerId(null);

  const deleteDeviationById = async () => {
    try {
      const response = await deleteDeviationServicebyId(selectedUser);
      if (response.status === 200 && response.success) {
        message.success("Deviation deleted successfully");
        setIsModalOpen(false);
        toggleRefreshData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // const dateString = rcuData?.credit_assessment_detail?.initiated_at;

  // const coapplicants =
  //   rcuData?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
  //     (item) => item?.customer_type === "Co-Applicant"
  //   );

  // Function to format the date
  // function formatDate(dateStr) {
  //   const date = new Date(dateStr);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  //   const year = date.getFullYear();

  //   return `${day}-${month}-${year}`;
  // }

  // const formattedDate = formatDate(dateString);

  const DeleteModalFooter = ({ onCancel }) => (
    <>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" danger onClick={deleteDeviationById}>
        Delete
      </Button>
    </>
  );

  return (
    <div>
      {loading ? (
        <div style={{ margin: "0 15px 0 15px", border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) :
        (<div>
          <div className="main_technical_report">
            <div>
              <p className="para">RCU</p>
            </div>

          </div>
        <LoanSummaryBlock data={rcuData}/>
     
      
        </div>)}

      {loadingContent ? (
        <div style={{ margin: "0 15px 0 15px", border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) : (
        <div>
          <div>
            <div className="heading-div">
              <p className="para">Screen Detail</p>
            </div>
          </div>

          {/* <div className="valuation_container">
        <div className="valuation_details">
          <div className="valuation_details_blue">Basic Detail</div>

          <div className="rcu_buttons_container">
            <UpOutlined
              style={{
                margin: "1rem",
                transform: isBasicDetailVisible
                  ? "rotate(0deg)"
                  : "rotate(180deg)",
              }}
              onClick={toggleBasicDetailVisibility}
            />
          </div>
        </div>

        {isBasicDetailVisible && (
          <div className="rcu_value_property">
            <div className="rcu_value_property_heading">
              Product
              {data?.loan_detail?.loan_type ? (
                <div className="value">
                  {data?.loan_detail?.loan_type?.display_name}
                </div>
              ) : (
                <div className="value">-</div>
              )}
            </div>

            <div className="rcu_value_property_heading">
              Sourcing Location
              {data?.loan_detail?.location ? (
                <div className="value">
                  {data?.loan_detail?.location?.display_name}
                </div>
              ) : (
                <div className="value">-</div>
              )}
            </div>

            <div className="rcu_value_property_heading">
              Screened Date
              {data ? (
                <div className="value">{data?.reporting_date}</div>
              ) : (
                <div className="value">-</div>
              )}
            </div>

            <div className="rcu_value_property_heading">
              Reporting Date
              {data ? (
                <div className="value">{data?.reporting_date}</div>
              ) : (
                <div className="value">-</div>
              )}
            </div>
          </div>
        )}
      </div> */}

          <div>
            <div className="valuation_container">
              <div className="valuation_details">
                <div className="valuation_details_blue">Customers</div>

                <div className="rcu_buttons_container">
                  <UpOutlined
                    style={{
                      margin: "1rem",
                      transform: isCustomersVisible
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                    onClick={toggleCustomersVisibility}
                  />
                </div>
              </div>

              {isCustomersVisible && (
                <div className="customers_content">
                  <div
                    className="customer_header"
                  >
                    <div className="header_item" style={{ flex: 1 }}>Customer Name</div>
                    <div className="header_item" style={{ flex: 2 }}>Residence Address</div>
                    <div className="header_item" style={{ flex: 2 }}>Office Address</div>
                    <div className="header_item" style={{ flex: 1, textAlign: "center" }}>Dedupe Check</div>
                    <div className="header_item" style={{ flex: 1 }}>Action</div>
                  </div>

                  {data?.rcu_data?.rcu_customer_detail?.map((datas, index) => (
                    <div
                      className="rcu_value_property"
                      key={datas.customer_detail.id}
                      style={{
                        paddingLeft: 8,
                        borderBottom:
                          index !== data.rcu_data.rcu_customer_detail.length - 1
                            ? "1px solid #d9d9d9"
                            : "none",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {datas ? (
                          <div className="value">{datas.customer_detail.name}</div>
                        ) : (
                          <div className="value">-</div>
                        )}
                      </div>
                      <div style={{ flex: 2 }}>
                        {datas ? (
                          <div className="value">
                            {datas.customer_detail.address_details
                              .filter(
                                (detail) =>
                                  detail.address_type.name === "Permanent Address"
                              )
                              .map((detail) => detail.address_detail)}
                          </div>
                        ) : (
                          <div className="value">-</div>
                        )}
                      </div>
                      <div style={{ flex: 2 }}>
                        {datas ? (
                          <div className="value">
                            {datas.customer_detail.address_details
                              .filter(
                                (detail) => detail.address_type.name === "Office Address"
                              )
                              .map((detail) => detail.address_detail)}
                          </div>
                        ) : (
                          <div className="value">-</div>
                        )}
                      </div>
                      <div style={{ flex: 1 }}>
                        {editingCustomerId === datas.customer_detail.id ? (
                          <div className="rcu_value_property_heading">
                            <Form.Item name="rcu_status">
                              <Select
                                value={dedupStatusCheck}
                                onChange={handleChange}
                                style={{ width: "90%" }}
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        ) : (
                          <div className="rcu_value_property_heading">
                            <div style={{ textAlign: "center" }}>{datas?.customer_detail?.dedup_check ? "Yes" : "No"}</div>
                          </div>
                        )}
                      </div>
                      <div style={{ flex: 1 }}>
                        {editingCustomerId === datas.customer_detail.id ? (
                          <div className="rcu_customer_edit_section" style={{ marginTop: 10 }}>
                            <p
                              className="rcu_customer_save"
                              onClick={() => handleSave(datas.id)}
                            >
                              Save
                            </p>
                            <p
                              className="rcu_customer_cancel"
                              onClick={handleCancelEdit}
                            >
                              Cancel
                            </p>
                          </div>
                        ) : (
                          <EditOutlined style={{ marginTop: 15 }}
                            onClick={() => handleEditClick(datas.customer_detail.id)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div></div>
              <div></div>
            </div>
          </div>


          {/*Start of Rcu Status Table*/}
          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">RCU Status</div>
              <div className="rcu_buttons_container">
                {/* <button
                  className="rcu_edit"
                  onClick={() => {
                    showDrawer();
                  }}
                >
                  <img src={edit} alt="Pencil Icon" />
                  <span>Edit</span>
                </button> */}
                <div onClick={() => { showDrawer(); }}>
                  <EditButton />
                </div>
                <Drawer
                  title={<Header title="RCU Status" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={open}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Rcustatusform
                    refreshData={toggleRefreshData}
                    open={open}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  style={{
                    margin: "1rem",
                    transform: isRcuVisible ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  onClick={toggleRcuVisibility}
                />
              </div>
            </div>

            {isRcuVisible && (
              <div className="rcu_content">
                <div className="valuation_details_heading">
                  <div className="rcu_value_property_heading">SR.No</div>
                  <div className="rcu_value_property_heading">Document</div>
                  <div className="rcu_value_property_heading">Images</div>
                  <div className="rcu_value_property_heading">Remarks</div>
                  <div className="rcu_value_property_heading">Status</div>
                </div>
                {data?.rcu_data?.rcu_docs?.map((datas, index, array) => (
                  <div className="rcu_value_property_status" key={index} style={{
                    borderBottom:
                      index < array.length - 1 ? "1px solid #d9d9d9" : "none", // Apply border only if not the last item
                  }}>
                    <div className="rcu_value_property_heading" style={{ color: "rgba(0, 0, 0, 0.85)" }}>{index + 1}</div>
                    <div className="rcu_value_property_heading">
                      {datas ? (
                        <div className="value">
                          {datas?.document_detail?.document_type?.dispaly_name || datas?.document_type}
                        </div>
                      ) : (
                        <div className="value">-</div>
                      )}
                    </div>
                    <div className="rcu_value_property_heading">
                      {/* Conditionally render based on file extension */}
                      {datas?.document_detail?.document_path || datas?.image_path ? (
                        <div className="img_document">
                          {(() => {
                            const url = datas?.document_detail?.document_path || datas?.image_path;
                            const parts = url.split('/');
                            const fileName = parts.pop().split('?')[0];
                            const fileExtension = fileName.split('.').pop().toLowerCase();

                            if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
                              return (
                                <>
                                  <RViewerJS>
                                    <img
                                      src={url}
                                      height={45}
                                      width={45}
                                      style={{ cursor: "pointer", marginBottom: "10px" }}
                                      alt={`Document: ${fileName}`}
                                    />
                                  </RViewerJS>
                                  {/* <p style={{ marginTop: "5px", textAlign: "center" }}>{fileName}</p> */}
                                </>
                              );
                            } else if (fileExtension === 'pdf') {
                              return (
                                <>
                                  <img
                                    src={pdf} // Ensure 'pdf' is a valid imported image placeholder for PDFs
                                    alt="PDF"
                                    height={45}
                                    width={45}
                                    style={{ cursor: "pointer", marginBottom: "10px" }}
                                    onClick={() => window.open(url, "_blank")}
                                  />
                                  {/* <p style={{ marginTop: "5px", textAlign: "center" }}>{fileName}</p> */}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <FileOutlined
                                    style={{
                                      fontSize: "35px",
                                      marginTop: "15px",
                                      cursor: "pointer",
                                    }}
                                    title={fileName}
                                  />
                                  {/* <p style={{ marginTop: "5px", textAlign: "center" }}>{fileName}</p> */}
                                </>
                              );
                            }
                          })()}
                        </div>
                      ) : (
                        <div className="value">-</div>
                      )}
                    </div>

                    <div className="rcu_value_property_heading">
                      {datas ? (
                        <div className="value">{datas.remark}</div>
                      ) : (
                        <div className="value">-</div>
                      )}
                    </div>
                    <div className="rcu_value_property_heading">
                      <p className="rcu_status_green">{datas.status}</p>
                    </div>
                  </div>
                ))}
              </div>

            )}

            <div></div>
            <div></div>
          </div>
          {/*End of Rcu Status Table*/}

          {/*Start of Manual Deviation Details Table*/}

          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">Manual Deviation Details</div>

              <div className="rcu_buttons_container">
                <button
                  className="rcu_deviation_button"
                  onClick={showDeviationDrawer}
                >
                  Add Manual Deviation
                </button>
                <Drawer
                  title={<Header title="Manual Deviation" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={openDeviation}
                  bodyStyle={{ paddingBottom: 80 }}
                  closable={false}
                  selectedUser={selectedUser}
                >
                  <Manualdeviationform
                    refreshData={toggleRefreshData}
                    open={openDeviation}
                    closeForm={onClose}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    loanId={loanid}
                    form={manualDeviationForm}
                    rcuid={rcuid}

                  />
                </Drawer>
                <UpOutlined
                  style={{
                    margin: "1rem",
                    transform: isDeviationVisible
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  }}
                  onClick={toggleDeviationVisibility}
                />
              </div>
            </div>

            {isDeviationVisible &&
              (rcuData.loan_deviation_detail?.length > 0 ? (
                <div className="deviation_content">
                  <div className="valuation_details_heading">
                    <div className="rcu_value_property_heading">SR.No</div>
                    <div className="rcu_value_property_heading">Deviations</div>
                    <div className="rcu_value_property_heading">
                      Actual Deviations
                    </div>
                    <div className="rcu_value_property_heading">Mitigates</div>
                    <div className="rcu_value_property_heading">Remove</div>
                  </div>

                  {rcuData.loan_deviation_detail.map((datas, index, array) => (
                    <div className="rcu_value_property_deviation" key={index} style={{
                      borderBottom:
                        index < array.length - 1 ? "1px solid #d9d9d9" : "none", // Apply border only if not the last item
                    }}>
                      <div className="rcu_value_property_heading">
                        <div className="value">{index + 1}</div>
                      </div>
                      <div className="rcu_value_property_heading">
                        <div className="value">{datas.deviation.name}</div>
                      </div>
                      <div className="rcu_value_property_heading">
                        <div className="value">{datas.actual_description}</div>
                      </div>
                      <div className="rcu_value_property_heading">
                        <div className="value">{datas.mitigates}</div>
                      </div>
                      <div className="rcu_value_property_heading">
                        <Space size="large">
                          <DeleteOutlined
                            style={{ color: "red" }}
                            onClick={() => {
                              setSelectedUser(datas.id);
                              setIsModalOpen(true);
                            }}
                          />
                          <EditOutlined
                            style={{ color: "#1890FF" }}
                            onClick={() => {
                              showDeviationDrawer();
                              setSelectedUser(datas.id);
                            }}
                          />
                        </Space>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Empty style={{ marginTop: 35, marginBottom: 35 }} />
              ))}
          </div>

          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">Conclusions</div>

              <div className="rcu_buttons_container">
                {/* <button
                  className="rcu_edit"
                  onClick={() => {
                    showConclusionDrawer();
                  }}
                >
                  <img src={edit} alt="Pencil Icon" />
                  <span>Edit</span>
                </button> */}
                <div onClick={() => {
                  showConclusionDrawer();
                }}>
                  <EditButton />

                </div>
                <Drawer
                  title={<Header title="Conclusions" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={openConclusions}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Conclusions
                    refreshData={toggleRefreshData}
                    open={openConclusions}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  style={{
                    margin: "1rem",
                    transform: isConclusionVisible ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  onClick={toggleConclusionVisibility}
                />
              </div>
            </div>

            {isConclusionVisible && (
              <div className="document_details_second_heading">
                <div className="valuation_details_second_data">
                  Overall Status <span style={{ color: "red" }}>*</span>
                </div>
                <div
                  className="valuation_details_second_data"
                  style={{ color: "#52C41A" }}
                >
                  {data?.rcu_data?.overall_status}
                </div>
                <br />
                <div className="valuation_details_second_data">
                  Remarks /Observation (please enter NA if none)
                </div>
                <div
                  className="valuation_details_second_data"
                  style={{ color: "#4D4D4D" }}
                >
                  {data?.rcu_data?.overall_remark}
                </div>
              </div>
            )}
          </div>

          <div className="valuation_container">
            <div className="document_details">
              <div className="document_details_blue" style={{ marginLeft: 15 }}>Complete Remark</div>

              <div className="rcu_buttons_container">
                {/* <button
                  className="rcu_edit"
                  onClick={() => {
                    showCompleteRemarkDrawer();
                  }}
                >
                  <img src={edit} alt="Pencil Icon" />
                  <span>Edit</span>
                </button> */}
                <div onClick={() => {
                  showCompleteRemarkDrawer();
                }}>
                  <EditButton />
                </div>
                <Drawer
                  title={<Header title="Complete Remark" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={openCompleteRemark}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Completeremark
                    refreshData={toggleRefreshData}
                    open={openCompleteRemark}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  style={{
                    margin: "1rem",
                    transform: isRemarkVisible ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  onClick={toggleRemarkVisibility}
                />
              </div>
            </div>

            {isRemarkVisible && (
              <div className="document_details_second_heading">
                <div
                  className="valuation_details_second_data"
                  style={{ color: "#4D4D4D" }}
                >
                  Complete Remark:
                  <br />
                  {data?.rcu_data?.completion_remark}
                </div>
              </div>
            )}
          </div>



          <div className="valuation_container">
            <div className="document_details">
              <div className="valuation_details_blue">
                Property Pictures and Other Documents
              </div>
              <div className="rcu_buttons_container">
                {/* <button
                    className="rcu_edit"
                    onClick={() => {
                      showPropertyPicturesDrawer();
                    }}
                  >
                    <img src={edit} alt="Pencil Icon" />
                    <span>Edit</span>
                  </button> */}
                <div onClick={() => {
                  showPropertyPicturesDrawer();
                }}>
                  <EditButton />
                </div>
                <Drawer
                  title={
                    <Header
                      title="Property Pictures and Other Documents"
                      onClose={onClose}
                    />
                  }
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={propertyPictures}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Propertypicturesdocuments
                    refreshData={toggleRefreshData}
                    open={propertyPictures}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  style={{
                    margin: "1rem",
                    transform: picture ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  onClick={togglepicturesVisibility}
                />
              </div>
            </div>

            {picture && (
              <div style={{ padding: 10 }}>
                <div className="document_images">
                  {data?.rcu_data?.rcu_document_upload?.map((datas, index) => {
                    // Extract the document name from the URL
                    const url = datas.document_path || "";
                    const parts = url.split('/');
                    const fileNameWithParams = parts.pop();
                    const fileName = fileNameWithParams.split('?')[0]; // Remove query parameters if present

                    return (
                      <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "5px" }}>
                        <RViewerJS>
                          <img
                            height={75}
                            width={75}
                            style={{ marginLeft: "5px", marginRight: "5px", cursor: "pointer" }}
                            src={url}
                            alt={fileName}
                          />
                        </RViewerJS>
                        <p style={{ marginTop: "5px", textAlign: "center" }}>{fileName}</p>
                      </div>
                    );
                  })}
                </div>
              </div>

            )}
          </div>
        </div>

      )}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={<DeleteModalFooter onCancel={handleCancel} />}
      >
        <p className="modal-paragraph">{`Are you sure you want to delete`}</p>
      </Modal>
    </div>

  );
};

export default Rcu;
