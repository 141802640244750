import React, { useEffect, useState } from "react";
import {
  DownOutlined,
  UpOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Space, Table, message } from "antd";
import Header from "../../../layout/views/Header";
import VehiclesEditForm from "./VehiclesEditForm";
import { getVehiclesByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { deleteVehicleByIdService } from "../../services/applicantRvServices";

const Vehicle = ({ selectedCustomerId }) => {
  const [openEditSix, setOpenEditSix] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [collapsed6, setCollapsed6] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [VehiclesData, setVehiclesData] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getVehiclesByCustomerId(selectedCustomerId);
        setVehiclesData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData,selectedCustomerId]);

  const toggleCollapse6 = () => {
    setCollapsed6(!collapsed6);
  };
  const showDrawerSix = () => {
    setOpenEditSix(true);
  };
  const onCloseSix = () => {
    setOpenEditSix(false);
  };

  const vehiclescolumns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      render: (vehicle_type) => {
        return vehicle_type ? vehicle_type : " -";
      },
    },
    {
      title: "Model No",
      dataIndex: "model_no",
      key: "model_no",
      render: (model_no) => {
        return model_no ? model_no : " -";
      },
    },

    {
      title: "Ownership Type",
      dataIndex: "ownership_type",
      key: "ownership_type",
      render: (ownership_type) => {
        return ownership_type ? ownership_type : " -";
      },
    },
    {
      title: "Vehicle No",
      dataIndex: "registration_number",
      key: "registration_number",
      render: (registration_number) => {
        return registration_number ? registration_number : " -";
      },
    },
    {
      title: "Financed From",
      dataIndex: "financed_from",
      key: "financed_from",
      render: (financed_from) => {
        return financed_from ? financed_from : " -";
      },
    },
    {
      title: "Loan Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (loan_amount) => {
        return loan_amount ? loan_amount : " -";
      },
    },

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              style={{ color: "#4880FF" }}
              onClick={() => {
                setSelectedVehicle(record);
                showDrawerSix();
              }}
            />
            <DeleteOutlined
              style={{ color: "#E5233DB2" }}
              onClick={() => {
                setSelectedVehicle(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];


  const deleteVehicle = async () => {
    try {
      const response = await deleteVehicleByIdService(selectedVehicle?.id);
      if (response.status === 200 && response.success === true) {
        message.success("Vehicle deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedVehicle(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };
  return (
    <div style={{ margin: "20px 0px 20px 0px" }}>
      <Drawer
        title={<Header title="Edit" onClose={onCloseSix} name="Vehicles" />}
        width={970}
        onClose={onCloseSix}
        open={openEditSix}
        closable={false}
      >
        <VehiclesEditForm
          open={openEditSix}
          id={selectedVehicle?.id}
          // loan_id={loan_id}
          closeForm={onCloseSix}
          refreshData={toggleRefreshTableData}
        // customerDetails={customerDetails}
        />
      </Drawer>
      <div className="property_description_heading">
        <div className="property_description_data">Vehicles</div>
        <div style={{ display: "flex" }}>
          <div
            style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
            onClick={toggleCollapse6}
          >
            {collapsed6 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed6 && (
        <div className="table-container" style={{ border: "1px solid #E4E7EC" }}>
          <Table
            columns={vehiclescolumns}
            dataSource={VehiclesData}
            pagination={false}
          />
        </div>
      )}
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteVehicle()
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this vehicle?`}</p>
      </Modal>
    </div>
  );
};

export default Vehicle;
