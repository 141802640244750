import React, { useEffect, useState } from 'react'
import usersecond from "../assets/application.png";
import contact from "../assets/contact.png";
import "../styles/loanApplication.css";
import ErrorMessage from '../../../../utils/errorHandling/ErrorMessage';
import { getLAN, getLoanDetailsById, getLoanDetailsByLoanId } from '../services/loanapplicationService';
import { message, Button } from 'antd';
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import location from "../assets/location.png"

const LoanDetailMainCard = ({refresh}) => {
  const [loanDetailsByLoanId, setLoanDetailsByLoanId] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const { encrypted_loan_id } = useParams();

  const [displayButton, setDisplayButton] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }


  const fetchLAN = async () => {
    try {
      const response = await getLAN(loan_id);
      if (response.status === 200) {
        message.success("Loan Account Number Generated Successfully");
        setDisplayButton(false);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        console.log("loan details basic",response?.data)
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchLoanDetailsByLoanId = async () => {
      try {
        const response = await getLoanDetailsByLoanId(loan_id);
        console.log("loan details",response?.data)
        setLoanDetailsByLoanId(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchLoanDetailsByLoanId();
    fetchLoanDetails();

  }, [loan_id, displayButton,refresh]);
  return (
    <>
      <div className="flex-container">
        <div className="loan-application-container">
          <div className="loan-details">
            <p>Loan Account Number</p>
            <h1 style={{ marginTop: "5px" }}>{loanDetails?.loan_account_number || "-"}</h1>
          </div>

          {/* <div className="loan-type">
            <img
              src={correct}
              alt="correct_icon"
              style={{ marginRight: "5px" }}
            />
            {loanDetailsByLoanId?.loan_detail?.loan_type?.loan_type || "-"}
          </div> */}
        </div>
        <div>
          {displayButton === true ? (
            <Button
              type="primary"
              className="loangenerate_button"
              onClick={fetchLAN}
            >
              Generate Lan Number
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="main_loan_basic_container" style={{ border: "1px solid #e4e7ec", }}>
        {/* <div style={{ borderBottom: "1px solid #e4e7ec",flexWrap:"wrap", paddingBottom: "10px", display: "flex", justifyContent: "space-between" }}> */}
        <div className='main_page_loan' style={{ borderBottom: "1px solid #e4e7ec", flexWrap: "wrap" }}>

          <div className="laon_main_page" >
            <div className="container-header" style={{ marginLeft: "" }}>
              <div  >
                <img
                  src={usersecond}
                  alt="user_image"
                  style={{ fontSize: "24px", height: "41px" }}
                />
              </div>


              <div className='card_loan'>
                <div className="loan_data">
                  {/* {loanDetailsByLoanId?.loan_detail?.application_number || "-"} */}
                  {loanDetailsByLoanId?.loan_detail?.loan_type?.loan_type || "-"}
                </div>
                <div className="loan_label">Loan Type</div>

              </div>
            </div>
          </div>

          <div className="laon_main_page" style={{ borderLeft: "1px solid #e4e7ec" }} >
            <div className="container-header" style={{ marginTop: "", marginLeft: "20px" }}>
              <div className="" >
                <img
                  src={usersecond}
                  alt="user_image"
                  style={{ fontSize: "24px", height: "41px" }}
                />
              </div>


              <div className='card_loan' >
                <div className="loan_data">
                  {loanDetailsByLoanId?.loan_detail?.application_number || "-"}
                </div>
                <div className="loan_label">Application Number</div>

              </div>
            </div>
          </div>


          <div className="laon_main_page" style={{ borderLeft: "1px solid #e4e7ec" }} >
            <div className="container-header" style={{ marginTop: "", marginLeft: "20px" }}>
              <div className="" >
                <img
                  src={contact}
                  style={{ fontSize: "24px", height: "41px" }}
                  alt=''
                />
              </div>


              <div className='card_loan' >
                <div className="loan_data">
                  {" "}
                  {loanDetailsByLoanId && loanDetailsByLoanId?.customer_details
                    ? loanDetailsByLoanId?.customer_details.find(
                      (customer_details) =>
                        customer_details.customer_type.customer_type_name ===
                        "Applicant"
                    )?.full_name || "-"
                    : "-"}
                </div>
                <div className="loan_label">Applicant</div>

              </div>
            </div>
          </div>


          <div className="laon_main_page" style={{ borderLeft: "1px solid #e4e7ec" }}>
            <div className="container-header">
              <div style={{ marginLeft: "20px" }} >
                <img
                  src={location}
                  alt="user_image"
                  style={{ fontSize: "24px", height: "41px" }}
                />
              </div>


              <div className='card_loan' style={{ marginRight: "200px" }}>

                <div className="loan_data">
                  {loanDetailsByLoanId?.loan_detail?.branch || "-"}

                </div>
                <div className="loan_label">Branch</div>
              </div>
            </div>
          </div>


        </div>
        <div className="Basic_details_container" >

          <div className="Basic_card">

            <div className="basic_card-content">
              <div className="loan_label" >Last Tranches</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.tranches || "-"}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">Scheme</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.scheme?.name || "-"}{" "}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">Product</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetails?.product?.name || "-"}{" "}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">Sanction Amount</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.product?.name || "-"}{" "}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">Installment Type</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.product?.installment_type || "-"}{" "}
              </div>
            </div>

          </div>
          <div className="Basic_card">
            {/* <div className="basic_card-content">
              <div className="label">Application Number</div>
              <div className="data">
                {loanDetailsByLoanId?.loan_detail?.application_number || "-"}
              </div>
            </div> */}
            <div className="basic_card-content">
              <div className="loan_label">Sales Manager</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.sales_manager?.name || "-"}
              </div>

            </div>

            <div className="basic_card-content">
              <div className="loan_label">Credit Manager</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.credit_manager?.name || "-"}
              </div>


            </div>


            <div className="basic_card-content">
              <div className="loan_label">Purpose</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetails?.loan_purpose?.display_name || "-"}
              </div>




            </div>

            <div className="basic_card-content">
              <div className="loan_label"> EMI Due Date</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetails?.emi_due_date || "-"}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label"> Disbursement Amount</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.Purpose?.name || "-"}
              </div>
            </div>

          </div>
          <div className="Basic_card">
            {/* <div className="basic_card-content">
              <div className="label">Loan Amount / Loan Tenure</div>
              <div className="data">
                ₹{" "}
                {IndianNumberFormat(
                  loanDetailsByLoanId?.loan_detail?.approved_amount
                ) || "-"}
              </div>
            </div> */}
            <div className="basic_card-content">
              <div className="loan_label">EMI</div>
              <div className="laon_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.Emi || "-"}
              </div>

            </div>

            <div className="basic_card-content">
              <div className="loan_label">No. of Installments / Frequency</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.installments || "-"}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">Interest Rate</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.intrest_rate || "-"}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">EMI/EQI/EHI/YI/ Amount</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.amount || "-"}
              </div>
            </div>

            <div className="basic_card-content">
              <div className="loan_label">Disbursement Date</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.amount || "-"}
              </div>
            </div>

          </div>
          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="loan_label">Sanction Date</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.sanction_date || "-"}
              </div>
            </div>
            <div className="basic_card-content">
              <div className="loan_label">Inst Start Date</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.start_date || "-"}
              </div>

            </div>

            <div className="basic_card-content">
              <div className="loan_label">Tenor</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.start_date || "-"}
              </div>

            </div>


            <div className="basic_card-content">
              <div className="loan_label">LTV</div>
              <div className="loan_data" style={{ paddingTop: "5px" }}>
                {loanDetailsByLoanId?.loan_detail?.ltv || "-"}
              </div>

            </div>


          </div>




        </div>
      </div>
    </>
  )
}

export default LoanDetailMainCard