import React, { useEffect, useState } from "react";
import "../styles/fi.css";
import { Tabs, message } from "antd";
import FiRVForm from "./fiApplicantRVInputForm/FiRVForm";
import FiOVForm from "./fiApplicantRVInputForm/FiOVForm";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import { getInitiatedDataByLoanId } from "../services/FIInputFormServices";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const FiSwitchTab = ({ customerId, loanId, stageId }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [fiAddresses, setFiAddresses] = useState([]);

  const navigate = useNavigate();

  const fetchInitiatedData = async () => {
    try {
      const response = await getInitiatedDataByLoanId(stageId);
      const addresses = response?.data?.fi_addresses || [];
      setFiAddresses(addresses);

      if (addresses.length > 0) {
        setSelectedApplicantId(addresses[0]["fi address id"]);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    fetchInitiatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCustomerDetails = () => {
    setShowCustomerDetails((prev) => !prev);
  };

  const ApplicantFIdata = fiAddresses?.map((item) => {
    const newData = {
      ...item,
      id: item["fi address id"],
      label:
        item.address_type === "Residential Address" ? "FiRVForm" : "FiOVForm",
      type: item.customer_type.name,
    };
    return newData;
  });

  const typeToComponentMap = {
    FiRVForm: FiRVForm,
    FiOVForm: FiOVForm,
  };

  const renderTabContent = () => {
    const selectedData = ApplicantFIdata.find(
      (item) => item.id === selectedApplicantId
    );
    if (!selectedData) return null;

    const Component = typeToComponentMap[selectedData.label] || null;
    return Component ? (
      <Component
        selectedData={selectedData}
        onSuccessfulSubmit={handleSuccessfulSubmit}
        fiAddresses={fiAddresses}
        onSuccess={fetchInitiatedData}
        loanId={loanId}
        stageId={stageId}
      />
    ) : null;
  };

  const handleTabClick = (id) => {
    setSelectedApplicantId(id);
  };

  const handleTabChange = (key) => {
    setSelectedTabIndex(Number(key));
    const selectedApplicant = fiAddresses[Number(key)];
    if (selectedApplicant) {
      handleTabClick(selectedApplicant["fi address id"]);
    }
  };

  const handleSuccessfulSubmit = (id) => {
    setFiAddresses((prevAddresses) =>
      prevAddresses.filter((address) => address["fi address id"] !== id)
    );
    // Set selected tab index and applicant ID to first remaining tab, if any
    if (fiAddresses.length > 1) {
      setSelectedTabIndex(0);
      setSelectedApplicantId(fiAddresses[0]["fi address id"]);
    } else {
      navigate(`/Application`);
    }
  };

  return (
    <>
      <div className="legal_tabs_container">
        <div
          className="customer_main_heading"
          style={{ borderBottom: !showCustomerDetails && "none" }}
        >
          <div>
            <h2 className="heading_loan_details">Details</h2>
          </div>
          <div className="customer_icon">
            {showCustomerDetails ? (
              <AiOutlineUp
                style={{ cursor: "pointer" }}
                onClick={toggleCustomerDetails}
              />
            ) : (
              <AiOutlineDown
                style={{ cursor: "pointer" }}
                onClick={toggleCustomerDetails}
              />
            )}
          </div>
        </div>

        <div style={{ display: showCustomerDetails ? "block" : "none" }}>
          <Tabs
            activeKey={String(selectedTabIndex)}
            onChange={handleTabChange}
            type="card"
            style={{ marginTop: "20px", marginLeft: "20px" }}
          >
            {fiAddresses?.map((applicant, index) => (
              <TabPane
                tab={
                  <div
                    onClick={() => handleTabClick(applicant["fi address id"])}
                  >
                    {applicant.customer_name}
                  </div>
                }
                key={index}
              >
                {/* Content for {applicant.customer_name} */}
              </TabPane>
            ))}
          </Tabs>

          <div>{renderTabContent()}</div>
        </div>
      </div>
    </>
  );
};

export default FiSwitchTab;
