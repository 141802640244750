import {
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Space,
  Button,
  message,
  Input,
  Modal,
  Upload
} from "antd";
import React, { useEffect, useState } from "react";
import "../styles/RCUInitiationForm.css";
import {
  getMasterAgencyListById,
  getRCUProcessListById,
  getRCUProcessDetailsById,
  postRCUInitiate,
  getAllAgentService,
  getRCUoptions
} from "../services/rcuinitiationform";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import pdf from "../images/Pdf.png";
import ReactQuill from "react-quill";
import { PlusOutlined, FileOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import RViewerJS from "viewerjs-react";
const { Option } = Select;

const RCUInitiationForm = ({ open, loan_id, closeForm }) => {
  const [form] = Form.useForm();
  const [vendors, setVendors] = useState([]);
  const [agents, setAgents] = useState([]);
  const [rcu, setRCU] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [agentsselected, setAgentsselected] = useState([]);
  const [sampledata, setSampledata] = useState();
  const { user_data } = useSelector((state) => state.user);
  const [modifiedDatas, setModifiedDatas] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState("");
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [rcutype, setRcutype] = useState([]);
  const [choice, setChoice] = useState("")
  const [loading, setLoading] = useState(false);

  const generateMailSubject = () => {
    const locationName = sampledata?.loan_detail?.location?.display_name || '';
    const loanAccountNumber = sampledata?.loan_detail?.loan_account_number || '';

    // Filter for the customer with type "Applicant"
    const applicant = sampledata?.customer_document_address_detail?.find(
      (customer) => customer.customer_type?.name === "Applicant"
    );

    const applicantFirstName = applicant?.customer_name?.first_name || '';

    const applicantMiddleName = applicant?.customer_name?.middle_name || '';

    const applicantLastName = applicant?.customer_name?.last_name || '';

    return `RCU initiate - ${loanAccountNumber} - ${applicantFirstName} ${applicantMiddleName} ${applicantLastName} (${applicant?.customer_type?.name}) ${locationName}`;

  };


  const handleBeforeUpload = async (file) => {
    const base64 = await convertToBase64(file);

    const newImage = {
      file_path: URL.createObjectURL(file),
      uid: file.uid,
      name: file.name,
      base64_url: base64, // Add the base64 URL to the newImage object
    };

    setImages((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    setAttachments((prevAttachments) => [...prevAttachments, base64]);

    return false;
  };


  const handleRemove = (file) => {
    URL.revokeObjectURL(file.originFileObj || file);
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedImages = updatedFileList?.map((file) => ({
      file_path: URL.createObjectURL(file.originFileObj || file),
      uid: file.uid,
      name: file.name,
    }));
    setImages(updatedImages);

    const updatedAttachments = attachments.filter(
      (_, index) => updatedFileList[index]?.uid !== file.uid
    );
    setAttachments(updatedAttachments);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error("File is not a valid Blob"));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const confirmDelete = (file) => {
    URL.revokeObjectURL(deleteUploadPicture.file_path);

    // Update the images state
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((image) => image.uid !== deleteUploadPicture.uid);
      return updatedImages;
    });

    // Update the fileList state
    setFileList((prevFileList) => {
      const updatedFileList = prevFileList.filter((file) => file.uid !== deleteUploadPicture.uid);
      return updatedFileList;
    });

    // Update the attachments state by finding the matching base64 string using uid
    setAttachments((prevAttachments) => {
      const updatedAttachments = prevAttachments.filter((attachment, index) => {
        const relatedImage = images[index];
        return relatedImage?.uid !== deleteUploadPicture.uid;
      });
      return updatedAttachments;
    });

    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const getRcutypecheck = async () => {
    try {
      const response = await getRCUoptions();
      setRcutype(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllVendors = async () => {
    try {
      const response = await getMasterAgencyListById();
      setVendors(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllAgents = async (agentsselected) => {
    try {
      const response = await getAllAgentService(agentsselected);
      setAgents(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (agentsselected) {
      getAllAgents(agentsselected);
    }
  }, [agentsselected]);

  const getAllRCU = async () => {
    try {
      const response = await getRCUProcessListById();
      setRCU(response?.data?.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRCUData = async () => {
    try {
      const response = await getRCUProcessDetailsById(loan_id);
      setSampledata(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    getAllVendors();
    getAllRCU();
    getRcutypecheck();
    getAllRCUData();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, loan_id]);

  useEffect(() => {
    if (sampledata) {
      const modifiedDatass = sampledata?.customer_document_address_detail?.map((detail) => {
        const modifiedDocuments = {};

        for (const category in detail?.customer_document) {
          if (detail?.customer_document?.hasOwnProperty(category)) {
            modifiedDocuments[category] = detail?.customer_document[category]?.map((doc) => ({
              ...doc,
              dropdown_value: "", // Initialize dropdown_value with an empty string or any default value
            }));
          }
        }

        return {
          ...detail,
          customer_document: modifiedDocuments,
        };
      });

      setModifiedDatas(modifiedDatass);
    }
  }, [sampledata]);

  const handleSubmit = async (values) => { 
    setLoading(true); 


    const mailBody = `
    <span class="email_content">
      Dear Team,<br><br>
      Please initiate the RCU Search for ${sampledata?.customer_document_address_detail
        ?.filter(customer => customer?.customer_type?.name === "Applicant")
        .map(customer =>
          `${customer?.customer_name?.first_name || ''} ${customer?.customer_name?.middle_name || ''} ${customer?.customer_name?.last_name || ''}`
        )
        .join(', ')
      } (Applicant).<br><br>
      <div class="initiation_form_table_one">
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">Customer Name</div>
          <div class="initiation_form_table_data_value">${sampledata?.customer_document_address_detail
        ?.filter(customer => customer?.customer_type?.name === "Applicant")
        .map(customer =>
          `${customer?.customer_name?.first_name || ''} ${customer?.customer_name?.middle_name || ''} ${customer?.customer_name?.last_name || ''}`
        )
        .join(', ')
      } (Applicant)</div>
        </div>
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">LAN</div>
          <div class="initiation_form_table_data_value">${sampledata?.loan_detail?.loan_account_number || 'N/A'}</div>
        </div>
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">Product</div>
          <div class="initiation_form_table_data_value">${sampledata?.loan_detail?.loan_type?.display_name || 'N/A'}</div>
        </div>
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">Transaction Type</div>
          <div class="initiation_form_table_data_value">${choice || 'N/A'}</div>
        </div>
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">Property Address</div>
          <div class="initiation_form_table_data_value">
            ${sampledata?.property_detail?.address_line_1 || ''}, 
            ${sampledata?.property_detail?.address_line_2 || ''}, 
            ${sampledata?.property_detail?.city?.name || ''}, 
            ${sampledata?.property_detail?.district?.name || ''}, 
            ${sampledata?.property_detail?.taluka?.name || ''}, 
            ${sampledata?.property_detail?.state?.name || ''}, 
            ${sampledata?.property_detail?.pincode?.name || ''}
          </div>
        </div>
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">Branch</div>
          <div class="initiation_form_table_data_value">${sampledata?.loan_detail?.location?.display_name || 'N/A'}</div>
        </div>
        <div class="initiation_form_table_data">
          <div class="initiation_form_table_data_heading">Contact Person</div>
          <div class="initiation_form_table_data_value">
            (Vastu - ${sampledata?.loan_detail?.credit_manager?.name || 'N/A'}, ${sampledata?.loan_detail?.credit_manager?.mobile || 'N/A'})<br><br>
            Customer Details:<br>
            ${sampledata?.customer_document_address_detail
        ?.filter(customer => customer?.customer_type?.name === "Applicant")
        .map(customer =>
          `${customer?.customer_name?.first_name || ''} ${customer?.customer_name?.middle_name || ''} ${customer?.customer_name?.last_name || ''} (APPLICANT) ${customer?.customer_name?.mobile_number || ''}`
        )
        .join('<br>')
      }
          </div>
        </div>
      </div>
    </span>
    `;
  
    const stripHtmlTags = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };
  
    const cleanedRemark = stripHtmlTags(values.remarks);
    const mailSubject = generateMailSubject();
  
    const formattedValues = {
      loan_detail: sampledata?.loan_detail?.id,
      agency: values.vendor,
      agent: values.agent_name,
      remark: cleanedRemark,
      stage_initiate: "Rcu",
      mail_to: values.mail_to,
      mail_cc: [user_data.email],
      mail_body: mailBody,
      mail_subject: mailSubject,
      attachment: attachments,
      type_of_check: choice,
      sampling_data: sampledata?.customer_document_address_detail?.map((rcue) => {
        // Conditionally handle Customer Profile documents only if choice !== "Document"
        const customerProfileDocs =
          choice !== "Documents"
            ? (rcue?.customer_document?.["Customer Profile"] || []).map((doc) => ({
                customer_id: rcue.customer_name.id,
                rcu_process: values[`document_${rcue.customer_name.id}_${doc.id}`], // Default to "Screen" if no value is set
              }))
            : [];
  
        // Filter out Customer Profile documents from the documents field
        const filteredDocuments = transformDocuments(rcue.customer_document).flatMap(({ keys, docs }) => {
          return docs
            ?.filter((doc) => {
              // Filter out documents associated with "Customer Profile"
              const isCustomerProfileDoc = keys.includes("Customer Profile");
              return !isCustomerProfileDoc;
            })
            .map((doc) => {
              const rcuProcessValue = values[`document_${rcue.customer_name.id}_${doc?.id}_${keys}_${doc.document_type}`];
              if (rcuProcessValue) {
                return {
                  document_id: doc.id,
                  rcu_process: rcuProcessValue,
                };
              }
              return undefined; // Filter out undefined entries
            })
            .filter((doc) => doc !== undefined);
        });
  
        // Return the formatted sampling data
        return {
          customer_id: rcue?.customer_name?.id,
          address: rcue?.customer_address?.map((addr) => ({
            address_id: addr.id,
            rcu_process: values[`address_${addr.id}`],
          })),
          documents: filteredDocuments,
          // Only include `customerProfileDocs` if choice !== "Document"
          customer_profile: customerProfileDocs.length > 0 ? customerProfileDocs : undefined,
        };
      }),
    };
  
    try {
      const response = await postRCUInitiate(formattedValues);
      if (response && response.success) {
        message.success("RCU initiated successfully");
        closeForm();
        form.resetFields();
        setImages([]);
      } else {
        message.error("Failed to update details");
        setImages([]);
        setValue("");
      }
    } catch (error) {
      message.error("Failed to update details due to an error");
      setImages([]);
      setValue("");
      closeForm()
      setLoading(false)
    

    }
  };
  

  const vendorCodeMap = vendors?.reduce((map, vendor) => {
    map[vendor.id] = vendor.code;
    return map;
  }, {});

  const handleSelectChange = (value, dropdownId, customerId, keys, type) => {
    form.setFieldsValue({
      [`document_${customerId}_${dropdownId}_${keys}_${type}`]: value,
      // document_${rcue.customer_name.id}_${doc?.id}
    });

    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
  };

  const handleChange = (id) => {
    const code = vendorCodeMap[id];
    setAgentsselected(code); // Set the code, but you could also store the id if needed
  };

  const transformDocuments = (documents) => {
    if (!documents || typeof documents !== 'object') return [];
    return Object.entries(documents)?.map(([keys, docs]) => ({
      keys,
      docs,
    }));
  };

  const handleAgentChange = (agentId) => {
    if (!agentId) {
      form.setFieldsValue({ mail_to: undefined });
    } else {
      const selectedAgent = agents.find(agent => agent.id === agentId);

      // Update the mail_cc field with the selected agent's email
      if (selectedAgent) {
        form.setFieldsValue({
          mail_to: selectedAgent.email,
        });
      }
    }
  };

  const filteredDocuments = (customerDocument) => {
    return transformDocuments(customerDocument).filter(({ keys }) => {
      if (choice === "Profile") {
        return keys === "Customer Profile";
      } else if (choice === "Documents") {
        return keys !== "Customer Profile";
      } else {
        return true; // For "Both", display all documents
      }
    });
  };

  const closeForms = () => {
    closeForm();
    form.resetFields();
    setImages([]);
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ overflowX: 'hidden' }}
      >

        <div>
          <Checkbox />
          <span className="legal_property_heading">
            &nbsp; Attach Application Form
          </span>
        </div>

        <div style={{ marginTop: "15px" }}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="vendor"
                label="Vendor :"
                required
                rules={[{ required: true, message: "Vendor is required" }]}
              >
                <Select placeholder="Please Select" allowClear onChange={handleChange}>
                  {vendors?.map((vendor) => (
                    <Option value={vendor.id} key={vendor.code}>
                      {vendor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="agent_name"
                label="Agent Name :"
                required
                rules={(!agents || agents.length === 0) ? [] : [{ required: true, message: "Agent Name is required" }]}
              >
                <Select
                  placeholder="Please Select"
                  allowClear
                  onChange={handleAgentChange}
                  disabled={!agents || agents.length === 0}
                >
                  {agents?.map((agent) => (
                    <Option value={agent.id} key={agent.id}>
                      {agent.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="type_of_check"
                label="Type Of Check:"
                required
                rules={[{ required: true, message: "Type Of Check is required" }]}
              >
                <Select
                  placeholder="Please Select"
                  onChange={(value) => setChoice(value)}  // Corrected onChange event
                  allowClear
                >
                  {
                    rcutype.map((choice, index) => (
                      <Option key={index} value={choice}>
                        {choice}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

          </Row>
        </div>

        <div
          className="Address_text"
          style={{
            backgroundColor: "#F1F1F1",
            borderBottom: "1px solid #D9D9D9",
            marginTop: "20px",
          }}
        >
          Address Sampling <span style={{ color: "#E74C3C" }}>*</span>
        </div>
        {modifiedDatas?.map((rcue) =>
          rcue?.customer_address && rcue.customer_address.length > 0 ? (
            <div
              className="rcu_div_container"
              key={rcue?.customer_name?.customer_id}
            >
              <div className="loan_fiinition">
                <div className="loan_fiinition_heading">
                  {rcue?.customer_type?.name} Name:{" "}
                  {`${rcue?.customer_name?.first_name} ${rcue?.customer_name?.middle_name} ${rcue?.customer_name?.last_name}`}
                </div>
                <div className="loan_fiinition_heading">
                  Mobile Number: {rcue?.customer_name?.mobile_number}
                </div>
              </div>

              <div>
                <div className="table_heading">
                  <div className="source-column">Address Type</div>
                  <div className="source-column">
                    Select RCU Process For Address
                  </div>
                  <div className="source_column_two">Address Details</div>
                </div>
                <div>
                  {rcue?.customer_address?.map((addr) => (
                    <div className="table_data" key={addr.id}>
                      <div className="table_data_body">
                        {addr.address_type.name}
                      </div>
                      <div className="table_data_body">
                        <Form.Item
                          name={`address_${addr.id}`}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please Select",
                        //   },
                        // ]}
                        >
                          <Select
                            style={{ width: "175px" }}
                            placeholder="Please Select"
                            allowClear
                          >
                            {rcu?.map((rcus) => (
                              <Option value={rcus.value} key={rcus.value}>
                                {rcus.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="table_data_body_two">
                        <p>
                          {addr.address_line_1}, {addr.address_line_2},{" "}
                          {addr.city.name}, {addr.district.name},{" "}
                          {addr.taluka.name}, {addr.state.name} -{" "}
                          {addr.pincode.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null
        )}

        <div
          className="Address_text"
          style={{
            backgroundColor: "#F1F1F1",
            borderBottom: "1px solid #D9D9D9",
            marginTop: "20px",
          }}
        >
          Documents Sampling <span style={{ color: "#E74C3C" }}>*</span>
        </div> 

        {modifiedDatas && modifiedDatas.map(
          (rcue) =>

            rcue?.customer_document && (

              <div
                className="rcu_div_container"
                key={rcue?.customer_name?.customer_id}
              >
               
                <div className="loan_fiinition">
                  <div className="loan_fiinition_heading">
                    {rcue?.customer_type?.name} Name: {rcue?.customer_name?.first_name}{" "}
                    {rcue?.customer_name?.middle_name}{" "}
                    {rcue?.customer_name?.last_name}
                  </div>
                  {/* <div className="loan_fiinition_heading"> */}
                  {/* Mobile Number: {rcue?.customer_name?.mobile_number} */}
                  <Form.Item
                    name={`dropdown_${rcue.id}`}
                  >
                    <Select
                      style={{ width: "175px" }}
                      placeholder="Please Select"
                      allowClear
                      onChange={(value) => {
                        const updatedFields = {
                          ...form.getFieldsValue(),
                          [`dropdown_${rcue.id}`]: value,

                          ...filteredDocuments(rcue.customer_document).reduce((acc, { docs, keys }) => {
                            docs.forEach((doc) => {
                              acc[`selectDocument_${rcue.customer_name.id}_${doc?.id}`] = value;
                              acc[`document_${rcue.customer_name.id}_${doc?.id}_${keys}_${doc.document_type}`] = value;
                            });
                            return acc;
                          }, {}),
                        };

                        // Set the updated fields in the form
                        form.setFieldsValue(updatedFields);
                      }}
                    >
                      {rcu?.map((rcus) => (
                        <Option value={rcus.value} key={rcus.value}>
                          {rcus.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* </div> */}
                </div>

                <div>
                  {/* <Row>
                    <Col span={6}> */}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="table_data_body">
                      <div className="source-column-div">Group</div>
                    </div>
                    {/* </Col> */}
                    {/* <Col span={6}> */}
                    <div className="table_data_body">
                      <div className="source-column-div">RCU Process</div>
                    </div>
                    {/* </Col> */}
                    {/* <Col span={6}> */}
                    <div className="table_data_body">
                      <div className="source-column-div">Documents</div>
                    </div>
                    {/* </Col> */}
                    {/* <Col span={6}> */}
                    <div className="table_data_body">
                      <div className="source-column-div">RCU Process</div>
                    </div> 
                  </div>
                  {/* </Col>
                  </Row> */}

                  

                  {filteredDocuments(rcue.customer_document).map(
                  
                    ({ keys, docs }) => (
                      <div className="table_data" key={keys}>
                        {/* <Row> */}
                        {/* <Col span={6}> */} 
                        <div className="table_data_body">
                          <div className="keys-column" style={{ marginTop: "-20px" }}>
                            {keys}
                          </div>
                        </div>
                        {/* </Col> */}
                        {/* <Col span={6}> */}
                        <div className="table_data_body">
                          <div className="source-column-div">
                            {docs?.[0]?.group_name}
                            <Col span={6}> 
                              <Form.Item
                                // name={`dropdown_${docs?.[0]?.id}`}
                                name={`selectDocument_${rcue.customer_name.id}_${docs?.[0]?.id}`}
                              >
                                <Select
                                  style={{ width: "175px" }}
                                  placeholder="Please Select"
                                  allowClear
                                  onChange={(value) => {
                                    // Iterate over docs array and update each document
                                    docs.forEach((doc) => {
                                      handleSelectChange(value, doc.id, rcue.customer_name.id, keys, doc.document_type);
                                    });
                                  }}
                                // onChange={(value) => handleSelectChange(value, docs[0]?.id, rcue.customer_name.id)}
                                >
                                  {rcu?.map((rcus) => (
                                    <Option value={rcus.value} key={rcus.value}>
                                      {rcus.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </div>
                        </div>
                        {/* </Col> */}
                        {/* <Col span={6}> */}
                        <div className="table_data_body">
                          <div className="document-image-container">
                            {docs?.map((doc) => {  
                              if (!doc.path) {
                                return (
                                  <div className="document_third" key={doc.id}>
                                    <p>No document available</p>
                                  </div>
                                );
                              }

                              // Extract the file name from the URL
                              const parts = doc.path.split('/');
                              const fileName = parts.pop().split('?')[0]; // Get the last part before the query params
                              const fileExtension = fileName.split('.').pop().toLowerCase();

                              return (
                                <div className="document_third" key={doc.id}>
                                  <div className="img_document">
                                    {/* Conditionally render based on file extension */}
                                    {['png', 'jpg', 'jpeg'].includes(fileExtension) ? (
                                      <>
                                        <RViewerJS>
                                          <img src={doc.path} height={64} width={64} alt="Document" style={{ cursor: "pointer" }} />
                                        </RViewerJS>
                                        <p className="rcu-doc-title">{doc?.document_type || "Customer Profile"}</p>
                                      </>
                                    ) : fileExtension === 'pdf' ? (
                                      <>
                                        <img
                                          src={pdf} // Assuming 'pdf_image' is an imported image placeholder for PDFs
                                          alt="PDF"
                                          height={64}
                                          width={64}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            window.open(
                                              doc.path,
                                              "_blank"
                                            )
                                          }
                                        />
                                        <p className="rcu-doc-title">{doc?.document_type || "Customer Profile"}</p>
                                      </>
                                    ) : (
                                      <>
                                        <FileOutlined style={{ fontSize: '64px' }} />
                                        <p className="rcu-doc-title">{doc?.document_type || "Customer Profile"}</p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* </Col> */}
                        {/* <Col span={6}> */}
                        <div className="table_data_body" 
                        style={{ display: "flex", flexDirection: "column", 
                        justifyContent: "space-evenly" 
                        }}
                        >
                            
                          {
                            docs.map((doc, index) => (
                              <div className="source-column-div">
                           <Col span={6}>
                              <Form.Item
                                // name={`document_${rcue.customer_name.id}_`}
                                name={`document_${rcue.customer_name.id}_${doc?.id}_${keys}_${doc.document_type}`}
                                style={{ width: "175px" }}
                              >
                                <Select
                                  placeholder="Please Select"
                                  allowClear
                                >
                                  {rcu?.map((rcus) => (
                                    <Option value={rcus.value} key={rcus.value}>
                                      {rcus.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              </Col>
                            </div>
                            ))
                          } 
                        </div>
                        {/* </Col> */}
                        {/* </Row> */}
                      </div>
                    )
                  )}
                </div>
              </div>
            )
        )}

        <div className="email_div_container" style={{ margin: "20px" }}>
          <div style={{ width: "100%" }}>
            <div className="content_block">
              <span className="email_heading"><span className='all_required_sign'> *</span>To :</span>
              <span className="email_content">
                <Form.Item
                  name="mail_to"
                >
                  <Input disabled />
                </Form.Item>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading"><span className='all_required_sign'> *</span>CC :</span>
              <span className="email_content">
                <Form.Item name="mail_cc">
                  <Input placeholder={user_data?.email} value={user_data?.email} disabled />
                </Form.Item>
              </span>

            </div>
            <div className="content_block">
              <span className="email_heading">Add CC :</span>
              <span className="email_content"><Form.Item name="add_cc"><Input /></Form.Item></span>

            </div>
            <div className="content_block">
              <span className="email_heading"><span className='all_required_sign'> *</span>Subject :</span>
              <span className="email_content">
                <Form.Item
                  name="mail_subject"
                // rules={[{ required: true, message: "Subject is required" }]}
                >
                  {generateMailSubject()}
                </Form.Item>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">Message :</span>
              <span className="email_content">
                Dear Team,<br />
                Please initiate RCU Search for
                &nbsp;{sampledata?.customer_document_address_detail
                  ?.filter(customer => customer?.customer_type?.name === "Applicant")
                  .map(customer =>
                    `${customer?.customer_name?.first_name || ''} ${customer?.customer_name?.middle_name || ''} ${customer?.customer_name?.last_name || ''}`
                  )
                  .join(', ')
                }
                (Applicant)<br />
                <div className="initiation_form_table_one ">
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Customer Name
                    </div>
                    <div className="initiation_form_table_data_value">
                      {sampledata?.customer_document_address_detail.filter(customer => customer?.customer_type?.name === "Applicant").map(customer => `${customer?.customer_name?.first_name} ${customer?.customer_name?.middle_name} ${customer?.customer_name?.last_name}`)}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      LAN
                    </div>
                    <div className="initiation_form_table_data_value">
                      {sampledata?.loan_detail?.loan_account_number}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Product
                    </div>
                    <div className="initiation_form_table_data_value">
                      {sampledata?.loan_detail?.loan_type?.display_name}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Check Type
                    </div>
                    <div className="initiation_form_table_data_value">{choice}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Property Address
                    </div>
                    <div className="initiation_form_table_data_value">
                      {`${sampledata?.property_detail?.address_line_1}, ${sampledata?.property_detail?.address_line_2}, ${sampledata?.property_detail?.city?.name}, ${sampledata?.property_detail?.district?.name}, ${sampledata?.property_detail?.taluka?.name}, ${sampledata?.property_detail?.state?.name}, ${sampledata?.property_detail?.pincode?.name}`}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Branch
                    </div>
                    <div className="initiation_form_table_data_value">
                      {sampledata?.loan_detail?.location?.display_name}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Contact Person
                    </div>
                    <div className="initiation_form_table_data_value">
                      (Vastu -{sampledata?.loan_detail?.credit_manager?.name}, {sampledata?.loan_detail?.credit_manager?.mobile})<br></br>
                      Customer Details:<br></br>
                      {sampledata?.customer_document_address_detail.filter(customer => customer?.customer_type?.name === "Applicant").map(customer => `${customer?.customer_name?.first_name} ${customer?.customer_name?.middle_name} ${customer?.customer_name?.last_name} (APPLICANT) ${customer?.customer_name?.mobile_number}`)}
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">
                <span className='all_required_sign'> *</span> Remarks:<br />
                (If attached, please mention the list of documents)
              </span>
              <span className="email_content">

                <Form.Item
                  name="remarks"
                  rules={[{ required: true, message: "Remark is required" }]}
                >
                  <ReactQuill theme="snow" value={value} onChange={setValue} />
                </Form.Item>
              </span>
            </div>

            <div className="content_block">
              <span className="email_heading">
                Attachments:<br></br>
              </span>
              {/* <span className="email_content"></span> */}
              <div style={{ marginTop: "15px" }}>
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="files" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginLeft: 10 }}>
                        <Upload
                          beforeUpload={handleBeforeUpload}
                          fileList={fileList}
                          onRemove={handleRemove}
                          showUploadList={false}
                          accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                          listType="picture-card"
                          className="avatar-uploader"
                        >
                          <PlusOutlined />
                        </Upload>
                      </div>
                      <div
                        className="uploaded-files"
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginLeft: "10px"
                        }}
                      >
                        {images?.map((image) => (
                          <div
                            key={image.uid}
                            className="uploaded-file"
                            style={{ position: "relative" }}
                          >
                            <RViewerJS>
                              {image.name.endsWith('.pdf') ? (

                                <img
                                  src={pdf}
                                  height={64}
                                  width={64}
                                  alt={image.file_path}
                                />
                              ) : (
                                // Otherwise, display the image as usual
                                <img
                                  src={image.file_path}
                                  alt={image.name}
                                  height={50}
                                  width={50}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </RViewerJS>
                            <p style={{ textAlign: "center" }}>{image.name}</p>

                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setDeleteUploadPicture(image);
                                setOpenUploadModal(true);
                              }}
                              style={{ marginTop: 5, marginBottom: 5 }}
                            />
                          </div>
                        ))}

                      </div>

                    </div>
                    <Modal
                      title="Confirm Delete"
                      onOk={() => confirmDelete(deleteUploadPicture)}
                      onCancel={() => setOpenUploadModal(false)}
                      open={openUploadModal}
                    >
                      <p>Are you sure you want to delete this file?</p>
                    </Modal>
                  </Col>
                </Row>
              </div>


            </div>
          </div>
        </div>

        <Space
          direction="horizontal"
          align="center"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          <Button type="primary" htmlType="submit"  loading={loading}>
            Initiate
          </Button>
          <Button onClick={closeForms}>Cancel</Button>
        </Space>
      </Form>
    </>
  );
};

export default RCUInitiationForm;

