import React from "react";
import "../../styles/applicantRv1.css";
import ResidentialDetails from "./ResidentialDetails";
import PersonContactedAtResidence from "./PersonContactedAtResidence";
import PersonalDetails from "./PersonalDetails";
import IdentificationDocuments from "./IdentificationDocuments";
import ResidenceNeighborConfirmation from "./ResidenceNeighborConfirmation";
import Vehicle from "./Vehicles";
import AssetSeen from "./AssetSeen";
import Others from "./Others";
import OverallStatus from "./OverallStatus";
import DocumentsRv from "./DocumentsRv";

const ApplicantRV = ({ selectedData, customerDetails, selectedCustomerId }) => {

  return (
    <div className=""style={{margin:'20px'}}>
      <div style={{ margin: "0px" }} className="fi_description_heading">
        <div className="fi_description_data">
        Residence Address : {selectedData.customer_address.full_address}
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {/* {renderEditButton()} */}
          </div>
        </div>
      </div>

      <ResidentialDetails
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId}
      />
      <PersonContactedAtResidence
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId}
      />

      <PersonalDetails
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId}
      />

      <div className="document_container" style={{ display: "flex",marginTop:"20px" }}>
        <IdentificationDocuments
          customerDetails={customerDetails}
          selectedCustomerId={selectedCustomerId}
        />
        <ResidenceNeighborConfirmation
          customerDetails={customerDetails}
          selectedCustomerId={selectedCustomerId}
        />
      </div>

      <Vehicle
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId}
      />
      <AssetSeen
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId}
      />
      <Others
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId}
      />
      <OverallStatus
        customerDetails={customerDetails}
        selectedCustomerId={selectedCustomerId} />

      <DocumentsRv
        selectedCustomerId={selectedCustomerId}
      />

    </div>
  );
};

export default ApplicantRV;
