import React from 'react';
import { Form, Select, Input, Button,Space } from 'antd';

const { TextArea } = Input;

const TechnicalApprovalForm = () => {
  const onFinish = (values) => {
    console.log('Form values:', values);
  };

  return (
    <div style={{marginTop:'5%'}}>
      <Form
        name="technical-approval-form"
        onFinish={onFinish}
         layout="vertical"
        
      >
       
        <Form.Item
          name="approvalStatus"
          label="Approval Status"
          rules={[{ required: true, message: 'Please select an approval status' }]}
          style={{width:'40%'}}
        >
          <Select>
            <Select.Option value="completed">Completed</Select.Option>
            <Select.Option value="rejected">Rejected</Select.Option>
          </Select>
        </Form.Item>
        
        <Form.Item
          name="remarks"
          label="Remark"
          rules={[{ required: true, message: 'Please enter a remark' }]}
        >
          <TextArea rows={4} placeholder="Please write your remark here..." />
        </Form.Item>

     
        <Form.Item>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>

        </Space>
      </Form.Item>
      </Form>
    </div>
  );
};

export default TechnicalApprovalForm;
