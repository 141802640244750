import React, { useEffect, useState } from 'react'
import "./style/LoanSummaryStyle.css"
import RViewerJS from 'viewerjs-react';
import pdf from "./asset/pdf.png";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { Empty } from 'antd';
import Loading from '../loading/Loading';


const LoanSummaryBlock = ({ data, attachments = [], typeCheck }) => {
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(true);

    console.log("Type Check:", typeCheck);


    useEffect(() => {
        // Simulate data loading
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000); // Adjust the time as needed (1000 ms = 1 second)

        return () => clearTimeout(timer);
    }, []);

    const modifiedData = {
        ...data,
        credit_assessment_data: data?.credit_assessment_detail || data?.credit_assessment_data,
    };


    const displayedAttachments = showAll ? attachments : attachments.slice(0, 2);
    const applicant = modifiedData?.customer_detail?.find(
        (customer) => customer.customer_type.name === "Applicant"
    );
    const coApplicants = modifiedData?.customer_detail?.filter(
        (customer) => customer.customer_type.name === "Co-Applicant"
    );

    const GuarantorDetail = modifiedData?.customer_detail?.find(
        (item) => item.customer_type.name === "Guarantor"
    );

    const getRcuDetail = (customer_type) => {
        return modifiedData?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
            (item) => item.customer_type === customer_type
        );
    };
    const rcuDetails = getRcuDetail("Applicant");
    const rcuApplicant = rcuDetails && rcuDetails.length > 0 ? rcuDetails[0] : null;

    const getCoapplicantRcuDetail = (customer_type) => {
        return modifiedData?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
            (item) => item.customer_type === customer_type
        );
    };
    const rcucopplicantDetails = getCoapplicantRcuDetail("Co-Applicant");
    const rcuCoApplicant = rcucopplicantDetails && rcucopplicantDetails.length > 0 ? rcucopplicantDetails[0] : null;



    return (
        <>
            <div>
                <div className="fade-in">
                    {loading ? (
                        <Loading loading={loading} />
                    ) : data ? (
                        <>
                            <div className="Loan_summary_first_container">
                                <div>
                                    <div style={{ color: "#00000073" }}>Loan Number</div>
                                    <div className="loan_summary_second_container_heading_two">
                                        {modifiedData?.loan_detail?.loan_account_number || "-"}

                                    </div>
                                </div>
                                <div>
                                    <div style={{ color: "#00000073" }}>Application Number</div>
                                    <div className="loan_summary_second_container_heading_two">
                                        {modifiedData?.loan_detail?.application_number || "-"}
                                        -
                                    </div>
                                </div>
                                <div>
                                    <div style={{ color: "#00000073" }}>Sales Manager</div>
                                    <div className="loan_summary_second_container_heading_two">
                                        {modifiedData?.loan_detail?.sales_manager?.name || "-"}

                                    </div>
                                </div>
                                <div>
                                    <div style={{ color: "#00000073" }}>Branch</div>
                                    <div className="loan_summary_second_container_heading_two">
                                        {modifiedData?.loan_detail?.location?.display_name || "-"}

                                    </div>
                                </div>
                            </div>

                            <div className="loan_summray_container" style={{ marginTop: "36px" }}>
                                <div className="loan_summary_table_one">
                                    <div className="loan_summary_one_heading">
                                        <div style={{ margin: " 0px 0px 10px 20px" }}>Details</div>
                                    </div>
                                    <div className="loan_summary_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">Agency Name:</div>
                                            <div className="loan_summary_table_data_value">
                                                {modifiedData?.credit_assessment_data?.agency?.name || "-"}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="loan_summary_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">Agent Name:</div>
                                            <div className="loan_summary_table_data_value">
                                                {modifiedData?.credit_assessment_data?.agent?.name || "-"}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="loan_summary_table_data">
                                        {applicant ? (
                                            <>
                                                <div className="loan_summary_table_data">
                                                    <div style={{ marginBottom: "10px", display: "flex" }}>
                                                        <div className="loan_summary_table_data_heading">
                                                            Applicant / Customer:
                                                        </div>
                                                        <div className="loan_summary_table_data_value">
                                                            {`${applicant.first_name} ${applicant.middle_name} ${applicant.last_name}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="loan_summary_table_data">
                                                    <div style={{ marginBottom: "10px", display: "flex" }}>
                                                        <div className="loan_summary_table_data_heading">
                                                            Customer Mobile No. :
                                                        </div>
                                                        <div className="loan_summary_table_data_value">
                                                            {applicant.customer_mobile_number || "-"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        ) : (


                                            // Fallback to RCU details if no applicant found
                                            
                                            rcuApplicant ? (
                                                <>
                                                    <div style={{ marginBottom: "10px", display: "flex"  }}>
                                                        <div className="loan_summary_table_data_heading">
                                                            Applicant / Customer:
                                                        </div>
                                                        <div className="loan_summary_table_data_value">
                                                            {`${rcuApplicant.first_name} ${rcuApplicant.middle_name} ${rcuApplicant.last_name}`}
                                                        </div>
                                                    </div>

                                                    <div style={{ marginBottom: "10px", display: "flex",borderTop:"1px solid #b8b3b373",paddingTop:"4px"  }}>
                                                        <div className="loan_summary_table_data_heading">
                                                            Customer Mobile No. :
                                                        </div>
                                                        <div className="loan_summary_table_data_value">
                                                            {rcuApplicant.mobile_number || "-"}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div>-</div>
                                            )
                                        )}


                                        {coApplicants ? (
                                            <div className="">
                                                <>

                                                    {coApplicants?.map((coApplicant, index) => (
                                                        <div
                                                            key={coApplicant.id}
                                                            className={` ${index !== coApplicants.length - 1 ? "loan_summary_table_data" : ""
                                                                }`}
                                                            style={{ marginBottom: "10px", paddingBottom: "8px", display: "flex" }}
                                                        >
                                                            <div className="loan_summary_table_data_heading">{`Co-Applicant ${index + 1
                                                                }:`}</div>
                                                            <div className="loan_summary_table_data_value">
                                                                {`${coApplicant.first_name} ${coApplicant.middle_name} ${coApplicant.last_name} ` || "-"}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            </div>
                                        ) : (

                                            rcuCoApplicant ? (
                                                <div>
                                                <>
                                                    <div style={{ marginBottom: "10px", display: "flex",borderTop:"1px solid #b8b3b373" }}>
                                                        <div className="loan_summary_table_data_heading" >
                                                            Co-Applicant / Customer:
                                                        </div>
                                                        <div className="loan_summary_table_data_value" >
                                                            {`${rcuCoApplicant.first_name} ${rcuCoApplicant.middle_name} ${rcuCoApplicant.last_name}`}
                                                        </div>
                                                    </div>

                                                    <div style={{ marginBottom: "10px", display: "flex", borderTop:"1px solid #b8b3b373",paddingTop:"4px"  }}>
                                                        <div className="loan_summary_table_data_heading">
                                                            Customer Mobile No. :
                                                        </div>
                                                        <div className="loan_summary_table_data_value">
                                                            {rcuCoApplicant.mobile_number || "-"}
                                                        </div>
                                                    </div>
                                                </>
                                                </div>
                                            ) : (
                                                <div>-</div>
                                            )

                                        )}

                                    </div>


                                    {GuarantorDetail && (
                                        <div className="loan_summary_table_data">
                                            <div style={{ marginBottom: "13px", display: "flex" }}>
                                                <div className="loan_summary_table_data_heading">
                                                    Personal Guarantor:
                                                </div>
                                                <div className="loan_summary_table_data_value">
                                                    {/* {GuarantorDetail?.full_name || "-"} */}
                                                    {`${GuarantorDetail.first_name} ${GuarantorDetail.middle_name} ${GuarantorDetail.last_name}`}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                <div className="Laon_summary_table_two">
                                    <div className="loan_summary_one_heading">
                                        <div style={{ margin: " 0px 0px 10px 20px" }}>Initiated Data</div>
                                    </div>
                                    <div className="loan_summary_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">Vendor Email:</div>
                                            <div className="loan_summary_table_mail_data_value">
                                                {modifiedData?.credit_assessment_data?.mail_to || "-"}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="loan_summary_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">
                                                Credit Manager Name:
                                            </div>
                                            <div className="loan_summary_table_data_value">
                                                {modifiedData?.loan_detail?.credit_manager?.name || "-"}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="loan_summary_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">
                                                Credit Manager Mobile No:
                                            </div>
                                            <div className="loan_summary_table_data_value">
                                                {modifiedData?.loan_detail?.credit_manager?.mobile || "-"}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="loan_summary_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">Initiated On:</div>
                                            <div className="loan_summary_table_data_value">
                                                {modifiedData?.credit_assessment_data?.initiated_at || "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="legal_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_table_data_heading">
                                                Provided Documents:
                                            </div>
                                            <div className="legal_table_data_value">
                                                {`Dear Team, Kindly Initiate ${typeCheck}`}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="loan_summary_img_table_data">
                                        <div style={{ marginBottom: "10px", display: "flex" }}>
                                            <div className="loan_summary_img_data_heading">Attachments: </div>
                                            <div className="loan_summary_img_data_value" style={{ display: "flex", gap: "10px", }}>
                                                {/* {displayedAttachments?.map((item, index) =>
                                    item.file_type === "application/pdf" ? (
                                        <img
                                            src={pdf}
                                            height={50}
                                            width={50}
                                            style={{ border: "none", cursor: "pointer" }}
                                            alt="PDF Preview"
                                            key={index}
                                            className="attachment-img"
                                        />
                                    ) : (
                                        <RViewerJS key={index}>
                                            <img
                                                src={item.attachment}
                                                alt="img"
                                                height={50}
                                                width={50}
                                                style={{ marginRight: "10px" }}
                                            />
                                        </RViewerJS>
                                    )
                                )}
                                {attachments.length > 2 && (
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '24px',
                                            lineHeight: '50px',
                                            padding: '0 10px',
                                            color: '#000'
                                        }}
                                        onClick={() => setShowAll(!showAll)}
                                    >
                                        {showAll ? <DoubleLeftOutlined style={{ color: "blue", fontSize: "15px" }} /> : '...'}
                                    </span>
                                )} */}
                                                {attachments && attachments.length > 0 ? (
                                                    displayedAttachments.map((item, index) =>
                                                        item.file_type === "application/pdf" ? (
                                                            <img
                                                                src={pdf}
                                                                height={50}
                                                                width={50}
                                                                style={{ border: "none", cursor: "pointer" }}
                                                                alt="PDF Preview"
                                                                key={index}
                                                                className="attachment-img"
                                                            />
                                                        ) : item.attachment ? (
                                                            <RViewerJS key={index}>
                                                                <img
                                                                    src={item.attachment}
                                                                    alt="img "
                                                                    height={50}
                                                                    width={50}
                                                                    style={{ marginRight: "10px" }}
                                                                />
                                                            </RViewerJS>
                                                        ) : (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    backgroundColor: '#f0f0f0',
                                                                    textAlign: 'center',
                                                                    lineHeight: '50px',
                                                                    borderRadius: '4px',
                                                                }}
                                                            >
                                                                No Image
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <div> - </div>
                                                )}

                                                {attachments.length > 2 && (
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '24px',
                                                            lineHeight: '50px',
                                                            padding: '0 10px',
                                                            color: '#000',
                                                        }}
                                                        onClick={() => setShowAll(!showAll)}
                                                    >
                                                        {showAll ? <DoubleLeftOutlined style={{ color: "blue", fontSize: "15px" }} /> : '...'}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) :
                        (
                            <div className="no_data_found_message">
                                <Empty />
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}

export default LoanSummaryBlock






