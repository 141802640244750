import { axiosRequest } from '../../../utils/api/axiosRequest';

export const deleteReceipt = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.delete(
                `api/v1/loan/receipt-detail/${id}`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
}

export const getListOfBankBranch = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/bankbranch/list`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getListOfBanks = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/banks/list`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getListOfChargeType = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/charge/list`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getListOfLoan = (text) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/loan/loan-detail/list-loan?search=${text}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getLoanAccountDetail = (text) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/loan/loan-detail/get-loan-account-detail?loan_account_number=${text}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getLoanNumberDetail = (loan_number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/loan/loan-detail/get-loan-account-detail?loan_account_number=${loan_number}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getPaymentMode = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/loan/choices-api/payment-mode-choice`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getReceiptList = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/loan/receipt-detail/`);
            return resolve(response?.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getReceiptDetail = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/loan/receipt-detail/${id}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getReceiptStatus = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`api/v1/loan/choices-api/reciept-status-choice`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getVastuAccount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/vastu-account/list`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const postReceipt = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post(
                `api/v1/loan/receipt-detail/`,
                data
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const updateReceipt = (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(
          `api/v1/loan/receipt-detail/${id}`,
          data
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  