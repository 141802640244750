import React, { useEffect, useState } from "react";
import DashboardLiteLayout from "./DashboardLiteLayout";
import { getEmployeeAssignedWork } from "../service/getEmployeeAssignedWork";
import { useDispatch } from "react-redux";
import { getUser } from "../../dashboard/services/homeService";
import { Modal, message } from "antd";
import { fetchUserLocation, setUser } from "../../../redux/userSlice";

const DashboardLite = () => {
  const [assignedTask, setAssignedTask] = useState([]);
  const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const userRequest = async () => {
        try {
            const response = await getUser();

            if (response.success === true) {
                const userData = response.data;
                dispatch(setUser(userData));

                // Check if location permission has been granted before
                const locationPermission = localStorage.getItem('locationPermission');
                if (!locationPermission) {
                    // Show modal to request location permission
                    setIsModalVisible(true);
                } else if (locationPermission === 'granted') {
                    // Fetch and dispatch location if permission was already granted
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                const { latitude, longitude } = position.coords;
                                dispatch(fetchUserLocation({ latitude, longitude }));
                            },
                            (error) => {
                                message.error("Error fetching location.");
                            }
                        );
                    }
                }
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
        // Request location permission
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch(fetchUserLocation({ latitude, longitude }));
                    // Save permission granted in local storage
                    localStorage.setItem('locationPermission', 'granted');
                },
                (error) => {
                    message.error("Location permission denied.");
                    // Save permission denied in local storage
                    localStorage.setItem('locationPermission', 'denied');
                }
            );
        } else {
            message.error("Geolocation is not supported by this browser.");
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        message.info("Location permission not granted.");
        // Save permission denied in local storage
        localStorage.setItem('locationPermission', 'denied');
    };

    useEffect(() => {
        userRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  useEffect(() => {
    const getEmployeeAssignedWorkList = async () => {
      try {
        const response = await getEmployeeAssignedWork();
        if (Array.isArray(response?.data)) {
          setAssignedTask(response.data);
        } else {
          setAssignedTask([]); // Set to empty array if data is not an array
        }
      } catch (error) {
        console.error("Error fetching assigned tasks list:", error);
        setAssignedTask([]); // Ensure the list is empty if there is an error
      }
    };

    getEmployeeAssignedWorkList();
  }, []);

  return (
  <>
       <div>
            <Modal
                title="Location Permission"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Allow"
                cancelText="Deny"
            >
                <p>We would like to access your location to provide better services. Do you allow us to access your location?</p>
            </Modal>
        </div>
        <DashboardLiteLayout assignedTask={assignedTask} />
  
  </>
  )
};

export default DashboardLite;
