import { axiosRequest } from "../../../utils/api/axiosRequest";


export const RCUPostService = (value) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/loan/rcu-detail/`, value);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  } 


  export const getRCUdetails = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/loan/initiate-stage/get-credit-assessment-data-for-rcu-input-form/${loan_id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getRCUDataByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/loan/rcu-detail/loan/${loan_id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const completeRemark = (loan_id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/loan/rcu-detail/update/${loan_id}`,data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getRCUStatusService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/loan/choices-api/rcu-status-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const patchDedupService = (loan_id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`api/v1/loan/rcu-detail/rcu-customer-detail/${loan_id}`,data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  }; 

  export const patchRCUStatusService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`api/v1/loan/rcu-detail/rcu-document/`,data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  }; 

  export const postDeviationService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`api/v1/loan/rcu-detail/rcu-document/`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  }; 

  export const getDeviationsListService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/deviation/deviation-rcu`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getDeviationDataService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-deviation/${id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const createDeviationService = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post(
                "/api/v1/loan/loan-deviation/",
                data
            );
            return resolve(response.data);
            
        } catch (error) {
            return reject(error);
        }
    });
};

export const updateDeviationDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/loan-deviation/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
   
export const postDocumentsByIdService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`api/v1/loan/rcu-document-upload/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteDeviationServicebyId = (id) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.delete(
              `/api/v1/loan/loan-deviation/${id}`
          );
          return resolve(response.data);
      } catch (error) {
          return reject(error);
      }
  });
};

export const deleteDocumentbyId = (id) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.delete(
              `/api/v1/loan/rcu-document-upload/${id}`
          );
          return resolve(response.data);
      } catch (error) {
          return reject(error);
      }
  });
};

export const getRCUIdbyloanId = (loanid) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.get(
              `/api/v1/loan/rcu-detail/loan/${loanid}`
          );
          return resolve(response.data);
      } catch (error) {
          return reject(error);
      }
  });
};

 



