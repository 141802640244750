import {
  Button,
  Col,
  Form,
  Select,
  Row,
  Upload,
  Space,
  message,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import RViewerJS from "viewerjs-react";
import getDocumentTypeList from "../../services/getDocumentTypeList";
import { createOtherDocumentsByIdServices, deleteOtherDocumentsByIdService } from "../../services/applicantRvServices";
import pdf_image from "../../assets/pdf.png";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const DocumentsOvEditForm = ({
  images,
  closeForm,
  refreshData,
  open,
  selectedCustomerId
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const fetchDocumentType = async () => {
    try {
      const response = await getDocumentTypeList();
      setLegalDocumentType(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    if (open) {
      fetchDocumentType();
    } else {
      form.resetFields(["document_type"]);
      setFileList([]);
      setImage([]);
      setUploadDisabled(true);
    }
  }, [open, form]);

  const handleDocumentNameChange = (value) => {
    // setDocumentName(value);
    setUploadDisabled(!value);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];

    // Assuming 'updatedFileList' is your array and 'form' is your form reference
    updatedFileList = updatedFileList.map((file, index) => {
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          document_type: form.getFieldValue("document_type"),
          file_path: file.originFileObj
            ? file.originFileObj.name
            : file.file_path,
        };
      }
      return file;
    });
    setFileList(updatedFileList);
    form.setFieldsValue({
      document_type: undefined,
      // file_path: undefined,
    });
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };
  const handleSubmit = async (values) => {
    try {
      const propertyDocumentDetail = await Promise.all(
        fileList.map(async (file) => ({
          fi_customer_detail: selectedCustomerId,
          is_identification_doc: false,
          file_path: await fileToBase64(file.originFileObj),
          document_type: file.document_type,
        }))
      );
      const payload = {
        document_path: propertyDocumentDetail,
      };
      const response = await createOtherDocumentsByIdServices(payload);
      if ((response.status = 200 && response.success)) {
        message.success("Dcuments added successfully");
        refreshData();
        closeForm();
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const confirmDelete = () => {

    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteOtherDocumentsByIdService(deletePicture.id);

      if (response?.status === 200 && response?.success === true) {
        message?.success("Documents deleted successfully");
        setOpenModal(false);
        // toggleRefreshTableData();
        // setSelectedPropertyPicture(null);
        refreshData();
      }
    } catch (error) {
      // setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };
  return (
    <Form
      layout="vertical"
      style={{ margin: "10px" }}
      onFinish={handleSubmit}
      form={form}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="document_type"
            label="Enter Document Name"
            required
            rules={[
              {
                required: fileList.length === 0,
                message: "Please select a document name",
              },
            ]}
          >
            <Select
              placeholder="Please select"
              allowClear
              style={{ flex: 1, marginRight: "8px", width: "100%" }}
              showSearch
              onChange={handleDocumentNameChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {legalDocumentType?.map((legaldocumenttype) => (
                <Option
                  key={legaldocumenttype?.id}
                  value={legaldocumenttype?.id}
                >
                  {legaldocumenttype?.display_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="file_path" label=" ">
            <Upload
              single
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}

            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                style={{ width: "176%" }}
                disabled={uploadDisabled}
              >
                Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ margin: "10px 10px 10px 10px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
        {image?.map((picture, index) => (
            <div key={index} style={{ margin: "10px", position: "relative" }}>
              <RViewerJS images={[picture.file_path]}>
                <img
                  src={picture.file_path}
                  alt=""
                  style={{ width: "100px", height: "100px", cursor: "pointer" }}
                />
              </RViewerJS>

              <div
                style={{
                  top: 0,
                  right: 0,
                  color: "#E5233DB2",
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "10px",
                }}
                onClick={() => {
                  setDeleteUploadPicture(picture);
                  setOpenUploadModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="image-gallery">
        {images?.map((picture, index) => (
          <div key={index} className="image-container">
            <RViewerJS images={[picture.document_path]}>
              {picture.file_type === "application/pdf" ? (
                <img
                  src={pdf_image}
                  width="100px"
                  height="100px"
                  style={{ border: "none", cursor: "pointer" }}
                  alt="PDF Preview"
                />
              ) : (
                <img
                  src={picture.document_path}
                  alt="Preview"
                  style={{ width: "100px", height: "100px", cursor: "pointer" }}
                />
              )}
            </RViewerJS>

            <h4 style={{ textAlign: "center", color: "#1890FF" }}>
              {picture.document_type.display_name}
            </h4>
            <div
              className="delete-icon"
              onClick={() => {
                setDeletePicture(picture);
                setOpenModal(true);
              }}
            >
              <DeleteOutlined />
            </div>
          </div>
        ))}
      </div>
      <Modal
        title="Confirm Delete"
        open={openUploadModal}
        onOk={confirmDelete}
        onCancel={() => {
          setOpenUploadModal(false);
          setDeleteUploadPicture(null);
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this upload image?`}</p>
      </Modal>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={deletePropertyPictures}
        onCancel={() => {
          setOpenModal(false);
          setDeletePicture(null);
        }}
        okType="danger"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>

      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "20px",
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default DocumentsOvEditForm;
