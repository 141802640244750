import React, { useEffect, useState } from "react";
import verify from "../assets/Group.png";
import "../../style/customerStyle.css";
import add from "../assets/Add.png";
import { Drawer, Empty, Modal, message } from "antd";
import Header from "../../../../layout/views/Header";
import ExistingLoanDetailsForm from "./ExistingLoanDetailsForm";
import { deleteExistingLoanDetailsByIdService, getExistingDetailsByIdService } from "../services/existingDetailsServices";
import { useParams } from "react-router-dom";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";

const ExistingLoanDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState();
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [bankAccountData, setBankAccountData] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }


  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
    setSelectedPerson(null);
    setSelectedRecord(null);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getExistingDetailsByIdService(loan_id);
        setBankAccountData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, loan_id]);

  const renderEditButton = (showDrawer, id, person) => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
          setSelectedCustomer(id);
          setSelectedPerson(person);
        }}
      >
        <EditButton />
      </div>
    );
  };

  const Headings = [
    "Key Parameter's",
    "Existing Loan Details",
    "Loan Purpose",
    "Sanctioned Amount",
    "Outstanding Amount",
    "Sanctioned Date",
    "Maturity Date",
    "EMI Amount",
    "ROI",
    "Loan Status",
    "Consider Obligation",
    "Source Of Liability",
    "Financial Institution",
  ];

  const fieldNameMap = {
    "loan_purpose": "Loan Purpose",
    "sanctioned_amount": "Sanctioned Amount",
    "outstanding_amount": "Outstanding Amount",
    "sanctioned_date": "Sanctioned Date",
    "maturity_date": "Maturity Date",
    "emi_amount": "EMI Amount",
    "roi": "ROI",
    "loan_status": "Loan Status",
    "consider_obligation": "Consider Obligation",
    "source_of_liability": "Source Of Liability",
    "financial_institutions": "Financial Institution",
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return 'tab_form_main_data_two_entries';
      case 3:
        return 'tab_form_main_data_three_entries';
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return '';
    }
  };

  const deleteRecord = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteExistingLoanDetailsByIdService(selectedRecord);
      if (response.status === 200 && response.success === true) {
        message.success("Existing loan details deleted successfully");
        toggleRefreshTableData();
        setSelectedRecord(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };
  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              name={selectedPerson ? `${selectedPerson.customer_name}’s Existing Loan Details` : `${selectedPerson?.customer_name}’s Existing Loan Detail`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <ExistingLoanDetailsForm
            refreshData={toggleRefreshTableData}
            id={selectedCustomer}
            customer_id={selectedCustomerId}
            open={open}
            closeForm={onClose}
          />
        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : bankAccountData?.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">{Headings[0]}</th>
                  {bankAccountData?.results.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            <img src={verify} alt="group" style={{ marginLeft: "5px" }} />

                          </span>
                        </div>
                        <div
                          className="tab_form_sub_edit"
                          onClick={() => {
                            showDrawer();
                            setSelectedCustomerId(person.customer_id)
                            setSelectedPerson(person)
                          }}
                        >
                          <img src={add} alt="Add Button" />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bankAccountData &&
                  [...Array(bankAccountData.max_count)].map((_, rowIndex) =>
                    Headings.slice(1).map((header, colIndex) => (
                      <tr
                        className={
                          header === "Existing Loan Details"
                            ? "bank-row"
                            : header === "PD Entries"
                              ? "bank-pd-row"
                              : ""
                        }
                      >
                        <td className="tab_form_left_heading">
                          {header === "Existing Loan Details"
                            ? `Existing Loan Details ${rowIndex + 1}`
                            : header}
                        </td>
                        {bankAccountData?.results.map((person, colIndex) => (
                          <td
                            className={`tab_form_main_data_table ${getTableColumnClass(bankAccountData.results.length)}`}
                          >
                            {person?.existing_loan_details &&
                              person?.existing_loan_details[rowIndex] ? (
                              <>
                                {header === "Loan Purpose"
                                  ? person.existing_loan_details[rowIndex]
                                    ?.loan_purpose?.name || "-"
                                  : header === "Sanctioned Amount"
                                    ? `₹ ${IndianNumberFormat(person.existing_loan_details[rowIndex]
                                      ?.sanctioned_amount)}` || "-"
                                    : header === "Outstanding Amount"
                                      ? `₹ ${IndianNumberFormat(person.existing_loan_details[rowIndex]
                                        ?.outstanding_amount)}` || "-"
                                      : header === "Sanctioned Date"
                                        ? person.existing_loan_details[rowIndex]
                                          ?.sanctioned_date || "-"
                                        : header === "Maturity Date"
                                          ? person.existing_loan_details[rowIndex]
                                            ?.maturity_date || "-"
                                          : header === "EMI Amount"
                                            ? `₹ ${IndianNumberFormat(person.existing_loan_details[rowIndex]
                                              ?.emi_amount)}` || "-"
                                            : header === "ROI"
                                              ? `${person.existing_loan_details[rowIndex]
                                                ?.roi} %` || "-"
                                              : header === "Loan Status"
                                                ? person.existing_loan_details[rowIndex]
                                                  ?.loan_status?.name || "-"
                                                : header === "Consider Obligation"
                                                  ? person.existing_loan_details[rowIndex]
                                                    ?.consider_obligation
                                                    ? "Yes"
                                                    : "No" || "-"
                                                  : header === "Source Of Liability"
                                                    ? person.existing_loan_details[0]
                                                      ?.source_of_liability || "-"
                                                    : header === "Financial Institution"
                                                      ? person.existing_loan_details[0]
                                                        ?.financial_institutions?.name || "-"
                                                      : ""}
                                {header === "Existing Loan Details" && (
                                  <div style={{ display: "flex", justifyContent:"flex-end" }}>
                                     <div
                                    onClick={() => {
                                      setSelectedRecord(person.existing_loan_details[rowIndex].id);
                                      setOpenModal(true);
                                    }}>

                                    <DeleteButton />
                                  </div>
                                    {person?.existing_loan_details[rowIndex]?.created_at &&
                                      person?.existing_loan_details[rowIndex]?.modified_at &&
                                      isModifiedAfterCreated(
                                        person?.existing_loan_details[rowIndex]?.created_at,
                                        person?.existing_loan_details[rowIndex]?.modified_at
                                      ) && (
                                        <HistoryTable
                                          id={(person?.existing_loan_details[rowIndex]?.id)}
                                          bench_id={"99ef8ca0d0318d21"}
                                          fieldNameMap={fieldNameMap}
                                        />
                                      )}
                                    <ConditionalRender
                                      userPermissions={user_data?.permissions}
                                      requiredPermissions={["MPITDE9"]}
                                    >
                                      {renderEditButton(
                                        showDrawer,
                                        person.existing_loan_details[rowIndex].id,
                                        person
                                      )}
                                    </ConditionalRender>

                                  </div>
                                )}
                              </>
                            ) : header === "Existing Loan Details" ? (
                              ""
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRecord();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default ExistingLoanDetails;
