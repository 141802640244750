import React, { useState, useEffect } from "react";
// import {
//   MenuFoldOutlined,
//   MenuUnfoldOutlined,
// } from "@ant-design/icons";
import {
  LoginOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Dropdown, message } from "antd";
import { Outlet, Link, useLocation } from "react-router-dom";
// import sibebarlogo from "../assets/sidebarlogo.png";
import Ellips from "../assets/Ellipse.png";
import Arrow from "../assets/Group_arrow.png";
import User from "../assets/user.png";
import vastologo from "../assets/vastulogo.png";
import DashboardIcon from "../assets/Dashboard Icon.svg";
import AdminIcon from "../assets/Admin Icon.svg";
import HRIcon from "../assets/HR Icon.svg";
import "../styles/myLayout.css";
import { Footer } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
import CreditIcon from "../assets/Credit.svg";
import AccountsIcon from "../assets/account.svg";
import OperationsIcons from "../assets/Operations.svg";
import SubMenuIcon from "../assets/Sub menu icon.svg"
import { decrypt, encrypt } from "../../../utils/cryptoUtils/cryptoUtils"
import { useParams } from 'react-router-dom';
function getItem(label, path, key, icon, children, requiredPermissions) {
  return {
    key,
    icon,
    children,
    label,
    path,
    requiredPermissions,
  };
}


const { Header, Content } = Layout;

const menu = (
  <Menu className="menu_list_container">
    {/* <Space key="1" className="menu_item_user">
      <img
        src={anurag}
        alt="user"
        style={{
          borderRadius: "50%",
          width: "30px",
          height: "30px",
        }}
      />
      <p>Anurag</p>
    </Space> */}

    {/* <Menu.Item key="2" className="menu_item">
      <UserOutlined /> <span>Profile</span>
    </Menu.Item>

    <Menu.Item key="3" className="menu_item">
      <SettingOutlined /> <span>Setting</span>
    </Menu.Item>

    <Menu.Item key="4" className="menu_item">
      <WechatOutlined /> <span>Chat</span>
    </Menu.Item>
    <Menu.Item key="5" className="menu_item">
      <QuestionCircleOutlined />
      <span>FAQ</span>
    </Menu.Item> */}

    <Menu.Item
      key="1"
      className="menu_item"
      onClick={() => {
        localStorage.clear();
        window.location.reload();
      }}
    >
      <LoginOutlined /> <span>LogOut</span>
    </Menu.Item>
  </Menu>
);

const notification = (
  <Menu className="menu_notification_container">
    <div className="main_header">
      <h4>Notification</h4>
      <p className="heading">View All</p>
    </div>
    <Menu.Item key="1" className="notification_item">
      <div className="image_heading">
        <img
          className="image"
          src={Ellips}
          alt="user"
          style={{
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            top: "-470px",
            left: "4856px",
          }}
        />
        <span className="icon_heading">Synced - VL00000030052</span>
      </div>
      <p className="center_paragraph">
        VL00000030052 Case has been synced <br /> successfully by Vishal Yadav
      </p>
    </Menu.Item>

    <Menu.Item key="2" className="notification_item">
      <img
        className="image"
        src={Arrow}
        alt="user"
        style={{
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          top: "-470px",
          left: "4856px",
        }}
      />{" "}
      <span icon_heading>Send Back - VL00000030052</span>
      <p className="center_paragraph">
        VL00000030052 Case has been sent back to <br /> you by Shiva Aiyar
      </p>
    </Menu.Item>

    <Menu.Item key="3" className="notification_item">
      <img
        className="image"
        src={User}
        alt="user"
        style={{
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          top: "-470px",
          left: "4856px",
        }}
      />{" "}
      <span icon_heading>Accept Case</span>
      <p className="center_paragraph">
        VL00000030052 Case has been <br /> assigned to you by Shiva Aiyar
      </p>
    </Menu.Item>

    <div className="notification_btn">
      <button className="accept_button">Accept</button>
      <button className="declient_button">Declient</button>
    </div>
  </Menu>
);

const MyLayout = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  // const [collapsed, setCollapsed] = useState(true);


  const { encrypted_stage_id } = useParams();


  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
    console.log("stage", stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }



  const items = [
    getItem(
      "Dashboard",
      "/",
      "1",
      <span>
        <img src={DashboardIcon} alt="Dashboard" className="layout-icons" />
      </span>,
      [
        getItem(
          "Dashboard",
          "/",
          "dashboard1",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />

        ),
        getItem(
          "Business Dashboard",
          "/maintenance",
          "dashboard2",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
    getItem(
      "Admin",
      "/",
      "sub1",
      // <img src={AdminIcon} alt="Admin" className="layout-icons" />,
      <span>
        <img src={AdminIcon} alt="Dashboard" className="layout-icons" />
      </span>,

      [
        getItem(
          "Master",
          "/master",
          "subadmin1",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "User",
          "/users",
          "sub2",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
    getItem(
      "Credit",
      "/",
      "credit",
      // <img src={CreditIcon} alt="Credit" className="layout-icons" />,
      <span>
        <img src={CreditIcon} alt="Credit" className="layout-icons" />
      </span>,
      [
        getItem(
          "Loan Detail",
          "/applications",
          "13",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Part Disbursal",
          "/part_disbursed",
          "part14",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Adhoc",
          "/adhoc",
          "adhoc",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
    getItem(
      "Operations",
      "/",
      "operations",
      // <img src={OperationsIcons} alt="Operations" className="layout-icons" />,
      <span>
        <img src={OperationsIcons} alt="Operations" className="layout-icons" />
      </span>,
      [
        getItem(
          "OTC/PDD",
          "/otc_pdd",
          "15",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "NACH",
          "/maintenance",
          "16",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          [
            getItem(
              "NACH",
              "/nach",
              "17",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
            getItem(
              "Not Checked",
              "/not_checked",
              "18",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
            getItem(
              "In Progress",
              "/in_progress",
              "19",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
          ]
        ),
      ]
    ),
    getItem(
      "All Stages",
      "/all_stage",
      "all_stage",
      // <img src={OperationsIcons} alt="Operations" className="layout-icons" />,
      <span>
        <img src={OperationsIcons} alt="all" className="layout-icons" />
      </span>,
      [
        getItem(
          "FI",
          `applications/${encrypt((7).toString())}`,
          "25",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,

        ),
        getItem(
          "Lead",
          `applications/${encrypt((1).toString())}`,
          "26",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "QC",
          `applications/${encrypt((1).toString())}`,
          "27",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "PD",
          `applications/${encrypt((3).toString())}`,
          "28",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "legal",
          `applications/${encrypt((4).toString())}`,
          "29",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Technical",
          `applications/${encrypt((5).toString())}`,
          "30",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "RCU",
          `applications/${encrypt((6).toString())}`,
          "31",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Rejected",
          `applications/${encrypt((9).toString())}`,
          "32",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "CAM",
          `applications/${encrypt((9).toString())}`,
          "33",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),

      ]
    ),
    getItem(
      "Account and Finance",
      "/",
      "Account",
      // <img src={AccountsIcon} alt="Accounts" className="layout-icons" />,
      <span>
        <img src={AccountsIcon} alt="Account" className="layout-icons" />


      </span>
      ,
      [
        getItem(
          "Receipt",
          "/receipt",
          "14",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
    getItem(
      "HR",
      "/",
      "sub4",
      // <img src={HRIcon} alt="HR" className="layout-icons" />,
      <span>
        <img src={HRIcon} alt="Dashboard" className="layout-icons" />
      </span>,
      [
        getItem(
          "Digital ID",
          "/id-card",
          "id-card",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
  ];

  const generateMenuItems = (menuData) => {
    return menuData.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            icon={item.icon}
            title={item.label}
          >
            {generateMenuItems(item.children)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          <Link to={item.path}>{item.label}</Link>
        </Menu.Item>
      );
    });
  };



  const getDefaultSelectedKey = () => {
    const storedKey = localStorage.getItem("selectedKey");
    if (storedKey) {
      return storedKey;
    }
    return "1";
  };
  const [selectedKey, setSelectedKey] = useState(getDefaultSelectedKey());

  const { user_data } = useSelector((state) => state.user);

  useEffect(() => {
    const pathname = location.pathname;
    const matchingItem = findMenuItemByPathname(pathname, items);

    if (matchingItem) {
      setSelectedKey(matchingItem.key);
      if (matchingItem.label === "Dashboard") {
        document.title = `Dashboard | LMS`;
      } else {
        document.title = `Manage ${matchingItem.label}s | LMS`;
      }
    } else {
      document.title = "Vastu | LMS";
    }

    localStorage.setItem("selectedKey", selectedKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleMenuItemSelect = ({ key }) => {
    const matchingItem = findMenuItemByKey(key, items);
    if (matchingItem) {
      setSelectedKey(matchingItem.key);
    }
  };
  function findMenuItemByKey(key, menuData) {
    for (const item of menuData) {
      if (item.key === key) {
        return item;
      } else if (item.children) {
        const subItem = findMenuItemByKey(key, item.children);
        if (subItem) {
          return subItem;
        }
      }
    }
    return null;
  }
  function findMenuItemByPathname(pathname, menuData) {
    for (const item of menuData) {
      if (item.path === pathname) {
        return item;
      } else if (item.children) {
        const subItem = findMenuItemByPathname(pathname, item.children);
        if (subItem) {
          return subItem;
        }
      }
    }
    return null;
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear local storage or perform any other necessary actions
      // localStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // const handleBeforeUnload = (event) => {

  //   const userLoggedIn = localStorage.getItem("userLoggedIn");
  //   if (!userLoggedIn) {
  //     // Clear local storage or perform any other necessary actions
  //     localStorage.clear();

  //     // Optionally, you can display a warning message to the user
  //     // event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
  //     window.location.href = "/login";
  //   }
  // };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        className="header_container"
        style={{
          padding: 0,
          background: colorBgContainer,
          height: "48px",
          borderBottom: "1px solid hsla(0, 0%, 0%, 0.06)",
          display: "flex",
          // alignItems: "center",
          justifyContent: "space-between",
          lineHeight: "48px"
        }}
      >
        <div style={{ width: "75%", display: "flex", marginRight: "40px" }}>

          <Link to="/">
            <div>
              <img
                style={{
                  marginLeft: "25px",
                  width: "25px",
                  height: "auto",
                  marginBottom: "10px"
                }}
                src={vastologo}
                alt="homelogo"
              />
            </div>
          </Link>
          {user_data && (
            <Menu
              style={{
                borderRadius: "0px",
                border: "none",
                display: 'flex',
                flexWrap: "wrap",
                marginLeft: "10px",
                minWidth: "200px",
                width: "100%",
                flexGrow: 1,
                flexShrink: 0,
              }}
              theme="light"
              selectedKeys={[selectedKey]}
              mode="horizontal"
              onSelect={handleMenuItemSelect}
            >
              {generateMenuItems(items, user_data?.permissions)}
            </Menu>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "2.5rem",
          }}
        >
          <Dropdown overlay={notification} trigger={["click"]}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Badge
                    count={6}
                    overflowCount={10}
                    size="small"
                    style={{ marginRight: "10px" }}
                  >
                    <BellOutlined
                      style={{
                        fontSize: "1rem",
                        marginRight: "1rem",

                      }}
                    />
                  </Badge> */}
            </div>
          </Dropdown>

          <Dropdown overlay={menu} trigger={["click"]}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {user_data?.profile_img ? (
                <img
                  src={user_data?.profile_img}
                  alt="user"
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    marginLeft: "10px",
                  }}
                />
              ) : (
                <div
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#802C2C",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#f0f0f0",
                    }}
                  >
                    {user_data?.name ? user_data.name.charAt(0).toUpperCase() : ""}
                  </span>
                </div>
              )}
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {user_data?.name}
              </span>
            </div>
          </Dropdown>
        </div>
      </Header>

      <Layout>
        <Content
          style={{
            padding: "20px",
            minHeight: 280,
            background: colorBgContainer,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "right",
            padding: "5px",
            background: "#fff",
          }}
        >
          © {currentYear} Vastu Housing Finance Corporation ltd.
        </Footer>
      </Layout>
    </Layout>

  );
};

export default MyLayout;
