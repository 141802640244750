import React, { useState } from 'react';
import LogTable from './LogTable';
import { Button, Modal, message, Skeleton } from 'antd';
import { getCustomerHistoryData } from '../services/historyTableServices';
import ErrorMessage from '../../../../utils/errorHandling/ErrorMessage';
import HistoryImage from "../assets/HistoryImage.png";

const HistoryTable = ({ id, bench_id, fieldNameMap }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomerHistory, setSelectedCustomerHistory] = useState(null);
  const [loading, setLoading] = useState(false);

  const showModal = async (customerId) => {
    setIsModalOpen(true);
    setLoading(true);
    try {
      const response = await getCustomerHistoryData(bench_id, customerId);
      setSelectedCustomerHistory(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedCustomerHistory(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCustomerHistory(null);
  };

  return (
    <>
      <img
        src={HistoryImage}
        alt="History Icon"
        style={{ margin: '0px 5px', cursor: "pointer", height: "27px" }}
        onClick={() => showModal(id)}
        title="View History"
      />

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        width={900}
      >
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 5 }}
          title={false}
        >
          <LogTable data={selectedCustomerHistory} fieldNameMap={fieldNameMap} />
        </Skeleton>
        <Button onClick={handleOk} style={{ margin: "20px 0px 0px 92.3%" }}>Close</Button>
      </Modal>
    </>
  );
};

export default HistoryTable;
