import React, { useState } from "react";
import { Space, Button, Modal } from "antd";
import { PlusCircleOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Typography, Drawer, message } from "antd";
import Header from "../../layout/views/Header";
import { Link } from "react-router-dom";
import DataTable from "../../../utils/dataTable/DataTable";
import IdCardForm from "./IdCardForm";
import "../styles/idcard.css"
import { deleteIDCardDetailsServicebyId } from "../services/idcardService"
import { encrypt } from "../../../utils/cryptoUtils/cryptoUtils";
/**
 * User view.<EyeOutlined />
 * @returns React component.
 */
const IdCard = () => {
  const { Title } = Typography;

  const [Users, setUsers] = useState([]);

  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [deleteValue, setDeleteValue] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeleteValue(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const onClose = () => {
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
    setOpen(false);
  };

  const closeDrawer = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "employee_name",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      sorter: true,
    },
    {
      title: "Blood Group",
      dataIndex: "blood_group",
      sorter: true,
    },
    {
      title: "Date Of Birth",
      dataIndex: "date_of_birth",
    },
    {
      title: "Contact Number",
      dataIndex: "mobile_number",
      sorter: true,
    },
    {
      title: "Emergency Number",
      dataIndex: "emergency_number",
      sorter: true,
    },
    {
      title: "Organization",
      dataIndex: "organization",
      sorter: true,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      sorter: true,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: true,
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        const encryptedEmployeeId = encrypt(record.employee_id.toString());
        return (
          <Space size="middle">
            <Link to={{
              pathname: `/id-card/id-card-detail/${record.organization}/${encryptedEmployeeId}`
            }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeOutlined />
            </Link>
            <EditOutlined
              onClick={() => {
                setSelectedUser(record);
                setSelectedUserId(record.id);
                showDrawer();
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setSelectedUser(record);
                setDeleteValue(record);
                setIsModalOpen(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const deleteUserById = async () => {
    try {
      const response = await deleteIDCardDetailsServicebyId(deleteValue.id);
      if (response.status === 200 && response.success === true) {
        message.success("ID Card deleted successfully");
        setIsModalOpen(false);
        toggleRefreshTableData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const DeleteModalFooter = ({ onCancel }) => (
    <>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" danger onClick={deleteUserById}>
        Delete
      </Button>
    </>
  );

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          ID Card
        </Title>
        <Button
          onClick={showDrawer}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          Add ID Card
        </Button>
      </Space>

      <DataTable
        apiPath="/api/v1/identity-card/get-id-details"
        tableData={Users}
        setTableData={setUsers}
        tableColumns={columns}
        refreshData={refreshTableData}
        searchPlaceholder={"Search ID Card"}
      />
      <Drawer
        title={
          <Header
            type={selectedUser ? "edit" : "add"}
            onClose={onClose}
            name="ID Card Details"
          />
        }
        width={720}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
        closable={false}
      >
        <IdCardForm
          refreshData={toggleRefreshTableData}
          id={selectedUserId}
          open={open}
          closeDrawer={closeDrawer}
          closeForm={onClose}
          onCancel={onClose}
          setId={setSelectedUserId}
        />
      </Drawer>
      <Modal open={isModalOpen} onCancel={handleCancel} footer={<DeleteModalFooter onCancel={handleCancel} />}>
        <p className="modal-paragraph">{`Are you sure you want to delete ${deleteValue?.employee_name}`}</p>
      </Modal>
    </div>
  );
};

export default IdCard;

