import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getAccessibilityChoice
} from "../../services/getCustomerDetailData";
import { updateOthersDetails } from "../../services/applicantRvServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const OthersEditForm = ({ customerDetails, closeForm, updateId, open, refreshData }) => {
  const [form] = Form.useForm();
  const [accessibility, setAccessibility] = useState([])
  const { Option } = Select;


  const validationSchema = Yup.object().shape({
    name_plate: Yup.string()
      .required("Name plate is required"),
      society_board: Yup.string()
      .required("Society board is required"),
      is_negative_area: Yup.string()
      .required("Is negative area is required"),
      has_political_contact: Yup.string()
      .required("Has political contact is required"),
      accessibility: Yup.string()
      .required("Accessibility is required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getAccessibilityChoice, setAccessibility);
  }, []);
  const getData = async () => {
    if (updateId) {
      try {
        const {
          name_plate,
          society_board,
          is_negative_area,
          has_political_contact,
          accessibility
        } =
          customerDetails;
        form.setFieldsValue({
          name_plate,
          society_board,
          is_negative_area,
          has_political_contact,
          accessibility
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  const handleSubmit = async (values) => {
    try {
      if (updateId) {
        const response = await updateOthersDetails(updateId, values);
        if ((response.status = 200 && response.success)) {
          message.success("Others details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="name_plate" label="Name Plate" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Name Plate"
                onChange={(e) => handleInput(e, "name_plate")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="society_board" label="Society Board" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Society Board"
                onChange={(e) => handleInput(e, "society_board")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="is_negative_area" label="Negative Area" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="has_political_contact"
              label="Political Contacts"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="accessibility" label="Accessibility" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {accessibility?.map((access) => <Option value={access}>{access}</Option>)}

              </Select>


            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default OthersEditForm;
