import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, message, Select } from "antd";
import {
  getBasicDetailsByIdService,
  updateBasicDetailsByIdService,
  listLoanPurposeService,
  listLoanTypeService,
  getListSchemeService,
  listLocationService,
  getProductEndUseService,
  // getSPOCLocationService,
  getProductService,
  getDueDateService,
  getInterestTypeService,
} from "../services/basicServices";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
// const { Option } = Select;


const validationSchema = Yup.object().shape({
  application_number: Yup.string()
    .required("Application Number is required")
    .min(15, "Application Number must be at exact 15 characters")
    .max(15, "Application Number must be at exact 15 characters")
    .label("Application Number"),
  loan_account_number: Yup.string()
    .required("Loan Account Number is required")
    .min(15, "Loan Account Number must be at exact 15 characters")
    .max(15, "Loan Account Number must be at exact 15 characters")
    .label("Loan Account Number"),
  requested_amount: Yup.string()
    .required("Requested amount is required")
    .test("is-number", "Requested amount must contain only digits", (value) => {
      return /^[0-9.]+$/.test(value);
    })
    .label("Requested Amount"),
    processing_Fee: Yup.string()
    .required("Processing fee is required")
    .test("is-number", "Processing fee must contain only digits", (value) => {
      return /^[0-9.]+$/.test(value);
    })
    .label("Requested Amount"),

  requested_tenure: Yup.string()
    .required("Requested tenure is required")
    .test("is-number", " Requested Tenure  (In Month) must contain only digits", (value) => {
      return /^[0-9]+$/.test(value);
    })
    .test("min-max-range", " Requested Tenure  (In Month) must be between 0 and 360 Months.", (value) => {
      const numericValue = parseInt(value, 10);
      return numericValue >= 0 && numericValue <= 360;
    })
  ,

  scheme: Yup.string()
    .required("Scheme is required")
    .label("Scheme"),
  loan_type: Yup.string()
    .required("Loan type is required")
    .label("Loan Type"),
  loan_purpose: Yup.string()
    .required("Loan purpose is required")
    .label("Loan Purpose"),
  location: Yup.string()
    .required("Location is required"),
  product: Yup.string()
    .required("Product is required"),
  product_end_use: Yup.string()
    .required("End use is required"),
  expected_roi: Yup.string()
    .required("Expected ROI is required"),
  emi_due_date: Yup.string()
    .required("Due date is required"),
  interest_type: Yup.string()
    .required("Interest type is required"),
  approved_amount: Yup.string()
    .required("Approved amount is required")
    .test("is-number", "Approved amount must contain only digits", (value) => {
      return /^[0-9.]+$/.test(value);
    })
    .test("min-max-range", "Approved amount must be between 1,00,000 to 1,00,00,000.", (value) => {
      const numericValue = parseInt(value, 10);
      return numericValue >= 100000 && numericValue <= 10000000;
    })
    .label("Approved Amount"),
  approved_roi: Yup.string()
    .required("Approved roi is required")
    .test("is-number", "Approved roi must contain only digits", (value) => {
      return /^[0-9.]+$/.test(value);
    })
    .test("min-max-range", "Approved roi must be between 1 to 99.", (value) => {
      const numericValue = parseInt(value, 10);
      return numericValue >= 1 && numericValue <= 99;
    })
    .label("Approved ROI"),

  approved_tenure: Yup.string()
    .required("Approved tenure is required")
    .test("is-number", "Approved tenure must contain only digits", (value) => {
      return /^[0-9]+$/.test(value);
    })
    .test("min-max-range", "Approved Tenure   (In Year) must be between 0 and 30 years.", (value) => {
      const numericValue = parseInt(value, 10);
      return numericValue >= 0 && numericValue <= 30;
    })
    .label("Approved Tenure (In Year)"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const BasicDetailsForm = ({ refreshData, id, open, closeForm ,onRefresh}) => {
  const [form] = Form.useForm();
  const [allLoanPurpose, setAllLoanPurpose] = useState([]);
  const [locationType, setLocationType] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [schemeType, setScheme] = useState([]);
  const [productEndUSe, setProductEndUse] = useState([]);
  // const [spocLocation, setSpocLocation] = useState([]);
  const [product, setProduct] = useState([]);
  const [dueDate, setDueDate] = useState([]);
  const [interestType, setInterestType] = useState([]);
  const [loanid, setLoanid] = useState(0)
  const [loading, setLoading] = useState(false);
 const getDepartmentData = async () => {
    if (id) {
      try {
        const response = await getBasicDetailsByIdService(id);
        const {
          application_number,
          loan_account_number,
          requested_amount,
          requested_tenure,
          scheme,
          loan_type,
          loan_purpose,
          approved_amount,
          approved_roi,
          location,
          approved_tenure,
          emi_due_date,
          product,
          product_end_use,
          expected_roi,
          interest_type,
          processing_fee
        } = response?.data;
        setLoanid(response?.data?.loan_type?.id)
        form.setFieldsValue({
          application_number,
          loan_account_number,
          requested_amount,
          requested_tenure,
          scheme: scheme.id,
          loan_type: loan_type.id,
          loan_purpose: loan_purpose.id,
          approved_amount,
          approved_roi,
          location: location.id,
          approved_tenure,
          emi_due_date,
          product: product?.id,
          product_end_use: product_end_use?.id,
          expected_roi,
          interest_type,
          processing_fee
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };


  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z0-9\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleSubmit = async (values) => {
    // values.approved_roi = parseFloat(values.approved_roi).toFixed(2);
    values.approved_roi = parseFloat(values.approved_roi);
    values.requested_tenure = parseInt(values.requested_tenure);
    values.approved_amount = parseInt(values.approved_amount);
    values.requested_amount = parseInt(values.requested_amount);
    values.requested_tenure = parseInt(values.requested_tenure);
    values.approved_tenure = parseInt(values.approved_tenure);
      values.approved_tenure = parseInt(values.approved_tenure);
      setLoading(true);
    try {
      const response = await updateBasicDetailsByIdService(id, values);
      if ((response.status = 200 && response.success)) {
        message.success("Basic Details successfully updated");
        refreshData();
        onRefresh();
        closeForm();
      }
    }
    catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      getDepartmentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const getAllLoanPurpose = async () => {
      try {
        const response = await listLoanPurposeService();
        setAllLoanPurpose(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllLoanType = async () => {
      try {
        const response = await listLoanTypeService();
        setLoanType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllLocation = async () => {
      try {
        const response = await listLocationService();
        setLocationType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };



    const getAllProductEndUse = async () => {
      try {
        const response = await getProductEndUseService();
        setProductEndUse(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    // const getAllSPOCLocation = async () => {
    //   try {
    //     const response = await getSPOCLocationService();
    //     setSpocLocation(response.data);
    //   }
    //   catch (error) {
    //     message.error(
    //       ErrorMessage(error?.response?.status, error?.response?.data?.message)
    //     );
    //   }
    // };


    const getAllProduct = async () => {
      try {
        const response = await getProductService();
        setProduct(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDueDate = async () => {
      try {
        const response = await getDueDateService();
        setDueDate(response?.data?.choices);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllInterestType = async () => {
      try {
        const response = await getInterestTypeService();
        setInterestType(response?.data?.choices);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
   
    getAllLoanPurpose();
    getAllLocation();
    getAllLoanType();
    getAllProductEndUse();
    // getAllSPOCLocation();
    getAllProduct();
    getAllDueDate();
    getAllInterestType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const getAllScheme = async () => {
      try {
        const response = await getListSchemeService(loanid);
        setScheme(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    if (loanid) {
      getAllScheme();
      }
  }, [loanid]);
  
  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        application_number: "",
        loan_account_number: "",
        requested_amount: 0,
        requested_tenure: "",
        scheme: "",
        loan_type: "",
        loan_purpose: "",
        location: "",
        approved_amount: 0,
        approved_roi: "",
      }}

    >
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="application_number" label="Application Number" rules={[yupSync]} required>
            <Input
              placeholder="Please enter Application Number"
              onChange={(e) => handleInput(e, "application_number")}
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="loan_account_number" label="Loan Account Number"
          // rules={[yupSync]} 
          // required
          >
            <Input placeholder="Please enter Loan Account Number"
              onChange={(e) => handleInput(e, "loan_account_number")}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="loan_type" label="Loan Type" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }
              disabled
            >
              {loanType?.map((loan_type) => {

                return (
                  <Select.Option
                    key={loan_type.id}
                    value={loan_type.id}>
                    {loan_type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>


      </Row>


      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="location" label="Location" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled>
              {locationType?.map((location) => {
                return (
                  <Select.Option
                    key={location.id}
                    value={location.id}>
                    {location.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="product" label="Product" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {product?.map((product) => {
                return (
                  <Select.Option
                    key={product.id}
                    value={product.id}>
                    {product.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="loan_purpose" label="Loan Purpose" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {allLoanPurpose?.map((loan_purpose) => {
                return (
                  <Select.Option
                    key={loan_purpose.id}
                    value={loan_purpose.id}>
                    {loan_purpose.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="product_end_use" label="End Use" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {productEndUSe?.map((endUse) => {
                return (
                  <Select.Option
                    key={endUse.id}
                    value={endUse.id}>
                    {endUse.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="scheme" label="Scheme" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {schemeType?.map((scheme_type) => {

                return (
                  <Select.Option
                    key={scheme_type.id}
                    value={scheme_type.id}>
                    {scheme_type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="requested_amount"
            label="Requested Amount"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Requested Amount"
              onChange={(e) => handleNumberFields(e, "requested_amount")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="requested_tenure" label="Requested Tenure  (In Month)" rules={[yupSync]} required>
            <Input placeholder="Please Enter Requested Tenure  (In Month)"
              onChange={(e) => handleNumberFields(e, "requested_tenure")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="expected_roi" label="Expected ROI" rules={[yupSync]} required>
            <Input placeholder="Please Enter Expected ROI"
              onChange={(e) => handleNumberFields(e, "expected_roi")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="emi_due_date" label="EMI Due Date" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {dueDate?.map((duedate) => {
                return (
                  <Select.Option
                    key={duedate?.value}
                    value={duedate?.value}>
                    {duedate?.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="interest_type" label="Interest Type" rules={[yupSync]} required>
          <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {interestType?.map((interestype) => {
                return (
                  <Select.Option
                    key={interestype?.value}
                    value={interestype?.value}>
                    {interestype?.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={8}>
          <Form.Item name="processing_Fee" label="Processing Fee" required>
            <Input placeholder="Please Enter Processing Fee"
              onChange={(e) => handleNumberFields(e, "processing_fee")}
            />
          </Form.Item>
        </Col> */}
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default BasicDetailsForm;
