import React, { useEffect, useState } from "react";
import "../styles/hfcidcard.css";
import logo from "../assets/logo.png";
import signature from "../assets/signature.png";
import QRCode from "react-qr-code";

const Hfcidcard = ({ data }) => {
  const [currentURL, setCurrentURL] = useState("");

  useEffect(() => {
    // Get the current URL
    const url = window.location.href;
    setCurrentURL(url);
  }, []);

  return (
    <>
      <div class="id-wrapper">
        <div class="id-wrapper-header">
          <center>
            <img class="logo pt-3" src={logo} alt="logo" width="35%" />
          </center>
        </div>

        <div class="profile-card__img">
          <center>
            <img src={data.data.profile_image} alt="profile" />
          </center>
        </div>

        <div class="id-wrapper-body">
          <div class="details">
            <div class="row">
              <div class="col-lg-12">
                <center>
                  <h1>{data.data.employee_name}</h1>
                </center>
                <center>
                  <hr />
                  <h3>{data.data.department}</h3>
                </center>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <strong>
                  {" "}
                  <label>EMP ID.</label>
                </strong>
              </div>
              <div class="col-lg-6 col-6">: {data.data.employee_id}</div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-6">
                <strong>
                  {" "}
                  <label>Blood Group</label>
                </strong>
              </div>
              <div class="col-lg-6 col-6">: {data.data.blood_group}</div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-6">
                <strong>
                  {" "}
                  <label>DOB</label>
                </strong>
              </div>
              <div class="col-lg-6 col-6">: {data.data.date_of_birth}</div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-6">
                <strong>
                  {" "}
                  <label>Contact No.</label>
                </strong>
              </div>
              <div class="col-lg-6 col-6">: {data.data.mobile_number}</div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-6">
                <strong>
                  {" "}
                  <label>Emergency No.</label>
                </strong>
              </div>
              <div class="col-lg-6 col-6">: {data.data.emergency_number}</div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-6">
                <strong>
                  {" "}
                  <label>Branch</label>
                </strong>
              </div>
              <div class="col-lg-6 col-6">: {data.data.branch}</div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                {/* <img class="logo pt-3" alt="qr-code" src={qr} /> */}
                {currentURL && (
                  <QRCode
                    size={128}
                    style={{ height: "auto", maxWidth: "85%", width: "850%" }}
                    value={currentURL} // Stringify the object
                    viewBox={`0 0 128 128`}
                  />
                )}
              </div>
              <div class="col-lg-6 col-6">
                <img class="logo pt-3" alt="signature" src={signature} />
                <br />
                Issuing Authority
              </div>
            </div>
          </div>
        </div>

        <div class="id-wrapper-footer p-2 px-4">
          <center>
            <h5>Vastu Housing Finance Corporation Ltd.</h5>
            <p>
              Unit No. 203 & 204, 2nd floor, ‘A’ Wing, Navbharat Estate, Zakaria
              Bunder Road, Sewri (West), Mumbai 400015. Maharashtra
            </p>
          </center>
        </div>
        <div class="id-wrapper-footer-green">
          <center>
            {" "}
            <p>
              <strong>www.vastuhfc.com</strong>
            </p>
          </center>
        </div>
      </div>
    </>
  );
};

export default Hfcidcard;
