import React, { useState } from "react";
import "../styles/loanDetails.css";
import { Button, Tabs, Space } from "antd";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import "../../../styles/loanTabsApplication.css";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import { schema } from "../../../../../config/schemeConfig";
const { TabPane } = Tabs;

const LoanDetails = ({ loanDetails, onRefresh }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showTabs, setShowTabs] = useState(true);
  const [activeButton, setActiveButton] = useState("sales");
  const { user_data } = useSelector((state) => state.user);

  const handleButtonClick = (type) => {
    if (type === "sales" || type === "underwriting" || type === "operations") {
      setActiveButton(type);
      setSelectedTabIndex(0);
    }
    setShowTabs(true);
  };

  const toggleTabContent = () => {
    setShowTabs((prev) => !prev);
  };

  // const tabs = {
  //   sales: [],
  //   underwriting: [
  //     { label: "Loan & Insurance", component: <LoanInsurance /> },
  //     { label: "Income Evaluation", component: <IncomeEvalution loanDetails={loanDetails} /> },
  //     { label: "Credit Analysis", component: <CreaditAnalysis /> },
  //     { label: "Disbursement Tranche", component: <DisbursementTranche /> },
  //     { label: "Miscellaneous Details", component: <MiscellaneousDetails /> },
  //   ],
  //   operations: [
  //     { label: "OTC/PDD", component: <OTCPDD /> },
  //     { label: "Cheques/NEFT", component: <ChequeAndNEFT /> },
  //     { label: "Payment Details", component: <PaymentDetail /> },
  //     { label: "PDC", component: <PDC /> },
  //   ],
  // };

  // schema[loanDetails?.loan_type?.display_name]?.forEach((item) => {
  //   tabs.sales.push({ label: item.label, component: <item.component /> });
  // });

  const tabs = {
    sales: [],
    underwriting: [],
    operations: [],
  };

  const loanTypeTabs = schema[activeButton]?.[loanDetails?.loan_type?.display_name] || [];

  loanTypeTabs.forEach((item) => {
    let componentWithProps;
    if (item.label === "Income Evaluation") {
      componentWithProps = <item.component loanDetails={loanDetails} />;
    } else if (item.label === "Basic Details") {
      componentWithProps = <item.component onRefresh={onRefresh} />;
    } else {
      componentWithProps = <item.component />;
    }

    tabs[activeButton].push({ label: item.label, component: componentWithProps });
  });

  return (
    <div className="loan_details_main_container">
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2 className="heading_loan_details">Loan Details</h2>

        <div style={{ display: "flex" }}>
          <div
            style={{
              backgroundColor: "#1018281A",
              padding: "3px",
              borderRadius: "6px",
              border: "1px solid #FFFFFF",
            }}
          >
            <Button
              style={{
                border: "none",
                backgroundColor:
                  activeButton === "sales"
                    ? "hsla(204, 57%, 36%, 1)"
                    : "#E4E7EC",
                color: activeButton === "sales" ? "#fff" : "#000",
                marginRight: "8px",
              }}
              onClick={() => handleButtonClick("sales")}
            >
              Sales
            </Button>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["MPRSLR5"]}
            >
              <Button
                style={{
                  border: "none",
                  backgroundColor:
                    activeButton === "underwriting"
                      ? "hsla(204, 57%, 36%, 1)"
                      : "#E4E7EC",
                  color: activeButton === "underwriting" ? "#fff" : "#000",
                }}
                onClick={() => handleButtonClick("underwriting")}
              >
                Underwriting
              </Button>
              <Button
                style={{
                  border: "none",
                  backgroundColor:
                    activeButton === "operations"
                      ? "hsla(204, 57%, 36%, 1)"
                      : "#E4E7EC",
                  color: activeButton === "operations" ? "#fff" : "#000",
                }}
                onClick={() => handleButtonClick("operations")}
              >
                Operations
              </Button>
            </ConditionalRender>
          </div>
          {showTabs ? (
            <AiOutlineUp
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                cursor: "pointer",
                marginTop: "12px",
              }}
              onClick={toggleTabContent}
            />
          ) : (
            <AiOutlineDown
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                cursor: "pointer",
                marginTop: "12px",
              }}
              onClick={toggleTabContent}
            />
          )}
        </div>
      </Space>
      <div
        className="customer_main_heading"
        style={{ borderBottom: !showTabs && "none" }}
      ></div>
      {showTabs && (
        <Tabs
          activeKey={String(selectedTabIndex)}
          onChange={(key) => setSelectedTabIndex(Number(key))}
          type="card"
          style={{ margin: "20px" }}
          destroyInactiveTabPane
        >
          {tabs[activeButton].map((tab, index) => (
            <TabPane tab={tab.label} key={index}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default LoanDetails;
