import React from "react";
import '../styles/creaditAnalysis.css'
import { Table } from "antd";
import "../../../customerTabDetails/style/customerStyle.css"
import "../styles/miscellaneous.css"
const MiscellaneousDetails = () => {
  const columns1 = [
    {
      title: 'SrNo.',
      dataIndex: 'no',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',

    },
    {
      title: 'Perform By',
      dataIndex: 'perform_by',
    },

    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
    },

    {
      title: 'Stage Status',
      dataIndex: 'stage_status',
    },

    {
      title: 'Date',
      dataIndex: 'date',
    },

  ];


  const data1 = [
    {
      key: '1',
      no: '1',
      comment: 'No previous vehicle experience. Funding 14lac',
      perform_by: 'Eklavya Aswani',
      assigned_to: 'David Sundar',
      stage_status: 'Initiated',
      date: '04-Dec-2023 02:37:22 PM',
    },

    {
      key: '2',
      no: <div style={{ marginBottom: '430px' }}>2</div>,

      comment:
        <div style={{ display: 'block' }}>

          Dear Sir,<br />
          Recommending the deal for : 14.00 lakhs used CE JCB 3 DX 2WD<br /> BACKHOE LOADER -2016 Model
          @ 84.85 % Ltv ,<br /> 4 Years Tenure , under FTB ProfileMitigants/Strengths:Applicant is<br /> having tractor
          ETR track & co applicant having own house<br /> worth of 25 lakhs.-Taken Gurantor with agri
          property<br />.applicnat is having Good experience in JCB Operating <br />filed since 9 + Years with non
          transport DL <br />Since 9 + Years ( DOI : 28-08-2014 - DOE : 29-08-2034 )
          Applicant is Driver <br />at M/S Varla Veera Swamy in Khammam City since 4 + Years,getting the<br />
          monthly 30- 40 k on his Duty as per TVR<br />
          Applicant has one vehicle Tractor MAHINDRA 415 DI -2016 Model <br />in Manapuram Finance Rs
          : 2.30 lakhs he is paid as on date 2 /4 <br />with ETR Track through Cibil
          Proposed vehicle <br />Deployed in Khammam City surrong areas,will be getting the monthly 60 k <br />
          - 1.50 lakhs income as per TVR<br />
          Purchased the asset for 17.50 lakhs and equity from customer is 3.50 lakhs<br /><br /><br />

          Thanks & Regards,<br />
          David sundar.<br />
          AP & Telangana<br />
          9866443939.`,

        </div>,
      perform_by: <div style={{ marginBottom: '430px' }}>David Sundar</div>,

      assigned_to: <div style={{ marginBottom: '430px' }}>David Sundar</div>,

      stage_status: <div style={{ marginBottom: '430px' }}>Approve</div>,

      date: <div style={{ marginBottom: '430px' }}>04-Dec-2023 02:37:22 PM</div>,

    },

  ];

  const onChange = (pagination, filters, sorter, extra) => {
  };

  return (
    <>
      <div className="tab_main_container">
<div style={{margin:"10px",border:"1px solid #e4e7ec"}}>
        <div>
          <div>
            <div className="heading_text">
              Case Storyboard</div></div>
          <div className="">

            <div>
              <div className="heading_text">
                Overall Comments of the PD Officer: *</div></div>
            <div className="  " style={{ margin: "10px", display: 'block' }}>

              <div className="miss_bold_heading" > Customer and Family Background:</div>
              <div className="data">Vastu HFC Officer Mr. MB Linga & Sateesh Peela visited the residence of the main applicant and met Ramesh Bahi Patel and Mr. Kiran Patel who are the main applicant to the case. The applicant staying in
                Own H.no.12-7-133/106/3,Anjaneya Nagar, Hyderabad, TS-500018. Applicant family consists of His spouse is co applicant to this loan, So, & Daughter in law He is living Rich class family - Proposed collateral <br />
                is in Municipal corporation limit- Basic standard available in home Like TV,Fridge,Fan,EB connection,Gas, Residence set up found satisfactory.<br /></div>
              <div className="miss_bold_heading">Customer Profile:</div>
              <div className="data">· Applicant Mr. Ramesh Bahi Patel (Age-56 years) is proprietor of M/s Sheetal Foot Wear since 15 years . He has been operating this business from visited same rented business premise  located at 6-3-802/17,
                Shop-1, Main Road, Zainab Commercial Complex, Ameerpet, Hyderabad. He is engaged in the business of Gents and Ladies & Kids Footwear retail basis.<br />
                · Business set up and activity was seen during the visit.<br />
                · We observed business name board of the premise<br />
                · We observed 5 to 6 customers during the visit.<br />
                · He has daily basis walk in individual local customers and daily attends 100 to 120 members in this Business,<br />
                · His per day Counter sales is Rs.20000/- to Rs.25,000/-<br />
                · We observed Fan, Table, Lights, Chairs, CCTV Cameras Etc, during the visit.<br />
                · Business premises located in the Commercial area of area of Maitri Ground Sangareddy Patancheru, Hyderabad.<br />
                · He maintains Average Stock is Rs.20 Lacs to 25lacs approx.<br />
                · He has 6 employees in this business & pays them monthly total salaries of Rs.1 lac approx.<br /></div>
              <div className="miss_bold_heading">Major customers are as follows: All are individual customers in Patancheru location in Hyderabad·</div>
              <div className="miss_bold_heading">Major suppliers are as follow:</div>
              <div className="data">
                · M/s. N Gyaneshwar Rao & Sons – Afzagunj, Hyderabad.<br />
                · M/s. Maharaja Shoe Company– Afzagunj, Hyderabad.<br />
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* <div style={{ backgroundColor: "#E6F7FE"}} className=" loan_details_heading">Residence Observations:</div> */}
          <div className=" ">
            <div className="heading_text" style={{ borderTop: "1px solid #e4e7ec" }}>
              Residence Observations:</div></div>
          <p className="data">The applicant Mr Ramesh Bahi Patel aged 56 years, qualified as a Undergraduate is staying in Owned premises measuring 1,000sq.ft. for last 2 years. The applicant has been staying in same city for last
            40 years. There are 6 in the family and they stay in a Nuclear family members setup.</p>
        </div>

        <div>

          <div className=" ">
            <div className="heading_text" style={{ borderTop: "1px solid #e4e7ec" }}>
              Case Storyboard</div></div>
          <p className="data">Vastu's employee M.B. Gadilingappa met with applicant at 6-3-802/17, SHOP-1, MAIN ROAD, ZAINAB COMMERCIAL COMPLEX.City Ameerpet Home. The applicant is a Self-employed and works as a proprietor of Proprietorship Sheetal Foot Wear .The customer has been managing the current business since the last 20 years & has a total business experience of 20 years. The Business the customer is managing is a First Generation business which is managed by Self .The Business premises measures 1,500 Sq.ft.in area and the structure is Pakka in nature.</p>
        </div>

        <div>

          <div className=" ">
            <div className="heading_text" style={{ borderTop: "1px solid #e4e7ec" }}>
              Sales Recommendation</div></div>

          <div className="table_container">
            <Table className="insurance_table" columns={columns1} dataSource={data1} onChange={onChange} pagination={false} />
          </div>
        </div>

        <div>
          <div className="heading_text"  >
            PSL Details</div>
          <div className=" " >
            <div className="heading_text">
              MSME</div></div>

          <div className="misscellanious_container">
            <div className="misscellanious_Basic_card">
              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">Asset Category</div>
                <div className="miscellaneous_data">CE</div>
              </div>

              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">Udyam No.</div>
                <div className="miscellaneous_data">NA</div>
              </div>

              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">Udyam Registration *</div>
                <div className="miscellaneous_data">-</div>
              </div>
            </div>

            <div className="misscellanious_Basic_card">
              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">Loan Amount</div>
                <div className="miscellaneous_data">₹ 14,00,000</div>
              </div>

              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">End Use Details</div>
                <div className="miscellaneous_data">NA</div>
              </div>
            </div>

            <div className="misscellanious_Basic_card">
              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">Occupation *</div>
                <div className="miscellaneous_data">Self Employed</div>
              </div>

              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">PSL *</div>
                <div className="miscellaneous_data">No</div>
              </div>
            </div>

            <div className="misscellanious_Basic_card">
              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">Industry Type</div>
                <div className="miscellaneous_data">Transport</div>
              </div>

              <div className="misscellanious_basic_card_content">
                <div className="miscellaneous_label">PSL *</div>
                <div className="miscellaneous_data">No</div>
              </div>

            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default MiscellaneousDetails;
