import { Col, Form, Input, Radio, Row } from "antd";
import React from "react";
import ICICIQuestion from "./ICICIQuestion";

const ICICIQuestionForm = ({
  item,
  index,
  showRemark,
  handleRadioChange,
  selectedValue,
  questionRemark,
  setQuestionRemark,
  insurance_id,
  text
}) => {
  return (
    <div key={item.id}>
         {index === 5 && (
        <p>
          Only for Female Customers,
        </p>
      )}
      <ICICIQuestion index={index} item={item} insurance_id={insurance_id} text={text} />

      {/* Display label "Only for Female Customers" before index 5 */}
   

      {/* Sub-Questions */}
      {text === "personal" && item.sub_questions?.map((subItem, subIndex) => {
        const checkDisorder = subItem?.question?.includes(
          "Any other disorder not mentioned above"
        );
        const quesOccupation = subItem?.question.includes(
          "Is your occupation associated with any specific hazard"
        );

        // Show a, b, c, etc., for sub-questions under index 2 or 4
        const subQuestionLabel =
          (index === 2 || index === 4) ? `${String.fromCharCode(97 + subIndex)}) ` : "";

        // Skip rendering for quesOccupation
        if (quesOccupation || subItem.question === item.question) {
          return null; // Skip rendering if the subItem question is the same as the main question
        }

        return (
          <div key={`${item.id}_${subItem.id}_${subIndex}`}>
            <p className="insurance_sub_question_heading" style={{padding: index === 2 ? '5px' : '15px'}}>
              {subQuestionLabel} {subItem.question}
            </p>

            {/* Show radio group only if (index !== 2) or (index === 2 && subIndex === 2) and text is "personal" */}
            {(text === "personal" && (index !== 2 || (index === 2 && subIndex === 2))) && (
              <Form.Item
                name={`radio_${subItem.id}`}
                rules={[
                  {
                    required: true,
                    message: "Please select an answer",
                  },
                ]}
                initialValue={
                  insurance_id ? (subItem.answer === "no" ? 0 : 1) : null // No selection if insurance_id is false
                }
              >
                <Radio.Group
                  value={showRemark}
                  onChange={(e) => handleRadioChange(subItem.id, e)}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Radio value={1}>Yes</Radio>
                    </Col>
                    <Col span={12}>
                      <Radio value={0}>No</Radio>
                    </Col>
                  </Row>
                </Radio.Group>

                {checkDisorder && selectedValue[subItem.id] === 1 && (
                  <div>
                    <Input
                      type="text"
                      placeholder="Remark"
                      style={{
                        width: "25vw",
                        marginTop: "15px",
                      }}
                      value={questionRemark}
                      onChange={(e) => setQuestionRemark(e.target.value)}
                    />
                  </div>
                )}
              </Form.Item>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ICICIQuestionForm;
