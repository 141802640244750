import React from 'react';
import { Table, Button, Tooltip, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteDeviation } from '../services/getDeviation';

const ManualDeviation = ({ loan_id, manualDeviationData, showDrawer, setSelectedManual, setManualDataValue, toggleRefreshTableData }) => {
    const onChange = (pagination, filters, sorter, extra) => {
    };

    const handleRemove = async (item) => {
        const response = await deleteDeviation(item?.id, loan_id);
        if (response.status === 200 && response.success === true) {
            message.success("Manual Deviation deleted successfully");
            toggleRefreshTableData();
        }
    };
    const manualDeviation = manualDeviationData.map((item, index) => {
        return {
            key: index + 1,
            no: index + 1,
            deviation: item.deviation.name,
            actual_deviations: item.actual_description,
            mitigates: item?.mitigates,
            actions: (
                <>
                    <Tooltip title="Edit">
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(item)}
                        />
                    </Tooltip>
                    <Tooltip title="Remove">
                        <Button
                            type="link"
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                            onClick={() => handleRemove(item)}
                            
                        />
                    </Tooltip>
                </>
            ),
        };
    });

    const columns2 = [
        {
            title: 'SrNo.',
            dataIndex: 'no',
        },
        {
            title: 'Deviation',
            dataIndex: 'deviation',
        },
        {
            title: 'Actual Deviation',
            dataIndex: 'actual_deviations',
        },
        {
            title: 'Mitigates',
            dataIndex: 'mitigates',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
        },
    ];

    const handleEdit = (item) => {
        // Add your edit logic here
        showDrawer()
        setSelectedManual(true)
        setManualDataValue(item)
       
    };



    return (
        <>
            <div className='decision_heading'>
                <div className='text'>Manual Deviation</div>
            </div>
            <div className="table_container">
                <Table
                    className="insurance_table"
                    columns={columns2}
                    dataSource={manualDeviation}
                    onChange={onChange}
                    pagination={false}
                    
                />
            </div>
        </>
    );
};

export default ManualDeviation;
