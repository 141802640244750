import React, { useState, useEffect } from "react";
import "../styles/loanInsurance.css";
import { Drawer, Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  getInsuranceDetailsByIdService,
  deleteMagmaHdi,
} from "../services/loanInsuranceService";
import Header from "../../../../layout/views/Header";
import MagmaHDIInsuranceForm from "./MagmaHDIInsuranceForm";
import EditButton from "../../../../../utils/editButton/EditButton";
import MagmaPlanData from "./MagmaPlanData";

const MagmaHDIInsuranceview = ({ insurance_id, loan_id,toggleRefreshInsurance,setDisabledButton,disabledButton }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [, setSelectedUser] = useState(null);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [data, setData] = useState([]);
  const [customer_id, setCustomer_id] = useState("");
  const [nominee_id, setNominee_id] = useState("");
  const [insurancecompanydetail, setInsurancecompanydetail] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
    setOpen(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const closeDrawer = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInsuranceDetailsByIdService(insurance_id);
        setData(data?.data);
        setCustomer_id(data?.data?.insurance_detail?.customer_detail?.id);
        setNominee_id(data?.data?.nominee_detail[0]?.id);
        setInsurancecompanydetail(
          data?.data?.insurance_detail?.insurance_company_detail?.id
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [insurance_id, refreshTableData]);

  const deleteUserById = async () => {
    try {
      const response = await deleteMagmaHdi(insurance_id);
      if (response.status === 200 && response.success === true) {
        message.success("ID Card deleted successfully");
        window.location.reload();
        setOpenModal(false);
        toggleRefreshTableData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <div className="loan-view-header">
        <div className="loan-view-heading">Details Of Coverage</div>
        <div className="insurance-button-container" style={{ display: "flex" }}>
          {/* <div className="insurance-download-button">
            <VerticalAlignBottomOutlined />
            Download Form
          </div> */}
          <div className="insurance-edit-button" onClick={showDrawer}>
            <EditButton />
          </div>
          <Drawer
            title={
              <Header
                title={insurance_id ? "Edit" : "Add"}
                onClose={onClose}
                name="Magma HDI Insurance"
              />
            }
            width={1000}
            onClose={onClose}
            open={open}
            style={{
              body: { paddingBottom: 80, paddingLeft: 0, paddingRight: 0 },
            }}
            closable={false}
          >
            <MagmaHDIInsuranceForm
              toggleRefreshTableData={toggleRefreshTableData}
              id={selectedUserId}
              open={open}
              closeDrawer={closeDrawer}
              closeForm={onClose}
              onCancel={onClose}
              setSelectedCustomer={setSelectedUserId}
              insurance_id={insurance_id}
              loan_id={loan_id}
              customer_id={customer_id}
              nominee_id={nominee_id}
              insurancecompanydetail={insurancecompanydetail}
              toggleRefreshInsurance={toggleRefreshInsurance}
              setDisabledButton={setDisabledButton}
              disabledButton={disabledButton}
            />
          </Drawer>
          <div
            className="insurance-delete-button"
            onClick={() => setOpenModal(true)}
          >
            <DeleteOutlined />
          </div>
        </div>
      </div>

      <div className="loan_insurance_details_container">
        <div className="Basic_card">
          <div className="basic_card-content">
            <div className="label">Loan Amount</div>
            <div className="data">
              {data?.insurance_coverage_data?.loan_amount || "-"}
            </div>
          </div>
          <div className="basic_card-content">
            <div className="label">Monthly Income</div>
            <div className="data">
              {data?.insurance_coverage_data?.monthly_income || "-"}
            </div>
          </div>
          <div className="basic_card-content">
            <div className="label">Branch Address</div>
            <div className="data">
              {data?.insurance_coverage_data?.branch_address?.name || "-"}
            </div>
          </div>
        </div>
        <div className="Basic_card">
          <div className="basic_card-content">
            <div className="label">Tenure Of Loan</div>
            <div className="data">
              {data?.insurance_coverage_data?.tenure || "-"}
            </div>
          </div>
          <div className="basic_card-content">
            <div className="label">Premium Amount (Including GST)</div>
            <div className="data">
              {data?.insurance_coverage_data?.total_premium_incl_gst || "-"}
            </div>
          </div>
        </div>

        <div className="Basic_card">
          <div className="basic_card-content">
            <div className="label">Policy Term(In Months)</div>
            <div className="data">
              {data?.insurance_coverage_data?.policy_term || "-"}
            </div>
          </div>
          <div className="basic_card-content">
            <div className="label">EMI Amount</div>
            <div className="data">
              {data?.insurance_coverage_data?.emi_amount || "-"}
            </div>
          </div>
        </div>

        <div className="Basic_card">
          <div className="basic_card-content">
            <div className="label">Sum Assured</div>
            <div className="data">
              {data?.insurance_coverage_data?.sum_assured || "-"}
            </div>
          </div>
          <div className="basic_card-content">
            <div className="label">Age Proof</div>
            <div className="data">
              {data?.insurance_coverage_data?.age_proof?.name || "-"}
            </div>
          </div>
        </div>
      </div>

      <div className="loan-view-header">
        <div className="loan-view-heading">
          Group Member / Life To Be Assured
        </div>
      </div>
      {data?.insured_customer_detail?.map((datas) => (
        <div className="loan_insurance_details_container">
          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Name</div>
              <div className="data">
                {datas?.first_name || "-"} {datas?.middle_name || "-"}{" "}
                {datas?.last_name || "-"}
              </div>
            </div>
            <div className="basic_card-content">
              <div className="label">Nationality</div>
              <div className="data">{datas?.nationality || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">Address 1</div>
              <div className="data">{datas?.address_line_1 || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">Pincode</div>
              <div className="data">{datas?.pincode?.name || "-"}</div>
            </div>
          </div>

          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Date Of Birth</div>
              <div className="data">{datas?.date_of_birth || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">Contact Number</div>
              <div className="data">{datas?.mobile || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">Address 2</div>
              <div className="data">{datas?.address_line_2 || "-"}</div>
            </div>
          </div>

          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Gender</div>
              <div className="data">{datas?.gender || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">Email</div>
              <div className="data">{datas?.email || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">City</div>
              <div className="data">{datas?.city?.name || "-"}</div>
            </div>
          </div>

          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Occupation</div>
              <div className="data">{datas?.ocupation?.name || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">Applicant Status</div>
              <div className="data">{datas?.customer_type?.name || "-"}</div>
            </div>
            <div className="basic_card-content">
              <div className="label">State</div>
              <div className="data">{datas?.state?.name || "-"}</div>
            </div>
          </div>
        </div>
      ))}

      <div>
        <div
          className="nominee_details_container"
          style={{
            backgroundColor: "#F9FAFB",
            color: "#565657",
            fontSize: "14px",
            lineHeight: "22px",
            padding: "10px",
          }}
        >
          Nominee Details
        </div>
        <div className="nominee_details">
          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Name</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.name || "-"}
              </div>
            </div>
            <div className="basic_card-content">
              <div className="label">Nominee's Contact Number</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.mobile || "-"}
              </div>
            </div>
          </div>

          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Relation With The Life To Be Assured</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.relation?.name || "-"}
              </div>
            </div>
            <div className="basic_card-content">
              <div className="label">Nominee's Address</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.address_line_1 || "-"}
              </div>
            </div>
          </div>

          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Nominee's Date Of Birth</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.date_of_birth || "-"}
              </div>
            </div>
            <div className="basic_card-content">
              <div className="label">Nominee's Email Address</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.email || "-"}
              </div>
            </div>
          </div>

          <div className="Basic_card">
            <div className="basic_card-content">
              <div className="label">Nominee's Gender</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.gender || "-"}
              </div>
            </div>
            <div className="basic_card-content">
              <div className="label">Nominee's Nationality</div>
              <div className="data">
                {data?.nominee_detail?.[0]?.nationality || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {data?.nominee_detail?.[0]?.appointee && (
        <div>
          <div
            className="nominee_details_container"
            style={{
              backgroundColor: "#F9FAFB",
              color: "#565657",
              fontSize: "14px",
              lineHeight: "22px",
              padding: "10px",
            }}
          >
            Appointee Details
          </div>

          <div className="nominee_details">
            <div className="Basic_card">
              <div className="basic_card-content">
                <div className="label">Name</div>
                <div className="data">
                  {data?.nominee_detail[0]?.appointee?.appointee_name || "-"}
                </div>
              </div>
              <div className="basic_card-content">
                <div className="label">Appointee's Contact Number</div>
                <div className="data">
                  {data?.nominee_detail[0]?.appointee?.mobile || "-"}
                </div>
              </div>
            </div>

            <div className="Basic_card">
              <div className="basic_card-content">
                <div className="label">
                  Relation With The Life To Be Assured
                </div>
                <div className="data">
                  {data?.nominee_detail?.[0]?.appointee?.relation?.name || "-"}
                </div>
              </div>
            </div>

            <div className="Basic_card">
              <div className="basic_card-content">
                <div className="label">Appointee's Date Of Birth</div>
                <div className="data">
                  {data?.nominee_detail[0]?.appointee?.date_of_birth || "-"}
                </div>
              </div>
            </div>

            <div className="Basic_card">
              <div className="basic_card-content">
                <div className="label">Appointee's Gender</div>
                <div className="data">
                  {data?.nominee_detail[0]?.appointee?.gender || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <MagmaPlanData data={data} />

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteUserById();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this insurance?`}</p>
      </Modal>
    </div>
  );
};

export default MagmaHDIInsuranceview;
