import React, { useState, useEffect } from 'react';
import { Upload, Button, Row, Col, Form, Space, message, Modal } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { postDocumentsByIdService, getRCUDataByIdService, deleteDocumentbyId } from "../services/rcuservices";

const Propertypicturesdocuments = ({ open, refreshData, closeForm, loanid, stage_id }) => {
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    const [base64Images, setBase64Images] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [rcuid, setRcuid] = useState();

    const getStatusData = async () => {
        try {
            const response = await getRCUDataByIdService(stage_id);
            const documentUploadData = response.data?.rcu_data?.rcu_document_upload || []; // Ensure it's an array
            setData(documentUploadData);
            setRcuid(response?.data?.rcu_data?.id);
        } catch (error) {
            message.error(`Failed to fetch statuses: ${error?.response?.data?.message || error.message}`);
        }
    };

    useEffect(() => {
        getStatusData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanid, open]);

    const handleUploadChange = (info) => {
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Images(prev => [...prev, reader.result]);
                setImages(prev => [...prev, URL.createObjectURL(info.file.originFileObj)]);
            };
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const handleSubmit = async () => {
        // Check if both new images and existing documents are empty
        if (base64Images.length === 0 && data.length === 0) {
            message.error('No images to upload');
            return;
        }

        // If there are no new images but existing documents are present
        if (base64Images.length === 0 && data.length > 0) {
            message.success('Document uploaded successfully');
            closeForm();
            refreshData();
            return;
        }

        // Proceed with API call if there are new images to upload
        try {
            const payload = {
                rcu_detail: rcuid,
                document_path: base64Images
            };
            await postDocumentsByIdService(payload);
            message.success('Document uploaded successfully');
            getStatusData(); // Refresh the data after successful upload
            setImages([]);
            setBase64Images([]);
            closeForm();
            refreshData();
        } catch (error) {
            message.error(`Failed to upload document: ${error?.response?.data?.message || error.message}`);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDocumentbyId(id);
            message.success('Document deleted successfully');
            getStatusData(); // Refresh the data after successful deletion
        } catch (error) {
            message.error(`Failed to delete document: ${error?.response?.data?.message || error.message}`);
        }
    };

    const showDeleteConfirm = (id) => {
        setDeleteId(id);
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        handleDelete(deleteId);
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleLocalDelete = (index) => {
        setImages(images.filter((_, i) => i !== index));
        setBase64Images(base64Images.filter((_, i) => i !== index));
    };

    return (
        <>
            <Form>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="document" required>
                            <Upload
                                maxCount={1}
                                showUploadList={false}
                                customRequest={({ file, onSuccess }) => {
                                    setTimeout(() => onSuccess(), 0);
                                    handleUploadChange({ file: { status: 'done', originFileObj: file } });
                                }}
                            >
                                <Button type='primary' icon={<UploadOutlined />}>Upload Document</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {images.map((img, index) => (
                        <Col key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginBottom: '20px', textAlign: 'center' }}>
                            <img src={img} alt="Uploaded" style={{ width: '75px', height: '75px' }} />
                            <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                style={{ marginTop: '5px' }} // Position the delete icon below the image
                                onClick={() => handleLocalDelete(index)}
                            />
                        </Col>
                    ))}
                    {data.map((item) => (
                        <Col key={item.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginBottom: '20px', textAlign: 'center' }}>
                            <img src={item.document_path} alt="Document" style={{ width: '75px', height: '75px' }} />
                            <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                style={{ marginTop: '5px' }} // Position the delete icon below the image
                                onClick={() => showDeleteConfirm(item.id)}
                            />
                        </Col>
                    ))}
                </Row>
                <Form.Item>
                    <Space
                        direction="horizontal"
                        align="center"
                        style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                        <Button type="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button type="default" onClick={closeForm}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Modal
                title="Delete Document"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want tonkjjkkn delete this document?</p>
            </Modal>
        </>
    );
};

export default Propertypicturesdocuments;
