import React, { useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import {
  listCity,
  listTaluka,
  listDistrict,
  listPincode,
  listState,
  listCountry,
  listUnitsService,
  CustomerListService,
} from "../../services/propertyDescriptionService";
import { ErrorMessage } from "formik";
import AddPropertyOwnerModal from "../AddPropertyOwnerModal";
const { Option } = Select;


const LegalPropertyDescriptionForm = ({yupSync,customDateValidator,form,loanId,legalInputData}) => {
  const [city, setCity] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [district, setDistrict] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [state, setState] = useState([]);
  const [country, setCountry] = useState([]);
  const [units, setUnits] = useState([]);
  const [propertyOwner, setPropertyOwner] = useState([]);
  const [collapsedpropertydescription, setCollapsedpropertydescription] =useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [, setShowOtherInput] = useState(false);

  const fetchUnits = async () => {
    try {
      // Fetch Units from the API
      const response = await listUnitsService();
      setUnits(response.data.choices);
    } catch (error) {
      console.error("Error fetching Units:", error);
      message.error("Failed to fetch Units");
    }
  };

  const fetchCustomerName = async (loanId) => {
    try {
      const response = await CustomerListService(loanId);
      setPropertyOwner(response.data.customer_list);

    } catch (error) {
      console.error("Error fetching Property Owners Name:", error);
      message.error("Failed to fetch Property Owners Name");
    }
  };

  useEffect(() => {
    fetchUnits();
    fetchCustomerName(loanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const getAllCity = async () => {
      try {
        const response = await listCity();
        setCity(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllTaluka = async () => {
      try {
        const response = await listTaluka();
        setTaluka(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await listDistrict();
        setDistrict(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincode = async () => {
      try {
        const response = await listPincode();
        setPincode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await listState();
        setState(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountry = async () => {
      try {
        const response = await listCountry();
        setCountry(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllCity();
    getAllTaluka();
    getAllDistrict();
    getAllPincode();
    getAllState();
    getAllCountry();
  }, [form]);

  useEffect(() => {
    if (legalInputData?.legal_initiate_data) {
      const { transaction_type, property_address } = legalInputData.legal_initiate_data;
      form.setFieldsValue({
        transaction_type,
        address: property_address, // Assuming `property_address` corresponds to the `address` field
      });
    }
  }, [legalInputData, form]);

  const handleOwnerChange = (value) => {
    if (value === "other") {
      setShowOtherInput(true);
      setIsModalVisible(true); // Show the modal when "Other" is selected
      // form.setFieldsValue({ present_owner: "" }); // Clear the field value
    } else {
      setShowOtherInput(false);
      setIsModalVisible(false);
      // form.setFieldsValue({ other_owner: "" });
    }
  };
  const handleAddPropertyOwner = (newOwner) => {
    // Add the new owner to the property owner list
    setPropertyOwner([
      ...propertyOwner,
      { id: propertyOwner.length + 1, first_name: newOwner },
    ]);
    // Optionally, you can also set the selected owner in the form field
    form.setFieldsValue({ present_owner: newOwner });
  };

  const toggleCollapsepropertydescription = () => {
    setCollapsedpropertydescription(!collapsedpropertydescription);
  };

  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s,./()0-9/-]/g, ""); // Allow numbers
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div style={{ border: "1px solid #E4E7EC" ,marginTop:"20px"}}>
    <div className="property_description_heading">
      <div className="property_description_data">Property Description</div>
      <div style={{ display: "flex" }}>
        <div
          style={{ margin: "10px 10px 10px 10px" }}
          onClick={toggleCollapsepropertydescription}
        >
          {/* {collapsed1 ? <DownOutlined /> : <UpOutlined />} */}
        </div>
      </div>
    </div>
    <div style={{ margin: "10px" }}>
        <Row gutter={12}>
        <Col span={8}>
            <Form.Item name="transaction_type" label="Transaction Type"  rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Transaction Type"
                disabled
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="present_owner"
              label="Property Owner"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                onChange={handleOwnerChange}
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {propertyOwner?.map((property) => {
                  return (
                    <Select.Option key={property.id} value={property.first_name}>
                    {`${property.first_name} (${property.customer_type})`}
                  </Select.Option>
                  );
                })}
                <Option key="other" value="other">
                  Other
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="address" label="Property Address" rules={[yupSync]} required >
              <Input
                placeholder="Enter Property Address"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="area_of_property"
              label="Area of Property"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Please Enter Area of Property" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="unit_type"
              label="Units"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {units?.map((unit) => (
                  <Option key={unit.value} value={unit.value}>
                    {unit.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="property_description_heading_two">
          <div className="property_description_data">As per Documents</div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="address1"
                label="Address Line 1"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter Address Line 1" onChange={(e) => handletextInput(e, "address1")} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="address2"
                label="Address Line 2"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter Address Line 2" onChange={(e) => handletextInput(e, "address2")} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="district"
                label="District"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select District"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {district?.map((district) => {
                    return (
                      <Select.Option
                        key={district.id}
                        value={district.name}
                      >
                        {district.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="state"
                label="State"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select State"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state?.map((state) => {
                    return (
                      <Select.Option key={state.id} value={state.id}>
                        {state.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="city"
                label="City"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select City"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {city?.map((city) => {
                    return (
                      <Select.Option key={city.id} value={city.name}>
                        {city.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select Pincode"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {pincode?.map((pincode) => {
                    return (
                      <Select.Option key={pincode.id} value={pincode.name}>
                        {pincode.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="landmark"
                label="Landmark"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter Landmark"  onChange={(e) => handletextInput(e, "landmark")}/>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="taluka"
                label="Taluka"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select Taluka"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {taluka?.map((taluka) => {
                    return (
                      <Select.Option key={taluka.id} value={taluka.name}>
                        {taluka.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="country"
                label="Country"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select Country"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {country?.map((country) => {
                    return (
                      <Select.Option key={country.id} value={country.name}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="description_east"
                label="On East Side"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter On East Side"  onChange={(e) => handletextInput(e, "description_east")}/>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="description_west"
                label="On West Side"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter On West Side"  onChange={(e) => handletextInput(e, "description_west")}/>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="description_north"
                label="On North Side"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter On North Side"  onChange={(e) => handletextInput(e, "description_north")}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="description_south"
                label="On South Side"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please Enter On South Side"  onChange={(e) => handletextInput(e, "description_south")}/>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="date_of_report"
                label="Date of Report"
                rules={[{ validator: customDateValidator }]}
                required
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  placeholder="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <AddPropertyOwnerModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onAddPropertyOwner={handleAddPropertyOwner}
        />
    </div>
  </div>
  )
}

export default LegalPropertyDescriptionForm