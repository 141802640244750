import { Form, Row, Col, message, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { listEmploymentType } from '../services/employmentDetailsServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import SalariedForm from './SalariedForm';
import SelfEmployedForm from './SelfEmployedForm';
import PensionerForm from './PensionerForm';
import RentalForm from './RentalForm';

const validationSchema = Yup.object().shape({
    employment_type: Yup.string()
        .required("Employment type is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};
const EmploymentType = ({ customer_id, closeForm, open, refreshData }) => {
    const [form] = Form.useForm();
    const [employmentTypes, setEmplomentTypes] = useState([]);
    const [selectedEmploymentType, setSelectedEmploymentType] = useState(null);

    const handleEmploymentTypeChange = (value) => {
        setSelectedEmploymentType(value);
    };

    useEffect(() => {
        const getAllEmploymentType = async () => {
            try {
                const response = await listEmploymentType();
                setEmplomentTypes(response.data);
            }
            catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        getAllEmploymentType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setSelectedEmploymentType(null);
        }
    }, [open, form]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{

                }}
            >
                <Row gutter={16} style={{ marginBottom: '16px' }}>
                    <Col span={8}>
                        <Form.Item name="employment_type" label="Employment Type" rules={[yupSync]} required>
                            <Select
                                Add Employment Details
                                mode="single"
                                placeholder="Please select"
                                required={true}
                                allowClear
                                onChange={handleEmploymentTypeChange}
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }>
                                {employmentTypes?.map((Employment_type) => {

                                    return (
                                        <Select.Option
                                            key={Employment_type.id}
                                            value={Employment_type.id}>
                                            {Employment_type.display_name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {selectedEmploymentType === 1 && (
                <SalariedForm customer_id={customer_id}
                    closeForm={closeForm}
                    refreshData={refreshData}
                    open={open}
                    selectedEmploymentType={selectedEmploymentType}
                />
            )}
            {selectedEmploymentType === 2 && (
                <SelfEmployedForm customer_id={customer_id}
                    closeForm={closeForm}
                    refreshData={refreshData}
                    open={open}
                    selectedEmploymentType={selectedEmploymentType} />
            )}
            {selectedEmploymentType === 3 && (
                <RentalForm customer_id={customer_id}
                    closeForm={closeForm}
                    refreshData={refreshData}
                    open={open}
                    selectedEmploymentType={selectedEmploymentType} />
            )}
            {selectedEmploymentType === 4 && (
                <PensionerForm customer_id={customer_id}
                    closeForm={closeForm}
                    refreshData={refreshData}
                    open={open}
                    selectedEmploymentType={selectedEmploymentType} />
            )}

        </>
    )
}

export default EmploymentType