import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import '../../partdisbursalDetails/styles/partDisbursalDetails.css';
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import { getApprovalDetailsByIdService } from '../services/approvalDetailsService'
// import { Select } from 'antd';
import TechnicalApprovalForm from './TechnicalApprovalForm';
import CollectionForm from './CollectionForm';
import CreditForm from './CreditForm';
import Operationform from './Operationform';
// const { Option } = Select;



const ApprovalDetails = () => {
  const [, setApprovalDetails] = useState([]);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getApprovalDetailsByIdService(loan_id);
        setApprovalDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="part_disbursed_table">
      <div className="main_disbursal">
        <div className="part_disbursed_heading">
          <div className="part_disbursed_data">
            <h3>Approval</h3>
          </div>
        </div>

        <div className='tab_container'>
          <Tabs
            type="card"
            onChange={(key) => console.log(`Selected Tab: ${key}`)}
            items={[
              {
                label: 'Technical',
                key: '1',
                children: (
                  <>
                  <div className='main_part_disbursal_details_container'>
                    <div className='part_disbursal_container'>
                      <div className='part_disbursal_card1'>
                        <div className="label">Approval Status</div>
                        <div className="data">Complete</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Remark</div>
                        <div className="data">Ok Recommended for 100%</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Approval Status</div>
                        <div className="data">Complete</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Remark</div>
                        <div className="data">Ok Recommended for 100%</div>
                      </div>
                    </div>
                  </div>
                  
                  <TechnicalApprovalForm/>
                  </>
                ),
              },
              {
                label: 'Collection',
                key: '2',
                children: (
                  <>
                  <div className='main_part_disbursal_details_container'>
                    <div className='part_disbursal_container'>
                      <div className='part_disbursal_card1'>
                        <div className="label">Approval Status</div>
                        <div className="data">Compeleted</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Remark</div>
                        <div className="data">Ok Recommended for 100%</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        {/* <div className="label">Approval Status</div>
                        <div className="data">Compeleted</div> */}
                      </div>
                      <div className='part_disbursal_card1'>
                        {/* <div className="label">Remark</div>
                        <div className="data">Ok Recommended for 100%</div> */}
                      </div>
                    </div>
                  </div>
                  <CollectionForm/>
                  </>
                ),
              },
              {
                label: 'Credit',
                key: '3',
                children: (

                  <>
                  <div className='main_part_disbursal_details_container'>
                    <div className='part_disbursal_container'>
                      <div className='part_disbursal_card1'>
                      <div className="label">Approval Status</div>
                      <div className="data">Completed</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Construction Compeletion Recommendede 0%</div>
                        <div className="data">construction</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Disb Amount Recommendede</div>
                        <div className="data">construction</div>
                      </div>
                     
                    </div>



                    <div className='part_disbursal_container'>
                      <div className='part_disbursal_card1'>
                      <div className="label">Remark</div>
                      <div className="data">Completed</div>
                      </div>
                    </div>
                  </div>
                  <CreditForm/>
                  </>
                ),
              },
              {
                label: 'Operation',
                key: '4',
                children: (
                  <>
                  <div className='main_part_disbursal_details_container'>
                    <div className='part_disbursal_container'>
                      <div className='part_disbursal_card1'>
                        <div className="label">Approval Status</div>
                        <div className="data">Compelete</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        <div className="label">Remark</div>
                        <div className="data">construction</div>
                      </div>
                      <div className='part_disbursal_card1'>
                        {/* <div className="label">Construction Type</div>
                        <div className="data">construction</div> */}
                      </div>
                      <div className='part_disbursal_card1'>
                        {/* <div className="label">Construction Type</div>
                        <div className="data">construction</div> */}
                      </div>
                    </div>
                  </div>
                  <Operationform/>
                  </>
                ),
              },
            ]}
          />
        </div>

      </div>
    </div>

  );
};

export default ApprovalDetails;


