import React from 'react';
import "../styles/notFound.css";
import { Link } from "react-router-dom";
import pagenotfound from "../assets/404_illustration.png";
import { Button} from "antd";
const PageNotFound = () => {
  return (
    <div className="full_container">
    <div className="section1">
        <div className="box_content">
            <div className="heading_section">
                <div className="headings">
                    <h3>404 error</h3>
                    <h1>Page not found</h1>
                </div>
                <p className="paragraph">
                    Sorry, the page you are looking for doesn't exist or
                    has been moved. Try searching our site:
                </p>
            </div>
            <div className="refresh_section">
                <Link to="/">
                    <Button  type="primary"  size="large" >Refresh</Button>
                </Link>
            </div>
        </div>
    </div>
  
    <div className="section2">
        <img src={pagenotfound} alt=''></img>
    </div>
</div>
  )
}

export default PageNotFound