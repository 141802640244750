import { Navigate, Outlet } from 'react-router-dom'

// Protected Routes
const AuthProtectedRoutes = () => {
  if (localStorage.getItem('access')) {
    return <Outlet />
} else {
    return <Navigate to="/login" />
}
}

export default AuthProtectedRoutes