import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { useEffect } from "react";

const { Option } = Select;

const MagmaInsuredCustomerForm = ({
  yupSync,
  customerId,
  occupationType,
  filterOption,
  handleInput,
  states,
  city,
  pincode,
  formValues,
  personalData,
  setMatchedCustomer,
  setFormValues,
  insurance_id,
  datas,
  // form
}) => {
  const [form] = Form.useForm();
  // Ensure datas[0] exists before accessing its properties
  const initialValues = datas?.[0]
    ? {
        insured_customer_name: `${datas[0].first_name || ""} ${
          datas[0].middle_name || ""
        } ${datas[0].last_name || ""}`.trim(),
        gender: datas[0].gender || "",
        nationality: datas[0].nationality || "",
        mobile: datas[0].mobile_number || "",
        email: datas[0].email || "",
        date_of_birth: datas[0].date_of_birth
          ? moment(datas[0].date_of_birth, "YYYY-MM-DD")
          : null,
        applicant_type: datas[0].customer_type?.display_name || "",
        address_line_1: datas[0].address_line_1 || "",
        address_line_2: datas[0].address_line_2 || "",
        city: datas[0].city?.id || undefined,
        state: datas[0].state?.id || undefined,
        pincode: datas[0].pincode?.id || undefined,
      }
    : {};

  useEffect(() => {
    if (customerId && personalData.length > 0) {
      const foundCustomer = personalData.find(
        (person) => person.id === customerId
      );
      if (foundCustomer) {
        const [day, month, year] = foundCustomer?.date_of_birth.split("-");
        const formattedDate = moment(`${year}-${month}-${day}`, "YYYY-MM-DD"); // Create a moment object
        form.setFieldsValue({
          insured_customer_name: `${foundCustomer.first_name || ""} ${
            foundCustomer.middle_name || ""
          } ${foundCustomer.last_name || ""}`.trim(),
          gender: foundCustomer.gender || undefined,
          nationality: foundCustomer.nationality || "",
          mobile: foundCustomer.mobile_number || "",
          email: foundCustomer.email || "",
          date_of_birth: formattedDate, // Pass the moment object here
          applicant_type: foundCustomer.customer_type?.display_name || "",
          address_line_1: "",
          address_line_2: "",
          city: '' || undefined,
          state: '' || undefined,
          pincode: '' || undefined,
          occupation:'' || undefined
        });

        setMatchedCustomer(foundCustomer);
      }
    }else{
      setFormValues({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, personalData, form, setMatchedCustomer]);

  const onFormValuesChange = (changedValues, allValues) => {
    setFormValues((prevFormValues) => {
      if (Array.isArray(prevFormValues)) {
        // If formValues is an array, update only the first index
        const updatedFormValues = [...prevFormValues]; // Make a copy of the existing array
        updatedFormValues[0] = allValues; // Update the first index with the new form values
        return updatedFormValues; // Return the updated array
      } else {
        // If formValues is not an array, set it directly
        return allValues;
      }
    });
  };

  return (
    <>
      <div className="details_coverage_hdfc">Insured Customer</div>
      <div className="drawer-insurance-space">
        <Form
          onValuesChange={onFormValuesChange}
          layout="vertical"
          form={form} // Bind the form instance
          initialValues={initialValues} // Set initial values here
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="insured_customer_name"
                label="Insured Customer Name"
                required
              >
                <Input placeholder="Enter Insured Customer Name" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="date_of_birth" label="Date Of Birth" required>
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  placeholder="Select Date"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="gender" label="Gender" required>
                <Select
                  placeholder="Please Select"
                  style={{ width: "100%" }}
                  disabled
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="occupation"
                label="Occupation"
                // rules={[yupSync]}
                required
                rules={[
                  {
                    required: true,
                    message: "Occupation is required",
                  },
                ]}
              >
                <Select
                  placeholder="Please Select Occupation"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear
                  disabled={insurance_id}
                >
                  {occupationType?.map((locations) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="nationality" label="Nationality" required>
                <Input placeholder="Enter Nationality" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="contact_number" label="Contact Number">
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="+91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="mobile" noStyle required>
                    <Input
                      style={{ width: "80%" }}
                      placeholder="Enter Contact Number"
                      disabled
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item name="email" label="Email ID" required>
                <Input placeholder="Enter Email" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="applicant_type" label="Applicant Type" required>
                <Input placeholder="Enter Applicant Type" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={12}>
              <Form.Item
                name="address_line_1"
                label="Address Line 1"
                // rules={[yupSync]}
                required
                rules={[
                  {
                    required: true,
                    message: "Address Line 1 is required",
                  },
                ]}
              >
                <Input placeholder="Please Enter Address line 1" disabled={insurance_id} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="address_line_2"
                label="Address Line 2"
                rules={[
                  {
                    required: true,
                    message: "Address Line 2 is Required",
                  },
                ]}
                required
              >
                <Input placeholder="Please Enter Address line 2" disabled={insurance_id} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is Required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select City"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear
                  disabled={insurance_id}
                >
                  {city?.map((locations) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is Required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select State" // Placeholder text
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  disabled={Boolean(insurance_id)}
                >
                  {states?.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Pincode is Required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Please Select Pincode"
                  showSearch
                  disabled={insurance_id}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {pincode?.map((pincodes) => (
                    <Option key={pincodes.id} value={pincodes.id}>
                      {pincodes.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default MagmaInsuredCustomerForm;
