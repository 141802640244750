import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getPropertyTechnicalDataByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-technical-data/technical-data/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPropertyTechMarketValueByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-technical-market-valuation/technical-market-valuation/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getAddressDataByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-technical-data/combine-technical-and-address/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const updatePropertyDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateTechnicalPropertyDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateAddressByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/update-combine-technical-and-address/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/locations/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllTalukaService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/taluka/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllDistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/district/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllCountriesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllUnitsTypeChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/unit-type-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStatusLandHoldingChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/status-land-holding-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllBeforeYearChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/choices-api/before-year-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}




export const getTypePropertyDocumentChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/type-propery-document-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getTypePropertySiteChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/type-property-site-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPlotTypeChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/plot-type-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getZoingChoicesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/zoing-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getMCGPLimitChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/mc-gp-limit-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getByLawChoicesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/by-law-choices`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getMaintainenceLevelChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/maintainece-level-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getapproachRoadTypeChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/approch-road-type-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getGOVPVTRoadChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/gov-pvt-road-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getLocalityTypeChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/locality-type-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getMarkebilityChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/markebility-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getSesmicZoneChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/sesmic-zone-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getRiskDemolitionChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/risk-demolition-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getNoOfKitchenService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/no-of-kitchen-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPortionWitnessChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/portion-witness-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getHouseDeliveryAgencyChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/house-delivery-agency-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getIdentifiedThroughChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/identified-through-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBeforeYearChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/before-year-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getTypeOfStructureService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/type-of-structure-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getPropertyDataService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/property-technical-data/technical-data/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getLandAuthorityService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/land-authority/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

