import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import InsurancePlanMagmaForm from "./InsurancePlanMagmaForm";
import { deleteInsurancePlanMagmaByIdService } from "../services/insurancePlanMagma";
import InsurancePlanMagmaFilter from "./InsurancePlanMagmaFilter";

const InsurancePlanMagma = () => {
  const { Title } = Typography;
  const [insurancePlanMagma, setInsurancePlanMagma] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedInsurancePlanMagma, setSelectedInsurancePlanMagma] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  // Filter InsurancePlanMagma
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    is_active: null,
    is_verified: null,
  });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedInsurancePlanMagma(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);

  const columns = [
    {
      title: "Name",
      dataIndex:"name",
      sorter: true,
      fixed: "left",
      width:200,
    },
    {
      title: "Display Name",
      sorter: true,
      dataIndex:"display_name",
      render: (display_name) => {
        return display_name !== null ? display_name : " - ";
      },
      width:200,
    },
    {
      title: "Code",
      sorter: true,
      dataIndex:"code",
      render: (code) => {
        return code !== null ? code : " - ";
      },
    },

    {
        title: "Sum Assured",
        sorter: true,
        dataIndex:"sum_insured",
        render: (sum_insured) => {
          return sum_insured !== null ? sum_insured : " - ";
        },
        width:200,
      },


      {
        title: "No Of Adults",
        sorter: true,
        dataIndex:"no_of_adults",
        render: (no_of_adults) => {
          return no_of_adults !== null ? no_of_adults : " - ";
        },
        
      },

      {
        title: "No Of Children",
        sorter: true,
        dataIndex:"no_of_children",
        render: (no_of_children) => {
          return no_of_children !== null ? no_of_children : " - ";
        },
        
      },

      {
        title: "Yrs 18 To 45",
        sorter: true,
        dataIndex:"yrs_18_to_45",
        render: (yrs_18_to_45) => {
          return yrs_18_to_45 !== null ? yrs_18_to_45 : " - ";
        },
        
      },

      {
        title: "Yrs 46 To 65",
        sorter: true,
        dataIndex:"yrs_46_to_65",
        render: (yrs_46_to_65) => {
          return yrs_46_to_65 !== null ? yrs_46_to_65 : " - ";
        },
        
      },


      {
        title: "Convalescence Benifits",
        sorter: true,
        dataIndex:"convalescence_benifits",
        render: (convalescence_benifits) => {
          return convalescence_benifits !== null ? convalescence_benifits : " - ";
        },
        
      },
      
      

      {
        title: "Amount Excl Gst",
        sorter: true,
        dataIndex:"amount_excl_gst",
        render: (amount_excl_gst) => {
          return amount_excl_gst !== null ? amount_excl_gst : " - ";
        },
        
      },
      

    {
      title: "Is Active",
      dataIndex:"is_active",
      render: (_, record) => {
        return record?.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex:"is_verified",
      render: (_, record) => {
        return record?.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex:"created_by",
      render: (_, record) => {
        return record?.created_by?.name;
      },
    },
    {
      title: "Created At",
      dataIndex:"created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by?.name;
      },
    },
    {
      title: "Modified At",
      dataIndex:"modified_at",
    },
    {
      title: "Actions",
      fixed:"right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => {
                setSelectedInsurancePlanMagma(record);
                showDrawer();
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setSelectedInsurancePlanMagma(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const deleteInsurancePlanMagma = async () => {
    try {
      const response = await deleteInsurancePlanMagmaByIdService(selectedInsurancePlanMagma?.id);

      if (response?.status === 200 && response?.success === true) {
        message?.success("Insurance Plan Magma deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedInsurancePlanMagma(null);
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
        Insurance Plan Magma 
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filters
          </Button>
          <Button
            onClick={showDrawer}
            type="primary"
            icon={<PlusCircleOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            Add Insurance Plan Magma
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/insurance-plan-magma/"
        tableData={insurancePlanMagma}
        setTableData={setInsurancePlanMagma}
        tableColumns={columns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />
      <Drawer
        title={
          <Header
            title={selectedInsurancePlanMagma ? "Edit" : "Add"}
            onClose={onClose}
            name="Insurance Plan Magma"
          />
        }
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <InsurancePlanMagmaForm
          refreshData={toggleRefreshTableData}
          id={selectedInsurancePlanMagma && selectedInsurancePlanMagma?.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedInsurancePlanMagma} 
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Insurance Plan Magma"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <InsurancePlanMagmaFilter
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteInsurancePlanMagma();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedInsurancePlanMagma?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default InsurancePlanMagma;


