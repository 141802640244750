import React from 'react';
import "../styles/undermaintenance.css";
import { Link } from "react-router-dom";
import { Button, Space} from "antd";
import arrow_icon from "../assets/arrow_icon.png"
import undermaintenance from "../assets/undermaintenance.png"
import whitescreen from "../assets/white_screen.png"
const UnderMaintenance = () => {
  return (
      <div className="full_container">
          <div className="section1">
              <div className="box_content">
                  <div className="heading_section">
                      <div className="headings">
                          <h3>404 error</h3>
                          <h1>Under maintenance</h1>
                      </div>
                      <p className="paragraph">
                          The page you’re looking for is currently under
                          maintenance and will be back soon. Stay tuned!
                      </p>
                  </div>
                  <div className="refresh_section">
                      <Space layout="vertical">
                          <Button className="go_back_btn" size="large">
                              {" "}
                              <img src={arrow_icon} alt=''></img> Go back
                          </Button>
                          <Link to="/">
                              <Button
                                  className="take_home_btn"
                                  type="primary"
                                  size="large">
                                  Take me home
                              </Button>
                          </Link>
                      </Space>
                  </div>
              </div>
          </div>
          <div className="section2">
              <img className="img1" src={undermaintenance} alt=''></img>
              <img className="img2" src={whitescreen} alt=''></img>
          </div>
      </div>
  );
}

export default UnderMaintenance