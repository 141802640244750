import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
import { listLegalIploadDocumentTypeService } from "../../services/documentDetailService";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import pdfIcon from "../../assets/pdf.png"; 
import RViewerJS from "viewerjs-react";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  upload_documents: Yup.mixed()
  .test(
    "is-valid",
    "Document Upload is required",
    (value) => typeof value === "string" || (Array.isArray(value) && value.length > 0)
  )
  .label("Upload Document"),
})

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const LegalUploadInputForm = ({ form ,setDocumentUpload}) => {
  const [, setDocumentName] = useState("");
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [, setDeletePicture] = useState(null);
  const [, setDeleteUploadPicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageId,setImageId] = useState()
  const handleDocumentNameChange = (value) => {
    setDocumentName(value);
    setUploadDisabled(!value);
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    // setFileList(newFileList); 
    const freshImages = newFileList.map((file,index) => ({
      file_path: URL.createObjectURL(file.originFileObj || file),
      isPDF: file.type === "application/pdf",
      id:index
    }));
    
    setImage(freshImages);
 
    setUploadDisabled(true);
    form.resetFields(["upload_documents"]);
  };

  const confirmDelete = () => {
    setImage(
      image.filter((image) => image.id !== imageId)
    );
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }
    const newImage = {
      file_path: URL.createObjectURL(file),
    };
    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const fetchLegalDocumentType = async () => {
    try {
      const response = await listLegalIploadDocumentTypeService();
      setLegalDocumentType(response.data.choices);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    fetchLegalDocumentType();

    form.resetFields(["upload_documents"]);
    setFileList([]);
    setImage([]);
    setUploadDisabled(true);
  }, [form]);



  return (
    <div style={{ border: "1px solid #E4E7EC", marginTop: "20px" }}>
      <div className="property_description_heading">
        <div className="property_description_data">
          Legal Documents and Other Documents
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{ margin: "10px 10px 10px 10px" }}
            // onClick={toggleCollapsepropertydescription}
          >
            {/* {collapsed1 ? <DownOutlined /> : <UpOutlined />} */}
          </div>
        </div>
      </div>

      <div style={{ margin: "10px" }}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="upload_documents"
              label="Enter Document Name"
              rules={image.length ? null : [yupSync]}
              required
            >
              <Select
                placeholder="Please select"
                allowClear
                style={{ flex: 1, marginRight: "8px", width: "100%" }}
                showSearch
                onChange={handleDocumentNameChange}
                // disabled={uploadDisabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
            
              >
                {legalDocumentType?.map((legaldocumenttype) => (
                  <Option
                    key={legaldocumenttype.value}
                    value={legaldocumenttype.value}
                  >
                    {legaldocumenttype.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="file_path" label=" ">
              <Upload
                multiple
                fileList={fileList}
                onChange={handleUploadChange}
                // onRemove={handleRemove}
                beforeUpload={handleBeforeUpload} // Handle file upload manually
                showUploadList={false} // Hide the file list
                disabled={uploadDisabled}
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  disabled={uploadDisabled}
                >
                  Upload Document
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ margin: "10px 10px 10px 10px" }}>
        <RViewerJS>
          <div style={{ display: "flex", flexWrap: "wrap",cursor: "pointer" }}>
            {image?.map((picture, index) => (
              <div key={index} style={{ margin: "10px", position: "relative" }}>
                {picture.isPDF ? (
                  <img
                    src={pdfIcon}
                    alt="PDF Icon"
                    style={{ width: "100px", height: "50px" }}
                  />
                ) : (
                  <img
                    src={picture.file_path}
                    alt=""
                    style={{ width: "100px", height: "50%" }}
                  />
                )}
                <div
                  style={{
                    top: 0,
                    right: 0,
                    color: "#E5233DB2",
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    setDeleteUploadPicture(picture);
                    setImageId(picture.id)
                    setOpenUploadModal(true);
                  }}
                >
                  <DeleteOutlined />
                </div>
              </div>
            ))}
          </div>
          </RViewerJS>
        </div>

        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openModal}
          // onOk={deletePropertyPictures}
          onCancel={() => {
            setOpenModal(false);
            setDeletePicture(null);
          }}
          okType="danger"
        ></Modal>
        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openUploadModal}
          onOk={confirmDelete}
          onCancel={() => {
            setOpenUploadModal(false);
            setDeleteUploadPicture(null);
          }}
          okType="danger"
        ></Modal>
      </div>
    </div>
  );
};

export default LegalUploadInputForm;
