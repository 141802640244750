import {axiosRequest} from "../../../../utils/api/axiosRequest"


export const getUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get("/api/v1/user/get_authenticated_user");
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

export const getBankAccountDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/bank-account-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getCustomerDetailsByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-summary/loan-application-summary/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const getkarzaDocumentDetailsByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/document-detail/karza-document-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }