import {
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  DatePicker,
  Button,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import * as Yup from "yup";
import {
  createRentalService,
  getRentalByIdService,
  listPropertyType,
  updateRentalByIdService,
  getAllMaintencePaidByService,
  getAllMunicipalTaxPaidByService,
  getAllGSTPaidByService,
  getAllRentalModeService,
} from "../services/employmentDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const validationSchema = Yup.object().shape({
  property_type: Yup.string().required("Property type is required"),
  year_of_start: Yup.string().required("Year of start is required"),
  gross_income: Yup.string().required("Gross income is required"),
  net_income: Yup.string().required("Net income is required"),
  lessee_name: Yup.string()
    .required("Lessee name is required")
    .min(2, "Lessee name must be between 2 and 128 characters")
    .max(128, "Lessee name must be between 2 and 128 characters"),

  maintainance_paid_by: Yup.string().required(
    "Maintainance paid by is required"
  ),
  municipal_tax_paid_by: Yup.string().required(
    "Municipal tax paid by is required"
  ),
  gst_paid_by: Yup.string().required("GST paid by is required"),
  rental_mode: Yup.string().required("Rental Mode by is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const RentalForm = ({
  refreshData,
  id,
  open,
  closeForm,
  customer_id,
  selectedEmploymentType,
}) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [allPropertyType, setAllPropertyType] = useState([]);
  const [maintenancePaidBy, setMaintenancePaidBy] = useState([]);
  const [municipalPaidBy, setMunicipalPaidBy] = useState([]);
  const [GSTPaidBy, setGSTPaidBy] = useState([]);
  const [rentalMode, setRentalMode] = useState([]);
  const [loading, setLoading] = useState(false);
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const getAllPropertyType = async () => {
      try {
        const response = await listPropertyType();
        setAllPropertyType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllMunicipalTaxPaidBy = async () => {
      try {
        const response = await getAllMunicipalTaxPaidByService();
        setMunicipalPaidBy(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllMaintencePaidBy = async () => {
      try {
        const response = await getAllMaintencePaidByService();
        setMaintenancePaidBy(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllGSTPaidBy = async () => {
      try {
        const response = await getAllGSTPaidByService();
        setGSTPaidBy(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllRentalMode = async () => {
      try {
        const response = await getAllRentalModeService();
        setRentalMode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllRentalMode();
    getAllPropertyType();
    getAllMunicipalTaxPaidBy();
    getAllMaintencePaidBy();
    getAllGSTPaidBy();
  }, []);

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };
  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  const handleNumberFields = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const customNetIncomeValidator = (_, value) => {
    const grossIncome = form.getFieldValue("gross_income");
    if (parseFloat(value) > parseFloat(grossIncome)) {
      return Promise.reject(
        "Net Income must be less than or equal to Gross Income"
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (id) {
      const getPensionerData = async () => {
        try {
          const response = await getRentalByIdService(id);
          let {
            property_type,
            year_of_start,
            gross_income,
            net_income,
            lessee_name,
            date_of_agreement,
            maintainance_paid_by,
            municipal_tax_paid_by,
            gst_paid_by,
            rental_mode,
            date_of_aggrement_not_mandatory,
          } = response?.data;

          year_of_start = dayjs().year(year_of_start).startOf("year");
          date_of_agreement = dayjs(date_of_agreement, "DD-MM-YYYY");
          form.setFieldsValue({
            property_type: property_type.id,
            year_of_start,
            gross_income,
            net_income,
            lessee_name,
            date_of_agreement,
            maintainance_paid_by,
            municipal_tax_paid_by,
            gst_paid_by,
            rental_mode,
            date_of_aggrement_not_mandatory,
          });
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      };

      getPensionerData();
    }
  }, [id, form, open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    values.net_income = parseFloat(values.net_income, 10);
    // values.rental_amount = parseFloat(values.rental_amount, 10);
    values.gross_income = parseFloat(values.gross_income, 10);
    values.year_of_start = values.year_of_start.format("YYYY");
    values.date_of_agreement = values.date_of_agreement
      ? values.date_of_agreement.format("YYYY-MM-DD")
      : null;
    try {
      if (id) {
        const response = await updateRentalByIdService(id, {
          ...values,
        });

        if (response.status === 200 && response.success) {
          message.success("Employment Details successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const formattedValues = [
          {
            employment_details: {
              customer_detail: customer_id,
              employment_type: selectedEmploymentType,
              loan_detail: parseInt(loan_id),
            },
            rental: {
              ...values,
            },
          },
        ];
        const response = await createRentalService(formattedValues);
        if ((response.status = 200 && response.success)) {
          message.success("Employment details successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        // property_type: "",
        year_of_start: "",
        gross_income: "",
        net_income: "",
        lessee_name: "",
        date_of_agreement: "",
        // maintainance_paid_by: "",
        // municipal_tax_paid_by: "",
        // gst_paid_by: "",
        date_of_aggrement_not_mandatory: "",
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="property_type"
            label="Property Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allPropertyType?.map((investment) => (
                <Select.Option key={investment.id} value={investment.id}>
                  {investment.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="year_of_start"
            label="Year Of Start (In Year) "
            rules={[yupSync]}
            required
          >
            <DatePicker
              picker="year"
              disabledDate={disabledDate}
              placeholder="Select Date"
              // format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="gross_income"
            label="Gross Rent"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Gross Income"
              onChange={(e) => handleNumberFields(e, "gross_income")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="net_income"
            label="Net Rent"
            rules={[
              { required: true, message: "Net income is required" },
              { validator: customNetIncomeValidator },
            ]}
            required
          >
            <Input
              placeholder="Please Enter Your Net Income"
              onChange={(e) => handleNumberFields(e, "net_income")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="lessee_name"
            label="Lessee Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Lessee Name"
              onChange={(e) => handleInput(e, "lessee_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="date_of_agreement" label="Date Of Agreement">
            <DatePicker
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="maintainance_paid_by"
            label="Maintainance Paid By"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {maintenancePaidBy?.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="municipal_tax_paid_by"
            label="Municipal Tax Paid By"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {municipalPaidBy?.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="gst_paid_by"
            label="GST Paid By"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {GSTPaidBy?.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="rental_mode"
            label="Rental Mode"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {rentalMode?.map((mode) => (
                <Select.Option key={mode} value={mode}>
                  {mode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default RentalForm;
