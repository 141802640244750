import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import '../styles/partDisbursalDetails.css';
import "../../partDisbursedInitiate/styles/partDisbursed.css";
import { getPartDisbursalDetailsByIdService } from "../services/partDisbursalDetailsService";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import RViewerJS from "viewerjs-react";
import dummy_img from "../assets/dummyImage.png";


const PartDisbursalDetails = () => {
  const [partDisbursalDetails, setPartDisbursalDetails] = useState([]);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPartDisbursalDetailsByIdService(loan_id);
        setPartDisbursalDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (key) => {
    console.log('Selected Tab Key:', key);
  };

  return (
    <div className="part_disbursed_table">
      <div className="main_disbursal">
        <div className="part_disbursed_heading">
          <div className="part_disbursed_data">
            <h3>Part Disbursal Details</h3>
          </div>
        </div>

        <Tabs
          defaultActiveKey="1"
          type="card"
          onChange={onTabChange}
          style={{ marginTop: '1rem', marginLeft: '1rem' }}
        >
          {partDisbursalDetails.map((item, index) => (
            <Tabs.TabPane
              tab={`Part Disbursment ${index + 1}`}
              key={item.id}
            >

              <div className='main_part_disbursal_details_container' >
                <div className='part_disbursal_container'>
                  <div className='part_disbursal_card1'>
                    <div className="label">Property Address</div>
                    <div className="data">{item.property_detail.property_address}</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Type</div>
                    <div className="data">{item.construction_type || 'N/A'}</div>
                  </div>

                  <div className='part_disbursal_card1'>
                    <div className="label">Builder Name</div>
                    <div className="data">{item.property_detail.property_builder_name}</div>
                  </div>
                </div>

                <div className='part_disbursal_container'>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Description</div>
                    <div className="data">
                      ₹ {new Intl.NumberFormat('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(item.disbursment_amount_requested)}
                    </div>
                  </div>

                  <div className='part_disbursal_card1'>
                    <div className="label">Disb Amount Requested</div>
                    <div className="data">
                      ₹ {new Intl.NumberFormat('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(item.disbursment_amount_requested)}
                    </div>
                  </div>

                  <div className='part_disbursal_card1'>
                    <div className="label">Disb Requested Date</div>
                    <div className="data">{item.disbursment_requested_date}</div>
                  </div>
                </div>

                <div className='part_disbursal_container'>
                  <div className='part_disbursal_card1'>
                    <div className="label">Type of Visit</div>
                    <div className="data">{item.type_of_visit}</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Property Structure</div>
                    <div className="data">{item.property_structure.name}</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Stage</div>
                    <div className="data">{item.construction_stage.name}</div>
                  </div>
                </div>

                <div className='part_disbursal_container'>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Completion % (Policy)</div>
                    <div className="data">{item.construction_completion_policy}%</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Completion % (As per User)</div>
                    <div className="data">{item.construction_completion_as_per_user}%</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Completion Recommendation % (Policy)</div>
                    <div className="data">{item.construction_completion_recommended_policy}%</div>
                  </div>
                </div>

                <div className='part_disbursal_container'>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Completion Recommendation % (As per User)</div>
                    <div className="data">{item.construction_completion_recommended_as_per_user}%</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Raised By</div>
                    <div className="data">{item.created_by.name}</div>
                  </div>

                  <div className='part_disbursal_card1'>
                    <div className="label">Credit Percentage Recommended</div>
                    <div className="data">{item.credit_percentage_recommended}%</div>
                  </div>
                </div>

                <div className='part_disbursal_container'>
                  <div className='part_disbursal_card1'>
                    <div className="label">Construction Stop</div>
                    <div className="data">{item.construction_stop}</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Loan Downsizing Recommendation</div>
                    <div className="data">{item.loan_downsizing_recommendation}</div>
                  </div>
                  <div className='part_disbursal_card1'>
                    <div className="label">Current Status</div>
                    <div className="data">{item.current_status.display_name}</div>
                  </div>
                </div>

                <div className='part_disbursal_container' style={{ marginBottom: '2rem' }}>
                  <div className='part_disbursal_card1'>
                    <div className="label">Percentage Commercial Area</div>
                    <div className="data">{item.percentage_commercial_area}%</div>
                  </div>


                  <div className='part_disbursal_card1'>
                    <div className="label">Credit Approval Amount</div>
                    <div className="data">
                      ₹ {new Intl.NumberFormat('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(item.credit_approval_amount)}
                    </div>
                  </div>

                  <div className='part_disbursal_card1'>
                    <div className="label"></div>
                    <div className="data"></div>
                  </div>
                </div>
                <div className="part_disbursed_data" >
                  <h3>Part Disbursal Image</h3>
                </div>

                <div className='part_disbursal_image' style={{ marginBottom: '2rem' }}>
                  {item.part_disbursment_documents && item.part_disbursment_documents.length > 0 ? (
                    item.part_disbursment_documents.map((doc) => (
                      <div className='part_disbursal_card1_image' key={doc.id}>
                        <div className="label">{doc.name}</div>

                        <RViewerJS
                          options={{
                            url: (image) =>
                              image.getAttribute("data-original"),
                          }}
                        >
                          <img
                            src={doc.thumb_file_path || dummy_img}
                            data-original={doc.file_path || dummy_img}
                            width="100px"
                            height="100px"
                            alt={doc.name}
                            style={{
                              width: doc.thumb_file_path ? "100px" : "85px",
                              height: "100px",
                              cursor: "pointer",
                              border: "none",
                            }}
                          />
                          </RViewerJS>
                      </div>



                    ))
                  ) : (
                  <div>No Documents Available</div>
                  )}
                </div>
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default PartDisbursalDetails;

