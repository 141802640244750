import React, {useState, useEffect } from 'react';
import { Table,message } from "antd";
import { getDisbursalHistoryByIdService } from "../services/PartDisbursalHistory";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";  
import "../../partDisbursedInitiate/styles/partDisbursed.css";


const PartDisbursalHistory = () => {
 const [disbursalHistory, setDisbursalHistory] = useState([]);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDisbursalHistoryByIdService(loan_id);
        setDisbursalHistory(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
  }, [loan_id]);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Assigned To",
      dataIndex: ["assigned_to", "name"],
      key: "assigned_to",
      render: (assignedTo) => assignedTo || "-",
    },
    {
      title: "Stage",
      dataIndex: ["construction_stage", "display_name"],
      key: "stage",
      render: (stage) => stage || "-",
    },
    {
      title: "Status",
      dataIndex: ["current_status", "display_name"],
      key: "status",
      render: (status) => status || "-",
    },
    {
      title: "Initiated Date",
      dataIndex: "initiated_date",
      key: "initiated_date",
      render: (date) => date ? dayjs(date).format("DD-MM-YYYY") : "-",
    },
    {
      title: "Completed Date",
      dataIndex: "completed_date",
      key: "completed_date",
      render: (date) => date ? dayjs(date).format("DD-MM-YYYY") : "-",
    },
  ];

  return (
    <div>
      <>
        <div className="part_disbursed_table">
          <div className="part_disbursed_heading">
            <div className="part_disbursed_data" >
              <h3>Part Disbursal History</h3>
            </div>
          </div>

          <div className='table_content'>
          <Table
          columns={columns}
          dataSource={disbursalHistory}
          rowKey="id"
          pagination={false}
        />
          </div>

        </div>
      </>


    </div>

  )
}

export default PartDisbursalHistory


