import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createPDCService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/loan/pdc-detail/",
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listBankService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/bank-account-detail-pdc/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updatePDCByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/loan/pdc-detail/${id}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const getChequeById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/pdc-detail/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const deleteChequeByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(
        `/api/v1/loan/pdc-detail/${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getChequeByLoanIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/pdc-detail/pdc-detail-list/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const listTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/choices-api/get-cheque-type`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPayableTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/loan/choices-api/get-payble-type"
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/loan/choices-api/get-status"
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getBankAccountsByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/bank-account-detail/bank-account-detail-pdc/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateMultipleChequesService = (chequeIds, remark) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `api/v1/loan/pdc-detail/remark/`,
        {
          ids: chequeIds,
          remark,
        }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomersByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loanId}`
      );
      console.log(response.data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
