import { Table } from 'antd';
import React from 'react'

const IncomeEstimateView = ({ nonCashIncomeData, renderEditButton, showDrawer }) => {
    const IncomeMultipliercolumns = [
        {
            title: 'SR.NO',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'F.Y',
            dataIndex: 'financial_year',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Profit After Tax  - APPLICANT',
            dataIndex: 'profit_after_tax',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Depreciation',
            dataIndex: 'depreciation',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Interest Of Loan Emi',
            dataIndex: 'interest_on_loans',
            render: (text) => text ? text : '-',
        },
        {
            title: (
                <div>
                    Interest paid to family members - <br />
                    provided they are on loan structure
                </div>
            ),
            // title: 'Interest paid to family members - provided they are on loan structure ',
            dataIndex: 'interest_paid_to_family_members',
            key: 'interest_paid_to_family_members',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Rental Income - Cash',
            dataIndex: 'rental_income_cash',
            key: 'rental_income_cash',
            render: (text) => text ? text : '-',
        },
       
        {
            title: 'Rental Income - Non Cash',
            dataIndex: 'rental_income_non_cash',
            key: 'rental_income_non_cash',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Dividend / Interest',
            dataIndex: 'dividend_or_interest',
            key: 'dividend_or_interest',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Agriculture Income',
            dataIndex: 'agricultural_income',
            key: 'agricultural_income',
            render: (text) => text ? text : '-',
        },
    ];
    return (
        <div className="heading_details_container">
            <div style={{ overflowX: "auto" }}>
                {nonCashIncomeData?.map((data) => (
                    <div key={data.id} style={{ marginBottom: "20px" }}>
                        <div className="heading_text">{data.customer?.name}{renderEditButton(showDrawer, data.customer?.id)}</div>

                        <Table
                            className="insurance_table"
                            columns={IncomeMultipliercolumns}
                            dataSource={data.income_detail}
                            pagination={false}
                            rowKey="id"
                            scroll={{ x: 'max-content' }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default IncomeEstimateView