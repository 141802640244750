// const isModifiedAfterCreated = (created_at, modified_at) => {
//     // Convert strings to JavaScript Date objects
//     const createdAtDate = new Date(created_at.split('.')[0] + 'Z');
//     const modifiedAtDate = new Date(modified_at.split('.')[0] + 'Z');

//     // Compare the dates
//     return modifiedAtDate > createdAtDate;
//   };
//   export default isModifiedAfterCreated


// const isModifiedAfterCreated = (created_at, modified_at) => {
//     const parseDateString = (dateTimeString) => {
//         const parts = dateTimeString.split(/[- :]/);
//         const month = parseInt(parts[1], 10) - 1;
//         let hour = parseInt(parts[3], 10);
//         const isPM = parts[5].toUpperCase() === 'PM';
//         if (hour === 12) {
//             hour = isPM ? 12 : 0;
//         } else {
//             hour += isPM ? 12 : 0;
//         }
//         return new Date(parts[2], month, parts[0], hour, parseInt(parts[4], 10));
//     }

//     const createdDate = parseDateString(created_at);
//     const modifiedDate = parseDateString(modified_at);

//     return modifiedDate.getTime() > createdDate.getTime();
// };
// export default isModifiedAfterCreated;

const isModifiedAfterCreated = (created_at, modified_at) => {
    const parseDateString = (dateTimeString) => {
        const regex = /(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2}) (AM|PM)/;
        const match = dateTimeString.match(regex);

        if (!match) {
            throw new Error("Invalid date format");
        }

        const [ , day, month, year, hourStr, minute, second, period ] = match;
        let hour = parseInt(hourStr, 10);
        const isPM = period.toUpperCase() === 'PM';

        if (hour === 12) {
            hour = isPM ? 12 : 0;
        } else {
            hour += isPM ? 12 : 0;
        }

        return new Date(year, month - 1, day, hour, minute, second);
    };

    const createdDate = parseDateString(created_at);
    const modifiedDate = parseDateString(modified_at);

    return modifiedDate.getTime() > createdDate.getTime();
};

export default isModifiedAfterCreated;

