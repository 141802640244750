import React, { useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { deleteUserByIdService } from "../services/userService";
import Header from "../../layout/views/Header";
import DataTable from "../../../utils/dataTable/DataTable";
import UserFormController from "./UserFormController";
import TableDisplayCard from "../../../utils/dataTable/TableDisplayCard";
import UserFilterForm from "./UserFilterForm";
import { Form } from "antd";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../utils/authorization/authorizeTableColumns";
/**
 * User view.
 * @returns React component.
 */
const User = () => {
  const { Title } = Typography;
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [form] = Form.useForm();
  const { user_data } = useSelector((state) => state.user);

  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    department: [],
    designation: [],
    location: [],
    zone: [],
  });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
    setOpen(false);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const closeDrawer = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      render: (code) => {
        return code !== null ? code : "-";
      },
    },
    {
      title: "Employee Code",
      dataIndex: "employee_code",
      sorter: true,
      render: (employee_code) => {
        return employee_code !== null ? employee_code : "-";
      },
    },
    {
      title: "Department",
      dataIndex: "department",

      render: (_, record) => {
        return record.department ? record.department.display_name : "-";
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",

      render: (_, record) => {
        return record.designation ? record.designation.display_name : "-";
      },
    },
    {
      title: "Country Code",
      dataIndex: "country_code",
      sorter: true,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },

    {
      title: "Roles",
      render: (_, record) => {
        return (
          <TableDisplayCard
            title={"Roles"}
            data={record}
            property={"roles"}
            setSelected={setSelectedUser}
          />
        );
      },
    },
    {
      title: "Permissions",
      render: (_, record) => {
        return (
          <TableDisplayCard
            title={"Permissions"}
            data={record}
            property={"permissions"}
            setSelected={setSelectedUser}
          />
        );
      },
    },

    {
      title: "Zones",
      render: (_, record) => {
        return (
          <TableDisplayCard
            title={"Zones"}
            data={record}
            property={"zones"}
            setSelected={setSelectedUser}
          />
        );
      },
    },

    {
      title: "Locations",
      render: (_, record) => {
        return (
          <TableDisplayCard
            title={"Locations"}
            data={record}
            property={"locations"}
            setSelected={setSelectedUser}
          />
        );
      },
    },

    {
      title: "Last Login",
      dataIndex: "last_login",
      sorter: true,
      render: (lastLogin, record) => {
        return lastLogin !== null ? lastLogin : "-";
      },
    },
    {
      title: "Is Admin",
      dataIndex: "is_admin",
      render: (is_admin, record) =>
        is_admin === true ? (
          <Badge
            text="Yes"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : is_admin === false ? (
          <Badge
            text="No"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        ) : (
          "-"
        ),
    },

    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by ? record.created_by.name : "-";
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: true,
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by ? record.modified_by.name : "-";
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
      sorter: true,
    },
  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["MPSIDD6"]}
            >
              <EditOutlined
                onClick={() => {
                  setSelectedUser(record);
                  setSelectedUserId(record.id);
                  showDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["MPTUSE2"]}
            >
              <DeleteOutlined
                onClick={() => {
                  setSelectedUser(record);
                  setOpenModal(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["MPSIDD6","MPTUSE2"]
  );

  const deleteUser = async () => {
    try {
      const response = await deleteUserByIdService(selectedUser?.id);
    
      if (response.status === 200 && response.success === true) {
        message.success("User deleted Successfully");
        setOpenModal(false);
        toggleRefreshTableData();
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  }; 
 
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Users
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filter
          </Button>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["MPRAEA1"]}
          >
            <Button
              onClick={showDrawer}
              type="primary"
              icon={<PlusCircleOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              Add User
            </Button>
          </ConditionalRender >
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/user/filter-user/"
        tableData={filteredData}
        setTableData={setFilteredData}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />

      <Drawer
        title={
          <Header
            title={selectedUser ? "Edit" : "Add"}
            onClose={onClose}
            name="User Profile"
          />
        }
        width={720}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
        closable={false}
      >
        <UserFormController
          refreshData={toggleRefreshTableData}
          id={selectedUserId}
          open={open}
          closeDrawer={closeDrawer}
          closeForm={onClose}
          onCancel={onClose}
          setId={setSelectedUserId}
        />
      </Drawer>

      <Drawer
        title={
          <Header title="User" name="User" onClose={closeFilterForm} />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        bodyStyle={{
          paddingBottom: 80,
        }}
        closable={false}
      >
        <UserFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          form={form}
          placement="right"
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteUser();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedUser?.name}`}</p>
      </Modal>
    </div>
  );
};

export default User;
