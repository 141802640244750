import { axiosRequest } from "../.././../../utils/api/axiosRequest";
export const getCustomerHistoryData = (bench_id, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/customer/history/history?bench_id=${bench_id}&id=${id}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}