import React, { useEffect, useState} from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer, Form } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { deleteChargesByIdService } from "../services/chargesTypeServices";
import ChargesForm from "./ChargesTypeForm";
import Header from "../../../layout/views/Header";
import DataTable from "../../../../utils/dataTable/DataTable";
import ChargesTypeFilterForm from "./ChargesTypeFilterForm";

const ChargesType = () => {
  const { Title } = Typography;
  const [chargesType, setChargesType] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  const [form] = Form.useForm();

    // Filter Charges Type
    const [showFilterForm, setShowFilterForm] = useState(false);
    const [filterFormData, setFilterFormData] = useState({
      filter: false,
      is_active: null,
      is_verified: null,
    });

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setSelectedCharges(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      width:200,
    },
    {
      title: "Display Name",
      sorter: true,
      dataIndex: "display_name",
      render: (display_name) => (display_name !== null ? display_name : " - "),
      width:200,
    },
    {
      title: "Code",
      sorter: true,
      dataIndex: "code",
      render: (code) => (code !== null ? code : " - "),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => (
        <Badge
          text={record.is_active ? "Active" : "Inactive"}
          color={record.is_active ? "#52C41A" : "#FF4D4F"}
          style={{ fontSize: "16px", color: record.is_active ? "#52C41A" : "#FF4D4F" }}
        />
      ),
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => (
        <Badge
          text={record.is_verified ? "Verified" : "Unverified"}
          color={record.is_verified ? "#52C41A" : "#FF4D4F"}
          style={{ fontSize: "16px", color: record.is_verified ? "#52C41A" : "#FF4D4F" }}
        />
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => record.created_by.name,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => record.modified_by.name,
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setSelectedCharges(record);
              showDrawer();
            }}
          />
          <DeleteOutlined
            onClick={() => {
              setSelectedCharges(record);
              setOpenModal(true);
            }}
          />
        </Space>
      ),
    },
  ];

  const deleteCharges = async () => {
    try {
      const response = await deleteChargesByIdService(selectedCharges?.id);

      if (response.status === 200 && response.success === true) {
        message.success("Charges deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedCharges(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Charges Type
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
         <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filters
          </Button>
          <Button
            onClick={showDrawer}
            type="primary"
            icon={<PlusCircleOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            Add Charges Type
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/charge-type/"
        tableData={chargesType}
        setTableData={setChargesType}
        tableColumns={columns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />
      <Drawer
        title={
          <Header
            title={selectedCharges ? "Edit" : "Add"}
            onClose={onClose}
            name="Charges Type"
          />
        }
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <ChargesForm
          refreshData={toggleRefreshTableData}
          id={selectedCharges && selectedCharges?.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedCharges} 
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Charges Type"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <ChargesTypeFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}

        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteCharges();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedCharges?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default ChargesType;
