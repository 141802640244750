import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Drawer, Empty, message } from "antd";
import { useSelector } from "react-redux";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import group from "../assets/image/Group.png";
import dummy_img from "../assets/dummyImage.png";
import "../styles/personalDetails.css";
import "../../style/customerStyle.css";
import { getPersonalDetailsByIdService } from "../services/personalDetailsServices";
import Header from "../../../../layout/views/Header";
import PersonalDetailsForm from "./PersonalDetailsForm";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const PersonalDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [personalData, setPersonalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedPersonal, setSelectedPersonal] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customer, setCustomer] = useState();
  const { user_data } = useSelector((state) => state.user);
  const [customHeaders, setCustomHeaders] = useState([]);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedPersonal(null);
    setCustomer(null);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  // useEffect(() => {
  //   if (loan_id) {
  //     const fetchData = async () => {
  //       setLoading(true);
  //       try {
  //         const response = await getPersonalDetailsByIdService(loan_id);
  //         setPersonalData(response.data.customers);
  //       } catch (error) {
  //         message.error(
  //           ErrorMessage(
  //             error?.response?.status,
  //             error?.response?.data?.message
  //           )
  //         );
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     fetchData();
  //   } else {
  //     setLoading(false);
  //   }
  // }, [refreshData, loan_id]);

  useEffect(() => {
    if (loan_id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await getPersonalDetailsByIdService(loan_id);
          setPersonalData(response.data.customers);

          const customerTypes = response?.data?.customers?.map(
            (customer) => customer?.customer_type?.code
          );
          const companyCustomerTypes = ["MCTCPAP3", "MCTUPNM6", "MCTNCPA2"];
          const isPresent = customerTypes.some((item) =>
            companyCustomerTypes.includes(item)
          );

          let newHeaders = [...additionalHeaders];

          if (isPresent) {
            newHeaders = [
              ...newHeaders.slice(0, 2),
              "Company Name",
              "Office Address Type",
              "Business Construction Type",
              "SPOC Location",
              "Corporate Identification Number (CIN)",
              "Taxpayer Identification Numbers (TIN)",
              ...newHeaders.slice(2),
            ];
          }

          setCustomHeaders(newHeaders);
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, loan_id]);

  const additionalHeaders = [
    "Key Parameter's",
    "Profile Photo",
    "First Name",
    "Middle Name",
    "Last Name",
    "Date Of Birth",
    "Age",
    "Gender",
    "Marital status",
    "Caste Category",
    "Place of Birth",
    "Nationality",
    "Religion",
    "University",
    "Father Name",
    "Spouse Name",
    "Email",
    "Relation With Applicant",
    "Education",
    "Family Type",
    "Phone Number",
    "Mobile Number",
    "Alternate Mobile Number",
  ];

  const fieldNameMap = {
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    date_of_birth: "Date Of Birth",
    age: "Age",
    gender: "Gender",
    marital_status: "Marital status",
    place_of_birth: "Place of Birth",
    nationality: "Nationality",
    religion: "Religion",
    university: "University",
    father_name: "Father Name",
    spouse_name: "Spouse Name",
    email: "Email",
    relation_with_applicant: "Relation With Applicant",
    education: "Education",
    family_type: "Family Type",
    phone_country_code: "Phone Country Code",
    phone_number: "Phone Number",
    mobile_country_code: "Mobile Country Code",
    mobile_number: "Mobile Number",
    alt_mobile_country_code: "Alternate Mobile Country Code",
    alt_mobile_number: "Alternate Mobile Number",
    caste_category: "Caste Category",
    salutation: "Salutation",
  };
  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };

  return (
    <div className="tab_form_container">
      <Drawer
        title={
          <Header
            title={selectedPersonal ? "Add" : "Edit"}
            onClose={onClose}
            name={`${selectedCustomer?.first_name} ${selectedCustomer?.middle_name}  ${selectedCustomer?.last_name}'s Details`}
          />
        }
        width={970}
        refreshData={toggleRefreshData}
        onClose={onClose}
        open={open}
        body-Style={{ paddingBottom: 80 }}
        closable={false}
        loan_id={loan_id}
      >
        <PersonalDetailsForm
          refreshData={toggleRefreshData}
          open={open}
          closeForm={onClose}
          id={selectedCustomer}
          customer={customer}
          setCustomer={setCustomer}
          loan_id={loan_id}
        />
      </Drawer>
      <div className="tab_form_main_container fade-in">
        {loading ? (
          <Loading loading={loading} />
        ) : personalData?.length > 0 ? (
          <table className="tab_form_main_table">
            <thead>
              <tr>
                <th className="tab_form_key_parameters">{customHeaders[0]}</th>
                {personalData.map((person, index) => (
                  <th key={index} className="tab_form_top_heading">
                    {customHeaders[0] === "Key Parameter's" && (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>
                            {`${person?.first_name} ${person?.middle_name} ${person?.last_name} `}{" "}
                          </span>
                        
                          <span>
                            <span>
                              {`(${person?.customer_type?.display_name})`}{" "}
                            </span>
                            <img
                              src={group}
                              alt="group"
                              style={{ marginLeft: "5px" }}
                            />
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          {person?.created_at &&
                            person?.modified_at &&
                            isModifiedAfterCreated(
                              person?.created_at,
                              person?.modified_at
                            ) && (
                              <HistoryTable
                                id={person?.id}
                                bench_id={"66bcfc08fcb1a944"}
                                fieldNameMap={fieldNameMap}
                              />
                            )}
                          <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["MPITDE9"]}
                          >
                            <div
                              className="tab_form_sub_edit"
                              onClick={() => {
                                setSelectedCustomer(person);
                                showDrawer();
                              }}
                            >
                              <EditButton />
                            </div>
                          </ConditionalRender>
                        </div>
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: customHeaders.length - 1 }).map(
                    (_, index) => (
                      <tr key={`loading-${index}`}>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )
                  )
                : customHeaders?.slice(1).map((header, rowIndex) => (
                    <tr
                      key={`row-${rowIndex}`}
                      className={`Personal_main_data_table ${
                        header === "PD Entries"
                          ? " tab_form_gray_highlighted_row"
                          : header === "No Of Years In Current City"
                          ? " tab_form_highlighted_row_orange"
                          : ""
                      }`}
                    >
                      <td
                        className={`tab_form_left_heading${
                          header === "PD Entries" ||
                          header === "No Of Years In Current City"
                            ? ` ${
                                header === "PD Entries"
                                  ? "tab_form_gray_highlighted_row "
                                  : "tab_form_highlighted_row_orange"
                              }`
                            : ""
                        }`}
                      >
                        {header}
                      </td>
                      {personalData?.map((person, colIndex) => (
                        <td
                          key={`col-${colIndex}`}
                          className={`tab_form_main_data_table ${getTableColumnClass(
                            personalData.length
                          )}`}
                        >
                          {header === "Profile Photo" ? (
                            // <RViewerJS>
                            //   <img
                            //     className="profile"
                            //     src={person.thumb_image || dummy_img}
                            //     alt="Profile"
                            //     style={{
                            //       width: person.image ? "100px" : "85px",
                            //       height: "100px",
                            //       cursor: "pointer",
                            //     }}
                            //   />
                            // </RViewerJS>

                            <RViewerJS
                              options={{
                                url: (image) =>
                                  image.getAttribute("data-original"),
                              }}
                            >
                              <img
                                className="profile"
                                src={person.thumb_image || dummy_img}
                                data-original={person.image || dummy_img}
                                alt="Profile"
                                style={{
                                  width: person.thumb_image ? "100px" : "85px",
                                  height: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            </RViewerJS>
                          ) : (
                            person[header.replace(/ /g, "_")] || ""
                          )}

                          {header === "Company Name" && (
                            <span>{person?.name || "-"}</span>
                          )}
                          {header === "Office Address Type" && (
                            <span>{person?.office_address_type || "-"}</span>
                          )}
                          {header === "Business Construction Type" && (
                            <span>
                              {person?.business_construction_type || "-"}
                            </span>
                          )}
                          {header === "SPOC Location" && (
                            <span>{person?.spoc_location || "-"}</span>
                          )}
                          {header === "Corporate Identification Number (CIN)" && (
                            <span>{person?.cin || "-"}</span>
                          )}
                          {header === "Taxpayer Identification Numbers (TIN)" && (
                            <span>{person?.tin || "-"}</span>
                          )}
                          {header === "First Name" && (
                            <span>
                              {person?.salutation} {person?.first_name || "-"}
                            </span>
                          )}

                          {header === "Middle Name" && (
                            <span>{person?.middle_name || "-"}</span>
                          )}

                          {header === "Last Name" && (
                            <span>{person?.last_name || "-"}</span>
                          )}

                          {header === "Date Of Birth" && (
                            <span>{person?.date_of_birth || "-"}</span>
                          )}

                          {header === "Age" && (
                            <span>{person?.age || "-"}</span>
                          )}

                          {header === "Place of Birth" && (
                            <span>{person?.place_of_birth || "-"}</span>
                          )}

                          {header === "Gender" && (
                            <span>{person?.gender || "-"}</span>
                          )}

                          {header === "Marital status" && (
                            <span>{person?.marital_status || "-"}</span>
                          )}

                          {header === "Caste Category" && (
                            <span>
                              {person?.caste_category?.display_name || "-"}
                            </span>
                          )}

                          {header === "Nationality" && (
                            <span>{person?.nationality || "-"}</span>
                          )}

                          {header === "Religion" && (
                            <span>{person?.religion || "-"}</span>
                          )}

                          {header === "University" && (
                            <span>{person?.university || "-"}</span>
                          )}

                          {header === "Father Name" && (
                            <span>{person?.father_name || "-"}</span>
                          )}

                          {header === "Spouse Name" && (
                            <span>{person?.spouse_name || "-"}</span>
                          )}

                          {header === "Email" && (
                            <span>{person?.email || "-"}</span>
                          )}
                          {header === "Relation With Applicant" && (
                            <span>
                              {person?.relation_with_applicant?.display_name ||
                                "-"}
                            </span>
                          )}

                          {header === "Education" && (
                            <span>{person?.education || "-"}</span>
                          )}

                          {header === "Family Type" && (
                            <span>{person?.family_type || "-"}</span>
                          )}
                          {header === "Phone Number" && (
                            <span>
                              {person?.phone_number ? (
                                <>+91 {person.phone_number}</>
                              ) : (
                                "-"
                              )}
                            </span>
                          )}

                          {header === "Mobile Number" && (
                            <span>
                              {person?.mobile_country_code
                                ? `+${person.mobile_country_code}`
                                : ""}
                              &nbsp;&nbsp;
                              {person?.mobile_number || "-"}
                            </span>
                          )}

                          {/* {header === "Alternate Mobile Number" && (
                          <span>
                            {person?.alt_mobile_number ? (
                              <>
                                {person?.alt_mobile_country_code ? `+${person.alt_mobile_country_code} ` : ''}{person.alt_mobile_number}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                        )} */}

                          {header === "Alternate Mobile Number" && (
                            <span>
                              {person?.alt_mobile_number ? (
                                <>+91 {person.alt_mobile_number}</>
                              ) : (
                                "-"
                              )}
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        ) : (
          <div className="no_data_found_message">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalDetails;
