import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getMonthlyExpensesById = (customer_id, loan_detail_id, bank_detail_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/account-aggregator/get-category-expenses?customer_id=${customer_id}&loan_detail_id=${loan_detail_id}&bank_detail_id=${bank_detail_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
export const getCustomerBankListByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/customer-wise-bank-list/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const createBankBalance = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/bank-balance-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const createCreditTranstionDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/account-aggregator/manual-save-txn", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listBankBalanceData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-balance-detail/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllBankBalanceByLoanId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/bank-balance-detail/bank-balance-by-customer/${customer_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateBankBalaceByIdService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/bank-balance-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getbankbalanceByID = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-balance-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


