import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getRefrencesDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/reference-detail/reference-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createReferenceService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/reference-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getReferncetById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/reference-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateInvestmentDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/reference-detail/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listRelationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/relation/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}; 
export const listCityervice = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/city/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}; 
export const listTalukaService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/taluka/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}; 

export const listdistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/district/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPincodeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listAddressTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/address-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const pincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const listStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}; 
export const listCountryService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}; 




export const listSalutationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/choices/salutation`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}; 


export const listReferenceType  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/reference_type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listSalutationType  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/salutation`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteReferenceDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/reference-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}