import React from 'react';
import { EyeOutlined } from "@ant-design/icons";

const ScoreTable = ({ highMarkData }) => {

  return (
    <div style={{ padding: '20px 0px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ textAlign: 'center', color: '#27658F' }}>Score Table</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', color: '#333', textAlign: 'center' }}>Bureau</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', color: '#333', textAlign: 'center' }}>Score</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', color: '#333', textAlign: 'center' }}>View Report</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
              {highMarkData?.highMarkScore === "02"
                ? 'HighMark'
                : highMarkData?.highMarkScore === "01"
                  ? 'Cibil'
                  : '-'}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
              {highMarkData?.data.score_detail["SCORE-VALUE"]}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
              <EyeOutlined />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTable;
