import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Input, Button, Table, Space, message, Row, Col, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import {
    createIncomeFormCam,
    getIncomeCustomerDataByCustomerId,
    getCustomersByLoanId,
    listMonthYear,
    updateIncomeFormByCustomerId,
    deleteIncomeDetailsByIdService
} from '../services/incomeDetailsServices';


const IncomeNonCashForm = ({ closeForm, open, refreshData, id }) => {
    const [form] = Form.useForm();
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [getCustomerId, setCustomerId] = useState(null);
    const [allMonthYear, setAllMonthYear] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const handleChange = (namePath, value) => {
        form.setFieldsValue({ [namePath]: value });
    };
    const { encrypted_loan_id } = useParams();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }



    const getNonCashData = async () => {
        if (id) {
            try {
                const response = await getIncomeCustomerDataByCustomerId(id);
                let { customer_detail, data } = response?.data;
                setCustomerId(customer_detail?.customer_id)
                // const customerName = getCustomerNameById(customer_detail);
                form.setFieldsValue({
                    customer: customer_detail?.customer_id,
                    salaryDetails: data.map(item => ({
                        id: item.id,
                        month_of_salary: item.month_of_salary,
                        monthly_net_salary_non_cash: item.monthly_income,
                        over_time_pay: item.over_time_pay
                    }))
                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }
    };





    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        const fetchCustomersByLoanId = async () => {
            try {
                const response = await getCustomersByLoanId(loan_id);
                const customers = response?.data?.customer_list?.map((customer) => {
                    return {
                        id: customer.id,
                        name: `${customer.first_name} ${customer.middle_name} ${customer.last_name}`,
                    };
                });
                setCustomerList(customers);

                // setLoading(false);
            } catch (error) {
                // setLoading(false);
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getMonthYear = async () => {
            try {
                const response = await listMonthYear();
                setAllMonthYear(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchCustomersByLoanId();
        getMonthYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCustomerchange = (value) => {
        setSelectedCustomer(value);
    };



    const handleSubmit = async (values) => {
        try {
            if (id) {
                const payload = {
                    customer_detail: getCustomerId,
                    loan_detail: loan_id,
                    data: values.salaryDetails.map((item) => ({
                        id: item.id,
                        monthly_net_salary_non_cash: item.monthly_net_salary_non_cash,                     // Ensure the ID is passed
                        month_of_salary: item.month_of_salary,  // Map month_of_salary if available
                        over_time_pay: item.over_time_pay // Map over_time_pay field
                        // Add other necessary fields here
                    })),
                };
                const response = await updateIncomeFormByCustomerId(payload);
                if ((response.status = 200 && response.success)) {
                    message.success("Income details of non cash successfully updated");
                    form.resetFields();
                    refreshData();
                    closeForm();
                }
            } else {
                const payload = {
                    loan_detail: loan_id,
                    customer_detail: selectedCustomer,
                    data: values.salaryDetails,         // Use the salaryDetails from the form values
                };

                const response = await createIncomeFormCam(payload);
                if ((response.status = 200 && response.success)) {
                    message.success("Income details of non cash successfully created");
                    form.resetFields();
                    closeForm();
                    refreshData();
                }
            }



        }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };


    const handleDelete = async () => {
        try {
            // Call the service to delete the record from the backend
            const response = await deleteIncomeDetailsByIdService(selectedRecord.id);
            if (response.status === 200 && response.success === true) {
                message.success("Record deleted successfully");
                setOpenModal(false);
                getNonCashData();
                refreshData();
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };

    useEffect(() => {
        if (open) {
            getNonCashData();

        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);



    return (
        <>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
                initialValues={{
                    salaryDetails: [{ month_of_salary: null, monthly_net_salary_non_cash: null, over_time_pay: null }],
                }}
                onFinish={handleSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="customer" label="Customer" required
                         rules={[{ required: true, message: 'Please select a customer' }]} >
                            <Select
                                value={selectedCustomer}
                                onChange={handleCustomerchange}
                                style={{ width: "300px" }}
                                placeholder="Please Select Customer"
                                disabled={!!id}
                            >
                                {customerList.length > 0 &&
                                    customerList.map((customer) => {
                                        return (
                                            <Select.Option value={customer.id}>{customer.name}</Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="salaryDetails">
                    {(fields, { add, remove }) => (
                        <>
                            <div className="table-responsive">
                                <Table
                                    columns={[
                                        {
                                            title: 'SR.NO',
                                            dataIndex: 'key',
                                            key: 'key',
                                            render: (text, record, index) => <span>{index + 1}</span>,
                                        },
                                        {
                                            title: 'Month & Year',
                                            dataIndex: 'month_of_salary',
                                            key: 'month_of_salary',
                                            width: 150,
                                            render: (_, record, index) => (
                                                <Form.Item
                                                    name={[index, 'month_of_salary']}
                                                    rules={[{ required: true, message: 'Please select' }]}
                                                >
                                                    <Select
                                                        placeholder="Please Select"
                                                        onChange={(value) => handleChange(['salaryDetails', index, 'month_of_salary'], value)}
                                                        style={{ width: '120px' }}
                                                        allowClear
                                                        showSearch
                                                    >
                                                        {allMonthYear?.map((branch) => (
                                                            <Select.Option key={branch} value={branch}>
                                                                {branch}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            ),
                                        },
                                        {
                                            title: 'Fixed Net Salary',
                                            dataIndex: 'monthly_net_salary_non_cash',
                                            key: 'monthly_net_salary_non_cash',
                                            render: (_, record, index) => (
                                                <Form.Item
                                                    name={[index, 'monthly_net_salary_non_cash']}
                                                    rules={[{ required: true, message: 'Please enter fixed net salary' }]}
                                                >
                                                    <Input
                                                        placeholder="Please enter fixed net salary"

                                                        onChange={(e) => handleChange(['salaryDetails', index, 'monthly_net_salary_non_cash'], e.target.value)}
                                                    />
                                                </Form.Item>
                                            ),
                                        },
                                        {
                                            title: 'OT/Variable',
                                            dataIndex: 'over_time_pay',
                                            key: 'over_time_pay',
                                            render: (_, record, index) => (
                                                <Form.Item
                                                    name={[index, 'over_time_pay']}
                                                    rules={[{ required: true, message: 'Please enter OT/Variable' }]}
                                                >
                                                    <Input
                                                        placeholder="Please enter OT/Variable"

                                                        onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                    />
                                                </Form.Item>
                                            ),
                                        },
                                        {
                                            title: 'Action',
                                            key: 'action',
                                            fixed: 'right',
                                            render: (_, record, index) => {
                                                const salaryValues = form.getFieldValue('salaryDetails') || []; // Ensure there's a default value
                                                const currentDetail = salaryValues[index] || {}; // Get the current salary detail values

                                                return (
                                                    <DeleteOutlined
                                                        style={{ color: 'red' }}
                                                        onClick={() => {
                                                            if (currentDetail?.id) {
                                                                setOpenModal(true);
                                                                setSelectedRecord(currentDetail)

                                                            } else {
                                                                remove(index);
                                                            }
                                                        }}
                                                    />
                                                );
                                            },
                                        },
                                    ]}
                                    dataSource={fields}
                                    rowKey="key"
                                    pagination={false}
                                    scroll={{ x: true }}
                                />
                            </div>
                            <Button type="primary"
                                onClick={() => add()}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: "10px" }} >
                                Month
                            </Button>
                        </>
                    )}
                </Form.List>

                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "20px" }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>

            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    handleDelete();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>

        </>

    );
};

export default IncomeNonCashForm;
