import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message, DatePicker, } from "antd";
import * as Yup from "yup";
import { createRtoService, getRtoByIdService, updateRtoByIdService, getAllStateService } from "../services/rtoOfficeService";
import dayjs from "dayjs";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, 'Name can only contain letters and spaces')
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^[A-Za-z ]+$/, 'Display name can only contain letters and spaces')
    .min(2, "Display name must be at least 2 characters")
    .max(128, "Display name must be at max 128 characters")
    .label("Display name"),
  state_code: Yup.string()
    .required("State code is required")
    .min(2, "State code must be between 2 and 8 digits")
    .max(8, "State code must be between 2 and 8 digits"),
  rto_code: Yup.string()
    .required("RTO code is required")
    .min(2, "RTO code must be between 2 and 8 digits")
    .max(8, "RTO code must be between 2 and 8 digits"),
  enable_date: Yup.string()
    .required("Enable date is required"),
    state: Yup.string()
    .required("State is required"),


});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const RtoOfficeForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [displayNameSynced, setDisplayNameSynced] = useState(true);
  const [states, setStates] = useState();
  const [form] = Form.useForm();

  const getRtoData = async () => {
    if (id) {
      try {
        const response = await getRtoByIdService(id);
        const { name,
          code,
          display_name,
          is_active,
          is_verified,
          state_code,
          rto_code,
          enable_date,
          state,
        } =
          response?.data;

        form?.setFieldsValue({
          name,
          code,
          display_name,
          state_code,
          rto_code,
          enable_date: dayjs(enable_date, "YYYY-DD-MM"),
          state: state?.id,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      values.enable_date = values.enable_date.format("YYYY-MM-DD");
      if (id) {
        const response = await updateRtoByIdService(id, values);
        if ((response.status = 200 && response?.success)) {
          message.success("RTO successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createRtoService(values);
        if ((response.status = 201 && response?.success)) {
          message?.success("RTO successfully created");
          refreshData();
          closeForm();
        }
      }
      // Reset displayNameSynced after successful submit
      setDisplayNameSynced(true);

    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Fetch department data when the form is initially opened or for editing
  useEffect(() => {
    if (open) {
      // If it's opened for editing, fetch department data
      getRtoData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true); // Reset sync state when form is closed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };



  useEffect(() => {
    const getAllState = async () => {
      try {
        const response = await getAllStateService();
        setStates(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllState();
  }, [form]);




  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        state_code: "",
        rto_code: "",
        enable_date: "",
        // state: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>


      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="state_code" label="State Code" rules={[yupSync]} required>
            <Input placeholder="Please Enter State Code"
              onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="rto_code" label="RTO" rules={[yupSync]} required>
            <Input placeholder="Please Enter RTO Code"
              onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }}
            />
          </Form.Item>
        </Col>

      </Row>


      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="enable_date" label="Enable Date" rules={[yupSync]} required>
            <DatePicker format="YYYY-DD-MM" style={{ width: "100%" }} placeholder="Select Date" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input placeholder="Please Enter Code" onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }} />
            </Form.Item>
          </Col>
        ) : null}

        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default RtoOfficeForm;
