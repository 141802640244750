import React, { useEffect, useState } from "react";
import "../../style/customerStyle.css";
import verify from "../assets/Group.png";
import { Empty, message } from "antd";
import { getCrimeCheckDetailsByLoanId, getFraudDetailsByLoanId } from "../services/fraudCrimeCheckServices";
import { Link, useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading"
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { EyeOutlined } from '@ant-design/icons';
import "../../../../commonCss/commonStyle.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const FraudCrimeCheck = () => {
  const { encrypted_loan_id } = useParams();
  const [crimeCheckData, setCrimeCheckData] = useState();
  const [fraudDetails, setFraudDetails] = useState();
  const [loading, setLoading] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }


  console.log("111",)


  useEffect(() => {
    // Fetch data when the component mounts
    const fetchFraudData = async () => {
      try {
        const data = await getFraudDetailsByLoanId(loan_id);
        setFraudDetails(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchFraudData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getCrimeCheckDetailsByLoanId(loan_id);
        setCrimeCheckData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const Heading2 = [
    "Hunter",
    'Date Of Initiation',
    'Descision',
  ];

  const Heading1 = [
    "Key Parameter's",
    'Crime Check',
    'No of Cases',
    'Report Date',
    'Risk Type',
    'Risk Summary',
    'View Full Report'
  ];

  const openReport = (reportLink) => {
    window.open(reportLink, '_blank');
  };

  const hasCrimeCheckDetails = (data) => {
    return crimeCheckData?.results?.some(person => person?.customer_crime_check_details?.length > 0);
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return 'tab_form_main_data_two_entries';
      case 3:
        return 'tab_form_main_data_three_entries';
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return '';
    }
  };
  return (
    <>
      <div className="tab_form_container">

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : hasCrimeCheckDetails(crimeCheckData) ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">{Heading1[0]}
                  </th>
                  {crimeCheckData?.results.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <span>{`${person?.customer_name}`} </span>
                      <span>
                        <span>{`(${person?.customer_type})`}  </span>
                        <img src={verify} alt="group" style={{ marginLeft: "5px" }} />

                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <>
                  {fraudDetails  && [...Array(fraudDetails.max_count)].map((_, rowIndex) => (
                    Heading2.slice(0).map((header, colIndex) => (
                      <tr
                        className={
                          header === "Hunter"
                            ? "investment-row"
                            : header === "PD Entries"
                              ? "investment-pd-row "
                              : ""
                        }
                      >
                        <td className="tab_form_left_heading">{header}</td>
                        {fraudDetails.map((person, colIndex) => (
                          <td
                            className={`tab_form_main_data_table ${getTableColumnClass(fraudDetails.length)}`}
                          >
                            {person ? (
                              <>
                                {header === "Date Of Initiation"
                                  ? person.date_of_initiation || '-'
                                  : header === "Descision"
                                    ? person.descision || '-'
                                    : ''}
                              </>
                            ) : (
                              header === "Hunter" ? "" : <span>-</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ))}

                  {crimeCheckData && [...Array(crimeCheckData.max_count)].map((_, rowIndex) => (

                    Heading1.slice(1).map((header, colIndex) => (
                      <tr
                        className={
                          header === "Crime Check"
                            ? "investment-row"
                            : header === "PD Entries"
                              ? "investment-pd-row "
                              : ""
                        }
                      >
                        <td className="tab_form_left_heading">{header}</td>

                        {crimeCheckData?.results.map((person, colIndex) => (
                          <td
                            className={`tab_form_main_data_table ${getTableColumnClass(crimeCheckData.results.length)}`}
                          >
                            {person?.customer_crime_check_details && person?.customer_crime_check_details[rowIndex] ? (
                              <>
                                {header === "No of Cases"
                                  ? person.customer_crime_check_details[rowIndex]?.number_of_cases || '-'
                                  : header === "Report Date"
                                    ? person.customer_crime_check_details[rowIndex]?.report_date || '-'
                                    : header === "Risk Type"
                                      ? person.customer_crime_check_details[rowIndex]?.risk_type || '-'
                                      : header === "Risk Summary"
                                        ? person.customer_crime_check_details[rowIndex]?.risk_summary || '-'
                                        : ''}
                                {header === "View Full Report" && (
                                  <Link onClick={() => openReport(person.customer_crime_check_details[rowIndex]?.report)}>
                                    {person.customer_crime_check_details[rowIndex]?.report ? (
                                      <EyeOutlined style={{ color: '#71797E' }} />
                                    ) : (
                                      "-"
                                    )}
                                  </Link>
                                )}


                              </>
                            ) : (
                              header === "Crime Check" ? "" : <span>-</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ))}
                </>
              </tbody>
            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FraudCrimeCheck
