import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Space, Input, Select, message, DatePicker } from "antd";
import * as Yup from "yup";
import {
  getExistingLoanById,
  listFinancialInstitutionService,
  listLoanPurposeService,
  listLoanStatusService,
  updateExistingLoanDetailsByIdService,
  listSourceOfLiability,
  createExistingLoanService
} from "../services/existingDetailsServices";
import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const { Option } = Select;
const validationSchema = Yup.object().shape({
  loan_purpose: Yup.string()
    .required("Loan purpose is required"),
  sanctioned_amount: Yup.string()
    .required("Sanctioned amount is required")
    .matches(/^\d{1,15}(\.\d{1,2})?$/, 'Sanctioned amount must be between 1 to 15 digits'),
  // .test('is-minimum', 'Sanctioned amount must be at least 10,000', 
  // value => value >= 10000),
  sanctioned_date: Yup.string()
    .required("Sanctioned date is required"),
  outstanding_amount: Yup.string()
    .required("Outstanding amount is required"),
  maturity_date: Yup.string()
    .required("Maturity date is required"),
  emi_amount: Yup.string()
    .required("EMI amount is required"),
  roi: Yup.string()
    .required("ROI is required")
    .test('is-valid-roi', 'ROI must be between 1 and 99.99',
      value => parseFloat(value) >= 1 && parseFloat(value) <= 99.99),
  loan_status: Yup.string()
    .required("Loan status is required"),
  consider_obligation: Yup.string()
    .required("Consider obligation is required"),
  source_of_liability: Yup.string()
    .required("Source of liability is required"),
  // .matches(/^[a-zA-Z ]+$/, "Source of Liability must contain only alphabetic characters"),
  financial_institutions: Yup.string()
    .required("Financial institution is required"),
  eligibility_calculation_under_repayment_program: Yup.string()
    .required("Eligibility calculation repayment program is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ExistingLoanDetailsForm = ({ refreshData, id, open, closeForm, customer_id }) => {
  const [form] = Form.useForm();
  const [allLoanPurpose, setAllLoanPurpose] = useState([]);
  const [allLoanStatus, setAllLoanStatus] = useState([]);
  const [allFinancialInstitution, setAllFinancialInstitution] = useState([]);
  const [allSourceOfLiabilities, setAllSourceOfLiabilities] = useState([]);
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [considerObligation, setConsiderObligation] = useState('');

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getExistingLoan = async () => {

    if (id) {
      try {
        const response = await getExistingLoanById(id);
        let {
          loan_purpose,
          sanctioned_amount,
          outstanding_amount,
          sanctioned_date,
          maturity_date,
          emi_amount,
          roi,
          loan_status,
          consider_obligation,
          source_of_liability,
          financial_institutions,
          eligibility_calculation_under_repayment_program
        } = response?.data;

        sanctioned_date = dayjs(sanctioned_date, "DD-MM-YYYY");
        maturity_date = dayjs(maturity_date, "DD-MM-YYYY");
        form.setFieldsValue({
          loan_purpose: loan_purpose.id,
          sanctioned_amount,
          outstanding_amount,
          sanctioned_date,
          maturity_date,
          emi_amount,
          roi,
          loan_status: loan_status.id,
          consider_obligation,
          source_of_liability,
          financial_institutions: financial_institutions.id,
          eligibility_calculation_under_repayment_program,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      getExistingLoan();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const customMaturityDateValidator = (_, value) => {
    const sanctionedDate = form.getFieldValue("sanctioned_date");
    if (dayjs(value).isSame(sanctionedDate, "day")) {
      return Promise.reject("Maturity date must be different from sanctioned date");
    }
    if (dayjs(value).isBefore(sanctionedDate, "day")) {
      return Promise.reject("Maturity date must be after the sanctioned date");
    }
    return Promise.resolve();
  };

  const customSanctionedDateValidator = (_, value) => {
    const maturityDate = form.getFieldValue("maturity_date");
    if (dayjs(value).isSame(maturityDate, "day")) {
      return Promise.reject("Sanctioned date must be different from maturity date");
    }
    if (dayjs(value).isAfter(maturityDate, "day")) {
      return Promise.reject("Sanctioned date must be before the maturity date");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const getAllLoanPurpose = async () => {
      try {
        const response = await listLoanPurposeService();
        setAllLoanPurpose(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllLoanStatus = async () => {
      try {
        const response = await listLoanStatusService();
        setAllLoanStatus(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllFinancialInstitution = async () => {
      try {
        const response = await listFinancialInstitutionService();
        setAllFinancialInstitution(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllSourceOfLiabilities = async () => {
      try {
        const response = await listSourceOfLiability();
        setAllSourceOfLiabilities(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllLoanPurpose();
    getAllLoanStatus();
    getAllFinancialInstitution();
    getAllSourceOfLiabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    values.sanctioned_date = values.sanctioned_date.format("YYYY-MM-DD");
    values.maturity_date = values.maturity_date.format("YYYY-MM-DD");
    try {
      if (id) {
        const response = await updateExistingLoanDetailsByIdService(id, {
          ...values,
        });
        if ((response.status = 200 && response.success)) {
          message.success("Existing loan details successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        values.loan_detail = parseInt(loan_id);
        values.customer_detail = customer_id;
        const response = await createExistingLoanService(values);
        if ((response.status = 200 && response.success)) {
          message.success("Bank account details successfully created");
          refreshData();
          closeForm();
        }
      }
    }
    catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false)
      closeForm();
    }
  };
  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleConsiderObligationChange = (value) => {
    console.log("consider", value)
    setConsiderObligation(value);
  };

  // Automatically adjust eligibility_calculation_under_repayment_program based on consider_obligation
  useEffect(() => {
    if (considerObligation === true) {
      form.setFieldsValue({
        eligibility_calculation_under_repayment_program: false,
      });
    } else if (considerObligation === false) {

      form.setFieldsValue({
        eligibility_calculation_under_repayment_program: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [considerObligation]);



  return (


    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        // loan_purpose: "",
        sanctioned_amount: "",
        outstanding_amount: "",
        sanctioned_date: "",
        maturity_date: "",
        emi_amount: "",
        roi: "",
        // loan_status: "",
        // consider_obligation: "",
        // source_of_liability: "",
        // financial_institutions: ""
      }}
    >


      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="loan_purpose" label="Loan Purpose" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {allLoanPurpose?.map((loan_purpose) => {

                return (
                  <Option
                    key={loan_purpose.id}
                    value={loan_purpose.id}>
                    {loan_purpose.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="sanctioned_amount" label="Sanctioned Amount" rules={[yupSync]} required>
            <Input placeholder="Please Enter Sanctioned Amount"
              onChange={(e) => handleNumberFields(e, "sanctioned_amount")} />

          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="outstanding_amount" label="Outstanding Amount" required
            rules={[
              { required: true, message: "Outstanding amount is required" },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const sanitizedValue = parseFloat(value);
                  const sanctionedAmount = form.getFieldValue("sanctioned_amount");

                  if (!sanctionedAmount || isNaN(parseFloat(sanctionedAmount))) {
                    return Promise.reject("Please enter a valid Sanctioned amount first");
                  }

                  if (!isNaN(sanitizedValue) && !isNaN(parseFloat(sanctionedAmount))) {
                    return sanitizedValue <= parseFloat(sanctionedAmount)
                      ? Promise.resolve()
                      : Promise.reject("Outstanding amount must be less than or equal to Sanctioned amount");
                  } else {
                    return Promise.reject("Please enter a valid number");
                  }
                },
              },
            ]}

          >
            <Input placeholder="Please Enter Outstanding Amount"
              onChange={(e) => handleNumberFields(e, "outstanding_amount")}
            />
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="sanctioned_date" label="Sanctioned Date"
            rules={[
              { required: true, message: "Sanctioned date is required" },
              { validator: customSanctionedDateValidator }
            ]} required>
            <DatePicker
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="maturity_date" label="Maturity Date" rules={[
            { required: true, message: "Maturity date is required" },
            { validator: customMaturityDateValidator }
          ]} required>
            <DatePicker
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
            />
          </Form.Item>

        </Col>
        <Col span={8}>
          <Form.Item
            name="emi_amount"
            label="EMI Amount"
            rules={[
              { required: true, message: "EMI amount is required" },
              {
                validator: (_, value) => {
                  const { sanctioned_amount, outstanding_amount } = form.getFieldsValue(["sanctioned_amount", "outstanding_amount"]);
                  if (!value) {
                    return Promise.resolve();
                  }
                  const emi = parseFloat(value);
                  if (emi === 0) {
                    return Promise.reject("EMI amount cannot be zero");
                  }
                  if (emi >= parseFloat(sanctioned_amount) || emi >= parseFloat(outstanding_amount)) {
                    return Promise.reject("EMI amount must be less than both the sanctioned and outstanding amount");
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input placeholder="Please Enter EMI Amount" onChange={(e) => handleNumberFields(e, "emi_amount")} />
          </Form.Item>

        </Col>

      </Row>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="roi" label="ROI" rules={[yupSync]} required>
            <Input placeholder="Please Enter ROI"
              onChange={(e) => handleNumberFields(e, "roi")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="loan_status" label="Loan Status" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {allLoanStatus?.map((loan_status) => {

                return (
                  <Select.Option
                    key={loan_status.id}
                    value={loan_status.id}>
                    {loan_status.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="consider_obligation" label="Consider Obligation" rules={[yupSync]} required>
            <Select placeholder="Please Select" allowClear showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

              }
              value={considerObligation}
              onChange={handleConsiderObligationChange}
            >
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="source_of_liability" label="Source Of Liability" rules={[yupSync]} required>
            <Select placeholder="Please Select Gender" allowClear showSearch filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
              {allSourceOfLiabilities.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="financial_institutions" label="Financial Institution" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {allFinancialInstitution?.map((financial_institutions) => {

                return (
                  <Select.Option
                    key={financial_institutions.id}
                    value={financial_institutions.id}>
                    {financial_institutions.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="eligibility_calculation_under_repayment_program"
            label="Eligibility Calculation Repayment Program"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>

        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ExistingLoanDetailsForm;