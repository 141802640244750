import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message, DatePicker } from "antd";
import { createAddInvestmentService, getInvestmentById, listInvestmentTypeService, updateInvestmentDetailsByIdService } from "../services/investmentDetailsServices";
import * as Yup from "yup";
import dayjs from "dayjs";
import moment from 'moment';
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const { Option } = Select;
const validationSchema = Yup.object().shape({
  investment_type: Yup.string().required("Investment type is required"),
  invested_amount: Yup.string()
    .required("Invested amount is required")
    // .matches(/^[1-9]\d*(\.\d+)?$/, "Invested amount must contain only digits and start with a non-zero digit")
    .max(12, "Invested amount must not exceed 12 digits"),
  invested_date: Yup.string().required("Invested date is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const InvestmentDetailsForm = ({ refreshData, id, open, closeForm, customer_id }) => {
  const [form] = Form.useForm();
  const [allInvestmentType, setAllInvestmentType] = useState([]);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getInvestmentData = async () => {
    if (id) {
      try {
        const response = await getInvestmentById(id);
        let {
          investment_type,
          invested_amount,
          invested_date,
          maturity_date,
        } = response?.data;

        invested_date = dayjs(invested_date, "DD-MM-YYYY");
        maturity_date = maturity_date && dayjs(maturity_date, "DD-MM-YYYY");

        form.setFieldsValue({
          investment_type: investment_type.id,
          invested_amount,
          invested_date,
          maturity_date
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    const getAllInvestmentType = async () => {
      try {
        const response = await listInvestmentTypeService();
        setAllInvestmentType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllInvestmentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    values.invested_date = values.invested_date.format("YYYY-MM-DD");
    values.maturity_date = values.maturity_date ? values.maturity_date.format("YYYY-MM-DD") : null;
    try {
      if (id) {
        const response = await updateInvestmentDetailsByIdService(id, values);
        if (response.status === 200 && response.success) {
          message.success("Investment details successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        values.loan_detail = parseInt(loan_id);
        values.customer_detail = customer_id;
        const response = await createAddInvestmentService(values);
        if (response.status === 201 && response.success) {
          message.success("Investment details successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
     }finally{  
      setLoading(false)
    }
  };

  useEffect(() => {
    if (open) {
      getInvestmentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const customMaturityDateValidator = (_, value) => {
    try {
      if (!value) {
        return Promise.resolve();
      }
      // const investedDate = form.getFieldValue("invested_date");
      const today = moment().startOf("day");
      // if (value.isSameOrBefore(investedDate.startOf("day"))) {
      //   throw new Error("Maturity Date must be after the Invested Date");
      // }
      if (value.isBefore(today)) {
        throw new Error("Maturity date cannot be earlier than the current date.");
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  const customDateValidator = (_, value) => {
    const today = moment().startOf("day");

    if (!value) {
      return Promise.reject("Invested date is required");
    }

    if (value.isAfter(today, 'day')) {
      return Promise.reject("Invested date cannot be in the future.");
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        invested_amount: "",
        invested_date: "",
        maturity_date: ""
      }}
    >
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="investment_type" label="Investment Type" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allInvestmentType?.map((investment) => (
                <Option key={investment.id} value={investment.id}>
                  {investment.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="invested_amount" label="Invested Amount" rules={[yupSync]} required>
            <Input placeholder="Please Enter Invested Amount"
              onChange={(e) => handleNumberFields(e, "invested_amount")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="invested_date" label="Invested Date"
            rules={[{ validator: customDateValidator }]} required>
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item name="maturity_date" label="Maturity Date"
            rules={[{ validator: customMaturityDateValidator }]}>
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default InvestmentDetailsForm;
