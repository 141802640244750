
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import { getMonthlyInflowAndOutflowById } from "../services/monthlyInflowAndOutFlowService";
import "../../IncomeEvaluation/styles/monthlyinflowandoutflow.css"
import { Skeleton, message } from 'antd';
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const options = {
  colors: ['#1890FF', '#2FC25B']
};

function MonthlyInflowAndOutFlow({ customer_id, bank_id,onDataFetched }) {
  const [apiData, setApiData] = useState([]);
  const { encrypted_loan_id } = useParams();
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMonthlyInflowAndOutflowById(customer_id, loan_id,bank_id);
        const result = response.data.monthly_txn;

        const transformedData = [
          ["Months", "Credit", "Debit"],
          ...result.map(item => [item.month, parseInt(item.credit_amount),parseInt(item.debit_amount)])
        ];

        setApiData(transformedData);
        onDataFetched(result.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [customer_id, loan_id,bank_id,onDataFetched]);
  
  return (

  <div className="monthlyinflowandoutflow_container">
    {apiData.length > 1 ? (
       <div className="chart-container">
       <Chart
         chartType="Bar"
         height="400px"
         data={apiData}
         options={options}
       />
     </div>
      ) : (
        <div><Skeleton /></div>
      )}
  </div>
  );
}

export  { MonthlyInflowAndOutFlow };
