import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getCustomerDetailData = (customerId) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.get(`/api/v1/loan/fi-combine/get-customer-details-by-customer-id/${customerId}`);
          resolve(response.data);
      } catch (error) {
          console.error('Error fetching customer details:', error.response ? error.response.data : error.message);
          reject(error);
      }
  });
}


export const getResidenceChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-residence-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}


export const getHouseTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-house-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getVehicleTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-vehicle-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getBusinessDesignationChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-business-designation-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getBusinessNatureChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-business-nature-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getPremisesTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-premises-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}



export const getOccupationTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-occupation-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getOfficeTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-office-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getOfficeLocationTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-office-location-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getQualificationTypeChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-qualification-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getLivingStandardChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-living-standard-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getRelationShip = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/relation/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getMartialStatus = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/choices/marital-status`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getOwnerShip = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-ownership-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}


export const getAssests = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/assets/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getOverallStatus = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/overall-status-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getAccessibilityChoice = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-accessibility-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getBusinessPremisesOwner = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-ownership-type-choice`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const getSurroundings = () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/fi-surrounding-choices`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}
