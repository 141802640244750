import React, { useState, useEffect } from 'react';
import "../styles/dependentDetails.css"
import add from "../assets/Add.png";
import verify from '../assets/Group.png'
import "../../style/customerStyle.css";
import { Drawer, Empty, Modal, message } from "antd";
import Header from "../../../../layout/views/Header";
import DependentDetailsForm from './DependentDetailsForm';
import { deleteDependentDetailsByIdService, getDependentDetailsByIdService } from '../services/dependentDetailsServices';
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading"
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';
import "../../../../commonCss/commonStyle.css";
import EditButton from '../../../../../utils/editButton/EditButton';
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from '../../../../../utils/deleteButton/DeleteButton';

const DependentDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [dependentData, setDependentData] = useState();
  const [round, setRound] = useState();
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [maxCountDependent, setMaxCountDependent] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
    setSelectedPerson(null);
    setSelectedRecord(null);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await getDependentDetailsByIdService(loan_id);
        setDependentData(response.data.results);
        setMaxCountDependent(response.data)
        setRound(response.data);
        setLoading(false);

      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData(loan_id);
  }, [refreshTableData, loan_id]);


  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const renderEditButton = (showDrawer, id, person) => {

    return (
      <div className="tab_form_sub_edit"

        onClick={() => {
          showDrawer();
          setSelectedCustomer(id);
          setSelectedPerson(person)
        }}>
        <EditButton />
      </div>
    );
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return 'tab_form_main_data_two_entries';
      case 3:
        return 'tab_form_main_data_three_entries';
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return '';
    }
  };

  const Heading1 = [
    "Key Parameter's",
    "Dependent",
    "First Name",
    "Middle Name",
    "Last Name",
    "Date Of Birth",
    "Age",
    "Gender",
    "Relation",
    "Mobile Number",
    "Phone Number",
    "Address Type",
    "Address Line 1",
    "Address Line 2",
    "Landmark",
    "Pincode",
    "City",
    "Taluka",
    "District",
    "State",
    "Country",

    // "PD Entries",
    // "Staying for Years"
  ];

  const fieldNameMap = {
    "first_name": "First Name",
    "middle_name": "Middle Name",
    "country": "Country",
    "last_name": "Last Name",
    "dependents_address": "Dependents Address",
    "date_of_birth": "Date Of Birth",
    "age": "Age",
    "gender": "Gender",
    "relation": "Relation",
    "address_line_1": "Address Line 1",
    "address_line_2": "Address Line 2",
    "landmark": "Landmark",
    "city": "City",
    "taluka": "Taluka",
    "district": "District",
    "state": "State",
    "pincode": "Pincode",
    "address_type": "Address Type",
    "mobile_number": "Mobile Number",
    "phone_number": "Phone Number",


  };

  const deleteRecord = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteDependentDetailsByIdService(selectedRecord);
      if (response.status === 200 && response.success === true) {
        message.success("Dependent details deleted successfully");
        toggleRefreshTableData();
        setSelectedRecord(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };
  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              name={selectedPerson ? `${selectedPerson?.customer_name}'s Dependent Details` : `${selectedPerson?.customer_name}'s  Dependent Details`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <DependentDetailsForm
            refreshData={toggleRefreshTableData}
            id={selectedCustomer}
            customer_id={selectedCustomerId}
            open={open}
            closeForm={onClose}
          />

        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : maxCountDependent.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">
                    {Heading1[0]}
                    {Heading1[0] === "Dependent" && (
                      <>
                        {setSelectedCustomer(null)}
                        {showDrawer()}
                      </>
                    )}
                  </th>

                  {dependentData?.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            <img src={verify} alt="group" style={{ marginLeft: "5px" }} />

                          </span>
                        </div>
                        <div
                          className="tab_form_sub_edit"
                          onClick={() => {
                            showDrawer();
                            setSelectedCustomerId(person.customer_id)
                            setSelectedPerson(person)
                          }}
                        >
                          <img src={add} alt="Add Button" />
                        </div>
                      </div>
                    </th>
                  ))}

                </tr>
              </thead>
              <tbody>
                {round && [...Array(round.max_count)]?.map((_, rowIndex) => (

                  Heading1?.slice(1)?.map((header, colIndex) => (
                    <tr
                      key={`row-${rowIndex}`}
                      className={
                        header === "Dependent" || header === "Dependent"
                          ? "investment-row"
                          : header === "PD Entries"
                            ? "investment-pd-row "
                            : header === 'Staying for Years'
                              ? ' tab_form_highlighted_row_orange'
                              : ""
                      }
                    >

                      <td
                        key={`col-${colIndex}`}
                        className={`tab_form_left_heading${header === 'Dependent'
                          ? ' tab_form_highlighted_row_blue'
                          : header === 'PD Entries'
                            ? ' dependent_gray_highlighted-row'
                            : header === 'Staying for Years'
                              ? ' tab_form_highlighted_row_orange'
                              : ''
                          }`}
                      >
                        {header === "Dependent" ? `Dependent ${rowIndex + 1}` : header}
                      </td>
                      {dependentData?.map((person, colIndex) => (

                        <td key={`col-${colIndex}`}
                          className={`tab_form_main_data_table ${getTableColumnClass(dependentData.length)}`}
                        >
                          {person.customer_dependents && person.customer_dependents[rowIndex] ? (
                            <>
                              {
                                header === "First Name"
                                  ? person.customer_dependents?.[rowIndex]?.first_name || '-'
                                  : header === "Middle Name"
                                    ? person.customer_dependents?.[rowIndex]?.middle_name || '-'
                                    : header === "Last Name"
                                      ? person.customer_dependents?.[rowIndex]?.last_name || '-'
                                      : header === "Date Of Birth"
                                        ? person.customer_dependents?.[rowIndex]?.date_of_birth || '-'
                                        : header === "Age"
                                          ? person.customer_dependents?.[rowIndex]?.age || '-'
                                          : header === "Gender"
                                            ? person.customer_dependents?.[rowIndex]?.gender || '-'
                                            : header === "Relation"
                                              ? person.customer_dependents?.[rowIndex]?.relation.display_name || '-'
                                              : header === "Address Type"
                                                ? person.customer_dependents?.[rowIndex]?.address_type?.display_name || '-'

                                                : header === "Address Line 1"
                                                  ? person.customer_dependents?.[rowIndex]?.address_line_1 || '-'
                                                  : header === "Address Line 2"
                                                    ? person.customer_dependents?.[rowIndex]?.address_line_2 || '-'
                                                    : header === "Landmark"
                                                      ? person.customer_dependents?.[rowIndex]?.landmark || '-'
                                                      : header === "Mobile Number"
                                                        ? `+91 ${person.customer_dependents[rowIndex].mobile_number}` || '-'
                                                        : header === "Phone Number"
                                                          ? `+91 ${person.customer_dependents[rowIndex].phone_number}` || '-'
                                                          : header === "City"
                                                            ? person.customer_dependents?.[rowIndex]?.city?.display_name || '-'
                                                            : header === "Taluka"
                                                              ? person.customer_dependents?.[rowIndex]?.taluka?.display_name || '-'
                                                              : header === "District"
                                                                ? person.customer_dependents?.[rowIndex]?.district?.display_name || '-'
                                                                : header === "State"
                                                                  ? person.customer_dependents?.[rowIndex]?.state?.display_name || '-'
                                                                  : header === "Pincode"
                                                                    ? person.customer_dependents?.[rowIndex]?.pincode?.display_name || '-'
                                                                    : header === "Country"
                                                                      ? person.customer_dependents?.[rowIndex]?.country?.display_name || '-'
                                                                      : ""
                              }

                              {header === `Dependent` && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <div
                                    onClick={() => {
                                      setSelectedRecord(person?.customer_dependents[rowIndex]?.id);
                                      setOpenModal(true);
                                    }}>

                                    <DeleteButton />
                                  </div>
                                  {person?.customer_dependents[rowIndex]?.created_at &&
                                    person?.customer_dependents[rowIndex]?.modified_at &&
                                    isModifiedAfterCreated(
                                      person?.customer_dependents[rowIndex]?.created_at,
                                      person?.customer_dependents[rowIndex]?.modified_at
                                    ) && (
                                      <HistoryTable
                                        id={(person?.customer_dependents[rowIndex]?.id)}
                                        bench_id={"61fe7e3711f4f62e"}
                                        fieldNameMap={fieldNameMap}
                                      />
                                    )}
                                  <ConditionalRender
                                    userPermissions={user_data?.permissions}
                                    requiredPermissions={["MPITDE9"]}
                                  >
                                    {renderEditButton(showDrawer, person?.customer_dependents[rowIndex]?.id, person)}
                                  </ConditionalRender>

                                </div>
                              )}

                            </>
                          ) : (
                            header === "Dependent" ? "" : <span>-</span>
                          )}
                        </td>
                      ))}


                    </tr>
                  ))
                ))}
              </tbody>

            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>


      </div>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRecord();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default DependentDetails














