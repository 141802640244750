import React, { useEffect } from "react";
import "../styles/documentDetails.css";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Space, Table, message } from "antd";
import { useState } from "react";
import Header from "../../layout/views/Header";
import ScrutinyAndLegalOpinionForm from "./ScrutinyAndLegalOpinionForm";
import PreDisbursementStageForm from "./PreDisbursementStageForm";
import PostDisbursementStageForm from "./PostDisbursementStageForm";
import {
  deleteDocumentDetailServiceById,
  getDocumentDetailServiceByLoanId,
  listDocumentTypeService,
} from "../services/documentDetailService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { getPropertyDescriptionById } from "../services/propertyDescriptionService";
import PropertyPicturesForm from "./PropertyPicturesForm";
import Loading from "../../../utils/loading/Loading";
import EditButton from "../../../utils/editButton/EditButton";
import pdfIcon from "../assets/pdf.png";
import RViewerJS from "viewerjs-react";

const DocumentDetails = ({ loanId, stageId }) => {
  const [open, setOpen] = useState(false);
  const [scrutiny, setScrutiny] = useState([]);
  const [preDisbursement, setPreDisbursement] = useState([]);
  const [postDisbursement, setPostDisbursement] = useState([]);
  const [propertyPictures, setPropertyPictures] = useState([]);
  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [openThirdDrawer, setOpenThirdDrawer] = useState(false);
  const [openFourthDrawer, setOpenFourthDrawer] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [collapsed3, setCollapsed3] = useState(true);
  const [collapsed4, setCollapsed4] = useState(true);
  const [selectedScrutiny, setSelectedScrutiny] = useState(null);
  const [selectedPreDisbursement, setSelectedPreDisbursement] = useState(null);
  const [selectedPostDisbursement, setSelectedPostDisbursement] =
    useState(null);
  const [selectedPropertyPicture, setSelectedPropertyPicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [documentType, setDocumentType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [legalId, setLegalId] = useState();

  const fetchDocumentType = async () => {
    try {
      const response = await listDocumentTypeService();

      setDocumentType(response.data.choices);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedScrutiny(null);
  };

  const showSecondDrawer = () => {
    setOpenSecondDrawer(true);
  };

  const onCloseSecondDrawer = () => {
    setOpenSecondDrawer(false);
    setSelectedPreDisbursement(null);
  };

  const showThirdDrawer = () => {
    setOpenThirdDrawer(true);
  };

  const onCloseThirdDrawer = () => {
    setOpenThirdDrawer(false);
    setSelectedPostDisbursement(null);
  };

  const showFourthDrawer = () => {
    setOpenFourthDrawer(true);
  };

  const onCloseFourthDrawer = () => {
    setOpenFourthDrawer(false);
    setSelectedPropertyPicture(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const renderEditButton = () => {
    return (
      <div className="tab_form_sub_edit">
        <EditButton />
      </div>
    );
  };
  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "500px",
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              style={{ color: "#4880FF" }}
              onClick={() => {
                setSelectedScrutiny(record);
                showDrawer();
              }}
            />
            <DeleteOutlined
              style={{ color: "#E5233DB2" }}
              onClick={() => {
                setSelectedScrutiny(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "500px",
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              style={{ color: "#4880FF" }}
              onClick={() => {
                setSelectedPreDisbursement(record);
                showSecondDrawer();
              }}
            />
            <DeleteOutlined
              style={{ color: "#E5233DB2" }}
              onClick={() => {
                setSelectedPreDisbursement(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const columns3 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "500px",
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              style={{ color: "#4880FF" }}
              onClick={() => {
                setSelectedPostDisbursement(record);
                showThirdDrawer();
              }}
            />
            <DeleteOutlined
              style={{ color: "#E5233DB2" }}
              onClick={() => {
                setSelectedPostDisbursement(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailServiceByLoanId(stageId);
        setScrutiny(response.data.scrutiny_documents);
        setPreDisbursement(response.data.pre_disbursement_documents);
        setPostDisbursement(response.data.post_disbursement_documents);
        setPropertyPictures(response.data.legal_upload_serialized_data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false); // Set loading state to false after data is fetched
      }
    };

    fetchData();
    fetchDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  useEffect(() => {
    const fetchLegalId = async () => {
      try {
        const response = await getPropertyDescriptionById(stageId);

        if (response?.data?.legal_detail_data?.id) {
          setLegalId(response.data.legal_detail_data.id);
        } else {
          console.error("Legal ID is undefined");
        }
      } catch (error) {
        console.error("Error response:", error?.response);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchLegalId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteScrunity = async () => {
    if (!selectedScrutiny) {
      setOpenModal(false);
      return;
    }

    try {
      const response = await deleteDocumentDetailServiceById(
        selectedScrutiny.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Scrunity document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedScrutiny(null);
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  const deletePreDisbursementDocument = async () => {
    if (!selectedPreDisbursement) {
      setOpenModal(false);
      return;
    }

    try {
      const response = await deleteDocumentDetailServiceById(
        selectedPreDisbursement.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Pre-disbursement document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedPreDisbursement(null); // Reset selectedPreDisbursement
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  const deletePostDisbursementDocument = async () => {
    if (!selectedPostDisbursement) {
      setOpenModal(false);
      return;
    }

    try {
      const response = await deleteDocumentDetailServiceById(
        selectedPostDisbursement.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Post-disbursement document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedPostDisbursement(null); // Reset selectedPostDisbursement
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  return (
    <div style={{border:"1px solid #e4e7ec"}}>
      <Drawer
        title={
          <Header
            title={selectedScrutiny ? "Edit" : "Add"}
            onClose={onClose}
            name="List Of Documents Submitted Before Me/Us For Scrutiny And Legal Opinion"
          />
        }
        width={1050}
        onClose={onClose}
        open={open}
        closable={false}
      >
        {loanId && stageId && (
          <ScrutinyAndLegalOpinionForm
            open={open}
            legalId={legalId}
            loanId={loanId}
            stageId={stageId}
            closeForm={onClose}
            documentType={documentType}
            scrutiny={scrutiny}
            selectedScrutiny={selectedScrutiny}
            refreshData={toggleRefreshTableData}
          />
        )}
      </Drawer>

      <Drawer
        title={
          <Header
            title={selectedPreDisbursement ? "Edit" : "Add"}
            onClose={onCloseSecondDrawer}
            name="Essential Documents Required To Be Collected At Pre-Disbursement Stage"
          />
        }
        width={1050}
        onClose={onCloseSecondDrawer}
        open={openSecondDrawer}
        closable={false}
      >
        <PreDisbursementStageForm
          open={openSecondDrawer}
          legalId={legalId}
          closeForm={onCloseSecondDrawer}
          documentType={documentType}
          preDisbursement={preDisbursement}
          selectedPreDisbursement={selectedPreDisbursement}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={selectedPostDisbursement ? "Edit" : "Add"}
            onClose={onCloseThirdDrawer}
            name="Documents Required To Be Collected At Post-Disbursement Stage To Create A Valid Mortgage"
          />
        }
        width={1050}
        onClose={onCloseThirdDrawer}
        open={openThirdDrawer}
        closable={false}
      >
        <PostDisbursementStageForm
          open={openThirdDrawer}
          legalId={legalId}
          closeForm={onCloseThirdDrawer}
          documentType={documentType}
          postDisbursement={postDisbursement}
          selectedPostDisbursement={selectedPostDisbursement}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseFourthDrawer}
            name="Legal Documents & Other Documents"
          />
        }
        width={1050}
        onClose={onCloseFourthDrawer}
        open={openFourthDrawer}
        closable={false}
      >
        <PropertyPicturesForm
          open={openFourthDrawer}
          legal_detail={legalId}
          closeForm={onCloseFourthDrawer}
          documentType={documentType}
          propertyPictures={propertyPictures}
          selectedPropertyPicture={selectedPropertyPicture}
          setSelectedPropertyPicture={setSelectedPropertyPicture}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">
            List Of Documents Submitted Before Me/Us For Scrutiny And Legal
            Opinion
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="property_description_data_button"
              onClick={() => {
                showDrawer();
              }}
              style={{ cursor: "pointer" }}
            >
              Add Documents
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
              onClick={toggleCollapse1}
            >
              {collapsed1 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed1 && (
          <div style={{ border: "1px solid #E4E7EC" }}>
            {loading ? ( // Show loading spinner if data is still being fetched
              <Loading />
            ) : (
              <Table
                columns={columns}
                dataSource={scrutiny}
                pagination={false}
              />
            )}
          </div>
        )}
      </div>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">
            Essential Documents Required To Be Collected At Pre-Disbursement
            Stage
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="property_description_data_button"
              onClick={() => {
                showSecondDrawer();
              }}
              style={{ cursor: "pointer" }}
            >
              Add Documents
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
              onClick={toggleCollapse2}
            >
              {collapsed2 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        <div>
          {collapsed2 && (
            <div className="table_container" style={{ border: "1px solid #E4E7EC" }}>
              {loading ? ( // Show loading spinner if data is still being fetched
                <Loading />
              ) : (
                <Table
                  columns={columns2}
                  dataSource={preDisbursement}
                  pagination={false}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div style={{margin:"10px"}}>
        <div className="document_detail_heading">
          <div className="property_description_data">
            Documents Required To Be Collected At Post-Disbursement Stage To
            Create A Valid Mortgage
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="property_description_data_button"
              onClick={() => {
                showThirdDrawer();
              }}
              style={{ cursor: "pointer" }}
            >
              Add Documents
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
              onClick={toggleCollapse3}
            >
              {collapsed3 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        <div>
          {collapsed3 && (
            <div className="table_container" style={{ border: "1px solid #E4E7EC" }}>
              {loading ? ( // Show loading spinner if data is still being fetched
                <Loading />
              ) : (
                <Table
                  columns={columns3}
                  dataSource={postDisbursement}
                  pagination={false}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div style={{margin:"10px" ,border:'1px solid #E4E7EC'}}>
        <div className="document_detail_heading">
          <div className="property_description_data">Property Pictures</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
              onClick={showFourthDrawer}
            >
              {renderEditButton()}
            </div>
            <div
              style={{ margin: "10px 10px 10px 10px", cursor: "pointer" }}
              onClick={toggleCollapse4}
            >
              {collapsed4 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed4 && (
          <div className="valuation_Proerty_images_container" >
            {loading ? (
              <Loading />
            ) : (
                <div className="document_images">
                  {propertyPictures?.map((picture, index) => (
                    <div
                      key={index}
                      style={{
                        flex: "0 0 11.1%",
                        boxSizing: "border-box",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {picture.file_type === "application/pdf" ? (
                        <img
                          src={pdfIcon}
                          alt={`PDF ${index}`}
                          style={{ width: "100%", height: "70%" }}
                        />
                      ) : (
                        <RViewerJS
                        options={{
                          url: (img) => img.getAttribute("data-original"),
                        }}
                      >
                        <img
                          src={picture.thumb_file_path}
                          data-original={picture.file_path}
                          alt="Preview"
                          style={{ width: "100px", height: "70%", cursor: 'pointer' }}
                        />
                      </RViewerJS>
                      )}
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        {picture.name}
                      </div>
                    </div>
                  ))}
                </div>
            )}
          </div>
        )}
      </div>
      <Modal
        title={<h4>Are you sure you want to delete this document?</h4>}
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteScrunity();
          deletePreDisbursementDocument();
          deletePostDisbursementDocument();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      ></Modal>
    </div>
  );
};

export default DocumentDetails;
