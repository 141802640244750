import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import { listAllCityService, listAllCountryService, listAllDistrictService, listAllStateService, listAllTalukaService, listUserService } from "../services/pinecodeServices";

const { Option } = Select;

const PincodeFilterForm = ({
  closeForm,
  setFilterFormData,
  toggleRefreshTableData,
  setActionPerformed
}) => {
  const [form] = Form.useForm();
  const [allStates, setAllStates] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [allDistrict, setAllDistrict] = useState([]);
  const [allTaluka, setAllTaluka] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);

  const handleSubmit = (values) => {
    // Iterate over form values and update filterFormData
    setFilterFormData((prevState) => ({
      ...prevState,
      filter: true,
    }));
    Object.keys(values).forEach((key) => {
      setFilterFormData((prevState) => ({
        ...prevState,
        [key]: values[key],
      }));
    });
    toggleRefreshTableData();
    closeForm();
    setActionPerformed(true);
  };

  const handleReset = () => {
    form.resetFields();
    setFilterFormData({
      filter: false,
      is_active: null,
      is_verified: null,
      state: [],
      city:[],
      district:[],
      taluka:[],
      country:[],
      created_by: [],
      modified_by: [],
    });
    setActionPerformed(true);

    form.setFieldValue({
      filter: false,
      is_active: null,
      is_verified: null,
      state: [],
      city:[],
      district:[],
      taluka:[],
      country:[],
      created_by: [],
      modified_by: [],
    });
  };

  useEffect(() => {
    const fetchAllState = async () => {
      try {
        const response = await listAllStateService();
        setAllStates(response.data);
      } catch (error) {
        message.error("Problem fetching states!");
      }
    };
    fetchAllState();
  }, []);

  useEffect(() => {
    const fetchAllCity = async () => {
      try {
        const response = await listAllCityService();
        setAllCity(response.data);
      } catch (error) {
        message.error("Problem fetching states!");
      }
    };
    fetchAllCity();
  }, []);
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await listUserService();
        setCreatedBy(response.data);
        setModifiedBy(response.data);
      } catch (error) {
        message.error("Problem fetching users!");
      }
    };
    fetchAllUsers();   
  }, []);

  useEffect(() => {
    const getAllDistrict = async () => {
      try {
        const response = await listAllDistrictService();

        setAllDistrict(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllDistrict();
  }, [form]);

  useEffect(() => {
    const getAllTaluka = async () => {
      try {
        const response = await listAllTalukaService();

        setAllTaluka(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllTaluka();
  }, [form]);

  useEffect(() => {
    const getAllCountry = async () => {
      try {
        const response = await listAllCountryService();

        setAllCountry(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllCountry();
  }, [form]);
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        filter: false,
        is_active: null,
        is_verified: null,
        state: [],
        city:[],
        district:[],
        taluka:[],
        country:[],
        created_by: [],
        modified_by: [],
      }}
    >

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="state" label="State">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allStates?.map((states) => {
                return (
                  <Select.Option key={states.id} value={states.id}>
                    {states.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="city" label="City">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allCity?.map((city) => {
                return (
                  <Select.Option key={city.id} value={city.id}>
                    {city.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
      <Col span={12}>
          <Form.Item name="district" label="District">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allDistrict?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="taluka" label="Taluka" >
            <Select
             mode="multiple"
             placeholder="Please select"
             allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allTaluka?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="country" label="Country">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allCountry?.map((loan) => {
                return (
                  <Select.Option key={loan.id} value={loan.id}>
                    {loan.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
<Form.Item name="created_by" label="Created By">
  <Select
    mode="multiple"
    placeholder="Please select"
    allowClear
    style={{ width: "100%" }}
    showSearch
    filterOption={(input, option) =>
      option.children
        ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        : false
    }
  >
    {createdBy?.map((created) => {
      return (
        <Select.Option key={created.id} value={created.id}>
          {created.name}
        </Select.Option>
      );
    })}
  </Select>
</Form.Item>

        </Col>
        <Col span={12}>
<Form.Item name="modified_by" label="Modified By">
  <Select
    mode="multiple"
    placeholder="Please select"
    allowClear
    style={{ width: "100%" }}
    showSearch
    filterOption={(input, option) =>
      option.children
        ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        : false
    }
  >
    {modifiedBy?.map((modified) => {
      return (
        <Select.Option key={modified.id} value={modified.id}>
          {modified.name}
        </Select.Option>
      );
    })}
  </Select>
</Form.Item>

        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={handleReset}>
          Clear Filter
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PincodeFilterForm;
