import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  Upload,
  Select,
  DatePicker,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  createidCardService,
  getIDCardDetailsbyId,
  updateIDCardDetailsByIdService,
} from "../services/idcardService";
import * as Yup from "yup";
import dayjs from "dayjs";
import ImgCrop from "antd-img-crop";
import {
  stringToDate,
  dateToString,
} from "../../../utils/dateConvertor/DateConvertor";
const validationSchema = Yup.object().shape({
  profile_image: Yup.string()
    .required("Profile Image is required!")
    .label("profile_image"),
  employee_name: Yup.string()
    .required("Name is required!")
    .min(2, "Name must be at least 2 characters!")
    .max(64, "Name must be at max 64 characters!")
    .label("Name"),
  organization: Yup.string().required("Organization is required!"),
  date_of_birth: Yup.string().required("Date Of Birth is required!"),
  blood_group: Yup.string().required("Blood Group is required!"),
  mobile_number: Yup.string()
    .required("Mobile Number is required!")
    .max(10, "Mobile Number must be 10 digits!")
    .label("Mobile Number"),
  emergency_number: Yup.string()
    .required("Emergency Number is required!")
    .max(10, "Emergency Number must be 10 digits!")
    .label("Emergency Number"),

  employee_id: Yup.string()
    .required("Employee Id is required!")
    .min(4, "Employee Id must be at least 4 characters!")
    .max(8, "Employee Id must be at max 8 characters!")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Employee Id must contain only alphanumeric characters"
    )
    .label("Employee Id"),
  department: Yup.string()
    .required("Department is required!")
    .min(2, "Department must be at least 2 characters!")
    .max(32, "Department must be at max 32 characters!"),
  branch: Yup.string()
    .required("Branch is required!")
    .min(2, "Branch must be at least 2 characters!")
    .max(32, "Branch must be at max 32 characters!"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};
const BLOOD_GROUPS = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const DEPARTMENTS = [
  "IT",
  "Sales",
  "Operations",
  "HR",
  "Marketing",
  "Finance",
  "Customer Service",
  "Research and Development",
  "Administration",
];

const BRANCHES = [
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Hyderabad",
  "Chennai",
  "Kolkata",
  "Pune",
  "Ahmedabad",
  "Surat",
  "Jaipur",
];

const CustomUploadButton = ({ loading, imageUrl, isEditMode }) => (
  <div>
    {loading ? (
      <LoadingOutlined />
    ) : imageUrl ? (
      <img
        src={imageUrl}
        alt="Profile"
        style={{
          // marginTop: "5px",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    ) : !isEditMode ? (
      <>
        <PlusOutlined />
        <div>Upload Image</div>
      </>
    ) : (
      <>
        <PlusOutlined />
        <div>Upload Image</div>
      </>
    )}
  </div>
);

const IdCardForm = ({
  id,
  onCancel,
  closeDrawer,
  open,
  refreshData,
  isEditMode,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    const isLt1M = file.size / 1024 / 1024 <= 1;

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/JPEG file!");
      return false;
    }
    if (!isLt1M) {
      message.error("Image must be less than or equal to 1MB!");
      return false;
    }
    setImageUrl(URL.createObjectURL(file));
    setImageFile(file);
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
    closeDrawer();
  };

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };
  const handleAlphaNumeric = (e, field) => {
    const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const disabledFutureDates = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };
  const getUserData = async () => {
    if (id) {
      try {
        const response = await getIDCardDetailsbyId(id);
        let {
          employee_name,
          employee_id,
          blood_group,
          date_of_birth,
          mobile_number,
          emergency_number,
          branch,
          organization,
          department,
          profile_image,
        } = response?.data;
        date_of_birth = stringToDate(date_of_birth);
        // date_of_birth = dayjs(date_of_birth, "DD-MM-YYYY");
        form.setFieldsValue({
          employee_name,
          employee_id,
          blood_group,
          date_of_birth,
          mobile_number,
          emergency_number,
          branch,
          profile_image,
          organization,
          department,
        });
        if (profile_image) {
          setImageUrl(profile_image);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    values.date_of_birth = dateToString(values.date_of_birth);
    // values.date_of_birth = values.date_of_birth?.format("YYYY-MM-DD");
    try {
      const formData = new FormData();
      formData.append("employee_name", values.employee_name);
      formData.append("organization", values.organization);
      formData.append("blood_group", values.blood_group);
      formData.append("date_of_birth", values.date_of_birth);
      formData.append("employee_id", values.employee_id);
      formData.append("mobile_number", values.mobile_number);
      formData.append("branch", values.branch);
      formData.append("emergency_number", values.emergency_number);
      formData.append("department", values.department);
      if (imageFile) {
        formData.append("profile_image", imageFile); // Append the uploaded image file
      }

      if (id) {
        const response = await updateIDCardDetailsByIdService(id, formData);
        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("ID Card details updated successfully");
            refreshData();
            closeDrawer();
          }
        }
      } else {
        const response = await createidCardService(formData);
        if (response && response?.data) {
          if (response?.status === 201 && response?.success) {
            message.success("ID Card details created successfully");
            refreshData();
            closeDrawer();
          }
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (open) {
      getUserData();
    } else {
      form.resetFields();
      setImageUrl(null);
      setImageFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form, closeDrawer]);

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          employee_name: "",
          date_of_birth: "",
          mobile_number: "",
          emergency_number: "",
          profile_image: "",
          employee_id: "",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Profile Image" name="profile_image">
              <ImgCrop>
                <Upload
                  name="profile_image"
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  <CustomUploadButton
                    loading={loading}
                    imageUrl={imageUrl}
                    isEditMode={isEditMode}
                  />
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="employee_name"
              label={<span>Name</span>}
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Name"
                onChange={(e) => handleInput(e, "employee_name")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="organization"
              label={<span>Organization</span>}
              rules={[yupSync]}
              required
            >
              <Select placeholder="Please Select organization">
                <Select.Option key="HFC" value="HFC">
                  HFC
                </Select.Option>
                <Select.Option key="Finserve" value="Finserve">
                  Finserve
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="blood_group"
              label="Blood Group"
              required
              rules={[yupSync]}
            >
              <Select placeholder="Please Select Blood Group">
                {BLOOD_GROUPS.map((group) => (
                  <Select.Option key={group} value={group}>
                    {group}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="date_of_birth"
              label="Date Of Birth"
              rules={[yupSync]}
              required
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{
                  width: "100%",
                }}
                placeholder="Select Date"
                disabledDate={disabledFutureDates}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="mobile_number"
              label="Mobile Number"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Mobile Number"
                onChange={(e) => handleNumberFields(e, "mobile_number")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="emergency_number"
              label="Emergency Number"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Emergency Number"
                onChange={(e) => handleNumberFields(e, "emergency_number")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="department"
              label={<span>Department</span>}
              required
              rules={[yupSync]}
            >
              <Select placeholder="Please Select Department">
                {DEPARTMENTS.map((department) => (
                  <Select.Option key={department} value={department}>
                    {department}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="employee_id"
              label={<span>Employee Id</span>}
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Employee Id"
                onChange={(e) => handleAlphaNumeric(e, "employee_id")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="branch"
              label={<span>Branch</span>}
              required
              rules={[yupSync]}
            >
              <Select placeholder="Please Select Branch">
                {BRANCHES.map((branch) => (
                  <Select.Option key={branch} value={branch}>
                    {branch}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default IdCardForm;
