import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createAgentService,
  getAgentByIdService,
  listUserService,
  listAgencyService,
  updateAgentByIdService,
} from "../services/agentServices";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters and spaces")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format "
    ),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits"),
  agency: Yup.string()
    .required("Agency is required")
    .min(1, "Agency is required"),
  user: Yup.string().required("User is required").min(1, "User is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AgentForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState([]);
  const [allAgency, setAllAgency] = useState([]);

  const getAgentData = async () => {
    if (id) {
      try {
        const response = await getAgentByIdService(id);
        const { name, email, mobile, agency, user, is_active, is_verified } =
          response?.data;
        form.setFieldsValue({
          name,
          email,
          mobile,
          agency: agency?.id,
          user: user?.id,
          is_active,
          is_verified,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateAgentByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Agent successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createAgentService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Agent successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (open) {
      getAgentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const response = await listUserService();
        setAllUsers(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };
    const getAllAgency = async () => {
      try {
        const response = await listAgencyService();
        setAllAgency(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllUsers();
    getAllAgency();
  }, [form]);

  const handleSelectedLoans = (value) => {};

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 10); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        email: "",
        mobile: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Name"
              onChange={(e) => handleInput(e, "name")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="email" label="Email" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Email"
              // onChange={(e) => handleInput(e, "email")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="mobile"
            label="Mobile Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Mobile Number"
              onChange={(e) => handleNumberFields(e, "mobile")}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="agency" label="Agency" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              onChange={handleSelectedLoans}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allAgency?.map((agency) => {
                return (
                  <Select.Option key={agency.id} value={agency.id}>
                    {agency.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="user" label="User" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              onChange={handleSelectedLoans}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allUsers?.map((user) => {
                return (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default AgentForm;
