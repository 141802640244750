import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Space, Select, message } from "antd";
import { getDecisionQuestionListService, createQuestionService } from "../services/creditAnalysisServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const DecisionQuestionAddForm = ({ loan_id, refreshData, open, closeForm }) => {
  const [form] = Form.useForm();
  const [questionType, setQuestionType] = useState("");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
 

  
  const handleQuestionTypeChange = (value) => {
    setQuestionType(value);
    form.setFieldsValue({ question: "", answer: "" });
  };

  const getAllQuestions = async () => {
    try {
      const response = await getDecisionQuestionListService();
      setQuestions(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Questions fetched successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    getAllQuestions();
  }, []);

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      let data = {};
      if (questionType === "master") {
        data = {
          question_id: values.description,
          answer: values.answer,
          loan_detail: loan_id,
        };
      } else if (questionType === "manually") {
        data = {
          question: values.question,
          answer: values.answer,
          loan_detail: loan_id,
        };
      }

      const response = await createQuestionService(data);
      if (response && response.success) {
        message.success("Question created successfully");
        form.resetFields(); // Reset form fields after successful submission
        refreshData(); // Refresh parent data
        closeForm(); // Close the form

      } else {
        message.error(response.message || "Creation failed");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        question: "",
        description: "",
        answer: "",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="questionType"
            label="Select Question type"
            rules={[{ required: true, message: "Please select a question type" }]}
          >
            <Select
              placeholder="Please select a question type"
              onChange={handleQuestionTypeChange}
            >
              <Option value="master">Master Question List</Option>
              <Option value="manually">Manually Question</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {questionType === "master" && (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Select Question"
                rules={[{ required: true, message: "Please select a question" }]}
              >
                <Select
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {questions?.map((question) => (
                    <Option key={question.id} value={question.id}>
                      {question.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="answer"
                label="Answer"
                rules={[{ required: true, message: "Please enter the answer" }]}
              >
                <Input.TextArea placeholder="Please enter Answer" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {questionType === "manually" && (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="question"
                label="Question"
                rules={[{ required: true, message: "Please enter the question" }]}
              >
                <Input.TextArea placeholder="Please enter Question" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="answer"
                label="Answer"
                rules={[{ required: true, message: "Please enter the answer" }]}
              >
                <Input.TextArea placeholder="Please enter Answer" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default DecisionQuestionAddForm;
