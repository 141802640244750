import React from 'react';
import { Row, Col } from 'antd';
import DashboardTaskAssignUI from './DashboardTaskAssignUI';
import DashBoardLiteEmployeeDetail from './DashBoardLiteEmployeeDetail';

const DashboardLiteLayout = ({assignedTask}) => {
  
  return (
<div style={{ backgroundColor: "#f0f5ff" }}>
  <Row gutter={16}>
    <Col span={18}>
      <DashboardTaskAssignUI assignedTask={assignedTask}/>
    </Col>
    <Col span={6}>
      <DashBoardLiteEmployeeDetail  />
    </Col>
  </Row>
</div>

  );
};

export default DashboardLiteLayout;
