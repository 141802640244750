import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, message } from "antd";
import { getRCUDataByIdService, completeRemark } from "../services/rcuservices"; 

const Completeremark = ({open, refreshData, closeForm, loanid, stage_id }) => {
  const [form] = Form.useForm();
  const [rcuid, setRcuid] = useState()

  const getDepartmentData = async () => { 
    try {
      const response = await getRCUDataByIdService(stage_id);
      const { completion_remark } = response?.data?.rcu_data;
      setRcuid(response?.data?.rcu_data?.id)
      form.setFieldsValue({
        completion_remark
      });
    } catch (error) {
      message.error(`${error?.response?.status}: ${error?.response?.data?.message}`);
    } 
  };

  useEffect(() => {
    getDepartmentData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanid, open]); 

  const handleSubmit = async (values) => { 
    try {
      const response = await completeRemark(rcuid, values);
      if ((response.status = 200 && response.success)) {
        message.success("Completion Remark successfully updated");
        refreshData();
        closeForm();
      }
    }
    catch (error) {
      closeForm();
      message.error (error?.response?.status, error?.response?.data?.message);
    }
  }

  const handleAlphaFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;
  
    // Remove all characters except alphanumeric characters (letters and numbers)
    newValue = newValue.replace(/[^a-zA-Z0-9]/g, "");
  
    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Row gutter={16} style={{ marginBottom: "16px" }}> 
      <Col span={24}>
                    <Form.Item
                        name="completion_remark"
                        label="Complete Remark"
                        rules={[{ required: true, message: 'Please Enter Complete Remark' }]}
                    >
                        <Input placeholder="Please Enter Complete Remark"   onChange={(e) =>
                            handleAlphaFields(e, "completion_remark")}/>
                    </Form.Item>
                </Col>
      </Row>

      <Form.Item>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button type="default" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Completeremark;
