import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getPropertyTechnicalDataByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-technical-data/technical-data/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

 


export const getPropertyTechMarketValueByIdService = (technical_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/property-technical-data/combine-technical-and-market-valuation/${technical_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const updatePropertyDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


// export const getPropertyTechnicalDataByIdService = (id, data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axiosRequest.get(`api/v1/loan/property_technical_market_valuation/${id}`, data);
//       return resolve(response.data)
//     } catch (error) {
//       return reject(error)
//     }
//   })
// }



export const updateMarketAndTechnicalByIdService = (identifier, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/update-combine-technical-market-valuation/${identifier}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}









