import React, { useEffect, useState } from "react";
import "../styles/loanInsurance.css";
import { Button, Empty, message, Drawer, Table } from "antd";
import "../../../../../utils/styles/heading.css";
import { PlusCircleOutlined } from "@ant-design/icons";

import {
  getChargesByIdService,
  getLoanInsuranceByIdService,
  getInsurancesByIdService,
} from "../services/loanInsuranceService";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import InsuranceForm from "./InsuranceForm";
import Header from "../../../../layout/views/Header";
import HDFCInsuranceview from "./HDFCInsuranceview";
import MagmaHDIInsuranceview from "./MagmaHDIInsuranceview";
import ICICIInsuranceview from "./ICICIInsuranceview";
import Loading from "../../../../../utils/loading/Loading";

const columns = [
  { title: "Charge Name", dataIndex: "charge" },
  { title: "Charge Percentage", dataIndex: "charge_percentage" },
  { title: "Charge Amount", dataIndex: "charge_amount" },
  { title: "Charge Gst Percentage", dataIndex: "charge_gst_percentage" },
  {
    title: "Charge GST Amount",
    dataIndex: "charge_gst_amount",
  },
  {
    title: "Total Amount",
    dataIndex: "total_amount",
  },
];

const LoanInsurance = () => {
  const [basicData, setBasicData] = useState(null);
  const [chargesData, setChargesData] = useState(null);
  const { encrypted_loan_id } = useParams();
  const [activeButton, setActiveButton] = useState(null); // State to track active insurance ID
  const [selectedInsurance, setSelectedInsurance] = useState(null); // State to track selected insurance
  const [collapseSections, setCollapseSections] = useState(true);
  const [loanDetailsCollapseSections, setLoanDetailsCollapseSections] = useState(true);
  const [chargesCollapseSections, setChargesCollapseSections] = useState(true);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [refeshInsurance, setRefreshInsurance] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);

  const toggleRefreshInsurance = () => {
    setRefreshInsurance((prev) => !prev);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getLoanInsuranceByIdService(loan_id);
        setBasicData(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [loan_id]);

  useEffect(() => {
    const fetchChargesData = async () => {
      try {
        setLoading(true);
        const res = await getChargesByIdService(loan_id);
        setChargesData(res.data.charge_details);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching charges data:", error);
        setLoading(false);
      }
    };
    fetchChargesData();
  }, [loan_id]);

  useEffect(() => {
    const fetchInsuranceData = async () => {
      try {
        const res = await getInsurancesByIdService(loan_id);
        setInsurances(res.data);
        if (res.data.length > 0) {
          setSelectedInsurance(res.data[0]);
          setActiveButton(res.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching charges data:", error);
        setLoading(false);
      }
    };
    fetchInsuranceData();
  }, [loan_id, refeshInsurance]);

  const data = chargesData
    ? chargesData.map((item, index) => ({
        key: index.toString(),
        no: (index + 1).toString(),
        charge: item.charge || "-",
        charge_percentage: item.charge_percentage || "-",
        charge_amount: item.charge_amount || "-",
        charge_gst_percentage: item.charge_gst_percentage || "-",
        charge_gst_amount: item.charge_gst_amount || "-",
        total_amount: item.total_amount || "-",
      }))
    : [];

  const showDrawer = () => setOpen(true);
  const onClose = () => {
    setCount(0);
    setOpen(false);
    setDisabledButton(true);
  };

  const handleButtonClick = (insurance) => {
    setActiveButton(insurance.id);
    setSelectedInsurance(insurance);
  };

  const renderInsuranceView = () => {
    if (!selectedInsurance) return null;
    switch (selectedInsurance.insurance_company_detail__code) {
      case "MICHHDF4":
        return (
          <HDFCInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );
      case "MICAMIM2":
        return (
          <ICICIInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );
      case "MICMADD2":
        return (
          <MagmaHDIInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
            setDisabledButton={setDisabledButton}
            disabledButton={disabledButton}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        title={<Header title={"Add"} onClose={onClose} name="Insurance" />}
        bodyStyle={{ padding: "0px" }}
        width={1000}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <InsuranceForm
          id={loan_id}
          open={open}
          closeForm={onClose}
          toggleRefreshInsurance={toggleRefreshInsurance}
          count={count}
          setCount={setCount}
          setDisabledButton={setDisabledButton}
          disabledButton={disabledButton}
        />
      </Drawer>
      <div className="tab_main_container">
        <div className="heading_details_container">
          <div className="heading_text">
            <div>Loan Details </div>
            <div>
              {loanDetailsCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setLoanDetailsCollapseSections(!loanDetailsCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setLoanDetailsCollapseSections(!loanDetailsCollapseSections)
                  }
                />
              )}
            </div>
          </div>
          {loanDetailsCollapseSections && (
            <>
              {basicData ? (
                <div className="loan_and_insurance_container">
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Application Number
                      </div>

                      <div className="loan_inusurance_data">
                        {basicData?.application_number || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Approved Amount
                      </div>

                      <div className="loan_inusurance_data">
                        {basicData?.approved_amount || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Scheme</div>
                      <div className="loan_inusurance_data">
                        {basicData?.scheme?.display_name || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Branch</div>
                      <div className="loan_inusurance_data">
                        {basicData?.location?.display_name || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">User</div>
                      <div className="loan_inusurance_data">
                        {basicData?.applicant_name || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Product</div>
                      <div className="loan_inusurance_data">
                        {basicData?.product?.display_name || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Interest Type</div>
                      <div className="loan_inusurance_data">
                        {basicData?.interest_type || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Loan Type</div>
                      <div className="loan_inusurance_data">
                        {basicData?.loan_type?.display_name || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="no_data_found_message">
                  <Empty />
                </div>
              )}
            </>
          )}
        </div>
        <div className="heading_details_container">
          <div className="heading_text">
            <div>Insurance Details</div>
            <div>
              <Button
                icon={<PlusCircleOutlined />}
                style={{
                  background: "#f5930a",
                  marginRight: "10px",
                  color: "white",
                  border: "1px solid #f5930a",
                }}
                onClick={() => showDrawer("External")}
              >
                Add Insurance
              </Button>
              {collapseSections ? (
                <UpOutlined
                  onClick={() => setCollapseSections(!collapseSections)}
                />
              ) : (
                <DownOutlined
                  onClick={() => setCollapseSections(!collapseSections)}
                />
              )}
            </div>
          </div>
          {collapseSections && (
            <>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  padding: "3px",
                  borderRadius: "6px",
                  // display: "flex",
                  gap: "10px",
                  marginTop: "10px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  // overflowX: "scroll",
                }}
              >
                {insurances && insurances.length > 0 ? (
                  insurances.map((insurance) => (
                    <Button
                      key={insurance.id}
                      style={{
                        border: "1px solid #276690",
                        backgroundColor:
                          activeButton === insurance.id ? "#276690" : "#FFFFFF",
                        color:
                          activeButton === insurance.id ? "#FFFFFF" : "#276690",
                        marginRight: "10px",
                        marginTop: "10px",
                        width: "210px",
                      }}
                      onClick={() => handleButtonClick(insurance)}
                    >
                      {insurance.insurance_company_detail__name}
                    </Button>
                  ))
                ) : (
                  <div className="no_data_found_message">
                    <Empty />
                  </div>
                )}
              </div>
              {insurances && insurances.length > 0 && renderInsuranceView()}
            </>
          )}
        </div>
        <div className="heading_details_container">
          <div className="heading_text">
            <div>Charges</div>
            <div>
              {chargesCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              )}
            </div>
          </div>
          {chargesCollapseSections && (
            <>
              {chargesData ? (
                <div className="table_container">
                  <Table
                    className="insurance_table"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </div>
              ) : (
                <Loading loading={loading} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoanInsurance;
