import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import "../../IncomeEvaluation/styles/monthlyExpenses.css";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import { getMonthlyExpensesById } from "../services/monthlyExpensesService";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const columns = [
  {
    title: "Sr. No",
    dataIndex: "sr_no",
    key: "sr_no",
  },
  {
    title: "Expense Type",
    dataIndex: "expense_type",
    key: "expense_type",
  },
  {
    title: "Frequency",
    dataIndex: "frequency",
    key: "frequency",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
];

const MonthlyExpenses = ({ customer_id, bank_id }) => {
  const { encrypted_loan_id } = useParams();
  const [monthlyExpenses, setMonthlyExpenses] = useState([]);
  const [, setTotalAmount] = useState(0);
  const [monthlyExpensesChartData, setMonthlyExpensesChartData] = useState([]);
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const monthlyExpensesData = await getMonthlyExpensesById(customer_id, loan_id,bank_id);
        const total = monthlyExpensesData.data.Total;
        const extractedMonthlyExpenses =
          monthlyExpensesData.data.category_wise_expenses.map(
            (data, index) => ({
              sr_no: index + 1,
              expense_type: data.category,
              frequency: "Monthly",
              amount: data.total_expenses,
            })
          );
        setTotalAmount(total);

        // Add total row
        const totalRow = {
          sr_no: "Total",
          expense_type: "",
          frequency: "",
          amount: total,
        };

        setMonthlyExpenses([...extractedMonthlyExpenses, totalRow]);

        const chartData = [["Expense Type", "Amount"]];
        monthlyExpensesData.data.category_wise_expenses.forEach((expense) => {
          chartData.push([expense.category, parseInt(expense.total_expenses)]);
        });
        setMonthlyExpensesChartData(chartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [customer_id, loan_id,bank_id]);

  const options = {
    is3D: true,
  };
  return (
    <div className="monthlyexpenses_container">
      <div className="business_expenses">
        <div className="business_table">
          <Table
            columns={columns}
            dataSource={[
              {
                sr_no: "Expenses",
                expense_type: "",
                frequency: "",
                amount: "",
              },
              ...monthlyExpenses,
            ]}
            pagination={false}
            rowClassName={(record) => {
              if (record.sr_no === "Total") {
                return "total_row";
              } else if (record.sr_no === "Expenses") {
                return "p_div";
              } else {
                return "";
              }
            }}
          />
        </div>
        <div className="chart">
          <div
            style={{
              width: "400px",
              marginVBottom: "10px",
              border: "0px solid red",
            }}
          >
            <Chart
              chartType="PieChart"
              data={monthlyExpensesChartData}
              options={options}
              // width={"100%"}
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyExpenses;
