import React, { useEffect, useState } from 'react';
import { Input, Select, Space, Button, Form, Row, Col, message } from "antd";
import { getDocumentDetailsByIdService, updateDocumentDetailsByIdService } from "../services/documentDetailsServices"
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
const { Option } = Select;

const ProvidedDocument = ({ open, closeForm, refreshData, stage_id }) => {
    const [form] = Form.useForm();
    const [technicalDocumentDetail, setTechnicalDocumentDetail] = useState(null);
    const [technicalid, setTechnicalid] = useState();
    const { encrypted_loan_id } = useParams();

    let loan_id = null;
    try {
      loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
      message.error("Invalid Loan ID");
    }

    const getPersonalData = async () => {
        let response, sale_deed, statutory_plan_number, sale_agreement, construction_agreement, rera_approval, allotment_letter, land_record, conversion_certificate, deviation_approval, property_tax_receipt, estimate_provided, other_document, general_power_of_attorney;
        try {
            response = await getDocumentDetailsByIdService(stage_id);
            setTechnicalDocumentDetail(response.data);
            setTechnicalid(response.data.sale_deed.technical_detail);

            ({
                sale_deed,
                statutory_plan_number,
                sale_agreement,
                construction_agreement,
                rera_approval,
                allotment_letter,
                land_record,
                conversion_certificate,
                deviation_approval,
                property_tax_receipt,
                estimate_provided,
                other_document,
                general_power_of_attorney
            } = response?.data);


            form?.setFieldsValue({
                sale_deed_collection_status: sale_deed?.collection_status,
                sale_deed_approval_number: sale_deed?.document_number,
                sale_agreement_collection_status: sale_agreement?.collection_status,
                sale_agreement_approval_number: sale_agreement?.document_number,
                statutory_plan_number_collection_status: statutory_plan_number?.collection_status,
                statutory_plan_number_approval_number: statutory_plan_number?.document_number,
                construction_agreement_collection_status: construction_agreement?.collection_status,
                construction_agreement_approval_number: construction_agreement?.document_number,
                rera_approval_collection_status: rera_approval?.collection_status,
                rera_approval_approval_number: rera_approval?.document_number,
                allotment_letter_collection_status: allotment_letter?.collection_status,
                allotment_letter_approval_number: allotment_letter?.document_number,
                land_record_collection_status: land_record?.collection_status,
                land_record_approval_number: land_record?.document_number,
                conversion_certificate_collection_status: conversion_certificate?.collection_status,
                conversion_certificate_approval_number: conversion_certificate?.document_number,
                deviation_approval_collection_status: deviation_approval?.collection_status,
                deviation_approval_approval_number: deviation_approval?.document_number,
                property_tax_reciept_collection_status: property_tax_receipt?.collection_status,
                property_tax_reciept_approval_number: property_tax_receipt?.document_number,
                estimate_provided_collection_status: estimate_provided?.collection_status,
                estimate_provided_approval_number: estimate_provided?.document_number,
                other_documents_approval_number: other_document?.document_number,
                other_documents_collection_status: other_document?.collection_status,
                general_power_approval_number: general_power_of_attorney?.document_number,
                general_power_collection_status: general_power_of_attorney?.collection_status,
            });
        } catch (error) {
            console.error("Error fetching document details:", error);
        }
    };



    useEffect(() => {
        if (open) {
            getPersonalData();
        } else {
            form?.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, form, loan_id]);



    const handleSubmit = async (values) => {

        const payload = {
            sale_deed: [
                {
                    id: technicalDocumentDetail?.sale_deed?.id,
                    collection_status: values?.sale_deed_collection_status,
                    document_number: values?.sale_deed_approval_number,
                }
            ],
            sale_agreement: [
                {
                    id: technicalDocumentDetail?.sale_agreement?.id,
                    collection_status: values?.sale_agreement_collection_status,
                    document_number: values?.sale_agreement_approval_number,
                }
            ],
            statutory_plan: [
                {
                    id: technicalDocumentDetail?.statutory_plan_number?.id,
                    collection_status: values?.statutory_plan_number_collection_status,
                    document_number: values?.statutory_plan_number_approval_number,
                }
            ],
            construction_agreement: [
                {
                    id: technicalDocumentDetail?.construction_agreement?.id,
                    collection_status: values?.construction_agreement_collection_status,
                    document_number: values?.construction_agreement_approval_number,
                }
            ],
            rera_approval: [
                {
                    id: technicalDocumentDetail?.rera_approval?.id,
                    collection_status: values?.rera_approval_collection_status,
                    document_number: values?.rera_approval_approval_number,
                }
            ],
            allotment: [
                {
                    id: technicalDocumentDetail?.allotment_letter?.id,
                    collection_status: values?.allotment_letter_collection_status,
                    document_number: values?.allotment_letter_approval_number,
                }
            ],
            land_record: [
                {
                    id: technicalDocumentDetail?.land_record?.id,
                    collection_status: values?.land_record_collection_status,
                    document_number: values?.land_record_approval_number,
                }
            ],
            deviation_approval: [
                {
                    id: technicalDocumentDetail?.deviation_approval?.id,
                    collection_status: values?.deviation_approval_collection_status,
                    document_number: values?.deviation_approval_approval_number,
                }
            ],
            property_tax: [
                {
                    id: technicalDocumentDetail?.property_tax_receipt?.id,
                    collection_status: values?.property_tax_reciept_collection_status,
                    document_number: values?.property_tax_reciept_approval_number,
                }
            ],
            estimate_provided: [
                {
                    id: technicalDocumentDetail?.estimate_provided?.id,
                    collection_status: values?.estimate_provided_collection_status,
                    document_number: values?.estimate_provided_approval_number,
                }
            ],
            other_documents: [
                {
                    id: technicalDocumentDetail?.other_document?.id,
                    collection_status: values?.other_documents_collection_status,
                    document_number: values?.other_documents_approval_number,
                }
            ],
            general_attorney: [
                {
                    id: technicalDocumentDetail?.general_power_of_attorney?.id,
                    collection_status: values?.general_power_collection_status,
                    document_number: values?.general_power_approval_number,
                }
            ],
            conversion_certificate: [
                {
                    id: technicalDocumentDetail?.conversion_certificate?.id,
                    collection_status: values?.conversion_certificate_collection_status,
                    document_number: values?.conversion_certificate_approval_number,
                }
            ],
        };

        try {
            const response = await updateDocumentDetailsByIdService(technicalid, payload);
            if (response && response?.data) {
                if (response?.status === 200 && response?.success) {
                    message.success("Documents Provided by VHFCL successfully");
                    closeForm();
                    refreshData();
                }
            } else {
                message.error("Failed to update Documents Provided by VHFCL");
                closeForm();
            }
        } catch (error) {
            message.error(error.response?.data?.message || "An error occurred while updating personal details");
            closeForm();
        }
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Row gutter={16} style={{ marginBottom: '10px', backgroundColor: "#F1F1F1" }}>
                <Col span={8} style={{ padding: "10px" }}>
                        Document Description
                    </Col>
                    <Col span={8} style={{ padding: "10px" }}>
                        Collection Status
                    </Col>
                    <Col span={8} style={{ padding: "10px" }}>
                        Document/approval Number
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Sale Deed/Release deed
                    </Col>
                    <Col span={8}>
                    <Form.Item
                        name="sale_deed_collection_status"
                        dependencies={['sale_deed_collection_status']}
                        rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                                onChange={(value) => {
                                    if (!value) {
                                        form.setFieldsValue({ sale_deed_approval_number: "" }); // Clear the input value
                                        form.setFields([{ name: 'sale_deed_approval_number', errors: [] }]); // Clear validation errors
                                    }
                                }}
                                
                                allowClear
                            >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.sale_deed_collection_status !== currentValues.sale_deed_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="sale_deed_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('sale_deed_collection_status') === true ? true : false,
                                            message: getFieldValue('sale_deed_collection_status') === true && 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your sale deed" disabled={getFieldValue('sale_deed_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Sale Agreement
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="sale_agreement_collection_status"
                            dependencies={['sale_agreement_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                            onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ sale_agreement_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'sale_agreement_approval_number', errors: [] }]); // Clear validation errors
                                }
                                
                            }}
                            allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.sale_agreement_collection_status !== currentValues.sale_agreement_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="sale_agreement_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('sale_agreement_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your sale agreement" disabled={getFieldValue('sale_agreement_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Statutory Plan And Number
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="statutory_plan_number_collection_status"
                            dependencies={['statutory_plan_number_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                             onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ statutory_plan_number_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'statutory_plan_number_approval_number', errors: [] }]); // Clear validation errors
                                }
                                
                            }}
                            allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.statutory_plan_number_collection_status !== currentValues.statutory_plan_number_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="statutory_plan_number_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('statutory_plan_number_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your statutory plan and number" disabled={getFieldValue('statutory_plan_number_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Construction Agreement/Gift Deed
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="construction_agreement_collection_status"
                            dependencies={['construction_agreement_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                              onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ construction_agreement_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'construction_agreement_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }} 
                            allowClear
                            >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.construction_agreement_collection_status !== currentValues.construction_agreement_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="construction_agreement_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('construction_agreement_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your construction agreement/gift deed" disabled={getFieldValue('construction_agreement_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        RERA Approval Number If Applicable
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="rera_approval_collection_status"
                            dependencies={['rera_approval_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                               onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ rera_approval_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'rera_approval_approval_number', errors: [] }]); // Clear validation errors
                                }
                                
                            }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.rera_approval_collection_status !== currentValues.rera_approval_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="rera_approval_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('rera_approval_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your RERA approval number if applicable" disabled={getFieldValue('rera_approval_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Allotment Letter
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="allotment_letter_collection_status"
                            dependencies={['allotment_letter_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                     >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                               onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ allotment_letter_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'allotment_letter_approval_number', errors: [] }]); // Clear validation errors
                                }
                               
                            }}  allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.allotment_letter_collection_status !== currentValues.allotment_letter_collection_status}
                            
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="allotment_letter_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('allotment_letter_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your  allotment letter" disabled={getFieldValue('allotment_letter_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        7/12 Extract/Patta/Land Record
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="land_record_collection_status"
                            dependencies={['land_record_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                       >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                               onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ land_record_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'land_record_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }}
                            allowClear
                            >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.land_record_collection_status !== currentValues.land_record_collection_status}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="land_record_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('land_record_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your 7/12 extract/patta/land record" disabled={getFieldValue('land_record_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Conversion Certificate
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="conversion_certificate_collection_status"
                            dependencies={['conversion_certificate_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                              onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ conversion_certificate_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'conversion_certificate_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.conversion_certificate_collection_status !== currentValues.conversion_certificate_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="conversion_certificate_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('conversion_certificate_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your conversion certificate" disabled={getFieldValue('conversion_certificate_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Deviation Approval Mail From Vastu Authority For Lack Of Documents
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="deviation_approval_collection_status"
                            dependencies={['deviation_approval_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                                onChange={(value) => {
                                    if (!value) {
                                        form.setFieldsValue({ deviation_approval_approval_number: "" }); // Clear the input value
                                        form.setFields([{ name: 'deviation_approval_approval_number', errors: [] }]); // Clear validation errors
                                    }
                                }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.deviation_approval_collection_status !== currentValues.deviation_approval_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="deviation_approval_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('deviation_approval_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your deviation approval mail from vastu authority for lack of documents" disabled={getFieldValue('deviation_approval_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Property Tax Receipt
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="property_tax_reciept_collection_status"
                            dependencies={['property_tax_reciept_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                             onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ property_tax_reciept_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'property_tax_reciept_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.property_tax_reciept_collection_status !== currentValues.property_tax_reciept_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="property_tax_reciept_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('property_tax_reciept_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your property tax receipt" disabled={getFieldValue('property_tax_reciept_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>




                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Estimate Provided And Its Value
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="estimate_provided_collection_status"
                            dependencies={['estimate_provided_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                             onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ estimate_provided_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'estimate_provided_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.estimate_provided_collection_status !== currentValues.estimate_provided_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="estimate_provided_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('estimate_provided_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                    allowClear
                                >
                                    <Input placeholder="Please enter your estimate provided and its value" disabled={getFieldValue('estimate_provided_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        Other Documents
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="other_documents_collection_status"
                            dependencies={['other_documents_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                             onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ other_documents_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'other_documents_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>



                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.other_documents_collection_status !== currentValues.other_documents_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="other_documents_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('other_documents_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your other documents approval number" disabled={getFieldValue('other_documents_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '16px', marginTop: "20px" }}>
                    <Col span={8}>
                        General Power Of Attorney
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="general_power_collection_status"
                            dependencies={['general_power_collection_status']}
                            rules={[{ required: true, message: "Collection Status is required" }]}
                        >
                            <Select placeholder="Search To Select" style={{ width: '100%' }}
                             onChange={(value) => {
                                if (!value) {
                                    form.setFieldsValue({ general_power_approval_number: "" }); // Clear the input value
                                    form.setFields([{ name: 'general_power_approval_number', errors: [] }]); // Clear validation errors
                                }
                            }} allowClear>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.general_power_collection_status !== currentValues.general_power_collection_status}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="general_power_approval_number"
                                    style={{ margin: 0 }}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: getFieldValue('general_power_collection_status') === true,
                                            message: 'Please enter approval number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter your general power of attorney" disabled={getFieldValue('general_power_collection_status') === false} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>



                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse", marginTop: "50px" }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>
        </div>
    );
}

export default ProvidedDocument;


