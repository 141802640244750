import React, { useEffect, useState } from "react";
import { Table, message, Modal, Divider, Form, Skeleton } from "antd";
import { Tooltip } from "antd";
import { getLoanStagesListService, stageToCompleteService } from "../services/loanapplicationService";
import { useParams, Link } from "react-router-dom";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { decrypt, encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { getActionRoute } from "../../../../utils/stageFlow/StageFlow";
import SendBackForm from "./SendBackForm";
import QueryIcon from "../assets/query.svg";
import ForwardIcon from "../assets/forward.svg";
import CompleteIcon from "../assets/complete.svg";
import ForwardForm from "./ForwardForm";
import CancelIcon from "../assets/cancel.svg";
import { updateUserAndStatusById } from "../services/sendBackService";

const LoanApplicationDetails = ({ open }) => {
  const { encrypted_loan_id } = useParams();
  const [stagesData, setStagesData] = useState(null);
  const [stageCode, setStageCode] = useState(null);
  const [getID, setID] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isForwardModalVisible, setIsForwardModalVisible] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(null);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmStatusModalVisible, setConfirmStatusModalVisible] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedStageCode, setSelectedStageCode] = useState(null);
  const [sendBackForm] = Form.useForm();
  const [forwaedForm] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const allowedStages = ["CAM", "Dedupe", "Technical", "Legal", "RCU", "FI"];

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  // const showModal = (stage_code) => {
  //   setIsModalVisible(true);
  //   setStageCode(stage_code);
  // };

  const showModal = (record) => {
    console.log("222", record)
    setIsModalVisible(true);
    setStageCode(record.stage_code); // You may still need the stage_code for some logic.

    // Pre-fill the form with stage and handled_by values
    sendBackForm.setFieldsValue({
      stage: record.stage,
      assign_to: record.handled_by,
    });
  };
  // const showForwardModal = (id) => {
  //   setIsForwardModalVisible(true);
  //   setID(id);
  // };

  const showForwardModal = (record) => {
    setIsForwardModalVisible(true);
    setID(record.id); // Store the record ID for future operations

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    sendBackForm.resetFields();
  };

  const handleCloseForwardModal = () => {
    setIsForwardModalVisible(false);
    forwaedForm.resetFields();
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const showConfirmModal = (id, stage, stage_code) => {
    setSelectedStage(stage);
    setSelectedStageCode(stage_code);
    setSelectedStageId(id);
    setConfirmModalVisible(true);
  };

  const showConfirmStatusModal = (id, stage) => {
    setSelectedStageId(id);
    setSelectedStage(stage);
    setConfirmStatusModalVisible(true);
  };

  const handleConfirmOk = async () => {
    setLoading(true); // Start loading

    try {
      await handleCompleteStage(selectedStageId, selectedStage, selectedStageCode);
      setConfirmModalVisible(false); // Close the modal after completion
    } catch (error) {
      // Handle error if any
      setLoading(false); // Stop loading
      message.error("An error occurred while completing the stage.");
    }
  };


  const handleConfirmStatusOk = () => {
    setConfirmStatusModalVisible(false);
    handleCancelStage(selectedStageId, selectedStage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLoanStagesListService(loan_id);
        setStagesData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    if (refreshTableData || open) {
      fetchData();
    }
  }, [loan_id, refreshTableData, open]);

  const handleCompleteStage = async (selectedStageId, stage, stage_code) => {
    try {
      const payload = {
        loan_stage: selectedStageId,
        loan_detail: loan_id,
        stage: stage_code,
        stage_status: "MSSTPDD5",
      };
      const response = await stageToCompleteService(payload);
      if ((response.status = 200 && response.success)) {
        message.success(`${stage} Stage completed successfully`);
        toggleRefreshTableData();
      }
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCancelStage = async (id, stage) => {
    try {
      if (id) {
        const payload = {
          stage_status: "MSSNLLL1",
        };
        const response = await updateUserAndStatusById(id, payload);
        if ((response.status = 200 && response.success)) {
          message.success(`${stage} Stage cancelled successfully`);
          toggleRefreshTableData();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getActionButton = (record) => {
    const { id, stage, stage_code, status } = record;

    const tooltipText = (action) => {
      switch (action) {
        case "Complete":
          return `Complete ${stage}`;
        case "Forward":
          return `Forward ${stage}`;
        case "Cancel":
          return `Cancel ${stage}`;
        case "Query":
          return `Query ${stage}`;
        default:
          return "";
      }
    };

    switch (status) {
      case "Completed":
        if (stage !== "Lead") {
          return (
            <Tooltip title={tooltipText("Query")}>
              <img
                alt="Query Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={QueryIcon}
                onClick={() => showModal(record)} // Pass the entire record
              />
            </Tooltip>
          );
        }
        return <div style={{ marginLeft: "10px" }}>-</div>;

      case "Re - Initiated":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Query")}>
              <img
                alt="Query Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={QueryIcon}
                onClick={() => showModal(record)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={ForwardIcon}
                onClick={() => showForwardModal(id)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );

      case "Initiated":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Query")}>
              <img
                alt="Query Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={QueryIcon}
                onClick={() => showModal(record)} // Pass the entire record
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={ForwardIcon}
                onClick={() => showForwardModal(record)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );

      case "Cancelled":
        return <div>-</div>;

      default:
        return <div>-</div>;
    }
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      render: (text) => text || "-",
    },
    {
      title: "Handled By",
      dataIndex: "handled_by",
      key: "handled_by",
      render: (text) => text || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const { stage, status, id } = record;
        const encrypted_stage_id = encrypt(id.toString());
        if (
          allowedStages.includes(stage) &&
          (status === "Initiated" || status === "Completed")
        ) {
          return (
            <Link
              to={getActionRoute(
                stage,
                status,
                encrypted_loan_id,
                encrypted_stage_id
              )}
              style={{
                textDecoration: "underline",
              }}
            >
              {status}
            </Link>
          );
        }
        return <span>{status || "-"}</span>;
      },
    },
    {
      title: "Initiated Date",
      dataIndex: "initiated_date",
      key: "initiated_date",
      render: (text) => text || "-",
    },
    {
      title: "Completed Date",
      dataIndex: "completed_date",
      key: "completed_date",
      render: (text) => text || "-",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => getActionButton(record),
    },
  ];

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {stagesData.length > 0 && (
            <Table
              columns={columns}
              dataSource={stagesData}
              pagination={false}
            />
          )}
        </>
      )}
      <Modal
        width={700}
        title="Sendback"
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        style={{ top: 40 }} // Adjust top positioning if needed
      >
        <Divider style={{ margin: "15px 0px" }} />

        <SendBackForm
          closeForm={handleCancel}
          stage_code={stageCode}
          form={sendBackForm}
          toggleTableRefreshData={toggleRefreshTableData}
        />
      </Modal>
      <Modal
        width={700}
        title="Forward"
        open={isForwardModalVisible}
        footer={null}
        onCancel={handleCloseForwardModal}
        style={{ top: 40 }} // Adjust top positioning if needed
      >
        <Divider style={{ margin: "15px 0px" }} />

        <ForwardForm
          closeForm={handleCloseForwardModal}
          // stage_code={stageCode}
          form={forwaedForm}
          id={getID}
          toggleTableRefreshData={toggleRefreshTableData}
        />
      </Modal>
      <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={() => setConfirmModalVisible(false)}
        okType="danger"
        okButtonProps={{ loading }}
      >
        <p>{`Are you sure you want to complete the ${selectedStage} stage?`}</p>
      </Modal>
      <Modal
        title="Confirm Cancel"
        open={confirmStatusModalVisible}
        onOk={handleConfirmStatusOk}
        onCancel={() => setConfirmStatusModalVisible(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to cancel the ${selectedStage} stage?`}</p>
      </Modal>
    </div>
  );
};

export default LoanApplicationDetails;
