import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Select,
  Row,
  Upload,
  message,
  Modal,
  Space,
  Empty
} from "antd";
import { UploadOutlined, UpOutlined, DeleteOutlined, FileOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RCUPostService, getRCUdetails } from "../services/rcuservices";
import pdf from "../assets/Pdf.png"
import { useNavigate, useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import Loading from "../../../utils/loading/Loading";
import RViewerJS from "viewerjs-react";

import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";
const { Option } = Select;

const RcuPost = () => {
  const [form] = Form.useForm();
  // const [isBasicDetailVisible, setIsBasicDetailVisible] = useState(true);
  const [isCustomersVisible, setIsCustomersVisible] = useState(true);
  const [rcuStatusVisible, setRcuStatusVisible] = useState(true);
  const [deviationVisible, setDeviationVisible] = useState(true);
  const [conclusionVisible, setConclusionVisible] = useState(true);
  const [completeRemarkVisible, setCompleteRemarkVisible] = useState(true);
  // const [vendorVisible, setVendorVisible] = useState(true);
  const [data, setData] = useState(true);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [images, setImages] = useState([]);
  const [isPropertyDocuments, setIsPropertyDocuments] = useState(true);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loanid, setLoanid] = useState(null);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [buttonloading, setbuttonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setLoanid(decrypt(encrypted_loan_id));
    } catch (error) {
      message.error("Invalid Loan ID");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encrypted_loan_id]);

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  // const toggleBasicDetailVisibility = () => {
  //   setIsBasicDetailVisible(!isBasicDetailVisible);
  // };

  const toggleCustomers = () => {
    setIsCustomersVisible(!isCustomersVisible);
  };

  const togglercustatus = () => {
    setRcuStatusVisible(!rcuStatusVisible);
  };

  const togglerDevation = () => {
    setDeviationVisible(!deviationVisible);
  };

  const togglerConclusion = () => {
    setConclusionVisible(!conclusionVisible);
  };

  const togglerCompleteRemark = () => {
    setCompleteRemarkVisible(!completeRemarkVisible);
  };

  // const togglerVendor = () => {
  //   setVendorVisible(!vendorVisible);
  // };

  const getRCUData = async () => {
    try {
      setLoading(true)
      const response = await getRCUdetails(stage_id);
      if (response.status === 200 && response.success) {
        setData(response.data);
        setLoading(false)
      }
    } catch (error) {
      message.error((error?.response?.status, error?.response?.data?.message));
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  const handleUploadChange = async ({ fileList: newFileList }) => {
    // Keep the new file list
    setFileList(newFileList);

    // Convert newly uploaded files to base64
    const base64Files = await Promise.all(
      newFileList.map((file) => fileToBase64(file.originFileObj))
    );

    // Sync images state with the new file list using uid and store the file name
    const updatedImages = newFileList.map((file, index) => ({
      uid: file.uid,
      file_path: base64Files[index],
      file_name: file.name, // Store file name
    }));

    setImages(updatedImages);
  };

  const handleRemove = (file) => {
    // Filter out the file that was removed based on uid
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    setImages((prevImages) => prevImages.filter((item) => item.uid !== file.uid));
  };

  const confirmDelete = () => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.uid !== deleteUploadPicture.uid)
    );
    setFileList((prevList) =>
      prevList.filter((file) => file.uid !== deleteUploadPicture.uid)
    );
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const handleSubmit = async (values) => {
    // Function to strip HTML tags from a string
    const stripHtmlTags = (htmlString) => {
      const doc = new DOMParser().parseFromString(htmlString, 'text/html');
      return doc.body.textContent || "";
    };
    setbuttonLoading(true);
    try {
      // Format the values for submission
      const formattedValues = {
        ...values,
        credit_assessment_stage_initiate: data?.credit_assessment_detail?.id,
        // loan_stage: Number(stage_id),
        product_type: 2,
        sourcing_location: data?.loan_detail?.location?.id,
        reporting_date: "2022-10-12",
        rcu_customer_details: (
          data.rcu_detail?.customer_wise_rcu_address_and_doc ?? []
        ).map((customer, index) => ({
          customer_detail: customer.customer_id,
          dedup_check: values[`dedup-check_${index}`],
        })),
        rcu_detail_docs: (
          data.rcu_detail?.customer_wise_rcu_address_and_doc ?? []
        ).flatMap((docs, index) => {
          // Filter out documents that are not of type "Profile"
          const filteredDocs = docs.rcu_document_detail.filter(
            (type) => type.document_type !== "Profile"
          );

          return filteredDocs.map((doc) => {
            return {
              rcu_document_detail: doc.rcu_document_id, // Use rcu_document_id for document reference
              remark: stripHtmlTags(values[`remarks_${doc.rcu_document_id}`]), // Get remark value
              status: values[`status_${doc.rcu_document_id}`] ? "Satisfactory" : "RCU-Medium Risk", // Get status value
              documents: filteredDocs, // Keep track of filtered documents (non-Profile)
            };
          });
        }),

        // Map profile RCU documents (documents with type "Profile")
        rcu_profile_detail: (
          data.rcu_detail?.customer_wise_rcu_address_and_doc ?? []
        ).flatMap((customer, index) => {
          // Filter to only include "Profile" documents
          const profileDocs = customer.rcu_document_detail.filter(
            (type) => type.document_type === "Profile"
          );

          return profileDocs.map((doc) => {
            return {
              rcu_profile_detail: doc.rcu_document_id, // Use rcu_document_id for profile document
              remark: stripHtmlTags(values[`remarks_${doc.rcu_document_id}`]), // Get remark for profile document
              status: values[`status_${doc.rcu_document_id}`] ? "Satisfactory" : "RCU-Medium Risk", // Get status for profile document
              documents: profileDocs, // Keep only "Profile" documents here
            };
          });
        }),
        overall_remark: stripHtmlTags(values.overall_remark), // Remove HTML tags from overall_remark
        rcu_document_upload: images.map((image) => ({
          document_path: image.file_path,
        })),
      };


      // Submit the formatted values
      const response = await RCUPostService(formattedValues);
      if (response?.status === 200 && response?.success) {
        message.success("RCU form submitted successfully");
        navigate(`/loandetails/${encrypted_loan_id}`)
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while submitting the form"
      );
    }finally{  
      setLoading(false)
    }
  };

   

  useEffect(() => {
    if (loanid) {
      getRCUData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanid, stage_id]);

  // const dateString = data?.credit_assessment_detail?.initiated_at;

  // Function to format the date
  // function formatDate(dateStr) {
  //   const date = new Date(dateStr);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  //   const year = date.getFullYear();

  //   return `${day}-${month}-${year}`;
  // }

  // const formattedDate = formatDate(dateString);

  // const coapplicants =
  //   data?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
  //     (item) => item?.customer_type === "Co-Applicant"
  //   );

  const handleAlphaFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except alphanumeric characters (letters and numbers)
    newValue = newValue.replace(/[^a-zA-Z0-9]/g, "");

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };



  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      {loading ? (
        <div >
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>) :
        (<div>
          <div>
            <div className="main_technical_report">
              <div>
                <p className="para">RCU</p>
              </div>
              {/* <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className="legalvaluation" style={{ width: "50%" }}>
                    <span style={{ margin: "2px 5px" }}>
                      <img src={dot} alt="" />
                    </span>
                    RCU Completed
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    <img src={download} alt="" />
                  </div>
                </div>
                <div style={{ color: "#00000073", marginTop: "10px" }}>
                  Report Last Updated On: 27 Mar 2023
                </div>
              </div> */}
            </div>


<LoanSummaryBlock data={data}/>
            {/* <div className="Basic_card_technical">
              <div className="basic_card-content">
                <div className="label_technical">Loan Number</div>
                <div className="data_technical">
                  {data?.loan_detail?.loan_account_number || "-"}
                </div>
              </div>
              <div className="basic_card-content">
                <div className="label_technical">Application Number</div>
                <div className="data_technical">
                  {data?.loan_detail?.application_number || "-"}
                </div>
              </div>
              <div className="basic_card-content">
                <div className="label_technical">Sales Manager</div>
                <div className="data_technical">
                  {data?.loan_detail?.sales_manager?.name || "-"}
                </div>
              </div>
              <div className="basic_card-content">
                <div className="label_technical">Branch </div>
                <div className="data_technical">
                  {data?.loan_detail?.location?.name || "-"}
                </div>
              </div>
            </div>
           */}
            {/* <div className="details_contanier">
              <div className="firts_details">
                <div className="details">
                  <div className="details_p"> Details</div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">Agency Name :</div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.credit_assessment_detail?.agency?.display_name || "-"}
                  </div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">Agent Name :</div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.credit_assessment_detail?.agent?.name || "-"}
                  </div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">Applicant Name :</div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.rcu_detail?.customer_wise_rcu_address_and_doc ? (
                      data?.rcu_detail.customer_wise_rcu_address_and_doc?.filter(
                        (item) => item?.customer_type === "Applicant"
                      )?.length === 0 ? (
                        <div>-</div>
                      ) : (
                        data?.rcu_detail?.customer_wise_rcu_address_and_doc
                          ?.filter((item) => item?.customer_type === "Applicant")
                          ?.map((item) => (
                            <div key={item?.id}>
                              {item?.first_name} {item?.last_name}
                            </div>
                          ))
                      )
                    ) : (
                      <div>Loading...</div>
                    )}
                  </div>
                </div>

                <div className="technicle_data">
                  <div className="label_technical_lable">
                    Customer Mobile No. :
                  </div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.rcu_detail?.customer_wise_rcu_address_and_doc ? (
                      data?.rcu_detail.customer_wise_rcu_address_and_doc?.filter(
                        (item) => item?.customer_type === "Applicant"
                      )?.length === 0 ? (
                        <div>-</div>
                      ) : (
                        data?.rcu_detail?.customer_wise_rcu_address_and_doc
                          ?.filter((item) => item?.customer_type === "Applicant")
                          ?.map((item) => (
                            <div key={item?.id}>{item?.mobile_number}</div>
                          ))
                      )
                    ) : (
                      <div>Loading...</div>
                    )}
                  </div>
                </div>

                {coapplicants?.map((coapp, index) => (
                  <div className="technicle_data">
                    <div className="label_technical_lable">
                      Co-Applicant {index + 1}:{" "}
                    </div>
                    <div className="data_technical" style={{ width: "50%" }}>
                      {coapp?.first_name} {coapp?.last_name}
                    </div>
                  </div>
                ))}
                <div className="technicle_data">
                  <div className="label_technical_lable">Personal Guarantor</div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
                      (item) => item?.customer_type === "Guarantor"
                    )?.length === 0 ? (
                      <div>-</div>
                    ) : (
                      data?.rcu_detail?.customer_wise_rcu_address_and_doc
                        ?.filter((item) => item.customer_type === "Guarantor")
                        ?.map((item) => (
                          <div key={item.id}>
                            {" "}
                           
                            {item.first_name} {item.last_name}
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
              <div className="second_details">
                <div className="details">
                  <div className="details_p"> Initiated Data</div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">Vendor Email:</div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.credit_assessment_detail?.agency?.email || "-"}
                  </div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">
                    Credit Manager Name:
                  </div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.loan_detail?.credit_manager?.name || "-"}
                  </div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">
                    Credit Manager Mobile No:
                  </div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.loan_detail?.credit_manager?.mobile || "-"}
                  </div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">Initiated On: </div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {formattedDate}
                  </div>
                </div>
                <div className="technicle_data">
                  <div className="label_technical_lable">
                    Provided Documents:{" "}
                  </div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    Dear Team, Please proceed for technical in mentioned case.
                  </div>
                </div>
                <div className="technicle_data">
                  <div
                    className="label_technical_lable"
                    style={{ height: "86px" }}
                  >
                    Attachments:
                  </div>
                  <div className="data_technical" style={{ width: "50%" }}>
                    {data?.initiate_stage_attachment_detail?.map((rcu) => (
                      <img src={rcu.attachment} height={50} width={50} style={{ marginTop: 15, marginLeft: 15 }} alt="Document 1" />
                    ))}
                  </div>
                </div>
              </div>
            </div> */}

            <div
              style={{
                border: "1px solid #E4E7EC",
                marginTop: "15px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{ borderRadius: "10px" }}
                className=""
              >
                <div className="rcu_details">
                  <div className="rcu_details_blue">Customers</div>
                  <div>
                    <UpOutlined
                      style={{
                        margin: "1rem",
                        transform: isCustomersVisible
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                      onClick={toggleCustomers}
                    />
                  </div>
                </div>
              </div>
              {isCustomersVisible && (
                <>
                  <div className="">
                    <div style={{ backgroundColor: "#F1F1F1" }}>
                      <div className="rcu-inside-container">
                        <Row gutter={16}>
                          <Col span={6}>
                            <div className="rcu_basic_card_content">
                              <div className="label_rcu">Customer Name</div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="rcu_basic_card_content">
                              <div className="label_rcu">
                                Residence Address
                              </div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="rcu_basic_card_content">
                              <div className="label_rcu">
                                Office Address
                              </div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="rcu_basic_card_content">
                              <div className="label_rcu">Dedupe Check</div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <div className="rcu-inside-container">
                        {data?.rcu_detail?.customer_wise_rcu_address_and_doc &&
                          Array.isArray(data.rcu_detail?.customer_wise_rcu_address_and_doc) &&
                          data?.rcu_detail?.customer_wise_rcu_address_and_doc.length > 0 ? (
                          (data?.rcu_detail?.customer_wise_rcu_address_and_doc ?? []).map(
                            (customer, index, array) => (
                              <Row
                                gutter={16}
                                key={index}
                                style={{
                                  borderBottom:
                                    index < array.length - 1 ? "1px solid rgb(217, 217, 217)" : "none", // Apply border only if not the last item
                                }}
                              >
                                <Col span={4}>
                                  <div className="rcu_basic_card_content">
                                    <div className="label_rcu">
                                      {customer.first_name} {customer.middle_name} {customer.last_name}
                                    </div>
                                  </div>
                                </Col>
                                <Col span={7}>
                                  {customer?.rcu_address_detail?.map(
                                    (address, idx) =>
                                      address.address_type === "Permanent Address" && (
                                        <div className="rcu_basic_card_content" key={idx}>
                                          <div className="label_rcu">
                                            {address.address_detail}
                                          </div>
                                        </div>
                                      )
                                  )}
                                </Col>
                                <Col span={7}>
                                  <div className="rcu_basic_card_content">
                                    <div className="label_rcu">
                                      {customer?.rcu_address_detail?.map(
                                        (address, idx) =>
                                          address.address_type === "Office Address" && (
                                            <div key={idx}>{address.address_detail}</div>
                                          )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name={`dedup-check_${index}`}
                                    label=""
                                    rules={[
                                      {
                                        required: true,
                                        message: "Dedupe Check is required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Search To Select"
                                      style={{ width: "100%", marginTop: 20 }}
                                      allowClear
                                    >
                                      <Option value={true}>Yes</Option>
                                      <Option value={false}>No</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            )
                          )
                        ) : (
                          <div>No address details available</div>
                        )}
                      </div>

                    </div>
                  </div>
                </>
              )}
            </div>

            <div
              style={{
                border: "1px solid #E4E7EC",
                marginTop: "15px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{ borderRadius: "10px" }}
               
              >
                <div className="rcu_details">
                  <div className="rcu_details_blue">RCU Status</div>
                  <div>
                    <UpOutlined
                      style={{
                        margin: "1rem",
                        transform: rcuStatusVisible
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                      onClick={togglercustatus}
                    />
                  </div>
                </div>
              </div>
              {rcuStatusVisible && (
                <>
                  <div style={{ backgroundColor: "#F1F1F1" }}>
                    <div className="rcu-inside-container">
                      <Row gutter={16}>
                        <Col span={4}>
                          <div className="rcu_basic_card_content">
                            <div className="label_rcu">SR.NO</div>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="rcu_basic_card_content">
                            <div className="label_rcu">Document</div>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="rcu_basic_card_content">
                            <div className="label_rcu" style={{ marginLeft: "40px" }}>Images</div>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="rcu_basic_card_content">
                            <div className="label_rcu">Remarks</div>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="rcu_basic_card_content">
                            <div className="label_rcu">Status</div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div>
                    <div className="rcu-inside-container">
                      {data?.rcu_detail?.customer_wise_rcu_address_and_doc &&
                        Array.isArray(data?.rcu_detail?.customer_wise_rcu_address_and_doc) &&
                        data?.rcu_detail?.customer_wise_rcu_address_and_doc.length > 0 ? (
                        data?.rcu_detail?.customer_wise_rcu_address_and_doc.map((item, index, array) => (
                          <Row
                            gutter={16}
                            key={index}
                            style={{
                              borderBottom: index < array.length - 1 ? "1px solid rgb(228, 231, 236)" : "none" // Apply border only if not the last item
                            }}
                            className="rcu_value_property_deviation"
                          >
                            <Col span={4}>
                              <div className="basic_card-content">
                                <div style={{ color: "#00000080" }}>
                                  {index + 1}
                                </div>
                              </div>
                            </Col>
                            <Col span={5} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                              {/* <div className="basic_card-content"> */}

                              {item?.rcu_document_detail.map((items, index) => (
                                <div className="basic_card-content">
                                  <div className="label_technical">
                                    <React.Fragment key={index}>
                                      <div style={{ marginTop: "-30px" }}>
                                        {/* <div className="label_technical-document"> */}
                                        {items?.document_type}
                                        {/* </div> */}
                                      </div>
                                    </React.Fragment>
                                  </div>
                                </div>
                              ))}
                              {/* </div> */}
                            </Col>
                            <Col span={5}>
                              <div className="basic_card-content">
                                <div className="label_technical">
                                  {item?.rcu_document_detail?.map((items, index) => {
                                    const url = items.document_detail_url || ""; // Handle undefined or null URLs
                                    const parts = url.split('/');
                                    const fileName = parts.pop().split('?')[0]; // Extract the file name without query parameters
                                    const fileExtension = fileName.split('.').pop().toLowerCase(); // Extract the file extension

                                    return (
                                      <React.Fragment key={index}>

                                        <div
                                          className="img_document"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {/* Conditionally render based on file extension */}
                                          {['png', 'jpg', 'jpeg'].includes(fileExtension) ? (
                                            <RViewerJS>
                                              <img
                                                src={url}
                                                height={45}
                                                width={45}
                                                style={{ marginTop: "15px", cursor: "pointer" }}
                                                alt={`Document: ${fileName}`}
                                              />
                                            </RViewerJS>
                                          ) : fileExtension === 'pdf' ? (
                                            <>
                                              <img
                                                src={pdf} // Ensure 'pdf' is a valid imported image placeholder for PDFs
                                                alt="PDF"
                                                height={45}
                                                width={45}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => window.open(url, "_blank")}
                                              />
                                            </>
                                          ) : (
                                            <FileOutlined
                                              style={{
                                                fontSize: "35px",
                                                cursor: "pointer",
                                              }}
                                            // title={fileName} // Optional: tooltip for file name
                                            />
                                          )}

                                          {/* Display the file name next to the image/icon */}
                                          {/* <p
                                            style={{
                                              marginTop: "15px",
                                              textAlign: "center",
                                              wordBreak: "break-all", // Handle long file names
                                            }}
                                          >
                                            {fileName}
                                          </p> */}
                                        </div>
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </Col>

                            <Col span={5} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                              {item?.rcu_document_detail?.map((items, index) => (
                                <div className="basic_card-content">
                                  <div className="label_technical"> 
                                    <Form.Item
                                      key={index} // Make sure each item has a unique key
                                      name={`remarks_${items.rcu_document_id}`}
                                      required
                                      rules={[
                                        {
                                          required: true,
                                          message: "Remark is required",
                                        },
                                      ]}
                                    // style={{ marginTop: 55 }}
                                    >
                                      <Input placeholder="Please Enter Remark" />
                                    </Form.Item>
                                  </div>
                                </div>
                              ))}
                            </Col>

                            <Col span={5} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                              {item?.rcu_document_detail?.map((items, index) => (
                                <div className="basic_card-content" key={items.rcu_document_id}>
                                  <div className="label_technical">
                                    <Form.Item
                                      name={`status_${items.rcu_document_id}`}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: "Status is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Search To Select"
                                        style={{ width: "100%" }}
                                        allowClear
                                      >
                                        <Option value={true}>Satisfactory</Option>
                                        <Option value={false}>RCU-Medium Risk</Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <div>No document details available</div>
                      )}
                    </div>

                  </div>
                </>
              )}
            </div>

            {/* {data?.loan_deviation_detail?.length > 0 && ( */}
            <div
              style={{
                border: "1px solid #E4E7EC",
                marginTop: "15px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{ borderRadius: "10px" }}
                className="container_technical"
              >
                <div className="valuation_details_blue_heading">
                  <div className="valuation_details_blue">
                    Manual Deviation Details
                  </div>
                  <div>
                    <UpOutlined
                      style={{
                        margin: "1rem",
                        transform: rcuStatusVisible
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                      onClick={togglerDevation}
                    />
                  </div>
                </div>
              </div>
              {data?.loan_deviation_detail?.length > 0 ? (
                <>
                  <div style={{ backgroundColor: "#F1F1F1" }}>
                    <div className="rcu-inside-container">
                      <Row gutter={16}>
                        <Col span={6}>
                          <div className="basic_card-content">
                            <div className="label_technical">SR.NO</div>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="basic_card-content">
                            <div className="label_technical">Deviations</div>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="basic_card-content">
                            <div className="label_technical">
                              Actual Deviation
                            </div>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="basic_card-content">
                            <div className="label_technical">Mitigates</div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div>
                    <div className="rcu-inside-container">
                      {data.loan_deviation_detail.map((deviation, index, array) => (
                        <Row
                          gutter={16}
                          style={{
                            borderBottom: index < array.length - 1 ? "1px solid #E4E7EC" : "none" // Apply border only if not the last item
                          }}
                          key={index} // Ensure you provide a unique key for each row
                        >
                          <Col span={6}>
                            <div className="basic_card-content">
                              <div className="label_technical">{index + 1}</div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="basic_card-content">
                              <div className="label_technical">
                                {deviation.deviation.name}
                              </div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="basic_card-content">
                              <div className="label_technical">
                                {deviation.actual_description}
                              </div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="basic_card-content">
                              <div className="label_technical">
                                {deviation.mitigates}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>

                  </div>
                </>
              ) : (
                <Empty style={{ marginTop: 35, marginBottom: 35 }} />
              )}
            </div>
            {/* // )} */}

            <div
              style={{
                border: "1px solid #E4E7EC",
                marginTop: "15px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{ borderRadius: "10px" }}
                className="container_technical"
              >
                <div className="valuation_details_blue_heading">
                  <div className="valuation_details_blue">Conclusions</div>
                  <div>
                    <UpOutlined
                      style={{
                        margin: "1rem",
                        transform: conclusionVisible
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                      onClick={togglerConclusion}
                    />
                  </div>
                </div>
              </div>
              {conclusionVisible && (
                <>
                  <div>
                    <div className="rcu-inside-container">
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="basic_card-content">
                            <div className="label_technical">Overall Status <span className='all_required_sign'> *</span></div>
                          </div>
                          <Form.Item
                            name="overall_status"
                            rules={[
                              {
                                required: true,
                                message: "Overall Status is required",
                              },
                            ]}
                          >
                            <Select placeholder="Please Enter Remark" allowClear>
                              <Select.Option value={"Satisfactory"}>
                                Satisfactory
                              </Select.Option>
                              <Select.Option value={"RCU-Medium Risk"}>
                                RCU-Medium Risk
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <div className="basic_card-content">
                            <div className="label_technical">Overall Remark <span className='all_required_sign'> *</span></div>
                          </div>
                          <Form.Item
                            name="overall_remark"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Overall Remark is required",
                              },
                            ]}

                          >
                            <ReactQuill
                              style={{ borderRadius: "10px", marginTop: "10px" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div></div>
                </>
              )}
            </div>

            <div>
              <div>
                <div
                  style={{
                    border: "1px solid #E4E7EC",
                    marginTop: "15px",
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ borderRadius: "10px" }} className="container_technical">
                    <div className="valuation_details_blue_heading">
                      <div className="valuation_details_blue">
                        Property Pictures and Other Documents{" "}
                      </div>
                      <div>
                        <UpOutlined
                          style={{
                            margin: "1rem",
                            transform: isPropertyDocuments
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          onClick={() => setIsPropertyDocuments(!isPropertyDocuments)}
                        />
                      </div>
                    </div>
                  </div>
                  {isPropertyDocuments && (
                    <div className="technical_property_container">
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item name="file_path" label=" ">
                            <Upload
                              multiple
                              fileList={fileList}
                              onChange={handleUploadChange}
                              onRemove={handleRemove}
                              beforeUpload={() => false} // Prevent automatic upload
                              showUploadList={false}
                            >
                              <Button type="primary" icon={<UploadOutlined />}>
                                Upload Document
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>

                      <div style={{ margin: "10px 10px 10px 10px" }}>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {images.map((picture) => (
                            <div
                              key={picture.uid}
                              style={{ margin: "10px", position: "relative" }}
                            >
                              <RViewerJS>
                                <img
                                  src={picture.file_path}
                                  alt=""
                                  style={{ width: "75px", height: "75px", cursor: "pointer" }}
                                />
                              </RViewerJS>
                              <div
                                style={{
                                  marginTop: "5px",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  wordWrap: "break-word",
                                  width: "75px",
                                }}
                              >
                                {picture.file_name} {/* Display the file name */}
                              </div>
                              <div
                                style={{
                                  top: 0,
                                  right: 0,
                                  color: "#E5233DB2",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                                onClick={() => {
                                  setDeleteUploadPicture(picture);
                                  setOpenUploadModal(true);
                                }}
                              >
                                <DeleteOutlined />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <Modal
                        title="Confirm Delete"
                        open={openUploadModal}
                        onOk={confirmDelete}
                        onCancel={() => {
                          setOpenUploadModal(false);
                          setDeleteUploadPicture(null);
                        }}
                        okType="danger"
                      >
                        <p>Are you sure you want to delete this uploaded image?</p>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                border: "1px solid #E4E7EC",
                marginTop: "15px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{ borderRadius: "10px" }}
                className="container_technical"
              >
                <div className="valuation_details_blue_heading">
                  <div className="valuation_details_blue">Complete Remark</div>
                  <div>
                    <UpOutlined
                      style={{
                        margin: "1rem",
                        transform: completeRemarkVisible
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                      onClick={togglerCompleteRemark}
                    />
                  </div>
                </div>
              </div>
              {completeRemarkVisible && (
                <>
                  <div>
                    <div className="rcu-inside-container">
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="basic_card-content">
                            <div className="label_technical">Complete Remark<span className='all_required_sign'> *</span></div>
                          </div>
                          <Form.Item
                            name="completion_remark"
                            rules={[
                              {
                                required: true,
                                message: "Complete Remark is required",
                              },
                            ]}
                          >
                            <Input placeholder="Please Enter Complete Remark" onChange={(e) =>
                              handleAlphaFields(e, "completion_remark")
                            } />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* <div
            style={{
              border: "1px solid #E4E7EC",
              marginTop: "15px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{ borderRadius: "10px" }}
              className="container_technical"
            >
              <div className="valuation_details">
                <div className="valuation_details_blue">
                  Vendor Stage Details
                </div>
                <div>
                  <UpOutlined
                    style={{
                      margin: "1rem",
                      transform: vendorVisible
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                    onClick={togglerVendor}
                  />
                </div>
              </div>
            </div>
            {vendorVisible && (
              <>
                <div
                  className="technical_property_container"
                  style={{ backgroundColor: "#F1F1F1" }}
                >
                  <div className="rcu-inside-container">
                    <Row gutter={16}>
                      <Col span={4}>
                        <div className="basic_card-content">
                          <div className="label_technical">ID</div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="basic_card-content">
                          <div className="label_technical">Assigned To</div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="basic_card-content">
                          <div className="label_technical">Status</div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="basic_card-content">
                          <div className="label_technical">Initiated Date</div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="basic_card-content">
                          <div className="label_technical">Completion Date</div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="basic_card-content">
                          <div className="label_technical">Reporting Date</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="rcu-inside-container">
                  <Row gutter={16}>
                    <Col span={4}>
                      <div className="basic_card-content">
                        <div className="label_technical">1</div>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="basic_card-content">
                        <div className="label_technical">
                          Infomnir Services pvt ltd
                        </div>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="basic_card-content">
                        <div className="label_technical">NA</div>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="basic_card-content">
                        <div className="label_technical">Initiated</div>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="basic_card-content">
                        <div className="label_technical">04-04-2014</div>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="basic_card-content">
                        <div className="label_technical">Reporting Date</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div> */}
            <Form.Item>
              <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Button type="primary" htmlType="submit" style={{ marginTop: "10px" }} buttonloading={buttonloading}>
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </div>
        </div>)}
    </Form>
  );
};
export default RcuPost;
