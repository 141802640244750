import React, { useState, useEffect } from "react";
import "../styles/fi.css";

import FiTabDetails from "./FiTabDetails";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import { message } from "antd";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { getFiLoanFormData } from "../services/filloanservices";
import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";

const Fi = ({typeCheck}) => {
  const [fidata, setFiData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);

  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);

  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFiLoanFormData(stage_id);
        setFiData(response.data);
   
        setAttachments(response.data?.initiate_stage_attachment_data || []);

      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="">
        <div>
          <h3 className="FI_heading">FI</h3>
        </div>


      </div>
      <LoanSummaryBlock data={fidata} attachments={attachments} typeCheck={typeCheck} />
      <FiTabDetails loanId={loan_id} stageId={stage_id} />
    </div>
  );
};

export default Fi;
