import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getCustomersByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getIncomeCalculationsByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/cam-income-detail/income-cal-scheme-wise/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const listMonthYear = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/list-of-month-year`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listFinancialYear = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/list-of-financial-year`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const createIncomeFormCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/income-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/additional-loan-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const LTVCalculationsByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/additional-loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const updateIncomeFormByCustomerId = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/income-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const loanbycustomerId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/income-detail/customer-income-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getIncomeCustomerDataByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/income-detail/customer-wise-income-detail/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const deleteIncomeDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/income-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getRentalDetailsByIdLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/employment-detail/employment-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const deleteRentalDetailsByIdLoanId = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/employment-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
