import './App.css';
import Router from './routes/Router';
import { ConfigProvider } from "antd";
import { customTheme } from './utils/styles/themeConfig';


function App() {
  return (
    <ConfigProvider theme={customTheme}>
      
      <Router 
      
      
      />

    </ConfigProvider>
  );
}

export default App;
