import React, { useEffect } from "react";
import { message } from "antd";

import FiSwitchTab from "../FiSwitchTab";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import { getFiInputFormData } from "../../services/filloanservices";
import { useState } from "react";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import LoanSummaryBlock from "../../../../utils/loanSummaryBlock/LoanSummaryBlock";


const FiInputForm = ({ typeCheck }) => {
  const [legalInputData, setLegalInputData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
 
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFiInputFormData(stage_id);
        setLegalInputData(response.data);
        setAttachments(response.data.initiate_stage_attachment_data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
     <div className="">
        <div>
          <h3 className="FI_heading">FI</h3>
        </div>


      </div>
    <LoanSummaryBlock data={legalInputData} attachments={attachments} typeCheck={typeCheck}/>
      <FiSwitchTab loanId={loan_id} stageId={stage_id} />
    </>
  );
};

export default FiInputForm;
