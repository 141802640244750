import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import {
  listUserService,
  listVehicleManufacturerService,
  listVehicleTypeService,
} from "../services/vehicleModelservice";
const { Option } = Select;

const VehicleModelFilterForm = ({
  closeForm,
  setFilterFormData,
  toggleRefreshTableData,
  setActionPerformed,
}) => {
  const [form] = Form.useForm();
  const [allVehicleManufacturer, setAllVehicleManufacturer] = useState([]);
  const [allVehicleType, setAllVehicleType] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);

  const handleSubmit = (values) => {
    // Iterate over form values and update filterFormData
    setFilterFormData((prevState) => ({
      ...prevState,

      filter: true,
    }));
    Object.keys(values).forEach((key) => {
      setFilterFormData((prevState) => ({
        ...prevState,
        [key]: values[key],
      }));
    });
    toggleRefreshTableData();
    closeForm();
    setActionPerformed(true);
  };

  const handleReset = () => {
    form.resetFields();
    setFilterFormData({
      filter: false,
      is_active: null,
      is_verified: null,
      vehicle_manufacturer: [],
      vehicle_type: [],
      created_by: [],
      modified_by: [],
    });
    setActionPerformed(true);
    form.setFieldValue({
      filter: false,
      is_active: null,
      is_verified: null,
      vehicle_manufacturer: [],
      vehicle_type: [],
      created_by: [],
      modified_by: [],
    });
  };

  useEffect(() => {
    const getAllVehicleManufacturer = async () => {
      try {
        const response = await listVehicleManufacturerService();
        setAllVehicleManufacturer(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    const getAllVehicleType = async () => {
      try {
        const response = await listVehicleTypeService();

        setAllVehicleType(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };
    const fetchAllUsers = async () => {
      try {
        const response = await listUserService();
        setCreatedBy(response.data);
        setModifiedBy(response.data);
      } catch (error) {
        message.error("Problem fetching users!");
      }
    };

    getAllVehicleManufacturer();
    getAllVehicleType();
    fetchAllUsers();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        filter: false,
        is_active: null,
        is_verified: null,
        vehicle_manufacturer: [],
        vehicle_type: [],
        created_by: [],
        modified_by: [],
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="vehicle_manufacturer" label="Vehicle Manufacturer">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allVehicleManufacturer?.map((VehicleManufacturer) => {
                return (
                  <Select.Option
                    key={VehicleManufacturer.id}
                    value={VehicleManufacturer.id}
                  >
                    {VehicleManufacturer.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="vehicle_type" label="Vehicle Type">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allVehicleType?.map((vehicletype) => {
                return (
                  <Select.Option key={vehicletype.id} value={vehicletype.id}>
                    {vehicletype.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="created_by" label="Created By">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  ? option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {createdBy?.map((created) => {
                return (
                  <Select.Option key={created.id} value={created.id}>
                    {created.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="modified_by" label="Modified By">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  ? option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {modifiedBy?.map((modified) => {
                return (
                  <Select.Option key={modified.id} value={modified.id}>
                    {modified.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={handleReset}>
          Clear Filter
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default VehicleModelFilterForm;
