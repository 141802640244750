import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, DatePicker, Table, message, Select, Flex } from "antd";
import moment from 'moment';
import * as Yup from "yup";
import { createCreditTranstionDetails, getCustomerBankListByIdService } from '../services/monthlyExpensesService';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import BankBalance from './BankBalance';

const validationSchema = Yup.object().shape({
  bank: Yup.string().required("Bank  is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};


const Banking = ({ closeForm, refreshData, customer_id, bank_id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [allBank, setAllBank] = useState([]);
  const [fromMonthSelected, setFromMonthSelected] = useState(false);
  const { encrypted_loan_id } = useParams();
  const [activeButton, setActiveButton] = useState("creditTransaction");
  const [showBankBalance, setShowBankBalance] = useState(false)
  const [showCreditTransaction, setShowCreditTransaction] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error('Invalid Loan ID');
  }


  const handleFromMonthChange = (date) => {
    if (date) {
      setFromMonthSelected(true);
      const nextYearSameMonth = date.clone().add(1, 'year');
      form.setFieldsValue({
        toMonth: nextYearSameMonth, // Automatically set "To Month"
      });

      const currentMonth = moment();
      if (date.isAfter(currentMonth, 'month')) {
        message.error('From Month cannot be in the future.');
        return;
      }
      calculateFullYearData(date);
    }
  };

  const handleToMonthChange = (date) => {
    if (date) {

      const currentMonth = moment();
      if (date.isAfter(currentMonth, 'month')) {
        message.error('To Month cannot be in the future.');
        return;
      }

      calculateFullYearData(form.getFieldValue('fromMonth')); // Recalculate table if necessary
    }
  };

  const calculateFullYearData = (start) => {
    if (!start) return;

    const months = [];
    const currentMonth = moment(); // Get current date
    for (let month = 0; month < 12; month++) {
      const monthDate = start.clone().add(month, 'month'); // Add each month sequentially

      if (monthDate.isAfter(currentMonth, 'month')) {
        message.error('Cannot include future months.');
        return;
      }
      months.push({
        month: monthDate.format("YYYY-MM"),
        creditCalculations: '',
        creditCount: 0,
        creditAmount: 0,
        debitCalculations: '',
        debitCount: 0,
        debitAmount: 0,
        inward: '',
        outward: '',
      });
    }
    setData(months); // Update the data source for the table
  };

  useEffect(() => {
    const getAllBank = async () => {
      try {
        const response = await getCustomerBankListByIdService(customer_id);
        setAllBank(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllBank();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleCalculationsChange = (index, value, type) => {
    const newData = [...data];
    const calculations = value || '';

    if (type === 'credit') {
      const creditValues = calculations.split('+').filter(Boolean).map(Number); // Convert to numbers
      const totalCreditTxns = creditValues.length; // Count of transactions
      const creditAmount = creditValues.reduce((acc, curr) => acc + curr, 0); // Sum of the values

      newData[index].creditCount = totalCreditTxns; // Update Credit Count
      newData[index].creditAmount = creditAmount.toFixed(2); // Update Credit Amount
    } else if (type === 'debit') {
      const debitValues = calculations.split('+').filter(Boolean).map(Number); // Convert to numbers
      const totalDebitTxns = debitValues.length; // Count of transactions
      const debitAmount = debitValues.reduce((acc, curr) => acc + curr, 0); // Sum of the values

      newData[index].debitCount = totalDebitTxns; // Update Debit Count
      newData[index].debitAmount = debitAmount.toFixed(2); // Update Debit Amount
    }

    setData(newData);
  };

  const handleInwardChange = (index, value) => {
    const newData = [...data];
    newData[index].inward = value; // Update inward value
    setData(newData);
  };

  const handleOutwardChange = (index, value) => {
    const newData = [...data];
    newData[index].outward = value; // Update outward value
    setData(newData);
  };
  const handleSubmit = async (values) => {
    try {
      // Prepare the payload based on the data state
      const transactions = data.map((item, index) => ({
        loan_detail: loan_id,
        customer: customer_id,
        bank_detail: bank_id,
        year: moment(item.month).year(),
        month: moment(item.month).month() + 1, // Months are 0-indexed in moment
        total_credit_txns: item.creditCount,
        total_debit_txns: item.debitCount,
        credit_amount: parseFloat(item.creditAmount),
        debit_amount: parseFloat(item.debitAmount),
        inward_bounce_charge_txns: item.inward ? parseFloat(item.inward) : 0,
        outward_bounce_charge_txns: item.outward ? parseFloat(item.outward) : 0,
      }));

      const response = await createCreditTranstionDetails({ transactions });

      if (response.status === 200 && response.success) {
        message.success('Credit details successfully created');
        setShowBankBalance(true);
        setShowCreditTransaction(false);
        setActiveButton("bankbalance")
        // closeForm();
        refreshData();

      }
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  useEffect(() => {
    // Reset form whenever closeForm changes (e.g., modal closes and reopens)
    if (closeForm) {
      form.resetFields(); // This will reset the form whenever the modal or drawer closes
      setData([]); // Reset the data for the table when the form closes
      setShowBankBalance(false); // Reset bank balance visibility
      setShowCreditTransaction(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeForm]);


  const columns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Credit Calculations',
      dataIndex: 'creditCalculations',
      key: 'creditCalculations',
      render: (_, record, index) => (
        <Form.Item
          name={['salaryDetails', index, 'creditCalculations']}

        >
          <Input.TextArea
            value={record.creditCalculations}
            onChange={(e) => handleCalculationsChange(index, e.target.value, 'credit')}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Credit Count',
      dataIndex: 'creditCount',
      key: 'creditCount',
      render: (text, record) => (
        <span>{record.creditCount}</span>
      ),
    },
    {
      title: 'Credit Amount',
      dataIndex: 'creditAmount',
      key: 'creditAmount',
      render: (text, record) => (
        <span>{record.creditAmount}</span>
      ),
    },
    {
      title: 'Debit Calculations',
      dataIndex: 'debitCalculations',
      key: 'debitCalculations',
      render: (_, record, index) => (
        <Form.Item
          name={['salaryDetails', index, 'debitCalculations']}

        >
          <Input.TextArea
            value={record.debitCalculations}
            onChange={(e) => handleCalculationsChange(index, e.target.value, 'debit')}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Debit Count',
      dataIndex: 'debitCount',
      key: 'debitCount',
      render: (text, record) => (
        <span>{record.debitCount}</span>
      ),
    },
    {
      title: 'Debit Amount',
      dataIndex: 'debitAmount',
      key: 'debitAmount',
      render: (text, record) => (
        <span>{record.debitAmount}</span>
      ),
    },
  ];


  const outwardColumns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Inward Amount',
      dataIndex: 'inward',
      key: 'inward',
      render: (_, record, index) => (
        <Input
          value={record.inward}
          onChange={(e) => handleInwardChange(index, e.target.value)}
          placeholder="Enter inward amount"
        />
      ),
    },
    {
      title: 'Outward Amount',
      dataIndex: 'outward',
      key: 'outward',
      render: (_, record, index) => (
        <Input
          value={record.outward}
          onChange={(e) => handleOutwardChange(index, e.target.value)}
          placeholder="Enter outward amount"
        />
      ),
    },
  ];

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          fromMonth: null,
          toMonth: null,
        }}
      >
             {showCreditTransaction && (
      
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="bank"
                label="Bank"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }

                >
                  {allBank?.map((bank) => {
                    return (
                      <Select.Option key={bank.bank.id} value={bank.bank.id}>
                        {bank.bank.display_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="fromMonth"
                label="From Month"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Please select from month');
                      }
                      if (value && value.isAfter(moment(), 'month')) {
                        return Promise.reject('Cannot include future months.');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  picker="month"
                  format="YYYY-MM"
                  style={{ width: "100%" }}
                  onChange={handleFromMonthChange} // Automatically updates the "To Month"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="toMonth"
                label="To Month"
                required

                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Please select To Month');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  disabled
                  picker="month"
                  format="YYYY-MM"
                  style={{ width: "100%" }}
                  onChange={handleToMonthChange}
                />
              </Form.Item>
            </Col>
          </Row>
             )}
 


        <div style={{ margin: "0" }}>
          <Flex gap="small" wrap="wrap">
            <Button
              style={{
                color: activeButton === "creditTransaction" ? "#FFF" : "#27658F",
                borderColor: "#27658F",
              }}
              type={activeButton === "creditTransaction" ? "primary" : "default"}
              onClick={() => {
                setActiveButton("creditTransaction");
                setShowCreditTransaction(true);

                setShowBankBalance(false)
              }}
            >
              Credit Transaction Details
            </Button>
            <Button
              style={{
                color: activeButton === "bankbalance" ? "#FFF" : "#27658F",
                borderColor: "#27658F",
              }}
              type={activeButton === "bankbalance" ? "primary" : "default"}
              onClick={() => {
                setActiveButton("bankbalance");
                setShowCreditTransaction(false);

                setShowBankBalance(true)

              }}
            >
              Bank Balance
            </Button>
          </Flex>
        </div>
        {fromMonthSelected && (
          <>
            <div>

              {showCreditTransaction && (
                <>
                  <div style={{ marginTop: 20 }}>

                    <Table
                      columns={columns}
                      dataSource={data}
                      rowKey="month"
                      pagination={false}
                    />

                  </div>

                  <div style={{ marginTop: 20, marginBottom: "20px" }}>


                    <Table
                      columns={outwardColumns}
                      dataSource={data}
                      pagination={false}
                      rowKey="month"
                    />

                  </div>

                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end', marginTop: 40 }}>
                    <Button type="primary" htmlType="submit">Save & Next</Button>
                  </div>
                </>
              )}


            </div>



          </>
        )}


      </Form>
      {showBankBalance && (
        <BankBalance
          closeForm={closeForm}
          refreshData={refreshData}
          customer_id={customer_id}
          bank_id={bank_id}
        />
      )}
    </>
  );
};

export default Banking;





