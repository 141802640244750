import { Button, Table, Tooltip } from 'antd'
import React from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const AutoDeviation = ({autoDeviationData,showDrawer,setSelectedManual}) => {
    const onChange = (pagination, filters, sorter, extra) => {
    };

    
    const autoDeviation = autoDeviationData.map((item, index) => {
        return {
            key: index + 1,
            no: index + 1,
            deviation: item.deviation.name,
            actual_deviations: item.actual_description,
            mitigates: item?.mitigates, 
            actions: (
                <>
                    <Tooltip title="Edit">
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(item)}
                        />
                    </Tooltip>
                    <Tooltip title="Remove">
                        <Button
                            type="link"
                            icon={<DeleteOutlined />}
                            onClick={() => handleRemove(item)}
                        />
                    </Tooltip>
                </>
            ),
        };
    });

    const handleEdit = (item) => {
        // Add your edit logic here
        showDrawer()
        setSelectedManual(true)
    };

    const handleRemove = (item) => {
        // Add your remove logic here

    };

    const columns1 = [
        {
            title: 'SrNo.',
            dataIndex: 'no',

        },
        {
            title: 'Deviation',
            dataIndex: 'deviation',

        },
        {
            title: 'Mitigates',
            dataIndex: 'mitigants',
        }
    ];

  return (
 <>
     <div className='decision_heading'> <div className='text'>Auto Deviation </div></div>
     <div className="table_container">
     <Table className="insurance_table" columns={columns1} dataSource={autoDeviation} onChange={onChange} pagination={false} />
     </div>
 </>
  )
}

export default AutoDeviation