
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import {
    listLoanTypeService,
    listUserService
} from "../services/productServices";

const { Option } = Select;

const ProductFilterForm = ({
    closeForm,
    setFilterFormData,
    toggleRefreshTableData,
    setActionPerformed
}) => {
    const [form] = Form.useForm();
    const [createdBy, setCreatedBy] = useState([]);
    const [modifiedBy, setModifiedBy] = useState([]);
    const [allLoanTypes, setAllLoanTypes] = useState([]);
    const handleSubmit = (values) => {
        // Iterate over form values and update filterFormData
        setFilterFormData((prevState) => ({
            ...prevState,
            filter: true,
        }));
        Object.keys(values).forEach((key) => {
            setFilterFormData((prevState) => ({
                ...prevState,
                [key]: values[key],
            }));
        });
        toggleRefreshTableData()
        closeForm()
        setActionPerformed(true);
    };

    const handleReset = () => {
        form.resetFields();
        setFilterFormData({
            filter: false,
            is_active: undefined,
            is_verified: undefined,
            created_by: [],
            modified_by: [],
        });
        setActionPerformed(true);
    };
    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await listUserService();
                setCreatedBy(response.data);
                setModifiedBy(response.data);
            } catch (error) {
                message.error("Problem fetching users!");
            }
        };
        const fetchAllLoanTypes = async () => {
            try {
                const response = await listLoanTypeService();
                setAllLoanTypes(response.data);
            } catch (error) {
                message.error("Problem fetching users!");
            }
        };
        fetchAllUsers();
        fetchAllLoanTypes();
    }, []);


    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                filter: false,
                is_active: null,
                is_verified: null,
                created_by: [],
                modified_by: [],
            }}
        >
            <Row gutter={16}>
                
                <Col span={12}>
                    <Form.Item name="is_active" label="Is Active" >
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="is_verified" label="Is Verified" >
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
            <Col span={12}>
                    <Form.Item name="loan_type" label="Loan Type">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    ? option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    : false
                            }
                        >
                            {allLoanTypes?.map((loantype) => {
                                return (
                                    <Select.Option key={loantype.id} value={loantype.id}>
                                        {loantype.display_name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="created_by" label="Created By">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    ? option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    : false
                            }
                        >
                            {createdBy?.map((created) => {
                                return (
                                    <Select.Option key={created.id} value={created.id}>
                                        {created.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="modified_by" label="Modified By">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    ? option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    : false
                            }
                        >
                            {modifiedBy?.map((modified) => {
                                return (
                                    <Select.Option key={modified.id} value={modified.id}>
                                        {modified.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={handleReset}>
                    Clear Filter
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default ProductFilterForm;
