import React, { useEffect, useState } from "react";
import EditButton from "../../../../utils/editButton/EditButton";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import greenDot from "../../assets/Dot.png"; 
import redDot from "../../assets/reddot.png";
import OverallStatusOvEditForm from "./OverallStatusOvEditForm";
import { getResidenceOverallStatusByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const OverallStatusOv = ({ fiDetail, selectedCustomerId }) => {
  const [open, setOpen] = useState(false);
  const [collapsed5, setCollapsed5] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [overallStatusOvData, setOverallStatusOvData] = useState(null);

  const toggleRefreshData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };

  const onClose5 = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getResidenceOverallStatusByCustomerId(
          selectedCustomerId
        );
        console.log("line no 36", response);
        setOverallStatusOvData(response?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData,selectedCustomerId]);
  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div style={{ padding: "10px 10px 10px 10px" }}>
      <Drawer
        title={<Header title="Edit" onClose={onClose5} name="Overall Status" />}
        width={970}
        onClose={onClose5}
        open={open}
        closable={false}
      >
        <OverallStatusOvEditForm
          open={open}
          closeForm={onClose5}
          refreshData={toggleRefreshData}
          updateId={overallStatusOvData?.id}
          customerDetails={overallStatusOvData}
        />
      </Drawer>
      <div style={{border:"1px solid #e4e7ec"}}>
      <div className="fi_description_heading">
        <div className="fi_description_data">Overall Status </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {renderEditButton()}
          </div>
          <div
            style={{ margin: "10px 10px 10px 10px" }}
            onClick={toggleCollapse5}
          >
            {collapsed5 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed5 && (
        <div>
          <>
            <div className="fi_description_basic_card_container" >
              <div className="fi_description_basic_card">
                <div className="" >
                  <div className="fi_description_basic_card_label">
                    Overall Status
                  </div>
                  <div className="fi_description_basic_card_data">
                  <img
                      src={
                        overallStatusOvData?.overall_status === "Positive"
                          ? greenDot
                          : redDot
                      }
                      alt=""
                    />
                    <span
                      style={{
                        color:
                          overallStatusOvData?.overall_status === "Positive"
                            ? "#389E0D"
                            : "#FF4D4F",
                        marginLeft: "5px",
                      }}
                    >
                      {overallStatusOvData?.overall_status || "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="fi_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                  Neighbour Check
                  </div>
                  <div className="fi_description_basic_card_data">
                    {overallStatusOvData?.neighbor_check || "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
      </div>
    </div>
  );
};

export default OverallStatusOv;
