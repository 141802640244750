import {
  Button,
  Col,
  Form,
  Select,
  Row,
  Upload,
  Space,
  message,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createLegalUploadDocumentDetailService,
  deleteLegalUploadDocumentDetailServiceById,
  listLegalIploadDocumentTypeService,
} from "../services/documentDetailService";
import "../styles/documentDetails.css";
import pdfIcon from "../assets/pdf.png";
import RViewerJS from "viewerjs-react";
const { Option } = Select;

const PropertyPicturesForm = ({
  legal_detail,
  closeForm,
  refreshData,
  open,
  propertyPictures,
  setSelectedPropertyPicture,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [documentName, setDocumentName] = useState("");
  const [pdfIconUrl] = useState(null);

  const fetchLegalDocumentType = async () => {
    try {
      const response = await listLegalIploadDocumentTypeService();
      setLegalDocumentType(response.data.choices);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    if (open) {
      fetchLegalDocumentType();
    } else {
      form.resetFields(["upload_documents"]);
      setFileList([]);
      setImage([]);
      setUploadDisabled(true);
    }
  }, [open, form]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async () => {
    try {
      const legalDetailValue = legal_detail;
      const uploadDocuments = await Promise.all(
        fileList.map(async (file) => ({
          [documentName]: {
            file_path: await fileToBase64(file.originFileObj),
            document_type: documentName,
          },
        }))
      );

      const payload = {
        legal_detail: legalDetailValue,
        upload_documents: uploadDocuments,
      };

      const response = await createLegalUploadDocumentDetailService(payload);
      if (response.status === 200 && response.success) {
        message.success("Property Pictures successfully uploaded");
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(
        error?.response?.data?.message || "Failed to upload property pictures"
      );
    }
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteLegalUploadDocumentDetailServiceById(
        deletePicture.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Property Picture deleted successfully");
        setOpenModal(false);
        setSelectedPropertyPicture(null);
        refreshData();
      }
    } catch (error) {
      message?.error(error?.response?.data?.message);
    }
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const newImages = newFileList.map((file) => {
      const isPdf = file.type === "application/pdf";
      return {
        file_path: isPdf
          ? pdfIconUrl
          : URL.createObjectURL(file.originFileObj || file),
        isPdf,
        uid: file.uid,
      };
    });
    setImage(newImages);
    setUploadDisabled(true);
    form.resetFields(["upload_documents"]);
  };

  const handleRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedImages = updatedFileList.map((file) => ({
      file_path:
        file.type === "application/pdf"
          ? pdfIconUrl
          : URL.createObjectURL(file.originFileObj || file),
      isPdf: file.type === "application/pdf",
      uid: file.uid,
    }));
    setImage(updatedImages);
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    setFileList(fileList.filter((file) => file.uid !== deleteUploadPicture.uid));
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }
    const newImage = {
      file_path:
        file.type === "application/pdf"
          ? pdfIconUrl
          : URL.createObjectURL(file),
      isPdf: file.type === "application/pdf",
      uid: file.uid,
    };
    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false;
  };

  const handleDocumentNameChange = (value) => {
    setDocumentName(value);
    setUploadDisabled(!value);
  };

  return (
    <Form
      layout="vertical"
      style={{ margin: "10px" }}
      onFinish={handleSubmit}
      form={form}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="upload_documents"
            label="Enter Document Name"
            required
          >
            <Select
              placeholder="Please select"
              allowClear
              style={{ flex: 1, marginRight: "8px", width: "100%" }}
              showSearch
              onChange={handleDocumentNameChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {legalDocumentType?.map((legaldocumenttype) => (
                <Option
                  key={legaldocumenttype.value}
                  value={legaldocumenttype.value}
                >
                  {legaldocumenttype.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="file_path" label=" ">
            <Upload
              multiple
              fileList={fileList}
              onChange={handleUploadChange}
              onRemove={handleRemove}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              disabled={uploadDisabled}
            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                disabled={uploadDisabled}
              >
                Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ margin: "10px 10px 10px 10px" }}>
        <RViewerJS>
          <div
            style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}
          >
            {image?.map((picture, index) => (
              <div key={index} style={{ margin: "10px", position: "relative" }}>
                {picture.isPdf ? (
                  <img
                    src={pdfIcon}
                    alt="PDF Icon"
                    style={{ width: "100px", height: "50px" }}
                  />
                ) : (
                  <img
                    src={picture.file_path}
                    alt=""
                    style={{ width: "100px", height: "50%" }}
                  />
                )}
                <div
                  style={{
                    top: 0,
                    right: 0,
                    color: "#E5233DB2",
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    setDeleteUploadPicture(picture);
                    setOpenUploadModal(true);
                  }}
                >
                  <DeleteOutlined />
                </div>
              </div>
            ))}
          </div>
        </RViewerJS>

        <RViewerJS>
        <div className="image-gallery" style={{cursor: "pointer"}}>
          {propertyPictures?.map((picture, index) => (
            <div key={index} className="image-container">
              {picture.file_type === "application/pdf" ? (
                <img src={pdfIcon} alt="" className="image" />
              ) : (
                <img src={picture.file_path} alt="" className="image" />
              )}
  
              <h4>{picture.name}</h4>
              <div
                className="delete-icon"
                onClick={() => {
                  setDeletePicture(picture);
                  setOpenModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
        </RViewerJS>
      </div>

      <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openModal}
          onOk={deletePropertyPictures}
          onCancel={() => {
            setOpenModal(false);
            setDeletePicture(null);
          }}
          okType="danger"
        ></Modal>
        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openUploadModal}
          onOk={confirmDelete}
          onCancel={() => {
            setOpenUploadModal(false);
            setDeleteUploadPicture(null);
          }}
          okType="danger"
        ></Modal>
  

      <Space
          direction="horizontal"
          align="center"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
    </Form>
  );
};

export default PropertyPicturesForm;
