import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getCreditAssessmentData = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/initiate-stage/get-credit-assessment-data-for-technical-input-form/${loan_id}`,);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  } 