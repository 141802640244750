import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  Select,
  Upload,
  Modal,
} from "antd";
import * as Yup from "yup";
import {
  createNachAccountService,
  getBankAccountByIdService,
  getNachStatusChoice,
  listAgencyTypeService,
  listModetypeService,
  updateNachAccountService,
} from "../services/nachDetailService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import pdf_image from "../assets/pdf.png";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";

const validationSchema = Yup.object().shape({
  bank: Yup.string().required("Bank name is required"),
  bank_branch: Yup.string().required("Branch name is required"),
  account_holder_name: Yup.string().required("Holder name is required"),
  account_type: Yup.string().required("Account type is required"),
  account_number: Yup.string().required("Account number is required"),
  ifsc_code: Yup.string().required("IFSC is required"),
  micr_code: Yup.string().required("MICR is required"),
  emi: Yup.string().required("Email is required"),
  status: Yup.string().required("Status is required"),
  vendor: Yup.string().required("Vendor is required"),
  mode: Yup.string().required("Mode is required"),
  mobile_number: Yup.string().required("Mobile number is required"),
  issue_with_unregistred_cases: Yup.string().required(
    "Issue With Unregistred Cases is required"
  ),
  action_required_from_branch: Yup.string().required(
    "Action Required From Branch is required"
  ),
  document: Yup.string().required("Upload file is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const NachForm = ({
  closeForm,
  nachId,
  refreshData,
  filterNachId,
  filterNotChecked,
  show,
  open,
}) => {
  console.log("66",nachId)
  const [nachStatusChoice, setNachStatusChoice] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [mode, setMode] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];

    // Assuming 'updatedFileList' is your array and 'form' is your form reference
    updatedFileList = updatedFileList.map((file, index) => {
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          documents: file.originFileObj,
        };
      }
      return file;
    });
    setFileList(updatedFileList);
    form.setFieldsValue({
      documents: undefined,
    });
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  useEffect(() => {
    const getBankAccountData = async (id) => {
      if (id) {
        try {
          const response = await getBankAccountByIdService(id);
          const {
            account_holder_name,
            bank,
            account_number,
            bank_branch,
            account_type,
            ifsc_code,
            micr_code,
          } = response?.data;
          form.setFieldsValue({
            account_holder_name,
            bank: bank.display_name,
            account_number,
            bank_branch: bank_branch.display_name,
            account_type,
            ifsc_code,
            micr_code,
          });
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      } else {
        setExistingImages(filterNotChecked?.document);
        form.setFieldsValue({
          account_holder_name: filterNotChecked?.holder_name,
          bank: filterNotChecked?.bank_name,
          account_number: filterNotChecked?.account_number,
          bank_branch: filterNotChecked?.branch_name,
          account_type: filterNotChecked?.account_type,
          ifsc_code: filterNotChecked?.ifsc_number,
          micr_code: filterNotChecked?.micr_number,
          emi: filterNotChecked?.emi,
          status: filterNotChecked?.status,
          vendor: filterNotChecked?.vendor.id,
          mode: filterNotChecked?.mode,
          mobile_number: filterNotChecked?.mobile_number,
          issue_with_unregistred_cases:
            filterNotChecked?.issue_with_unregistred_cases,
          action_required_from_branch:
            filterNotChecked?.action_required_from_branch,
          // document: filterNotChecked.document,
        });
      }
    };
    getBankAccountData(nachId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nachId, filterNachId, show, refreshData]);

  useEffect(() => {
    if (!open && !filterNachId) {
      form.resetFields();
      setImage([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getNachStatusChoice, setNachStatusChoice);
  }, []);

  useEffect(() => {
    const getAllVendors = async () => {
      try {
        const response = await listAgencyTypeService();
        setVendor(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };
    const getAllMode = async () => {
      try {
        const response = await listModetypeService();
        setMode(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllVendors();
    getAllMode();
  }, [form]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (values, file) => {
    let nachDocumentDetail, newdoc;
    try {
      // Ensure file and originFileObj are defined before processing
      if (file && file.originFileObj) {
        nachDocumentDetail = await Promise.all(
          await fileToBase64(file.originFileObj)
        );
      }

      if (values?.document?.file) {
        newdoc = await fileToBase64(values.document.file);
      }
      const payload = {
        ...values,
        document: filterNachId ? nachDocumentDetail : newdoc,
      };

      let response;
      if (filterNachId) {
        response = await updateNachAccountService(filterNachId, payload);
      } else {
        response = await createNachAccountService(payload, nachId);
      }

      if (response?.status === 200 && response?.success) {
        message.success(
          filterNachId
            ? "Nach person bank details successfully updated"
            : "Nach person bank details successfully uploaded"
        );
        closeForm();
        refreshData();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Failed to upload NACH account details"
      );
    }
  };

  // if (!filterNotChecked) {
  //   return;
  // }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        bank_name: "",
        branch: "",
        holder_name: "",
        account_type: "",
        account_number: "",
        ifsc_code: "",
        micr: "",
        emi: "",
        status: null,
        // vendor: null,
        mode: null,
        mobile_number: "",
        document: "",
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item name="bank" label="Bank Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Bank Name" disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="bank_branch"
            label="Branch Name"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Branch Name" disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="account_holder_name"
            label="Holder Name"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Holder Name" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="account_type"
            label="Account Type"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Account Type" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="account_number"
            label="Account Number"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Account Number" disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="ifsc_code"
            label="IFSC Code"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter IFSC Code" disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="micr_code"
            label="MICR Code"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter MICR Code" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="emi"
            label="Emi (3 Month)"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Emi" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="status" label="Status " rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {nachStatusChoice?.map((status) => (
                <Option value={status}>{status}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item name="vendor" label="Vendor" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Enter Vendor"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendor?.map((item) => (
                <Option value={item.id}>{item.display_name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="mode" label="Mode" rules={[yupSync]} required>
            <Select
              placeholder="Please Select Mode"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {mode?.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="mobile_number"
            label="Mobile Number"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Mobile Number" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="issue_with_unregistred_cases"
            label="Issue With Unregistred Cases"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Issue With Unregistred Cases" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="action_required_from_branch"
            label="Action Required From Branch"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Action Required From Branch" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={24}>
          <Form.Item name="document" label="Upload Documents">
            <Upload
              single
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              disabled={
                (image && image.length > 0) ||
                (existingImages && existingImages.length > 0)
              }
            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                style={{ width: "176%" }}
                disabled={
                  (image && image.length > 0) ||
                  (existingImages && existingImages.length > 0)
                }
              >
                Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ margin: "10px 10px 10px 10px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {image?.map((picture, index) => (
            <div
              key={index}
              style={{ marginRight: "15px", position: "relative" }}
            >
              <img
                src={picture.file_path}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
              <div
                style={{
                  // marginLeft: "-19px",
                  color: "#E5233DB2",
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "10px",
                }}
                onClick={() => {
                  setDeleteUploadPicture(picture);
                  setOpenUploadModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="image-gallery">
        {filterNachId && existingImages && (
          <div className="image-gallery">
            <RViewerJS>
              <img
                src={existingImages}
                alt="Preview"
                style={{ width: "100px", height: "100px", cursor: "pointer" }}
              />
            </RViewerJS>
          </div>
        )}
      </div>
      <Modal
        title="Confirm Delete"
        open={openUploadModal}
        onOk={confirmDelete}
        onCancel={() => {
          setOpenUploadModal(false);
          setDeleteUploadPicture(null);
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this upload image?`}</p>
      </Modal>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default NachForm;
