import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import { getUserList, getDepartmentListService, getAllDesignationService, getLocationListService, getZoneListService, getAllRoleService, listPermissionsService } from "../services/userService"

const UserFilterForm = ({   
    closeForm,
    setFilterFormData,
    toggleRefreshTableData}) => {

    const [form] = Form.useForm();
    const [department, setDepartment] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [location, setLocation] = useState([]);
    const [zone, setZone] = useState([]);
    const [role, setRole] = useState([]);
    const [permission, setPermission] = useState([])
    const [user, setUser] = useState([])

   

    const handleSubmit = (values) => {
        // Ensure roles and permissions are always arrays
        values.role = values.role || [];
        values.permission = values.permission || [];
        values.modified_by = values.modified_by || [];
        values.created_by = values.created_by || [];
    
        // Create a new object to hold only non-null and non-empty array values
        const nonEmptyValues = {};
        Object.keys(values).forEach((key) => {
            if (values[key] !== null && !(Array.isArray(values[key]) && values[key].length === 0)) {
                nonEmptyValues[key] = values[key];
            }
        });
    
    

        // Update filterFormData with non-empty values
        setFilterFormData((prevState) => ({
          
            filter: true,
            ...nonEmptyValues,
        }));
    
        toggleRefreshTableData();
        closeForm();
    };
    
    
    const handleReset = () => {
        form.resetFields();
        setFilterFormData({
            filter: false,
            is_active: null,
            is_verified: null,
            department: [],
            designation: [],
            location: [],
            zone: [],
            role: [],
            permission: [],
            created_by: [],
            modified_by: [],
            is_admin: null
        });
        toggleRefreshTableData();
        form.setFieldsValue({
            filter: false,
            is_active: null,
            is_verified: null,
            department: [],
            designation: [],
            location: [],
            zone: [],
            roles: [],
            permissions: [],
            created_by: [],
            modified_by: [],
            is_admin: null
        });
    };

    useEffect(() => {
        const getDepartmentList = async () => {
            try {
                const response = await getDepartmentListService();
                setDepartment(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        getDepartmentList();
    }, []);

    useEffect(() => {
        const getDesignationList = async () => {
            try {
                const response = await getAllDesignationService();
                setDesignation(response?.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        getDesignationList();
    }, []);

    useEffect(() => {
        const getLocationList = async () => {
            try {
                const response = await getLocationListService();
                setLocation(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        getLocationList();
    }, []);

    useEffect(() => {
        const getZoneList = async () => {
            try {
                const response = await getZoneListService();
                setZone(response.data);
            } catch (error) {
                message.error(error.response.data.message);
            }
        };
        getZoneList();
    }, []);

    useEffect(() => {
        const getRoleList = async () => {
            try {
                const response = await getAllRoleService();
                setRole(response?.data);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        };
        getRoleList();
    }, []);

    useEffect(() => {
        const getPermissionsList = async () => {
            try {
                const response = await listPermissionsService();
                setPermission(response?.data);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        };
        getPermissionsList();
        
    }, []);

    useEffect(() => {
        const getUsersList = async () => {
            try {
                const response = await getUserList();
                setUser(response?.data);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        };
        getUsersList();
        
    }, []);


    

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                filter: false,
                is_active: null,
                is_verified: null,
                department: [],
                designation: [],
                location: [],
                zone: [],
                role: [],
                permission: [],
                created_by: [],
                modified_by: [],
                is_admin: null
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="department" label="Department">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {department?.map((departments) => (
                                <Select.Option key={departments.id} value={departments.id}>
                                    {departments.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="designation" label="Designation">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {designation?.map((designations) => (
                                <Select.Option key={designations.id} value={designations.id}>
                                    {designations.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="location" label="Location">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {location?.map((locations) => (
                                <Select.Option key={locations.id} value={locations.id}>
                                    {locations.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="zone" label="Zone">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {zone?.map((zones) => (
                                <Select.Option key={zones.id} value={zones.id}>
                                    {zones.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="roles" label="Role">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {role?.map((roles) => (
                                <Select.Option key={roles.id} value={roles.id}>
                                    {roles.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="permissions" label="Permissions">
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {permission?.map((permissions) => (
                                <Select.Option key={permissions.id} value={permissions.id}>
                                    {permissions.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="is_active" label="Is Active">
                        <Select placeholder="Please select" allowClear>
                            <Select.Option key={true} value={true}>
                                True
                            </Select.Option>
                            <Select.Option key={false} value={false}>
                                False
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="is_verified" label="Is Verified">
                        <Select placeholder="Please select" allowClear>
                            <Select.Option key={true} value={true}>
                                True
                            </Select.Option>
                            <Select.Option key={false} value={false}>
                                False
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="created_by" label="Created By"> 
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {user?.map((users) => (
                                <Select.Option key={users.id} value={users.id}>
                                    {users.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="modified_by" label="Modified By"> 
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {user?.map((users) => (
                                <Select.Option key={users.id} value={users.id}>
                                    {users.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={handleReset}>
                    Clear Filter
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    )
}

export default UserFilterForm
