import React, { useState, useEffect } from 'react';
import { Select, Table } from 'antd';

const { Option } = Select;

const MagmaHDIInsurance = ({ planningListData, setCount,insurance_id, setInsurancePlanMagma, formValues, setFormValues, planDetails }) => {
  const [customerPlan, setCustomerPlan] = useState([]);
  const [convalescenceBenefit, setConvalesenceBenefit] = useState({});

  useEffect(() => {
    // Initialize customerPlan with planDetails if available
    if (planDetails) {
      setCustomerPlan(planDetails);
      setConvalesenceBenefit(planDetails);
    }
  }, [planDetails]);

  const handleChange = (value) => {
    const getPlan = planningListData?.find((val) => val.id === value);

    setInsurancePlanMagma(getPlan?.id);
    setConvalesenceBenefit(getPlan);

    const countCustomerUnderPlan = getPlan?.no_of_adults + getPlan?.no_of_children;

    setCount(countCustomerUnderPlan);
    setCustomerPlan(getPlan);

    let newFormValues = Array.isArray(formValues) ? [...formValues] : [formValues];

    if (newFormValues.length < countCustomerUnderPlan) {
      while (newFormValues.length < countCustomerUnderPlan) {
        newFormValues.push(null);
      }
    } else if (newFormValues.length > countCustomerUnderPlan) {
      newFormValues = newFormValues.slice(0, countCustomerUnderPlan);
    }

    setFormValues(newFormValues);
  };

  const columns = [
    { title: 'Plan Name', dataIndex: 'name', key: 'name' },
    { title: 'Sum Insured', dataIndex: 'sum_insured', key: 'sum_insured' },
    { title: 'No of Adults', dataIndex: 'no_of_adults', key: 'no_of_adults' },
    { title: 'No of Children', dataIndex: 'no_of_children', key: 'no_of_children' },
    { title: '18 to 45 Years Coverage', dataIndex: 'yrs_18_to_45', key: 'yrs_18_to_45' },
    { title: '46 to 65 Years Coverage', dataIndex: 'yrs_46_to_65', key: 'yrs_46_to_65' },
  ];

  return (
    <div className="">
      <div className="details_coverage_hdfc">Detail of Magma HDI Insurance</div>
      {!insurance_id && <div style={{ padding: "20px" }}>
        <p>Select Plan:</p>
        <Select
          id="insurance-plan"
          style={{ width: 200 }}
          onChange={handleChange}
          placeholder="Select a plan"
          allowClear
          disabled={!!planDetails}
        >
          {planningListData?.map((plan) => (
            <Option key={plan.id} value={plan.id}>
              {plan.name} - {plan.sum_insured}
            </Option>
          ))}
        </Select>
      </div>}
      <div style={{ padding: "20px" }}>
        <p>Your insured Customer Plan:</p>
      </div>
      {customerPlan && (
        <Table
          columns={columns}
          dataSource={[customerPlan]}
          pagination={false}
          rowKey="id"
        />
      )}
      <div style={{ padding: "20px", marginTop: '10px' }}>
        <p>Convalescence Benefit</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '35px', backgroundColor: '#F1F1F1' }}>
          <div>Convalescence Benefit</div>
          <div>Amount in Rs. Exclusive of GST</div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '50px' }}>
        <div>{convalescenceBenefit?.convalescence_benifits}</div>
        <div>{convalescenceBenefit?.amount_excl_gst}</div>
      </div>
    </div>
  );
};

export default MagmaHDIInsurance;
