import React from 'react';
import '../styles/decisionDetails.css'
import { Table } from "antd";
import edit from '../assets/pencil-line.png';
import { GoDotFill } from "react-icons/go";
import { UpOutlined } from '@ant-design/icons';

const DecisionDetails = () => {
    

    const onChange = (pagination, filters, sorter, extra) => {
    };



    const columns1 = [
        {
            title: 'SrNo.',
            dataIndex: 'no',

        },
        {
            title: 'Deviation',
            dataIndex: 'deviation',

        },
        {
            title: 'Mitigants',
            dataIndex: 'mitigants',
        }
    ];

    const data1 = [
        {
            key: '1',
            no: '1',
            deviation: 'Development of the vicinity is less than norms - Approval at L3 required',
            mitigants: 'Since the property is located in Gram panchayat where the surrounding area is in Under development stage however, basic amenities like Road, Water and Electricity are observed and the property is in Good locality.',

        },
    ];



    const columns2 = [
        {
            title: 'SrNo.',
            dataIndex: 'no',

        },
        {
            title: 'Deviation',
            dataIndex: 'deviation',

        },
        {
            title: 'Actual Deviations',
            dataIndex: 'actual_deviations',
        },
        {
            title: 'Mitigates',
            dataIndex: 'mitigates',
        },
        {
            title: 'Remove',
            dataIndex: 'remove',
        }
    ];

    const data2 = [
        {
            key: '1',
            no: '1',
            deviation: 'Blood relatives & sharing same kitchen not joining loan structure (Microhousing) (L1)',
            actual_deviations: 'Applicant blood relatives & sharing same kitchen not joining loan structure',
            mitigates: 'Applicant Ashiya Begum blood relatives & sharing same kitchen not joining loan structure ',
            remove: '',
        },

        {
            key: '2',
            no: '2',
            deviation: 'Less than 2 income generating streams in the family (Microhousing) (L2)',
            actual_deviations: 'Only single earner income Consider As co-applicant',
            mitigates: 'This case only one single income earnr he is owne bussiness he is gatteing income as per month 25000,RS.',
            remove: '',
        },

        {
            key: '3',
            no: '3',
            deviation: 'Deviation/Waiver of BM Personal Discussion (Microhousing) (L3)',
            actual_deviations: 'All Pd Done By BCM',
            mitigates: 'All Pd Done By BCM',
            remove: '',
        },

        {
            key: '4',
            no: '4',
            deviation: 'CIBIL no match (NTC score from -1 to 200) (Microhousing) (L1)',
            actual_deviations: 'applicant & Co-applicant cibil no match',
            mitigates: 'Applicant &co-applicant has not availed any loan prior to this%2F PAN card is old%2F%0D%0AFirst time borrower.',
            remove: '',
        },

    ];


    const columns3 = [
        {
            title: 'ID',
            dataIndex: 'id',

        },
        {
            title: 'Status',
            dataIndex: 'status',

        },
        {
            title: 'Remark',
            dataIndex: 'remark',
        },

        {
            title: 'Done By',
            dataIndex: 'done_By',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    const data3 = [
        {
            key: '1',
            id: '1',
            status: <div className="disbursed-container_red">
                <GoDotFill style={{ width: "16px", padding: "3px 5px 0px 0px" }} />
                <span className='red'>
                    Forward
                </span>
            </div>,
            remark: 'Since the property is located in Gram panchayat where the surrounding area is in Under development stage however, basic amenities like Road, Water and Electricity are observed and the property is in Good locality.',
            done_By: 'Srinivasarao Avula',
            date: '27-Dec-2023 06:09:28 pm',

        },

        {
            key: '2',
            id: '2',
            status: <div className="disbursed-container_red ">
                <GoDotFill style={{ width: "16px", padding: "3px 5px 0px 0px" }} />
                <span className='Success'>
                    Success
                </span>
            </div>,
            remark: ' OK',
            done_By: 'Murali Dara',
            date: '27-Dec-2023 06:09:28 pm',

        },
    ];



    const columns4 = [
        {
            title: 'SrNo.',
            dataIndex: 'no',

        },
        {
            title: 'Property',
            dataIndex: 'property',

        },
        {
            title: 'Text',
            dataIndex: 'text',
        },

        {
            title: 'Document',
            dataIndex: 'document',
        },

        {
            title: 'Written By',
            dataIndex: 'written_by',
        },


        {
            title: 'Stage',
            dataIndex: 'stage',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    const data4 = [
        {
            key: '1',
            no: '1',
            property: 'Question',
            text: 'this is not considerable doc',
            document: 'NA',
            written_by: 'Ramesh Krishnamoorthy',
            stage: 'Technical',
            date: '03-Jan-2024 08:19:02 PM',
        },

        {
            key: '1',
            no: '',
            property: 'Answer',
            text: 'ok',
            document: 'Jebaraj Dharmaraj',
            written_by: 'Jebaraj Dharmaraj',
            stage: 'Technical',
            date: '04-Jan-2024 11:37:09 AM',
        },
    ];



    return (
        <>
            <div className='main_basic_container'>
            <div className='valuation_container'>

                <div className='valuation_details'>
                    <div className='valuation_details_blue'>Deviation Details</div>
                    <div >
                        <div className='basic_edit_container valuation_edit_button'>
                            <button className="tab_form_sub_edit edit_button valuation_edit ">
                                <img src={edit} alt="Pencil Icon" />
                                <span>Edit</span></button>
                            <UpOutlined style={{ margin: "1rem" }} />
                        </div>
                    </div>
                </div>
                <div className='decision_heading'> <div className='text'>Auto Deviation </div></div>
                <Table className="insurance_table" columns={columns1} dataSource={data1} onChange={onChange} pagination={false} />



                <div className='decision_heading'> <div className='text'>Manual Deviation</div></div>
                <Table className="insurance_table" columns={columns2} dataSource={data2} onChange={onChange} pagination={false} />
            </div>

            <div className='valuation_container'>
                <div className='decision_heading'> <div className='text'>Technical Decision</div></div>
                <Table className="insurance_table" columns={columns3} dataSource={data3} onChange={onChange} pagination={false} />
            </div>


            <div className='valuation_container'>
                <div className='decision_heading'> <div className='text'>Decision Question</div></div>

                <Table className="insurance_table" columns={columns4} dataSource={data4} onChange={onChange} pagination={false}

                />
            </div>
            </div>
        </>
    );
}

export default DecisionDetails;
