const ConditionalRender = ({
  userPermissions,
  requiredPermissions,
  children,
}) => {
  const hasPermission = userPermissions?.some((permission) =>
  requiredPermissions.includes(permission?.code) || permission?.code === 'MPADMIN1'
);
  return hasPermission ? children : null;
};

export default ConditionalRender;
