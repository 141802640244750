
import { axiosRequest } from "../../../../../utils/api/axiosRequest";



export const getDocumentDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/technical-document-collection-detail/technical-document-collection-and-upload-document/${loan_id}`);

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateDocumentDetailsByIdService = (technical_id,data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/technical-document-collection-detail/update-tech-document-by-tech-id/${technical_id}`,data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getRemarkDataByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/property-technical-data/technical-data/${loan_id}`);

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateRemarkDataByIdService = (technical_id,data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/property-technical-data/${technical_id}`,data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const deleteDocumentDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/technical-document-upload/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const listLegalDocumentTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/technical-upload-document-type-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listOverallStatusTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/overall-status-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};





export const createUploadImagesModelService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/technical-document-upload/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
