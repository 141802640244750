import React, { useState } from "react";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import ConstructionLine from '../../construction_timeline/views/ConstructionLine';
import DpdString from '../../dpdString/views/DpdString';
import ChargesReceivable from '../../chargesReceivable/views/ChargesReceivable';
import PartDisbursalDetails from '../../partdisbursalDetails/views/PartDisbursalDetails';
import BeneficiaryDetails from '../../beneficiaryDetails/views/BeneficiaryDetails';
import PartDisbursalHistory from '../../partDisbursalHistory/views/PartDisbursalHistory';

import "../../../loanApplication/customerTabDetails/customerDetails/styles/customerDetails.css"
import "../styles/partDisbursedTable.css";
import PaymentDetails from "../../paymentDetails/views/PaymentDetails";
import ApprovalDetails from "../../approvalDetails/views/ApprovalDetails";




const PartDisbursedTable = () => {
    const [showCustomerDetails, setShowCustomerDetails] = useState(true);

    const toggleCustomerDetails = () => {
        setShowCustomerDetails((prev) => !prev);
    };


    return (
        <div className="part_disbursed_main_container" >
            <div
                className="part_disbursed_main_heading"
                style={{ borderBottom: !showCustomerDetails && "none" }}
            >
                <div>
                    <h2 className="heading_loan_details">Part Disbursed</h2>
                </div>
                <div className="customer_icon">
                    {showCustomerDetails ? (
                        <AiOutlineUp
                            style={{ cursor: "pointer" }}
                            onClick={toggleCustomerDetails}
                        />
                    ) : (
                        <AiOutlineDown
                            style={{ cursor: "pointer" }}
                            onClick={toggleCustomerDetails}
                        />
                    )}
                </div>
            </div>
            <div style={{ display: showCustomerDetails ? "block" : "none" }}>

                <ConstructionLine />
                <DpdString />
                <PaymentDetails/>
                <ChargesReceivable />
                <PartDisbursalDetails />
                <BeneficiaryDetails />
                <PartDisbursalHistory />
                <ApprovalDetails/>
            </div>
        </div>
    );
};

export default PartDisbursedTable;
