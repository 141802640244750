import React from 'react';
import '../../documentDetails/styles/documentstyle.css'
import edit from '../assets/pencil-line.png'
import { Badge, Table } from 'antd';
import '../../trail/styles/trail.css'

const columns = [
  {
      title: 'ID',
      dataIndex: 'id',      
  },
  {
      title: 'Assigned To',
      dataIndex: 'Assigned_to',
      
  },
  {
      title: 'Forwarded To',
      dataIndex: 'Forwarded_To',
  },


  {
    title: 'Status',
    dataIndex: 'Initiated_Date',
    render: (_, record) => {
      let badgeColor = '';
      switch(record.status) {
        case 'Initiated':
          badgeColor = "#2E90FA";
          break;
        case 'Success':
          badgeColor = '#389E0D'; // Green
          break;
        case 'Re-Initiated':
          badgeColor = '#2E90FA'; // Blue
          break;
          case 'Vendor-Completed':
            badgeColor = '#5925DC';
            break;
          case 'Forward':
            badgeColor = '#C4320A'; // Blue
            break;
        default:
          badgeColor = '#000000'; // Default color (Black)
      }
      return (
        <Badge
          text={record.status}
          color={badgeColor}
          style={{ fontSize: "16px", color: badgeColor }}
        />
      );
    }
  },
  

  {
    title: 'Initiated Date',
    dataIndex: 'Initiated_Date',
},
 
  {
      title: 'Completion Date',
      dataIndex: 'Completion_Date',
  },


];
const data = [
  {
      key: '1',
      id: '1',
      Assigned_to: 'SMR ENGINEERS AND VALUERS',
      Forwarded_To: "Kairam Narendhar",
      status: "Initiated",
      Initiated_Date: "27-Dec-2023 06:09:28 pm",
      Completion_Date: "27-Dec-2023 06:16:04 pm",
      
  },
  {
    key: '2',
    id: '2',
    Assigned_to: 'SMR ENGINEERS AND VALUERS',
    Forwarded_To: "Kairam Narendhar",
    status: "Success",
    Initiated_Date: "27-Dec-2023 06:09:28 pm",
    Completion_Date: "27-Dec-2023 06:16:04 pm",
    
},
{
  key: '3',
  id: '3',
  Assigned_to: 'SMR ENGINEERS AND VALUERS',
  Forwarded_To: "Kairam Narendhar",
  status: "Re-Initiated",
  Initiated_Date: "27-Dec-2023 06:09:28 pm",
  Completion_Date: "27-Dec-2023 06:16:04 pm",
  
},
{
  key: '4',
  id: '4',
  Assigned_to: 'SMR ENGINEERS AND VALUERS',
  Forwarded_To: "Kairam Narendhar",
  status: "Vendor-Completed",
  Initiated_Date: "27-Dec-2023 06:09:28 pm",
  Completion_Date: "27-Dec-2023 06:16:04 pm",
  
},
{
  key: '4',
  id: '4',
  Assigned_to: 'SMR ENGINEERS AND VALUERS',
  Forwarded_To: "Kairam Narendhar",
  status: "Forward",
  Initiated_Date: "27-Dec-2023 06:09:28 pm",
  Completion_Date: "-",
  
},



]

const onChange = (pagination, filters, sorter, extra) => {
};

  

const Trail = () => {
  return (
    <>
     <div className='main_basic_container'>
      <div className='valuation_container'>
        <div className='document_details'>
          <div className='document_details_blue'>Vendor Stage Details</div>

          <div>

            <div className='basic_edit_container basic_button'>
              <button className="tab_form_sub_edit edit_button documnet_edit basic ">
                <img src={edit} alt="Pencil Icon" />
                <span>Edit</span></button>

            </div>

          </div>



        </div>


                    <Table className="insurance_table" columns={columns} dataSource={data} onChange={onChange} pagination={false} />



      </div>
      </div>
    </>
  );
}

export default Trail;
