import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import { getZoneListService ,listUserService} from "../services/locationsService";


const { Option } = Select;

const LocationsFilterForm = ({
  closeForm,
  setFilterFormData,
  toggleRefreshTableData,
  setActionPerformed
}) => {
  const [form] = Form.useForm();
  const [allZone, setAllZone] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);

  const handleSubmit = (values) => {
    // Iterate over form values and update filterFormZone
    setFilterFormData((prevState) => ({
      ...prevState,
      filter: true,
    }));
    Object.keys(values).forEach((key) => {
      setFilterFormData((prevState) => ({
        ...prevState,
        [key]: values[key],
      }));
    });
    toggleRefreshTableData();
    closeForm();
    setActionPerformed(true);

  };

  const handleReset = () => {
    form.resetFields();
    setFilterFormData({
      filter: false,
      is_active: null,
      is_verified: null,
      zone: [],
      created: [],
      modified: [],
    });
    setActionPerformed(true);
    form.setFieldValue({
      filter: false,
      is_active: null,
      is_verified: null,
      zone: [],
      created: [],
      modified: [],
    });
  };

  useEffect(() => {
    const fetchAllState = async () => {
      try {
        const response = await getZoneListService();
        setAllZone(response.data);
      } catch (error) {
        message.error("Problem fetching states!");
      }
    };
    const fetchAllUsers = async () => {
      try {
        const response = await listUserService();
        setCreatedBy(response.data);
        setModifiedBy(response.data);
      } catch (error) {
        message.error("Problem fetching users!");
      }
    };
    fetchAllUsers();
    fetchAllState();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        filter: false,
        is_active: null,
        is_verified: null,
        zone: [],
        created: [],
        modified: [],
      }}
    >
     
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="zone" label="Zone">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allZone?.map((states) => {
                return (
                  <Select.Option key={states.id} value={states.id}>
                    {states.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>     
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified">
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="created" label="Created By">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {createdBy?.map((created) => {
                return (
                  <Select.Option key={created.id} value={created.id}>
                    {created.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
      <Col span={12}>
          <Form.Item name="modified" label="Modified By">
            <Select
              mode="multiple"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {modifiedBy?.map((modified) => {
                return (
                  <Select.Option key={modified.id} value={modified.id}>
                    {modified.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={handleReset}>
          Clear Filter
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default LocationsFilterForm;
