import dayjs from 'dayjs';

function stringToDate(dateString) {
    return dayjs(dateString, "DD-MM-YYYY");
}

function dateToString(dateObject) {
  return dayjs(dateObject).format("DD-MM-YYYY"); 
}

const formatDate = (inputDate) => {
  const months = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12'
  };

  const [day, month, year] = inputDate?.split('-');
  const formattedMonth = months[month];
  return `${day}-${formattedMonth}-${year}`;
};
export { stringToDate, dateToString,formatDate };
