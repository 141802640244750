import { Button, Checkbox, Space, message, Form } from "antd";
import React, { useEffect, useState } from "react";
import {
  getMiscellaneousDetailServiceById,
  updateMiscellaneousDetailServiceById,
} from "../services/miscellaneousDetailsService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  declaration: Yup.boolean().oneOf([true], "You must accept the declaration"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ValuerCertificationForm = ({
  closeForm,
  loanId,
  stageId,
  refreshData,
  updateId,
  Checktrue,
}) => {
  const [declaration, setDeclaration] = useState(Checktrue || false);
  const [form] = Form.useForm();

  useEffect(() => {
    const getValuerCertificationData = async () => {
      try {
        const response = await getMiscellaneousDetailServiceById(stageId);
        if (response.data && response.data.length > 0) {
          setDeclaration(response.data[0].declaration === 1);
          form.setFieldsValue({ declaration: response.data[0].declaration === 1 });
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };

    getValuerCertificationData();
  }, [stageId, form]);

  const handleSubmit = async (values) => {
    try {
      const updatedDeclaration = values.declaration ? 1 : 0;
      const response = await updateMiscellaneousDetailServiceById(updateId, {
        declaration: updatedDeclaration,
      });
      if (response.status === 200 && response.success) {
        message.success("Valuer Certification Form successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCheckboxChange = (e) => {
    setDeclaration(e.target.checked);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{ declaration: declaration }}
    >
      <div className="">
        <div style={{ marginTop: "5px" }}>
          <Form.Item
         label={<span style={{ color: "#00000080" }}>Remark</span>}
            name="declaration"
            valuePropName="checked"
            rules={[yupSync]}
            required
          >
            <Checkbox
              style={{ marginRight: "8px" }}
              onChange={handleCheckboxChange}
            >
              I Hereby Declare that The Information in this Report is True and
              Correct to the best of my Knowledge and Belief.
            </Checkbox>
          </Form.Item>
        </div>
        <Space
          direction="horizontal"
          align="center"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "300px",
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </div>
    </Form>
  );
};

export default ValuerCertificationForm;
