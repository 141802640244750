import { Table, message, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { getIncomeCalculationsByLoanId } from '../services/incomeDetailsServices';
import IncomeCalculationsColumns from './IncomeCalculationsColumns';

const IncomeCalculation = ({ refreshTableData, schemeDisplayName, setHandleCAMButton }) => {
    const { encrypted_loan_id } = useParams();
    const [incomeCalculationColumns, setIncomeCalculationColumns] = useState([]);
    const [incomeCalculationData, setIncomeCalculationData] = useState([]);
    const [loading, setLoading] = useState(true);

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }

    useEffect(() => {
        const fetchIncomeData = async () => {

            try {
                const response = await getIncomeCalculationsByLoanId(loan_id);
                if (response.status === 200 && response.success) {
                    const { columns, data: transformedData } = IncomeCalculationsColumns(schemeDisplayName, response?.data);
                    setIncomeCalculationColumns(columns);
                    setIncomeCalculationData(transformedData);
                    setHandleCAMButton(transformedData && transformedData.length > 0);
                }
            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            } finally {
                setLoading(false);
            }
        };

        fetchIncomeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loan_id, refreshTableData, schemeDisplayName]);

    return (
        <div className="income_calculations_container">
            <div className="heading_text">INCOME CALCULATIONS</div>
            <div className='income_table'>
                {loading ? (
                    <Skeleton active paragraph={{ rows: 5 }} />
                ) : (

                    <Table
                        columns={incomeCalculationColumns}
                        dataSource={incomeCalculationData}
                        pagination={false}
                        scroll={{ x: true }}
                    />

                )}
            </div>
        </div>
    );
};

export default IncomeCalculation;
