import React, { useEffect, useState } from "react"; 
import map from "../assets/address_map.png";
import "../styles/propertyDetails.css";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Drawer, Empty, Table } from "antd";
import Header from "../../../../layout/views/Header";
import "../styles/propertyDetails.css";
import PropertyDetailsForm from "./PropertyDetailsForm";
import AddressPropertyDetails from "./AddressPropertyDetails";
import BoundariesDetailsFrom from "./BoundariesDetailsFrom";
import LocationPropertyDetails from "./LocationPropertyDetails";
import PlanApprovalForm from "./PlanApprovalForm";
import { getPropertyTechnicalDataByIdService } from "../../valuationDetails/services/valuationDetailsServices";
import { getAddressDataByIdService } from "../services/propertyDetailsServices";
import "../../commonCSS/commonTechnicalValuation.css";
import Loading from "../../../../../utils/loading/Loading";
import EditButton from "../../../../../utils/editButton/EditButton";

const PropertyDetails = ({ open, stage_id }) => {
  const [loading, setLoading] = useState(false);
  const [openPropertyForm, setOpenPropertyForm] = useState(false);
  const [openAddressPropertyForm, setOpenAddressPropertyForm] = useState(false);
  const [openBoundariesDetailForm, setOpenBoundariesDetailForm] =
    useState(false);
  const [openLocationPropertyForm, setOpenLocationPropertyForm] =
    useState(false);
  const [, setOpenBuildingParametersForm] =
    useState(false);
  const [openPlanApprovalForm, setOpenPlanApprovalForm] = useState(false);
  const [technicalPropertyData, setTechnicalPropertyData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [hideAddressContent, setHideAddressContent] = useState(false);
  const [hidePropertyContent, setHidePropertyContent] = useState(false);
  const [hideLocationPropertyContent, setHideLocationPropertyContent] =
    useState(false);
  const [hideBuildingParametersContent,] =
    useState(false);
  const [hideBoundariesDetail, setHideBoundariesDetail] = useState(false);
  const [hidePlanApprovalContent, setHidePlanApprovalContent] = useState(false);
  // const [collapseSections, setCollapseSections] = useState(true);


  const columns1 = [
    {
      title: 'Parameters',
      dataIndex: 'parameters',
      render: text => text || '-',
    },
    {
      title: 'North',
      dataIndex: 'north',
      render: text => text || '-',
    },
    {
      title: 'South',
      dataIndex: 'south',
      render: text => text || '-',
    },
    {
      title: 'East',
      dataIndex: 'east',
      render: text => text || '-',
    },
    {
      title: 'West',
      dataIndex: 'west',
      render: text => text || '-',
    },

    {
      title: 'Boundaries Matching',
      dataIndex: 'boundaries_matching',
      render: (boundariesMatching, record) => {
        if (record.parameters === 'As per Documents') {
          return boundariesMatching === 'true' ? 'Yes' : 'No';
        }
        return '-';
      },
    },
    {
      title: 'Mismatch Remarks',
      dataIndex: 'mismatch_remarks',
      render: (text, record) => {
        if (record.parameters === 'As per Documents') {
          return text || '-';
        }
        return '-';
      },
    },
  ];

  const data1 = [
    {
      key: "1",
      no: "1",
      parameters: "As per Documents",
      north: technicalPropertyData?.technical_detail[0]?.document_north,
      south: technicalPropertyData?.technical_detail[0]?.document_south,
      east: technicalPropertyData?.technical_detail[0]?.document_east,
      west: technicalPropertyData?.technical_detail[0]?.document_west,
      boundaries_matching:
        technicalPropertyData?.technical_detail[0]?.boundaries_matching?.toString(),
      mismatch_remarks:
        technicalPropertyData?.technical_detail[0]?.boundary_mismatch_remark,
    },
    {
      key: "2",
      no: "2",
      parameters: "As per Site/Actual",
      north: technicalPropertyData?.technical_detail[0]?.actual_north,
      south: technicalPropertyData?.technical_detail[0]?.actual_south,
      east: technicalPropertyData?.technical_detail[0]?.actual_east,
      west: technicalPropertyData?.technical_detail[0]?.actual_west,
    },
  ];

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPropertyTechnicalDataByIdService(stage_id);
        setTechnicalPropertyData(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, stage_id]);

  const columns = [
    {
      title: "Key",
      dataIndex: "key_parameters",
      key: "key_parameters",
      render: (text, record) => {
        if (record.key === "13") {
          return <span style={{ color: "#E98C17B2" }}>{text}</span>;
        }
        return <span>{text}</span>;
      },
    },
    {
      title: "Address as per Document",
      dataIndex: "address_as_per_document",
      key: "address_as_per_document",
    },
    {
      title: "Address as per Actual at Site",
      dataIndex: "address_as_per_actual_at_site",
      key: "address_as_per_actual_at_site",
    },
  ];

  useEffect(() => {
    const fetchAddressData = async () => {
      try {
        const data = await getAddressDataByIdService(stage_id);
        if (data && data.data && data.data.technical_address_details) {
          const responseData = data.data.technical_address_details;
          const siteData =
            responseData.actual_site_address.length > 0
              ? responseData.actual_site_address[0]
              : null;
          const docData =
            responseData.document_site_address.length > 0
              ? responseData.document_site_address[0]
              : null;

          if (siteData && docData) {
            const newData = [
              {
                key: "1",
                key_parameters: "Address Line 1",
                address_as_per_document: docData.address1,
                address_as_per_actual_at_site: siteData.address1,
              },
              {
                key: "2",
                key_parameters: "Address Line 2",
                address_as_per_document: docData.address2,
                address_as_per_actual_at_site: siteData.address2,
              },

              {
                key: "5",
                key_parameters: "Survey Number",
                address_as_per_document: docData.survey_number,
                address_as_per_actual_at_site: siteData.survey_number,
              },
              {
                key: "3",
                key_parameters: "Plot No",
                address_as_per_document: docData.plot_number,
                address_as_per_actual_at_site: siteData.plot_number,
              },

              {
                key: "4",
                key_parameters: "House No. / Flat No.",
                address_as_per_document: docData.house_number,
                address_as_per_actual_at_site: siteData.house_number,
              },

              // {
              //   key: "6",
              //   key_parameters: "Landmark",
              //   address_as_per_document: docData.landmark,
              //   address_as_per_actual_at_site: siteData.landmark,
              // },
              {
                key: "16",
                key_parameters: "State",
                address_as_per_document: docData.state,
                address_as_per_actual_at_site: siteData.state,
              },

              {
                key: "7",
                key_parameters: "City",
                address_as_per_document: docData.city,
                address_as_per_actual_at_site: siteData.city,
              },
              {
                key: "10",
                key_parameters: "Pincode",
                address_as_per_document: docData.pincode,
                address_as_per_actual_at_site: siteData.pincode,
              },
              {
                key: "9",
                key_parameters: "Taluka",
                address_as_per_document: docData.taluka,
                address_as_per_actual_at_site: siteData.taluka,
              },

              {
                key: "8",
                key_parameters: "District",
                address_as_per_document: docData.district,
                address_as_per_actual_at_site: siteData.district,
              },
              {
                key: "11",
                key_parameters: "Country",
                address_as_per_document: docData.country,
                address_as_per_actual_at_site: siteData.country,
              },

              {
                key: "13",
                key_parameters: "Address as per document on docket",
                address_as_per_document: `${docData.address1}, ${docData.address2}, ${docData.survey_number}, ${docData.plot_number}, ${docData.house_number}, ${docData.state}, ${docData.city}, ${docData.pincode}, ${docData.taluka}, ${docData.district}, ${docData.country}`,
                address_as_per_actual_at_site: `${siteData.address1}, ${siteData.address2}, ${siteData.survey_number}, ${siteData.plot_number}, ${siteData.house_number}, ${siteData.state}, ${siteData.city}, ${siteData.pincode}, ${siteData.taluka}, ${siteData.district}, ${siteData.country}`,
              },
            ];

            setDataSource(newData);
          } else {

          }
        } else {

        }
      } catch (error) {
        console.error("Error fetching address data:", error);
      }
    };

    fetchAddressData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, stage_id]);

  const showPropertyForm = () => {
    setOpenPropertyForm(true);
  };

  const showCertificationForm = () => {
    setOpenAddressPropertyForm(true);
  };

  const showBoundariesDetailForm = () => {
    setOpenBoundariesDetailForm(true);
  };

  const showLocationPropertyForm = () => {
    setOpenLocationPropertyForm(true);
  };

  const showPlanApprovalForm = () => {
    setOpenPlanApprovalForm(true);
  };

  const onClose = () => {
    setOpenPropertyForm(false);
    setOpenAddressPropertyForm(false);
    setOpenBoundariesDetailForm(false);
    setOpenLocationPropertyForm(false);
    setOpenBuildingParametersForm(false);
    setOpenPlanApprovalForm(false);
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div className="main_basic_container">
          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">Property Details</div>
              <div>
                <div style={{ display: "flex", alignItems: "center"}}>
                  {/* <div
                    className="underwriting_form_sub_edit"
                    onClick={showPropertyForm}
                  > */}
                  {/* <img src={edit} alt="Pencil Icon" />
                    <span>Edit</span> */}
                     <div onClick={showPropertyForm}> 
                  <EditButton  />
                  </div>
                  {/* </div> */}
                  {hidePropertyContent ? (
                    <DownOutlined
                      onClick={() =>
                        setHidePropertyContent(!hidePropertyContent)
                      }
                      style={{ margin: "16px" }}
                    />
                  ) : (
                    <UpOutlined
                      onClick={() =>
                        setHidePropertyContent(!hidePropertyContent)
                      }
                      style={{ margin: "16px" }}
                    />
                  )}
                </div>
              </div>
              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Property Details"
                  />
                }
                width={970}
                onClose={onClose}
                visible={openPropertyForm}
                styles={{ body: { paddingBottom: 80 } }}
                closable={false}
                refreshData={toggleRefreshData}
              >
                <PropertyDetailsForm
                  open={openPropertyForm}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  stage_id={stage_id}
                />
              </Drawer>
            </div>


            {!hidePropertyContent && (
              <div className="location_Property_container">
                <div>
                  {technicalPropertyData ? (
                    <>
                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          {/* <div className="basic_card-content"> */}
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Property Address
                              </div>

                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.address

                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">Loan Account No</div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.loan_detail?.loan_account_number
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="label">
                              Product
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.loan_type?.product_name
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">

                          <div className="basic_card-content">
                            <div className="label">
                              Date of Inspection / Site visit
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.date_of_inspection
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Name of Current Owner / Seller
                            </div>
                            {technicalPropertyData ? (
                              <div className="data" style={{ marginBottom: '2rem' }}>
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.current_owner_name
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>


                      </div>


                    </>
                  ) : (
                    <div className="no_data_found">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>










          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">
                Address of Property being Appraised
              </div>
              <div>
                <div style={{ display: "flex", alignItems: "center"}}>
                  {/* <div
                    className="underwriting_form_sub_edit"
                    onClick={showCertificationForm}
                  >
                    <img src={edit} alt="Pencil Icon" />
                    <span>Edit</span>
                  </div> */}
                     <div  onClick={showCertificationForm}> 
                    <EditButton />
                  </div>
                  {hideAddressContent ? (
                    <DownOutlined
                      onClick={() => setHideAddressContent(!hideAddressContent)}
                      style={{ margin: "16px" }}
                    />
                  ) : (
                    <UpOutlined
                      onClick={() => setHideAddressContent(!hideAddressContent)}
                      style={{ margin: "16px" }}
                    />
                  )}
                </div>
              </div>
              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Address of Property being Appraised"
                  />
                }
                width={970}
                onClose={onClose}
                visible={openAddressPropertyForm}
                styles={{ body: { paddingBottom: 80 } }}
                closable={false}
                refreshData={toggleRefreshData}
              >
                <AddressPropertyDetails
                  open={openAddressPropertyForm}
                  refreshData={toggleRefreshData}
                  closeForm={onClose}
                  stage_id={stage_id}
                />
              </Drawer>
            </div>

            {!hideAddressContent && (
              <>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />

                <div className="loan_details_heading remark">Remark:</div>
                <div className="loan_insurance_details_container">
                  <div className="loan_insurance_card">
                    <div className="basic_card-content">
                      <div className="basic_card-content">
                        <div className="label">Address Matching</div>
                        {technicalPropertyData ? (
                          <div className="data">
                            <p>
                              {technicalPropertyData.technical_detail[0]
                                ?.address_matching
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="Basic_card">
                    <div className="basic_card-content">
                      <div className="label">Latitude</div>
                      {technicalPropertyData ? (
                        <div className="data">
                          {technicalPropertyData?.technical_detail[0]?.latitude}
                        </div>
                      ) : (
                        <div className="data">-</div>
                      )}
                    </div>
                  </div>

                  <div className="Basic_card">
                    <div className="basic_card-content">
                      <div className="label">Longitude</div>
                      {technicalPropertyData ? (
                        <div className="data">
                          {
                            technicalPropertyData?.technical_detail[0]
                              ?.longitude
                          }
                        </div>
                      ) : (
                        <div className="data">-</div>
                      )}
                    </div>
                  </div>

                  <div className="Basic_card">
                    <div className="basic_card-content">
                      <div className="label">Google Map</div>
                      <div className="tab_form_map_edit_button">
                        <span className="tab_form_sub_map">
                          <img src={map} alt="Map Icon" />
                          <span>Map</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">Boundaries Detail</div>
              <div>
                <div style={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
                  {/* <div
                    className="underwriting_form_sub_edit"
                    onClick={showBoundariesDetailForm}
                  >
                    <img src={edit} alt="Pencil Icon" />
                    <span>Edit</span>
                  </div> */}
                     <div onClick={showBoundariesDetailForm}> 
                  <EditButton  />
                  </div>
                  {hideBoundariesDetail ? (
                    <DownOutlined
                      onClick={() =>
                        setHideBoundariesDetail(!hideBoundariesDetail)
                      }
                      style={{ margin: "16px" }}
                    />
                  ) : (
                    <UpOutlined
                      onClick={() =>
                        setHideBoundariesDetail(!hideBoundariesDetail)
                      }
                      style={{ margin: "16px" }}
                    />
                  )}
                </div>
              </div>
            </div>

            {!hideBoundariesDetail && (
              <Table
                className="insurance_table"
                columns={columns1}
                dataSource={data1}
                pagination={false}
                size="middle"
              />
            )}

            <Drawer
              title={
                <Header
                  title="Edit"
                  onClose={onClose}
                  name="Boundaries Detail"
                />
              }
              width={970}
              onClose={onClose}
              visible={openBoundariesDetailForm}
              styles={{ body: { paddingBottom: 80 } }}
              closable={false}
              refreshData={toggleRefreshData}
            >
              <BoundariesDetailsFrom
                open={openBoundariesDetailForm}
                refreshData={toggleRefreshData}
                closeForm={onClose}
                stage_id={stage_id}
              />
            </Drawer>
          </div>

          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">
                Location & Property Specific Details (Based On Site Visit)
              </div>
              <div>
                <div style={{ display: "flex", justifyItems: "center", alignItems: 'center' }}>
                  <div
                    // className="underwriting_form_sub_edit"
                    onClick={showLocationPropertyForm}
                  >
                    {/* <img src={edit} alt="Pencil Icon" />
                    <span>Edit</span>
                     */}
                        <div> 
                    <EditButton
                    
                    // onClick={showBoundariesDetailForm} 
                    />
                    </div>
                  </div>
                  {hideLocationPropertyContent ? (
                    <DownOutlined
                      onClick={() =>
                        setHideLocationPropertyContent(
                          !hideLocationPropertyContent
                        )
                      }
                      style={{ margin: "16px" }}
                    />
                  ) : (
                    <UpOutlined
                      onClick={() =>
                        setHideLocationPropertyContent(
                          !hideLocationPropertyContent
                        )
                      }
                      style={{ margin: "16px" }}
                    />
                  )}
                </div>
                <Drawer
                  title={
                    <Header
                      title="Edit"
                      onClose={onClose}
                      name="Location & Property Specific Details (Based On Site Visit)"
                    />
                  }
                  width={970}
                  onClose={onClose}
                  visible={openLocationPropertyForm}
                  styles={{ body: { paddingBottom: 80 } }}
                  closable={false}
                  refreshData={toggleRefreshData}
                >
                  <LocationPropertyDetails
                    open={openLocationPropertyForm}
                    closeForm={onClose}
                    refreshData={toggleRefreshData}
                    stage_id={stage_id}
                  />
                </Drawer>
              </div>
            </div>

            {!hideLocationPropertyContent && (
              <div className="location_Property_container">
                <div>
                  {technicalPropertyData ? (
                    <>
                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          {/* <div className="basic_card-content"> */}
                          {/* <div className="basic_card-content"> */}
                          <div className="basic_card-content">
                            <div className="label">
                              Status of Land Holding
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.status_land_holding
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Plot Demarcation Available</div>
                            <div className="data">
                              {
                                technicalPropertyData?.technical_detail[0]?.demarcation === true
                                  ? 'Yes'
                                  : technicalPropertyData?.technical_detail[0]?.demarcation === false
                                    ? 'No'
                                    : '-'
                              }
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">Layout developed by</div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.developed_by.display_name
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="label">
                              Type of property as per document
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.type_of_property_document
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="label">
                              Type of property at site
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.type_of_property_site
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>{" "}
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Location/Zoning as per master plan
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.zoning
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="basic_card-content">
                                <div className="label">
                                  Development of the vicinity in %
                                </div>
                                {technicalPropertyData ? (
                                  <div className="data">
                                    {
                                      technicalPropertyData?.technical_detail[0]
                                        ?.development_vicinity
                                    }
                                  </div>
                                ) : (
                                  <div className="data">-</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Approach road width(in feet)
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.approch_road_width
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>


                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Approach road type</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.approch_road_type
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Govt road or Private passage
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.govt_private_road
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          {" "}
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Within Corporation / Urban Development / GP
                                Limit
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.mc_gp_limit
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Identified through</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.identified_through
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Person met at site and his relationship with owner
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.met_person_name
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">Type of roof</div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.type_of_structure
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Occupied since(in years)
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.occupied_since
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Occupancy status</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.occupation_status
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Residual life of property (in years)
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.residual_life_of_property
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Age of the property (in years)
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.age_of_property
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Whether toilet built in house/outside?
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]
                                  ?.toilet
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Availability of basic amenities like -
                              electricity, water etc.
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData?.technical_detail[0]
                                  ?.basic_amenities
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Maintenance level of building{" "}
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.maintenance_level
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">

                            <div className="label">Structure conforming to the guidelines as mentioned in NBC by Government of India </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]?.structure_government ? "yes" : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>

                        </div>
                        <div className="location_Property_card">

                          <div className="basic_card-content">
                            <div className="label">Distance from nala/open canal (in feet)</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]?.distance_from_canal ?? "-"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>

                        </div>

                        <div className="location_Property_card">

                          <div className="basic_card-content">
                            <div className="label">Property situated near nala/open canal:</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]?.situated_near_canal ? "Yes" : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>


                        </div>

                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Distance from high tension line if HT lies are witnessed(in feet)</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]?.distance_from_ht_line ?? "-"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>

                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Seismic zone classification
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.seismic_zone
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Property situated near high tension lines
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]
                                  ?.situated_near_ht_lines
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Marketability</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.marketability
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">Locality type</div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.locality_type
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="basic_card-content">
                                <div className="label">
                                  Number of Kitchens available
                                </div>
                                {technicalPropertyData ? (
                                  <div className="data">
                                    {
                                      technicalPropertyData?.technical_detail[0]
                                        ?.no_of_kitchen
                                    }
                                  </div>
                                ) : (
                                  <div className="data">-</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Any hazard in The event of earthquakes/ land slide
                              /cyclone /flood /chemical hazardous/ fire
                              hazardous/tsunamis etc as per guideline of NDMA
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData.technical_detail[0]
                                    ?.house_delivery_agency
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              % of commercial portion witnessed at the property
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.commercial_portion_witnessed
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Market feedback (broker name and Broker number
                                or paste the link of similar properties from
                                99acres, magicbricks, proptiger)
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.market_feedback
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="basic_card-content">
                                <div className="label">
                                  Risk Of Demolition
                                </div>
                                {technicalPropertyData ? (
                                  <div className="data">
                                    {
                                      technicalPropertyData?.technical_detail[0]
                                        ?.risk_of_demolition
                                    }
                                  </div>
                                ) : (
                                  <div className="data">-</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">
                                Before how many years of recent demolition
                              </div>
                              {technicalPropertyData ? (
                                <div className="data">
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.before_years
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              {" "}
                              Any recent demolition done in this vicinity?
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]
                                  ?.recent_demolition
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                      </div>

                      <div className="location_Property_details">
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Will the property be affected for road widening
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData?.technical_detail[0]
                                  ?.road_widening
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">
                              Is the property in negative area
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]
                                  ?.property_negative_area
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>


                        <div className="location_Property_card" style={{ marginBottom: "3rem" }}>
                          <div className="basic_card-content">
                            <div className="label">
                              Road widening chances foreseen
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]
                                  ?.road_widening
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                        <div className="location_Property_card" style={{ marginBottom: "3rem" }}>
                          <div className="basic_card-content">
                            <div className="label">
                              Distance from branch(in KM)
                            </div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {
                                  technicalPropertyData?.technical_detail[0]
                                    ?.distance_from_branch
                                }
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>

                      </div>

                      <div
                        className="location_Property_details"
                        style={{ marginBottom: "5rem" }}
                      >
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="label">Is Lift Available</div>
                            {technicalPropertyData ? (
                              <div className="data">
                                {technicalPropertyData.technical_detail[0]
                                  ?.lift_available
                                  ? "Yes"
                                  : "No"}
                              </div>
                            ) : (
                              <div className="data">-</div>
                            )}
                          </div>
                        </div>
                        <div className="location_Property_card">
                          <div className="basic_card-content">
                            <div className="basic_card-content">
                              <div className="label">House delivery agency</div>
                              {technicalPropertyData ? (
                                <div
                                  className="data"
                                  style={{ marginBottom: "3rem" }}
                                >
                                  {
                                    technicalPropertyData?.technical_detail[0]
                                      ?.house_delivery_agency
                                  }
                                </div>
                              ) : (
                                <div className="data">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="no_data_found">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            )}


          </div>

          <div className="valuation_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">Plan Approval</div>
              <div>
                <div style={{ display: "flex", justifyItems: "center" }}>
                  <div
                    // className="underwriting_form_sub_edit"
                    onClick={showPlanApprovalForm}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <img src={edit} alt="Pencil Icon" />
                    <span>Edit</span> */}
                     <div> 
                    <EditButton />
                    </div>
                  </div>
                  {hidePlanApprovalContent ? (
                    <DownOutlined
                      onClick={() =>
                        setHidePlanApprovalContent(!hidePlanApprovalContent)
                      }
                      style={{ margin: "16px" }}
                    />
                  ) : (
                    <UpOutlined
                      onClick={() =>
                        setHidePlanApprovalContent(!hidePlanApprovalContent)
                      }
                      style={{ margin: "16px" }}
                    />
                  )}
                </div>
              </div>
            </div>

            {!hideBuildingParametersContent && (
              <div className="loan_insurance_details_container">
                {technicalPropertyData ? (
                  <>
                    <div className="loan_insurance_card">
                      <div className="basic_card-content">
                        <div className="basic_card-content">
                          <div className="label">
                            Is the plan complying DCR norms
                          </div>
                          {technicalPropertyData ? (
                            <div className="data">
                              {technicalPropertyData.technical_detail[0]
                                ?.dcr_norms
                                ? "Yes"
                                : "No"}
                            </div>
                          ) : (
                            <div className="data">-</div>
                          )}
                        </div>
                      </div>
                      <div className="basic_card-content">
                        <div className="label">Risk of Demolition</div>
                        {technicalPropertyData ? (
                          <div className="data">
                            {
                              technicalPropertyData?.technical_detail[0]
                                ?.risk_of_demolition
                            }
                          </div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>

                      <div className="basic_card-content">
                        <div className="label">Availability of Approved Plan / Sanction Plan</div>
                        {technicalPropertyData ? (
                          <div className="data">
                            {(technicalPropertyData?.technical_detail[0]?.as_per_approved === 0) ? "Yes" : (technicalPropertyData?.technical_detail[0]?.as_per_approved === 1) ? "No" : "-"}
                          </div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>


                    </div>
                    <div className="Basic_card">
                      <div className="basic_card-content">
                        <div className="label">Number of floors permitted</div>
                        {technicalPropertyData ? (
                          <div className="data">
                            {
                              technicalPropertyData?.technical_detail[0]
                                ?.floors_permitted
                            }
                          </div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>

                      <div className="basic_card-content">
                        <div className="label">Vertical Deviation (%)</div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.vertical_deviation}</div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>

                      <div className="basic_card-content">
                        <div className="label">Designation of the authority </div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.local_byelaws}</div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>
                    </div>
                    <div className="Basic_card">
                      <div className="basic_card-content">
                        <div className="label">Number of Floors constructed</div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.floors_constructed}</div>
                        ) : (
                          <div className="data">-</div>
                        )}


                      </div>

                      <div className="basic_card-content">
                        <div className="label">Any Extension in Future </div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.extension_in_future}</div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>

                      <div className="basic_card-content">
                        <div className="label">Construction Permission Number </div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.construction_permission_no}</div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>
                    </div>
                    <div className="Basic_card">
                      <div className="basic_card-content">
                        <div className="label">FSI Achieved </div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.fsi_achieved}</div>
                        ) : (
                          <div className="data">-</div>
                        )}


                      </div>


                      <div className="basic_card-content">
                        <div className="label">Setback Deviation(%) </div>
                        {technicalPropertyData ? (
                          <div className="data">{technicalPropertyData?.technical_detail[0]?.setback_deviation}</div>
                        ) : (
                          <div className="data">-</div>
                        )}
                      </div>




                    </div>

                  </>
                ) : (
                  <div className="no_data_found" style={{ marginLeft: "30rem" }}>
                    <Empty />
                  </div>


                )}

              </div>


            )}

            <Drawer
              title={<Header title="Edit" onClose={onClose} name="Plan Approval" />}
              width={970}
              onClose={onClose}
              visible={openPlanApprovalForm}
              styles={{ body: { paddingBottom: 80 } }}
              closable={false}
              refreshData={toggleRefreshData}
            >
              <PlanApprovalForm open={openPlanApprovalForm} refreshData={toggleRefreshData} closeForm={onClose} stage_id={stage_id} />
            </Drawer>

          </div>


        </div>
      )}
    </>
  );
};

export default PropertyDetails;
