import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { deleteEmploymentNatureByIdService } from "../services/employmentNatureService";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import EmploymentNatureForm from "./EmploymentNatureForm";
import EmploymentNatureFilterForm from "./EmploymentNatureFilterForm";

const EmploymentNature = () => {
 
  const { Title } = Typography;
  const [employees, setEmpolyees] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  // Filter Employment Nature
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    is_active: null,
    is_verified: null,
  });

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedEmployee(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };


  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      width:200,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      sorter: true,
      render: (display_name) => {
        return display_name !== null ? display_name : " - ";
      },
      width:200,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      render: (code) => {
        return code !== null ? code : " - ";
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by.name;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by.name;
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },
    {
      title: "Actions",
      fixed:"right",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setSelectedEmployee(record);
              showDrawer();
            }}
          />
          <DeleteOutlined
            onClick={() => {
              setSelectedEmployee(record);
              setOpenModal(true);
            }}
          />
        </Space>
      ),
    },
  ];


  const deleteEmploymentNature = async () => {
    try {
      const response = await deleteEmploymentNatureByIdService(selectedEmployee.id);

      if (response.status === 200 && response.success === true) {
        message.success("Employment Nature deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedEmployee(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };

  return (
    <div>
    <Space
      direction="horizontal"
      align="center"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Title level={2} align="center">
      Employment Nature
      </Title>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filters
        </Button>
        <Button
          onClick={showDrawer}
          type="primary"
          icon={<PlusCircleOutlined />}
          style={{ marginLeft: "1rem" }}
        >
          Add Employment Nature
        </Button>
      </div>
    </Space>

    <DataTable
      apiPath="/api/v1/master/employment-nature/"
      tableData={employees}
      setTableData={setEmpolyees}
      tableColumns={columns}
      refreshData={refreshTableData}
      searchPlaceholder={"Type to search"}
      filters={filterFormData}
    />

    <Drawer
    
      title={<Header title={selectedEmployee ? "Edit" : "Add"}  onClose={onClose} name="Employment Nature"/>}
      width={720}
      onClose={onClose}
      open={open}
      styles={{ body: { paddingBottom: 80 } }} 
      closable={false}
    >
      
      <EmploymentNatureForm
        refreshData={toggleRefreshTableData}
        id={selectedEmployee && selectedEmployee.id}
        open={open}
        closeForm={onClose}
        isEditMode={!!selectedEmployee} 
      />
    </Drawer>

    <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Employment Nature"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <EmploymentNatureFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>

    <Modal
      title="Confirm Delete"
      open={openModal}
      onOk={() => {
        setOpenModal(false);
        deleteEmploymentNature();
      }}
      onCancel={() => setOpenModal(false)}
      okType="danger"
    >
      <p>{`Are you sure you want to delete ${selectedEmployee?.name}?`}</p>
    </Modal>
  </div>
  )
}

export default EmploymentNature