import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import {
  createCountryService,
  getCountryByIdService,
  updateCountryByIdService,
} from "../services/countryServices";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, "Code can only contain capital letters and numbers")
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters and spaces")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .required("Name is required")
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^[A-Za-z ]+$/, "Display name can only contain letters and spaces")
    .min(2, "Display name must be at least 2 characters")
    .max(128, "Display name must be at max 128 characters")
    .label("Display name"),
  image: Yup.string().required("Image is required").label("Image"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};
const CustomUploadButton = ({ loading, imageUrl, isEditMode }) => (
  <div>
    {loading ? (
      <LoadingOutlined />
    ) : imageUrl ? (
      <img
        src={imageUrl}
        alt="Flag"
        style={{
          // marginTop: "5px",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    ) : !isEditMode ? (
      <>
        <PlusOutlined />
        <div>Upload Image</div>
      </>
    ) : (
      <>
        <PlusOutlined />
        <div>Upload Image</div>
      </>
    )}
  </div>
);

const CountryForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    const isLt1M = file.size / 1024 / 1024 <= 1;

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/JPEG file!");
      return false;
    }
    if (!isLt1M) {
      message.error("Image must be less than or equal to 1MB!");
      return false;
    }
    setImageUrl(URL.createObjectURL(file));
    setImageFile(file);
    return false;
  };


  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  const getCountryData = async () => {
    if (id) {
      try {
        const response = await getCountryByIdService(id);
        const { name, code, display_name, is_active, is_verified, image } =
          response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          is_active,
          is_verified,
          image,
        });
        if (image) {
          setImageUrl(image);

        }
        setDisplayNameSynced(true);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      const payload = {
        name: values.name,
        display_name: values.display_name,
        code: values.code,
        is_active: values.is_active,
        is_verified: values.is_verified,
      };
      if (imageFile) {
        payload.image = await fileToBase64(imageFile);
      }


      if (id) {
        const response = await updateCountryByIdService(id, payload);
        if ((response.status = 200 && response.success)) {
          message.success("Country successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createCountryService(payload);
        if ((response.status = 201 && response.success)) {
          message.success("Country successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (open) {
      getCountryData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true);
      setImageUrl(null); // Reset image URL
      setImageFile(null); // Reset image file
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        image: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item label="Image" name="image">
            <Upload
              name="image"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              <CustomUploadButton
                loading={loading}
                imageUrl={imageUrl}
                isEditMode={isEditMode}
              />
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="display_name"
            label="Display Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Code"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default CountryForm;
