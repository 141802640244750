import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row, Space, message } from 'antd';

import * as Yup from "yup";
import { getstrngthById, updateStrengthOfcaseService } from '../services/creditAnalysisServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';

const validationSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
}; 

const StrenthForm = ({ closeForm, open, id, refreshData, }) => {
    const [form] = Form.useForm();
  


   
    
    const handleInput = (e, field) => {
        const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
        form.setFieldsValue({ [field]: newValue });
    };

    const getStregthData = async () => {
        if (id) {
            try {
                const response = await getstrngthById(id);
                const { description } = response.data;  
             
                form.setFieldsValue({ description }); 
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                  )
            }
        }
    };

    useEffect(() => {
        if (open) {
            getStregthData();
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    
    const handleSubmit = async (values) => {
        try {
            const response = await updateStrengthOfcaseService(id, values);
            if (response.status === 200 && response.success) {
                message.success("Strengths of the case updated successfully");
                refreshData();
                closeForm();
            }
        } catch (error) {
            message.error(
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                  )
            );
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
        >
            <Row gutter={16} style={{ marginBottom: "16px" }} >
                <Col span={8}>
                    <Form.Item name="description" label="Description" rules={[yupSync]} required>
                        <Input
                            placeholder="Please enter description"
                            onChange={(e) => handleInput(e, "description")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default StrenthForm;
