import { axiosRequest } from "../../../../../utils/api/axiosRequest"

export const getDocumentDetailsByIdService = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/media-detail/media-detail/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }