import React, { useState } from 'react'
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Modal, Select, Table, message } from 'antd';
import "../style/PaymentDetail.css"
const { Option } = Select;

const PaymentDetail = () => {

  const [collapsed1, setCollapsed1] = useState(false);
  const [openModal, setOpenModal] = useState(false);


  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Loan ID",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Branch",
      dataIndex: "document_type",
      key: "document_type",
    },
    {
      title: "Receipt Type",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Instrument Type",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Inst. Date",
      dataIndex: "received_location",
      key: "received_location",
    },
    {
      title: "Receipt Date",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Inst. NO   ",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Inst.Amount",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Bank Acc NO   ",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Inst .Status",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Batch ID",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "RE",
      dataIndex: "date",
      key: "date",
    },
  ];

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  return (
    <div className='tab_main_container'>
      <div className="heading_details_container">
        <div className="otc_pdd_payment_heading"
          style={{ borderBottom: collapsed1 && "none" }}>
          <div className="otc_pdd_payment_description_data"

          >Payment Details</div>

          <div className="otc_pdd_stage" style={{ marginRight: '10px' }}>
            <div>
              <Select
                placeholder="Select Stage"
                className="otc_pdd_select_stage"
              // onChange={handleSelectStage}
              >
                {[{ id: 1, display_name: "Last 3 months" }, { id: 2, display_name: "chowksay" }, { id: 3, display_name: "mayank" }]
                  ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map((stage) => {
                    return (
                      <Option key={stage.id} value={stage.display_name}>
                        {stage.display_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div>
              <Button
                type="primary"
                // className="otc_pdd_go_button"
                style={{ margin: "5px" }}
              >
                Go
              </Button>
            </div>
            <div>
              <Button style={{ color: "green", margin: "3px" }}>Export to Excel</Button>
            </div>
          </div>

          <div onClick={toggleCollapse1}>
            {collapsed1 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
        <div className="otc_pdd_table_container">
          {!collapsed1 && (
            <Table
              // dataSource={PreFinancial}
              columns={columns}
              pagination={false}
            />
          )}
        </div>
      </div>



      <Modal
        title="Alert"
        open={openModal}
        onOk={() => {
          message.success("Document deleted successfully");
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okText="Ok"
        cancelText="Cancel"
        centered
        width={500}
      >
        <p>Are you sure you want to delete this document?</p>
      </Modal>
    </div>
  )
}

export default PaymentDetail