import { Button, Col, Divider, Form, Row, Select, Space, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import {
  createSendBackQuery,
  // getAllQueryStatus,
  getAllStages,
  getAllSubSection,
  getAllUsers,
} from "../services/sendBackService";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  assign_to: Yup.string().required("Assign to is required"),
  stage: Yup.string().required("Stage is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const SendBackForm = ({
  closeForm,
  stage_code,
  form,
  toggleTableRefreshData,
}) => {
  // const [form1] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [subSections, setSubSections] = useState(null);
  // const [querystatus, setQueryStatus] = useState(null);
  const [users, setUsers] = useState(null);
  const [stages, setStages] = useState(null);
  const [loading, setLoading] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersresponse = await getAllUsers();
        setUsers(usersresponse.data);
        const response = await getAllSubSection();
        setSubSections(response.data);
        // const querystatusresponse = await getAllQueryStatus();
        // setQueryStatus(querystatusresponse.data);
        const stagesresponse = await getAllStages(stage_code);
        setStages(stagesresponse.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleInput = (e, name, fieldKey) => {
  //     const newValue = e.target.value.replace(/[^A-Za-z0-9.,/?\s]/g, "");
  //     const queries = form.getFieldValue('queries');
  //     queries[fieldKey] = { ...queries[fieldKey], [name]: newValue };
  //     form.setFieldsValue({ queries });
  // };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        loan_detail: loan_id,
        stage: stage_code,
        ...values,
      };
      const response = await createSendBackQuery(payload);
      if ((response.status = 200 && response.success)) {
        message.success("Query send back successfully");
        closeForm();
        form.resetFields();
        toggleTableRefreshData();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={{ queries: [{}] }} // Initialize Form.List with one empty object
    >
      <div
        style={{
          height: "300px",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0px",
        }}
      >
        <div style={{ marginRight: "20px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="assign_to"
                label="Assign To"
                rules={[yupSync]}
                required
                
              >
                <Select
                
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  disabled={true}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users?.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="stage"
               label="Stage" 
               rules={[yupSync]}
               required
               
                >
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  disabled={true}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {stages?.map((stage) => (
                    <Select.Option
                      key={stage.code}
                      value={stage.code}
                    >
                      {stage?.display_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
                            <Form.Item name="query_status" label="Query Status">
                                <Select
                                    mode="single"
                                    placeholder="Please select"
                                    required={true}
                                    allowClear
                                    style={{ width: "100%" }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {querystatus?.map((type) => (
                                        <Select.Option key={type} value={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
          </Row>

          <Form.List name="queries">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div
                    key={key}
                    style={{ position: "relative", marginBottom: "16px" }}
                  >
                    {index > 0 && (
                      <Button
                        onClick={() => remove(name)}
                        icon={<DeleteOutlined />}
                        style={{
                          color: "red",
                          position: "absolute",
                          right: 0,
                          zIndex: 1,
                        }}
                      />
                    )}
                    <div style={{ paddingTop: "10px" }}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "sub_section"]}
                            fieldKey={[fieldKey, "sub_section"]}
                            label="Sub Section"
                            rules={[
                              {
                                required: true,
                                message: "Sub section is required",
                              },
                            ]}
                            required
                          >
                            <Select
                              mode="single"
                              placeholder="Please select"
                              required={true}
                              allowClear
                              style={{ width: "100%" }}
                              showSearch
                              filterOption={(input, option) =>
                                option?.children
                                  ?.toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {subSections?.map((subsection) => (
                                <Select.Option
                                  key={subsection.id}
                                  value={subsection.id}
                                >
                                  {subsection?.display_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "query"]}
                            fieldKey={[fieldKey, "query"]}
                            label="Write Your Query"
                            // sonChange={(e) => handleInput(e, "query",fieldKey)}
                            rules={[
                              { required: true, message: "Query is required" },
                              {
                                min: 2,
                                message:
                                  "Query must be between 2 and 1000 characters",
                              },
                              {
                                max: 1000,
                                message:
                                  "Query must be between 2 and 1000 characters",
                              },
                            ]}
                            required
                          >
                            <TextArea placeholder="Enter Query" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
                <Button
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  // style={{ left: "60%" }}
                >
                  Add
                </Button>
              </>
            )}
          </Form.List>
        </div>
      </div>
      <Divider />
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default SendBackForm;
