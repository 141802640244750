import { axiosRequest } from "../../../../utils/api/axiosRequest";


export const getListOfLoanDetailService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/locations/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listStatuservice = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listOfUsersService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

// export const listLoansFilterService = (search, page, limit, filter, requested_amount, location, approved_amount, location) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axiosRequest.get(`api/v1/loan/loan-detail/?search=${search}&page=${page}&limit=${limit}&filter=${filter}&requested_amount=${requested_amount}&approved_amount=${approved_amount}&between=0,10000&gtr=500000&lt=40000&location=${location}`);
//       return resolve(response.data);
//     } catch (error) {
//       return reject(error);
//     }
//   });
// };