
import React from 'react';
import editIcon from '../../utils/editButton/assets/Editbutton.png'; // Replace with your actual edit icon

const EditButton = () => {
  return (
      <img src={editIcon} alt="Edit Icon" />
  );
};

export default EditButton;
