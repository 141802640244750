import React from "react";
import { RxCross2 } from "react-icons/rx";
import "../styles/header.css";
const Header = ({ title, onClose, name }) => {
  return (
    <div className="form_header">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{title}</span>
        <RxCross2
          style={{
            fontSize: "24px",
            cursor: "pointer",
            // marginLeft: "600px",
            color: " #b3b4b6",
          }}
          onClick={() => {
            onClose(false);
          }}
        />
      </div>
      <h3 className="form_title">{name}</h3>
    </div>
  );
};

export default Header;
