import React, { useEffect, useState } from "react";
import "../styles/referenceDetails.css";
import verify from "../assets/Group.png";
import add from "../assets/Add.png";
import "../../style/customerStyle.css";
import { Drawer, Empty, Modal, message } from "antd";
import Header from "../../../../layout/views/Header";
import ReferenceDetailsForm from "./ReferenceDetailsForm";
import { deleteReferenceDetailsByIdService, getRefrencesDetailsByIdService } from "../services/refrencesDetailsServices";
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading"
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";

const ReferenceDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [bankAccountData, setBankAccountData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState();
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
    setSelectedPerson(null);
    setSelectedRecord(null);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getRefrencesDetailsByIdService(loan_id);
        setBankAccountData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);


  const renderEditButton = (showDrawer, id, person) => (
    <div className="tab_form_sub_edit" onClick={() => {
      setSelectedCustomer(id);
      setSelectedPerson(person);
      showDrawer();
    }}>
      <EditButton />
    </div>
  );

  const Headings = [
    {
      label: "Reference Details",
    },
    {
      label: "Salutation",
      value: "salutation",
    },
    {
      label: "First Name",
      value: "first_name",
    },
    {
      label: "Middle Name",
      value: "middle_name",
    },
    {
      label: "Last Name",
      value: "last_name",
    },
    {
      label: "Relation",
      value: "relation.name",
    },
    {
      label: "Phone Number",
      value: "phone",
    },
    {
      label: "Mobile Number",
      value: "mobile",
    },

    {
      label: "Reference Type",
      value: "reference_type",
    },

    {
      label: "Year Known",
      value: "year_known",
    },

    {
      label: "Email",
      value: "email",
    },

    {
      label: "Address Type",
      value: "address_type.display_name",
    },


    {
      label: "Address Line 1",
      value: "address_line_1",
    },
    {
      label: "Address Line 2",
      value: "address_line_2",
    },
    {
      label: "Landmark",
      value: "landmark",
    },

    {
      label: "pincode",
      value: "pincode.display_name",
    },

    {
      label: "City",
      value: "city.display_name",
    },
    {
      label: "Taluka",
      value: "taluka.display_name",
    },
    {
      label: "District",
      value: "district.display_name",
    },

    {
      label: "State",
      value: "state.display_name",
    },
    {
      label: "Country",
      value: 'country.display_name',
    }





  ];

  const fieldNameMap = {

    "salutation": "Salutation",
    "first_name": "First Name",
    "middle_name": "Middle Name",
    "last_name": "Last Name",
    "relation": "Relation",
    "phone_number": "Phone Number",
    "mobile_number": "Mobile Number",
    "country": "Country",

    "address_line_1": "Address Line 1",
    "address_line_2": "Address Line 2",
    "email": "Email",
    "landmark": "Landmark",
    "year_known": "Year Known",
    "reference_type": "Reference Type",
    "address_type": "Address Type",
    "city": "City",
    "taluka": "Taluka",
    "district": "District",
    "pincode": "Pincode",
    "state": "State",





  };



  const formatName = (salutation, firstName) => {
    return `${salutation ? salutation + " " : ""}${firstName}`;
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return 'tab_form_main_data_two_entries';
      case 3:
        return 'tab_form_main_data_three_entries';
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return '';
    }
  };

  const deleteRecord = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteReferenceDetailsByIdService(selectedRecord);
      if (response.status === 200 && response.success === true) {
        message.success("Reference details deleted successfully");
        toggleRefreshTableData();
        setSelectedRecord(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };
  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              name={selectedPerson ? `${selectedPerson?.customer_name}'s Reference Details` : `${selectedPerson?.customer_name}'s  Reference Detail`}
            />
          }

          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <ReferenceDetailsForm
            refreshData={toggleRefreshTableData}
            id={selectedCustomer}
            customer_id={selectedCustomerId}
            open={open}
            closeForm={onClose}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : bankAccountData.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">  Key Parameter's
                    {Headings[0] === "Reference Details" && renderEditButton(showDrawer, "details1")}
                  </th>
                  {bankAccountData?.results.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            <img src={verify} alt="group" style={{ marginLeft: "5px" }} />
                          </span>
                        </div>
                        <div
                          className="tab_form_sub_edit"
                          onClick={() => {
                            showDrawer();
                            setSelectedCustomerId(person)
                            setSelectedPerson(person)
                          }}
                        >
                          <img src={add} alt="Add Button" />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bankAccountData && [...Array(bankAccountData.max_count)].map((_, rowIndex) => (
                  Headings.map((header, headerIndex) => (
                    <tr
                      key={`row-${headerIndex}`}
                      className={
                        header.label === "Reference Details"
                          ? "reference-row "
                          : header.label === "PD Entries"
                            ? "reference-pd-row"
                            : ""
                      }
                    >
                      <td
                        className="tab_form_left_heading">{header.label === "Reference Details" ? `Reference Details ${rowIndex + 1}` : header.label}</td>
                      {bankAccountData?.results.map((person, colIndex) => (
                        <td
                          key={`col-${colIndex}`}
                          className={`tab_form_main_data_table ${getTableColumnClass(bankAccountData.results.length)}`}
                        >
                          {person.reference_detail && person.reference_detail[rowIndex] ? (
                            <>
                              {

                                header.hasOwnProperty("value") && (
                                  header.value === "first_name" ? (
                                    formatName(person.reference_detail[rowIndex].salutation, person.reference_detail[rowIndex].first_name)
                                  )
                                    : header.value === "phone" ? (
                                      person.reference_detail[rowIndex].phone_number ? (
                                        `+${person.reference_detail[rowIndex].phone_country_code} ${person.reference_detail[rowIndex].phone_number}`
                                      ) : (
                                        "-"
                                      )
                                    )
                                      : header.value === "mobile" ? (
                                        person.reference_detail[rowIndex].mobile_number ? (
                                          `+${person.reference_detail[rowIndex].mobile_country_code} ${person.reference_detail[rowIndex].mobile_number}`
                                        ) : (
                                          "-"
                                        )
                                      )

                                        : header.value === "emaill" ? (
                                          person.reference_detail[rowIndex].emaill ? (
                                            `${person.reference_detail[rowIndex].emaill}`
                                          ) : (
                                            "-"
                                          )
                                        )


                                          : header.value === "years_known" ? (
                                            person.reference_detail[rowIndex].years_known ? (
                                              `${person.reference_detail[rowIndex].years_known}`
                                            ) : (
                                              "-"
                                            )
                                          )


                                            : header.value === "reference_type" ? (
                                              person.reference_detail[rowIndex].reference_type ? (
                                                `${person.reference_detail[rowIndex].reference_type}`
                                              ) : (
                                                "-"
                                              )
                                            )



                                              : (
                                                header.value
                                                  .split(".")
                                                  .reduce((acc, key) => acc?.[key], person?.reference_detail?.[rowIndex]) || "-"
                                              )
                                )}
                              {header.label === "Reference Details" && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                  <div
                                    onClick={() => {
                                      setSelectedRecord(person.reference_detail[rowIndex].id);
                                      setOpenModal(true);
                                    }}>

                                    <DeleteButton />
                                  </div>
                                  {person?.reference_detail[rowIndex]?.created_at &&
                                    person?.reference_detail[rowIndex]?.modified_at &&
                                    isModifiedAfterCreated(
                                      person?.reference_detail[rowIndex]?.created_at,
                                      person?.reference_detail[rowIndex]?.modified_at
                                    ) && (
                                      <HistoryTable
                                        id={person?.reference_detail[rowIndex]?.id}
                                        bench_id={"ea6a108f89f76a0a"}
                                        fieldNameMap={fieldNameMap}
                                      />
                                    )}
                                  <ConditionalRender
                                    userPermissions={user_data?.permissions}
                                    requiredPermissions={["MPITDE9"]}
                                  >
                                    {renderEditButton(showDrawer, person.reference_detail[rowIndex].id, person)}
                                  </ConditionalRender>

                                </div>
                              )}
                            </>
                          ) : (
                            header.label === "Reference Details" ? "" : <span>-</span>
                          )}
                        </td>
                      ))}

                    </tr>
                  ))
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no_data_found_message">
              <Empty />
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRecord();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default ReferenceDetails;
