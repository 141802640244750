import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";
import StageFlowForm from "./StageFlowForm";
import { deleteStageFlowByIdService } from "../services/stageFlowServices";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import StageFlowFilterForm from "./StageFlowFilterForm";

const StageFlow = () => {
  const { Title } = Typography;
  const [stageFlow, setStageFlow] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedStageFlow, setSelectedStageFlow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

    // Filter Stage Flow
    const [showFilterForm, setShowFilterForm] = useState(false);
    const [filterFormData, setFilterFormData] = useState({
      filter: false,
      is_active: null,
      is_verified: null,
      stage: [],
      next_stage:[]
    });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedStageFlow(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);
  const columns = [
    {
      title: "Stage",
      sorter: true,
      dataIndex: "stage",
      render: (stage) => {
        return stage && stage.display_name ? stage.display_name : " - ";
      },
    },
    {
        title: "Next Stage",
        sorter: true,
        dataIndex: "next_stage",
        render: (next_stage) => {
          return next_stage && next_stage.display_name ? next_stage.display_name : " - ";
        },
      },
   
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by.name;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by.name;
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },
    {
      title: "Actions",
      fixed:"right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => {
                setSelectedStageFlow(record);
                showDrawer();
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setSelectedStageFlow(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];


  const deleteStageFlow = async () => {
    try {
      const response = await deleteStageFlowByIdService(selectedStageFlow.id);
      if (response.status === 200 && response.success === true) {
        message.success("Stage Flow deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedStageFlow(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
        Stage Flow 
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filters
          </Button>
          <Button
            onClick={showDrawer}
            type="primary"
            icon={<PlusCircleOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            Add Stage Flow 
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/stage-flow/"
        tableData={stageFlow}
        setTableData={setStageFlow}
        tableColumns={columns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />
      <Drawer
        title={<Header 
        title={selectedStageFlow ? "Edit" : "Add"} onClose={onClose} name="Stage Flow"/>}
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <StageFlowForm
          refreshData={toggleRefreshTableData}
          id={selectedStageFlow && selectedStageFlow.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedStageFlow} 
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Stage Flow"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <StageFlowFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}

        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteStageFlow();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </div>
  );
};

export default StageFlow;
