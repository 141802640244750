import React, { useEffect, useState } from "react";
import "../styles/propertyMatching.css";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { getPropertyDedupe } from "../services/dedupeService";
import { Empty, message } from "antd";
import Loading from "../../../utils/loading/Loading";

const PropertyMatching = ({ loanId }) => {
  const [showNoData, setShowNoData] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPropertyDepupeData = async () => {
      try {
        setLoading(true);
        const response = await getPropertyDedupe(loanId);

        if (response?.response_code !== 200) {
          setShowNoData(true);
          setLoading(false);
        } else {
          const data = [];
          response?.propertymatch.forEach((customer, index) => {
            data.push({
              key: index,
              customer_name: customer.matched_loan_account_no,
              customer_type: "",
              rule_name: "",
              match_percentage: "",
              is_lan_row: true,
            });
            data.push({
              key: index,
              customer_name: customer.matched_customer_full_name,
              customer_type: customer.matched_customer_type,
              rule_name: customer.matched_rule_name,
              match_percentage: customer.matched_percentage,
              is_lan_row: false,
            });
          });

          setPropertyData(data);
          setLoading(false);
        }
      } catch (error) {
        setShowNoData(true);
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchPropertyDepupeData();
  }, [loanId]);

  return (
    <div className="property-matching-container">
      {loading ? (
        <div style={{ border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) : showNoData ? (
        <div className="no_data_found_message">
          <Empty />
        </div>
      ) : (
        <table className="property_custom_table">
          <thead>
            <tr>
              <th className="table-header customer_name_column">
                Customer Name
              </th>
              <th className="table-header customer_type_column">
                Customer Type
              </th>
              <th className="table-header rule_name_column">Rule Name</th>
              <th className="table-header match_percentage_column">
                Match Percentage
              </th>
            </tr>
          </thead>
          <tbody>
            {propertyData &&
              propertyData?.map((item, index) => (
                <tr
                  key={item.key}
                  className={`table-row ${item.is_lan_row === true ? "first-row" : ""
                    } `}
                >
                  <td className="property-table-data">{item.customer_name}</td>
                  <td className="property-table-data">{item.customer_type}</td>
                  <td className="property-table-data">{item.rule_name}</td>
                  <td className="property-table-data">
                    {item.match_percentage}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {/* <div className="remark_div">
        <div className="remark">Remark :</div>
        <div className="no_match_found">No Match Found.</div>
      </div> */}
    </div>
  );
};

export default PropertyMatching;
