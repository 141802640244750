import {
  Button,
  Col,
  Form,
  Select,
  Row,
  Upload,
  Space,
  message,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createUploadImagesModelService,
  deleteDocumentDetailsByIdService,
  listLegalDocumentTypeService,
} from "../services/documentDetailsServices";

const { Option } = Select;

const PropertyPicturesForm = ({
  closeForm,
  refreshData,
  images,
  setSelectedPropertyPicture,
  open,
  technical_detail,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [documentName, setDocumentName] = useState("");
  const [, setAttachments] = useState([]);

  const fetchLegalDocumentType = async () => {
    try {
      const response = await listLegalDocumentTypeService();
      setLegalDocumentType(response.data.choices);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    if (open) {
      fetchLegalDocumentType();
    } else {
      form.resetFields(["upload_documents"]);
      setFileList([]);
      setImage([]);
      setUploadDisabled(true);
    }
  }, [open, form]);

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteDocumentDetailsByIdService(deletePicture.id);

      if (response?.status === 200 && response?.success === true) {
        message?.success("Property Picture deleted successfully");
        setOpenModal(false);
        setSelectedPropertyPicture(null);
        refreshData();
      }
    } catch (error) {
      message?.error(error?.response?.data?.message);
    }
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const newImages = newFileList.map((file) => ({
      uid: file.uid,
      file_path: URL.createObjectURL(file.originFileObj || file),
      document_name: file.document_name, // Ensure document name is preserved
    }));
    setImage(newImages);

    setUploadDisabled(false);
    form.resetFields(["upload_documents"]);
  };

  const handleRemove = (file) => {
    URL.revokeObjectURL(file.file_path);

    setImage((prevImages) => prevImages.filter((image) => image.uid !== file.uid));
    setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment.uid !== file.uid)
    );

    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const confirmDelete = () => {
    if (deleteUploadPicture) {
      setImage((prevImages) =>
        prevImages.filter((image) => image.uid !== deleteUploadPicture.uid)
      );

      setFileList((prevFileList) =>
        prevFileList.filter((file) => file.uid !== deleteUploadPicture.uid)
      );

      setAttachments((prevAttachments) =>
        prevAttachments.filter(
          (attachment) => attachment.uid !== deleteUploadPicture.uid
        )
      );
    }

    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    // Add document name to the file object
    file.document_name = documentName;

    const newImage = {
      file_path: URL.createObjectURL(file),
      document_name: documentName, // Save document name with each file
    };
    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const handleDocumentNameChange = (value) => {
    setDocumentName(value);
    setUploadDisabled(!value);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async () => {
    try {
      const technicalDetailValue = technical_detail;
      const uploadDocuments = await Promise.all(
        fileList.map(async (file) => ({
          [file.document_name]: {
            file_path: await fileToBase64(file.originFileObj),
            document_type: file.document_name,
          },
        }))
      );

      const payload = {
        technical_detail: technicalDetailValue,
        upload_documents: uploadDocuments,
      };

      const response = await createUploadImagesModelService(payload);
      if (response.status === 200 && response.success) {
        message.success("Property Pictures successfully uploaded");
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(
        error?.response?.data?.message || "Failed to upload property pictures"
      );
    }
  };

  return (
    <Form layout="vertical" style={{ margin: "10px" }} form={form} onFinish={handleSubmit}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="upload_documents" label="Enter Document Name" required>
            <Select
              placeholder="Please select"
              allowClear
              style={{ marginRight: "8px", width: "100%", flex: 1 }}
              showSearch
              onChange={handleDocumentNameChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {legalDocumentType?.map((legaldocumenttype) => (
                <Option key={legaldocumenttype.value} value={legaldocumenttype.value}>
                  {legaldocumenttype.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item name="file_path" label=" ">
            <Upload
              multiple
              fileList={fileList}
              onChange={handleUploadChange}
              onRemove={handleRemove}
              beforeUpload={handleBeforeUpload} // Handle file upload manually
              showUploadList={false}
              disabled={uploadDisabled} // Hide the file list
            >
              <Button type="primary" icon={<UploadOutlined />} disabled={uploadDisabled}>
                Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ margin: "10px 10px 10px 10px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {image?.map((picture, index) => (
            <div key={index} style={{ margin: "10px", position: "relative" }}>
              <img src={picture.file_path} alt="" style={{ width: "100px", height: "auto" }} />
              <div
                style={{
                  top: 0,
                  right: 0,
                  color: "#E5233DB2",
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "10px",
                }}
                onClick={() => {
                  setDeleteUploadPicture(picture);
                  setOpenUploadModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="image-gallery">
        {images?.map((picture, index) => (
          <div key={index} className="image-container">
            <img src={picture.file_path} alt="" className="image" />
            <h4 style={{ textAlign: "center", color: "#1890FF" }}>{picture.name}</h4>
            <div
              className="delete-icon"
              onClick={() => {
                setDeletePicture(picture);
                setOpenModal(true);
              }}
            >
              <DeleteOutlined />
            </div>
          </div>
        ))}
      </div>

      <Form.Item style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "40px" }}
            disabled={fileList.length === 0}
          >
            Save
          </Button>

          <Button onClick={closeForm} style={{ marginTop: "40px" }}>
            Cancel
          </Button>
        </Space>
      </Form.Item>

      <Modal
        title="Delete Confirmation"
        open={openModal}
        onOk={deletePropertyPictures}
        onCancel={() => setOpenModal(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this property picture?</p>
      </Modal>

      <Modal
        title="Delete Confirmation"
        open={openUploadModal}
        onOk={confirmDelete}
        onCancel={() => setOpenUploadModal(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this uploaded picture?</p>
      </Modal>
    </Form>
  );
};

export default PropertyPicturesForm;
