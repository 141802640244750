import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
} from "antd";
import * as Yup from "yup";
import {
  getEmploymentByIdService,
  listEmploymentNatureService,
  listIndustryervice,
  listOrganizationNatureService,
  updateEmploymentByIdService,
  listBusinessLegalStructure,
  createSalariedService
} from "../services/employmentDetailsServices";
import "moment/locale/en-gb"; // Import the English locale
import moment from "moment";
import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const validationSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required("Organization name is required")
    .min(2, "Organization name must be between 2 and 128 characters")
    .max(128, "Organization name must be between 2 and 128 characters"),
  industry_type: Yup.string().required("Industry type is required"),
  year_of_start: Yup.string()
    .required("Year of start is required"),
  total_experience: Yup.string()
    .required("Total experience is required")
    .test(
      'max-years',
      'Total business experience must be less than 100 years',
      value => parseInt(value, 10) < 100
    ),
  managed_by: Yup.string().required("Managed by is required")
    .min(2, "Managed by must be between 2 and 128 characters")
    .max(128, "Managed by must be between 2 and 128 characters"),
  number_of_employees: Yup.string()
    .required("Number of employees is required")
    .test(
      "is-positive-integer",
      "Number of employees can not be 0",
      (value) => {
        if (!value) return false; // Ensuring value is not empty
        const intValue = parseInt(value);
        return intValue > 0 && Number.isInteger(intValue);
      }
    )
    .test(
      "is-less-than-1000000",
      "Number of employees must be less than 1000000",
      (value) => {
        if (!value) return false; // Ensuring value is not empty
        const intValue = parseInt(value);
        return intValue < 1000000;
      }
    ),
  no_of_branches: Yup.string()
    .required("Number of branches is required")
    .test(
      "is-less-than-100000",
      "Number of branches must be less than 100000",
      (value) => {
        if (!value) return false; // Ensuring value is not empty
        const intValue = parseInt(value);
        return intValue < 100000;
      }
    ),
  gross_income: Yup.string().required("Gross income is required"),
  net_income: Yup.string().required("Net income is required"),
  business_legal_structure: Yup.string().required(
    "Business legal structure is required"
  )
    .min(2, "Business legal structure must be between 2 and 128 characters")
    .max(128, "Business legal structure must be between 2 and 128 characters"),
  nature_of_employment: Yup.string().required(
    "Nature of employment is required"
  ),
  department: Yup.string().required("Department is required")
    .min(2, "Department must be between 2 and 128 characters")
    .max(128, "Department must be between 2 and 128 characters"),
  designation: Yup.string().required("Designation is required")
    .min(2, "Designation must be between 2 and 128 characters")
    .max(128, "Designation must be between 2 and 128 characters"),
  experience_in_current_organization: Yup.string()
    .required("Experience in current organization is required")
    .test('is-valid-experience', 'Experience in current organization is inconsistent with the date of joining', function (value) {
      const { date_of_joining } = this.parent;
      const experienceYears = parseInt(value);
      if (!date_of_joining) return true;
      const joiningYear = dayjs(date_of_joining).year();
      const currentYear = dayjs().year();
      return experienceYears === currentYear - joiningYear;
    }),
  date_of_joining: Yup.string().required("Date of joining is required"),
  organization_nature: Yup.string().required(
    "Organization nature is required"
  ),
});
const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};


const SalariedForm = ({ refreshData, id, open, closeForm, customer_id, selectedEmploymentType }) => {
  const [form] = Form.useForm();
  const [allInvestmentType, setAllInvestmentType] = useState([]);
  const [allInvestment, setAllInvestment] = useState([]);
  const [allEmploymentNature, setAllEmploymentNature] = useState([]);
  const [investedDate] = useState(null);
  const [maturityDate] = useState(null);
  const [businessLegalStructure, setBusinessLegalStructure] = useState([]);
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    if (id) {
      const getEmploymentData = async () => {
        try {
          const response = await getEmploymentByIdService(id);
          let {
            organization_name,
            industry_type,
            year_of_start,
            total_experience,
            managed_by,
            number_of_employees,
            no_of_branches,
            gross_income,
            net_income,
            business_legal_structure,
            nature_of_employment,
            experience_in_current_organization,
            department,
            designation,
            date_of_joining,
            organization_nature,
          } = response?.data;

          year_of_start = dayjs().year(year_of_start).startOf('year');
          date_of_joining = dayjs(date_of_joining, "DD-MM-YYYY");

          form.setFieldsValue({
            organization_name,
            industry_type: industry_type.id,
            year_of_start,
            total_experience,
            managed_by,
            number_of_employees,
            no_of_branches,
            gross_income,
            net_income,
            business_legal_structure,
            nature_of_employment: nature_of_employment.id,
            experience_in_current_organization,
            department,
            designation,
            date_of_joining,
            organization_nature: organization_nature.id,
          });
        } catch (error) {
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      };

      getEmploymentData();
    }
  }, [id, form, investedDate, maturityDate, open]);

  useEffect(() => {
    const getAllInvestmentType = async () => {
      try {
        const response = await listIndustryervice();
        setAllInvestmentType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllInvestmentType();
  }, [id, form, investedDate, maturityDate, open]);

  useEffect(() => {
    const getAllInvestmentT = async () => {
      try {
        const response = await listOrganizationNatureService();
        setAllInvestment(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllEmploymentNature = async () => {
      try {
        const response = await listEmploymentNatureService();
        setAllEmploymentNature(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllBusinessLegalStructure = async () => {
      try {
        const response = await listBusinessLegalStructure();
        setBusinessLegalStructure(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllInvestmentT();
    getAllEmploymentNature();
    getAllBusinessLegalStructure();
  }, [id, form, investedDate, maturityDate, open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    values.number_of_employees = parseInt(values.number_of_employees);
    values.net_income = parseFloat(values.net_income, 10);
    values.gross_income = parseFloat(values.gross_income, 10);
    values.no_of_branches = parseFloat(values.no_of_branches, 10);
    values.year_of_start = values.year_of_start.format("YYYY");
    values.date_of_joining = values.date_of_joining.format(
      "YYYY-MM-DD"
    );

    try {
      if (id) {
        const response = await updateEmploymentByIdService(id, {
          ...values,
        });

        if (response.status === 200 && response.success) {
          message.success("Employment Details successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const formattedValues = [{
          "employment_details": {
            customer_detail: customer_id,
            employment_type: selectedEmploymentType,
            loan_detail: parseInt(loan_id),
          },
          "salaried": {
            ...values
          }
        }];
        const response = await createSalariedService(formattedValues);
        if ((response.status = 200 && response.success)) {
          message.success("Employment details successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }

  };

  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleNumberFields = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };

  const customNetIncomeValidator = (_, value) => {
    const grossIncome = form.getFieldValue("gross_income");
    if (parseFloat(value) > parseFloat(grossIncome)) {
      return Promise.reject("Net Income must be less than or equal to Gross Income");
    }
    return Promise.resolve();
  };
  
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        organization_name: "",
        // industry_type: "",
        year_of_start: "",
        total_experience: "",
        managed_by: "",
        number_of_employees: "",
        no_of_branches: "",
        gross_income: "",
        net_income: "",
        // business_legal_structure: "",
        // nature_of_employment: "",
        experience_in_current_organization: "",
        department: "",
        designation: "",
        date_of_joining: "",
        // organization_nature: "",
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="organization_name"
            label="Organization Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Organization Name"
              onChange={(e) => handleInput(e, "organization_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="industry_type"
            label="Industry Type"
            rules={[yupSync]}
            required
          >
            <Select
              showSearch
              mode="single"
              placeholder="Please Select Industry Type"
              required={true}
              allowClear

              style={{ width: "100%" }}

              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allInvestmentType?.map((investment) => (
                <Select.Option key={investment.id} value={investment.id}>
                  {investment.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="year_of_start"
            label="Year Of Start (In Year)"
            rules={[yupSync]}
            required

          >
            <DatePicker
              picker="year"
              disabledDate={disabledDate}
              placeholder="Select Date"
              // format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>

        <Col span={8}>
          <Form.Item
            name="total_experience"
            label="Total Experience (In Year)"
            rules={[yupSync]}
            required

          >
            <Input
              placeholder="Please Enter Total Experience (In Year)"
              onChange={(e) => handleNumberFields(e, "total_experience")}
              style={{ marginTop: "20px" }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="experience_in_current_organization"
            label={
              <span>
                Experience In Current Organization
                <br />
                (In Year)
              </span>
            }
            rules={[
              { required: true, message: "Experience in current organization is required" },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const sanitizedValue = parseInt(value);
                  const totalExperience = form.getFieldValue("total_experience");

                  if (!totalExperience || isNaN(parseInt(totalExperience))) {
                    return Promise.reject("Please enter a valid total experience first");
                  }

                  if (!isNaN(sanitizedValue) && !isNaN(parseInt(totalExperience))) {
                    return sanitizedValue <= parseInt(totalExperience)
                      ? Promise.resolve()
                      : Promise.reject("Experience in current organization must be less than or equal to total experience");
                  } else {
                    return Promise.reject("Please enter a valid number");
                  }
                },
              }

            ]}
            required
          >
            <Input
              placeholder="Please Enter Your Experience In Current Organization"
              onChange={(e) =>
                handleNumberFields(e, "experience_in_current_organization")
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="number_of_employees"
            label="Number Of Employees"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter No. Of Employees"
              onChange={(e) => handleNumberFields(e, "number_of_employees")}
              style={{ marginTop: '21px' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="no_of_branches"
            label="Number Of Branches"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter No. Of Branches"
              onChange={(e) => handleNumberFields(e, "no_of_branches")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="gross_income"
            label="Gross Income"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Gross Income"
              onChange={(e) => handleNumberFields(e, "gross_income")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="net_income"
            label="Net Income"
            rules={[
              { required: true, message: "Net income is required" },
              { validator: customNetIncomeValidator }
            ]}
            required
          >
            <Input
              placeholder="Please Enter Your Net Income"
              onChange={(e) => handleNumberFields(e, "net_income")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="business_legal_structure"
            label="Business Legal Structure"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {businessLegalStructure.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="nature_of_employment"
            label="Employment Nature"
            rules={[yupSync]}
            required
          >
            <Select
              showSearch
              mode="single"
              placeholder="Please Select Employment Nature"
              required={true}
              allowClear
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allEmploymentNature?.map((employment) => (
                <Select.Option key={employment.id} value={employment.id}>
                  {employment.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="managed_by"
            label="Managed By"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Managed By"
              onChange={(e) => handleInput(e, "managed_by")}
            />
          </Form.Item>

        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="department"
            label="Department"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Department"
              onChange={(e) => handleInput(e, "department")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Designation"
              onChange={(e) => handleInput(e, "designation")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="date_of_joining"
            label="Date Of Joining"
            rules={[yupSync]}
            required
          >
            <DatePicker
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              style={{
                width: "100%",
              }}
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="organization_nature"
            label="Organization Nature"
            rules={[yupSync]}
            required
          >
            <Select
              showSearch
              mode="single"
              placeholder="Please Select Organization Nature"
              required={true}
              allowClear
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allInvestment?.map((investment) => (
                <Select.Option key={investment.id} value={investment.id}>
                  {investment.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default SalariedForm;
