import React, { useEffect, useState } from "react";
import { Tabs, Divider, Space, message } from "antd";
import PropertyDescription from "./PropertyDescription";
import DocumentDetails from "./DocumentDetails";
import MiscellaneousDetails from "./MiscellaneousDetails";
import "../styles/legalValuation.css";

import { getLegalValuationFormData } from "../services/legalValuationService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";

const { TabPane } = Tabs;

const LegalValuation = ({typeCheck }) => {
  const [legalInputData, setLegalInputData] = useState([]);
  const [, setAttachments] = useState([]);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();


  

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const tabs = [
    { label: "Property Description", component: <PropertyDescription loanId={loan_id} stageId={stage_id} /> },
    { label: "Document Details", component: <DocumentDetails loanId={loan_id} stageId={stage_id} /> },
    { label: "Miscellaneous Details", component: <MiscellaneousDetails loanId={loan_id} stageId={stage_id} /> },
  ];

  // const applicant = legalInputData?.customer_detail?.find(
  //   (customer) => customer.customer_type.name === "Applicant"
  // );
  // const coApplicants = legalInputData?.customer_detail?.filter(
  //   (customer) => customer.customer_type.name === "Co-Applicant"
  // );

  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString(undefined, options);
  // };
  // const DisplayDate = ({ date }) => {
  //   return <div>{date ? formatDate(date) : "N/A"}</div>;
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLegalValuationFormData(stage_id);
        setLegalInputData(response.data);
        setAttachments(response.data.initiate_stage_attachment_data);

      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="legal_main_container">
      <div className="legal_valuation_first_container">
        <div>
          <h3 className="legal_heading">Legal Valuation Report</h3>

        </div>
      </div> 
   
      <LoanSummaryBlock data={legalInputData} typeCheck={typeCheck} />

      <div className="legal_tabs_container">
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2
            className="legal_tabs_container_heading"
            style={{ paddingTop: "8px" }}
          >
            Details
          </h2>
        </Space>

        <Divider style={{ margin: "0px" }} />

        <Tabs type="card" style={{ margin: "20px" }}>
          {tabs.map((tab, index) => (
            <TabPane tab={tab.label} key={index}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default LegalValuation;
