import React, { useState } from "react";
import { Tabs } from "antd";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
// import MiscellaneousDetails from '../../miscellaneousDetails/views/MiscellaneousDetails';
import "../../customerDetails/styles/customerDetails.css";
import "../../../styles/loanTabsApplication.css";
import { customerDetailsTabs } from "../../../../../config/schemeConfig";
const { TabPane } = Tabs;

const CustomerDetails = ({ loanDetails }) => {
  const [mode,] = useState("top");
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [activeKey, setActiveKey] = useState("0");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const toggleCustomerDetails = () => {
    setShowCustomerDetails((prev) => !prev);
  };

  const tabs = [];

  
  customerDetailsTabs[loanDetails?.loan_type?.display_name]?.forEach((item) => {
    let componentWithProps;
    if (item.label === "Address Details") {
      componentWithProps = <item.component loanDetails={loanDetails} />;
    } else {
      componentWithProps = <item.component />;
    }

    tabs.push({ label: item.label, component: componentWithProps });
  });
  return (
    <div className="customer_main_container">
      <div
        className="customer_main_heading"
        style={{ borderBottom: !showCustomerDetails && "none" }}
      >
        <div>
          <h2 className="heading_loan_details">Customer Details</h2>
        </div>
        <div className="customer_icon">
          {showCustomerDetails ? (
            <AiOutlineUp
              style={{ cursor: "pointer" }}
              onClick={toggleCustomerDetails}
            />
          ) : (
            <AiOutlineDown
              style={{ cursor: "pointer" }}
              onClick={toggleCustomerDetails}
            />
          )}
        </div>
      </div>
      <div style={{ display: showCustomerDetails ? "block" : "none" }}>
        {/* <div style={{ marginBottom: 8 }}>
          <Radio.Group
            onChange={(e) => setMode(e.target.value)}
            value={mode}
          >
            <Radio.Button value="top">Horizontal</Radio.Button>
            <Radio.Button value="left">Vertical</Radio.Button>
          </Radio.Group>
        </div> */}
        <Tabs tabPosition={mode} type="card" style={{ "margin": "20px" }} onChange={handleTabChange} activeKey={activeKey} defaultActiveKey="1" destroyInactiveTabPane>
          {tabs.map((tab, index) => (
            <TabPane tab={tab.label} key={index}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default CustomerDetails;