import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import {
  getReceiptStatus,
  getPaymentMode,
  getListOfBanks,
  getListOfBankBranch,
  getVastuAccount,
  getListOfLoan,
  getLoanNumberDetail,
  getListOfChargeType,
  getReceiptDetail,
  updateReceipt,
  postReceipt,
} from "../services/receiptService";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import debounce from "lodash.debounce";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";

const validationSchema = Yup.object().shape({
  reciept: Yup.mixed().required("Receipt image is required"),
  loan_detail: Yup.string().required("Loan account number is required"),
  customer_name: Yup.string().required("Customer name is required"),
  customer_mobile_number: Yup.string().required("Mobile number is required"),
  payment_mode: Yup.string().required("Payment mode is required"),
  bank: Yup.string().required("Bank is required"),
  bank_branch: Yup.string().required("Bank branch is required"),
  account_number: Yup.string().required("Account number is required"),
  utr_cheque_number: Yup.string().required("UTR number is required"),
  instrument_date: Yup.string().required("Date is required"),
  amount: Yup.string().required("Amount is required"),
  deposit_amount: Yup.string().required("Deposit amount is required"),
  reciept_date: Yup.string().required("Receipt is required"),
  deposit_date: Yup.string().test(
    "is-today-or-future",
    "Deposit date should be today or a future date",
    function (value) {
      return moment().isSameOrBefore(value, "day");
    }
  ),
  reciept_status: Yup.string().required("Receipt status is required"),
  deposit_account: Yup.string().required("Deposit account is required"),
  remark: Yup.string().required("Remark is required"),
  // charges_type: Yup.string()
  //   .required("Charges type is required"),
  // charges_amount: Yup.string()
  //   .required("Charges aamount is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ReceiptForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allReceiptStatus, setAllReceiptStatus] = useState([]);
  const [allPaymentMode, setAllPaymentMode] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [allBankBranch, setAllBankBranch] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allChargesType, setAllChargesType] = useState([]);
  const [loanList, setLoanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [instrumentDateLabel, setInstrumentDateLabel] = useState("Date");
  const [paymentMode, setPaymentMode] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [initialLoanDetail, setInitialLoanDetail] = useState(null);
  // const [chargesAmount, setChargesAmount] = useState('');
  // const [chargesList, setChargesList] = useState([]);
  const [chargesError, setChargesError] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return Upload.LIST_IGNORE; // Prevents the file from being uploaded
    }
    setImageUrl(URL.createObjectURL(file));
    setImageFile(file);
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setImageLoading(true);
    }
  };

  const CustomUploadButton = ({ loading, imageUrl, isEditMode }) => (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            marginTop: "5px",
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ) : !isEditMode ? (
        <>
          <PlusOutlined />
          <div>Upload Image</div>
        </>
      ) : (
        <>
          <PlusOutlined />
          <div>Upload Image</div>
        </>
      )}
    </div>
  );
  useEffect(() => {
    const getAllReceiptStatus = async () => {
      try {
        const receiptresponse = await getReceiptStatus();
        setAllReceiptStatus(receiptresponse.data);
        const paymentmoderesponse = await getPaymentMode();
        setAllPaymentMode(paymentmoderesponse.data);
        const allbanksresponse = await getListOfBanks();
        setAllBanks(allbanksresponse.data);
        const allbankbranchresponse = await getListOfBankBranch();
        setAllBankBranch(allbankbranchresponse.data);
        const allaccountsresponse = await getVastuAccount();
        setAllAccounts(allaccountsresponse.data);
        const allchargestyperesponse = await getListOfChargeType();
        setAllChargesType(allchargestyperesponse.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllReceiptStatus();
  }, [form]);

  const handleSearch = async (value) => {
    if (value) {
      setLoading(true);
      try {
        const response = await getListOfLoan(value);
        setLoanList(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    } else {
      setLoanList([]);
    }
  };

  const getReceiptData = async () => {
    if (id) {
      try {
        const response = await getReceiptDetail(id);
        const {
          loan_detail,
          customer_name,
          customer_mobile_number,
          payment_mode,
          bank,
          bank_branch,
          account_number,
          utr_cheque_number,
          instrument_date: instrumentDateString,
          amount,
          deposit_amount,
          reciept_date: receiptDateString,
          deposit_date: depositDateString,
          reciept_status,
          deposit_account,
          remark,
          reciept,
          thumb_reciept,
          charges,
        } = response?.data;

        const newCharges =
          charges?.map((charge) => {
            return {
              id: charge?.id,
              charges_type: charge?.charges_type?.id,
              charges_amount: charge?.charges_amount,
            };
          }) || [];

        setInitialLoanDetail(loan_detail);
        console.log(initialLoanDetail);
        const instrument_date = instrumentDateString
          ? dayjs(instrumentDateString, "DD-MM-YYYY")
          : null;
        const reciept_date = dayjs(receiptDateString, "DD-MM-YYYY");
        const deposit_date = dayjs(depositDateString, "DD-MM-YYYY");
        if (reciept && thumb_reciept) {
          setImageUrl({
            original: reciept, // Full-size image
            thumb: thumb_reciept, // Thumbnail image
          });
        }
        setPaymentMode(payment_mode);
        setInstrumentDateLabel(getInstrumentDateLabel(payment_mode));
        form.setFieldsValue({
          loan_detail: loan_detail?.loan_account_number,
          customer_name,
          customer_mobile_number,
          payment_mode,
          bank: bank?.id,
          bank_branch: bank_branch?.id,
          account_number,
          utr_cheque_number,
          instrument_date,
          amount,
          deposit_amount,
          reciept_date,
          deposit_date,
          reciept_status,
          deposit_account: deposit_account?.id,
          remark,
          reciept,
          charges: newCharges,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      getReceiptData();
      setChargesError(false);
    } else {
      form.resetFields();
      setImageUrl(null);
      setImageFile(null);
      setPaymentMode("");
      setLoanList([]);
      setInstrumentDateLabel("Date");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, open, form]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const handleSelect = async (value) => {
    const selectedLoan = loanList.find(
      (loan) => loan.loan_account_number === value
    );

    if (selectedLoan) {
      setSelectedLoanId(selectedLoan?.id);
    }
    try {
      const response = await getLoanNumberDetail(value);
      const { customer_name, customer_mobile_number } = response.data;
      form.setFieldsValue({
        customer_name,
        customer_mobile_number,
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
    setInstrumentDateLabel(getInstrumentDateLabel(value));
  };

  const getInstrumentDateLabel = (paymentMode) => {
    switch (paymentMode) {
      case "NEFT":
        return "NEFT Date";
      case "RTGS":
        return "RTGS Date";
      case "Cheque":
        return "Instrument Date";
      default:
        return "Date";
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // handle charges error
  const validateChargesRule = (_, value) => {
    const amount = parseFloat(form.getFieldValue("amount") || 0);
    const sumOfCharges = value.reduce(
      (sum, charge) => sum + parseFloat(charge.charges_amount || 0),
      0
    );
    if (sumOfCharges !== amount) {
      return Promise.reject(
        new Error("Sum of charges does not match the amount")
      );
    }
    return Promise.resolve();
  };

  const validateCharges = () => {
    const { charges, amount } = form.getFieldsValue(["charges", "amount"]);
    const sumOfCharges = charges?.reduce(
      (sum, charge) => sum + parseFloat(charge?.charges_amount || 0),
      0
    );

    if (sumOfCharges !== parseFloat(amount)) {
      setChargesError(true);
    }
    if (sumOfCharges === parseFloat(amount)) {
      setChargesError(false);
    }
  };

  const handleSubmit = async (values) => {
    const { charges } = values;
    const modifiedCharges = charges.map((charge) => ({
      ...charge,
      charges_amount: Math.round(parseFloat(charge.charges_amount) * 100) / 100,
    }));
    const payload = {
      ...values,
      charges: modifiedCharges,
    };

    payload.reciept_date = values.reciept_date
      ? moment(values.reciept_date).format("YYYY-MM-DD")
      : "";
    // payload.instrument_date = values.instrument_date ? values.instrument_date.format("YYYY-MM-DD") : null;
    payload.deposit_date = values.deposit_date.format("YYYY-MM-DD");

    if (values.payment_mode !== "Cash") {
      payload.instrument_date = values.instrument_date.format("YYYY-MM-DD");
    } else {
      delete payload.instrument_date; // Remove instrument_date if payment mode is Cash
    }

    payload.amount = Number(values.amount) || "";
    payload.charges_amount = Number(values.charges_amount) || "";
    try {
      if (id) {
        const currentLoanDetailId =
          loanList.find(
            (loan) => loan.loan_account_number === values.loan_detail
          )?.id || initialLoanDetail?.id;

        if (currentLoanDetailId !== initialLoanDetail) {
          payload.loan_detail = currentLoanDetailId;
        } else {
          payload.loan_detail = initialLoanDetail;
        }
        const response = await updateReceipt(id, payload);
        if (response.status === 200 && response.success) {
          message.success("Receipt successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        if (imageFile) {
          const base64Image = await fileToBase64(imageFile);
          payload.reciept = base64Image;
        }
        payload.loan_detail = selectedLoanId;
        const response = await postReceipt(payload);
        if (response.status === 200 && response.success) {
          message.success("Receipt successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ charges: [{}] }}
        context={{ id }}
      >
        {id && imageUrl && (
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "left", marginBottom: "50px" }}>
              <RViewerJS
                options={{
                  url: (image) => image.getAttribute("data-original"), // Get the full-size image URL from data-original
                }}
              >
                <img
                  src={imageUrl.thumb} // Thumbnail image
                  data-original={imageUrl.original} // Full-size image URL for preview
                  alt="Receipt Thumbnail"
                  style={{ width: "200px", height: "auto", cursor: "pointer" }} // Adjust the size and cursor style
                />
              </RViewerJS>
            </Col>
          </Row>
        )}

        {!id && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Upload Files"
                name="reciept"
                rules={[yupSync]}
                required
              >
                <Upload
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  <CustomUploadButton
                    loading={imageLoading}
                    imageUrl={imageUrl}
                    isEditMode={isEditMode}
                  />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Loan Number"
              name="loan_detail"
              rules={[yupSync]}
              required
            >
              <Select
                showSearch
                allowClear
                placeholder="Enter Loan Number"
                onSearch={debouncedHandleSearch}
                onSelect={handleSelect}
                loading={loading}
                filterOption={false}
                notFoundContent={loading ? <Spin size="small" /> : null}
                disabled={id}
              >
                {loanList.map((loan) => (
                  <Select.Option key={loan.id} value={loan.loan_account_number}>
                    {loan?.loan_account_number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Customer Name"
              name="customer_name"
              rules={[yupSync]}
              required
            >
              <Input
                disabled
                placeholder="Please enter Customer name"
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Mobile Number"
              name="customer_mobile_number"
              rules={[yupSync]}
              required
            >
              <Input
                disabled
                placeholder="Please enter Mobile Number"
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Payment Receipt Mode"
              name="payment_mode"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                onChange={handlePaymentModeChange}
                allowClear
                showSearch
                disabled={id && true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allPaymentMode?.map((type) => {
                  return (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          {paymentMode !== "Cash" && (
            <>
              <Col span={8}>
                <Form.Item
                  label="Bank Name"
                  name="bank"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    mode="single"
                    placeholder="Please select"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allBanks?.map((bank) => {
                      return (
                        <Select.Option key={bank.id} value={bank.id}>
                          {bank?.display_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Bank Branch Name"
                  name="bank_branch"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    mode="single"
                    placeholder="Please select"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allBankBranch?.map((bankbranch) => {
                      return (
                        <Select.Option
                          key={bankbranch.id}
                          value={bankbranch.id}
                        >
                          {bankbranch?.display_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Account Number"
                  name="account_number"
                  rules={[yupSync]}
                  required
                >
                  <Input
                    type="number"
                    placeholder="Please enter Account number"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="UTR/Cheque Number"
                  name="utr_cheque_number"
                  rules={[yupSync]}
                  required
                >
                  <Input placeholder="Please enter UTR number" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={instrumentDateLabel}
                  name="instrument_date"
                  rules={[yupSync]}
                  required
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={8}>
            <Form.Item label="Amount" name="amount" rules={[yupSync]} required>
              <Input
                placeholder="Please enter Amount"
                type="number"
                onChange={() => validateCharges()}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Deposit Amount"
              name="deposit_amount"
              rules={[
                { required: true, message: "Deposit amount is required" },
                {
                  validator: (_, value) => {
                    const { amount, deposit_amount } = form.getFieldsValue([
                      "amount",
                      "deposit_amount",
                    ]);
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (deposit_amount === 0) {
                      return Promise.reject("EMI amount cannot be zero");
                    }
                    if (deposit_amount < parseFloat(amount)) {
                      return Promise.reject(
                        "Deposit amount must be gerater than or equal to amount"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              required
            >
              <Input
                placeholder="Please enter Deposit amount"
                type="number"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Receipt Date"
              name="reciept_date"
              initialValue={moment()}
              rules={[yupSync]}
              required
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                disabled
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Deposit Date"
              name="deposit_date"
              rules={[
                { required: true, message: "Deposit date is required" },
                ...(!id ? [yupSync] : []), // Apply Yup validation only if id is not present
              ]}
              required
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          {id && (
            <Col span={8}>
              <Form.Item
                label="Receipt Status"
                name="reciept_status"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allReceiptStatus?.map((type) => {
                    return (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={8}>
            <Form.Item
              label="Deposit Account"
              name="deposit_account"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                placeholder="Please select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allAccounts?.map((account) => {
                  return (
                    <Select.Option key={account.id} value={account.id}>
                      {account?.display_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Remark" name="remark" rules={[yupSync]} required>
              <Input.TextArea placeholder="Please enter remark" />
            </Form.Item>
          </Col>
        </Row>

        <Form.List
          name="charges"
          rules={[{ validator: validateChargesRule }]}
          style={{ marginBottom: 0 }}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <div
                  key={key}
                  style={{ position: "relative", marginBottom: "16px" }}
                >
                  {index > 0 && (
                    <Button
                      onClick={() => {
                        remove(name);
                        validateCharges();
                      }}
                      icon={<DeleteOutlined />}
                      style={{
                        color: "red",
                        position: "absolute",
                        right: "201px",
                        zIndex: 1,
                      }}
                    />
                  )}
                  <div style={{ display: "flex" }}>
                    {/* <Row gutter={16}> */}
                    <Col span={8}>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        {...restField}
                        label={index === 0 ? "Charges Type" : ""}
                        name={[name, "charges_type"]}
                        fieldKey={[fieldKey, "charges_type"]}
                        rules={[
                          {
                            required: true,
                            message: "Charges type is required",
                          },
                        ]}
                        required
                      >
                        <Select
                          mode="single"
                          placeholder="Please select"
                          required={true}
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {allChargesType?.map((type) => {
                            return (
                              <Select.Option key={type.id} value={type.id}>
                                {type.display_name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        style={{ marginLeft: "18px", marginBottom: 0 }}
                        {...restField}
                        name={[name, "charges_amount"]}
                        fieldKey={[fieldKey, "charges_amount"]}
                        label={index === 0 ? "Charges Amount" : ""}
                        rules={[
                          {
                            required: true,
                            message: "Charges amount is required",
                          },
                        ]}
                        required
                      >
                        <Input
                          type="number"
                          placeholder="Please enter charges amount"
                          onChange={() => validateCharges()}
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row> */}
                  </div>
                </div>
              ))}
              {chargesError && (
                <div style={{ color: "red" }}>
                  Sum of charges does not match the amount.
                </div>
              )}
              <Row gutter={16} style={{ marginBottom: "20px" }}>
                <Col span={8}>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    style={{ marginTop: "18px" }}
                  >
                    Add Charges
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </>
  );
};

export default ReceiptForm;
