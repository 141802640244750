
import {axiosRequest} from "../../../utils/api/axiosRequest";
export const getUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get("/api/v1/user/get-authenticated-user");
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }