import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  listTypeService,
  listPayableTypeService,
  listStatusService,
  createPDCService,
  listBankService,

} from "../services/PDCService";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
  cheque_number: Yup.string()
    .required("Cheque number is required")
    .matches(/^\d{6}$/, "Cheque number must be exactly 6 digits"),
  account_number: Yup.string()
    .required("Account number is required")
    .matches(/^[0-9]+$/, "Account number must contain only digits")
    .min(9, "Account number must be between 9 and 18 digits")
    .max(18, "Account number must be between 9 and 18 digits"),
  bank_name: Yup.string()
    .required("Bank name type is required")
    .min(3, "Bank name type must be at least 3 characters")
    .max(100, "Bank name type must be at most 100 characters"),
  no_of_checque: Yup.string()
    .required("No. of cheques  is required")
    .matches(/^\d+$/, "No. of cheques can only contain digits"),
  cheque_type: Yup.string().required("Cheque type  is required"),
  payable_type: Yup.string().required("Payable type  is required"),
  status: Yup.string().required("Bank branch is required"),
  micr: Yup.string()
    .required("MICR code is required")
    .matches(/^\d{9}$/, "MICR code must be exactly 9 digits"),
  remark: Yup.string().required("Remark is required"),
  bank: Yup.string().required("Bank is required").min(1, "Bank is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AddCheque = ({
  closeForm,
  open,
  refreshData,
  id,
  autoFillBankAccount,
  selectedRows,
}) => {
  const [form] = Form.useForm();
  const [allType, setAllType] = useState([]);
  const [allPayableType, setAllPayableType] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    if (open) {
      // getCityData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  useEffect(() => {
    const getAllData = async () => {
      try {
        const response = await listTypeService();
        setAllType(response?.data?.choices);
        const payabletyperesponse = await listPayableTypeService();
        setAllPayableType(payabletyperesponse?.data?.choices);
        const statusresponse = await listStatusService();
        setAllStatus(statusresponse?.data?.choices);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/\D/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    if (autoFillBankAccount !== null) {

      form.setFieldsValue({
        "bank": autoFillBankAccount?.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFillBankAccount]);
  useEffect(() => {
    const getAllBanks = async () => {
      try {
        const response = await listBankService(loan_id);
        setAllBanks(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllBanks();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.loan_detail = parseInt(loan_id);
      const response = await createPDCService(values);
      if ((response.status = 200 && response.success)) {
        message.success("Cheque added successfully");
        refreshData();
        closeForm();
      }
      // }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally{  
      setLoading(false)
    }
  };
  const handleSelectedLoans = (value) => { };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        cheque_number: "",
        // payable_type: "",
        account_number: "",
        // status: "",
        // cheque_type: "",
        no_of_checque: "",
      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        {/* {!id || */}
        {/* {selectedRows.length > 0 && ( */}
        <>
          <Col span={8}>
            <Form.Item
              name="cheque_number"
              label="Cheque Number"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please enter cheque number"
                onChange={(e) => handleNumberFields(e, "cheque_number")}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="bank" label="Bank" rules={[yupSync]} required>
              <Select
                mode="single"
                placeholder="Please select"
                onChange={handleSelectedLoans}
                required={true}
                allowClear
                style={{ width: "135%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {allBanks?.map((bank) => {
                  return (
                    <Select.Option key={bank.id} value={bank.id}>
                     {` ${bank?.bank?.display_name} ( Acc. No. ${bank.account_number})`}
                    </Select.Option>
                  );
                 
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* 
          <Col span={8}>
            <Form.Item
              name="bank_name"
              label="Bank Name"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please enter account bank name"
                onChange={(e) => handleInput(e, "bank_name")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="account_number"
              label="Account Number"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please enter your account number"
                onChange={(e) => handleNumberFields(e, "account_number")}
              />
            </Form.Item>
          </Col> */}

          {/* </Row>
            <Row gutter={16} style={{ marginBottom: "16px" }}> */}
          {/* <Col span={8}>
            <Form.Item name="micr" label="MICR Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter MICR Code"
                onChange={(e) => handleNumberFields(e, "micr")}
              />
            </Form.Item>
          </Col> */}

          <Col span={8}>
            <Form.Item
              name="cheque_type"
              label="Type"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allType?.map((type) => {
                  return (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="payable_type"
              label="Payable Type"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allPayableType?.map((type) => {
                  return (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="status" label="Status" rules={[yupSync]} required>
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allStatus?.map((status) => {
                  return (
                    <Select.Option key={status.value} value={status.value}>
                      {status.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="no_of_checque"
              label="No. Of Cheque"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter No. Of Cheque"
                onChange={(e) => handleNumberFields(e, "no_of_checque")}
              />
            </Form.Item>
          </Col>
        </>
        {/* )} */}
        {/* } */}
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default AddCheque;
