import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createPdInitiationDataService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/initiate-stage/pd-initiate", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllUserListService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/user/get-user-list`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getAllPdType = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choices-api/pd-type-choice`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const listAllDocumentCategoryService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/document-category/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };