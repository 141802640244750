import { Button, Col, Divider, Form, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import {
  getAllUsers,
  updateUserAndStatusById,
} from "../services/sendBackService";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  user: Yup.string().required("User is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ForwardForm = ({
  closeForm,
  form,
  id,
  toggleTableRefreshData,
}) => {
  // const { encrypted_loan_id } = useParams();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  // let loan_id = null;
  // try {
  //   loan_id = decrypt(encrypted_loan_id);
  // } catch (error) {
  //   message.error("Invalid Loan ID");
  // }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersresponse = await getAllUsers();
        setUsers(usersresponse.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleTableRefreshData]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (id) {
        const response = await updateUserAndStatusById(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Stage forwarded successfully");
          closeForm();
          form.resetFields();
          toggleTableRefreshData();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false)
    }
  };


  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
      // initialValues={{ user: "" }}
      >
        <div
          style={{
            height: "auto",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "0px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="user"
                  label="Assign To"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    mode="single"
                    placeholder="Please select"
                    required={true}
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    // disabled={true}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users?.map((user) => (
                      <Select.Option key={user?.id} value={user?.id}>
                        {user?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <Divider />
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </>
  );
};

export default ForwardForm;
