import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createAgencyService,
  getAgencyByIdService,
  listAgencyTypeService,
  updateAgencyByIdService,
} from "../services/agencyServices";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, "Code can only contain capital letters and numbers")
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters and spaces")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^[A-Za-z ]+$/, "Display name can only contain letters and spaces")
    .min(2, "Display name must be at least 2 characters")
    .max(128, "Display name must be at max 128 characters")
    .label("Display name"),
  agency_type: Yup.string()
    .required("Agency type is required")
    .min(1, "Agency type is required"),
  email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format"
    ),
  agency_code: Yup.string()
    .required("Agency code is required")
    .matches(
      /^(?=.*[0-9])[0-9a-zA-Z]+$/,
      "Agency code must contain at least one number and only alphanumeric characters"
    )
    .min(2, "Agency code must be between 2 to 8 digits")
    .max(8, "Agency code must be between 2 to 8 digits"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters ")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AgencyForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allAgencyTypes, setAllAgencyTypes] = useState([]);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);

  const getAgencyData = async () => {
    if (id) {
      try {
        const response = await getAgencyByIdService(id);
        const {
          name,
          code,
          display_name,
          agency_type,
          email,
          agency_code,
          mobile,
          phone,
          is_active,
          is_verified,
        } = response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          agency_type: agency_type?.id,
          email,
          agency_code,
          mobile,
          phone,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    if (!values.phone) {
      delete values.phone;
    }
  
    try {
      if (id) {
        const response = await updateAgencyByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Agency successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createAgencyService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Agency successfully created");
          refreshData();
          closeForm();
        }
      }
      // Reset displayNameSynced after successful submit
      setDisplayNameSynced(true);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (open) {
      getAgencyData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };

  useEffect(() => {
    const getAllAgencyTypes = async () => {
      try {
        const response = await listAgencyTypeService();
        setAllAgencyTypes(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllAgencyTypes();
  }, [form]);

  const handleSelectedLoans = (value) => {};

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 10); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };

  const handlePhoneNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 15); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="display_name"
            label="Display Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Code"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        ) : null}

        <Col span={12}>
          <Form.Item
            name="agency_type"
            label="Agency Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              onChange={handleSelectedLoans}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allAgencyTypes?.map((agency_type) => {
                return (
                  <Select.Option key={agency_type.id} value={agency_type.id}>
                    {agency_type.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="email" label="Email" rules={[yupSync]} required>
            <Input placeholder="Please Enter Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="agency_code"
            label="Agency Code"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Agency Code" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="mobile" label="Mobile" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Mobile"
              onChange={(e) => handleNumberFields(e, "mobile")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label="Phone" >
            <Input
              placeholder="Please Enter Phone"
              onChange={(e) => handlePhoneNumberFields(e, "phone")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default AgencyForm;
