import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons" ;
import LocationsForm from "./LocationsForm";
import { deleteLocationsByIdService } from "../services/locationsService";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import LocationsFilterForm from "./LocationsFilterForm";

const Locations = () => {
  const { Title } = Typography;
  const [Locations, setLocations] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  
    // Filter Locations
    const [showFilterForm, setShowFilterForm] = useState(false);
    const [filterFormData, setFilterFormData] = useState({
      filter: false,
      is_active: null,
      is_verified: null,
      zone: [],
    });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedLocations(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      width:200,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      sorter: true,
      render: (display_name) => {
        return display_name !== null ? display_name : " - ";
      },
      width:200,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      render: (code) => {
        return code !== null ? code : " - ";
      },
    },
    {
      title: "Address Line 1",
      dataIndex: "address_line_1",
      sorter: true,
      render: (address_line_1) => {
        return address_line_1 !== null ? address_line_1 : " - ";
      },
    },
    {
      title: "Address Line 2",
      dataIndex: "address_line_2",
      sorter: true,
      render: (address_line_2) => {
        return address_line_2 !== null ? address_line_2 : " - ";
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      render: (email) => {
        return email !== null ? email : " - ";
      },
    },
    {
      title: "Contact Number",
      dataIndex: "contact",
      sorter: true,
      render: (contact) => {
        return contact !== null ? contact : " - ";
      },
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      sorter: true,
      render: (latitude) => {
        return latitude !== null ? latitude : " - ";
      },
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      sorter: true,
      render: (longitude) => {
        return longitude !== null ? longitude : " - ";
      },
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      sorter: true,
      render: (pincode) => {
        return pincode !== null ? pincode.display_name : " - ";
      },
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
      render: (city) => {
        return city !== null ? city.display_name : " - ";
      },
    },
    {
      title: "Taluka",
      dataIndex: "taluka",
      sorter: true,
      render: (taluka) => {
        return taluka !== null ? taluka?.display_name : " - ";
      },
    },
    {
      title: "District",
      dataIndex: "district",
      sorter: true,
      render: (district) => {
        return district !== null ? district.display_name : " - ";
      },
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: true,
      render: (state) => {
        return state !== null ? state.display_name : " - ";
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: true,
      render: (country) => {
        return country !== null ? country?.display_name : " - ";
      },
    },
    {
      title: "Zone",
      dataIndex: "zone",
      sorter: true,
      render: (zone) => {
        return zone !== null ? zone.display_name : " - ";
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by.name;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by.name;
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },
    {
      title: "Actions",
      fixed:"right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => {
                setSelectedLocations(record);
                showDrawer();
              }}
            />

            <DeleteOutlined
              onClick={() => {
                setSelectedLocations(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const deleteLocations = async () => {
    try {
      const response = await deleteLocationsByIdService(selectedLocations?.id);
      if (response.status === 200 && response.success === true) {
        message.success("Locations deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedLocations(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Locations
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setShowFilterForm(true)}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filters
          </Button>

          <Button
            onClick={showDrawer}
            type="primary"
            icon={<PlusCircleOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            Add Locations
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/locations/"
        tableData={Locations}
        setTableData={setLocations}
        tableColumns={columns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />
      <Drawer
       title={<Header title={selectedLocations ? "Edit" : "Add"}  onClose={onClose} name="Locations"/>}
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <LocationsForm
          refreshData={toggleRefreshTableData}
          id={selectedLocations && selectedLocations.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedLocations} 
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Locations"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <LocationsFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteLocations();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedLocations?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default Locations;
