import React, { useEffect, useState } from "react";
import "../../styles/documentDetails.css";
import {
  Button,
  Checkbox,
  Col,
  
  Form,
  Input,
  Row,
  Space,
  message,
} from "antd";
import * as Yup from "yup";
import "../../styles/propertyDescription.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LegalPropertyDescriptionForm from "./LegalPropertyDescriptionForm";
import LegalDocumentInputForm from "./LegalDocumentInputForm";
import LegalUploadInputForm from "./LegalUploadInputForm";
import OtherProvisionInputForm from "./OtherProvisionInputForm";
import {
  createLegalInputFormData,
  getLegalInputFormData,
} from "../../services/legalInputFormService";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import ConclusionsInputForm from "./ConclusionsInputForm";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
// import RViewerJS from "viewerjs-react";
// import pdf from "../../assets/pdf.png";
import LoanSummaryBlock from "../../../../utils/loanSummaryBlock/LoanSummaryBlock";

const validationSchema = Yup.object().shape({
  transaction_type: Yup.string()
    .trim()
    .required("Transaction Type is required")
    .matches(/^(?!\s)[A-Za-z0-9\s\d\W]*$/, "Transaction Type  is required")
    .label("Transaction Type "),
  present_owner: Yup.string()
    .required("Property owner  is required")
    .label("Property owner"),
  address: Yup.string()
    .trim()
    .required("Property Address is required")
    .matches(/^(?!\s)[A-Za-z0-9\s\d\W]*$/, "Property Address is required")
    .label("Property Address"),
  otherprovision_present_owner: Yup.string()
    .required("Property owner  is required")
    .label("Property owner"),
  unit_type: Yup.string().required("Units is required").label("Units"),
  area_of_property: Yup.string()
    .matches(/^\d+$/, "Area of property must contain only digits") // Only allow digits
    .required("Area of property is required")
    .label("Area of property"),
  description_east: Yup.string()
    .trim()
    .required("On East Side is required")
    .label("On East Side"),
  description_west: Yup.string()
    .trim()
    .required("On West Side is required")
    .label("On West Side"),
  description_north: Yup.string()
    .trim()
    .required("On North Side is required")
    .label("On North Side"),
  description_south: Yup.string()
    .trim()
    .required("On South Side is required")
    .label("On South Side"),
  address1: Yup.string()
    .trim()
    .required("Address Line 1 is required")
    .label("Address Line 1"),
  address2: Yup.string()
    .trim()
    .required("Address Line 2 is required")
    .label("Address Line 2"),
  landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be at least 2 characters long.")
    .max(225, "Landmark must be at most 225 characters long.")
    .label("Landmark"),
  city: Yup.string().required("City is required").label("City"),
  taluka: Yup.string().required("Taluka is required").label("Taluka"),
  district: Yup.string().required("District is required").label("District"),
  pincode: Yup.string().required("Pincode is required").label("Pincode"),
  state: Yup.string().required("State is required").label("State"),
  country: Yup.string().required("Country is required").label("Country"),
  first_owner: Yup.string()
    .trim()
    .required("First owner is required")
    .matches(
      /^[A-Za-z][A-Za-z ]*$/,
      "First owner can only contain letters and spaces and cannot start with a space"
    )
    .min(2, "First owner must be at least 2 characters")
    .max(32, "First owner must be at max 32 characters")
    .label("First owner"),
  advocate_name: Yup.string()
    .required("Advocate name is required")
    .min(2, "Advocate name must be at least 2 characters")
    .max(32, "Advocate name must be at max 32 characters")
    .label("Advocate name"),
  market_clear: Yup.string().required("Market clear status is required"),
  chain_of_events: Yup.string().required("Chain of events is required"),
  overall_status: Yup.string().required("Overall Status is required"),
  conclusion: Yup.string().required("Overall remark is required"),
  declaration: Yup.boolean()
    .required("You must accept the declaration")
    .test(
      "is-true",
      "You must accept the declaration",
      (value) => value === true
    ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};
const LegalInputForm = ({ typeCheck }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [enteredDataScrutiny, setEnteredDataScrutiny] = useState([]);
  const [legalInputData, setLegalInputData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [enteredDataPreDisbursement, setEnteredDataPreDisbursement] = useState(
    []
  );
  const [enteredDataPostDisbursement, setEnteredDataPostDisbursement] =
    useState([]);
  const [, setDocumentUpload] = useState([]);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [loading, setLoading] = useState(false);

  
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const customDateValidator = (_, value) => {
    const today = moment().startOf("day");

    if (!value) {
      return Promise.reject("Date is required");
    }

    if (value.isAfter(today, "day")) {
      return Promise.reject("Date can not be in the future date.");
    }
    return Promise.resolve();
  };

  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString(undefined, options);
  // };

  // const DisplayDate = ({ date }) => {
  //   return <div>{date ? formatDate(date) : "N/A"}</div>;
  // };

 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLegalInputFormData(stage_id);
        setLegalInputData(response.data);
        setAttachments(response.data.initiate_stage_attachment_data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    values.date_of_report = values.date_of_report.format("YYYY-MM-DD");
    const newValues = {
      ...values,
      credit_assessment_detail: legalInputData?.credit_assessment_data?.id,
      loan_detail: loan_id,
      scrutiny_documents: enteredDataScrutiny,
      pre_disbursement_documents: enteredDataPreDisbursement,
      post_disbursement_documents: enteredDataPostDisbursement,
    };
    try {
      const response = await createLegalInputFormData(newValues);
      if (response.status === 200 && response?.success) {
        message.success("Legal Input Form successfully created");
        form.resetFields();
        navigate(`/loandetails/${encrypted_loan_id}`);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }finally{  
      setLoading(false)
    }
  };

  return (
    <div
      style={{ padding: "10px 10px 10px 10px", }}
    >
      <div className="legal_valuation_first_container">
        <div>
          <h3 className="legal_heading">Legal</h3>
        </div>


      </div>
    <LoanSummaryBlock data={legalInputData} attachments={attachments}  typeCheck={typeCheck}/>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <LegalPropertyDescriptionForm
          yupSync={yupSync}
          customDateValidator={customDateValidator}
          form={form}
          loanId={loan_id}
          legalInputData={legalInputData}
        />
        <LegalDocumentInputForm
          form={form}
          enteredDataScrutiny={enteredDataScrutiny}
          setEnteredDataScrutiny={setEnteredDataScrutiny}
          enteredDataPreDisbursement={enteredDataPreDisbursement}
          setEnteredDataPreDisbursement={setEnteredDataPreDisbursement}
          enteredDataPostDisbursement={enteredDataPostDisbursement}
          setEnteredDataPostDisbursement={setEnteredDataPostDisbursement}
        />
        <OtherProvisionInputForm yupSync={yupSync} form={form} />

        <ConclusionsInputForm yupSync={yupSync} form={form} />

        <div style={{ border: "1px solid #E4E7EC", marginTop: "20px" }}>
          <div className="property_description_heading">
            <div className="property_description_data">Advocate Details</div>
            <div style={{ display: "flex" }}>
              <div
                style={{ margin: "10px 10px 10px 10px" }}
              // onClick={toggleCollapsepropertydescription}
              >
                {/* {collapsed1 ? <DownOutlined /> : <UpOutlined />} */}
              </div>
            </div>
          </div>

          <div style={{ margin: "10px" }}>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="advocate_name"
                  label="Advocate Name"
                  rules={[yupSync]}
                  required
                >
                  <Input
                    placeholder="Please Enter Advocate Name"
                    onChange={(e) => handleInput(e, "advocate_name")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        <div style={{ border: "1px solid #E4E7EC", marginTop: "20px" }}>
          <div className="property_description_heading">
            <div className="property_description_data">
              Valuer Certification
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ margin: "10px 10px 10px 10px" }}
              // onClick={toggleCollapsepropertydescription}
              >
                {/* {collapsed1 ? <DownOutlined /> : <UpOutlined />} */}
              </div>
            </div>
          </div>

          <div style={{ margin: "10px" }}>
            <div className="">

              <div style={{ marginTop: "5px" }}>
                <Form.Item
                  label={<span style={{ color: "#00000080" }}>Remark</span>}
                  name="declaration"
                  valuePropName="checked"
                  getValueProps={(value) => ({ checked: !!value })}
                  getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
                  rules={[yupSync]}
                  required
                >
                  <Checkbox style={{ marginRight: "8px" }}>
                    I Hereby Declare that The Information in this Report is True
                    and Correct to the best of my Knowledge and Belief.
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <LegalUploadInputForm
          form={form}
          setDocumentUpload={setDocumentUpload}
        />

        <Space
          direction="horizontal"
          align="center"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default LegalInputForm;
