import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, message } from "antd";
import vastu_logo from "../assets/vastulogo.png";
import google_icon from "../assets/google_icon.png";
import login_image from "../assets/login_image.png";
import { useGoogleLogin } from "@react-oauth/google";
import baseConfig from "../../../config/baseConfig";
import "../styles/login.css";
import { loginUserService } from "../services/loginService";
import axios from "axios";
import { Link } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set the document title when the component mounts
    document.title = "Vastu LMS | Login";

    // Clean up by resetting the document title when the component unmounts
    return () => {
      document.title = "LMS"; // Set the default title or your desired default title
    };
  }, []);

  const onFinish = async (values) => {
    try {
      const response = await loginUserService(values);
      localStorage.setItem("access", response?.data?.access);
      localStorage.setItem("refresh", response?.data?.refresh);
      message.success("Logged In successfully");
      navigate("/");
    } catch (error) {
      if (!error.response?.data?.message) {
        message.error("Sorry, Please contact support..!");
      } else {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleGoogleLogin = async (respone) => {
    try {
      const response = await axios.get(
        `${baseConfig.serverurl}/api/v1/userauth/auth/login/google/?token=${respone?.access_token}`
      );
      localStorage.setItem("access", response?.data?.data?.access);
      localStorage.setItem("refresh", response?.data?.data?.refresh);
      message.success("Logged In successfully");
      navigate("/");
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Problem logging in, please contact support..!"
      );
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  });

  return (
    <div className="login_form_main_container">
      <div className="form_control_container">
        <div className="form_section">
          <Form
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <div className="form_content">
              <div className="header_section">
                <img src={vastu_logo} alt="Logo" />
                <div className="welcome_header_section">
                  <h2>Welcome back</h2>
                  <p className="welcome_back_heading">
                    Welcome back! Please enter your details.
                  </p>
                </div>
              </div>
              <div className="input_section">
                <div className="form_input_section">
                  <div className="input_wrapper">
                    <label>Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Email is required",
                        },
                        {
                          type: "email",
                          message: "Invalid email format",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          height: "38px",
                        }}
                        placeholder="Enter your email"
                      />
                    </Form.Item>
                  </div>
                  <div className="input_wrapper">
                    <label>Password</label>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{
                          height: "38px",
                        }}
                        placeholder="Enter your password"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="login_singup_google">
                  <div className="form-check-and-button">
                    <div className="centered-content">
                      <Form.Item name="remember">
                        <Checkbox>Remember me for 30 days</Checkbox>
                      </Form.Item>
                      {/* <a href="/">Forgot Password</a> */}
                      <Link to="/forget-password">Forgot Password</Link>
                    </div>
                  </div>

                  <div className="form_action_section">
                    <Button
                      style={{
                        padding: "10px 18px",
                        height: "44px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                      classNames="sign_in_button"
                      type="primary"
                      htmlType="submit"
                      className="w-100 mb-2"
                    >
                      Sign in
                    </Button>
                    {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                    <Button
                      onClick={() => googleLogin()}
                      style={{
                        marginTop: "16px",
                        height: "44px",
                      }}
                      variant="light"
                      className="sign_with_google_btn w-100 "
                    >
                      <img src={google_icon} alt="Google Icon" />
                      Sign in with Google
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div className="footer_section">
          <p>© 2022 Vastu Housing Finance Corporation ltd</p>
        </div>
      </div>

      <div className="image_control_container">
        <img
          className="right_side_heroImage_wrapper"
          src={login_image}
          alt="Login"
        />
      </div>
    </div>
  );
};

export default Login;
