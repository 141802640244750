import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const getMasterAgentListById  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/agent/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
 
  

export const getMasterAgencyListById  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/master/agency/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getAllAgentService = (code) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/master/agent/agency-agent-list?code=${code}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getRCUProcessListById  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/loan/choices-api/get-rcu-process`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
   

  export const getRCUProcessDetailsById  = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/loan/initiate-stage/get-data-for-rcu-initiate/${loan_id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const postRCUInitiate = async (data) => {
    try {
      const response = await axiosRequest.post(`/api/v1/loan/initiate-stage/rcu-initiate`, data);
      return response.data;
    } catch (error) {
      console.error("API request failed:", error);
      throw error;  // rethrow the error to be handled by the calling function
    }
  };
 
  export const getRCUoptions = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(
                `/api/v1/loan/choices-api/rcu-type-wise-check`
            );
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
  };