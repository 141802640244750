import React from 'react';
import { Table } from "antd";
import "../../partDisbursedInitiate/styles/partDisbursed.css";

const PaymentDetails = () => {
  const paymentData = [
    {
      hash: "1",
      loan_id: "1",
      branch: "pune",
      recepit_type: "pay",
      instrument_type: "installment",
      inst_date: "12/03/2022",
      receipt_date: "11/9/2021",
      inst_no: "1",
      inst_amount: "10",
      bank_acc_no: "889977667799",
      status: "pending",
      inst_status: "initiat",
      reason: "-",
      branch_id: "2",
    },
    {
      hash: "2",
      loan_id: "2",
      branch: "mumbai",
      recepit_type: "pay",
      instrument_type: "installment",
      inst_date: "14/04/2022",
      receipt_date: "12/10/2021",
      inst_no: "2",
      inst_amount: "20",
      bank_acc_no: "778866554433",
      status: "completed",
      inst_status: "processed",
      reason: "none",
      branch_id: "3",
    },
    
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "hash",
      key: "hash",
      render: (text) => text || "-",
    },
    {
      title: "Loan Id",
      dataIndex: "loan_id",
      key: "loan_id",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Recepit type",
      dataIndex: "recepit_type",
      key: "recepit_type",
    },
    {
      title: "Instrument type",
      dataIndex: "instrument_type",
      key: "instrument_type",
    },
    {
      title: "Inst Date",
      dataIndex: "inst_date",
      key: "inst_date",
    },
    {
      title: "Receipt Date",
      dataIndex: "receipt_date",
      key: "receipt_date",
    },
    {
      title: "Inst No",
      dataIndex: "inst_no",
      key: "inst_no",
    },
    {
      title: "Inst Amount",
      dataIndex: "inst_amount",
      key: "inst_amount",
    },
    {
      title: "Bank Acc No",
      dataIndex: "bank_acc_no",
      key: "bank_acc_no",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Inst Status",
      dataIndex: "inst_status",
      key: "inst_status",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Branch Id",
      dataIndex: "branch_id",
      key: "branch_id",
    },
  ];

  return (
    <div>
      <div className="part_disbursed_table">
        <div className="part_disbursed_heading">
          <div className="part_disbursed_data">
            <h3>Payment Details Last 3 Months</h3>
          </div>
        </div>
        <div className="table_content">
          <Table
            dataSource={paymentData}
            columns={columns}
            pagination={false}
            rowKey="hash"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
