import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getMonthlyInflowAndOutflowById = (customer_id, loan_detail_id,bank_detail_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/account-aggregator/get-month-wise?customer_id=${customer_id}&loan_detail_id=${loan_detail_id}&bank_detail_id=${bank_detail_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
