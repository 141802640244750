import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Space, message, DatePicker } from "antd";
import * as Yup from "yup";
import dayjs from "dayjs"; 
import { getPropertyTechnicalDataByIdService, updatePropertyDetailsByIdService } from "../services/valuationDetailsServices";

const validationSchema = Yup.object().shape({
    date_of_visit: Yup.date()
        .required("Date of Visit is required")
        .max(dayjs().endOf('day'), "Date of Visit cannot be in the future")
        .label("Date of Visit"),
    date_of_valuation: Yup.date()
        .required("Date of Report Submission is required")
        .label("Date of Report Submission"),
    engineer_name: Yup.string()
        .required("Name of Engineer Visited the property is required")
        .label("Name of Engineer Visited the property"),
    place: Yup.string()
        .required("Place is required")
        .label("Place")
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const ValueCertificationDetailsForm = ({ refreshData, id, open, closeForm, stage_id }) => {
    const [form] = Form.useForm();
    const [technicalid, setTechnicalid] = useState(); 

    const getPersonalData = async () => {
        try {
            const response = await getPropertyTechnicalDataByIdService(stage_id);
            setTechnicalid(response.data.technical_detail[0].id);

            let {
                date_of_visit,
                date_of_valuation,
                engineer_name,
                place
            } = response?.data?.technical_detail[0];

            // Ensure the dates are parsed correctly as dayjs objects in the correct format
            date_of_visit = dayjs(date_of_visit, "DD-MM-YYYY");
            date_of_valuation = dayjs(date_of_valuation, "DD-MM-YYYY");

            form?.setFieldsValue({
                date_of_visit,
                date_of_valuation,
                engineer_name,
                place
            });
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        if (open) {
            getPersonalData();
        } else {
            form?.resetFields();
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id, form, stage_id]);

    const handleSubmit = async (values) => {
        values.date_of_visit = values.date_of_visit.format("YYYY-MM-DD");
        values.date_of_valuation = values.date_of_valuation.format("YYYY-MM-DD");

        const payload = {
            ...values,
        };

        try {
            const response = await updatePropertyDetailsByIdService(technicalid, payload);
            if (response?.status === 200 && response?.success) {
                message.success("Valuer Certification updated successfully");
                refreshData();
                closeForm();
            } else {
                message.error("Failed to update Valuer Certification");
            }
        } catch (error) {
            message.error(error.response?.data?.message || "An error occurred while updating personal details");
        }
    };

    const handleAlphaFields = (e, field) => {
        let newValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
        form.setFieldsValue({ [field]: newValue });
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                date_of_visit: "",
                report_date: "",
                engineer_name: "",
                place: ""
            }}
        >
            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="date_of_visit" label="Date of Visit" rules={[yupSync]} required>
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%" }}
                            placeholder="Select Date"
                        />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="date_of_valuation" label="Date of report submission" rules={[yupSync]} required>
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%" }}
                            placeholder="Select Date"
                        />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="engineer_name" label="Name of Engineer Visited the property" rules={[yupSync]} required>
                        <Input
                            placeholder="Please Enter Name of Engineer Visited the property"
                            onChange={(e) => handleAlphaFields(e, "engineer_name")}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Form.Item name="place" label="Place" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Place" onChange={(e) => handleAlphaFields(e, "place")} />
                    </Form.Item>
                </Col>
            </Row>

            <Space direction="horizontal" align="center" style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button type="primary" htmlType="submit">Submit</Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default ValueCertificationDetailsForm;
