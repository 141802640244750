import { axiosRequest } from "../../../utils/api/axiosRequest"


export const updateOccupationDetails = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-business-occupation/${id}`,data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const updateBusinessPremisesDetails = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-business-premises/${id}`,data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const updateOverallStatusOvDetails = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-customer-detail/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}