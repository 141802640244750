import React, { useEffect, useState } from 'react';
import { getUser } from "../services/caseHealthService";
import { useDispatch } from "react-redux";
import { setUser } from '../../../../redux/userSlice';
import { message } from 'antd';
import avtar from '../assets/image/Avatarr.png'
import approved from '../assets/image/approved .png'
import Bank from '../assets/image/bank@img.png';
import Icon from '../assets/image/icon.png';
import { Progress } from 'antd';
import { Table, Button } from 'antd';
import '../styles/caseHealthSummary.css';
import { getBankAccountDetailsByIdService, getCustomerDetailsByIdService, getkarzaDocumentDetailsByIdService } from "../services/caseHealthService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import GoogleMapLocation from "../../../../utils/googleMapLocation/GoogleMapLocation";
import { Chart } from "react-google-charts";


const CaseHealthSummary = ({ loan_id }) => {
    const [bankAccountData, setBankAccountData] = useState(null);
    const [customerData, setCustomerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);          // Active loans & FOIR in table store
    const [loanDetails, setLoanDetails] = useState([]);
    const [branchLatitude, setBranchLatitude] = useState();     //  Branch Latitude store
    const [branchLongitude, setBranchLongitude] = useState();   //  Branch Longitude store
    const [documentDetails, setDocumentDetails] = useState();     //  karza customer document store
    const [visibleCustomerId, setVisibleCustomerId] = useState(null);
    


    // Case Health Summary Details Service
    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const { data } = await getCustomerDetailsByIdService(loan_id);
                setCustomerData(data);

                // Set loan details
                setLoanDetails(data.existing_loan_data); // Assuming this is the structure from your API

                // Table data for financial details
                const financialTableData = [
                    {
                        key: '1',
                        column1: 'Appraised Net Monthly Income',
                        column2: `₹ ${data.appraised_monthly_income.toLocaleString('en-IN', { minimumFractionDigits: 2 })}`,
                    },
                    {
                        key: '2',
                        column1: 'FOIR (As Per Policy)',
                        column2: `${data.foir}%`,
                    },
                    {
                        key: '3',
                        column1: 'Max EMI',
                        column2: `₹ ${data.max_emi.toLocaleString('en-IN', { minimumFractionDigits: 2 })}`,
                    },
                    {
                        key: '4',
                        column1: 'EMI Factor',
                        column2: `₹ ${data.emi_factor.toLocaleString('en-IN', { minimumFractionDigits: 2 })}`,
                    },
                    {
                        key: '5',
                        column1: 'Eligibility',
                        column2: `₹ ${data.eligibility.toLocaleString('en-IN', { minimumFractionDigits: 2 })}`,
                    },
                    {
                        key: '6',
                        column1: 'EMI',
                        column2: `₹ ${data.emi.toLocaleString('en-IN', { minimumFractionDigits: 2 })}`,
                    },
                    {
                        key: '7',
                        column1: 'Actual LTV',
                        column2: `${data.actual_ltv}%`,
                    },
                    {
                        key: '8',
                        column1: 'Actual FOIR',
                        column2: data.actual_foir || 'N/A',
                    },
                ];
                setTableData(financialTableData);
                setBranchLatitude(data.branch_lat);
                setBranchLongitude(data.branch_long);

            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            }
        };

        fetchCustomerData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loan_id]);


    // Bank Account Details Service
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getBankAccountDetailsByIdService(loan_id);
                setBankAccountData(response.data.results);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchData();
    }, [loan_id]);

    // Karza Customer Document Details Service
    

    useEffect(() => {
        const fetchDocumentData = async () => {
            try {
                const response = await getkarzaDocumentDetailsByIdService(loan_id);
                console.log("API Response:", response.data); // Log API response

                const formattedData = response.data.customers.flatMap((customer, index) =>
                    customer.documents.map((document) => ({
                        no: index + 1,
                        facts: document.karza_status[0]?.fact || 'N/A',
                        customerName: customer.customer_name,
                        status: document.karza_status[0]?.status || 'N/A',
                        result: document.karza_status[0]?.status || 'N/A',
                        verifiedOn: document.karza_status[0]?.created_at || 'N/A',
                        path: document.path,
                    }))
                );

                console.log("Formatted Data:", formattedData); // Log formatted data

                setDocumentDetails(formattedData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                message.error(
                    `Error: ${error.response?.status || ''} - ${error.response?.data?.message || 'Something went wrong'}`
                );
            }
        };

        fetchDocumentData();
    }, [loan_id]);


    // Karza customer documnet table columns
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: 80,
        },
        {
            title: 'Facts',
            dataIndex: 'facts',
            key: 'facts',
            width: 200,
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 120,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            width: 120,
        },
        {
            title: 'Verified on',
            dataIndex: 'verifiedOn',
            key: 'verifiedOn',
            width: 150,
        },
        {
            title: 'View',
            key: 'view',
            render: (text, record) => (
                <Button type="link" onClick={() => handleView(record.path)}>
                    View
                </Button>
            ),
            width: 100,
        },
    ];

    // Active loans & FOIR in table columns
    const columns1 = [
        {
            dataIndex: 'column1',
            key: 'column1',
            width: 300,
        },
        {
            dataIndex: 'column2',
            key: 'column2',
            width: 280,
        },
    ];

    // Karza customer documnet table in view button functionality
    const handleView = (path) => {
        window.open(path, '_blank');
    };

    //Bank details in table Arrow button and Added button Fuctionality
    const handleArrowClick = (customerId) => {
        setVisibleCustomerId(prevId => (prevId === customerId ? null : customerId));
    };

    // CanvasJSChart pieChart code
    
    const dispatch = useDispatch();
    const userRequest = async () => {
        try {
            const response = await getUser();

            if (response?.success === true) {
                const userData = response?.data;
                dispatch(setUser(userData));
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        userRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const data = [
        ["Customer Type", "Percentage"],
        ["Applicant", 5],
        ["Co-Applicant", 31],
        ["Co-Applicant-2", 40],
        ["Co-Applicant-3", 17],
        ["Personal Guarantor", 7],
      ];
    
      const options = {
        title: "Customer Satisfaction",
        pieHole: 0.4, 
        is3D: false,
        pieSliceText: "percentage",
        legend: { position: "right" },
      };
    return (
        <div className='main_container'>
            <div className='case_Health_Summary_decision_summary'>
                <div className='decision_summary'>
                    <div className='summary'>
                        <div><h1 className='summary_heading'>Decision Summary</h1></div>
                        <div>
                            <h3 className='credit_heading'>Overall Creditworthiness</h3>
                            <div className='colourbar'>
                                <div className='red_color'></div>
                                <div className='orange_color'></div>
                                <div className='yellow_color'></div>
                                <div className='green_color'><p className='text_confident'>Confident</p></div>
                                <div className='light_green_color'></div>
                            </div>
                        </div>
                    </div>


                    <div className='summary'>
                        <p className='approve_decision'>Final Approver’s Decision</p>
                        <h3 className='button_refer'>Refer</h3>
                        <div className='logo'>
                            <div className='image_avtar'><img src={avtar} alt="" /></div>
                            <div><h3 className='decision_mekar'>Decision Maker:</h3><h3 className='name'> Adarsh Shah</h3>
                            </div>
                        </div>
                        <div>
                            <p className='date'>Date: 10 May 2024   12:39 PM</p>
                        </div>
                    </div>

                    <div className='summary'>
                        <p className='approve_decision'>Final Approver’s Decision</p>
                        <h3 className='button_refer'>Refer</h3>
                        <div className='logo'>
                            <div className='image_avtar'><img src={avtar} alt="" /></div>
                            <div><h3 className='decision_mekar'>Decision Maker:</h3><h3 className='name'> Adarsh Shah</h3>
                            </div>
                        </div>
                        <div>
                            <p className='date'>Date: 10 May 2024   12:39 PM</p>
                        </div>
                    </div>
                    <div className='approved_image'><img src={approved} alt="" /></div>
                </div>
            </div>


            <div className='customer_container'>

                <div className='customer_info_div'>
                    <h3 className='customer_heading'> Customers</h3>

                    <div>
                        <div className='customer_details'>
                            <div className='customer_box'></div>
                            <div className='details_info' style={{ marginTop: "13px", marginLeft: '10px' }}>
                                <h3>No. of Applicants Added</h3>
                            </div>
                            <div className='number'>{customerData?.total_number_of_customer || 0}</div>
                        </div>
                    </div>

                    <div className='customer_details'>
                        <div className='customer_box'></div>
                        <div className='details_info' style={{ marginLeft: '10px', marginTop: "13px" }}>
                            <h3>No. of Women Applicants</h3>
                        </div>
                        <div className='number' style={{ color: " #F93C65" }}>
                            {customerData?.number_of_women_applicants || 0}
                        </div>
                    </div>

                    <div className='customer_details'>
                        <div className='customer_box'></div>
                        <div className='details_info' style={{ marginLeft: '10px', marginTop: "13px" }}>
                            <h3>Average Bureau Scores of All Customers</h3>
                        </div>
                        <div className='number' style={{ marginLeft: '10px', color: " #52C41A" }}>
                            {customerData?.avg_cibil_score || 0}
                        </div>
                    </div>

                    <div className='customer_details'>
                        <div className='customer_box'></div>
                        <div className='details_info' style={{ marginLeft: '10px', marginTop: "13px" }}>
                            <h3>No. of References Added</h3>
                        </div>
                        <div className='number'>{customerData?.number_of_references_added || 0}</div>
                    </div>
                </div>
                {/* <div className='customer_info_div'>
                    {customerData ? (
                        <>
                            <div className='location_container'>
                                {customerData && branchLatitude !== null && branchLongitude !== null && (
                                    <GoogleMapLocation

                                        className='location'
                                        latitude={customerData.customer_lat}
                                        longitude={customerData.customer_long}
                                        branchLatitude={branchLatitude}
                                        branchLongitude={branchLongitude}
                                    />
                                )}
                            </div>

                            <div className='location_details'>
                                <div>
                                    <p className='sale_value' style={{ marginLeft: "1.5rem", marginTop: "2rem" }}>
                                        Forced Sale Value (INR) at 75%
                                    </p>
                                    <h3 className='amount' style={{ marginLeft: "1.5rem" }}>
                                        ₹ {customerData.requested_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 }) || '0.00'}
                                    </h3>
                                </div>
                                <div>
                                    <p className='sale_value' style={{ marginRight: "5rem", marginTop: "2rem" }}>
                                        Loan Amount
                                    </p>
                                    <h3 className='amount' style={{ marginRight: "5rem" }}>
                                        ₹ {customerData.technical_valuation_of_properrty?.toLocaleString('en-IN', { minimumFractionDigits: 2 }) || '0.00'}
                                    </h3>
                                </div>
                            </div>

                            <div className='percentage'>
                                <p className='sale_value' style={{ marginTop: "2rem" }}>LTV Calculated</p>
                                <h1 className='num'>{customerData.ltv ? `${customerData.ltv}%` : 'N/A'}</h1>
                            </div>
                        </>
                    ) : (
                        <p>Loading customer data...</p>
                    )}
                </div> */}

                <div className='customer_info_div'>
                    {customerData ? (
                        <>
                            <div className='location_container'>
                                {branchLatitude !== null && branchLongitude !== null && (
                                    <GoogleMapLocation
                                        className='location'
                                        latitude={customerData?.customer_lat}
                                        longitude={customerData?.customer_long}
                                        branchLatitude={branchLatitude}
                                        branchLongitude={branchLongitude}
                                    />
                                )}
                            </div>

                            <div className='location_details'>
                                <div>
                                    <p className='sale_value' style={{ marginLeft: "1.5rem", marginTop: "2rem" }}>
                                        Forced Sale Value (INR) at 75%
                                    </p>
                                    <h3 className='amount' style={{ marginLeft: "1.5rem" }}>
                                        ₹ {customerData.requested_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 }) || '0.00'}
                                    </h3>
                                </div>
                                <div>
                                    <p className='sale_value' style={{ marginRight: "5rem", marginTop: "2rem" }}>
                                        Loan Amount
                                    </p>
                                    <h3 className='amount' style={{ marginRight: "5rem" }}>
                                        ₹ {customerData?.technical_valuation_of_properrty?.toLocaleString('en-IN', { minimumFractionDigits: 2 }) || '0.00'}
                                    </h3>
                                </div>
                            </div>

                            <div className='percentage'>
                                <p className='sale_value' style={{ marginTop: "2rem" }}>LTV Calculated</p>
                                <h1 className='num'>{customerData?.ltv ? `${customerData?.ltv}%` : 'N/A'}</h1>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='map_div '>
                                <GoogleMapLocation
                                    className='location'
                                    style={{ height: '30px' }}
                                    latitude={19.9975}
                                    longitude={73.7898}
                                    branchLatitude={branchLatitude}
                                    branchLongitude={branchLongitude}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className='account_main_container'>
                <div className='pichart'>
                    <h1 className='piechart_ammount'>₹ 5,192,00.00</h1>
                    <p className='piechart_details'>Total Income of All Customers</p>
                    <hr className='account_section_divider' />

                    <div>
                        {/* <CanvasJSChart options={options} /> */}
                        <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>

                </div>

                <div className='bank_account_details'>
                    <h1 className='bank_heading'>Bank Accounts</h1>

                    <div className='bank_main_container'>
                        {bankAccountData && bankAccountData?.map((customer) => (
                            <div>
                                <div className='bank_details_details' key={customer?.customer_id}>
                                    <div className='bank_customer_details'>
                                        <div>
                                            <img className='bank_image' src={Bank} alt="Bank Logo" />
                                        </div>
                                        <div className='customer_name'>
                                            {customer?.customer_bank_details && customer?.customer_bank_details?.map((bankDetail) => (
                                                <>
                                                    <h3 className='customer_name'>{bankDetail?.account_holder_name || '-'}</h3>
                                                    <h3 className='name_saving'>{bankDetail?.account_number ? `Savings account ${bankDetail?.account_number}` : '-'}</h3>
                                                </>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='account_div_one'>
                                        <div><p className='verify'>Verify</p></div>
                                        <div className='arrow' onClick={() => handleArrowClick(customer?.customer_id)}>
                                            {/* <img style={{marginTop:'15px',marginLeft:"10px"}} src={Icon} alt="" /> */}
                                            <img
                                                style={{ marginTop: '15px', marginLeft: "10px", transform: visibleCustomerId === customer?.customer_id ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
                                                src={Icon}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                {visibleCustomerId === customer?.customer_id && (
                                    <div className='bank_details_details_content'>
                                        {customer?.customer_bank_details && customer?.customer_bank_details?.map((bankDetail) => (
                                            <div className='bank_person_details' key={bankDetail?.id}>
                                                <div className='bank_information'>

                                                    <div className='account_card'>
                                                        <div className='label'>Bank</div>
                                                        <div className='data'>{bankDetail?.bank?.display_name || '-'}</div>
                                                    </div>

                                                    <div className='account_card'>
                                                        <div className='label'>Bank Branch</div>
                                                        <div className='data'>{bankDetail?.bank_branch?.display_name || '-'}</div>
                                                    </div>

                                                    <div className='account_card'>
                                                        <div className='label'>Account Type</div>
                                                        <div className='data'>{bankDetail?.account_type || '-'}</div>
                                                    </div>


                                                </div>

                                                <div className='bank_information'>

                                                    <div className='account_card'>
                                                        <div className='label'>IFSC Code</div>
                                                        <div className='data'>{bankDetail?.ifsc_code || '-'}</div>
                                                    </div>

                                                    <div className='account_card'>
                                                        <div className='label' style={{ marginRight: '25px' }}>MICR Code</div>
                                                        <div className='data'>{bankDetail?.micr_code || '-'}</div>
                                                    </div>

                                                    <div className='account_card'>
                                                        <div className='label'></div>
                                                        <div className='data'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='table_chart'>
                <div><h1 className='verification_text'>Asset / Document / Fraud Verifications</h1></div>

                <div className='main_table'>
                <Table
                dataSource={documentDetails}
                columns={columns}
                loading={loading}
                pagination={false}
                rowKey={(record) => record.no} // Unique key for each row
            />
                </div>
            </div>


            <div className='active_loan'>
                <div className='foir'><h1 className='foir_text'>Active Loans & FOIR</h1></div>
                <div className='main_active_loan' >
                    <div className='active_loan_first'>
                        {loanDetails?.map((loan, index) => (
                            <div className='auto_loan' key={index}>
                                <div className='auto'>{loan?.loan_purpose}</div>
                                <div className='progress'>
                                    <Progress
                                        percent={100 - loan?.remaining_percentage} // Calculate the completion percentage
                                        size="small"
                                        status="active"
                                    />
                                </div>
                                <div className='sanction'>
                                    Sanctioned Amt: ₹ {loan?.total_sanctioned_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className='active_loan_two' >
                        <Table
                            dataSource={tableData}
                            columns={columns1}
                            pagination={false}
                            showHeader={false}
                            loading={loading}
                        />

                    </div>
                </div>
            </div>
        </div>



    );
};

export default CaseHealthSummary;


