import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, Radio, Row, Space } from "antd";
import "quill/dist/quill.snow.css";
import Quill from "quill";

const OtherProvisionInputForm = ({ yupSync, form }) => {
  const [marketClear, setMarketClear] = useState(null);
  const quillRef = useRef(null);

  useEffect(() => {
    if (!quillRef.current) {
      quillRef.current = new Quill('#provisionInputFormQuillEditor', {
        theme: 'snow',
      });

      quillRef.current.on('text-change', () => {
        const content = quillRef.current.root.innerHTML;
        const cleanContent = content.replace(/^<p>|<\/p>$/g, ''); // Remove wrapping <p> tags
        form.setFieldValue("chain_of_events", cleanContent);
      });
    }
  }, [form]);

  const onChange = (e) => {
    const value = e.target.value;
    setMarketClear(value);
    form.setFieldValue('market_clear', value);
  };
  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div style={{ border: "1px solid #E4E7EC", marginTop: "20px"}}>
      <div className="property_description_heading">
        <div className="property_description_data">Other Provisions</div>
        <div style={{ display: "flex" }}>
          <div style={{ margin: "10px 10px 10px 10px" }}>
            {/* {collapsed1 ? <DownOutlined /> : <UpOutlined />} */}
          </div>
        </div>
      </div>

      <div style={{ margin: "10px" }}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="first_owner"
              label="Who was the first owner of the Property?"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Please Enter First Owner Name" onChange={(e) => handleInput(e, "first_owner")} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              name="otherprovision_present_owner"
              label="Who is the present owner of the Property?"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Please Enter Present Owner Name" onChange={(e) => handleInput(e, "otherprovision_present_owner")}/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              name="market_clear"
              label="Is the title clear and marketable?"
              rules={[yupSync]}
              required
            >
              <Radio.Group onChange={onChange} value={marketClear}>
                <Space direction="vertical">
                <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="chain_of_events"
          label="How the title devolve to current owner by which document (Chain of event must be mention with document no and date.)"
          rules={[yupSync]}
          required
        >
          <div
            id="provisionInputFormQuillEditor"
            style={{
              height: "130px",
              border: "1px solid #d9d9d9",
              borderRadius: "2px",
            }}
          ></div>
        </Form.Item>
      </div>
    </div>
  );
};

export default OtherProvisionInputForm;
