import { axiosRequest } from "../../../utils/api/axiosRequest"


export const updateResidenceandOverallDetails = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-customer-detail/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}



export const updateIdentificationDocumentsById = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-document/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}
export const updatePersonContactedAtResidenceById = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-person-contacted/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const updatePersonalDetailsById = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-personal-detail/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}



export const deleteIdentificationDocumentsByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/loan/fi-document/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateResidenceDocumentsById = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-neighbour-confirmation/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}


export const deleteResidenecDocumentsByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/loan/fi-neighbour-confirmation/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateVehicleById = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-vehicle-detail/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}
  export const deleteVehicleByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/loan/fi-vehicle-detail/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateAssetsByCustomerId = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-assets/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const updateOthersDetails = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-other-detail/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const updateOthersDocuments = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/loan/fi-document/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const deleteOtherDocumentsByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/loan/fi-document/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createOtherDocumentsByIdServices = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/fi-document/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }