import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  getDepartmentListService,
  getDesignationListService,
  getLocationListService,
  getUserWorkDetailsService,
  updateUserWorkDetailsService,
} from "../services/userService";

const validationSchema = Yup.object().shape({
  department: Yup.string().required("Department is required"),
  designation: Yup.string().required("Designation is required"),
  location: Yup.string().required("Location is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const WorkDetailsForm = ({
  onNext,
  onCancel,
  closeDrawer,
  open,
  id,
  formCompleted,
}) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [desigantions, setDesigantions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [, setSelectedDepartment] = useState(null);
  const [designationDisabled, setDesignationDisabled] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentResponse = await getDepartmentListService();
        setDepartments(departmentResponse.data);

        const locationResponse = await getLocationListService();
        setLocations(locationResponse.data);

        if (id && open) {
          const workDetailsResponse = await getUserWorkDetailsService(id);
          const { department, designation, location } =
            workDetailsResponse?.data;
          form.setFieldsValue({
            department: department?.id,
            designation: designation?.id,
            location: location?.id,
          });
          setSelectedDepartment(department?.id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, open, form]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateUserWorkDetailsService(id, values);
        if (response.status === 200 && response.success) {
          message.success("User Work Details Successfully Updated");
        } else {
          message.error("Failed to update user work details");
        }
      }

      onNext();
    } catch (error) {
      form.setFieldsValue({
        department: "",
        designation: "",
        location: "",
      });
      message.error("An error occurred while updating user work details");
    }
  };

  const handleDepartmentChange = async (value) => {
    setSelectedDepartment(value);
    setDesignationDisabled(false);

    try {
      const designationResponse = await getDesignationListService(value);
      setDesigantions(designationResponse.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "An error occurred while fetching designations!"
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        department: "",
        designation: "",
        location: "",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="department"
            label={<span>Department</span>}
            required
            rules={[yupSync]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              onChange={handleDepartmentChange}
            >
              {departments.map((department) => (
                <Select.Option
                  key={department.id}
                  value={department.id}
                  label={department.display_name}
                >
                  {department.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="designation"
            label={<span>Designation</span>}
            required
            rules={[yupSync]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              disabled={designationDisabled}
            >
              {desigantions.map((designation) => (
                <Select.Option
                  key={designation.id}
                  value={designation.id}
                  label={designation.display_name}
                >
                  {designation.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="location"
            label={<span>Location</span>}
            required
            rules={[yupSync]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
            >
              {locations.map((location) => (
                <Select.Option
                  key={location.id}
                  value={location.id}
                  label={location.display_name}
                >
                  {location.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="vertical"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Next
        </Button>
        <Button onClick={handleCancel}>Prev</Button>
        <Button onClick={closeDrawer}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default WorkDetailsForm;
