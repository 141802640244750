// Define the reusable function
function authorizeTableColumns(
  columns,
  columnsToAdd,
  userPermissions,
  requiredPermissions
) {
  let updatedColumns = [...columns];

  const hasRequiredPermissions = userPermissions?.some((permission) =>
    requiredPermissions.includes(permission?.code)
  );

  const hasAdminPermission = userPermissions?.some(
    (permission) => permission.code === "MPADMIN1"
  );

  if (hasRequiredPermissions || hasAdminPermission) {
    updatedColumns = [...columns, ...columnsToAdd];
  }

  return updatedColumns;
}

export default authorizeTableColumns;
