import { axiosRequest } from "../../../utils/api/axiosRequest";

const getDocumentTypeList = () => {
    return new Promise(async (resolve, reject) => {
        try {
          const response = await axiosRequest.get(`/api/v1/master/document-type/list`);
          return resolve(response.data);
        } catch (error) {
          return reject(error);
        }
      });
}

export default getDocumentTypeList

