import React, { useState } from "react";
import {
  Input,
  Tooltip,
  Divider,
  Space,
  Typography,
  Empty,
  Popover,
} from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
const TableDisplayCard = ({ title, data, property, setSelected }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenChange = (open) => {
    if (!open) {
      setSearchQuery("");
      setSelected(null);
    }
  };

  const filteredList = data?.[property]?.filter((item) =>
    item?.display_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const content = (
    <div>
      <Input
        name={`Search ${title}`}
        placeholder="Search"
        prefix={
          <Tooltip title="Search">
            <SearchOutlined className="site-form-item-icon" />
          </Tooltip>
        }
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Divider />
      <Space
        style={{
          height: "120px",
          overflowY: "auto",
          width: "100%",
        }}
        direction="vertical"
      >
        {filteredList?.length > 0 ? (
          <>
            {filteredList?.map((item) => (
              <Typography.Text key={item?.id}>
                {item?.display_name}
              </Typography.Text>
            ))}
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Space>
    </div>
  );

  return (
      <div
          style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
          }}>
          <Popover
              content={content}
              title={title}
              trigger="click"
              onOpenChange={handleOpenChange}>
              <EyeOutlined
                  onClick={() => {
                      setSearchQuery("");
                      setSelected(data);
                  }}
                  style={{ fontSize: "16px" }}
              />
          </Popover>
      </div>
  );
};

export default TableDisplayCard;
