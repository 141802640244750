import { useEffect, useState } from "react";
import { axiosRequest } from "../../../utils/api/axiosRequest";
import { message } from "antd";

const UseFI = (loanId, stageId) => {
  const [loanDetail, setLoanDetail] = useState();
  const [creditAssessmentData, setCreditAssessmentData] = useState();
  const [initiateStageAttachmentData, setInitiateStageAttachmentData] =
    useState();
  const [fiAdresses, setFiAddresses] = useState();
  const [customerDetail, setCustomerDetail] = useState();
  useEffect(() => { 
    if (loanId && stageId) {
      const fetchFIData = async () => {
        try {
          const response = await axiosRequest.get(
            `/api/v1/loan/fi-combine/get-customer-list-by-loan-stage-id/${stageId}`
          );
          if (!response?.data) {
            return null;
          }
          setFiAddresses(response?.data?.data);
          // setCreditAssessmentData(response?.data?.data.credit_assessment_data)
          // setLoanDetail(response.data.data.loan_detail);
          // setInitiateStageAttachmentData(response?.data?.data?.initiate_stage_attachment_data)
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      };

      const fetchFICustomerData = async () => {
        try {
          const response = await axiosRequest.get(
            `/api/v1/loan/initiate-stage/get-credit-assessment-data-for-fi-input-form/${stageId}`
          );
          if (!response?.data) {
            return null;
          }
          // setFiAddresses(response?.data?.data)
          setCreditAssessmentData(response?.data?.data.credit_assessment_data);
          setLoanDetail(response.data.data.loan_detail);
          setInitiateStageAttachmentData(
            response?.data?.data?.initiate_stage_attachment_data
          );
          setCustomerDetail(response?.data?.data?.customer_detail); 
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      };
      fetchFIData();
      fetchFICustomerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loanDetail,
    creditAssessmentData,
    initiateStageAttachmentData,
    fiAdresses,
    customerDetail
  };
};

export default UseFI;
