import React, { useEffect, useState } from "react";
import { Space, Modal, Typography, Drawer, Button, message } from "antd";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import { Form } from "antd";
import ListOfApplicationFilterForm from "./ListOfApplicationFilterForm";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import { Link } from "react-router-dom";
import IndianNumberFormat from "../../../../utils/indianNumberFormat/IndianNumberFormat";
import { useSelector } from "react-redux";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { decrypt } from '../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';


const { Title } = Typography;

const ListOfApplication = () => {
  const { encrypted_stage_id } = useParams();
  const [stageId, setStageId] = useState(null)
  const [listOfApplications, setListOfApplications] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedListOfApplication, setSelectedListOfApplication] =
    useState(null);
  const [filteropen, setFilteropen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);


  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    location: null,
    loan_type: [],
    loan_status: [],
    sales_manager: [],
    amountType: null,
    created_by: [],
    modified_by: [],
  });

  useEffect(() => {
    if (encrypted_stage_id !== null) {

      setStageId(null)
      try {
        const stage_id = decrypt(encrypted_stage_id);
        setStageId(stage_id)
      } catch (error) {
        message.error("Invalid Loan or Stage ID");
      }
    }
  }, [encrypted_stage_id])

  const [form] = Form.useForm();

  const { user_data } = useSelector((state) => state.user);

  const showDrawer1 = () => {
    setFilteropen(true);
  };

  const onClose1 = () => {
    setSelectedListOfApplication(null);
    setFilteropen(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  useEffect(() => {
    if (!filteropen && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [filteropen, actionPerformed]);
  const columns = [
    {
      title: "Loan Account Number",
      dataIndex: "loan_account_number",
      fixed: "left",
      render: (text) => text || "-",
    },
    {
      title: "Applicant Name",
      dataIndex: "applicant_name",
      render: (text) => text || "-",
    },
    {
      title: "Application Number",
      dataIndex: "application_number",
      render: (text) => text || "-",
    },
    {
      title: "Location",
      render: (record) => record?.location?.display_name || "-",
    },
    {
      title: "Loan Type",
      render: (record) => record?.loan_type?.display_name || "-",
    },
    {
      title: "Loan Amount",
      dataIndex: "requested_amount",
      render: (amount) => {
        return amount !== undefined ? formatCurrency(IndianNumberFormat(amount)) : "-";
      },
    },
    {
      title: "Sales Manager",
      render: (record) => record?.sales_manager?.name || "-",
    },
    {
      title: "Status",
      render: (record) => (
        <div
          style={{
            backgroundColor: "#ECFFDC",
            borderRadius: "9px",
            padding: "5px 10px",
            display: "inline-block",
            color: "#40B5AD",
          }}
        >
          {record?.loan_stage || "-"}
        </div>
      ),
    },
  ];

  const formatCurrency = (value) => {
    return `₹ ${value.toLocaleString('en-IN')}`;
  };


  const authorizedColumns = [
    {
      title: "View",
      fixed: "right",
      render: (_, record) => (
        <Link to={record.id ? `/loandetails/${encrypt(record.id.toString())}` : '#'}>
          <EyeOutlined style={{ fontSize: "18px", color: "black" }} />
        </Link>
      ),
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["MPANOB6"]
  );

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Applications
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={showDrawer1}
            icon={<FilterOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            More Filter
          </Button>
        </div>
      </Space>
      {
        stageId ? (
          <DataTable
            // apiPath={stage_id ? `/api/v1/loan/loan-detail/?stage_id=${stage_id}` : "/api/v1/loan/loan-detail/"}
            apiPath={`/api/v1/loan/loan-detail/?stage_id=${stageId}`}
            tableData={listOfApplications}
            setTableData={setListOfApplications}
            tableColumns={tableColumns}
            refreshData={refreshTableData}
            searchPlaceholder={"Type to search "}
            filters={filterFormData}
          />
        ) : (
          <DataTable
            // apiPath={stage_id ? `/api/v1/loan/loan-detail/?stage_id=${stage_id}` : "/api/v1/loan/loan-detail/"}
            apiPath={`/api/v1/loan/loan-detail/`}
            tableData={listOfApplications}
            setTableData={setListOfApplications}
            tableColumns={tableColumns}
            refreshData={refreshTableData}
            searchPlaceholder={"Type to search "}
            filters={filterFormData}
          />
        )
      }





      <Drawer
        title={<Header title="Filter" name="Applications" onClose={onClose1} />}
        width={720}
        onClose={onClose1}
        open={filteropen}
        styles={{ body: { paddingBottom: 80 } }}
        closable={false}
      >
        <ListOfApplicationFilterForm
          refreshData={toggleRefreshTableData}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          open={showDrawer1}
          closeForm={onClose1}
          form={form}
          placement="right"
          setActionPerformed={setActionPerformed}
        />
      </Drawer>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedListOfApplication?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default ListOfApplication;
