import { Button, Col, Form, Row, Select, Space, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import {
  getMiscellaneousDetailServiceById,
  listOverallTypeService,
  updateMiscellaneousDetailServiceById,
} from "../services/miscellaneousDetailsService";
import * as Yup from "yup";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  overall_status: Yup.string().required("Overall Status is required!"),
  conclusion: Yup.string().required("Overall remark is required!"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ConclusionsForm = ({
  loanId,
  stageId,
  closeForm,
  open,
  updateId,
  refreshData,
}) => {
  const [, setConclusionData] = useState();
  const [overallstatus, setOverallstatus] = useState([]);
  const [quillContent, setQuillContent] = useState("");
  const [form] = Form.useForm();
  const quillRef = useRef(null);

  const fetchoverallstatus = async () => {
    try {
      const response = await listOverallTypeService();
      setOverallstatus(response.data.choices);
    } catch (error) {
      console.error("Error fetching Overall Type:", error);
      message.error("Failed to fetch Overall Type");
    }
  };

  const getConclusionData = async () => {
    try {
      const response = await getMiscellaneousDetailServiceById(stageId);
      setConclusionData(response?.data[0]);
      let { overall_status, conclusion } = response?.data[0];
      form?.setFieldsValue({
        overall_status,
        conclusion,
      });

      if (quillRef.current) {
        quillRef.current.root.innerHTML = conclusion || "";
        setQuillContent(conclusion || "");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (open) {
      getConclusionData();
      fetchoverallstatus();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form]);

  useEffect(() => {
    if (open) {
      getConclusionData();
      const quill = new Quill("#conclusionsQuillEditor", {
        theme: "snow",
      });

      quill.on("text-change", () => {
        setQuillContent(quill.root.innerHTML);
      });

      quillRef.current = quill;
    } else {
      form.resetFields();
      // Cleanup Quill instance if form is closed
      if (quillRef.current) {
        quillRef.current.off("text-change");
        quillRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleSubmit = async (values) => {
    try {
      const updatedValues = {
        ...values,
        conclusion: stripHtmlTags(quillContent),
      };
      const response = await updateMiscellaneousDetailServiceById(
        updateId,
        updatedValues
      );
      if (response.status === 200 && response.success) {
        message.success("Conclusion Form successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="overall_status"
            label="Overall Status"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {overallstatus?.map((overallstatus) => (
                <Option key={overallstatus.value} value={overallstatus.value}>
                  {overallstatus.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="conclusion"
        label="Overall Remark"
        rules={[
          {
            validator: (_, value) =>
              stripHtmlTags(quillContent).length > 0
                ? Promise.resolve()
                : Promise.reject("Overall Remark is required!"),
          },
        ]}
        required
      >
        <div
          id="conclusionsQuillEditor"
          style={{
            height: "130px",
            border: "1px solid #d9d9d9",
            borderRadius: "2px",
          }}
        >
          {" "}
        </div>
      </Form.Item>

      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "20px",
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ConclusionsForm;
