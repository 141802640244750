import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
} from "antd";
import { IoCallOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { getCustomerMobileByIdService, createCallService } from "../services/televerificationDetailsService";
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import * as Yup from "yup";

const validationSchema = Yup.object().shape({

  from_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),
  from_mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),
  customer_detail: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),

  to_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const TeleVerificationCallDetailsForm = ({ refreshData, open, closeForm, id, customer_id }) => {
  const [form] = Form.useForm();
  const { user_data } = useSelector((state) => state.user);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    if (user_data?.mobile) {
      form.setFieldsValue({
        from_mobile: user_data?.mobile,
      });
    }
  }, [user_data, form, open]);


  useEffect(() => {
    const getCustomerById = async () => {
      if (customer_id) {
        try {
          const response = await getCustomerMobileByIdService(customer_id);
          form.setFieldsValue({
            customer_detail: response?.data?.mobile_number,
          });
        } catch (error) {
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      }
    };
    getCustomerById();
  }, [customer_id, form]);

  const handleSubmit = async (values) => {
    try {
      values.loan_detail = parseInt(loan_id);
      values.customer_detail = customer_id;
      // console.log(values);
      const response = await createCallService(values);
      if ((response.status = 200 && response.success)) {
        message.success("Call Successfully initiated");
        refreshData();
        closeForm();
      }
    }
    catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 10); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={9}>
            <Form.Item
              label="From (Caller)"
              required
            >
              <Input.Group compact>
                <Form.Item
                  name="from_country_code"
                  rules={[yupSync]}
                  noStyle
                >
                  <Input
                    style={{
                      width: "20%",
                      textAlign: "center",
                      background: "#f1f1f1",
                    }}
                    disabled
                    defaultValue="91"
                  />
                </Form.Item>
                <Form.Item
                  name="from_mobile"
                  rules={[yupSync]}
                  noStyle
                >
                  <Input
                    style={{ width: "80%" }}
                    placeholder="Please Enter Mobile Number"
                    onChange={(e) => handleNumberFields(e, "from_mobile")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="To (Callee)"
              required
            >
              <Input.Group compact>
                <Form.Item
                  name="to_country_code"
                  rules={[yupSync]} noStyle
                >
                  <Input
                    style={{
                      width: "20%",
                      textAlign: "center",
                      background: "#f1f1f1",
                    }}
                    disabled
                    defaultValue="91"
                  />
                </Form.Item>
                <Form.Item
                  name="customer_detail"
                  rules={[yupSync]}
                  noStyle
                 
                >
                  <Input
                    style={{ width: "80%" }}
                    disabled
                    placeholder="Please Enter Mobile Number"
                    onChange={(e) => handleNumberFields(e, "customer_detail")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col style={{ marginTop: "30px" }}>
            <Button type="primary" htmlType="submit">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <IoCallOutline style={{ fontSize: "14px", marginRight: "8px", fontWeight: "600" }} />
                <span>Call</span>
              </div>
            </Button>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </>
  );
}

export default TeleVerificationCallDetailsForm;
