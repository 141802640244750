import { Button, Col, Form, Input, Row, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { getChequeById, updateMultipleChequesService, updatePDCByIdService } from '../services/PDCService';
import { decrypt } from '../../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    remark: Yup.string().required("Remark is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};
const EditCheque = ({ closeForm,refreshData ,id ,selectedRows, open}) => {
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [loading, setLoading] = useState(false);

    let loan_id = null;
    try {
      loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  
    const handleInput = (e, field) => {
        const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
        form.setFieldsValue({ [field]: newValue });
    };
    
     const getChequeData = async () => {
    if (id) {
      try {
        const response = await getChequeById(id);
        const {
          remark,
        } = response?.data;
        form.setFieldsValue({
          remark,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };
    useEffect(() => {
        if (open) {
          getChequeData();
        } else {
          form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [open]);
    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            values.loan_detail = parseInt(loan_id);
            if (selectedRows.length > 0) {
                const response = await updateMultipleChequesService(
                    selectedRows,
                    values.remark
                );
                if ((response.status = 200 && response.success)) {
                    message.success("Cheque updated successfully");
                    refreshData();
                    closeForm();
                }
            } else if (id) {
                const response = await updatePDCByIdService(id, values);
                if ((response.status = 200 && response.success)) {
                    message.success("Cheque updated successfully");
                    refreshData();
                    closeForm();
                }
            }


        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }finally{  
            setLoading(false)
          }
    };


    return (

        <>
            <Form
                layout="vertical"
                onFinish={handleSubmit}
                form={form}>
                <Row gutter={16} style={{ marginBottom: "16px" }}>
                    <Col span={8}>

                        <Form.Item name="remark" label="Remark" rules={[yupSync]} required>
                            <Input
                                placeholder="Please enter remark"
                                onChange={(e) => handleInput(e, "remark")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>
        </>
    )
}

export default EditCheque