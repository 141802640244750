import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getAssests,
} from "../../services/getCustomerDetailData";
import { updateAssetsByCustomerId } from "../../services/applicantRvServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";


const AssetSeenForm = ({ customerDetails, closeForm, updateId, open, refreshData }) => {
  const [form] = Form.useForm();
  const [assets, setAssets] = useState([]);
  const { Option } = Select;

  const validationSchema = Yup.object().shape({
    assets: Yup.array().min(1, "Please select at least one asset").required("Assets are required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getAssests, setAssets);
  }, []);

  const getData = async () => {

    if (updateId) {
      try {
        const selectedAssetIds = customerDetails.map(asset => asset.assets);
        form.setFieldsValue({
          assets: selectedAssetIds,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  const handleSubmit = async (values) => {
    try {
      if (updateId) {
        const response = await updateAssetsByCustomerId(updateId, values);
        if ((response.status = 200 && response.success)) {
          message.success("Assets successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        style={{ margin: "0px 10px 10px 0px" }}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="assets" label="Assets" rules={[yupSync]} required>
              <Select
                mode="multiple"
                placeholder="Please select"
                required={true}
                allowClear
                style={{ width: "65%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {assets.map((asset) => (
                  <Option key={asset.id} value={asset.id}>{asset.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default AssetSeenForm;
