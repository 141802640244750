import React, { useState, useEffect } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import EditButton from "../../../../utils/editButton/EditButton";
import { Drawer, Empty, message } from "antd";
import Header from "../../../layout/views/Header";
import PersonContactedAtResidenceEditForm from "./PersonContactedAtResidenceEditForm";
import { getPersonContactedAtResidenceByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../../utils/loading/Loading";

const PersonContactedAtResidence = ({ selectedCustomerId }) => {
  const [openEditTwo, setOpenEditTwo] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [collapsed2, setCollapsed2] = useState(true);
  const [loading, setLoading] = useState(true);
  const [personContactedData, setPersonContactedData] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };
  const showDrawerTwo = () => {
    setOpenEditTwo(true);
  };
  const onCloseTwo = () => {
    setOpenEditTwo(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getPersonContactedAtResidenceByCustomerId(
          selectedCustomerId
        );
        setPersonContactedData(reponse?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, selectedCustomerId]);

  const renderEditButtonTwo = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerTwo();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div style={{ padding: "0px 0px 14px 0px", border: "1px solid #e4e7ec" }}>
      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseTwo}
            name="Person Contacted At Residence"
          />
        }
        width={970}
        onClose={onCloseTwo}
        open={openEditTwo}
        closable={false}
      >
        <PersonContactedAtResidenceEditForm
          open={openEditTwo}
          updateId={personContactedData?.id}
          // loan_id={loan_id}
          closeForm={onCloseTwo}
          refreshData={toggleRefreshTableData}
          customerDetails={personContactedData}
        />
      </Drawer>
      <div className="fade-in">
        {loading ? (
          <Loading loading={loading} /> // Loading component while data is fetching
        ) : personContactedData ? (
<>

      <div className="fi_description_heading">
        <div className="fi_description_data">
          Person Contacted At Residence
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {renderEditButtonTwo()}
          </div>
          <div
            style={{ margin: "10px 10px 10px 10px",cursor:"pointer" }}
            onClick={toggleCollapse2}
          >
            {collapsed2 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed2 && (
        <div>
          <>
            <div className="fi_description_basic_card_container">
              <div className="fi_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Relationship With Applicant
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.relationship_with_applicant.relation || "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Houseowner's Name
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.owner_name || "-"}
                  </div>
                </div>
              </div>

              <div className="fi_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    No. Of Years At Current Address
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.no_of_years_at_current_address || "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Please Mention Rent Amount
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.rent_amount || "-"}
                  </div>
                </div>
              </div>

              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    No. Of Years In city
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.no_of_years_in_current_city || "-"}
                  </div>
                </div>

                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Please Mention Permanent Address
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.permanent_address ||
                      "-"}
                  </div>
                </div>
              </div>
              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="fi_description_basic_card_label">
                    Residence Area In Sq. Ft.
                  </div>
                  <div className="fi_description_basic_card_data">
                    {personContactedData?.area_in_sq_feet ||
                      "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
       </>
      ) : (
        <div className="no_data_found_message">
          <Empty />
        </div>
      )}
    </div>
    </div>
  );
};

export default PersonContactedAtResidence;
