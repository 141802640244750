import React, { useEffect, useState } from "react";
import group from '../assets/Group.png'
import "../styles/bureauDetails.css"
import "../../style/customerStyle.css"
import { EyeOutlined } from '@ant-design/icons';
import { getBureauDetailsByIdService } from '../services/bureauDetailService';
import { useNavigate, useParams } from 'react-router-dom';
import { Empty, message } from "antd";
import Loading from "../../../../../utils/loading/Loading";
import "../../../../commonCss/commonStyle.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const additionalHeaders = [
  'Score',
  'Vintage',
  'No of trade lines',
  'High credit value',
  // "Peak Exposure Type Loan type ",
  "Total current balance on live loan accounts",
  "No of active loans",
  "Highest DPD Reported",
  "Number of Enquires Secured",
  "Loans taken from months",
  "View Full Report",
];



const BureauDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [bankBureauData, setBankBureauData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBureauDetailsByIdService(loan_id);
        setBankBureauData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(error?.response?.data?.message || "Error fetching bureau details");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const hasAnyBureauData = (person) => {
    return (person?.cibil && !isEmpty(person.cibil)) || (person?.highmark && !isEmpty(person.highmark));
  };

  const formatCurrency = (value) => {
    return `₹ ${value.toLocaleString('en-IN')}`;
  };



  return (
    <div className='tab_form_container'>
      <div className='tab_form_main_container fade-in'>
        {loading ? (
          <Loading loading={loading} />
        ) : bankBureauData?.some(hasAnyBureauData) ? (
          <table className='tab_form_main_table'>
            <thead>
              <tr>
                <th className="tab_form_key_parameters"
                  rowSpan="2">

                  <div className='first_heading'>
                    <div className='second_heading'>
                      Key Parameter's
                    </div>
                    <div style={{ flex: 1 }}>
                      <span></span>
                    </div>
                  </div>
                </th>
                {bankBureauData?.map((person, index) => (
                  <th colSpan="2" className='tab_form_top_heading'>
                    <span>{`${person?.customer_name}`} </span>
                    <span>
                      <span>{`(${person?.customer_type})`}  </span>
                      <img src={group} alt="group" style={{ marginLeft: "5px" }} />

                    </span>
                  </th>
                ))}

              </tr>
              <tr>
                {bankBureauData?.map((person, index) => (
                  <>
                    <th className="bure_cibil_heading top_head" style={{ textAlign: "center" }}>
                      CIBIL
                    </th>

                    <th className="bure_cibil_heading top_head" style={{ textAlign: "center" }}>
                      HIGHMARK
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {additionalHeaders?.map((header, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  <td className='tab_form_left_heading'>
                    {header}
                  </td>
                  {bankBureauData?.map((person, colIndex) => (
                    <React.Fragment key={`col-${colIndex}`}>
                      <td className={`${header === 'Score' ? 'green-text' : 'cibil_highmark'}`} style={{ textAlign: "center" }}>

                        {/* {person.cibil ? person.cibil[header.toLowerCase().replace(/ /g, "_")] || "-" : "-"} */}
                        {person?.cibil ? (
                          <>
                            {
                              header === 'Score' ? (
                                person?.cibil?.score || '-'
                              ) : header === 'Vintage' ? (
                                person?.cibil?.vintage || '-'
                              ) : header === 'No of trade lines' ? (
                                person?.cibil?.no_of_trade_lines || '-'
                              ) : header === 'High credit value' ? (
                                formatCurrency(person?.cibil?.high_credit_value) || '-'
                              ) : header === 'Total current balance on live loan accounts' ? (
                                formatCurrency(person?.cibil?.total_current_balance_on_live_loan_acc) || '-'
                              ) : header === 'No of active loans' ? (
                                person?.cibil?.no_of_active_loans || '-'
                              ) : header === 'Highest DPD Reported' ? (
                                person?.cibil?.highest_DPD_repost || '-'
                              ) : header === 'Number of Enquires Secured' ? (
                                person?.cibil?.no_of_inquiries_secured || '-'
                              ) : header === 'Loans taken from months' ? (
                                person?.cibil?.loans_taken_12_24_month || '-'
                              ) : Object.keys(person.cibil).length > 0 && header === 'View Full Report' ? (
                                <EyeOutlined onClick={() => navigate(`/cibil_report/${person.customer_id}`)} /> || '-'
                              ) : "-"}
                          </>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td className={`${header === 'Score' ? 'green-text' : 'cibil_highmark'}`} key={`col-${colIndex}`} style={{ textAlign: "center" }}>
                        {person?.highmark ? (
                          <>
                            {
                              header === 'Score' ? (person?.highmark?.score || '-') :
                                header === 'Vintage' ? (person?.highmark?.vintage || '-') :
                                  header === 'No of trade lines' ? (person?.highmark?.no_of_trade_lines || '-') :
                                    header === 'High credit value' ? (person?.highmark?.high_credit_value || '-') :
                                      header === 'Total current balance on live loan accounts' ? (person?.highmark?.total_current_balance_on_live_loan_acc || '-') :
                                        header === 'No of active loans' ? (person?.highmark?.no_of_active_loans || '-') :
                                          header === 'Highest DPD Reported' ? (person?.highmark?.highest_DPD_repost || '-') :
                                            header === 'Number of Enquires Secured' ? (person?.highmark?.no_of_inquiries_secured || '-') :
                                              header === 'Loans taken from months' ? (person?.highmark?.loans_taken_12_24_month || '-') :
                                                Object.keys(person.highmark).length > 0 && header === 'View Full Report' ? (
                                                  <EyeOutlined onClick={() => navigate(`/highmark_report/${person.customer_id}`)} /> || '-'
                                                ) : '-'}
                          </>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no_data_found_message">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default BureauDetails;



