import React, { useEffect, useState } from "react";
import "../styles/hfcidcard.css";
import logo from "../assets/logo.png";
import signature from "../assets/signature.png";
import finserve from "../assets/finvserve.png";
import { getIDCardDetailsServicebyId } from "../services/idcardService";
import { message } from "antd";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";

const IdCardDetail = () => {
  const [data, setData] = useState();
  const [xmlData, setXmlData] = useState("");
  let { organization, encrypted_employee_id } = useParams();

  let employee_id = ("");
  try {
    employee_id = decrypt(encrypted_employee_id);
  } catch (error) { 
    message.error("Invalid Loan ID");
  }

  const getUserData = async () => {
    try {
      const response = await getIDCardDetailsServicebyId(organization,employee_id);
      setData(response.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(
    () => getUserData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization,employee_id]
  );
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    // Convert JSON to XML
    const jsonToXml = (json) => {
      let xml = "<root>";
      for (let key in json) {
        xml += `<${key}>${json[key]}</${key}>`;
      }
      xml += "</root>";
      return xml;
    };

    // Generate XML data for QR code
    if (data) {
      let { id, is_deleted, profile_image, ...others } = data;
      const xml = jsonToXml(others);
      setXmlData(xml);
    }
  }, [data]);

  return (
    <>
      {data && (
        <div class="id-wrapper no_print">
          <div class="id-wrapper-header no-print">
            <center>
              <img
                class="logo pt-3"
                src={data?.organization === "HFC" ? logo : finserve}
                alt="logo"
                width="35%"
              />
            </center>
          </div>

          <div class="profile-card__img">
            <center>
              <img src={data?.profile_image} alt="profile" />
            </center>
          </div>

          <div class="id-wrapper-body">
            <div class="details">
              <div class="row">
                <div class="col-lg-12">
                  <center>
                    <h1>{data?.employee_name}</h1>
                  </center>
                  <center>
                    <hr />
                    <h3>{data?.department}</h3>
                  </center>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <strong>
                    {" "}
                    <label>EMP ID.</label>
                  </strong>
                </div>
                <div class="col-lg-6 col-6">: {data?.employee_id}</div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-6">
                  <strong>
                    {" "}
                    <label>Blood Group</label>
                  </strong>
                </div>
                <div class="col-lg-6 col-6">: {data?.blood_group}</div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-6">
                  <strong>
                    {" "}
                    <label>DOB</label>
                  </strong>
                </div>
                <div class="col-lg-6 col-6">: {data?.date_of_birth}</div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-6">
                  <strong>
                    {" "}
                    <label>Contact No.</label>
                  </strong>
                </div>
                <div class="col-lg-6 col-6">: {data?.mobile_number}</div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-6">
                  <strong>
                    {" "}
                    <label>Emergency No.</label>
                  </strong>
                </div>
                <div class="col-lg-6 col-6">: {data?.emergency_number}</div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-6">
                  <strong>
                    {" "}
                    <label>Branch</label>
                  </strong>
                </div>
                <div class="col-lg-6 col-6">: {data?.branch}</div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  {/* <img class="logo pt-3" alt="qr-code" src={qr}/> */}
                  {data && (
                    <QRCode
                      size={128}
                      style={{ height: "auto", maxWidth: "85%", width: "850%" }}
                      value={xmlData} // Stringify the object
                      viewBox={`0 0 128 128`}
                    />
                  )}
                </div>
                <div class="col-lg-6 col-6">
                  <img class="logo pt-3" alt="signature" src={signature} />
                  <br />
                  Issuing Authority
                </div>
              </div>
            </div>
          </div>

          <div class="id-wrapper-footer p-2 px-4">
            <center>
              <h5 className="id-wrapper-footer-heading">
                {data?.organization === "Finserve"
                  ? "Vastu Finserve India Private Limited"
                  : data?.organization === "HFC"
                  ? "Vastu Housing Finance Corporation Ltd."
                  : ""}
              </h5>
              <p class="id-wrapper-footer-center">
                Unit No. 203 & 204, 2nd floor, ‘A’ Wing, Navbharat Estate,
                Zakaria Bunder Road, Sewri (West), Mumbai 400015. Maharashtra
              </p>
            </center>
          </div>
          <div class="id-wrapper-footer-green">
            <p>
              <center>
                {" "}
                <strong>
                  {data?.organization === "Finserve"
                    ? "www.vastufinserve.com"
                    : data?.organization === "HFC"
                    ? "www.vastuhfc.com"
                    : ""}
                </strong>
              </center>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default IdCardDetail;
