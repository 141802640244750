import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import {
  createLocationsService,
  getLocationsByIdService,
  updateLocationsByIdService,
  getZoneListService,
  getAllCityService,
  getAllCountriesService,
  getAllDistrictService,
  getAllStateService,
  getAllTalukaService,
  getallpincode,
  pincodeCodeService,
} from "../services/locationsService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, "Code can only contain capital letters and numbers")
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters and spaces")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^[A-Za-z ]+$/, "Display name can only contain letters and spaces")
    .min(2, "Display name must be at least 2 characters")
    .max(128, "Display name must be at max 128 characters")
    .label("Display name"),
  zone: Yup.string().required("Zone is required").min(1, "Zone is required"),
  address_line_1: Yup.string()
    .trim()
    .required("Address Line 1 is required")
    .label("Address Line 1"),
  address_line_2: Yup.string()
    .trim()
    .required("Address Line 2 is required")
    .label("Address Line 2"),
  email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format"
    )
    .label("Email"),
  contact: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits"),

  latitude: Yup.string()
    .required("Latitude is required")
    .matches(
      /^[0-9]{2}\.[0-9]{6}$/, 
      "Invalid format"
    )
    .label("Latitude"),
  longitude: Yup.string()
    .required("Longitude is required")
    .matches(
      /^[0-9]{2}\.[0-9]{6}$/, 
      "Invalid format"
    )
    .label("Longitude"),
  pincode: Yup.string().required("Pincode is required"),
  city: Yup.string().required("City is required"),
  taluka: Yup.string().required("Taluka is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const LocationsForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [zones, setZones] = useState([]);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [taluka, setTaluka] = useState();
  const [district, setDistrict] = useState();
  const [states, setStates] = useState();
  const [country, setCountry] = useState();
  const getLocationsData = async () => {
    if (id) {
      try {
        const response = await getLocationsByIdService(id);
        const { name,
          code,
          display_name,
          address_line_1,
          address_line_2,
          email,
          contact,
          latitude,
          longitude,
          pincode,
          city,
          district,
          state,
          taluka,
          country,
          zone,
          is_active,
          is_verified } =
          response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          address_line_1,
          address_line_2,
          email,
          contact,
          latitude,
          longitude,
          pincode: pincode?.id,
          city: city?.id,
          district: district?.id,
          state: state?.id,
          taluka: taluka?.id,
          country: country?.id,
          zone: zone?.id,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          taluka: taluka?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    const getAllPincodelist = async () => {
      try {
        const response = await getallpincode();
        setPincode(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCitylist = async () => {
      try {
        const response = await getAllCityService();
        setCity(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllTalukalist = async () => {
      try {
        const response = await getAllTalukaService();
        setTaluka(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await getAllDistrictService();
        setDistrict(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await getAllStateService();
        setStates(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountries = async () => {
      try {
        const response = await getAllCountriesService();
        setCountry(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllPincodelist();
    getAllCitylist();
    getAllTalukalist();
    getAllDistrict();
    getAllState();
    getAllCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);


  useEffect(() => {
    const getZonesList = async () => {
      try {
        const response = await getZoneListService();
        setZones(response.data);
      } catch (error) {
        error.message("Problem fetching zones");
      }
    };
    getZonesList();
  }, []);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };

  const handleSubmit = async (values) => {

    const payload = {
      ...values,
      latitude: Number(values.latitude),
      longitude: Number(values.longitude),
    };
    try {
      if (id) {
        const response = await updateLocationsByIdService(id, payload);
        if ((response.status = 200 && response.success)) {
          message.success("Locations successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createLocationsService(payload);
        if ((response.status = 201 && response.success)) {
          message.success("Locations successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s,./()0-9/-]/g, ""); // Allow numbers
    form.setFieldsValue({ [field]: newValue });
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleDecimalInput = (e, field) => {
    let newValue = e.target.value
      .replace(/[^0-9.]/g, "") // Allow only numbers and decimal
      .replace(/(\..*)\./g, "$1") // Prevent multiple dots

    // Limit to 6 decimal places
    const [integerPart, decimalPart] = newValue.split(".");
    if (decimalPart && decimalPart.length > 6) {
      newValue = `${integerPart}.${decimalPart.slice(0, 6)}`;
    }

    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    if (open) {
      getLocationsData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        zone: null,
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="display_name"
            label="Display Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="address_line_1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              // onChange={(e) => handleInput(e, "address_line_1")}
              onChange={(e) => handletextInput(e, "address_line_1")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="address_line_2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handletextInput(e, "address_line_2")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="email" label="Email" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Email"
            // onChange={(e) => handleInput(e, "email")}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Contact Number"
            name="contact"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Contact Number"
              onChange={(e) => handleNumberFields(e, "contact")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="latitude"
            label="Latitude"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Latitude"
              onChange={(e) => handleDecimalInput(e, "latitude")}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="longitude"
            label="Longitude"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Longitude"
              onChange={(e) => handleDecimalInput(e, "longitude")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Code"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item name="zone" label="Zone" rules={[yupSync]} required>
            <Select
              showSearch
              placeholder="Please Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
            >
              {zones?.map((zone) => (
                <Select.Option key={zone.id} value={zone.id} label={zone.name}>
                  {zone.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please Select"
              filterOption={filterOption}
              onChange={handlePincodeChange}
              allowClear
            >
              {pincode?.map((pincode, index) => (
                <Option key={pincode.id} value={pincode.id}>
                  {pincode.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {city?.map((locations, index) => (
                <Option key={locations.id} value={locations.id}>
                  {locations.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {taluka?.map((talukas, index) => (
                <Option key={talukas.id} value={talukas.id}>
                  {talukas.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>


      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {district?.map((districts, index) => (
                <Option key={districts.id} value={districts.id}>
                  {districts.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {country?.map((countries, index) => (
                <Option key={countries.id} value={countries.id}>
                  {countries.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default LocationsForm;
