import React, { useEffect, useState } from 'react'
import "../styles/cibil.css"
import { Divider, Table, message } from 'antd';
import { useParams } from "react-router-dom";
import { getCibilReportByIdService } from '../services/bureauDetailService';
import moment from 'moment';
import "../styles/highMarkReport.css"
import Chart from "react-google-charts";
import greendot from "../assets/greendot.png"
import yellowdot from "../assets/yellowdot.png"
import reddot from "../assets/reddot.png"
import crifimage from "../assets/crifimage.png"
import ReactSpeedometer from "react-d3-speedometer";
import info from "../assets/information 6.png";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const columns = [
  {
    title: 'Address Category',
    dataIndex: 'address_category',
    key: 'address_category',

  },
  {
    title: 'Complete Address',
    dataIndex: 'complete_address',
    key: 'complete_address',
  },
  {
    title: 'Enriched Through Enquiry',
    dataIndex: 'enriched_through_enquiry',
    key: 'enriched_through_enquiry',
  },
  {
    title: 'Date Reported',
    dataIndex: 'date_reported',
    key: 'date_reported',
  },
];




const enquiryColumns = [
  {
    title: 'Enquiry Member Name',
    dataIndex: 'enquiry_member_name',
    key: 'enquiry_member_name',
  },
  {
    title: 'Date of Enquiry',
    dataIndex: 'date_of_enquiry',
    key: 'date_of_enquiry',
  },

  {
    title: 'Enquiry Purpose',
    dataIndex: 'enquiry_purpose',
    key: 'enquiry_purpose',
  },
  {
    title: 'Enquiry Amount',
    dataIndex: 'enquiry_amount',
    key: 'enquiry_amount',
  },

];


const options = {
  pieHole: 0.8,
  colors: ["#219653", "#E61D2B", "#E6AE1D"],
  pieSliceText: "none",
  legend: "none",
};
const baroptions = {
  chart: {},
  legend: { position: "none" },
  width: "20px",
};



export const CibilReport = () => {
  const { encrypted_loan_id } = useParams();
  const [cibilData, setcibilData] = useState(null);

  let customer_id = null;
  try {
    customer_id = decrypt(encrypted_loan_id); 
  } catch (error) { 
    message.error("Invalid Loan ID");
  }

  const chartdata = [
    ["Task", "Number of Accounts"],
    ["Others", 6],
    [
      "Overdue",
      parseInt(
        cibilData?.all_accounts?.overdue_account
      ),
    ],
    [
      "Active",
      parseInt(
        cibilData?.all_accounts?.active_accounts
      ),
    ],
  ];
  const bardata = [
    ["Year", "Sales"],
    ["30 Days",  parseInt(
      cibilData?.enquiry_graph_details?.last_30_days
    ),],
    ["60 Days", parseInt(
      cibilData?.enquiry_graph_details?.last_60_days
    ),],
    ["12 Months",  parseInt(
      cibilData?.enquiry_graph_details?.last_12_months
    ),],
    ["24 Months", parseInt(
      cibilData?.enquiry_graph_details?.last_24_months
    ),],
    ["Recent", parseInt(
      cibilData?.enquiry_graph_details?.recent
    ),],
  ];
  const modifiedData = cibilData?.cibil?.addresses?.map(address => {
    const completeAddress = [
      address.address_line_1 ? `${address.address_line_1}\n` : '',
      address.address_line_2 ? `${address.address_line_2}\n` : '',
      address.address_line_3 ? `${address.address_line_3}\n` : '',
      address.address_line_4 ? `${address.address_line_4}\n` : '',
      address.address_line_5 ? `${address.address_line_5}\n` : ''
    ].join('').trim();

    return {
      ...address,
      complete_address: completeAddress || '-',
      enriched_through_enquiry: address.enriched_through_enquiry || '-',
      date_reported: address.date_reported || '-',
    };
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        setTimeout(async () => {
          const data = await getCibilReportByIdService(customer_id);
          setcibilData(data.data);
        }, 1000);
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchData();
  }, [customer_id,]);



  return (
    <>
      <div className='main_cibil_container'>
        <div>
          <p className="para_heading_first">CIBIL Report</p>
        </div>
        <div className='cibil_heading_second'>
          Home / HL0000000164179 / <span style={{ color: "#000000D9" }}>CIBIL Report</span>
        </div>
      </div>

      <div style={{ background: "#78C25E" }}>
        <div style={{ display: "flex", justifyContent: "center"}}>
          <div style={{ height: "160px" }}>
            <ReactSpeedometer
              value={parseInt(cibilData?.cibil?.score_detail?.scorecard_score)}
              customSegmentLabels={[
                {
                  text: "Very Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Ok",
                  position: "INSIDE",
                  color: "#555",
                  fontSize: "19px",
                },
                {
                  text: "Good",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Very Good",
                  position: "INSIDE",
                  color: "#555",
                },
              ]}
            />

          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <img src={crifimage} alt="" />
        </div>
        <Divider style={{margin: "0",backgroundColor: "white" }} />
        <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 10px 0px 10px", borderbottom: "20px" }}>
          <h4><span style={{ color: "#00000073" }}>SCORE NAME : </span>{cibilData?.cibil?.score_detail?.scorecard_name || ' -'}</h4>
          <h4><span style={{ color: "#00000073" }}>SCORE CARD VERSION : </span>{cibilData?.cibil?.score_detail?.scorecard_version || ' -'}</h4>
          <h4><span style={{ color: "#00000073" }}>SCORE CARD DATE : </span>{cibilData?.cibil?.score_detail?.scorecard_date || ' -'}</h4>

        </div>

      </div>
      <div className="div1" style={{ marginTop: "10px" }}>
        <div
          style={{
            width: "35%",
            borderRight: "1px solid #F0F2F5",
            marginLeft: "10px",
          }}
        >
          <p className="para" style={{ margin: "10px" }}>
            Account Summary
          </p>
          <div className="summary_para">
            Tip: Current Balance, Disbursed Amount & Instalment Amount is considered ONLY for ACTIVE account
          </div>
        </div>
        <div style={{ width: "100%" }}>


          <div className="third_div_account">
            <div style={{ border: "1px solid #E4E7EC", width: "380px", height: "257px", borderRadius: "8px", margin: "12px 0px 0px 12px" }}>
              <h4 style={{ fontFamily: "Roboto", fontWeight: "500", margin: "12px 0px 0px 12px" }}>All Accounts</h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "126px", margin: "50px 0px 0px 13px" }}>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={greendot} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {" "}
                      Others - 3
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={reddot} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Overdue -
                      {
                         cibilData?.all_accounts?.overdue_account
                      }
                    </span>
                  </h4>
                  <h4
                    style={{
                      marginright: "10px",
                      color: "#00000080",
                      margin: "0px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <img src={yellowdot} alt="green" />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      Active -
                      {
                         cibilData?.all_accounts?.active_accounts
                      }
                    </span>
                  </h4>
                </div>
                <div style={{ width: "250px", height: "100px" }}>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="220px"
                    data={chartdata}
                    options={options}
                  />

                </div>
              </div>
            </div>
            <div style={{ border: "1px solid #E4E7EC", width: "400px", height: "257px", borderRadius: "8px", margin: "12px 12px 12px 12px" }}>
              <h4 style={{ fontFamily: "Roboto", fontWeight: "500", margin: "12px 0px 0px 12px" }}>Inquiries in last 24 Months (Total -  {parseInt(
      cibilData?.enquiry_graph_details?.last_24_months
    )})</h4>
              <div style={{ margin: "5px 12px 5px 12px " }}>
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="200px"
                  data={bardata}
                  options={baroptions}
                />
              </div>

            </div>
          </div>
          <div style={{ width: "50%", display: "flex", justifyContent: "space-between", margin: "12px" }}>
            <div style={{ width: "188px", height: "104px", borderRadius: "3px", background: "#5030E514" }}>
              <div style={{ margin: "8px" }}>
                <h4 style={{ color: "#667085" }}> High Cr./Sanction Amt :</h4>
                <h3>₹{cibilData?.high_credit_sanctioned_amount}</h3>
              </div>
            </div>

            <div style={{ width: "188px", height: "104px", borderRadius: "3px", background: "#5030E514" }}>
              <div style={{ margin: "8px" }}>
                <h4 style={{ color: "#667085" }}>Current Balance :</h4>
                <h3> ₹{cibilData?.current_balance}</h3>
              </div>
            </div>

          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>


            <div style={{ margin: "8px 8px" }}>
              <span className='date_format'>Date Opened Oldest: </span>
              <span>
                {cibilData?.date_opened_oldest && moment(cibilData.date_opened_oldest, "DD-MM-YYYY").format('DD-MM-YYYY')}
              </span>
            </div>
            <div style={{ margin: "8px" }}>
              <span className='date_format'>Date Opened Recent: </span>
              <span style={{ marginLeft: "8px" }}>
                {cibilData?.date_opened_recent && moment(cibilData.date_opened_recent, "DD-MM-YYYY").format('DD-MM-YYYY')}
              </span>
            </div>

          </div>
        </div>
      </div>


      <div className='div1' style={{ marginTop: "10px" }}>
        <div style={{ width: "35%", borderRight: "1px solid #F0F2F5" }}>
          <p className='para' style={{ margin: "10px" }}>Consumer/Borrower Information</p>
        </div>
        <div style={{ width: "96%" }} >
          <div className='heagin_first' style={{ borderBottom: "1px solid #F0F2F5" }}><p className='para' style={{ margin: "10px" }}> {cibilData?.cibil?.consumer_info?.full_name}</p></div>

          <div className='third_div_account'>
            <div className='first_div_consumer'>
              <div className='lable_first'>


                <span className='span_lable'> Date of Birth </span> <span className='span_data'>: {cibilData?.cibil?.consumer_info?.date_of_birth}</span>
              </div>
              <div className='lable_first'>
                <span className='span_lable'>Gender </span><span className='span_data'>: {cibilData?.cibil?.consumer_info?.gender}</span>
              </div>
              <div className='lable_first'>
                <span className='span_lable'>
                  Telephone (S) <img src={info} alt="" style={{ height: "20px", marginLeft: "8px" }} /> </span> <span className='span_data'>: {cibilData?.cibil?.consumer_info?.telephone?.map((number, index) => (
                    <span key={index}>{number}<br></br></span>
                  ))}
                </span>

              </div>
            </div>
            <div className='second_div_cosumer'>
              <div className='lable_first' >
                <span style={{ marginTop: "10px" }} className='span_lable'>Email </span><span style={{ marginTop: "10px" }} className='span_data'>: {cibilData?.cibil?.consumer_info?.email}</span>

              </div>
              <div className='lable_first'>
                <span className='span_lable'>Identification (S) <img style={{ height: "20px", marginLeft: "9px" }} src={info} alt="" />  </span><span className='span_data'>: {cibilData?.cibil?.consumer_info?.identification?.map((id, index) => (

                  <span key={index}>{id}<br></br></span>
                ))}</span>
              </div>

            </div>
          </div>
        </div>

      </div>





      <div style={{ marginTop: "10px" }}>

        <div className='div1' style={{ marginTop: "10px" }}>
          <div style={{ width: "35%", borderRight: "1px solid #F0F2F5", }}>
            <p className='para' style={{ margin: "10px" }}>Employment Details : </p>
          </div>

          <div style={{ width: "97%" }} >

            <div className='third_div_account'>

              <>
                {cibilData && cibilData.cibil.employments.map((employment, index) => (
                  <div className='first_div'>
                    <div key={index}>
                      <div className='lable_first'>
                        <span className='span_lable'> Account Type </span>
                        <span className='span_data'>: {employment.account_type || ' - '}</span>
                      </div>
                      <div className='lable_first'>
                        <span className='span_lable'>Occupation Code  </span>
                        <span className='span_data'>: {employment.occupation_code || '-'}</span>
                      </div>
                      <div className='lable_first'>
                        <span className='span_lable'>Net Income Indicator </span>
                        <span className='span_data'>: {employment.net_income_indicator || '-'}</span>
                      </div>
                    </div>
                    <div className='second_div_employment'>
                      <div className='lable_first' >
                        <span className='span_lable'>Date Reported :  </span>
                        <span className='span_data'>: {employment.date_reported_and_certified || '-'}</span>
                      </div>
                      <div className='lable_first'>
                        <span className='span_lable'>Income</span>
                        <span className='span_data'>: {employment.income || '-'}</span>
                      </div>
                      <div className='lable_first'>
                        <span className='span_lable'>Monthly Income </span>
                        <span className='span_data'>: {employment.monthly_income_indicator || '-'}</span>
                      </div>
                    </div>
                  </div>
                ))}

              </>
            </div>

          </div>

        </div>

      </div>

      <div style={{ marginTop: "10px" }}>

        <div className='div2'>
          <div style={{ width: "35%", borderRight: "1px solid #F0F2F5", }}>
            <p className='para' style={{ margin: "10px" }}>Adddress</p>
          </div>

          <div className='main_div_cibil_second' style={{ width: "97%" }}>
            <>
              {modifiedData && (
                <Table
                  className='cibil_table'
                  columns={columns}
                  dataSource={modifiedData}
                  pagination={false}
                />
              )}
            </>
            {/* <Table className='cibil_table' columns={columns} dataSource={cibilData?.cibil?.addresses || [] } pagination={false} /> */}
          </div>

        </div>
      </div>


      <div className='div1' style={{ marginTop: "10px" }}>
        <div style={{ width: "35%", borderRight: "1px solid #F0F2F5", }}>
          <p className='para' style={{ margin: "10px" }}>Your Account</p>
        </div>
        <div style={{ width: "97%" }} >
          {cibilData && cibilData?.cibil?.account?.map((account, index) => (
            <div key={index}>
              <div className='heagin_first' style={{ borderBottom: "1px solid #F0F2F5" }}>
                <p className='para' style={{ margin: "10px" }}> Account {index + 1} <span><button className='credti_btn'>credit card</button></span></p>
              </div>
              <div className='third_div_account'>
                <div className='first_div'>
                  <div className='lable_first'>
                    <span className='span_lable'> Reporting Member Name :</span>
                    <span className='span_data'>{account?.reporting_member_short_name}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'> Ownership Indicator :</span>
                    <span className='span_data'>{account?.ownership_indicator}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>
                      Date of Last Payment : </span>
                    <span className='span_data'>{account?.date_of_last_payment}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>
                      High Credit Amount : </span>
                    <span className='span_data'>{account?.high_credit_sanctioned_amount}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>
                      Account Type : </span>
                    <span className='span_data'> {account?.account_type}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>
                      Date Opened/Disbursed : </span>
                    <span className='span_data'>{account?.date_opened_disbursed}</span>
                  </div>
                </div>
                <div className='second_div'>
                  <div className='lable_first'>
                    <span className='span_lable'>Current Balance : </span>
                    <span className='span_data'>{account?.current_balance}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>Payment History Date : </span>
                    <span className='span_data'>{account?.payment_history_start_date}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>Credit Limit : </span>
                    <span className='span_data'>{account?.credit_limit || '-'}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>Cash Limit : </span>
                    <span className='span_data'>{account?.cash_limit || ' - '}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'> Payment Frequency :</span>
                    <span className='span_data'>{account?.payment_frequency || "-"}</span>
                  </div>
                </div>
              </div>

              <div className='heagin_first' style={{ borderBottom: "1px solid #F0F2F5" }}>
                <p className='para' style={{ margin: "10px" }}> Payment History </p>
              </div>
              <div style={{ borderBottom: "1px solid #F0F2F5" }}>
                <div className='main_div_cibil_second' style={{ width: "100%", margin: "10px" }}>
                  <table style={{ width: "99%" }}>
                    <thead >
                      <tr>
                        <th
                          style={{ position: "relative", paddingTop: "10px" }}
                        >
                          <div
                            style={{
                              transform: "translateY(-30%)",
                            }}
                          >
                            <span
                              style={{
                                transform: "rotate(25deg)",
                                display: "inline-block",
                                marginRight: "4px",
                              }}
                            >
                              Year
                            </span>
                            <span
                              style={{
                                transform: "rotate(25deg)",
                                display: "inline-block",
                                marginRight: "20px",

                              }}
                            >
                              Month
                            </span>
                          </div>
                        </th>
                        {Object.keys(account?.payment_history)?.map((year, index) => (
                          index === 0 &&
                          Object.keys(account?.payment_history[year])?.map((month) => (
                            <th style={{ color: "#667085" }} key={`${year}-${month}`}>{month}</th>
                          ))
                        ))}

                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(account?.payment_history)?.map((year) => (
                        <tr key={year}>
                          <td style={{ backgroundColor: "#FAFAFA", color: "#667085" }}>{year}</td>
                          {Object.keys(account?.payment_history[year])?.map((month) => (
                            <td style={{ color: "#667085" }} key={`${year}-${month}`}>{account?.payment_history[year][month] || '-'}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}

        </div>

      </div>


      <div style={{ marginTop: "10px" }}>

        <div className='div2'>
          <div style={{ width: "35%", borderRight: "1px solid #F0F2F5", }}>
            <p className='para' style={{ margin: "10px" }}>Enquiries</p>
          </div>
          <div className='main_div_cibil_second' style={{ width: "97%" }}>

            <Table className='cibil_table' columns={enquiryColumns} dataSource={cibilData?.cibil?.enquiries || []} pagination={false} />
          </div>

        </div>

        <div className='div2'>
          <div className='memder_id_div' style={{ margin: "10px" }}>
            <div>
              <span className='member_text'>Member ID:</span><span>{cibilData?.member_id || ' - '}</span>
            </div>
            <div>
              <span className='member_text'>ENQUIRY CONTROL NUMBER:</span><span>{cibilData?.enquiry_control_number || ' - '}</span>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}
