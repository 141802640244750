import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllEndUseService = (page, limit, searchQuery) => {
    return new Promise(async (resolve, reject) => {
        try {
            page = page ? page : 1;
            limit = limit ? limit : 10;

            let path = `/api/v1/master/icici-questionnaire/?page=${page}&limit=${limit}`;
            if (searchQuery.length > 0) {

                path = `/api/v1/master/icici-questionnaire/?search=${searchQuery}&page=${page}&limit=${limit}`;
            }
            const response = await axiosRequest.get(path);
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};


export const getIcicInsuranceByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/icici-questionnaire/${id}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const createIcicInsuranceService = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post("/api/v1/master/icici-questionnaire/", data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const updateIcicInsuranceByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.patch(`/api/v1/master/icici-questionnaire/${id}`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}


export const deleteIcicInsuranceByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.delete(`/api/v1/master/icici-questionnaire/${id}`);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const listLoanTypeService = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/loantype/list`);
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};


export const listUserService = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get('/api/v1/user/get-user-list');
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const listQuestionTypeService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/choices-api/icici-question-type`);
        
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };