import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getDeviationsListService,
  getDeviationDataService,
  createDeviationService,
  updateDeviationDetailsByIdService,
} from "../services/rcuservices";

const Manualdeviationform = ({
  open,
  rcuid,
  refreshData,
  closeForm,
  selectedUser,
  setSelectedUser,
  loanId,
  form,
  
}) => {
  const [deviationlist, setDeviationlist] = useState([]);
  const [, setRcustatus] = useState();

 
  const getDeviationData = async () => {
    try {
      const response = await getDeviationsListService();
      setDeviationlist(response.data); // Assuming response.data is the list of deviations
    } catch (error) {
      message.error(`Failed to fetch data`);
    }
  };

  const getManualDeviations = async () => {
    try {
      const response = await getDeviationDataService(selectedUser);
      const { mitigates, actual_description, deviation } = response?.data;
      setRcustatus(response.data.loan_detail);
      form.setFieldsValue({
        mitigates,
        actual_description,
        deviation: deviation.id, // Set the deviation field to the deviation ID
      });
    } catch (error) {
      message.error(
        `${error?.response?.status}: ${error?.response?.data?.message}`
      );
    }
  };

  useEffect(() => {
    getDeviationData();
    if (selectedUser) {
      getManualDeviations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, open]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleSubmit = async (values) => {
    // Sanitize the mitigates field
    const sanitizedMitigates = stripHtmlTags(values.mitigates);

    const payload = {
      loan_detail: loanId,
      deviation: values.deviation,
      actual_description: values.actual_description,
      mitigates: sanitizedMitigates,
      rcu_detail: rcuid
    };

    try {
      if (selectedUser) {
        const response = await updateDeviationDetailsByIdService(
          selectedUser,
          payload
        );
        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("Deviation updated successfully");
            setSelectedUser("")
            refreshData();
            closeForm(); // Close the form after successful update
          }
        }
      } else {
        const response = await createDeviationService(payload);
        if (response && response?.data) {
          if (response?.status === 201 && response?.success) {
            message.success("Deviation created successfully");
            refreshData();
            closeForm(); // Close the form after successful creation
          }
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="deviation"
            label="Deviation"
            rules={[{ required: true, message: "Please select a deviation" }]}
          >
            <Select
              placeholder="Please Select Deviation"
              style={{ width: 200 }}
              allowClear
            >
              {deviationlist.map((deviation) => (
                <Select.Option key={deviation.id} value={deviation.id}>
                  {deviation.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={24}>
          <Form.Item
            name="actual_description"
            label="Actual Deviations"
            rules={[
              { required: true, message: "Please enter the actual deviation" },
            ]}
          >
            <Input placeholder="Please Enter Actual Deviations" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={24}>
          <Form.Item
            name="mitigates"
            label="Mitigant"
            rules={[{ required: true, message: "Please enter the mitigant" }]}
          >
            <ReactQuill placeholder="Enter description here..." />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space
          direction="horizontal"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button type="default" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Manualdeviationform;
